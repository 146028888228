import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/core';

@Injectable({
  providedIn: 'root',
})
export class TourEmitterService {
  public TourEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  public HasTourEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  tourOn = false;
  hasTour = false;
  constructor(private authService: AuthenticationService) {
    this.tourOn = this.authService.getTourGuide() ? (localStorage.getItem('tour') === 'true' ? true : false) : false;
  }

  isTourON() {
    return this.tourOn && this.authService.getTourGuide();
  }

  hasTourOnScreen() {
    return this.hasTour || JSON.parse(localStorage.getItem('tour-on'));
  }

  turnHasTourOn() {
    this.hasTour = true;
    localStorage.setItem('tour-on', JSON.stringify(true));
    this.HasTourEmitter.emit(this.hasTour);
  }

  turnHasTourOff() {
    this.hasTour = false;
    localStorage.setItem('tour-on', JSON.stringify(false));
    this.HasTourEmitter.emit(this.hasTour);
  }
}
