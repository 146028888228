<div class="widget__child" [ngClass]="styleClass">
  <div class="widget__header" *ngIf="widgetTitle || headerTemplate">
    <h2 class="widget__title">
      <span *ngIf="widgetTitle">
        <a [routerLink]="clickThrough">
          {{ widgetTitle }}
        </a>
      </span>
      <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
    </h2>
  </div>
  <ng-content></ng-content>
</div>
