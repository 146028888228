<ul [class]="rootClass" (click)="listClick($event)">
  <ng-template ngFor let-child [ngForOf]="(root ? item : item.items)">
    <li
      *ngIf="child.separator"
      class="ui-menu-separator ui-widget-content"
      [ngClass]="{'ui-helper-hidden': child.visible === false}"
    ></li>
    <li
      *ngIf="!child.separator"
      #listItem
      [ngClass]="{'ui-menuitem ui-corner-all':true,
                'ui-menu-parent':child.items,'ui-menuitem-active':listItem==activeItem, 'active': child==itemActive, 'ui-helper-hidden': child.visible === false}"
      (mouseenter)="onItemMouseEnter($event,listItem)"
      (click)="onItemMenuClick($event, listItem, child)"
    >
      <a
        *ngIf="!child.routerLink"
        [attr.data-automationid]="child.automationId"
        [attr.target]="child.target"
        [attr.title]="child.title"
        [attr.id]="child.id"
        (click)="onItemClick($event, child)"
        [ngClass]="{'ui-menuitem-link ui-corner-all':true,'ui-state-disabled':child.disabled, 'active': child==itemActive}"
        [ngStyle]="child.style"
        [class]="child.styleClass"
      >
        <span class="ui-menuitem-icon" *ngIf="child.icon" [ngClass]="child.icon"></span>
        <span class="ui-menuitem-text">{{child.label}}</span>
        <span
          class="ui-submenu-icon pi pi-fw"
          *ngIf="child.items"
          [ngClass]="{'pi-caret-down':root,'pi-caret-right':!root}"
        ></span>
      </a>
      <a
        *ngIf="child.routerLink"
        [routerLink]="child.routerLink"
        [attr.data-automationid]="child.automationId"
        [queryParams]="child.queryParams"
        [routerLinkActive]="'ui-state-active'"
        [routerLinkActiveOptions]="child.routerLinkActiveOptions||{exact:false}"
        [attr.target]="child.target"
        [attr.title]="child.title"
        [attr.id]="child.id"
        (click)="itemClick($event, child)"
        [ngClass]="{'ui-menuitem-link ui-corner-all':true,'ui-state-disabled':child.disabled, 'active': child==itemActive}"
        [ngStyle]="child.style"
        [class]="child.styleClass"
      >
        <span class="ui-menuitem-icon" *ngIf="child.icon" [ngClass]="child.icon"></span>
        <span class="ui-menuitem-text">{{child.label}}</span>
        <span
          class="ui-submenu-icon pi pi-fw"
          *ngIf="child.items"
          [ngClass]="{'pi-caret-down':root,'pi-caret-right':!root}"
        ></span>
      </a>
      <cvc-menubarSub [autoZIndex]="false" class="ui-submenu" [item]="child" *ngIf="child.items" [autoDisplay]="true"></cvc-menubarSub>
    </li>
  </ng-template>
</ul>
