import { Component, OnInit, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import HighCharts from '@clearviewshared/highchart/highchart';
import { WidgetService } from '../widget.service';
import { CacheService } from '@app/shared/services/cache.service';
import { IAsrUtilization } from '../widget-interface';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import {
  drawPieChartDot,
  generateSentimentCount,
  generateSentimentCountV2,
} from '@clearviewshared/highchart/helperfunctions';
import { Identifiers } from '@app/shared/services/app.config.type';
import { FiltersService } from '@app/shared/services/filters.service';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { AuthenticationService, I18nService } from '@app/core';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { GlobalFilterApiService } from '@app/shared/module/global-filters/global-filters.api.service';
import { color } from 'highcharts';

@Component({
  selector: 'asr-utilization',
  templateUrl: './asr-utilization.component.html',
  styleUrls: ['./asr-utilization.component.scss'],
})
export class AsrUtilizationComponent extends WidgetBase implements OnInit, OnDestroy {
  routerPrefix = '';
  @Input() makeRequest = true;
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() removeWidgetClass = false;
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @ViewChild('containerDVBCount', { read: ElementRef }) containerDVBCount: ElementRef;
  @ViewChild('containerDVBCount2', { read: ElementRef }) containerDVBCount2: ElementRef;

  @Input() module = 'voc';
  @Input() isVOC = false;
  noResult: boolean = false;
  canRender = false;
  colors = ['green', 'orange', 'red', 'gray'];
  data: any[];
  dataModel: IAsrUtilization[] = [];
  chartOptions: any = {};
  alertResoulation = 0;
  chart1: any;
  chart2: any;
  name: string;
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  allSentiment: boolean;
  sentiment: any;
  isAspectCategorySelected = true;
  ac: any;
  api: Subscription;
  isToggleDisabled = false;
  showsWidgets: boolean = false;
  response: any[];
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public toastService: ToastService,
    private appConfig: AppConfigurationService,
    private filterService: FiltersService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService,
    public gbfService: GlobalFilterService,
    public gbfapiservice: GlobalFilterApiService,
    public authService: AuthenticationService
  ) {
    super(gbfService, translateService);
    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }
      this.secondsCounter = interval(widgetThresholdVal);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.asrUtilizationUrl).subscribe((res: any) => {
        if (res) {
          this.data = res;
          this.initData(res.FillRatePercent);
          this.initData2(res.MonthItemComliancePercent);
          this.canRender = true;
        }
      })
    );

    this.subscriptions.push(
      this.widgetService.update.subscribe(() => {
        this.loadWidget();
      })
    );

    // this.secondsCounterSub = this.secondsCounter.subscribe((n: any) => {
    //   // this.loadWidget();
    // });
    this.gbfService.gbfOnApplyFilter.subscribe(() => {
      const surresp: any = JSON.parse(localStorage.getItem('gbf_survey_response'));
      if (
        this.gbfService.surveyQuestion.length !== this.gbfapiservice['surveyQuestions'].length ||
        (surresp &&
          surresp?.tree[1]?.filter((x: any) => x.IsChecked && !x.IsPartialChecked).length !== surresp.tree[1].length)
      ) {
        this.isToggleDisabled = true;
      } else {
        this.isToggleDisabled = false;
      }
    });
    this.gbfService.gbfTabChange.subscribe(() => {
      this.isToggleDisabled = false;
    });
  }

  loadWidget() {
    this.loadView();
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getAsrUtilization().subscribe({
      next: (res: any) => {
        if (res == null || res != null) {
          this.response = res;
          this.widgetService.isAllLoaded = true;
          this.canRender = true;
          if (res != null) {
            this.initData(res.MonthItemComliancePercent);
            this.initData2(res.FillRatePercent);
          } else {
            this.initData(0);
            this.initData2(0);
          }
        }
      },
      complete: () => {
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      },
      error: () => {
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      },
    });
  }
  initData(fillPercentage: number) {
    this.chartOptions = {};
    this.dataModel = [];
    //const fillPercentage = 97.7; // The percentage you want to display

    this.chartOptions = {
      chart: {
        type: 'solidgauge',
        height: '250',
        //width: 350,
        backgroundColor: 'transparent',
        margin: [10, 0, 40, 0],
      },
      credits: { enabled: false },
      title: {
        text: fillPercentage.toFixed(1) + '%', // Display the desired percentage
        y: 110,
        style: {
          'font-size': '25x',
          color: fillPercentage >= 95 ? 'green' : 'red',
          'font-weight': 'bold',
        },
      },
      subtitle: {
        text: 'Utilization Score (%)',
        y: 230,
        style: {
          'font-size': '15px',
          color: 'Black',
          'font-weight': 'bold',
        },
      },
      pane: {
        center: ['50%', '50%'],
        size: '100%',
        startAngle: 0,
        endAngle: 360,
        background: {
          backgroundColor: '#EEE',
          innerRadius: '80%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },
      tooltip: {
        enabled: false,
      },
      yAxis: {
        stops: [
          [0.1, '#55BF3B'], // green
          [0.5, '#DDDF0D'], // yellow
          [0.9, '#55BF3B'], // red
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          y: -70,
        },
        labels: {
          enabled: false,
        },
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
      series: [
        {
          data: [fillPercentage],
          color: fillPercentage > 95 ? '#55BF3B' : '#870e2e',
          innerRadius: '80%',
          outerRadius: '100%',
        },
      ],
    };

    this.chart1 = HighCharts['Reflecx'].createOrUpdate(
      this.chart1,
      this.containerDVBCount.nativeElement,
      this.chartOptions
    );
    // drawPieChartDot(this.chart);
  }

  initData2(fillPercentage: number) {
    this.chartOptions = {};
    this.dataModel = [];

    this.chartOptions = {
      chart: {
        type: 'solidgauge',
        height: '250',
        //width: 350,
        margin: [10, 0, 40, 0],
        backgroundColor: 'transparent',
      },
      credits: { enabled: false },
      title: {
        text: fillPercentage.toFixed(1) + '%', // Display the desired percentage
        y: 110,
        style: {
          'font-size': '25x',
          color: fillPercentage >= 95 ? 'green' : 'red',
          'font-weight': 'bold',
        },
      },
      subtitle: {
        text: 'Off Shelf Fill Rate (%)',
        y: 230,
        style: {
          'font-size': '15px',
          color: 'black',
          'font-weight': 'bold',
        },
      },
      pane: {
        center: ['50%', '50%'],
        size: '100%',
        startAngle: 0,
        endAngle: 360,
        background: {
          backgroundColor: '#EEE',
          innerRadius: '80%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },
      tooltip: {
        enabled: false,
      },
      yAxis: {
        stops: [
          [0.1, '#55BF3B'], // green
          [0.5, '#DDDF0D'], // yellow
          [0.9, '#0096FF'], // red
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          y: -70,
        },
        labels: {
          enabled: false,
        },
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
      series: [
        {
          data: [fillPercentage],
          color: '#0096FF',
          innerRadius: '80%',
          outerRadius: '100%',
        },
      ],
    };

    this.chart2 = HighCharts['Reflecx'].createOrUpdate(
      this.chart2,
      this.containerDVBCount2.nativeElement,
      this.chartOptions
    );
  }

  sentimentChange(e: any) {
    this.loadWidget();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
    if (this.api) {
      this.api.unsubscribe();
    }
  }

  loadView() {
    const mainNodeLevel = this.gbfService.authorizationData.MainNodeLevel;
    const selectedOrgLevel = this.gbfService.getFilterObject().OrgLevel;

    this.showsWidgets = selectedOrgLevel >= mainNodeLevel;
    return this.showsWidgets;
  }
}
