<appc-layout-small>
  <widget-base
    [widgetTitle]="widgetTitle"
    [styleClass]="styleClass"
    [clickThrough]="clickThrough"
    [headerTemplate]="headerTemplate"
  >
    <ng-content></ng-content>
  </widget-base>
</appc-layout-small>
