import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from '@primeng';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { WidgetService } from '../../../widgets/widget.service';

@Component({
  selector: 'app-comment-list-modal.component',
  templateUrl: './comment-list-modal.component.html',
  styleUrls: ['./comment-list-modal.component.scss'],
})
export class CommentListModalComponent implements OnInit {
  @ViewChild('dt', { static: true }) dataTable: any;
  cols: any[];

  @ViewChild('header', { static: true }) headerRef: any;
  @ViewChild('footer', { static: true }) footerRef: any;
  @ViewChild('content', { static: true }) contentRef: any;
  data: any;
  message: string;

  frozenCol: any[];
  pageSize = 25;
  pageDropDown: SelectItem[];
  pageNumber = 1;
  title: string;
  showScrollIcons = true;
  label = this.translateService.instant('Per Page');
  totalRecords: number;
  timeZoneCode: string;
  commentListData: any;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translateService: TranslateService,
    private service: WidgetService
  ) {
    this.data = this.config['data'];
    this.cols = [
      { field: 'Sentiment', header: '' },
      { field: 'Comment', header: this.translateService.instant('Comment') },
      { field: 'CustomerName', header: this.translateService.instant('Customer') },
    ];
    this.service
      .getCustomerJourneyCommentList(
        this.data.kpiName,
        this.data.loyality,
        this.data.Nps,
        this.pageSize,
        this.pageNumber
      )
      .subscribe((res: any) => {
        if (res) {
          this.commentListData = res;
          if (res && res[0]) {
            this.totalRecords = res[0].TotalRecords;
          }
        }
      });
    this.message = this.data.message;
    this.pageDropDown = [
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: ' 100', value: 100 },
    ];
  }
  onPageSizeChange(event: any, dt: any) {
    this.pageNumber = 1;
    this.pageSize = event.value;
    dt.first = 0;
    this.service
      .getCustomerJourneyCommentList(
        this.data.kpiName,
        this.data.loyality,
        this.data.Nps,
        this.pageSize,
        this.pageNumber
      )
      .subscribe((res: any) => {
        if (res) {
          this.commentListData = res;
          if (res && res[0]) {
            this.totalRecords = res[0].TotalRecords;
          }
        }
      });
  }
  paginate(event: any) {
    this.pageNumber = event.first / event.rows + 1;
    this.service
      .getCustomerJourneyCommentList(
        this.data.kpiName,
        this.data.loyality,
        this.data.Nps,
        this.pageSize,
        this.pageNumber
      )
      .subscribe((res: any) => {
        if (res) {
          this.commentListData = res;
          if (res && res[0]) {
            this.totalRecords = res[0].TotalRecords;
          }
        }
      });
  }
  ngOnInit() {}

  Yes() {
    this.ref.close(true);
  }

  No() {
    this.ref.close(false);
  }
}
