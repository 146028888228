<app-widget-dynamic [rootClass]="rootClass" [styleClass]="styleClass" [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle" [widgetTitleToolTip]="widgetTitleToolTip">

  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>

    <div  class="widget-node widget-node__print" >
      <p-dropdown
        [autoZIndex]="false"
        class="dropdown-container"
        [options]="datePeriodOptions"
        [(ngModel)]="selectedDatePeriod"
        (onChange)="datePeriodChange()"
        [style]="{ width: '160px' }"
        placeholder="{{ 'select_option_dropdown.placeholder' | translate }}"
      ></p-dropdown>
    </div>

    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  
  <ng-container widgetArea>
    <div class="valign__content">
      <div class="valign__graph">
          <div class="widget__content"  [attr.data-can-render]="canRender">
            <ng-container *ngIf="data && data.length">
              <cvp-carousel-header #carasolHeader [value]="data" [numVisible]="columsNumbers">
                <ng-template let-item rfTemplate="item">
                  <div (click)="selectWidgetTabs(item)" title="{{ item.tooltip }}">
                    <a styleClass="tabs-secondary" [class.active]="item.name == activeItem">
                      {{ item.id === 2 ? GetKpiNameTranslation(item.name) : item.name | translate }}
                     
                      <span *ngIf="item.id != 1 && item.id != 5">({{ item.value | number: '0.1-1' }}<span *ngIf="item.id == 3">%</span>)</span>
                      <span *ngIf="item.id == 5">({{ item.value | number | replacer }})</span>
                  </a>
                  </div>
                </ng-template>
              </cvp-carousel-header>
            </ng-container>
            <div (clickOutside)="toggleMenu('')">
              <div class="page__filters">
                <div class="filter-item filter-item--hierarchy">
                  <div class="filter-hierarchy">
                    <label>{{ 'performance_trend_Compare with' | translate }}</label>
                    <div class="filter-field">
                      <div class="filter__trigger" [class.active]="showHierarchy">
                        <span *ngIf="getBreadCrumbs()?.length >= 5" >
                          <rf-breadcrumb [model]="getPlaceholderCrumbs()" [styleClass]="'breadcrumbs-tags'"></rf-breadcrumb>
                        </span>
                        <rf-breadcrumb *ngIf="getBreadCrumbs()?.length < 5" [model]="getBreadCrumbs()" [styleClass]="'breadcrumbs-tags'"></rf-breadcrumb>
                        <div class="field__icons">
                          <span *ngIf="!showHierarchy" (click)="showHierarchy_(); $event.stopPropagation()">
                            <i class="far fa-angle-down"></i>
                          </span>
                          <span *ngIf="showHierarchy" (click)="toggleMenu(''); $event.stopPropagation()">
                            <i class="fal fa-times"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <a (click)="compareMonthsRolling()" class="btn">{{ 'go_button.title' | translate }}</a>
                  </div>
                </div>
                <!-- <div class="filter-item filter-item--toggle">
                  <cvc-inputSwitch
                    onLabel="{{ 'performance_trend_Stats' | translate }}"
                    offLabel="{{ 'performance_trend_Question' | translate }}"
                    (onChange)="IsStatClick($event)"
                  ></cvc-inputSwitch>
                </div> -->
                <div class="widget__filters filters--medium"></div>
                <div style="text-align: right">
                  <!-- <app-widget-spinner [hidden]="canRender"></app-widget-spinner> -->
                </div>
              </div>
              <div [ngStyle]="{ display: showHierarchy ? 'block' : 'none' }">
                <appc-performance-trend-org (offmenu)="toggleMenu('')" [parentComponent] = "componentName"></appc-performance-trend-org>
              </div>
            </div>
            <br>
            <div class="widget__graph">
              <ng-container *ngIf="data && data.length">
                <dashboard-performance-trend-widget
                  layout="xl"
                  rootClass="card-xl"
                  [makeRequest]="false"
                  [setDataFrominput]="setDataBar()"
                  [allowDropDownShow]="false"
                  [widgetTitle]="activeItem"
                  [isAllowedTarget]="true"
                  >
                </dashboard-performance-trend-widget>
              </ng-container>
            </div>
        </div>
      </div>
    </div>
  </ng-container>

</app-widget-dynamic>