<nav #nav class="nav" id="menu" *ngIf="windowSize > 1024">
  <ng-container *ngIf="false; else selectionTab">
    <p></p>
  </ng-container>
</nav>

<nav #nav class="nav" id="menu" [@slideInOut]="animationState" *ngIf="windowSize < 1025">
  <ng-container *ngIf="false; else selectionTab">
    <p></p>
  </ng-container>
</nav>

<ng-template #selectionTab>
  <ul>
    <li class="nav__link" *ngFor="let item of tabItems" [class.active]="item.active">
      <ng-template [ngxPermissionsOnly]="item.permission">
        <ng-container *ngIf="item.name == 'Surveys'">
          <a
            [tourAnchor]="item.anchorId"
            [routerLink]="item.url"
            [queryParams]="{ adCo: surveyType }"
            (click)="onClick(item.url)"
            translate
          >
            <!-- <i [class]="item.icon"></i> <span translate [innerHTML]="item.html"></span> -->
            <i [class]="item.icon"></i> <span translate [innerHTML]="item.name"></span>
          </a>
        </ng-container>
        <ng-container *ngIf="item.name !== 'Surveys'">
          <!-- [queryParams]="{prop: 'xxx'}" -->
          <a [tourAnchor]="item.anchorId" [routerLink]="item.url" (click)="onClick(item.url)" translate>
            <!-- <i [class]="item.icon"></i> <span translate [innerHTML]="item.html"></span> -->
            <i [class]="item.icon"></i> <span translate [innerHTML]="item.name"></span>
          </a>
        </ng-container>
      </ng-template>
    </li>
  </ul>
</ng-template>
