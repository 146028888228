import { SelectableRow } from 'primeng/table';
import { Directive } from '@angular/core';

@Directive({
  selector: '[cvpSelectableRow]',
  host: {
    '[class.ui-selectable-row]': 'isEnabled()',
    '[class.ui-state-highlight]': 'selected',
    '[attr.tabindex]': 'isEnabled() ? 0 : undefined',
  },
})
export class CVPSelectableRow extends SelectableRow {}
