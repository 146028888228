<div
  [attr.data-id]="widgetTitle"
  [attr.data-group]="group"
  [attr.data-match-height]="group === 'medium' ? 'medium' : null"
  [attr.class]="!removeWidgetClass ? 'widget' + ' ' + styleClass + ' ' + dataMatchHeightClass : styleClass + ' ' + dataMatchHeightClass"
>
  <div class="translation__overlay">
    <a class="btn">Edit</a>
  </div>
  <div class="widget__header">
    <h2 pTooltip="{{ widgetTitleToolTip }}" tooltipPosition="top"  class="widget__title" *ngIf="widgetTitle?.length > 0">
      {{ widgetTitle }}
      <!-- <span> --> 
        <!-- <strong [ngClass]="{'clr-green': widgetTitle =='Reserved Title'}"> {{ widgetTitle }}</strong> -->
        <!-- <strong></strong> -->
      <!-- </span> -->
    </h2>
    <div class="widget__tool">
      <ng-content #wh select="[widgetHeaderBase]"></ng-content>
      <div class="widget-node" *ngIf="false">
        <a
          class="handler"
          [class.active]="menu.visible"
          (click)="menu.toggle($event);"
          (click)="activeFilter=!activeFilter;"
          ><i class="far fa-ellipsis-h"></i
        ></a>
        <cvc-menu [autoZIndex]="false" [styleClass]="'widget--menu'" [appendTo]="'body'" #menu [popup]="true" [model]="items"></cvc-menu>
      </div>
    </div>
    <ng-template *ngIf="headerTemplate" [ngTemplateOutlet]="headerTemplate"></ng-template>
  </div>
  <ng-content></ng-content>
</div>
