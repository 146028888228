<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <div widgetArea class="card-table">
    <table [hidden]="!canRender" [attr.data-can-render]="canRender">
      <tbody>
        <tr>
          <th *ngFor="let col of cols">{{ col.header }}</th>
        </tr>
        <tr *ngFor="let yoy of yearOverYear">
          <td *ngFor="let col of cols">
            <div *ngIf="col.field == 'KpiScore'">
              <a (click)="RouteOrgSummary();"> {{ yoy[col.field] }}</a>
            </div>
            <div *ngIf="col.field == 'YOY'">
              <a (click)="RouteOrgSummary();"> {{ yoy[col.field] }}</a>
            </div>
            <div *ngIf="col.field == 'Current'">{{ yoy[col.field] | number }}</div>
            <div *ngIf="col.field !== 'YOY' && col.field !== 'Current'  &&  col.field !== 'KpiScore'">
              {{ yoy[col.field] }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-widget-dynamic>
