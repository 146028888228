<span
  [ngClass]="{'ui-autocomplete ui-widget':true,'ui-autocomplete-dd':dropdown,'ui-autocomplete-multiple':multiple}"
  [ngStyle]="style"
  [class]="styleClass"
>
  <input
    *ngIf="!multiple"
    #in
    [attr.type]="type"
    [attr.id]="inputId"
    [ngStyle]="inputStyle"
    [class]="inputStyleClass"
    autocomplete="off"
    [attr.required]="required"
    [ngClass]="'ui-inputtext ui-widget ui-state-default ui-corner-all ui-autocomplete-input'"
    [value]="inputFieldValue"
    (click)="onInputClick($event)"
    (input)="onInput($event)"
    (keydown)="onKeydown($event)"
    (keyup)="onKeyup($event)"
    [attr.autofocus]="autofocus"
    (focus)="onInputFocus($event)"
    (blur)="onInputBlur($event)"
    (change)="onInputChange($event)"
    (paste)="onInputPaste($event)"
    [attr.placeholder]="placeholder"
    [attr.size]="size"
    [attr.maxlength]="maxlength"
    [attr.tabindex]="tabindex"
    [readonly]="readonly"
    [disabled]="disabled"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledBy"
    [attr.aria-required]="required"
  />
  <ul
    *ngIf="multiple"
    #multiContainer
    class="ui-autocomplete-multiple-container ui-widget ui-inputtext ui-state-default ui-corner-all"
    [ngClass]="{'ui-state-disabled':disabled,'ui-state-focus':focus}"
    (click)="multiIn.focus()"
  >
    <li #token *ngFor="let val of value" class="ui-autocomplete-token ui-state-highlight ui-corner-all">
      <span class="ui-autocomplete-token-icon pi pi-fw pi-times" (click)="removeItem(token)" *ngIf="!disabled"></span>
      <span *ngIf="!selectedItemTemplate" class="ui-autocomplete-token-label">{{resolveFieldData(val)}}</span>
      <ng-container *ngTemplateOutlet="selectedItemTemplate; context: {$implicit: val}"></ng-container>
    </li>
    <li class="ui-autocomplete-input-token">
      <input
        #multiIn
        [attr.type]="type"
        [attr.id]="inputId"
        [disabled]="disabled"
        [attr.placeholder]="(value&&value.length ? null : placeholder)"
        [attr.tabindex]="tabindex"
        (input)="onInput($event)"
        (click)="onInputClick($event)"
        (keydown)="onKeydown($event)"
        [readonly]="readonly"
        (keyup)="onKeyup($event)"
        [attr.autofocus]="autofocus"
        (focus)="onInputFocus($event)"
        (blur)="onInputBlur($event)"
        (change)="onInputChange($event)"
        (paste)="onInputPaste($event)"
        autocomplete="off"
        [ngStyle]="inputStyle"
        [class]="inputStyleClass"
        [attr.aria-label]="ariaLabel"
        [attr.aria-labelledby]="ariaLabelledBy"
        [attr.aria-required]="required"
      />
    </li>
  </ul>
  <i *ngIf="loading" class="ui-autocomplete-loader pi pi-spinner pi-spin"></i
  ><button
    #ddBtn
    type="button"
    pButton
    icon="pi pi-fw pi-caret-down"
    class="ui-autocomplete-dropdown"
    [disabled]="disabled"
    (click)="handleDropdownClick($event)"
    *ngIf="dropdown"
  ></button>
  <div
    #panel
    *ngIf="overlayVisible"
    class="ui-autocomplete-panel ui-widget ui-widget-content ui-corner-all ui-shadow"
    [style.max-height]="scrollHeight"
    [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}"
    (@overlayAnimation.start)="onOverlayAnimationStart($event)"
  >
    <!-- (@overlayAnimation.done)="onOverlayAnimationDone($event)" -->
    <ul class="ui-autocomplete-items ui-autocomplete-list ui-widget-content ui-widget ui-corner-all ui-helper-reset">
      <li
        *ngFor="let option of suggestions; let idx = index"
        [ngClass]="{'ui-autocomplete-list-item ui-corner-all':true,'ui-state-highlight':(highlightOption==option)}"
        (mouseenter)="highlightOption=option"
        (mouseleave)="highlightOption=null"
        (click)="selectItem(option)"
      >
        <span *ngIf="!itemTemplate">{{resolveFieldData(option)}}</span>
        <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: option, index: idx}"></ng-container>
      </li>
      <li *ngIf="noResults && emptyMessage" class="ui-autocomplete-list-item ui-corner-all">{{emptyMessage}}</li>
    </ul>
  </div>
</span>
