import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { Dimensions, ImageCroppedEvent, ImageTransform } from './image-cropper/interfaces/index';
import { DynamicDialogConfig, DynamicDialogRef } from '@primeng';
import { DialogService } from '@primeng';
import { NavService } from '@reflecxshared/services/nav/nav.service';
@Component({
  selector: 'app-image-dialog',
  templateUrl: './image.dialog.component.html',
  providers: [DialogService],
})
export class ImageDialogComponent {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperEvent: any;
  base64Image: any;
  cropSettings: any;
  showCropper = false;
  private imageCropperTypes = ['LogoPath', 'HeaderPath', 'FaviconPath', 'OptionsPath'];
  cropperImage = false;
  browseOption = {
    Width: 100,
    Height: 50,
    WidthText: '',
    HeightText: '',
    aspectRatio: 23 / 7,
  };
  // resize:any = {width:0,height:0};
  @ViewChild('header', { static: true, read: TemplateRef }) headerRef: any;
  @ViewChild('footer', { static: true, read: TemplateRef }) footerRef: any;
  @ViewChild('content', { static: true, read: TemplateRef }) contentRef: any;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public navService: NavService
  ) {
    this.imageChangedEvent = this.config.data.imageEvent;
    this.base64Image = this.config.data.baseImage;
    this.cropSettings = this.config.data.cropSettings;
    this.cropperImage = this.imageCropperTypes.indexOf(this.cropSettings.imageTypeOfUpload) >= 0;
    if (this.cropSettings.imageTypeOfUpload && this.cropSettings.imageTypeOfUpload.search('DefaultTemplate') !== -1) {
      this.cropperImage = this.cropSettings.imageTypeOfUpload.search('DefaultTemplate') !== -1;
    }
    if (this.cropperImage) {
      const isTemplate = this.cropSettings.imageTypeOfUpload.search('DefaultTemplate') !== -1;
      let value = this.themeDimension(this.cropSettings.imageTypeOfUpload, isTemplate);
      this.browseOption.Width = value.Width;
      this.browseOption.WidthText = `${value.Width}px`;
      this.browseOption.Height = value.Height;
      this.browseOption.HeightText = `${value.Height}px`;
      this.browseOption.aspectRatio = value.Width / value.Height;
    }
  }
  themeDimension(key: string, isTemplate: boolean = false): { Width: number; Height: number; aspectRatio: number } {
    let value = this.navService.getThemeImageDimension(key, isTemplate);
    return value;
  }
  saveImage() {
    this.ref.close(this.croppedImage);
  }
  cancel() {
    this.ref.close();
  }
  skip() {
    this.ref.close({ file: this.config.data.imageEvent.target.files[0] });
  }
  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.cropperEvent = event;
  }
  cropperReady(sourceImageDimensions: any) {
    // console.log('Cropper ready', sourceImageDimensions);
  }

  imageLoaded() {
    this.showCropper = true;
  }

  loadImageFailed() {
    // show message
  }
}
