<div
  (gfClickOutside)="toggle($event, '')"
  id="indise-gbfd"
  *ngIf="viewAblefilters && viewAblefilters.length > 0"
  class="selected-filter"
  title="selected filters"
>
  <a (click)="show = !show" style="margin: 0" class="btn btn--filter"><i class="fa fa-filter"></i></a>
  <div *ngIf="show" class="selected-filter-dropdown active">
    <h2>{{'Filters Summary' | translate}}</h2>
    <ul class="selected-filter-list">
      <li *ngFor="let filter of viewAblefilters">
        <div class="list-label">{{ filter.name | translate }}</div>
        <div translate class="list-detail">{{ filter.displayValue || filter.value }}</div>
      </li>
    </ul>
  </div>
</div>
