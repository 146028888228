<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough && clickThroughReport">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <div widgetArea class="widget__content widget--keyperformance fullpage--widget" [attr.data-can-render]="canRender">
    <div class="lowest__score" [style.width.%]="100 ">
      <div class="widget__graph">
        <div class="center-block" #containerAOI></div>
      </div>
      <div class="top_widget__footers" *ngIf="keyPerformanceLowestQuestion">
        <table class="table" *ngIf="keyPerformanceLowestQuestion.qs">
          <tbody>
            <tr>
              <!-- <th class="text-left" (click)="displayCustomerQuestionComment(keyPerformanceLowestQuestion)"> -->
              <th class="text-left">
                <a *ngIf="hasCommentPermission"
                  >{{keyPerformanceLowestQuestion.cd}}: {{keyPerformanceLowestQuestion.qs}}
                </a>
                <span *ngIf="!hasCommentPermission"
                  >{{keyPerformanceLowestQuestion.cd}}: {{keyPerformanceLowestQuestion.qs}}
                </span>
              </th>
              <th class="text-right">{{changeValue(keyPerformanceLowestQuestion.sc)}}</th>
            </tr>
            <tr *ngFor="let oo of keyPerformanceLowestGridList">
              <td class="text-left">{{oo.Parent}}</td>
              <td class="text-right">{{changeValue(oo.QuestionAvgScore)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</app-widget-dynamic>
