<li [attr.class]="config?.rootClass">
  <!-- <label class="filter__label">Filter Name</label> -->
  <cvp-calendar
    #datePicker
    [allowMonthSelect]="allowMonthSelect"
    [showDonebutton]="showDonebutton"
    (onDataPickerOpen)="toggleMenu('calendar')"
    (_onMonthSelect)="onMonthSelect($event)"
    [programDate]="programDate"
    (hideDatePicker)="hideDatePicker($event)"
    [dateDropDown]="dateDropDown"
    [currentCalendarDate]="currentCalendarDate"
    [allowMonthSelect]="allowMonthSelect"
    [primeCalendar]="false"
    [inline]="true"
    view="month"
    (onIdentifierChange)="onIdentifierChange($event)"
    [identifier]="identifer"
    (_onDateRangeChange)="onDateRangeChange($event)"
    [showDatePicker]="active"
    [defaultSelectedDate]="defaultSelectedDate"
    (done)="datePickerDone($event)"
    [dateFormat]="config?.format"
    [monthNavigator]="monthNavigator"
    [yearNavigator]="yearNavigator"
    [moment]="primeMoment"
    [autoDatepickerDropdownSelect]="autoDatepickerDropdownSelect"
  >
  </cvp-calendar>
</li>
