import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { Subscription, debounceTime, fromEvent, interval } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import HighCharts from '@clearviewshared/highchart/highchart';
import { WidgetService } from '../widget.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ISentimentCount } from '../widget-interface';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import { FiltersService } from '@app/shared/services/filters.service';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { AuthenticationService, I18nService } from '@app/core';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { GlobalFilterApiService } from '@app/shared/module/global-filters/global-filters.api.service';
import Highcharts from 'highcharts';
import { MenuItem, SelectItem } from 'primeng/api';
import { ActiveOrgHierachy } from '@app/core/types/orgHierarchy';
import { CompareItem, QueryTypes } from '../../comparison-report/type/DataParam';
import { SidebarService } from '@app/shared/services/sidebar.service';
import { NavService } from '@reflecxshared/services/nav/nav.service';
import { BreadCrumbService } from '@app/shared/services/breadcrumb.service';
import { ExportService } from '@clearviewshared/services/export.service';
import { PageLayoutComponent } from '@app/shared/components/page-layout/page-layout.component';
import {
  IPerformanceTrendKpiResponse,
  IPerformanceTrendStatusBarResponse,
  IPerformanceTrendWidgetResponse,
} from '../../reporting/performance-trend/type/IPerformanceTrendResponse';
import { PerformanceTrendService } from './performance-trend-widget.service';
@Component({
  selector: 'performance-trend-widget',
  templateUrl: './performance-trend-widget.html',
  styleUrls: ['./performance-trend-widget.component.scss'],
})
export class PerformanceTrendWidgetComponent extends WidgetBase implements OnInit, OnDestroy {
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @Input() module = 'reporting';
  @Output() filterEmitter: EventEmitter<MenuItem[]> = new EventEmitter<MenuItem[]>();
  @ViewChild(PageLayoutComponent) childComponent!: PageLayoutComponent;
  @ViewChild('carasolHeader', { static: true }) carasol: any;

  //data: any;
  noResult = false;
  colors = ['green', 'orange', 'red', 'gray'];
  chartOptions: any = {};
  name: string;
  ac: any;
  api: Subscription;
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  isToggleDisabled = false;

  widgetType = 'PerformanceTrendWidget';
  showHierarchy = false;
  showMenu = false;
  Highcharts = Highcharts;
  chart: any;
  updateFlag: boolean;
  data: Array<IPerformanceTrendStatusBarResponse> = [];
  data2: Array<IPerformanceTrendStatusBarResponse> = [];
  dropdownValue: any = 1;
  dropdownOptions: SelectItem[];
  childs: any[];
  items: MenuItem[] = [{ label: 'National' }];
  widgetresponce: Array<IPerformanceTrendKpiResponse> = [];
  eventName: any;
  stat: any = false;
  currentLevel: number;
  activeData: ActiveOrgHierachy[];
  logActive: any[];
  items2: MenuItem[];
  currentParent = 'null';
  currentOrg: string;
  activeItem: any = {};
  childactiveItem: any = {};
  Param: any = {};
  compareList: CompareItem[] = [];
  parent = '';
  Array = Array;
  columsNumbers = '12';
  canRender: boolean = true;
  existRecord: any[] = [];
  ischildRender: boolean = true;
  isfromcchild: boolean = false;
  responsiveOptions: any = [];
  Selectitem: any = { kpiKey: '', kpiType: '' };
  private subscriptions: Subscription[] = [];
  urlPerformanceTrendStatus = `${
    this.performacetrendservice.performanceTrendUrl
  }/PerformanceTrendWidgetStatusBar/${false}`;
  urlPerformanceTrendKpi = `${this.performacetrendservice.performanceTrendUrl}/PerformanceTrendWidgetKpi/`;
  urlPerformanceTrendCombined = `${this.performacetrendservice.performanceTrendUrl}/Combined`;
  flagGraph = false;
  selectedDatePeriod = '12';
  datePeriodOptions: { label: any; value: string }[];
  componentName = 'performanceTrendWidget';

  constructor(
    private router: Router,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public toastService: ToastService,
    private appConfig: AppConfigurationService,
    private filterService: FiltersService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService,
    public gbfService: GlobalFilterService,
    public gbfapiservice: GlobalFilterApiService,
    public authService: AuthenticationService,
    private sideBarService: SidebarService,
    private navService: NavService,
    private breadcrumbService: BreadCrumbService,
    private performacetrendservice: PerformanceTrendService,
    private exportService: ExportService,
    private translationHelperService: TranslationHelperService
  ) {
    super(gbfService, translateService);

    this.data2.push({
      childs: [],
      id: 4,
      name: '6',
      tooltip: '',
      value: '0.0',
    });

    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }
      this.secondsCounter = interval(widgetThresholdVal);
    }

    fromEvent(window, 'resize')
      .pipe(debounceTime(1500))
      .subscribe((event) => {
        if (window.innerWidth < 1024) {
          this.columsNumbers = '1';
        } else {
          this.columsNumbers = '12';
        }
      });

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ];

    this.subscriptions.push(
      this.cacheService.for(this.urlPerformanceTrendCombined).subscribe((res: IPerformanceTrendWidgetResponse) => {
        if (res) {
          this.data = res.StatusBar;
          this.Selectitem = this.data[0];
          this.activeItem = this.data[0].name;
          this.widgetresponce = res.KpisTrend;
        }
      })
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.datePeriodOptions = [
      { label: '3MTD', value: '3' },
      { label: '6MTD', value: '6' },
      { label: '12MTD', value: '12' },
    ];

    if (window.innerWidth < 1024) {
      this.columsNumbers = '1';
    } else {
      this.columsNumbers = '12';
    }
    const h = JSON.parse(localStorage.getItem('authorizationData'));
    this.currentLevel = h.Level;
    this.currentParent = h.ParentOrganization;
    this.currentOrg = h.OrganizationName;
    this.updateFlag = false;
    this.data = [];
    const KEY = this.getLocalStorageKey();
    const mainNodeLevel = this.gbfService.authorizationData.MainNodeLevel;
    const selectedOrgLevel = this.gbfService.getFilterObject().OrgLevel;
    if (!this.gbfService[KEY] || selectedOrgLevel <= mainNodeLevel) {
      // const localOrg: any = localStorage.getItem('gbf_org');
      // localStorage.setItem(KEY, localOrg);
      // this.gbfService[KEY] = localOrg;
    }
    this.loadPerformanceTrendWidget();
    this.loadPerformanceTrendWidget(); //TODO - Juggar. Actual issue on performance-trend.component.ts line 284 if (this.containerPerTreWidget)
  }
  datePeriodChange() {
    this.loadPerformanceTrendWidget();
  }
  getLocalStorageKey() {
    const PROPERTY = this.gbfService.currentAllowedFilters.includes('multi_org') ? 'multi_org' : 'org';
    return 'performance_trend_' + this.componentName + '_' + PROPERTY;
  }
  loadPerformanceTrendWidget(): void {
    this.canRender = false;
    const param = {
      kpiKey: this.Selectitem.kpiKey,
      kpiType: this.Selectitem.kpiType,
      selectedMonths: this.selectedDatePeriod,
    };
    this.performacetrendservice
      .getPerformanceTrendWidgetCombined(param, this.gbfService, this.getLocalStorageKey())
      .subscribe((res: IPerformanceTrendWidgetResponse) => {
        if (res) {
          this.data = res.StatusBar;
          if (this.Selectitem.kpiKey == '') {
            this.Selectitem = this.data[0];
            this.activeItem = this.data[0].name;
          }
          this.widgetresponce = res.KpisTrend;
          this.compareList = [];
          this.canRender = true;
          this.flagGraph = true;
        }
      });
  }
  compareMonthsRolling(): void {
    this.loadPerformanceTrendWidget();
    this.showHierarchy = false;
  }

  ngOnChanges(changes: SimpleChanges) {}

  getPlaceholderCrumbs(): any {
    const crumbs: any = this.getBreadCrumbs();
    if (crumbs.length >= 5) {
      const label = `${crumbs[0].label} ${this.translateService.instant('and')} ${
        crumbs.length - 1
      } ${this.translateService.instant('More Selected')}`;
      return [
        {
          label: label,
          value: '',
        },
      ];
    }
    return [];
  }

  getBreadCrumbs() {
    const KEY = this.getLocalStorageKey();
    if (this.gbfService[KEY]) {
      if (Array.isArray(this.gbfService[KEY])) {
        const maxLevel = Math.max(...this.gbfService[KEY].map((obj: any) => obj.level));
        const maxLevelObjects = this.gbfService[KEY].filter((obj: any) => obj.level === maxLevel);
        // if(maxLevelObjects?.length > 1)
        // {
        //  return { label: this.translateService.instant(maxLevelObjects[0].Label + ' and ' + (maxLevelObjects.length - 1) + ' more selected')}
        // }
        return maxLevelObjects.map((x: any) => {
          if (this.gbfService[x.label]) {
            return { label: this.translateService.instant(this.gbfService[x.label]) };
          } else {
            return { label: this.translateService.instant(x.label) };
          }
        });
      } else {
        if (this.gbfService[KEY].label) {
          return [{ label: this.translateService.instant(this.gbfService[KEY].label) }];
        } else if (KEY == 'performance_trend_multi_org') {
          let item = JSON.parse(this.gbfService[KEY]).selected.label;
          if (item) {
            return [{ label: this.translateService.instant(item) }];
          }
        }
      }
    }
  }

  showHierarchy_() {
    if (this.showHierarchy) {
      this.orgDone('showHierarchy_');
      this.showHierarchy = false;
      return;
    }
    this.toggleMenu('showHierarchy');
  }

  toggleMenu(field: string) {
    this.gbfService.forceReRender.next(true);
    this.showHierarchy = false;
    switch (field.trim()) {
      case 'showHierarchy':
        this.showHierarchy = true;
        break;
    }
  }

  orgDone(event: any) {
    let selected = this.activeData[this.activeData.length - 1].active;
    if (selected) {
      if (this.isEmpty(selected)) {
        selected = this.activeData[this.activeData.length - 2].active;
      }
    }

    if (!event) {
      this.showHierarchy = false;
    }
  }

  setEventName(name: string) {
    switch (name) {
      case 'In Progress':
        return 'InProgress';
      default:
        return name;
    }
  }

  setEnum(num: any) {
    switch (num) {
      case 1:
        return QueryTypes.HotAlert;
      case 2:
        return QueryTypes.Survey;
      case 3:
        return QueryTypes.Custome;
      case 4:
        return QueryTypes.Question;
      case 5:
        return QueryTypes.NumberofSurvey;
    }
  }

  isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  chartCallback(chart: Highcharts.Chart) {}

  calcPrePercent(val: number, totalVal: number, decPlace: number) {
    let result = '0';
    const valArr = ((val / totalVal) * 100).toString().split('.');
    if (valArr.length > 1) {
      if (valArr[1].length > 0) {
        result = valArr[0].toString() + '.' + valArr[1].substring(0, decPlace);
      }
    } else {
      result = valArr[0].toString();
    }

    return result;
  }

  calcAndMap(data: any) {}

  selectWidgetTabs(item: any): void {
    this.isfromcchild = false;
    this.Selectitem = item;
    this.activeItem = item.name;
    this.loadPerformanceTrendWidget();
    this.ischildRender = true;
    if (item.childs && item.childs.length !== 0) {
      this.childs = item.childs;
      this.ischildRender = false;
    }
  }

  setDataBar() {
    return this.widgetresponce;
  }

  GetKpiNameTranslation(key: string): string {
    return this.translationHelperService.GetSurveyKpiNameTranslation(key);
  }

  disableCtrl() {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
    if (this.api) {
      this.api.unsubscribe();
    }
  }
}
