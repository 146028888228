import { Component, LOCALE_ID, OnInit, Inject, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { Observable } from 'rxjs';
import { identityServerAngularConfig } from '@env/environment';

import { AuthenticationService } from '../core/authentication/authentication.service';
import { RealTimeNotificationService } from '@app/shared/services/realTimeNotification/real-time-notification.service';
import { SignalrService } from '@app/core/signalr.service';
import { environment } from '@env/environment';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { NgxPermissionsService, NgxPermissionsObject } from 'ngx-permissions';
import { MyStartupService } from '@app/app.service';
import { LoaderService } from './../core/loader.service';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { Guid } from 'guid-typescript';
import { HttpClient } from '@angular/common/http';
import { AuthServiceCleaner } from '@app/shared/services/auth.service.cleaner';
import { IdleTimeoutService } from '@app/shared/services/idle-timeout.service';
import { AppConfig } from 'src/global.config';
import { LocaleService } from '@app/shared/services/locale.service';
import { LocaleId } from '@app/shared/services/locale.provider';
import { NgForageCache } from 'ngforage';
import { RootService } from '@app/core/authentication/root.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import moment from 'moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  identity$: Observable<User>;
  loggedIn$: Observable<boolean>;
  loading$: Observable<boolean>;
  expiring$: Observable<boolean>;
  expired$: Observable<boolean>;
  errors$: Observable<any>;

  public isLoggedIn: boolean;
  public identity: any;
  error: string = undefined;
  ngForage: NgForageCache;
  permissions: NgxPermissionsObject;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private realTimeNotificationService: RealTimeNotificationService,
    private signalRService: SignalrService,
    private toastService: ToastService,
    private idleTimeoutService: IdleTimeoutService,
    private http: HttpClient,
    private authClearService: AuthServiceCleaner,
    private route: ActivatedRoute,
    private ngxpermissions: NgxPermissionsService,
    private myStartupService: MyStartupService,
    private loaderService: LoaderService,
    public appConfig: AppConfigurationService,
    private notificationsService: NotificationsService, // private i18nService: I18nService,
    private localeService: LocaleService,
    public injector: Injector,
    private rootService: RootService,
    @Inject(LOCALE_ID) public localeId: LocaleId
  ) {
    this.ngForage = this.injector.get(NgForageCache);
  }

  ngOnInit() {
    if (this.authService.checkIsSSOUserFlow()) {
      this.router.navigate(['/sso']);
      return;
    }

    this.checkIsIdletimeoutSet();
    this.checkIsTokenExpired();
  }

  checkIsTokenExpired() {
    let isTokenExpired = JSON.parse(localStorage.getItem('isTokenExpired'));
    if (isTokenExpired && isTokenExpired == true) {
      window.localStorage.clear();
      this.authService.signoutRedirect();
      return;
    } else {
      this.checkTenantName();
    }
  }

  checkIsIdletimeoutSet() {
    let isIdleTimeout = JSON.parse(localStorage.getItem('isIdleTimeout'));
    let isUserReloggedIn = JSON.parse(localStorage.getItem('isUserReloggedIn'));
    if (isIdleTimeout && isIdleTimeout == true) {
      window.localStorage.clear();
      this.authService.signoutRedirect();
      return;
    } else if (isUserReloggedIn && isUserReloggedIn == true) {
      localStorage.removeItem('isUserReloggedIn');
      this.router.navigate(['/post-logout']);
    }
  }

  checkTenantName() {
    let tenantName = this.route.snapshot.paramMap.get('id');
    if (!tenantName && this.authService.authData == null) {
      if (environment.login.isDefaultEnabled) {
        tenantName = environment.login.defaultRouteValue;
      }
    }
    if (tenantName) {
      if (this.authService.authData == null) {
        if (this.authService.getTenantName() == null || tenantName !== this.authService.getTenantName()) {
          localStorage.setItem('tenant', tenantName);
        }
        this.authService.init();
        this.initLogin();
      } else {
        this.authService.init();
        this.initLogin();
      }
    } else {
      localStorage.setItem('tenant', '');
      this.authService.init();
      this.initLogin();
    }
  }

  initLogin() {
    this.loaderService.showMain = true;
    this.checkIsIdletimeoutSet();

    this.authService.getCurrentUser().subscribe({
      next: (user) => {
        if (user) {
          this.authService.checkTokenExpiry(user.expires_at);
          this.isLoggedIn = true;
          this.authService.isLoggedIn = true;
          this.identity = user;
          this.initUser();
        } else {
          this.isLoggedIn = false;
          this.authService.isLoggedIn = false;
          this.identity = '';
          if (this.authService.isSetLoggedOut === false) {
            this.authService.clearAuthData();
            localStorage.setItem('isSSOLogin', null);
            localStorage.setItem('ssoClient', null);
            localStorage.setItem('ssoToken', null);
            localStorage.setItem('isIdleTimeout', null);

            localStorage.setItem('idle_timeout_enabled', null);
            this.authService.signinRedirect();
          } else {
            this.router.navigate(['/endsession']);
          }
        }
      },
      error: (e) => {
        console.log(e);
        this.loaderService.showMain = false;
        this.router.navigate(['/oops']);
      },
    });
  }

  login() {
    this.authService.login();
  }

  initUser() {
    const res = {
      access_token: this.identity.access_token,
      expiry: this.identity.expires_at,
    };

    //// set default user language
    (window as any).GetDefaultLanguage(res.access_token, environment.apiUrl).then((r: any) => {
      (window as any).setLanguage(r.data);
      this.localeService.setDefaultLocale(r.data);
      this.localeService.setLocale(r.data);
      this.authService.storeAuthData(res).subscribe(
        (response) => {
          const data = this.authService.onSignIn(response);
          if (response && response.IdleTimeoutConfig != null && response.IdleTimeoutConfig.IsIdletimeoutEnabled) {
            this.idleTimeoutService.setIdletimeout(response.IdleTimeoutConfig.TimeoutSeconds);
          }

          if (environment.signalRConfigured) {
            this.signalRService.init();
            this.realTimeNotificationService.init();
            this.realTimeNotificationService.subscribeListeners();
          }
          this.authService.getLanguage().subscribe((langResponse) => {
            const isOrgList = response.DataAccessLevels.length > 1;
            this.authService.getOrgList(isOrgList).subscribe((res: any) => {
              if (res && res.length !== 0) {
                this.authService.orgListData = this.treeToLinearN(res);
              }
              this.myStartupService.data = langResponse;
              // localStorage.setItem('translations', JSON.stringify(langResponse));
              localStorage.setItem('translations', JSON['makeStringify'](langResponse));
              this.permissions = this.ngxpermissions.getPermissions();
              const Admin = this.permissions['Admin'] ? true : false;
              const Reporting = this.permissions['Reporting'] ? true : false;

              this.loaderService.showMain = false;
              if (localStorage.getItem('loginNotificationId') == null) {
                const id: string = Guid.create().toString();
                localStorage.setItem('appNotifyId', id);
                const notifyData = {
                  message: 'Login Successfull',
                  pageName: 'Login',
                  module: 'Login',
                  appNotifyId: id,
                };
                this.notificationsService.ActivityNonRequestNotification(notifyData, true);
              }
              if (Reporting) {
                this.getConfiguration('gf', 'Global Filter').then((filterCOnfiguration) => {
                  this.router.navigate(['/' + environment.reportingRoutePrefix]);
                });
              } else if (Admin) {
                this.router.navigate(['/' + environment.reflecxRoutePrefix]);
              } else {
                this.router.navigate(['/unauthorized']);
              }
            });
          });
        },
        (reason) => {
          switch (reason.status) {
            case -1: {
              this.error = 'Some error occurred. Please check your internet connection or try again';
              this.loaderService.showMain = false;

              break;
            }
            case 400: {
              this.error = reason.Message;
              this.loaderService.showMain = false;

              break;
            }
            case 500: {
              this.error = reason.error.ExceptionMessage;
              this.loaderService.showMain = false;

              break;
            }
          }
        }
      );
    });
  }

  private getConfiguration(id: string, module: string) {
    return new Promise((resolve, reject) => {
      const user = this.authService.getUser();
      const globalConfig = user.FilterBarConfig.FilterConfig.config;
      if (!user) {
        return resolve(true);
      }
      const url = `/configuration?Id=${id}&UserId=${user.Id}&ClientId=${user.ClientId}&Module=${module}`;
      return this.http
        .disableLoader()
        .disableHttpCancel()
        .get<any>(url)
        .subscribe({
          next: async (configuration: any) => {
            if (configuration && configuration.Settings) {
              const object = configuration.Settings;
              for (const property in object) {
                const config: any = globalConfig.find((config: any) => {
                  return config.localStorageKey == property;
                });
                if (config && config.localStorageKey !== 'gbf_org') {
                  if (config?.database === 'indexdb') {
                    try {
                      const parsed = JSON.parse(object[property]);
                      localStorage.setItem(property, JSON.stringify({ selected: parsed.selected }));
                    } catch (error) {}
                    await this.ngForage.setItem(property, object[property]);
                  } else {
                    localStorage.setItem(property, object[property]);
                  }
                  localStorage.setItem(property, object[property]);
                  // this.gbfService[property + '_full'] = object[property];
                  // this.gbfService[property + '_breadcrums'] = object[property].breadcrums;

                  const identifier = object.gbf_date_identifier || config?.range || 'MTD';
                  if (config.localStorageKey === 'gbf_calendar') {
                    localStorage.setItem('gbf_date_identifier', identifier);
                    if (identifier.toLowerCase() !== 'custom range') {
                      let dateDropDownOptions = this.rootService.getCalenderDropDownOptions();
                      if (identifier.toLowerCase() === 'program to date') {
                        const programDate = this.appConfig.getByIdentifier(Identifiers.ProgramDate);
                        if (programDate) {
                          if (programDate.ClientConfig === null) {
                            programDate.ClientConfig = programDate;
                          }
                          if (programDate.ClientConfig) {
                            const dateValue = new Date(programDate.ClientConfig.Fields['equal']['value']);
                            if (dateValue) {
                              let progamToDateArray = [
                                moment(dateValue).format('MMM D, YYYY'),
                                moment().format('MMM D, YYYY'),
                              ];
                              let updatedObject = progamToDateArray.join('|');
                              object[property] = updatedObject;
                            }
                          }
                        }
                      } else {
                        let selectedOption = dateDropDownOptions.filter((item: any) => {
                          return item.key.toLowerCase() === identifier.toLowerCase();
                        });
                        selectedOption =
                          selectedOption.length === 0
                            ? [
                                {
                                  label: 'MTD',
                                  key: 'MTD',
                                  value: (value: any = moment()) => [moment(value).startOf('month'), moment()],
                                },
                              ]
                            : selectedOption;

                        let updatedDates = object[property].split('|');
                        updatedDates[0] = selectedOption[0].value()[0].format('MMM D, YYYY');
                        updatedDates[1] = selectedOption[0].value()[1].format('MMM D, YYYY');
                        let updatedObject = updatedDates.join('|');
                        object[property] = updatedObject;
                      }
                    }
                  }
                  // if(user.ClientName !== 'smithville'){
                  //   return resolve(true);
                  // }
                }
                //  else {
                //   if(user.ClientName !== 'smithville'){
                //     return resolve(true);
                //   }
                // }
              }
              return resolve(true);
            } else {
              return resolve(true);
            }
          },
          error: (e) => {
            return resolve(true);
          },
        });
    });
  }

  private treeToLinearN(list: any): Array<any> {
    const orgData: Array<any> = [];
    list.forEach((element: any) => {
      element.Data.forEach((dataNodes: any) => {
        dataNodes.Nodes.forEach((node: any) => {
          let insertElement: any = {
            id: node.Name,
            level: element.Level,
            label: node.Label,
            name: node.Label,
            value: node.NodeName,
            Title: element.Title,
            parent: dataNodes.Parent,
            hasChild: node.HasChild || false,
            identifier: node.Identifier,
            nodeId: node.NodeId,
          };
          orgData.push(insertElement);
        });
      });
    });
    return orgData;
  }
}
