import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import HighCharts, { theme } from '@clearviewshared/highchart/highchart';
@Component({
  selector: 'dial-meter',
  templateUrl: './dial-meter.component.html',
  styleUrls: ['./dial-meter.component.scss'],
})
export class DialMeterComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() rank: any;
  @Input() dialsLength: any = 0;
  rankingConfig: any;

  Highcharts = HighCharts;
  @ViewChild('container', { read: ElementRef, static: true }) container: ElementRef;
  chart: any;
  constructor(private appConfig: AppConfigurationService) {
    HighCharts.setOptions(theme);
  }

  ngOnInit() {
    if (this.data) {
      this.chart = HighCharts['Reflecx'].createOrUpdate(this.chart, this.container.nativeElement, this.data);
    }
    var kpiConfig = this.appConfig.getByIdentifier('dashboard.kpi.dialers.v2');
    this.processRankingConfig(kpiConfig);
  }

  GetClass(rank: number, total: number) {
    var midPoint = Math.floor(total / 2);
    return rank > midPoint ? 'location--negative' : 'location--positive';
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      // tslint:disable-next-line:max-line-length
      this.chart = HighCharts['Reflecx'].createOrUpdate(
        this.chart,
        this.container.nativeElement,
        changes.data.currentValue
      );
    }
  }

  processRankingConfig(config: any) {
    if (config && config.Fields.ranking_config) {
      // Filter out the ranking_config items where EnableCss is true
      this.rankingConfig = Object.values(config.Fields.ranking_config)
        .filter((item: any) => item.EnableCss === true)
        .map((item: any) => ({
          EnableCss: item.EnableCss,
          Label: item.Label,
          Level: item.Level,
        }));

      // Now you can use this.rankingConfig as needed
    }
  }
  isLevelNameEnabled(levelName: string): boolean {
    if (this.rankingConfig.length === 0) {
      return false; // Return false if no items are available
    }
    return this.rankingConfig.some((item: any) => item.Label === levelName && item.EnableCss === true);
  }
}
