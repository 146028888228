import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RealTimeNotificationService } from '@app/shared/services/realTimeNotification/real-time-notification.service';
import { DynamicDialogRef } from '@primeng';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-real-time-notification',
  templateUrl: './real-time-notification.component.html',
  styleUrls: ['./real-time-notification.component.scss'],
})
export class RealTimeNotificationComponent implements OnInit {
  @ViewChild('header', { static: true }) headerRef: any;
  @ViewChild('footer', { static: true }) footerRef: any;
  @ViewChild('content', { static: true }) contentRef: any;
  constructor(
    public ref: DynamicDialogRef,
    public route: ActivatedRoute,
    public router: Router,
    public realTimeNotificationService: RealTimeNotificationService
  ) {}

  ngOnInit() {}

  onClose() {
    this.ref.close();
  }

  gotoDashboard(id: number) {
    this.router.navigate([`/${environment.reflecxRoutePrefix}/insight/${id}`]);
    this.ref.close();
  }
}
