import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FiltersService } from '@app/shared/services/filters.service';
import { HelperService } from '@clearviewshared/services/helper.service';
import { RestMessage } from '@app/core/types';
import moment from 'moment';
import { DynamicTopic } from '../dynamic-topic/dynamicTopicResponse';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DynamicTopicService {
  dynamicSearchData: any = [];
  StatusEnum: any = {
    Positive: 0,
    Neutral: 1,
    Negative: 2,
    Unclassified: 3,
  };

  constructor(
    private httpClient: HttpClient,
    private filtersService: FiltersService,
    private translateService: TranslateService,
    private helperService: HelperService
  ) {}
  getRMDynamicTopics(payload: any, PageNo: any, PageSize: any, qureyStatus: any, loader: boolean, sentiment: String) {
    //const filters = this.filtersService.getFilterObjectForVOCDashboard();
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    filters['POS'] = payload;
    filters['CategoryName'] = 'All Categories';
    delete filters['CategoryId'];
    delete filters['CategoryLevel'];
    filters['SentimentType'] = qureyStatus ? this.StatusEnum[qureyStatus] : null;
    filters['PageNo'] = PageNo;
    filters['PageSize'] = PageSize;
    const url = '/sm-reports/dynamicsearch/' + (sentiment == undefined || sentiment == '' ? '-1' : sentiment);
    // console.log('url ', url);
    return this.httpClient.post<DynamicTopic>(url, filters);
    // if (loader) {
    // } else {
    //   return this.httpClient.disableLoader().post<DynamicTopic>(url, filters);
    // }
  }
  getDynamicTopics(payload: any, PageNo: any, PageSize: any, qureyStatus: any, loader: boolean, sentiment: String) {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    filters['POS'] = payload;
    filters['CategoryName'] = 'All Categories';
    delete filters['CategoryId'];
    delete filters['CategoryLevel'];
    filters['SentimentType'] = qureyStatus ? this.StatusEnum[qureyStatus] : null;
    filters['PageNo'] = PageNo;
    filters['PageSize'] = PageSize;
    const url = '/vocreports/dynamicsearch/' + (sentiment == undefined || sentiment == '' ? '-1' : sentiment);
    // console.log('url ', url);
    return this.httpClient.post<DynamicTopic>(url, filters);
    // if (loader) {
    // } else {
    //   return this.httpClient.disableLoader().post<DynamicTopic>(url, filters);
    // }
  }
  getDynamicTopicsExport(payload: any, sentiment: any) {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    // const filters = this.filtersService.getFilterObject();
    const url = '/vocreports/exportdynamictopic/' + (sentiment == '' || sentiment == undefined ? '-1' : sentiment);
    // console.log(filters);
    return this.httpClient.post<DynamicTopic>(url, filters);
  }

  downloadDynamicTopicsExport(payload: any, PageNo: any, PageSize: any, isExportAll: boolean, qureyStatus: any) {
    const filters = this.filtersService.getFilterObjectForVOCDashboard();
    filters['POS'] = payload;
    filters['CategoryName'] = 'All Categories';
    delete filters['CategoryId'];
    delete filters['CategoryLevel'];
    filters['SentimentType'] = qureyStatus ? this.StatusEnum[qureyStatus] : null;
    filters['PageNo'] = 1;
    filters['PageSize'] = PageNo * PageSize;

    // console.log(qureyStatus, 'query status');

    const url = `/vocreports/exportdynamictopic/${isExportAll}/${
      qureyStatus == '' || qureyStatus == undefined ? '-1' : qureyStatus
    }`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.httpClient
      .post(url, filters, { responseType: 'arraybuffer', observe: 'response', headers: headers })
      .pipe(
        catchError((err) => {
          return EMPTY;
        })
      )
      .subscribe((res: any) => {
        // // console.log(res);
        this.helperService.exportExelFile(res, this.translateService.instant('Dynamic Topic Search'));
      });
  }
  downloadRMDynamicTopicsExport(payload: any, PageNo: any, PageSize: any, isExportAll: boolean, qureyStatus: any) {
    //const filters = this.filtersService.getFilterObjectForVOCDashboard();
    const filters = this.filtersService.getFilterObjectForSocialMedia();
    filters['POS'] = payload;
    filters['CategoryName'] = 'All Categories';
    delete filters['CategoryId'];
    delete filters['CategoryLevel'];
    filters['SentimentType'] = qureyStatus ? this.StatusEnum[qureyStatus] : null;
    filters['PageNo'] = 1;
    filters['PageSize'] = PageNo * PageSize;

    // console.log(qureyStatus, 'query status');

    const url = `/sm-reports/exportdynamictopic/${isExportAll}/${
      qureyStatus == '' || qureyStatus == undefined ? '-1' : qureyStatus
    }`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.httpClient
      .post(url, filters, { responseType: 'arraybuffer', observe: 'response', headers: headers })
      .pipe(
        catchError((err) => {
          return EMPTY;
        })
      )
      .subscribe((res: any) => {
        // // console.log(res);
        this.helperService.exportExelFile(res, this.translateService.instant('Dynamic Topic Search'));
      });
  }
}
