import { Component, Input } from '@angular/core';
import WidgetBase from './widegt.base';

@Component({
  selector: 'app-widget-layout',
  template: `
    <appc-widget-xl
      *ngIf="layout === 'xl'"
      [styleClass]="styleClass"
      [clickThrough]="clickThrough"
      [widgetTitle]="widgetTitle"
    >
      <ng-container widgetHeaderRoot>
        <ng-content select="[widgetHeader]"></ng-content>
      </ng-container>
      <ng-container widgetMain>
        <ng-content select="[widgetArea]"></ng-content>
      </ng-container>
    </appc-widget-xl>
    <appc-widget-medium *ngIf="layout === 'medium'" [clickThrough]="clickThrough" [widgetTitle]="widgetTitle">
      <ng-container widgetHeaderRoot>
        <ng-content select="[widgetHeader]"></ng-content>
      </ng-container>
      <ng-container widgetMain>
        <ng-content select="[widgetArea]"></ng-content>
      </ng-container>
    </appc-widget-medium>
  `,
})
export class WidgetLayoutComponent extends WidgetBase {}
