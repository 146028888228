import { Component, OnInit, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import HighCharts from '@clearviewshared/highchart/highchart';
import { WidgetService } from '../widget.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ISentimentCount, SentimentCountStatus } from '../widget-interface';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import {
  drawPieChartDot,
  generateSentimentCount,
  generateSentimentCountV2,
} from '@clearviewshared/highchart/helperfunctions';
import { Identifiers } from '@app/shared/services/app.config.type';
import { sentimentCountData } from '../widget-data';
import { FiltersService } from '@app/shared/services/filters.service';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { AuthenticationService, I18nService } from '@app/core';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { GlobalFilterApiService } from '@app/shared/module/global-filters/global-filters.api.service';

@Component({
  selector: 'featured-score',
  templateUrl: './featured-score.component.html',
  styleUrls: ['./featured-score.component.scss'],
})
export class FeaturedScoreComponent extends WidgetBase implements OnInit, OnDestroy {
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @ViewChild('containerDVBCount', { read: ElementRef }) containerDVBCount: ElementRef;
  @ViewChild('containerDVBCount2', { read: ElementRef }) containerDVBCount2: ElementRef;

  @Input() module = 'reporting';
  // @Input() isVOC = false;
  noResult = false;
  canRender = false;
  colors = ['green', 'orange', 'red', 'gray'];
  data: any;
  dataModel: ISentimentCount[] = [];
  chartOptions: any = {};
  alertResoulation = 0;
  chart1: any;
  chart2: any;
  name: string;
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  allSentiment: boolean;
  sentiment: any;
  isAspectCategorySelected = true;
  ac: any;
  api: Subscription;
  isToggleDisabled = false;
  kpiScore: any;
  targetScore: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public toastService: ToastService,
    private appConfig: AppConfigurationService,
    private filterService: FiltersService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService,
    public gbfService: GlobalFilterService,
    public gbfapiservice: GlobalFilterApiService,
    public authService: AuthenticationService
  ) {
    super(gbfService, translateService);
    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }
      this.secondsCounter = interval(widgetThresholdVal);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.setAspectCatPreset();
    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.featureScroreUrl).subscribe((res: any) => {
        if (res) {
          this.data = res;

          //this.initData(res);
          this.canRender = true;
        }
      })
    );

    this.subscriptions.push(
      this.widgetService.update.subscribe(() => {
        this.setAspectCatPreset();
        this.loadWidget();
      })
    );

    // this.secondsCounterSub = this.secondsCounter.subscribe((n: any) => {
    //   // this.loadWidget();
    // });
    this.gbfService.gbfOnApplyFilter.subscribe(() => {
      const surresp: any = JSON.parse(localStorage.getItem('gbf_survey_response'));
      if (
        this.gbfService.surveyQuestion.length !== this.gbfapiservice['surveyQuestions'].length ||
        (surresp &&
          surresp?.tree[1]?.filter((x: any) => x.IsChecked && !x.IsPartialChecked).length !== surresp.tree[1].length)
      ) {
        this.isToggleDisabled = true;
      } else {
        this.isToggleDisabled = false;
      }
    });
    this.gbfService.gbfTabChange.subscribe(() => {
      this.isToggleDisabled = false;
    });
  }

  setAspectCatPreset() {
    const aspectCategory = this.filterService.getAspectCategory();
    if (aspectCategory.label === 'All Categories') {
      this.isAspectCategorySelected = false;
      this.allSentiment = false;
    } else {
      this.isAspectCategorySelected = true;
      this.allSentiment = false;
    }
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getFeaturedScore().subscribe({
      next: (res: any) => {
        if (res) {
          this.data = this.extractData(res);
          this.kpiScore = this.data.kpiScore;
          this.initData(this.i18nService, this.data);

          this.canRender = true;
          this.widgetService.isAllLoaded = true;
        }
      },
      complete: () => {
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      },
      error: () => {
        this.noResult = true;
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      },
    });
  }

  extractData(res: any): { targetScore: number; kpiScore: number } {
    return {
      targetScore: res.target ? res.target.target_score : 0,
      kpiScore: res.response_kpis ? parseFloat(res.response_kpis[0].score.toFixed(1)) : 0,
    };
  }
  initData(i18nService: I18nService, data: any) {
    this.chartOptions = {};
    this.dataModel = [];

    this.chartOptions = {
      chart: {
        type: 'gauge',
        spacingBottom: 10,
        spacingTop: 10,
        spacingLeft: 10,
        width: 350,
        spacingRight: 10,
        padding: 0,
        margin: 10,
        plotBorderWidth: 0,
        plotShadow: false,
      },

      credits: {
        enabled: false,
      },
      // tooltip: {
      //   formatter: function () {
      //     return '<b>' + this.series.name + '</b>: ' + i18nService.transformNumber(this.y.toFixed(1)) + ' %';
      //   },
      // },
      pane: [
        {
          startAngle: -360,
          endAngle: 360,
          background: [
            {
              backgroundColor: 'white',
              borderWidth: 0,
              borderColor: '#e6e6e6',
              outerRadius: '116%',
              //  innerRadius: "100%"
            },
          ],
        },
      ],
      plotOptions: {
        gauge: {
          borderWidth: 100,
          pivot: {
            radius: 0,
          },
          dial: {
            radius: '100%',
            backgroundColor: '',
            baseWidth: 0,
            topWidth: 3,
            rearLength: '-77%',
          },
        },
      },
      title: {
        text: data.targetScore,
        align: 'center',
        verticalAlign: 'top',
        y: 190,
        style: {
          width: '20%',
          color: 'black',
          fontSize: '32px',
          fontWeight: '500',
          textTransform: 'capitalize',
        },
      },
      subtitle: {
        text: 'Target',
        align: 'center',
        verticalAlign: 'top',
        y: 215,
        style: {
          width: '15%',
          fontSize: '20px',
          fontWeight: '400',
          color: 'gray',
          textTransform: 'uppercase',
        },
      },
      // the value axis
      yAxis: {
        min: 0,
        max: 1000,
        gridLineColor: 'red',
        gridLineWidth: 0,
        minorTickInterval: 'auto',
        minorTickWidth: 1.5,
        minorTickLength: 30,
        minorTickPosition: 'inside',
        minorTickColor: data.kpiScore > 950 ? 'green' : 'red',
        tickPixelInterval: 50,
        lineColor: 'transparent',
        tickWidth: 0,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: 'red',
        plotBands: [
          {
            from: 0,
            to: 1000,
            color: 'gray',
            outerRadius: '70%',
            thickness: '2%',
          },
        ],
        labels: {
          step: 10,
          enabled: false,
          rotation: 'auto',
        },
      },

      series: [
        {
          data: [
            {
              y: data.kpiScore,
              dial: {
                backgroundColor: 'red',
                radius: '85%',
                borderColor: 'red',
                borderWidth: 1,
                baseWidth: 12,
                topWidth: 1,
                baseLength: '80%',
              },
            },
          ],
          name: 'MTD Score',
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: 'Target',
          data: [
            {
              y: data.targetScore,
              dial: {
                backgroundColor: 'red',
                radius: '85%',
                borderColor: 'red',
                borderWidth: 1,
                baseWidth: 12,
                topWidth: 1,
                baseLength: '80%',
              },
            },
          ],

          // tooltip: {
          //   valueSuffix: 'heelo',
          // },
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    this.chart1 = HighCharts['Reflecx'].createOrUpdate(
      this.chart1,
      this.containerDVBCount.nativeElement,
      this.chartOptions
    );
  }

  sentimentChange(e: any) {
    this.loadWidget();
  }

  disableCtrl() {
    // this.allSentiment = false;
    // return false;
    // if(this.isAspectCategorySelected){
    // }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
    if (this.api) {
      this.api.unsubscribe();
    }
  }
}
