import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Subscription } from 'rxjs';
import { WidgetsRegistry } from './widget-helper.service';
import { AuthenticationService } from '@app/core';
@Component({
  selector: 'app-widget-hoster',
  templateUrl: 'widget-hoster.component.html',
})
export class WidgetHosterComponent implements OnInit, OnDestroy {
  events: any[] = [];
  reRender = false;
  @Input() component = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @Input() showGraph = false;
  @Input() allowDropDownShow = true;

  extra: any = {};
  private subscriptions: Subscription[] = [];
  constructor(
    public gbfService: GlobalFilterService,
    private appConfig: AppConfigurationService,
    private authService: AuthenticationService
  ) {
    this.subscriptions.push(
      this.gbfService.gbfTabChange.subscribe((val: any) => {
        this.init(val);
      }),
      this.gbfService.gbfOnApplyFilter.subscribe((val: any) => {
        this.init(this.gbfService.tab.value);
      })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });
  }
  init(val: any) {
    // val = 'Sale,Service';
    val = val.split(',').map((x: string) => ({ value: x }));
    const _events = Array.isArray(val) ? val : [{ value: val }];
    if (_events && _events.length > 0) {
      let allEvents: any[] = JSON.parse(localStorage.getItem('surveyVersions') || '[]') || [];
      const events: any[] = [];
      _events.forEach((v) => {
        const version =
          this.authService.authData.ClientName === 'infiniti'
            ? this.gbfService.getVersionQP('', {})
            : this.gbfService.getVersion('', {});
        let found = allEvents.find((x: any) => version?.SurveyVersion === x.value);
        if (!found) {
          found = allEvents.find((x: any) => x.CurrentSurvey && x.Event.toLowerCase() === v.value.toLowerCase());
        }
        // (version.SurveyVersion === x.value) ||
        // const found = allEvents.find(
        //   (x: any) =>
        //     version.SurveyVersion === x.value || (x.CurrentSurvey && x.Event.toLowerCase() === v.value.toLowerCase())
        // );
        // console.log(found, version);
        if (found) {
          events.push(found);
        }
      });
      if (!allEvents.length) {
        events.push({
          Brand: this.gbfService.model.Brand,
          CurrentSurvey: true,
          Event: this.gbfService.tab.value,
          ParentSurveyId: this.gbfService.version.value,
          SurveyId: this.gbfService.version.value,
          Version: this.gbfService.version.label,
          label: this.gbfService.version.label,
          value: this.gbfService.version.value,
        });
      }
      this.events = events;
      this.extra = { events: this.events };
      if (
        WidgetsRegistry[this.component]?.beforeInit &&
        typeof WidgetsRegistry[this.component]?.beforeInit === 'function'
      ) {
        WidgetsRegistry[this.component]?.beforeInit(this, { allEvents, appConfig: this.appConfig });
      }
      this.reRender = false;
      setTimeout(() => {
        this.reRender = true;
      }, 0);
    } else {
      this.events = [];
    }
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.init(this.gbfService.tab.value);
      this.reRender = true;
    }, 0);
  }
}

@Component({
  selector: 'app-widget-hoster-helper',
  template: `<ng-container *ngFor="let event of _events" #vc></ng-container>`,
})
export default class WidgetHosterHelperComponent implements AfterViewInit {
  @ViewChildren('vc', { read: ViewContainerRef }) public vcs: QueryList<ViewContainerRef>;
  @Input() _events: any[] = [];
  @Input() set events(_events: any) {
    this._events = _events;
  }
  get events(): any {
    return this._events;
  }
  @Input() component = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() styleClass = '';
  @Input() clickThrough = '';
  @Input() extra: any = {};
  @Input() widgetTitle = '';
  @Input() showGraph = false;
  @Input() allowDropDownShow = true;

  allowedInputs = [
    'events',
    'layout',
    'rootClass',
    'globalEvents',
    'styleClass',
    'clickThrough',
    'widgetTitle',
    'showGraph',
    'allowDropDownShow',
  ];
  constructor(public gbfService: GlobalFilterService, private cd: ChangeDetectorRef) {}
  load() {
    this.vcs.toArray().forEach((element: ViewContainerRef, index: number) => {
      element.clear();
      if (WidgetsRegistry[this.component]) {
        const componentRef = element.createComponent(WidgetsRegistry[this.component].component);
        componentRef.instance['apiConfig'] = this.events[index];
        this.allowedInputs.forEach((input) => {
          if (input === 'rootClass' && this[input] === 'card-medium' && this._events.length > 2) {
            componentRef.instance[input] = 'card-small';
          } else {
            componentRef.instance[input] = this[input];
          }
        });
        componentRef.instance['extra'] = this.extra;
        componentRef.instance['from'] = 'widgetHoster';
        this.cd.detectChanges();
      } else {
        // console.log(this.component);
      }
    });
  }
  ngAfterViewInit(): void {
    this.load();
  }
}
