import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { WidgetService } from '../widget.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { CacheService } from '@app/shared/services/cache.service';
import { FiltersService } from '@app/shared/services/filters.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { I18nService } from '@app/core/i18n.service';
import { generateTop5ClosedAlerts } from '@clearviewshared/highchart/helperfunctions';
import Highcharts from 'highcharts';
import { IPortalTrafficResponse } from './types/IPortalTrafficResponse';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
@Component({
  selector: 'portal-traffic-widget',
  templateUrl: './portal-traffic-widget.component.html',
  styleUrls: ['./portal-traffic-widget.component.scss'],
})
export class PortalTrafficWidgetComponent extends WidgetBase implements OnInit, OnDestroy {
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @Input() showGraph = false;
  canRender = false;
  records: any = [];
  header: any = [];
  dispGraph = false;
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  api: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private appConfig: AppConfigurationService,
    private cacheService: CacheService,
    public toastService: ToastService,
    public i18nService: I18nService,
    public gbfService: GlobalFilterService
  ) {
    super(gbfService, translateService);
    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }
      this.secondsCounter = interval(widgetThresholdVal);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadWidget();
    // this.subscriptions.push(
    //   this.cacheService.for(this.widgetService.ocMaintNoUrl).subscribe((res: Array<any>) => {
    //     if (res) {
    //       this.records = res;
    //     }
    //   })
    // );
    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
    }
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    this.dispGraph = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.records = [
      {
        label: 'Appeal Listing',
        data: [
          {
            day: 'Sun',
            output: '72',
            key: 1,
          },
        ],
      },
      {
        label: 'Bulletin Uploads',
        data: [
          {
            day: 'Sun',
            output: '111',
            key: 1,
          },
        ],
      },
      {
        label: 'Issue List',
        data: [
          {
            day: 'Sun',
            output: '1434',
            key: 1,
          },
        ],
      },
      {
        label: 'Issue Dashboard',
        data: [
          {
            day: 'Sun',
            output: '850',
            key: 1,
          },
        ],
      },
      {
        label: 'Comment List',
        data: [
          {
            day: 'Sun',
            output: '96',
            key: 1,
          },
        ],
      },
      {
        label: 'Competitors',
        data: [
          {
            day: 'Sun',
            output: '2',
            key: 1,
          },
        ],
      },
      {
        label: 'Contact Statistics',
        data: [
          {
            day: 'Sun',
            output: '154',
            key: 1,
          },
        ],
      },
      {
        label: 'Cross  Tabs',
        data: [
          {
            day: 'Sun',
            output: '1',
            key: 1,
          },
        ],
      },
      {
        label: 'Customer Search',
        data: [
          {
            day: 'Sun',
            output: '617',
            key: 1,
          },
        ],
      },
      {
        label: 'Dashboard',
        data: [
          {
            day: 'Sun',
            output: '52407',
            key: 1,
          },
        ],
      },
    ];
    this.header = ['HITS'];
    this.canRender = true;
    this.dispGraph = true;
    this.widgetService.isAllLoaded = true;
    // this.api = this.widgetService.getOCMaintNo().subscribe({
    //   next: (res: Array<any>) => {
    //     if (res) {
    //       this.records = res;
    //       this.canRender = true;
    //       this.dispGraph = true;
    //       this.widgetService.isAllLoaded = true;
    //     }
    //   },
    //   complete: () => {
    //     this.canRender = true;
    //     this.dispGraph = true;
    //     this.widgetService.isAllLoaded = true;
    //   },
    //   error: () => {
    //     this.canRender = true;
    //     this.dispGraph = false;
    //     this.widgetService.isAllLoaded = true;
    //   },
    // });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }
}
