import { Component, OnInit, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Subscription, interval, Observable, fromEvent } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import HighCharts from '@clearviewshared/highchart/highchart';
import { WidgetService } from '../widget.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ITotalAlerts, AlertStatus, CorrectContactInfoStatus } from '../widget-interface';
import { ToastService } from '@app/shared/services/toast/toast.service';
import {
  generateTotalAlerts,
  generateNpsScoreV2,
  generateTotalAlertsV2,
  drawPieChartDot,
  generateSentimentCountV2,
  generateCorrectContactCountV3,
} from '@clearviewshared/highchart/helperfunctions';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import { debounceTime } from 'rxjs/operators';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { I18nService } from '@app/core';
import {
  ICorrectContactInfoOutstandingResultResponse,
  ICorrectContactInfoOutstandingResponse,
} from './types/ICorrectContactInfoOutstandingResultResponse';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
import { GbfBase } from '@app/shared/module/global-filters/shared/gbf-base';
import { environment } from '@env/environment';

@Component({
  selector: 'correct-contact-info-outstanding',
  templateUrl: './correct-contact-info-outstanding.component.html',
  styleUrls: ['./correct-contact-info-outstanding.component.scss'],
})
export class CorrectContactInfoOutstandingComponent extends WidgetBase implements OnInit, OnDestroy {
  routerPrefix = '';
  _HighCharts = HighCharts;
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @ViewChild('containerTotalAlert', { read: ElementRef }) containerTotalAlert: ElementRef;
  @ViewChild('containerTotalAlert2', { read: ElementRef }) containerTotalAlert2: ElementRef;
  canRender = false;
  noResult = false;
  data: any[] = [];
  dataModel: any[] = [];
  topView: any[] = [];
  chartOptions: any = {};
  expiredToday = 0;
  expireTodayPercentage = 0;
  chart: any;
  chart2: any;
  name: string;
  colors = ['green', 'yellow', 'orange', 'blue', 'red', 'gray', 'purple'];
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  api: Subscription;
  clickThroughReport: boolean;
  permissions: NgxPermissionsObject;
  private subscriptions: Subscription[] = [];
  reportingRoutePrefix: any = environment.reportingRoutePrefix;
  user: any;

  constructor(
    private router: Router,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    public gbfService: GlobalFilterService,
    private cacheService: CacheService,
    public toastService: ToastService,
    private appConfig: AppConfigurationService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService,
    public ngxPermission: NgxPermissionsService // public widgetBase: WidgetBase
  ) {
    super(gbfService, translateService);
    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }
      this.secondsCounter = interval(widgetThresholdVal);
    }
    this.user = this.gbfService.authService.getUser();
    fromEvent(window, 'resize')
      .pipe(debounceTime(1500))
      .subscribe((event) => {
        this.initChart(0, 0);
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.permissions = this.ngxPermission.getPermissions();
    this.setPermissions();
    this.loadWidget();

    this.subscriptions.push(
      this.cacheService
        .for(this.widgetService.correctContactInfoOutstandingUrl)
        .subscribe((res: ICorrectContactInfoOutstandingResultResponse) => {
          if (res) {
            this.data = res.Views ? res.Views : [];
            // this.alertResolution = res.AlertResolution ? res.AlertResolution : 0;
            this.topView = res.TopView;
            this.initChart(0, 0);
            this.canRender = true;
          }
        })
    );
    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
      this.subscriptions.push(
        this.gbfService.gbfOnApplyFilter.subscribe(() => {
          this.loadWidget();
        })
      );
    }
  }

  routeToAlertlist(key: any) {
    let eventName = this.eventName;
    if (eventName != '') {
      this.router.navigate(['/insights/dashboard/surveyresend'], {
        queryParams: { status: CorrectContactInfoStatus[key] || 1, en: eventName },
      });
    } else {
      this.router.navigate(['/insights/dashboard/surveyresend'], {
        queryParams: { status: CorrectContactInfoStatus[key] || 1 },
      });
    }
  }

  paramsAlertStatus(key: any) {
    return CorrectContactInfoStatus[key] || 1;
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getCorrectContactInformation({ apiConfig: this.apiConfig }).subscribe({
      next: (res: any) => {
        if (res) {
          res.Views = [
            {
              Key: 'missing_info',
              Count: 0,
              Order: '0',
            },

            {
              Key: 'invalid_info',
              Count: 0,
              Order: '1',
            },
          ];
          this.data = res ? res.Views : [];

          if (res.email_edit.length > 0) {
            this.data.forEach((f: any) => {
              f.Count = res.email_edit[0][f.Key];
            });

            this.expiredToday = res.email_edit ? res.email_edit[0]['today_expire'] : 0;
            this.expireTodayPercentage = res.email_edit ? res.email_edit[0]['today_expire_pace'] : 0;
          }

          //this.topView = res.TopView;
          this.canRender = true;
          this.widgetService.isAllLoaded = true;
          this.initChart(this.expiredToday, this.expireTodayPercentage);
          this.initChart2();
        }
      },
      complete: () => {
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      },
      error: () => {
        this.noResult = true;
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      },
    });
  }
  setPermissions() {
    this.clickThroughReport = this.permissions['reporting_globaldashboard_surveyresend'] ? true : false;
  }
  initChart(target: number, score: number) {
    const language = this.user.DefaultLanguage.split('-');
    this.chartOptions = {};
    this.dataModel = [];
    const fillPercentage = score; // The percentage you want to display
    const _score = this.i18nService.transformNumber(Number(parseFloat(fillPercentage + '').toFixed(1)));
    this.chartOptions = {
      chart: {
        type: 'solidgauge',
        height: '100%',
        width: 300,
        backgroundColor: 'transparent',
      },
      credits: { enabled: false },
      title: {
        text: language[0] == 'en' ? _score.replace(new RegExp(`,`, 'g'), '') + '%' : _score + '%', // Display the desired percentage
        y: 65,
        x: 10,
        style: {
          'font-size': '30px',
          color: 'black',
          'font-weight': 'bold',
        },
      },

      pane: {
        center: ['50%', '50%'],
        size: '100%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: '#EEE',
          innerRadius: '80%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },
      tooltip: {
        enabled: false,
      },
      yAxis: {
        stops: [
          [0.1, '#55BF3B'], // green
          [0.5, '#DDDF0D'], // yellow
          [0.9, '#55BF3B'], // red
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          y: -70,
        },
        labels: {
          enabled: false,
        },
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: -30, // Adjust the Y position to place the label where you want
            borderWidth: 0,
            useHTML: true,
            format: '<div style="text-align:center">{point.label}</div>',
            style: {
              'font-size': '20px',
              color: 'red',
              'font-weight': 'bold',
            },
          },
        },
      },
      series: [
        {
          data: [
            {
              y: target,
              label: this.translateService.instant('Expiring Today'),
            },
          ],
          color: '#55BF3B',
          innerRadius: '80%',
          outerRadius: '100%',
        },
      ],
      // Add a custom subtitle
      subtitle: {
        text: target,
        y: 190, // Adjust the Y position as needed
        style: {
          'font-weight': 'bold',
          'font-size': '26px',
          color: 'red',
        },
      },
    };

    this.chart = HighCharts['Reflecx'].createOrUpdate(
      this.chart,
      this.containerTotalAlert.nativeElement,
      this.chartOptions
    );
    // drawPieChartDot(this.chart);
  }
  initChart2() {
    this.chartOptions = {};
    this.dataModel = [];
    if (this.data) {
      const IsAllZero = this.data.find((x: any) => {
        return x.Count > 0;
      });
      if (!IsAllZero) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }
      let totalCout = 0;
      const charData: any[] = [];
      this.data.forEach((data: any, index: number) => {
        totalCout += data.Count;
      });
      this.data.map((itm, i) => {
        if (itm.Count !== 0) {
          itm.Percent = this.calcPrePercent(itm.Count, totalCout, 1);
        } else {
          itm.Percent = '0.0';
        }

        charData.push({
          // itm.Title
          name: this.translateService.instant(itm.Key),
          nametext: itm.Key,
          namex: this.translateService.instant('hotalert_alert_count.label'),
          data: itm.Count,
          y: itm.Count,
          Percent: itm.Percent,
          order: itm.Order,
          events: {
            legendItemClick: (e: any) => {
              if (e.target.visible) {
                totalCout -= e.target.data;
                alertStatusCount = totalCout;
              } else {
                totalCout += e.target.data;
                alertStatusCount = totalCout;
              }
            },
          },
        });

        return itm;
      });

      const seriesData: any = [
        {
          type: 'pie',
          size: '60%',
          innerSize: '85%',
          tooltip: {
            pointFormat: '<b>{point.y}</b>',
            borderWidth: 0,
            backgroundColor: 'none',
            shadow: false,
            useHTML: true,
          },
          dataLabels: {
            enabled: false,
          },
          showInLegend: false,
          name: this.translateService.instant('hotalert_alert_count.label'),
          data: charData,
        },
      ];

      charData.sort((a, b) => (a.order > b.order ? 1 : -1));
      const plotOpt = {
        cursor: 'pointer',
        point: {
          events: {
            click: (e: any, b: any, c: any) => {
              if (e.point.data > 0 && this.clickThroughReport) {
                this.router.navigate(['/insights/dashboard/surveyresend'], {
                  queryParams: { source: CorrectContactInfoStatus[e.point.nametext] },
                });
              }
            },
          },
        },
      };
      const kpiPercent = totalCout;
      // const result = this.transformNumber(parseFloat(kpiPercent));
      // const tys = result.toString();
      let alertStatusCount = parseFloat(totalCout.toFixed(1));
      // tslint:disable-next-line:max-line-length
      // tslint:disable-next-line:max-line-length
      if (this.containerTotalAlert2 && this.containerTotalAlert2.nativeElement && this._HighCharts) {
        const link = `/portal/${this.reportingRoutePrefix}/dashboard/surveyresend'`;
        if (!this.noResult) {
          this.chartOptions = generateCorrectContactCountV3(
            seriesData,
            plotOpt,
            kpiPercent,
            this.i18nService,
            (this.name = this.translateService.instant('Total Records \n Outstanding'))
            //this.translateService.instant('Total Records \n Outstanding')
          );
          this.chart2 = this._HighCharts['Reflecx'].createOrUpdate(
            this.chart2,
            this.containerTotalAlert2.nativeElement,
            this.chartOptions
          );

          //drawPieChartDot(this.chart);
        } else {
          // tslint:disable-next-line:max-line-length
          this.chartOptions = generateCorrectContactCountV3(
            seriesData,
            plotOpt,
            kpiPercent,
            this.i18nService,
            (this.name = 'Total Records Outstanding')
            //this.translateService.instant('Total Records \n Outstanding')
          );
          this.chart2 = this._HighCharts['Reflecx'].createOrUpdate(
            this.chart2,
            this.containerTotalAlert2.nativeElement,
            this.chartOptions
          );
          //drawPieChartDot(this.chart);
        }
      }
    }
  }

  calcPrePercent(val: number, totalVal: number, decPlace: number) {
    let result = '0';
    const valArr = ((val / totalVal) * 100).toString().split('.');
    if (valArr.length > 1) {
      if (valArr[1].length > 0) {
        result = valArr[0].toString() + '.' + valArr[1].substring(0, decPlace);
      }
    } else {
      result = valArr[0].toString();
    }

    return result == 'NaN' ? '0.0' : result;
  }

  calcPrePer(val: number, decPlace: number) {
    let result = '0.0';
    const valArr = val.toString().split('.');
    if (valArr.length > 1) {
      if (valArr[1].length > 0) {
        result = valArr[0].toString() + '.' + valArr[1].substring(0, decPlace);
      }
    } else {
      result = valArr[0].toString();
    }

    return result == 'NaN' ? '0.0' : result;
  }

  GetHotalertStatusTranslation(key: string) {
    return this.translationHelper.GetHotalertStatusTranslation(key);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }

    if (this.api) {
      this.api.unsubscribe();
    }
  }

  getIndexReminder(index: number) {
    return index % 2;
  }
  changeValue(value: any) {
    const language = this.user.DefaultLanguage.split('-');
    const score = this.i18nService.transformNumber(Number(parseFloat(Math.round(value * 100) / 100 + '').toFixed(1)));
    return language[0] == 'en' ? score.replace(new RegExp(`,`, 'g'), '') : score;
  }
}
