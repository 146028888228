import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FiltersService } from '@app/shared/services/filters.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GlobalFilterHelperService } from '../../global-filters-helper.service';

@Component({
  selector: 'app-gbf-straight-tree',
  templateUrl: 'global-filter-straight-tree.component.html',
})
export class GlobalFilterStraightTreeComponent implements OnInit {
  Object = Object;
  @Input() config: any = {};
  @Input() values: any = [];
  @Input() active = false;
  activeTree: any = {};
  search = '';
  render = true;
  searchChanged: Subject<string> = new Subject<string>();
  searchItems: any[] = [];
  worker: any;
  activatedItems: any[] = [];
  activatedLevel = 1;
  activatedParent = 'null';
  breadcrums: any[] = [];
  searchLoader: boolean;
  ctx = this;
  constructor(
    public gbfHelperServer: GlobalFilterHelperService,
    public filtersService: FiltersService,
    public cd: ChangeDetectorRef
  ) {
    this.searchChanged.pipe(debounceTime(250)).subscribe((x) => {
      this.buildSearch(x);
    });
    if (typeof Worker !== 'undefined' && !this.worker) {
      this.worker = new Worker(new URL('../../filter.worker', import.meta.url), { type: 'module' });
    }
  }
  ngOnInit(): void {
    this.activatedLevel = this.config.startLevel;
    this.gbfHelperServer[this.config.property] = [];
    this.activeTree = { [this.config.startLevel]: [], [this.config.startLevel + 1]: [] };
    this.gbfHelperServer.orgsTree = this.filtersService[this.config.filterRef];
    if (this.config.treeToLinear && this.config.property && this.filtersService[this.config.filterRef]) {
      this.gbfHelperServer.treeToLinear(this.filtersService[this.config.filterRef], this.config.property, true);
    }
    if (this.gbfHelperServer[this.config.property] && this.gbfHelperServer[this.config.property].length > 0) {
      let rootElements = this.gbfHelperServer[this.config.property].filter(
        (x: any) => x.level === this.config.startLevel
      );
      if (rootElements && rootElements.length > 0) {
        rootElements = rootElements.map((rEl: any) => {
          rEl.IsChecked = true;
          return rEl;
        });
        this.activeTree[this.config.startLevel] = rootElements;
        rootElements.forEach((x: any) => {
          const rootChilds = this.gbfHelperServer[this.config.property].filter((y: any) => y.parent === x.name);
          if (rootChilds && rootChilds.length > 0) {
            this.activeTree[this.config.startLevel + 1] = [
              ...this.activeTree[this.config.startLevel + 1],
              ...rootChilds,
            ];
          }
        });
      }
    }
    if (this.config.settings.stright) {
      this.buildActivatedTree();
    }
  }
  reRender() {
    this.render = false;
    setTimeout(() => {
      this.render = true;
    }, 0);
  }

  onListBreadcrumClick(parsel: { index: number; item: any }) {
    if (parsel.index !== -1) {
      this.breadcrums = this.breadcrums.splice(0, parsel.index + 1);
      this.activatedParent = parsel.item.id;
      this.activatedLevel = parsel.item.level + 1;
    } else {
      this.breadcrums = [];
      this.activatedParent = 'null';
      this.activatedLevel = 1;
    }
    this.buildActivatedTree();
  }

  onItemClicked(parsel: { items: any[]; item: any; type: string; el: string }) {
    if (this.config.settings.stright && parsel.type !== 'selection' && parsel.item.hasChild) {
      this.activatedParent = parsel.item.id;
      this.activatedLevel = parsel.item.level + 1;
      this.buildActivatedTree();
      this.breadcrums.push(parsel.item);
    } else {
      Object.keys(this.activeTree).forEach((key: any) => {
        if (parsel.items.length > 0) {
          parsel.items.forEach((item: any) => {
            if (item.level < key) {
              this.activeTree[key] = [];
            }
          });
        } else {
          if (parsel.item.level < key) {
            this.activeTree[key] = [];
          }
        }
      });
      parsel.items.forEach((item: any, index: number) => {
        if (index === 0) {
          this.activeTree[item.level + 1] = [];
        }
        const rootChilds = this.gbfHelperServer[this.config.property].filter((y: any) => y.parent === item.id);
        if (rootChilds && rootChilds.length > 0) {
          this.activeTree[item.level + 1] = [...this.activeTree[item.level + 1], ...rootChilds].map((el: any) => {
            el.IsChecked = false;
            return el;
          });
        }
      });
    }
  }
  onSearch(query: string) {
    this.searchChanged.next(query);
  }
  makeSearchTree(item: any, index: any, searchItems: any) {
    const found = this.gbfHelperServer[this.config.property].find((x: any) => x.id === item.parent);
    if (found) {
      searchItems[index].push(found);
      if (found.parent !== -1) {
        this.makeSearchTree(found, index, searchItems);
      }
    }
  }
  buildSearch(query: string) {
    this.searchLoader = true;
    if (this.worker) {
      this.worker.onmessage = ({ data }: any) => {
        this.searchItems = data;
        this.searchLoader = false;
      };
      this.worker.postMessage({ items: this.gbfHelperServer[this.config.property], query });
    } else {
      const searchItems: any = [];
      this.gbfHelperServer[this.config.property].forEach((item: any) => {
        if (query && item.label.toLowerCase().search(this.gbfHelperServer.escapeRegExp(query.toLowerCase())) !== -1) {
          const insertedIndex = searchItems.push([item]) - 1;
          if (item.parent !== -1) {
            this.makeSearchTree(item, insertedIndex, searchItems);
          }
        }
      });
      searchItems.forEach((items: any[]) => {
        items.reverse();
      });
      this.searchItems = searchItems.slice(0, 150);
      this.searchLoader = false;
    }
  }

  buildActivatedTree() {
    const items = this.gbfHelperServer[this.config.property].filter((item: any) => {
      return item.level === this.activatedLevel && item.parent === this.activatedParent;
    });
    this.activatedItems = items;
  }
}
