import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { environment } from '@env/environment';
import moment from 'moment';
import { Router } from '@angular/router';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Identifiers } from './app.config.type';
import { AuthenticationService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@app/core/loader.service';
const AllowedTypes = {
  png: 'png',
  jpeg: 'jpeg',
  webp: 'webp',
};

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  permissions: NgxPermissionsObject;
  beforePrint = new Subject();
  shouldWait = false;
  constructor(
    private http: HttpClient,
    private route: Router,
    private authService: AuthenticationService,
    private loadreService: LoaderService,
    public translateService: TranslateService,
    public ngxPermission: NgxPermissionsService
  ) {
    this.permissions = ngxPermission.getPermissions();
  }

  printPage() {
    document.body.click();
    const content = document.getElementsByTagName('html')[0].outerHTML;
    this.printPdf(content);
  }

  printPdf(content: string) {
    // if (environment.isPrintAllowed) {
    if (this.permissions[Identifiers.PrintDashboard] ? true : false) {
      const dd = moment(new Date()).format('YYYY-MM-DD HH:mm');
      const authData: any = JSON.parse(localStorage.getItem('authorizationData'));
      if (!authData) {
        return;
      }
      const headers = new HttpHeaders();
      headers.append('Authorization', 'Bearer ' + this.authService.getToken());
      headers.append('token', this.authService.getToken());
      const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
      const STYLE_REGEX = /<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi;
      while (SCRIPT_REGEX.test(content)) {
        content = content.replace(SCRIPT_REGEX, '');
      }
      while (STYLE_REGEX.test(content)) {
        content = content.replace(STYLE_REGEX, '');
      }
      this.http
        .post(
          environment.printServerUrl + '/pdf',
          {
            payload: {
              html: content,
              token: this.authService.getToken(),
              timestamp: dd,
              language: localStorage.getItem('language'),
              width: window.innerWidth,
            },
          },
          {
            responseType: 'arraybuffer',
            headers,
          }
        )
        .subscribe((res: any) => {
          const blob = new Blob([res]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const route = this.route.url.split('/')[2];
          const languages = localStorage.getItem('language');
          const english = {
            dashboard: 'Global Dashboard',
            alerts: 'Alerts',
            voc: 'Text Analytics',
            rm: 'Reputation Management',
            reportgenerator: 'Report generator',
            crosstabs: 'Cross Tabs',
            reports: 'Day of Week Report',
          };
          var filename = this.translateService.instant(`print-${english[route]}`);
          link.download = filename + '.pdf';
          link.click();
        });
    }
  }

  printImage(content: string, config: any = { type: 'png', customWidth: false, width: 0, fileName: '' }) {
    let imageType = AllowedTypes[config?.type] || AllowedTypes.png;
    let customWidth = config?.customWidth || false;
    let width = config.width || window.innerWidth;

    // if (environment.isPrintAllowed) {
    if (true) {
      const authData: any = JSON.parse(localStorage.getItem('authorizationData'));
      if (!authData) {
        return;
      }
      let params = this.getParams(content);
      let headers = params.headers;
      let payload = {
        html: params.content,
        ...(config.type !== 'pdf' && { type: imageType }),
        token: this.authService.getToken(),
        timestamp: params.dateFormat,
        ...(config.type !== 'pdf' && customWidth),
        language: localStorage.getItem('language'),
        width: width,
      };
      this.http
        .post(
          environment.printServerUrl + (config.type !== 'pdf' ? '/image' : '/pdf'),
          {
            payload,
          },
          {
            responseType: 'arraybuffer',
            headers,
          }
        )
        .subscribe((res: any) => {
          const blob = new Blob([res]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          var filename = this.translateService.instant(`${config.fileName}`);
          link.download = filename + '.' + (config.type !== 'pdf' ? imageType : 'pdf');
          link.click();
        });
    }
  }

  getParams(content: string) {
    const dateFormat = moment(new Date()).format('YYYY-MM-DD HH:mm');
    const headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + this.authService.getToken());
    headers.append('token', this.authService.getToken());
    const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
    const STYLE_REGEX = /<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi;
    while (SCRIPT_REGEX.test(content)) {
      content = content.replace(SCRIPT_REGEX, '');
    }
    while (STYLE_REGEX.test(content)) {
      content = content.replace(STYLE_REGEX, '');
    }
    let data = { dateFormat, headers, content };
    return data;
  }

  downloadFile = function (fileName: string, chart_element_id: string, fileType: string) {
    let content = document.getElementById(chart_element_id).innerHTML;
    content = content.replace(
      `style="width: 100%; height: 350px; display: block; overflow: hidden;`,
      `style="width: 65%; height: 550px; display: block; overflow: hidden ;margin: 0 auto;`
    );
    let config: any = { type: fileType, customWidth: false, width: 0, fileName: fileName };
    this.printImage(content, config);
  };
}
