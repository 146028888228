import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { WidgetService } from '../widget.service';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '@app/shared/services/cache.service';
import { Subscription, interval } from 'rxjs';
import { ToastService } from '@app/shared/services/toast/toast.service';
import WidgetBase from '../widegt.base';
import { ISurveyCompletionRate } from './types/ISurveyCompletionRate';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';

@Component({
  selector: 'survey-completion-widget',
  templateUrl: './survey-completion.html',
})
export class SurveyCompletionRateComponent extends WidgetBase implements OnInit, OnDestroy {
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @Input() data: ISurveyCompletionRate;
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  canRender = false;
  sentSurvey: any;
  totalEvent: any;
  api: Subscription;
  private subscriptions: Subscription[] = [];
  constructor(
    public toastService: ToastService,
    public widgetService: WidgetService,
    public gbfService: GlobalFilterService,
    public translateService: TranslateService,
    private cacheService: CacheService
  ) {
    super(gbfService, translateService);
  }
  ngOnInit() {
    super.ngOnInit();
    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.surveycompletionrate).subscribe((res: ISurveyCompletionRate) => {
        if (res) {
          this.data = res;
          this.canRender = true;
        }
      })
    );
    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
      this.subscriptions.push(
        this.gbfService.gbfOnApplyFilter.subscribe(() => {
          this.loadWidget();
        })
      );
    }
    // this.secondsCounterSub = this.secondsCounter.subscribe((n) => {
    //   //this.loadWidget();
    // });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }

  loadWidget() {
    this.canRender = false;
    this.widgetService.isAllLoaded = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getSurveyCompletionRate({ apiConfig: this.apiConfig }).subscribe(
      (res: ISurveyCompletionRate) => {
        this.data = res;
        const colors = [
          { color: 'red', code: '#f35d58' },
          { color: 'blue', code: '#59b9ef' },
          { color: 'black', code: '#222' },
          { color: 'yellow', code: '#ffc107' },
        ];
        this.data.EventCount.TotalPercentage = this.data.EventCount.TotalEvents > 0 ? 100 : 0;
        this.data.Statuses = this.data.Statuses.map((status, index) => {
          status.Color = { color: '', code: '' };
          if (colors[index]) {
            status.Color.color = colors[index].color;
            status.Color.code = colors[index].code;
          } else {
            status.Color.color = 'red';
            status.Color.code = '#f35d58';
          }
          return status;
        });
        this.canRender = true;
        this.widgetService.isAllLoaded = true;
      },
      (error) => {
        this.data.EventCount = {
          TotalPercentage: 0,
          TotalEvents: 0,
          ValidEmails: 0,
          ValidEmailsPercentage: 0,
          Percentage: 0,
        };
        this.toastService.error('Failed.');
        this.canRender = true;
        this.widgetService.isAllLoaded = true;
      }
    );
  }

  getPercentage(status: any) {
    if (status && this.data.EventCount) {
      return (status.Count / this.data.EventCount.TotalEvents) * 100;
    } else {
      return 0;
    }
  }
}
