<div class="{{ rootClass }}">
  <widget-base
    [removeWidgetClass]="removeWidgetClass"
    [widgetTitle]="widgetTitle"
    [widgetTitleToolTip]="widgetTitleToolTip"
    [styleClass]="styleClass"
    [clickThrough]="clickThrough"
    [headerTemplate]="headerTemplate"
    [dataMatchHeightClass]="dataMatchHeightClass"
    [group]="rootClass === 'card-medium' ? 'medium' : 'none'"
  >
    <ng-container widgetHeaderBase>
      <ng-content select="[widgetHeader]"></ng-content>
    </ng-container>
    <ng-content select="[widgetArea]"></ng-content>
    <ng-content></ng-content>
  </widget-base>
</div>
