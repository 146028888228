<div class="kpi">
  <div class="kpi__inner">
    <div class="kpi__location">
      <ul *ngFor="let ran of rank; let index = index">
        <li  [ngClass]="GetClass(ran.Rank , ran.Total)">
          <label class="location__label">{{ ran.LevelName }}</label>
          <span *ngIf="ran.Category == 'upper'"
            [ngStyle]="{ 'color': isLevelNameEnabled(ran.LevelName) ? 'black' : '#3BB001' }"
            class="location__score"
            [tooltipStyleClass]="isLevelNameEnabled(ran.LevelName) ? 'default-tooltip' : 'green-tooltip'"
            pTooltip="{{ ran.Hover ? (ran.Hover | translate) : ('' | translate) }}">
            <i class="fal fa-minus-circle"></i> {{ ran.Rank }}/{{ ran.Total }}
          </span>
          <span *ngIf="ran.Category == 'lower' || ran.Category == ''"
            [ngStyle]="{ 'color': isLevelNameEnabled(ran.LevelName) ? 'black' : '#DE3545' }"
            class="location__score"
            [tooltipStyleClass]="isLevelNameEnabled(ran.LevelName) ? 'default-tooltip' : 'red-tooltip'"
            pTooltip="{{ ran.Hover ? (ran.Hover | translate) : ('' | translate) }}">
            <i class="fal fa-minus-circle"></i> {{ ran.Rank }}/{{ ran.Total }}
          </span>
        </li>
      </ul>
    </div>
    <div #container class="center-block kpi__meter"></div>
    <ng-content></ng-content>
  </div>
</div>
