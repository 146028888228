// `.env.ts` is generated by the `npm run env` command
import { env } from './.env';

export const environment = {
  production: true,
  version: env.npm_package_version + '-qa',
  appVersion: require('../../package.json').version,
  apiUrl: 'https://qapd.reflecx.io/app',
  serverUrl: '/api',
  apiVersion: '/v1',
  defaultLanguage: 'en-CA',
  hmr: false,
  logs: false,
  reflecxRoutePrefix: 'composer', // admin, '', admin
  reportingRoutePrefix: 'insights', // reporting, reporting, ''
  defaultAppRoute: 'insights', // reporting, datasource, globaldashboard
  Apps: { ReflecxApp: true, ReportingApp: true },
  baseLine: false,
  cacheDb: 'MyApp',
  engineUrl: `${(window as any).baseUrl}/responses/`,

  libraryPath: '/library/',
  FilePath: '/Uploads/',
  directorySeparator: '/',
  fromEmailAddress: ['survey@epanelprogram.toyotaexperience.ca'],
  dateFormat: 'LLL dd, yyyy, hh:mm:ss aa',
  signalRConfigured: true,
  signalRUrl: `${(window as any).baseUrl}/app/RealTimeNotificationHub`,
  loaderStyle: 'main',
  defaultMarketLanguage: 'en-CA',
  NotificationSetting: {
    notificationSessionTimeoutMinutes: 30,
    notificationUpdateInterval: 30000,
  },
  login: {
    isDefaultEnabled: false,
    defaultRoute: '/login/:id',
    defaultRouteValue: 'utility',
  },
  printServerUrl: 'https://qapd.reflecx.io/print',
  isPrintAllowed: true,
};

// IdentityServer Angular Client Configuration
// export const identityServerAngularConfig = {
//   authority: 'https://qapd.reflecx.io/accounts',
//   client_id: 'local-angular',
//   redirect_uri: 'https://qapd.reflecx.io/portal/callback.html',
//   response_type: 'code',
//   scope: 'openid profile email api1 offline_access',
//   post_logout_redirect_uri: 'https://qapd.reflecx.io/portal/signout-callback.html',
//   silent_redirect_uri: 'https://qapd.reflecx.io/portal/renew-callback.html',
//   accessTokenExpiringNotificationTime: 300,
//   automaticSilentRenew: true,
//   clockSkew: 86400
// };

export const identityServerAngularConfig = Object.assign({}, (window as any).bootstrapSettings);

// IdentityServer TCI SSO Configuration
export const identityServerTCIConfig = {
  authority: 'https://qapd.reflecx.io/accounts',
  client_id: 'tci.rop.client',
  scope: 'api1',
  secret: 'secret',
};

// IdentityServer SSO Configuration
export const identityServerSSOConfig = {
  authority: (window as any).baseUrl + '/accounts',
  client_id: 'external.saml',
  redirect_uri: (window as any).baseUrl + '/portal/extcallback.html',
  response_type: 'code',
  scope: 'openid profile email api1 offline_access',
  post_logout_redirect_uri: (window as any).baseUrl + '/portal/ext-signout-callback',
  silent_redirect_uri: (window as any).baseUrl + '/portal/silent-callback.html',
  accessTokenExpiringNotificationTime: 300,
  automaticSilentRenew: true,
  clockSkew: 86400,
};
export const apiURL = 'https://inteliq.io/vocdemoapi';
export const staticUrl = 'https://clearviewprodemo.com';
////// Q and A details
// export const QNAAPIURL = "http://nlpdev.ibex.co:8008:8015/qa_service/"
export const QNAAPIURL = 'http://172.21.3.198:8015/qa_service/';

export const pipelineData = {
  Comment_Type: 'social_media',
  TopNCategories: '3',
  ModelFilesPath: '/data/E_Drive/All_Models/cross_lingual_models',
  BatchSizetoProcess: '1000',
  ClientCode: 'Acura',
  IS_DEBUG: 'False',
  Client_Name_Masking: 'Toyota',
};
