<div class="card-xl">
  <div class="filter" (gfClickOutside)="toggleMenu($event, '')">
    <ul>
      <ng-container *ngFor="let config of gbfService.current_configs">
        <app-gbf-list
          *ngIf="config.type === 'list'"
          [config]="config"
          pindex="1"
          [hidden]="config.display === false"
          [class]="config.rootClass"
          (onclick)="toggleMenu($event, config)"
          [values]="config?.settings?.remote ? gbfApiService[config?.property] : config.values"
          (offmenu)="toggleMenu($event, '')"
          [active]="activeState[config.name]"
        >
        </app-gbf-list>
        <app-gbf-tree
          *ngIf="config.type === 'tree' && config.display !== false"
          [config]="config"
          [class]="config.rootClass"
          (offmenu)="toggleMenu($event, '')"
          (onclick)="toggleMenu($event, config)"
          [values]="config.values"
          [active]="activeState[config.name]"
        >
        </app-gbf-tree>
        <app-gbf-calendar
          *ngIf="config.type === 'calendar' && config.display !== false"
          [config]="config"
          [class]="config.rootClass"
          (click)="toggleMenu($event, config)"
          [values]="config.values"
          (offmenu)="toggleMenu($event, '')"
          [active]="activeState[config.name]"
        >
        </app-gbf-calendar>
        <app-gbf-slider
          *ngIf="config.type === 'slider' && config.display !== false"
          [config]="config"
          [class]="config.rootClass"
          (click)="toggleMenu($event, config)"
          [values]="config.values"
          [active]="activeState[config.name]"
        >
        </app-gbf-slider>
        <li class="filter__item flexbtn" *ngIf="config.name === 'applybutton'">
          <label class="filter__label">Actions</label>
          <a class="btn btn--filter" (click)="applyFilter($event)"> {{ 'apply_filters_button.title' | translate }} </a>
        </li>
        <li class="filter__item flexbtn" *ngIf="config.name === 'applybutton'">
          <app-gbf-dynamic-filter></app-gbf-dynamic-filter>
        </li>
        <li class="filter__item flexbreak" *ngIf="config.name === 'break'"></li>
      </ng-container>
    </ul>
  </div>
</div>
<div class="card-xl">
  <p-tabMenu [activeItem]="getActiveItem()" [model]="gbfService.current_tabs" styleClass="'tabs'"></p-tabMenu>
</div>
