import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HeaderTabService } from '@app/shared/services/header-tab.service';
import { Router, NavigationStart } from '@angular/router';
import { Subscription, fromEvent } from 'rxjs';
import { HeaderItems } from '@app/core/types/headerItems';
import { TourService } from '@tour-custom';
import { SlideInOutAnimation } from '@clearviewshared/animation/SlideInOutAnimation';
import { debounceTime } from 'rxjs/operators';
import { NgxPermissionsService, NgxPermissionsObject } from 'ngx-permissions';
import { Identifiers } from '@app/shared/services/app.config.type';
import { environment } from '@env/environment';

@Component({
  selector: 'appc-header-tabs',
  templateUrl: './header-tabs.component.html',
  styleUrls: ['./header-tabs.component.scss'],
  animations: [SlideInOutAnimation],
})
export class HeaderTabsComponent implements OnInit, OnDestroy {
  windowSize = window.innerWidth;
  @ViewChild('nav') nav: any;
  animationState = 'out';
  tabItems: HeaderItems[];
  headerTabServiceSubscribtion: Subscription;
  surveyType: number = 2;
  permissions: NgxPermissionsObject;
  qureyparamsForSurvey: any;
  constructor(
    public headerTabService: HeaderTabService,
    private router: Router,
    public _ngxPermission: NgxPermissionsService,
    private tourService: TourService
  ) {
    router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        setTimeout(() => {
          this.headerTabService.setItems(router.url.split('/')[1], this.headerTabService.activeItem);
          this.headerTabService.setActiveItems(this.headerTabService.activeItem);
          this.setActiveItemForBrowserBack(this.headerTabService.activeItem);
        }, 3000);
      }
    });

    fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe((event) => {
        this.windowSize = window.innerWidth;
      });
    this.permissions = _ngxPermission.getPermissions();
    this.surveyType = this.permissions[Identifiers.SurveyCoreFilter] ? 2 : 1;
    this.qureyparamsForSurvey = { adCo: this.surveyType };
    this.headerTabService.headerReportModule.subscribe((isReport) => {
      if (isReport) {
        this.tabItems = this.headerTabService.getHeaderTabs();
        const index = this.tabItems.findIndex((x) => {
          return x.url === '/insights/reports';
        });
        this.tabItems.forEach((x, i, arr) => {
          arr[i].active = false;
        });
        this.tabItems[index].active = true;
        localStorage.setItem('gbf_url', '/insights/reports');
        this.headerTabService.setHeaderItems(this.tabItems);
        this.headerTabService.headerReportModule.next(false);
      }
    });
    this.headerTabService.headerReportActiveRemove.subscribe((isActivationRemove) => {
      if (isActivationRemove) {
        this.tabItems = this.headerTabService.getHeaderTabs();
        this.tabItems.forEach((x, i, arr) => {
          arr[i].active = false;
        });
        this.headerTabService.headerReportActiveRemove.next(false);
      }
    });
  }

  toggleShowDiv(e: any) {
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
  }
  ngOnInit() {
    this.tabItems = this.headerTabService.getHeaderTabs();
    if (this.tabItems.length == 1) {
      if (this.tabItems[0].url == '/composer/survey') {
        this.onClick(this.tabItems[0].url);
      } else if (this.tabItems[0].url == '/composer/survey?adCo=1') {
        this.onClick(this.tabItems[0].url);
      } else if (this.tabItems[0].url == '/composer/survey?adCo=2') {
        this.onClick(this.tabItems[0].url);
      } else if (this.router.url == '/composer/survey?adCo=3') {
        this.onClick(this.tabItems[0].url);
      }
    }
    var url = localStorage.getItem('gbf_url');
    if (url === null) {
      let rArr = this.router.url.split('/');
      if (rArr.length > 3) {
        rArr.pop();
      }
      url = rArr.join('/');
      //localStorage.setItem('gbf_url', url);
    }

    if (url) {
      if (
        !this.router.url.includes(url) ||
        url === '/' + environment.reportingRoutePrefix ||
        url === '/' + environment.reflecxRoutePrefix ||
        url === '/' + environment.defaultAppRoute
      ) {
        let rArr = this.router.url.split('/');
        if (rArr.length > 3) {
          rArr.pop();
        }
        url = rArr.join('/');
      }

      let index = this.tabItems.findIndex((x) => {
        return url.includes(x.url);
        // return x.url === url;
      });

      if (index === -1) {
        index = 0;
      }

      this.tabItems.forEach((x, i, arr) => {
        arr[i].active = false;
      });

      if (this.tabItems[index]) {
        this.tabItems[index].active = true;
      }

      localStorage.setItem('gbf_url', url);
    }

    //     this.headerTabServiceSubscribtion = this.headerTabService
    //       .getHeaderItems()
    //       .subscribe(tabs => {
    //         this.tabItems = tabs;
    // console.log('tab',this.tabItems);
    //       });
  }
  checkActiveTab(item: any) {
    var alltabs: any[] = [];
    this.tabItems.forEach((x: any) => alltabs.push(x.module));
    var name = alltabs.filter((element) => location.pathname.split('/').includes(element))[0];
    if (item.module === name) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    if (this.headerTabServiceSubscribtion) {
      this.headerTabServiceSubscribtion.unsubscribe();
    }
  }

  onClick(url: string) {
    const index = this.tabItems.findIndex((x) => {
      return x.url === url;
    });
    this.tabItems.forEach((x, i, arr) => {
      arr[i].active = false;
    });
    this.tabItems[index].active = true;
    // this.router.navigateByUrl(`${url}`,{ adCo: 2 });
    if (url == '/composer/survey') {
      // this.router.navigate([`${url}`, { adCo: 2 }]);
      this.router.navigate([`${url}`], { queryParams: { adCo: this.surveyType } });
    } else {
      this.router.navigate([`${url}`]);
    }
    localStorage.setItem('gbf_url', url);
    this.headerTabService.setHeaderItems(this.tabItems);
    this.animationState = 'out';
  }

  private setActiveItemForBrowserBack(module: string) {
    const headersObject = {
      'Global Dashboard': 'Dashboard',
      'Hot Alerts': 'Alerts',
      Reporting: 'Reports',
      VOC: 'Text Analytics',
      'Social Media': 'Reputation Management',
    };
    if (this.tabItems) {
      this.tabItems.map((x) => {
        x.active = false;
        if ((headersObject[module] || module) === x.name) {
          x.active = true;
          localStorage.setItem('gbf_url', x.url);
        }
      });
    }
  }
}
