<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <div widgetArea style="text-align: right" [attr.data-can-render]="canRender">
    <div #reasonescalations style="height: 250px"></div>
    <br />
    <div class="widget__content">
      <table class="table-reporting">
        <tbody>
          <tr>
            <th style="font-weight: bold; width: 70%; color: #000">{{'Alert_escalations.top_5_issues' | translate}}</th>
            <th style="font-weight: bold; color: #000">{{'National' | translate}}</th>
          </tr>
          <tr *ngFor="let resolution of resolutions">
            <td style="width: 70%">
              <a
                (click)="escalationsBreakdown(resolution);"
                style="cursor: pointer"
                [style.font-weight]="resolution.isActive ? 'bold' : 'normal'"
                >{{ getTranslatedEscalation(resolution.Title) }}</a
              >
            </td>
            <td>
              <!-- <span style="font-weight: bold; margin-right:10px;">{{resolution.Count }}</span> <span
                style="color:#969dab;">({{resolution.Percentage.toFixed(0) }}%)</span> -->
              <span style="font-weight: bold; margin-right: 10px">{{resolution.Count }}</span>
              <span style="color: #969dab">({{ percentTrim(resolution.Percentage) | number }}%)</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</app-widget-dynamic>
