import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  SimpleChanges,
  OnDestroy,
  AfterViewInit,
  Input,
} from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as HighCharts from 'highcharts';
import { CacheService } from '@app/shared/services/cache.service';
import { WidgetService } from '../widget.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { generateRecommendedActions } from '@clearviewshared/highchart/helperfunctions';
import { FiltersService } from '@app/shared/services/filters.service';
import { Router } from '@angular/router';
import { AuthenticationService, I18nService } from '@app/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { IRecommendedActionsResponse } from './types/IRecommendedActionsResponse';
import { IKPI } from '../widget-interface';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';

@Component({
  selector: 'recommended-actions-widget',
  templateUrl: './recommended-actions.html',
  styleUrls: ['./recommended-actions.scss'],
})
export class RecommendedActionsComponent extends WidgetBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('recommendedactions', { read: ElementRef, static: true }) recommendedactions: ElementRef;
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  ProgramSubscribtion: Subscription;
  FilterSubscribtion: Subscription;
  resolutions: any;
  data: IRecommendedActionsResponse;
  grid: any = [];
  filters: any;
  params: any;
  showdiv = true;
  kpiName: '';
  chartModel: any = {};
  chart: any;
  canRender = false;
  orgName = this.authService.getUser();
  IsAppButtonVisible = false;
  api: Subscription;
  lastNode: number;
  private subscriptions: Subscription[] = [];
  constructor(
    public widgetService: WidgetService,
    public router: Router,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public toastService: ToastService,
    private filterService: FiltersService,
    public gbfService: GlobalFilterService,
    private authService: AuthenticationService,
    public ngxPermission: NgxPermissionsService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService
  ) {
    super(gbfService, translateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.filters = this.filterService.getFilterObject();
    this.isAppButtonVisible();

    this.subscriptions.push(
      this.cacheService.for(this.widgetService.recommendedActionsUrl).subscribe((res: IRecommendedActionsResponse) => {
        if (res) {
          this.data = res;
          this.canRender = true;
        }
      })
    );

    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
      this.subscriptions.push(
        this.gbfService.gbfOnApplyFilter.subscribe(() => {
          this.loadWidget();
        })
      );
    }
    this.lastNode = this.filterService.getLastNode();
  }
  ngAfterViewInit() {
    this.loadWidget();
  }
  isAppButtonVisible() {
    let permission = this.ngxPermission.getPermissions();
    if (permission && permission[Permission.GLOBALDASHBOARD_RECOMMENDED_ACTION_AP_BUTTON]) {
      this.IsAppButtonVisible = true;
    } else {
      this.IsAppButtonVisible = false;
    }
  }

  loadWidget() {
    this.canRender = false;
    this.widgetService.isAllLoaded = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getRecommendedActions({ apiConfig: this.apiConfig, extra: this.extra }).subscribe(
      (res: IRecommendedActionsResponse) => {
        if (res && res.Kpi && res.Kpi.length > 0) {
          this.showdiv = true;
          this.data = res;
          // this.kpiName = this.data.Kpi[0].KpiName;

          this.widgetService.isAllLoaded = true;
          this.canRender = true;
          this.gridUpdate(this.data.Kpi[0].KpiName);
          this.initData();
        } else {
          this.showdiv = false;
          this.data = null;
          this.grid = [];
          this.canRender = true;
        }
      },
      (error: any) => {
        this.canRender = false;
        this.widgetService.isAllLoaded = true;
      }
    );
  }
  createActionRoute(data: any) {
    this.router.navigate(['/insights/coach/create-action'], { queryParams: { dealerName: data.DealerName } });
  }
  orgSummaryRoute(data: any, filters: any) {
    this.params = {
      dealerCode: data.DealerCode,
      parent: data.parent,
      orglevel: filters.OrgLevel,
    };
    this.router.navigate(['/insights/reports/orgsummary'], {
      queryParams: { code: data.DealerCode, parent: data.parent, previewId: 2 },
    });
  }
  gridUpdate(kpiname: string) {
    if (this.data && this.data.KpisGrid) {
      kpiname = kpiname && kpiname != null && kpiname != '' ? kpiname.trim() : kpiname;
      this.data.KpisGrid.forEach((x: any) => {
        if (x.KpiName.toLowerCase() === kpiname.toLowerCase()) {
          this.grid = x;
        }
      });
    }
  }
  initData() {
    this.chartModel = {};

    if (this.data && this.data.Kpi && this.data.Kpi.length > 0) {
      const category = this.data.Kpi.map((value: IKPI, index: number, array: IKPI[]) => {
        // return value.KpiName;
        return this.GetSurveyKpiNameTranslation(value.KpiName);
      });

      const sd = this.data.Kpi.map((value: IKPI, index: number, array: IKPI[]) => {
        return {
          // name: value.KpiName,
          name: this.GetSurveyKpiNameTranslation(value.KpiName),
          y: parseFloat(value.Score.toFixed(1)),
        };
      });

      const seriesData: any = [
        {
          name: this.translateService.instant('Recommended Actions'),
          colorByPoint: true,
          data: sd,
        },
      ];

      this.chartModel = generateRecommendedActions(
        seriesData,
        category,
        (event: any) => {
          this.kpiName = event.point.name;
          this.gridUpdate(this.kpiName);
        },
        this.i18nService
      );
      this.chart = new HighCharts.Chart(this.recommendedactions.nativeElement, this.chartModel);
    } else {
      this.chartModel = [];
      this.chartModel = generateRecommendedActions(
        [],
        [],
        (event: any) => {
          this.kpiName = event.point.name;
          this.gridUpdate(this.kpiName);
        },
        this.i18nService
      );
      this.chart = new HighCharts.Chart(this.recommendedactions.nativeElement, this.chartModel);
    }

    this.canRender = true;
  }

  GetSurveyKpiNameTranslation(key: string) {
    return this.translationHelper.GetSurveyKpiNameTranslation(key);
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngOnDestroy() {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }
}
