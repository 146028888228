import { Component, OnInit, ViewChild, ElementRef, SimpleChanges, Input, OnDestroy } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import HighCharts from '@clearviewshared/highchart/highchart';
import { CacheService } from '@app/shared/services/cache.service';
import { WidgetService } from '../widget.service';
import { ReasonsEscalationsService } from './reasons-escalations.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { generateReasonsEscalations } from '@clearviewshared/highchart/helperfunctions';
import { FiltersService } from '@app/shared/services/filters.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { IReasonsEscalationsResponse } from './types/IReasonsEscalationsResponse';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';

@Component({
  selector: 'reasons-escalations-widget',
  templateUrl: './reasons-escalations.html',
  styleUrls: ['./reasons-escalations.scss'],
})
export class ReasonsEscalationsComponent extends WidgetBase implements OnDestroy, OnInit {
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @ViewChild('reasonescalations', { read: ElementRef, static: true }) reasonescalations: ElementRef;
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  ProgramSubscribtion: Subscription;
  FilterSubscribtion: Subscription;
  resolutions: any[];
  data: Array<IReasonsEscalationsResponse>;
  chartModel: any = {};
  chart: any;
  canRender = false;
  api: Subscription;
  category: string[] = [
    this.translateService.instant('Alert_escalations.dealer'),
    this.translateService.instant('Alert_escalations.corporate'),
  ];

  private subscriptions: Subscription[] = [];
  constructor(
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    private hotAlertEfficiencyService: ReasonsEscalationsService,
    public toastService: ToastService,
    private filterService: FiltersService,
    private appConfig: AppConfigurationService,
    public gbfService: GlobalFilterService,
    private translationHelper: TranslationHelperService
  ) {
    super(gbfService, translateService);
    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }
      this.secondsCounter = interval(widgetThresholdVal);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadWidget();
    this.subscriptions.push(
      this.cacheService
        .for(this.widgetService.reasonsEscalationsUrl)
        .subscribe((res: Array<IReasonsEscalationsResponse>) => {
          if (res) {
            this.data = res;
            this.initData();
            this.canRender = true;
          }
        })
    );
    this.subscriptions.push(
      this.widgetService.update.subscribe(() => {
        this.loadWidget();
      })
    );
  }

  loadWidget() {
    this.canRender = false;
    this.widgetService.isAllLoaded = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getReasonsEscalations({ apiConfig: this.apiConfig }).subscribe(
      (res: Array<IReasonsEscalationsResponse>) => {
        if (res) {
          this.data = res;
          this.initData();
          this.widgetService.isAllLoaded = true;
          this.canRender = true;
        }
      },
      (error: any) => {
        this.canRender = false;
        this.widgetService.isAllLoaded = true;
      }
    );
  }

  initData() {
    this.chartModel = {};

    if (this.data && this.data.length > 0) {
      const seriesData: any = [
        {
          name: this.translateService.instant('Alert_escalations.title'),
          colorByPoint: true,
          data: [
            {
              name: this.translateService.instant('Alert_escalations.dealer'),
              y: this.data[0].Dealer,
            },
            {
              name: this.translateService.instant('Alert_escalations.corporate'),
              y: this.data[0].Corporate,
            },
          ],
        },
      ];

      this.chartModel = generateReasonsEscalations(seriesData, this.category);
      this.chart = HighCharts['Reflecx'].createOrUpdate(
        this.chart,
        this.reasonescalations.nativeElement,
        this.chartModel
      );
    }

    // let filter = this.filterService.getFilterObject();
    this.resolutions = this.data[0].ReasonsEscalationsData;
    this.canRender = true;
  }

  escalationsBreakdown(res: any) {
    if (this.data != null && this.data.length > 0) {
      const breakdownEscalation = this.data[0].ReasonsEscalationsBreakdownData;
      if (breakdownEscalation != null) {
        const selected = breakdownEscalation.find((x: { Title: any }) => x.Title === res.Title);

        if (selected != null) {
          const seriesData: any = [
            {
              // name: this.translateService.instant('Alert_escalations.title'),
              name: selected.Title,
              colorByPoint: true,
              data: [
                {
                  name: this.translateService.instant('Alert_escalations.dealer'),
                  y: selected.Dealer,
                },
                {
                  name: this.translateService.instant('Alert_escalations.corporate'),
                  y: selected.Corporate,
                },
              ],
            },
          ];

          this.chartModel = generateReasonsEscalations(seriesData, this.category);
          this.chart = HighCharts['Reflecx'].createOrUpdate(
            this.chart,
            this.reasonescalations.nativeElement,
            this.chartModel
          );

          // For Bold
          this.resolutions.map((data) => {
            if (data.Title === res.Title) {
              if (data.isActive) {
                data.isActive = false;
                this.initData();
              } else {
                data.isActive = true;
              }
            } else {
              data.isActive = false;
            }
          });
        }
      }
    }
  }

  getTranslatedEscalation(key: string) {
    return this.translationHelper.GetHotalertEscalationTranslation(key);
  }
  percentTrim(val: number): string {
    let result = '0';
    const valArr = val.toString().split('.');
    if (valArr.length > 1) {
      result = valArr[0].toString();
    } else {
      result = valArr[0].toString();
    }

    return result;
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.data) {
    //   this.chart = HighCharts['Reflecx'].createOrUpdate(this.chart,this.container.nativeElement, changes.data.currentValue);
    //   this.canRender = true;
    // }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }
}
