<app-widget-dynamic
  [removeWidgetClass]="removeWidgetClass"
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node widget-node__print" *ngIf="allowDropDownShow">
      <p-dropdown
        [autoZIndex]="false"
        class="cba-ctrl"
        [options]="kpiNames"
        placeholder="{{'Overall' | translate }}"
        (onChange)="onKPIChange($event)"
        [(ngModel)]="name"
        [style]="{'width':'200px'}"
      >
      </p-dropdown>
    </div>
    <div class="widget-node widget-node__print" *ngIf="allowDropDownShow">
      <p-dropdown
        [autoZIndex]="false"
        class="cba-ctrl"
        [options]="MonthsSelect"
        placeholder="{{'MTD' | translate }}"
        (onChange)="initData()"
        [(ngModel)]="MonthOption"
        [style]="{'width':'200px'}"
      >
      </p-dropdown>
    </div>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <div widgetArea class="widget__content">
    <div class="widget__graph">
      <div class="nodata-wrapper" *ngIf="showsWidgets">
        <div class="nodata">
          <i class="nodata__icon fal fa-info-circle"></i>
          <p class="nodata__msg">{{ 'No Result Found' | translate }}</p>
        </div>
      </div>
      <div #containerPerTreWidget ></div>
    </div>
  </div>
</app-widget-dynamic>
