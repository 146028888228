import { Directive } from '@angular/core';
import { CancelEditableRow } from 'primeng/table';
import { CVPEditableRow } from './editableRow.directive';
import { CVPTableComponent } from '../table.component';
import { EditableRow } from 'primeng/table';

@Directive({
  selector: '[cvpCancelEditableRow]',
  providers: [CVPEditableRow],
})
export class CVPCancelEditableRow extends CancelEditableRow {
  constructor(public dt: CVPTableComponent, public editableRow: EditableRow) {
    super(dt, editableRow);
  }
}
