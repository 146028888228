import { Directive } from '@angular/core';
import { SaveEditableRow } from 'primeng/table';
import { CVPEditableRow } from './editableRow.directive';
import { CVPTableComponent } from '../table.component';
import { EditableRow } from 'primeng/table';

@Directive({
  selector: '[cvpSaveEditableRow]',
})
export class CVPSaveEditableRow extends SaveEditableRow {
  constructor(public dt: CVPTableComponent, public editableRow: EditableRow) {
    super(dt, editableRow);
  }
}
