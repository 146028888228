import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import HighCharts, { theme } from '@clearviewshared/highchart/highchart';
import { TranslateService } from '@ngx-translate/core';
import { generateKPI } from '@clearviewshared/highchart/helperfunctions';
import { Subscription, interval } from 'rxjs';
import { CacheService } from '@app/shared/services/cache.service';
import { WidgetService } from '../widget.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import WidgetBase from '../widegt.base';
import { I18nService } from '@app/core';
import { IKpisResponse } from './types/IKpisResponse';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment-timezone';
import {
  DynamicFilters,
  FilterTypes,
  GlobalFilterRouter,
} from '@app/shared/module/global-filters/global-filter-router';
@Component({
  selector: 'kpi-meter',
  templateUrl: './kpi-meter.component.html',
  styleUrls: ['./kpi-meter.component.scss'],
})
export class KpiMeterComponent extends WidgetBase implements OnInit, OnDestroy {
  @Input() data: any;
  @Input() rank: any;
  @ViewChild('container', { static: true }) container: ElementRef;
  Highcharts = HighCharts;
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  rankKpi: any;
  kpiData: any[] = [];
  canRender = false;
  dialsLength = 0;
  api: Subscription;
  private subscriptions: Subscription[] = [];
  user: any = {};
  filter: any;
  constructor(
    public toastService: ToastService,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public i18nService: I18nService,
    private appConfig: AppConfigurationService,
    public gbfService: GlobalFilterService,
    private gbfRouter: GlobalFilterRouter,
    private routeData: ActivatedRoute
  ) {
    super(gbfService, translateService);
    HighCharts.setOptions(theme);
    this.user = this.gbfService.authService.getUser();
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.kpiUrl).subscribe((res: Array<IKpisResponse>) => {
        if (res) {
          this.generateKPIChart(res);
        }
      })
    );
    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
    }

    // this.secondsCounterSub = this.secondsCounter.subscribe((n) => {
    //   // this.loadWidget();
    // });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getKpis({ apiConfig: this.apiConfig }).subscribe({
      next: (res: Array<IKpisResponse>) => {
        if (res) {
          this.generateKPIChart(res);
          this.canRender = true;
          this.widgetService.isAllLoaded = true;
        }
      },
      error: (error: any) => {
        this.toastService.error('Failed.');
        this.canRender = true;
        this.widgetService.isAllLoaded = true;
      },
      complete: () => {
        this.canRender = true;
      },
    });
  }
  private getDialsLength(dialsMeta: any) {
    let dialsLength = 0;
    for (const dialMeta of dialsMeta) {
      if (dialMeta.Label.length > 0) {
        try {
          if (dialMeta.Active && dialMeta.IsDashboardKPI) {
            dialsLength += 1;
          }
        } catch (e) {}
      }
    }
    this.dialsLength = dialsLength;
    return this.dialsLength;
  }
  private generateKPIChart(res: any) {
    this.rankKpi = res;
    this.kpiData = [];
    if (res) {
      const dialsLength = this.getDialsLength(res);
      this.dialsLength = dialsLength;
      for (const oo of res) {
        if (oo.Label.length > 0) {
          try {
            oo.Label = this.translateService.instant(oo.Label);
            const target = this.translateService.instant('Target');
            if (oo.Active && oo.IsDashboardKPI) {
              this.kpiData.push({
                data: generateKPI(
                  `${oo.Label}`,
                  parseFloat((oo.Weight * 100) / 100 + '').toFixed(1),
                  parseFloat((oo.Target * 100) / 100 + '').toFixed(1),
                  'green',
                  this.i18nService,
                  oo.IsTarget,
                  target,
                  dialsLength,
                  oo.TargetLabel,
                  oo.LowerIsBetter
                ),
                rank: oo.KpiRanks,
                bottomDetails: oo.BottomDetails,
              });
            }
          } catch (e) {}
        }
      }
      this.kpiData.forEach((KD) => {
        if (this.user && this.user.DefaultLanguage) {
          const language = this.user.DefaultLanguage.split('-');
          if (language[0] == 'en') {
            KD.data.title.text = KD.data.title.text.replace(new RegExp(`,`, 'g'), '');
          }
        }
      });
    }
  }

  route(e: any, rowData: any) {
    const viewableParams: Array<DynamicFilters> = [];

    if (rowData.Enable_click_through) {
      const [cStart, cEnd] = this.gbfService.getCalenderRange();
      const endMoment = moment(cEnd);
      const startMoment = moment(cStart);

      const endMonth = endMoment.get('month') + 1;
      const endYear = endMoment.get('year');
      const startMonth = startMoment.get('month') + 1;
      const startYear = startMoment.get('year');
      let filter = {
        Parent: '',
        StartDate: cStart,
        EndDate: cEnd,
        description: '',
        less: endMonth,
        greater: startMonth,
        StartYear: startYear,
        EndYear: endYear,
        year: endYear,
        url_param: '',
        Description: '',
      };
      if (rowData.Label === 'no_of_qp_survey') {
        filter.Parent = 'Total number of survey responses';
        filter.Description = 'survey_response';
        filter.description = 'Total number of survey responses';
        this.setFilter(filter, true);
      } else if (rowData.Label === 'no_of_eligible_events') {
        filter.Parent = '# of Total Eligible Records in Queue';
        filter.Description = 'eligible_records_in_queue';
        filter.description = '# of Total Eligible Records in Queue';
        this.setFilter(filter, true);

        const _params = this.getFilter();
        localStorage.setItem('currentDisposition', filter.description);
        localStorage.setItem('clickThrough', JSON.stringify(_params));
      }

      viewableParams.push({
        key: 'calendar',
        name: 'Calendar',
        value: [moment(cStart).format('MMM D, YYYY'), moment(cEnd).format('MMM D, YYYY')],
        type: FilterTypes.Global,
      });
      //viewableParams.push({ key: '', name: 'Description', value: filter.Description, type: FilterTypes.Dynamic });
      this.gbfRouter.navigate([rowData.BaseUrl], {}, { key: rowData.BaseUrl, filters: viewableParams }, () => {
        filter.url_param = 'all';
        this.setFilter(filter, true);
      });
      return;
    }
  }
  setFilter(filter: any, drillDown: boolean) {
    this.filter = {
      ReportingModel: this.gbfService.getFilterObject(),
      ClickThroughFilter: filter,
      isDrillDown: drillDown,
    };
  }

  getFilter(isV2Call: boolean = false) {
    const npsFilter = this.routeData.snapshot.queryParams['nps'];
    if (npsFilter) {
      this.filter = undefined;
    }
    if (this.filter) {
      this.filter.ReportingModel = this.gbfService.getFilterObject();
      if (isV2Call && 'StartDate' in this.filter.ClickThroughFilter && 'EndDate' in this.filter.ClickThroughFilter) {
        this.filter.ReportingModel.StartDate = this.filter.ClickThroughFilter.StartDate;
        this.filter.ReportingModel.EndDate = this.filter.ClickThroughFilter.EndDate;
      }
    }
    return (
      this.filter || {
        ReportingModel: this.gbfService.getFilterObject(),
        ClickThroughFilter: null,
        isDrillDown: false,
      }
    );
  }
}
