import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Input,
  AfterViewInit,
} from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import HighCharts from '@clearviewshared/highchart/highchart';
import { WidgetService } from '../widget.service';
import { ISentimentCount, SentimentCountStatus } from '../widget-interface';
import { ToastService } from '@app/shared/services/toast/toast.service';
import {
  drawPieChartDot,
  generateSentimentCount,
  generateSentimentCountV2,
  generateStaticCountV2,
} from '@clearviewshared/highchart/helperfunctions';
import { I18nService } from '@app/core';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { GlobalFilterApiService } from '@app/shared/module/global-filters/global-filters.api.service';
import { of } from 'rxjs';

const data = of([
  [
    {
      Key: 'Positive',
      Title: 'Positive',
      Count: 18031.0,
      TotalCount: 0.0,
      Percent: 87.1,
      Order: 1.0,
    },
  ],
]);

@Component({
  selector: 'app-donut-static-widget',
  templateUrl: './donut-static-count.html',
})
export class DonutStaticComponent implements AfterViewInit, OnDestroy {
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @ViewChild('element', { read: ElementRef }) element: ElementRef;
  @Input() module = 'voc';
  @Input() title = 'Customer Health Score';
  @Input() score = '71.2';
  @Input() isVOC = false;
  noResult = false;
  canRender = false;
  @Input() colors = ['green', 'orange', 'red', 'gray'];
  data: ISentimentCount[];
  dataModel: ISentimentCount[] = [];
  chartOptions: any = {};
  alertResoulation = 0;
  chart: any;
  name: string;
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  allSentiment: boolean;
  sentiment: any;
  isAspectCategorySelected = true;
  ac: any;
  api: Subscription;
  isToggleDisabled = false;
  private subscriptions: Subscription[] = [];

  constructor(
    public widgetService: WidgetService,
    public translateService: TranslateService,
    public toastService: ToastService,
    public i18nService: I18nService,
    public gbfService: GlobalFilterService,
    public gbfapiservice: GlobalFilterApiService
  ) {}

  ngAfterViewInit(): void {
    this.loadWidget();
  }

  ngOnInit() {}

  loadWidget() {
    this.api = data.subscribe((res: any) => {
      this.data = res[0];
      this.canRender = true;
      this.initData();
    });
  }

  initData() {
    this.chartOptions = {};
    this.dataModel = [];
    if (this.data) {
      const IsAllZero = this.data.find((x: any) => {
        return x.Count > 0;
      });
      if (!IsAllZero) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }
      let totalCout = 0;
      const charData: any[] = [];
      this.data.forEach((data: ISentimentCount, index: number) => {
        totalCout += data.Count;
      });

      this.data.map((itm, i) => {
        charData.push({
          name: itm.Title,
          nameText: itm.Title,
          data: itm.Count,
          y: itm.Count,
          Percent: itm.Percent,
          order: itm.Order,
          events: {
            legendItemClick: (e: any) => {
              if (e.target.visible) {
                totalCout -= e.target.data;
                totalCount2 = totalCout;
              } else {
                totalCout += e.target.data;
                totalCount2 = totalCout;
              }
            },
          },
        });
        return itm;
      });

      charData.sort((a, b) => (a.order > b.order ? 1 : -1));

      const seriesData: any = [
        {
          type: 'pie',
          colors: this.colors,
          size: '100%',
          margin: 0,
          padding: 0,
          innerSize: '85%',
          tooltip: {
            pointFormat: '<b>{point.y}</b>',
            borderWidth: 0,
            backgroundColor: 'none',
            shadow: false,
            useHTML: true,
          },
          dataLabels: {
            enabled: false,
          },
          showInLegend: false,
          name: this.translateService.instant('total.count'),
          data: charData,
        },
      ];

      const plotOpt = {
        cursor: 'pointer',
      };

      let totalCount2 = parseFloat(totalCout.toFixed(1));
      if (this.element && this.element.nativeElement) {
        if (!this.noResult) {
          this.chartOptions = generateStaticCountV2(seriesData, plotOpt, this.score, this.i18nService, this.title);
          this.chart = HighCharts['Reflecx'].createOrUpdate(this.chart, this.element.nativeElement, this.chartOptions);
        } else {
          this.chartOptions = generateStaticCountV2([], plotOpt, this.score, this.i18nService, this.title);
          this.chart = HighCharts['Reflecx'].createOrUpdate(this.chart, this.element.nativeElement, this.chartOptions);
          // drawPieChartDot(this.chart);
        }
      }
    }
  }

  calcPrePercent(val: number, totalVal: number, decPlace: number) {
    let result = '0';
    const valArr = ((val / totalVal) * 100).toString().split('.');
    if (valArr.length > 1) {
      if (valArr[1].length > 0) {
        result = valArr[0].toString() + '.' + valArr[1].substring(0, decPlace);
      }
    } else {
      result = valArr[0].toString();
    }

    return result;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });
  }
}
