<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
  [widgetTitleToolTip]="widgetTitleToolTip"
>
<ng-container widgetHeader>

  <div
    class="toggle widget-node widget-node__print"
    [attr.disabled]="isAspectCategorySelected"
    (click)="disableCtrl()"
  >
  <span  pInputText pTooltip="{{'Graph displays % of No response to QP Q6/FRFT by vehicle model' | translate}}" tooltipPosition="top" style="border: none;">
    <i class="fal fa-question-circle icon-question"></i>
  </span>
  <cvc-inputSwitch
  onLabel="{{'Current Models' | translate}}"
  offLabel="{{'Other Models' | translate}}"
  [disabled]="isAspectCategorySelected || !canRender|| isToggleDisabled"
  (onChange)="toggleChange()"
  [(ngModel)]="selecteditem"
>
</cvc-inputSwitch>
  </div>
  <div class="widget-node">
    <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
  </div>
</ng-container>
<div widgetArea class="widget__content">
  <div class="widget__graph">
    <div class="nodata-wrapper" *ngIf="showsWidgets">
      <div class="nodata">
        <i class="nodata__icon fal fa-info-circle"></i>
        <p class="nodata__msg">{{ 'No Result Found' | translate }}</p>
      </div>
    </div>
    <div #containerFrftModelQ6no ></div>
  </div>
</div>
</app-widget-dynamic>
