<div class="kpi">
  <div class="kpi__inner">
    <div class="kpi__location">
      <ul *ngFor="let ran of rank; let index = index">
        <li class="location--positive" *ngIf="index == 0">
          <!-- <label class="location__label">National:</label>
                    <span class="location__score"><i class="fal fa-minus-circle"></i> 2/5</span> -->
          <label class="location__label">{{ ran.LevelName }}</label>
          <span class="location__score"><i class="fal fa-minus-circle"></i> {{ ran.Rank }}/{{ ran.Total }}</span>
        </li>
        <li class="location--negative" *ngIf="index == 1">
          <!-- <label class="location__label">Reigon:</label>
                    <span class="location__score"><i class="fal fa-minus-circle"></i> 27/35</span> -->
          <label class="location__label">{{ 'Region.title' | translate }}</label>
          <span class="location__score"><i class="fal fa-minus-circle"></i> {{ ran.Rank }}/{{ ran.Total }}</span>
        </li>
        <li class="location--positive" *ngIf="index == 2">
          <!-- <label class="location__label">Reigon:</label>
                    <span class="location__score"><i class="fal fa-minus-circle"></i> 27/35</span> -->
          <label class="location__label">{{ 'Dealer Type' | translate }}</label>
          <span class="location__score"><i class="fal fa-minus-circle"></i> {{ ran.Rank }}/{{ ran.Total }}</span>
        </li>
      </ul>
    </div><div  #container class="center-block kpi__meter"></div>
    <div>
      <label>MTD QP Overall Satisfaction Score 945.2</label>
    </div>
  </div>
</div>
