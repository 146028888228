import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { DataTableBodyCellComponent } from '@swimlane/ngx-datatable';

export type TreeStatus = 'collapsed' | 'expanded' | 'loading' | 'disabled';

@Component({
  selector: 'rdatatable-body-list-cell',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="datatable-body-cell-label">
      <ng-template
        #cellTemplate
        *ngIf="column.cellTemplate"
        [ngTemplateOutlet]="column.cellTemplate"
        [ngTemplateOutletContext]="cellContext"
      >
      </ng-template>
    </div>
  `,
})
export class RDataTableBodyListCellComponent extends DataTableBodyCellComponent implements OnInit {
  ngOnInit(): void {}
}
