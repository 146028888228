import { Component, OnInit, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Subscription, interval, Observable, fromEvent } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import HighCharts from '@clearviewshared/highchart/highchart';
import { WidgetService } from '../widget.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ITotalAlerts, AlertStatus } from '../widget-interface';
import { ToastService } from '@app/shared/services/toast/toast.service';
import {
  generateTotalAlerts,
  generateNpsScoreV2,
  generateTotalAlertsV2,
  drawPieChartDot,
} from '@clearviewshared/highchart/helperfunctions';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import { debounceTime } from 'rxjs/operators';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { I18nService } from '@app/core';
import { ITotalAlertResultResponse, ITotalAlertsResponse } from './types/ITotalAlertResultResponse';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
import { GbfBase } from '@app/shared/module/global-filters/shared/gbf-base';
import { environment } from '@env/environment';
@Component({
  selector: 'total-alerts-widget',
  templateUrl: './total-alerts.html',
  styleUrls: ['./total-alerts.component.scss'],
})
export class TotalAlertsComponent extends WidgetBase implements OnInit, OnDestroy {
  routerPrefix = '';
  _HighCharts = HighCharts;
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @ViewChild('containerTotalAlert', { read: ElementRef }) containerTotalAlert: ElementRef;
  canRender = false;
  noResult = false;
  data: ITotalAlertsResponse[] = [];
  data2: any;
  dataModel: ITotalAlertsResponse[] = [];
  topView: any[] = [];
  chartOptions: any = {};
  alertResolution = 0;
  chart: any;
  name: string;
  colors = ['green', 'yellow', 'orange', 'blue', 'red', 'gray'];
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  api: Subscription;
  clickThroughReport: boolean;
  permissions: NgxPermissionsObject;
  private subscriptions: Subscription[] = [];
  reportingRoutePrefix: any = environment.reportingRoutePrefix;
  isTotalIssuesWidget = false;
  hasPostivePerc = false;
  DrillDownEnabled = true;
  IsAncherActive: boolean = false;
  alertStatusCount: number = 0;
  color: string = 'green';
  user: any = {};
  constructor(
    private router: Router,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    public gbfService: GlobalFilterService,
    private cacheService: CacheService,
    public toastService: ToastService,
    private appConfig: AppConfigurationService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService,
    public ngxPermission: NgxPermissionsService // public widgetBase: WidgetBase
  ) {
    super(gbfService, translateService);
    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }
      this.secondsCounter = interval(widgetThresholdVal);
    }
    this.user = this.gbfService.authService.getUser();
    fromEvent(window, 'resize')
      .pipe(debounceTime(1500))
      .subscribe((event) => {
        if (!this.isTotalIssuesWidget) this.initChart();
        else this.initChart2();
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.permissions = this.ngxPermission.getPermissions();
    this.setPermissions();
    this.loadWidget();

    this.subscriptions.push(
      this.cacheService.for(this.widgetService.totalAlertsUrl).subscribe((res: ITotalAlertResultResponse) => {
        if (res) {
          this.data = res.Views ? res.Views : [];
          this.alertResolution = res.AlertResolution ? res.AlertResolution : 0;
          this.canRender = true;
          this.widgetService.isAllLoaded = true;
          let hasSettings = res.hasOwnProperty('Settings');
          if (hasSettings && res.Settings.type === 'total_issue_widget') {
            this.isTotalIssuesWidget = true;
            this.data2 = this.calcScoreAndPerc(res);
            this.initChart2();
          } else {
            this.topView = res.TopView;
            this.initChart();
          }
        }
      })
    );
    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
      this.subscriptions.push(
        this.gbfService.gbfOnApplyFilter.subscribe(() => {
          this.loadWidget();
        })
      );
    }
  }

  routeToAlertlist(key: any) {
    let eventName = this.eventName;
    if (eventName != '') {
      this.router.navigate(['/insights/alerts/list'], {
        queryParams: { status: AlertStatus[key] || 1, en: eventName },
      });
    } else {
      this.router.navigate(['/insights/alerts/list'], { queryParams: { status: AlertStatus[key] || 1 } });
    }
  }
  RedirectToAlertList() {
    if (this.permissions[Permission.REPORT_HOTALERT_LIST]) this.router.navigate(['/insights/alerts/list'], {});
  }
  paramsAlertStatus(key: any) {
    return AlertStatus[key] || 1;
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getTotalAlerts({ apiConfig: this.apiConfig }).subscribe({
      next: (res: ITotalAlertResultResponse) => {
        if (res) {
          this.data = res ? res.Views : [];
          this.alertResolution = res.AlertResolution ? res.AlertResolution : 0;
          this.canRender = true;
          this.widgetService.isAllLoaded = true;
          let hasSettings = res.hasOwnProperty('Settings');
          if (hasSettings && res.Settings.type === 'total_issue_widget') {
            this.isTotalIssuesWidget = true;
            this.data2 = this.calcScoreAndPerc(res);
            this.initChart2();
          } else {
            this.topView = res.TopView;
            this.initChart();
          }
          if (res.Settings.is_drilldown_enabled == true) {
            this.DrillDownEnabled = true;
          }
        }
      },
      complete: () => {
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      },
      error: () => {
        this.noResult = true;
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      },
    });
  }
  setPermissions() {
    this.clickThroughReport = this.permissions[Permission.REPORT_HOTALERT_LIST] ? true : false;
  }
  initChart() {
    this.chartOptions = {};
    this.dataModel = [];
    if (this.data) {
      const IsAllZero = this.data.find((x: any) => {
        return x.Count > 0;
      });
      if (!IsAllZero) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }
      let totalCout = 0;
      const charData: any[] = [];
      this.data.forEach((data: any, index: number) => {
        totalCout += data.Count;
      });
      this.data.map((itm, i) => {
        if (itm.Count !== 0) {
          itm.Percent = this.calcPrePercent(itm.Count, totalCout, 1);
        } else {
          itm.Percent = '0.0';
        }

        charData.push({
          // itm.Title
          name: this.GetHotalertStatusTranslation(itm.Key),
          nametext: itm.Key,
          namex: this.translateService.instant('hotalert_alert_count.label'),
          data: itm.Count,
          y: itm.Count,
          Percent: itm.Percent,
          order: itm.Order,
          events: {
            legendItemClick: (e: any) => {
              if (e.target.visible) {
                totalCout -= e.target.data;
                alertStatusCount = totalCout;
              } else {
                totalCout += e.target.data;
                alertStatusCount = totalCout;
              }
              // this.chart.update({
              //   title: {
              //     text: "<span class='pie-chart'><span class='chart__title'>KPI</span> <span class='chart__count'>" +
              //     kpiPercent + '%' +
              //       "</span>  <span class='chart__label'>Alert Count</span> <span class='chart__link'>" +
              //       totalCout +
              //       "</span></span>",
              //   },
              // });
            },
          },
        });

        return itm;
      });

      const seriesData: any = [
        {
          type: 'pie',
          size: '100%',
          innerSize: '85%',
          tooltip: {
            pointFormat: '<b>{point.y}</b>',
            borderWidth: 0,
            backgroundColor: 'none',
            shadow: false,
            useHTML: true,
          },
          dataLabels: {
            enabled: false,
          },
          showInLegend: false,
          name: this.translateService.instant('hotalert_alert_count.label'),
          data: charData,
        },
      ];

      charData.sort((a, b) => (a.order > b.order ? 1 : -1));

      const plotOpt = {
        cursor: 'pointer',
        point: {
          events: {
            click: (e: any, b: any, c: any) => {
              if (e.point.data > 0 && this.clickThroughReport) {
                this.router.navigate(['/insights/alerts/list'], {
                  queryParams: { status: AlertStatus[e.point.nametext] || 1 },
                });
              }
            },
          },
        },
      };
      const kpiPercent = this.alertResolution.toFixed(1);
      // const result = this.transformNumber(parseFloat(kpiPercent));
      // const tys = result.toString();
      let alertStatusCount = parseFloat(totalCout.toFixed(1));
      // tslint:disable-next-line:max-line-length
      // tslint:disable-next-line:max-line-length
      if (this.containerTotalAlert && this.containerTotalAlert.nativeElement && this._HighCharts) {
        const link = `/portal/${this.reportingRoutePrefix}/alerts/list`;
        if (!this.noResult) {
          this.chartOptions = generateTotalAlertsV2(
            seriesData,
            plotOpt,
            this.i18nService.transformNumber(parseFloat(kpiPercent)),
            `<a href=${link}>${alertStatusCount}</a>`,
            this.translateService.instant('hotalert_alert_count.label'),
            this.i18nService
          );
          this.chart = this._HighCharts['Reflecx'].createOrUpdate(
            this.chart,
            this.containerTotalAlert.nativeElement,
            this.chartOptions
          );
          //drawPieChartDot(this.chart);
        } else {
          // tslint:disable-next-line:max-line-length
          this.chartOptions = generateTotalAlertsV2(
            [],
            plotOpt,
            this.i18nService.transformNumber(parseFloat(kpiPercent)),
            `<a href=${link}>${alertStatusCount}</a>`,
            this.translateService.instant('hotalert_alert_count.label'),
            this.i18nService
          );
          this.chart = this._HighCharts['Reflecx'].createOrUpdate(
            this.chart,
            this.containerTotalAlert.nativeElement,
            this.chartOptions
          );
          //drawPieChartDot(this.chart);
        }
      }
    }
  }
  initChart2() {
    const title_plotLine = this.translateService.instant('Target');
    const title_yAxis = this.translateService.instant('Issue Rate (%)');
    let targetScoreNumber = Number(this.data2.targetScore.replace('%', ''));
    this.color = targetScoreNumber > this.data2.kpiPerc ? '#dc3545' : '#3bb001';
    this.chartOptions = {};
    this.dataModel = [];
    this.chartOptions = {
      chart: {
        type: 'solidgauge',
        height: '130px',
        margin: [10, 0, 0, 0],
        backgroundColor: 'transparent',
      },
      credits: { enabled: false },
      title: {
        text: this.data2.kpiPerc + '%',
        y: 70,
        x: 7,
        style: {
          'align-content': 'center',
          'font-weight': 'bold',
          'font-size': '20px',
          color: this.color,
        },
      },
      subtitle: {
        text: this.translateService.instant('Issue Resolution Rate'),
        y: 90,
        x: 5,
        style: { 'align-content': 'center', 'font-weight': 'bold', 'font-size': '10px', 'this.color': '#969dab' },
      },
      pane: {
        center: ['50%', '90%'],
        size: '180%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: '#EEE',
          innerRadius: '80%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },
      tooltip: {
        enabled: true,
        formatter: function () {
          return title_yAxis + ' : ' + this.y + '%';
        },
      },
      yAxis: {
        max: 100,
        stops: [[-9.9, this.color]],
        tickLength: 37,
        tickWidth: 3,
        min: 0,
        lineColor: 'transparent',
        minorTickLength: 0,
        tickPosition: 'inside',
        tickInterval: 0,
        tickPositions: [80],
        tickColor: 'white',
        opposite: false,
        title: {
          y: -70,
        },
        labels: {
          className: 'custom-target-plot-line-label',
          enabled: true,
          x: -30,
          y: -40,
          useHTML: true,
          formatter: function () {
            return (
              '<span id="_totalalertslabel" class="custom-target-plot-line-label" data-target="' +
              this.value +
              '" style="color: white; background-color: rgba(0, 0, 0, 1); padding: 4px; border-radius: 4px; box-shadow: 0 4px 6px rgba(0, 0, 0, 1); font-size: 11px; display: none;">' +
              title_plotLine +
              ' (%)' +
              ' : ' +
              this.value +
              '.0%</span>'
            );
          },
        },
        plotLines: [
          {
            color: 'white',
            width: 2,
            value: 80,
            zIndex: 5,
          },
        ],
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
      series: [
        {
          data: [this.data2.kpiPerc],
          innerRadius: '80%',
          outerRadius: '100%',
          name: 'Issue Count',
          dataLabels: {
            enable: true,
          },
        },
      ],
    };

    this.chart = HighCharts['Reflecx'].createOrUpdate(
      this.chart,
      this.containerTotalAlert.nativeElement,
      this.chartOptions
    );

    const plotLineHover = document.querySelector('.highcharts-plot-line');
    plotLineHover.addEventListener('mouseover', function () {
      this.setAttribute('style', 'cursor: pointer');
    });

    let labelSpan: any = document.querySelector('.custom-target-plot-line-label');
    let plotLinePath: any = document.querySelector('.highcharts-container svg path[stroke="white"][stroke-width="2"]');
    let hideTimeout: any = null;

    if (plotLinePath) {
      plotLinePath.addEventListener('mouseover', function () {
        clearTimeout(hideTimeout);
        labelSpan.style.display = 'block';
      });

      plotLinePath.addEventListener('mouseout', function () {
        hideTimeout = setTimeout(function () {
          labelSpan.style.display = 'none';
        }, 2000);
      });
    }
  }

  calcPrePercent(val: number, totalVal: number, decPlace: number) {
    let result = '0';
    const valArr = ((val / totalVal) * 100).toString().split('.');
    if (valArr.length > 1) {
      if (valArr[1].length > 0) {
        result = valArr[0].toString() + '.' + valArr[1].substring(0, decPlace);
      }
    } else {
      result = valArr[0].toString();
    }

    return result == 'NaN' ? '0.0' : result;
  }

  calcPrePer(val: number, decPlace: number) {
    let result = '0.0';
    const valArr = val.toString().split('.');
    if (valArr.length > 1) {
      if (valArr[1].length > 0) {
        result = valArr[0].toString() + '.' + valArr[1].substring(0, decPlace);
      }
    } else {
      result = valArr[0].toString();
    }

    return result == 'NaN' ? '0.0' : result;
  }
  calcScoreAndPerc(res: any) {
    const IsAllZero = this.data.find((x: any) => {
      return x.Count > 0;
    });
    if (!IsAllZero) {
      this.noResult = true;
    } else {
      this.noResult = false;
    }
    let totalCount = 0;
    this.data.forEach((data: any, index: number) => {
      totalCount += data.Count;
    });

    const kpiPercent = parseFloat(this.alertResolution.toFixed(1));
    this.alertStatusCount = res.AlertCount;
    let data = {
      kpiPerc: kpiPercent,
      alertStatusCount: this.alertStatusCount,
      targetScore: res.Settings.target,
    };
    if (data.targetScore < data.kpiPerc) this.hasPostivePerc = true;
    return data;
  }
  GetHotalertStatusTranslation(key: string) {
    return this.translationHelper.GetHotalertStatusTranslation(key);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }

    if (this.api) {
      this.api.unsubscribe();
    }
  }

  getIndexReminder(index: number) {
    return index % 2;
  }

  changeValue(value: any) {
    if (isNaN(value)) return '';
    const language = this.user.DefaultLanguage.split('-');
    const score = this.i18nService.transformNumber(Number(parseFloat(Math.round(value * 100) / 100 + '').toFixed(1)));
    return language[0] == 'en' ? score.replace(new RegExp(`,`, 'g'), '') : score;
  }
}
