import Highcharts, { theme } from '@clearviewshared/highchart/highchart';
import { I18nService } from '@app/core';
import moment from 'moment';
Highcharts.setOptions(theme);

export function generateSurveyTrend(
  categories: string[],
  completionCount: any,
  responseRate: any,
  i18nService: I18nService = null
): any {
  return {
    colors: ['#7cb5ec', '#434348', '#fd7e14', '#dc3545'],
    chart: {
      zoomType: 'xy',
    },
    // title: {
    //   text: i18nService.getTranslate('Survey Trend'),
    //   align: 'left'
    // },

    xAxis: [
      {
        categories: categories,
        crosshair: true,
        // labels: {
        //   enabled: true,
        // },
        // stackLabels: {
        //   enabled: true,
        // },
      },
    ],
    yAxis: [
      {
        // Primary yAxis
        labels: {
          enabled: true,
          // format: '{value}°C',
          style: {
            color: '#434348',
          },
        },
        title: {
          text: i18nService.getTranslate('Survey Response Rate'),
          style: {
            color: '#434348',
          },
        },
      },
      {
        // Secondary yAxis
        title: {
          text: i18nService.getTranslate('Survey Completion Count'),
          style: {
            color: '#7cb5ec',
          },
        },
        labels: {
          //format: '{value} mm',
          style: {
            color: '#7cb5ec',
          },
        },
        opposite: true,
      },
    ],
    plotOptions: {
      series: {
        //cursor: Type(),
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          // formatter: function () {
          //   return this.y > 0 ? `${i18nService.transformNumber(this.y.toFixed(1))}` + `%` : '';
          // },
        },

        //point: point,
      },
    },
    tooltip: {
      shared: true,
    },
    legend: {
      //   align: 'center',
      //   //x: 80,
      //  // verticalAlign: 'bottom',
      //   //y: 80,
      //   floating: true,
      //   backgroundColor: 'rgba(255,255,255)'
      enabled: true,
      backgroundColor: '#FFFFFF',
      itemDistance: 70,
    },
    series: [
      {
        name: i18nService.getTranslate('Survey Completion Count'),
        type: 'column',
        yAxis: 1,
        data: completionCount,
        dataLabels: {
          enabled: true,
          //rotation: -90,
          color: '#FFFFFF',
          style: {
            //fontSize: '13px',
            fontFamily: 'sans-serif',
          },
        },
        // tooltip: {
        //   valueSuffix: ' mm'
        // }
      },
      {
        name: i18nService.getTranslate('Survey Response Rate'),
        type: 'spline',
        data: responseRate,
        tooltip: {
          valueSuffix: '%',
          pointFormatter: function () {
            return i18nService.transformNumber(this.y, false);
          },
        },
        dataLabels: {
          enabled: true,
          //rotation: -90,
          color: '#000',
          style: {
            //fontSize: '13px',
            fontFamily: 'sans-serif',
          },
          formatter: function () {
            return i18nService.transformNumber(this.point.y, false);
          },
        },
      },
    ],
  };
}

export function generateTablePieChart(title: string, subtitle: string, count: number = 50, color: string): any {
  return {
    chart: {
      type: 'solidgauge',
      height: '52px',
      spacingBottom: 0,
      margin: [0, 0, 0, 0],
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      width: 250,
      style: {
        float: 'left',
      },
    },
    tooltip: {
      enabled: false,
    },

    title: {
      text: title,
      align: 'left',

      style: {
        fontSize: '13px',
        fontWeight: '400',
        color: '#212229',
      },
      x: 70,
      y: 16,
    },

    subtitle: {
      text: subtitle,
      color: 'red',
      align: 'left',
      style: {
        fontSize: '22px',
        fontWeight: '700',
        color: '#494c57',
      },
      x: 70,
      y: 41,
    },
    credits: {
      enabled: false,
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
      size: '42px',
      margin: [0, 0, 0, 0],
      spacingTop: 0,
      spacingLeft: 0,
      center: ['13%', '50%'],
      spacingRight: 0,
      background: [
        {
          // Track for Move
          outerRadius: '120%',
          innerRadius: '88%',
          backgroundColor: '#cad0e8',
          borderWidth: 0,
        },
      ],
    },

    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: [],
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
        linecap: 'round',
        stickyTracking: false,
      },
    },

    series: [
      {
        name: 'Move',
        data: [
          {
            color: theme.colors,
            radius: '120%',
            innerRadius: '88%',
            y: parseFloat(subtitle) / 10,
          },
        ],
      },
    ],
  };
}

export function generateTableLineChart(
  x: any,
  categories = ['APR 18', 'MAY 18', 'JUN 18', 'JUL 18', 'AUG 18', 'SEP 18', 'OCT 18', 'NOV 18']
): any {
  return {
    chart: {
      backgroundColor: null,
      borderWidth: 0,
      type: 'spline',
      width: 300,
      height: '50px',
      // small optimalization, saves 1-2 ms each sparkline
      skipClone: true,
    },
    legends: {
      enabled: false,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
        },
        color: '#d7d7d7',
      },
    },
    xAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      startOnTick: false,
      endOnTick: false,
      lineWidth: 0,
      categories: categories,
      tickPositions: [],
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tickPositions: [],
    },
    labels: {
      enabled: false,
    },
    series: [
      {
        showInLegend: false,
        dataLabels: {
          enabled: false,
        },
        name: '',
        data: x,
        label: '',
      },
    ],
  };
}

export function generateKPI(
  title: string,
  scrore: string,
  target: string,
  color = 'green',
  i18nService: I18nService,
  IsTarget: boolean,
  targetTranslatedstring: string,
  dialLength = 3,
  targetLabel: string = null,
  lowerIsBetter: boolean = false
): any {
  const scoreValue = parseFloat(scrore);
  const targetValue = parseFloat(target);
  const scoreTranslated = i18nService.transformNumber(scoreValue);
  const targetTranslated = i18nService.transformNumber(targetValue);
  const gaugeColor = IsTarget ? (scoreValue >= targetValue ? '#4edd9e' : '#dd654e') : '#4edd9e';
  const htmlTitleAttribute = title.replace('<br/>', '');
  let targetArrowColor = scoreValue >= targetValue ? '#3bb001' : '#3bb001';
  color = IsTarget
    ? (scoreValue <= targetValue && lowerIsBetter) || (scoreValue >= targetValue && !lowerIsBetter)
      ? '#0f794a'
      : '#de225f'
    : '#de225f';

  // targetArrowColor = 'orange';
  // color = '#fec42a';

  const widthMapping = (width = 3) => {
    switch (width) {
      case 4:
      case 5:
        return 170;
      default:
        return 220;
    }
  };
  if (!IsTarget) {
    return {
      chart: {
        type: 'gauge',
        height: '100%',
        width: 300,
        spacingBottom: 10,
        spacingTop: 10,
        spacingLeft: 10,
        spacingRight: 10,
        padding: 0,
        margin: 10,
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },

      credits: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.series.name + '</b>: ' + i18nService.transformNumber(this.y.toFixed(1)) + ' %';
        },
      },
      pane: [
        {
          startAngle: -360,
          endAngle: 360,
          background: [
            {
              backgroundColor: '#000',
              borderWidth: 10,
              borderColor: '#e6e6e6',
              outerRadius: '116%',
              //  innerRadius: "100%"
            },
          ],
        },
      ],
      plotOptions: {
        gauge: {
          borderWidth: 100,
          pivot: {
            radius: 0,
          },
          dial: {
            size: '30%',
            radius: '100%',
            backgroundColor: gaugeColor,
            baseWidth: 3,
            topWidth: 3,
            rearLength: '-77%',
          },
        },
      },
      title: {
        text: scoreTranslated,
        align: 'center',
        verticalAlign: 'top',
        y: 115,
        style: {
          width: '20%',
          color: 'white',
          fontSize: '28px',
          fontWeight: '500',
          textTransform: 'uppercase',
        },
      },
      subtitle: {
        text: title,
        align: 'center',
        verticalAlign: 'top',
        y: 65,
        style: {
          width: '15%',
          fontSize: '11px',
          fontWeight: '400',
          color: 'white',
          textTransform: 'uppercase',
        },
      },
      // the value axis
      yAxis: {
        min: 0,
        max: 200,
        gridLineColor: 'green',
        gridLineWidth: 0,
        minorTickInterval: 'auto',
        minorTickWidth: 1.3,
        minorTickLength: 12,
        minorTickPosition: 'inside',
        minorTickColor: color,
        tickPixelInterval: 20,
        lineColor: 'transparent',
        tickWidth: 0,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: 'white',
        plotBands: [
          {
            from: 0,
            to: 200,
            color: '#fff',
            outerRadius: '75%',
            thickness: '2%',
          },
        ],
        labels: {
          step: 10,
          enabled: false,
          rotation: 'auto',
        },
      },

      series: [
        {
          data: [
            {
              y: parseFloat(scrore),
            },
          ],
          name: title,
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };
  } else {
    return {
      chart: {
        type: 'gauge',
        spacingBottom: 10,
        spacingTop: 10,
        spacingLeft: 10,
        width: widthMapping(dialLength),
        spacingRight: 10,
        padding: 0,
        margin: 10,
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },

      credits: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return (
            '<b>' +
            this.series.name +
            '</b>: ' +
            i18nService.transformNumber(this.y.toFixed(1)) +
            ' ' +
            `${targetLabel !== null ? '' : '%'}`
          );
        },
      },
      pane: [
        {
          startAngle: -360,
          endAngle: 360,
          background: [
            {
              backgroundColor: '#000',
              borderWidth: 10,
              borderColor: '#e6e6e6',
              outerRadius: '116%',
              //  innerRadius: "100%"
            },
          ],
        },
      ],
      plotOptions: {
        gauge: {
          borderWidth: 100,
          pivot: {
            radius: 0,
          },
          dial: {
            radius: '100%',
            backgroundColor: 'green',
            baseWidth: 3,
            topWidth: 3,
            rearLength: '-77%',
          },
        },
      },
      title: {
        text: scoreTranslated,
        align: 'center',
        verticalAlign: 'top',
        y: 115,
        style: {
          width: '20%',
          color: 'white',
          fontSize: '32px',
          fontWeight: '500',
          textTransform: 'capitalize',
        },
      },
      subtitle: {
        text:
          title.length > 23
            ? `<h3 style="text-align: center" title="${htmlTitleAttribute}">${title.substring(0, 23)}...</h4>`
            : `<h4 style="text-align: center;margin-top: 3px;" title="${htmlTitleAttribute}">${title}</h4>`,
        useHTML: true,
        align: 'center',
        verticalAlign: 'top',
        y: 72,
        style: {
          width: '15%',
          fontSize: '11px',
          fontWeight: '400',
          color: 'white',
          textTransform: 'uppercase',
        },
      },
      // the value axis
      yAxis: {
        min: 0,
        max: 200,
        gridLineColor: 'red',
        gridLineWidth: 0,
        minorTickInterval: 'auto',
        minorTickWidth: 1.3,
        minorTickLength: 12,
        minorTickPosition: 'inside',
        minorTickColor: color,
        tickPixelInterval: 20,
        lineColor: 'transparent',
        tickWidth: 0,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: 'white',
        plotBands: [
          {
            from: 0,
            to: 200,
            color: '#fff',
            outerRadius: '75%',
            thickness: '2%',
          },
        ],
        labels: {
          step: 10,
          enabled: false,
          rotation: 'auto',
        },
      },

      series: [
        {
          data: [
            {
              y: parseFloat(scrore),
            },
          ],
          name: title,
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: targetTranslatedstring,
          data: [
            {
              y: parseFloat(targetTranslated),
              id: targetTranslatedstring,
              name: color,
              marker: {
                fillColor: 'green',
                radius: 50,
              },
              dial: {
                radius: '100%',
                backgroundColor: targetArrowColor,
                borderColor: targetArrowColor, //'#0f7d4c',
                borderWidth: 1,
                borderRadius: 10,
                markerEnd: 'arrow',
                baseWidth: 15,
                topWidth: 1,
                baseLength: '80%',
              },
            },
            // {
            //   y: 60,
            //   dial: {
            //     backgroundColor: '#ff6d2a',
            //     radius: '85%',
            //     borderColor: '#ff6d2a',
            //     borderWidth: 1,
            //     baseWidth: 12,
            //     topWidth: 1,
            //     baseLength: '80%'
            //   }
            // }
          ],

          tooltip: {
            valueSuffix: '',
          },
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };
  }
}

export function generateHotAlerts(kpi: string, alertCount: string, data: any): any {
  return {
    chart: {
      // renderTo: "container",
      spacingBottom: 10,
      spacingTop: 10,
      spacingLeft: 0,
      spacingRight: 0,
      style: {
        width: '100%',
        height: 230,
        float: 'left',
      },
      //  marginRight:250,
      type: 'pie',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      floating: true,
      backgroundColor: null,
      // padding: 15,
      // margin: 30,
      // x: 120,
      // itemMarginRight: 10,
      itemMarginBottom: 15,
      itemStyle: {
        fontSize: 14,
        color: '#b2b2b2',
        fontWeight: '600',
      },
    },
    title: {
      // useHTML:true,
      // text: 'kpi',
      useHTML: true,
      align: 'center',
      text:
        '<span class="pie-chart"><span class="chart__title">KPI</span> <span class="chart__count">' +
        kpi +
        '</span>  <span class="chart__label">Alert Count</span> <span class="chart__link">' +
        alertCount +
        '</span></span>',
      verticalAlign: 'top',
      y: 75,
      style: {
        color: '#666666',
        textTransform: 'uppercase',
        fontWeight: '700',
        zIndex: -1,
        fontSize: '12px',
      },
    },

    yAxis: {
      title: {
        text: 'tes',
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        borderWidth: 0,
      },
    },

    tooltip: {
      formatter: function () {
        return '<b>' + this.point.name + '</b>: ' + this.percentage.toFixed(2) + ' %';
      },
    },
    series: [
      {
        name: 'Browsers',
        data: data,
        size: '100%',
        innerSize: '85%',
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };
}

export function generateNpsScoreV2(
  seriesData: any,
  plotOpt: any,
  kpiPercent: any,
  npsCount: any,
  npsCountText: string,
  i18nService: I18nService
): any {
  const alignments =
    window.innerWidth > 520
      ? { align: 'right', verticalAlign: 'middle' }
      : { width: '100%', x: 0, align: 'middle', verticalAlign: 'bottom' };
  const kpiPer = 440;
  return {
    colors: ['#3bb001', '#ffc107', '#fd141f', '#007bff', '#dc3545', '#dc3545', '#dc3545', '#dc3545', '#dc3545'],
    chart: {
      backgroundColor: 'transparent',
    },
    credits: {
      enabled: false,
    },
    title: {
      useHTML: true,
      text:
        "<span class='pie-chart'><span class='chart__title'>NPS</span> <span class='chart__count'>" +
        kpiPercent +
        '%' +
        "</span>  <span class='chart__label'>" +
        npsCountText +
        "</span> <span class='chart__link'>" +
        npsCount +
        '</span></span>',
      verticalAlign: 'middle',
      x: alignments.x,
      y: 15,
      style: {
        color: '#666666',
        textTransform: 'uppercase',
        fontWeight: '700',
        zIndex: 1,
        fontSize: '12px',
      },
    },
    legend: {
      enabled: false,
      align: alignments.align,
      layout: 'vertical',
      width: alignments.width,
      verticalAlign: alignments.verticalAlign,
      useHTML: true,
      labelFormatter: function () {
        // tslint:disable-next-line:max-line-length
        // return '<div style=\'float:left; margin-bottom:20px;min-width:45px;display:inline-block; text-align:left;\'><span style=\'color:#007bff;min-width:45px;display:inline-block; margin-right:10px;\'>' + this.data + "</span><span style='color:#969dab;min-width:45px;display:inline-block; margin-right:10px;'>" + calcPrePer(this.percentage, 1) + " %</span><span style='color:#969dab; margin-right:20px;'>" + this.name + "</span></div>";
        return `
          <div class="widget__content" style="width: 100vh;">
            <div class="widget__counts">
              <div class="counts__listp1 counts__list counts__table">
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="count__count"><a href="javascript:void(0)">${
                    this.data
                  }</a></div>
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="count__percentage">${i18nService.transformNumber(
                    parseFloat(calcPrePer(this.percentage, 1))
                  )} %</div>
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="series__name">${
                    this.name
                  }</div>
              </div>
            </div>
          </div>
        `;
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        borderWidth: 0,
        startAngle: 140,
        ...plotOpt,
      },
      gauge: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: 'none',
      padding: 1,
      valueDecimals: 1,
      shadow: false,
      formatter: function () {
        return '<b>' + this.point.name + '</b>: ' + this.y + ' %';
      },
    },
    pane: {
      startAngle: -220,
      endAngle: 140,
      background: [
        {
          backgroundColor: 'transparent',
          borderWidth: 0,
          innerRadius: '100%',
          outerRadius: '100%',
        },
      ],
    },
    yAxis: [
      {
        zIndex: 10,
        height: 100,
        lineWidth: 0,
        offset: 1.7,
        min: -28,
        plotBands: [
          {
            from: 0,
            to: [kpiPer],
            color: Highcharts.getOptions().colors[10],
            outerRadius: '114%',
            thickness: '1%',
          },
        ],
        max: 100,
        minorTickWidth: 0,
        minorTickLength: 8,
        minorTickPosition: 'inside',
        minorTickColor: '#fff',
        labels: {
          enabled: false,
        },
        tickWidth: '1',
        tickAmount: '120',
        tickPosition: 'inside',
        tickLength: '6',
        tickColor: '#fff',
        // tickPositioner: function () {
        //   if (this.chart.series[1]) {
        //     var point = this.chart.series[1].points[0]
        //     var previousAxisTicks = this.chart.yAxis[0].tickPositions,
        //       ticks = previousAxisTicks
        //         .filter(function (elem: any) {
        //           return elem >= 0 && elem < (point.y)
        //         })
        //         .map(function (el: any) {
        //           return el
        //         });
        //     return ticks;
        //   }
        // },
        innerRadius: '10%',
        zindex: 10,
        pane: 0,
      },
    ],
    series: [
      ...seriesData,
      //   {
      //   name: "OBW Rate",
      //   type: "gauge",
      //   data: [{
      //     y: kpiPer,
      //     name: "OBW Rate"
      //   }],
      //   dataLabels: {
      //     padding: 5,
      //     useHTML: true,
      //     enabled: false,
      //     format: "{point.y} %",
      //     borderWidth: 0,
      //     style: {
      //       color: "#555",
      //       fontSize: 30
      //     }
      //   },
      //   tooltip: {
      //     valueSuffix: ' %',
      //     borderWidth: 0,
      //     backgroundColor: 'none',
      //     shadow: false,
      //     useHTML: true
      //   },
      //   animation: null,
      //   dial: {
      //     backgroundColor: '#999',
      //     borderColor: '#fff',
      //     borderWidth: 0,
      //     shapeType: 'path',
      //     baseWidth: 22,
      //     baseLength: "104%",
      //     rearLength: '-104%',
      //     radius: '104%',
      //   },
      //   pivot: {
      //     radius: 0
      //   }
      // }
    ],
  };
}
export function generateNpsScoreV1(
  seriesData: any,
  plotOpt: any,
  kpiPercent: any,
  npsCount: any,
  npsCountText: string,
  i18nService: I18nService
): any {
  const alignments =
    window.innerWidth > 520
      ? { align: 'right', verticalAlign: 'middle' }
      : { width: '100%', x: 0, align: 'middle', verticalAlign: 'bottom' };
  return {
    colors: ['#3bb001', '#ffc107', '#fd141f', '#007bff', '#dc3545', '#dc3545', '#dc3545', '#dc3545', '#dc3545'],
    chart: {
      type: 'pie',
      height: 250,
    },
    title: {
      useHTML: true,
      text:
        "<span class='pie-chart'><span class='chart__title'>NPS</span> <span class='chart__count'>" +
        kpiPercent +
        '%' +
        "</span>  <span class='chart__label'>" +
        npsCountText +
        "</span> <span class='chart__link'>" +
        npsCount +
        '</span></span>',
      verticalAlign: 'middle',
      x: alignments.x,
      y: 15,
      style: {
        color: '#666666',
        textTransform: 'uppercase',
        fontWeight: '700',
        zIndex: -1,
        fontSize: '12px',
      },
    },
    tooltip: {
      enabled: true,
    },
    legend: {
      enabled: false,
      align: alignments.align,
      layout: 'vertical',
      width: alignments.width,
      verticalAlign: alignments.verticalAlign,
      useHTML: true,
      labelFormatter: function () {
        // tslint:disable-next-line:max-line-length
        // return '<div style=\'float:left; margin-bottom:20px;min-width:45px;display:inline-block; text-align:left;\'><span style=\'color:#007bff;min-width:45px;display:inline-block; margin-right:10px;\'>' + this.data + "</span><span style='color:#969dab;min-width:45px;display:inline-block; margin-right:10px;'>" + calcPrePer(this.percentage, 1) + " %</span><span style='color:#969dab; margin-right:20px;'>" + this.name + "</span></div>";
        return `
          <div class="widget__content" style="width: 100vh;">
            <div class="widget__counts">
              <div class="counts__listp1 counts__list counts__table">
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="count__count"><a href="javascript:void(0)">${
                    this.data
                  }</a></div>
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="count__percentage">${i18nService.transformNumber(
                    parseFloat(calcPrePer(this.percentage, 1))
                  )} %</div>
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="series__name">${
                    this.name
                  }</div>
              </div>
            </div>
          </div>
        `;
      },
    },
    plotOptions: {
      pie: {
        size: 180,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        innerSize: 150,
        depth: 45,
        showInLegend: true,
      },
      series: plotOpt,
    },
    series: seriesData,
    credits: {
      enabled: false,
    },
  };
}
export function generatePerformanceTrend(
  data: any,
  categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
): any {
  return {
    chart: {
      type: 'area',
      spacingBottom: 0,
      spacingTop: 0,
      spacingLeft: 20,
      spacingRight: 20,
    },
    credits: {
      enabled: false,
    },
    legend: {
      align: 'center',
      itemStyle: {
        color: '#b2b2b2',
        fontWeight: '500',
      },
      verticalAlign: 'bottom',
    },
    yAxis: {
      title: {
        text: '',
        style: {
          color: '#666666',
          fontWeight: '700',
          fontSize: '14px',
        },
      },
    },
    plotOptions: {
      area: {
        fillOpacity: 0.05,
      },
    },
    xAxis: {
      gridLineWidth: 1,
      opposite: true,
      startOnTick: false,
      endOnTick: false,
      minPadding: 0,
      maxPadding: 0,
      labels: {
        padding: 0,
        distance: 0,
        // formatter: function() {
        //   return categories[this.value];
        // }
      },
      categories: categories,
    },

    series: data,
  };
}

export function generateAreaOfImprovement(title: string, questions: string[], scores: number[]): any {
  return {
    chart: {
      type: 'column',
      spacingBottom: 20,
      spacingTop: 20,
      spacingLeft: 30,
      spacingRight: 30,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: title,
      style: {
        color: '#666666',
        fontWeight: '700',
        fontSize: '14px',
      },
    },
    // tooltip: {
    //   enabled: false
    // },
    xAxis: {
      gridLineWidth: 1,
      categories: questions, // ['Q1', 'Q2', 'Q3', 'Q4', 'Q5']
    },
    yAxis: {
      min: 0,
      max: 10,
      title: {
        text: '',
      },
    },
    tooltip: {
      pointFormat: "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b> ({point.y}%)<br/>",
      shared: true,
    },
    /* plotOptions: {

     },*/

    plotOptions: {
      series: {
        pointWidth: 20,
        cursor: 'pointer',
        events: {
          click: function (event: any) {
            // this.valueChange.emit(this.yAxis.chart.hoverPoint.category);
          },
        },
      },
      column: {
        stacking: 'normal',
        borderWidth: 0,
      },
    },
    series: [
      {
        name: 'score',
        showInLegend: false,
        color: '#f4f5f7',
        tooltip: {
          pointFormat: '',
        },
        data: scores, // [5, 3, 4, 7, 2]
      },
      {
        name: 'score',
        showInLegend: false,
        color: '#007bff',
        data: scores, // [5, 3, 4, 7, 2]
      },
    ],
  };
}

export function generateHotAlertEfficiency(categories: string[], data: any): any {
  return {
    chart: {
      type: 'area',
      spacingBottom: 5,
      spacingTop: 5,
      spacingLeft: 25,
      spacingRight: 25,
    },
    credits: {
      enabled: false,
    },
    legend: {
      align: 'center',
      itemStyle: {
        color: '#b2b2b2',
        fontWeight: '500',
      },
      verticalAlign: 'bottom',
    },
    title: {
      text: '',
    },
    plotOptions: {
      area: {
        fillOpacity: 0.05,
        marker: {
          fillColor: 'white',
          lineWidth: 2,
          // lineColor: null,
          symbol: 'circle',
        },
      },
    },
    yAxis: {
      title: {
        text: '',
      },
    },
    xAxis: {
      gridLineWidth: 1,
      categories: categories,
    },

    series: data,
  };
}

export function generateSaleQuestions(): any {
  return {
    chart: {
      type: 'solidgauge',
      spacingBottom: 5,
      spacingTop: 5,
      spacingLeft: 5,
      spacingRight: 5,
    },

    title: {
      text: 'Service Initiation',
      style: {
        fontSize: '12px',
      },
      x: 150,
      y: 100,
    },

    subtitle: {
      text: '962.06',
      style: {
        fontSize: '16px',
      },
      x: 130,
      y: 120,
    },

    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          // Track for Move
          outerRadius: '100%',
          innerRadius: '88%',
          borderWidth: 0,
        },
      ],
    },

    yAxis: {
      min: 0,
      max: 20,
      lineWidth: 0,
      tickPositions: [],
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
        linecap: 'round',
        stickyTracking: false,
      },
    },

    series: [
      {
        name: 'Move',
        data: [
          {
            color: '#b2b2b2',
            radius: '100%',
            innerRadius: '88%',
            y: 50,
          },
        ],
      },
    ],
  };
}

export function generatSMPerformanceTrends(
  seriesData: any,
  seletedMon: any,
  i18nService: I18nService = null,
  event: string
): any {
  return {
    colors: ['#3bb001', '#007bff', '#dc3545', '#ffc107'],
    chart: {
      type: 'area',
    },
    plotOptions: {
      area: {
        fillOpacity: 0.2,
      },
    },
    xAxis: {
      title: {
        text: '',
      },
      labels: {
        enabled: true,
      },
      categories: seletedMon,
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        enabled: true,
      },
      // min: 0,
      // max: 100,
    },
    tooltip: {
      formatter: function () {
        return (
          this.x +
          '<br>' +
          '<b>' +
          this.series.name +
          '</b>: ' +
          i18nService.transformNumber(this.y, Number.isInteger(this.y)) +
          '<br>' +
          i18nService.getTimeIntervalDetails(event, this.y)
        );
      },
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    series: seriesData,
  };
}

export function generatePerformanceTrends(
  seriesData: any,
  seletedMon: any,
  i18nService: I18nService = null,
  colors: Array<string> = ['#3bb001', '#007bff', '#dc3545', '#ffc107'],
  title: string = ''
): any {
  return {
    colors: colors,
    chart: {
      type: 'areaspline',
    },
    subtitle: {
      text:
        title !== ''
          ? `<div style="background: #4ca1fe;color: #fff;border-radius: 3px;font-size: 13px;padding:10px;"> ${title}</div>`
          : title,
      useHTML: true,
      align: 'left',
    },
    xAxis: {
      title: {
        text: '',
      },
      labels: {
        enabled: true,
      },
      categories: seletedMon,
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        enabled: true,
      },
      // min: 0,
      // max: 100,
    },

    tooltip: {
      shared: true,
      crosshairs: true,
      useHTML: true,
      pointFormatter: function () {
        return (
          '<span style="padding-right:1px;color:' +
          this.color +
          '">\u25CF <b>' +
          this.series.name +
          '</b>: </span>' +
          '<span style="padding:4px; font-weight:bold;">' +
          i18nService.transformNumber(this.y.toFixed(1)) +
          '</span><br>'
        );
      },
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    series: seriesData,
  };
}

export function generateKeyPerformanceIndicators(
  seriesData: any,
  kpiData: any,
  seletedMon: any,
  gcolor: any,
  i18nService: I18nService = null
): any {
  return {
    MonthlyChart: {
      chart: {
        type: 'spline',
      },
      xAxis: {
        title: {
          text: '',
        },
        lineColor: '#ffffff',
        labels: {
          enabled: false,
        },
        gridLineColor: '#ffffff',
        categories: seletedMon,
        tickPositions: [],
      },
      yAxis: {
        title: {
          text: '',
        },
        lineColor: '#ffffff',
        labels: {
          enabled: false,
        },
        gridLineColor: '#ffffff',
      },
      tooltip: {
        enabled: true,
        formatter: function () {
          return '<b>' + this.x + '</b> : <b>' + i18nService.transformNumber(this.y.toFixed(1), false) + '</b>  ';
        },
      },
      legend: {
        enabled: false,
      },
      colors: [gcolor],
      series: [
        {
          marker: {
            enabled: false,
          },
          name: '',
          data: kpiData,
        },
      ],
      credits: {
        enabled: false,
      },
    },
    ScoreChart: {
      chart: {
        type: 'solidgauge',
        margin: [0, 0, 0, 0],
        height: '90px',
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        // size: '150%',
        margin: [0, 0, 0, 0],
        spacingTop: 0,
        spacingLeft: 0,
        center: ['50%', '30%'],
        spacingRight: 0,
        spacingBottom: 0,
        background: [
          {
            outerRadius: '100%',
            innerRadius: '100%',
            borderWidth: 0,
          },
        ],
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
        },
      },
      series: [
        {
          name: null,
          data: [
            {
              color: gcolor,
              borderWidth: 35,
              radius: '75%',
              innerRadius: '60%',
              y: parseFloat(seriesData.TotalScore.toFixed(1)),
            },
          ],
        },
      ],
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    },
  };
}

function calcPrePer(val: number, decPlace: number) {
  let result = '0.0';
  const valArr = val.toString().split('.');
  if (valArr.length > 1) {
    if (valArr[1].length > 0) {
      result = valArr[0].toString() + '.' + valArr[1].substring(0, decPlace);
    }
  } else {
    result = valArr[0].toString();
  }

  return result;
}

export function generateTotalAlerts(
  seriesData: any,
  plotOpt: any,
  kpiPercent: any,
  alertStatusCount: any,
  alertcounttext: string,
  i18nService: I18nService
): any {
  const alignments =
    window.innerWidth > 520
      ? { align: 'right', verticalAlign: 'middle' }
      : { width: '100%', x: 0, align: 'middle', verticalAlign: 'bottom' };
  return {
    colors: theme.colors,
    chart: {
      type: 'pie',
      height: 250,
    },
    title: {
      useHTML: true,
      text:
        "<span class='pie-chart'><span class='chart__title'>KPI</span> <span class='chart__count'>" +
        kpiPercent +
        '%' +
        "</span>  <span class='chart__label'>" +
        alertcounttext +
        "</span> <span class='chart__link'>" +
        alertStatusCount +
        '</span></span>',
      verticalAlign: 'middle',
      x: alignments.x,
      y: 15,
      style: {
        color: '#666666',
        textTransform: 'uppercase',
        fontWeight: '700',
        zIndex: 1,
        fontSize: '12px',
      },
    },
    tooltip: {
      enabled: true,
      //  headerFormat: '',
      // pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
      //     '   {point.namex}: <b>{point.y}</b><br/>'
    },
    legend: {
      enabled: false,
      align: alignments.align,
      layout: 'vertical',
      width: alignments.width,
      verticalAlign: alignments.verticalAlign,
      useHTML: true,
      labelFormatter: function () {
        // tslint:disable-next-line:max-line-length
        // return '<div style=\'float:left; margin-bottom:20px;min-width:45px;display:inline-block; text-align:left;\'><span style=\'color:#007bff;min-width:45px;display:inline-block; margin-right:10px;\'>' + this.data + "</span><span style='color:#969dab;min-width:45px;display:inline-block; margin-right:10px;'>" + calcPrePer(this.percentage, 1) + " %</span><span style='color:#969dab; margin-right:20px;'>" + this.name + "</span></div>";
        return `
          <div class="widget__content" style="width: 100vh;">
            <div class="widget__counts">
              <div class="counts__listp1 counts__list counts__table">
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="count__count"><a href="javascript:void(0)">${
                    this.data
                  }</a></div>
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="count__percentage">${i18nService.transformNumber(
                    parseFloat(calcPrePer(this.percentage, 1))
                  )} %</div>
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="series__name">${
                    this.name
                  }</div>
              </div>
            </div>
          </div>
        `;
      },
    },
    plotOptions: {
      pie: {
        size: 180,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        innerSize: 150,
        depth: 45,
        showInLegend: true,
      },
      series: plotOpt,
    },
    series: seriesData,
    credits: {
      enabled: false,
    },
  };
}
export function generateTotalAlertsV2(
  seriesData: any,
  plotOpt: any,
  kpiPercent: any,
  alertStatusCount: any,
  alertcounttext: string,
  i18nService: I18nService
): any {
  const kpiPer = 440;
  const alignments =
    window.innerWidth > 520
      ? { align: 'right', verticalAlign: 'middle' }
      : { width: '100%', x: 0, align: 'middle', verticalAlign: 'bottom' };
  return {
    colors: ['#3bb001', '#ffc107', '#fd7e14', '#007bff', '#dc3545', '#808080', ' #A020F0', '#dc3545', '#dc3545'],
    chart: {
      backgroundColor: 'transparent',
    },
    credits: {
      enabled: false,
    },
    title: {
      useHTML: true,
      text:
        "<span class='pie-chart'><span class='chart__title'>KPI</span> <span class='chart__count'>" +
        kpiPercent +
        '%' +
        "</span>  <span class='chart__label'>" +
        alertcounttext +
        "</span> <span class='chart__link'>" +
        alertStatusCount +
        '</span></span>',
      verticalAlign: 'middle',
      x: alignments.x,
      y: 15,
      style: {
        color: '#666666',
        textTransform: 'uppercase',
        fontWeight: '700',
        zIndex: 1,
        fontSize: '12px',
      },
    },
    tooltip: {
      enabled: true,
      //  headerFormat: '',
      // pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
      //     '   {point.namex}: <b>{point.y}</b><br/>'
    },
    legend: {
      enabled: false,
      align: alignments.align,
      layout: 'vertical',
      width: alignments.width,
      verticalAlign: alignments.verticalAlign,
      useHTML: true,
      labelFormatter: function () {
        // tslint:disable-next-line:max-line-length
        // return '<div style=\'float:left; margin-bottom:20px;min-width:45px;display:inline-block; text-align:left;\'><span style=\'color:#007bff;min-width:45px;display:inline-block; margin-right:10px;\'>' + this.data + "</span><span style='color:#969dab;min-width:45px;display:inline-block; margin-right:10px;'>" + calcPrePer(this.percentage, 1) + " %</span><span style='color:#969dab; margin-right:20px;'>" + this.name + "</span></div>";
        return `
          <div class="widget__content" style="width: 100vh;">
            <div class="widget__counts">
              <div class="counts__listp1 counts__list counts__table">
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="count__count"><a href="javascript:void(0)">${
                    this.data
                  }</a></div>
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="count__percentage">${i18nService.transformNumber(
                    parseFloat(calcPrePer(this.percentage, 1))
                  )} %</div>
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="series__name">${
                    this.name
                  }</div>
              </div>
            </div>
          </div>
        `;
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        borderWidth: 0,
        startAngle: 140,
        ...plotOpt,
      },
      gauge: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    pane: {
      startAngle: -220,
      endAngle: 140,
      background: [
        {
          backgroundColor: 'transparent',
          borderWidth: 0,
          innerRadius: '100%',
          outerRadius: '100%',
        },
      ],
    },
    yAxis: [
      {
        zIndex: 10,
        height: 100,
        lineWidth: 0,
        offset: 1.7,
        min: -28,
        plotBands: [
          {
            from: 0,
            to: [kpiPer],
            color: Highcharts.getOptions().colors[10],
            outerRadius: '114%',
            thickness: '1%',
          },
        ],
        max: 100,
        minorTickWidth: 0,
        minorTickLength: 8,
        minorTickPosition: 'inside',
        minorTickColor: '#fff',
        labels: {
          enabled: false,
        },
        tickWidth: '1',
        tickAmount: '120',
        tickPosition: 'inside',
        tickLength: '6',
        tickColor: '#fff',
        tickPositioner: function () {
          if (this.chart.series[1]) {
            var point = this.chart.series[1].points[0];
            var previousAxisTicks = this.chart.yAxis[0].tickPositions,
              ticks = previousAxisTicks
                .filter(function (elem: any) {
                  return elem >= 0 && elem < point.y;
                })
                .map(function (el: any) {
                  return el;
                });
            return ticks;
          }
        },
        innerRadius: '10%',
        zindex: 10,
        pane: 0,
      },
    ],
    series: [
      ...seriesData,
      // {
      //   name: 'OBW Rate',
      //   type: 'gauge',
      //   data: [
      //     {
      //       y: kpiPer,
      //       name: 'OBW Rate',
      //     },
      //   ],
      //   dataLabels: {
      //     padding: 5,
      //     useHTML: true,
      //     enabled: false,
      //     format: '{point.y} %',
      //     borderWidth: 0,
      //     style: {
      //       color: '#555',
      //       fontSize: 30,
      //     },
      //   },
      //   tooltip: {
      //     valueSuffix: ' %',
      //     borderWidth: 0,
      //     backgroundColor: 'none',
      //     shadow: false,
      //     useHTML: true,
      //   },
      //   animation: null,
      //   dial: {
      //     backgroundColor: '#999',
      //     borderColor: '#fff',
      //     borderWidth: 0,
      //     shapeType: 'path',
      //     baseWidth: 22,
      //     baseLength: '104%',
      //     rearLength: '-104%',
      //     radius: '104%',
      //   },
      //   pivot: {
      //     radius: 0,
      //   },
      // },
    ],
  };
}

export function generateSocialMediaKPI(
  element: any,
  event: any = null,
  kpiName: string = '',
  i18nService: I18nService,
  propertyKey = 'average_rating'
): any {
  const clickFunction = event === null ? function () {} : event;
  return {
    chart: {
      backgroundColor: null,
      borderWidth: 0,
      type: 'area',
      margin: 0,
      width: 150,
      height: 60,
      skipClone: true,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      visible: false,
      categories: element.monthly.map((x: any) => moment(x.month, 'YYYYMM').format('MMM, YYYY')),
      title: {
        text: null,
      },
      startOnTick: false,
      endOnTick: false,
      lineWidth: 0,
      tickPositions: [],
    },
    yAxis: [
      {
        visible: false,
        title: false,
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false,
        },
        // title: {
        //     text: null
        // },
        tickPositions: [],
      },
    ],
    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        fillOpacity: 0.3,
      },
      series: {
        cursor: 'normal',
        borderWidth: 0,
        dataLabels: {
          enabled: false,
          // format: '{point.y:.1f}'
        },
        point: {
          events: {
            click: clickFunction,
          },
        },
      },
    },
    tooltip: {
      followPointer: true,
      followTouchMove: true,
      formatter: function () {
        return (
          "<div style='font-size:smaller;font-weight: bold;'>" +
          this.x +
          "</div><div style='font-size:smaller'>: </div><span style='font-size:smaller;font-weight:bold;color:#c3002f;'>" +
          i18nService.transformNumber(this.y) +
          '</span>' +
          '<br>' +
          "<span style='font-size:smaller;'>" +
          i18nService.getTimeIntervalDetails(kpiName, this.y) +
          '</span>'
        );
      },
    },
    series: [
      {
        name: element.Name,
        showInLegend: false,
        exporting: {
          enabled: false,
        },
        data: Object.values(element.monthly.map((x: any) => x[propertyKey])),
        marker: {
          symbol: 'circle',
        },
        color: '#a9b4bd',
      },
    ],
  };
}

export function generateAlertsEfficiency(seriesData: any, plotOpt: any, seletedMon: any, yaxisTitle: any): any {
  return {
    colors: ['#3bb001', '#ffc107', '#fd7e14', '#007bff', '#dc3545', '#dc3545', '#dc3545', '#dc3545', '#dc3545'],
    chart: {
      type: 'line',
    },
    xAxis: {
      title: {
        text: '',
      },
      labels: {
        enabled: true,
      },
      categories: seletedMon,
      gridLineWidth: 1,
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: yaxisTitle,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          fill: '#6d869f',
        },
      },
      labels: {
        formatter: function () {
          return this.axis.defaultLabelFormatter.call(this);
        },
      },
      // min: 0,
      // max: 100,
    },
    tooltip: {
      enabled: true,
    },
    legend: {
      enabled: true,
      align: 'center',
      layout: 'horizontal',
    },
    series: seriesData,

    plotOptions: {
      series: plotOpt,
    },

    credits: {
      enabled: false,
    },
  };
}

export function generateReasonsEscalations(seriesData: any, categories: string[]): any {
  return {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      gridLineWidth: 1,
      categories: categories,
      labels: {
        style: {
          fontSize: '13px',
          fontWeight: 'bold',
        },
      },
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: '',
      },
      // min: 0,
      // max: 100,
    },
    series: seriesData,
    credits: {
      enabled: false,
    },
  };
}

export function generateRecommendedActions(
  seriesData: any,
  categories: string[],
  clickfunc: any,
  i18nService: I18nService = null
): any {
  return {
    kpiname: '',
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    colors: theme.colors,
    plotOptions: {
      series: {
        borderWidth: 0,
        pointWidth: 40,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return i18nService.transformNumber(this.point.y);
          },
        },
        events: {
          click: clickfunc,
        },
      },
    },
    xAxis: {
      type: 'category',
      gridLineWidth: 1,
      labels: {
        enabled: true,
      },
      // categories: categories,
      // labels: {
      //   style: {
      //     align: 'left',
      //     "fontSize": "13px",
      //     // "fontWeight": "bold"
      //   }
      // }
    },
    yAxis: {
      allowDecimals: false,
      labels: {
        enabled: true,
      },
      title: {
        text: '',
      },
      tooltip: {
        formatter: function () {
          return this.x + '<br>' + '<b>' + this.series.name + '</b>: ' + i18nService.transformNumber(this.y.toFixed(1));
        },
      },
      // min: 0,
      // max: 100,
    },
    tooltip: {
      formatter: function () {
        return this.key + '<br>' + '<b>' + this.series.name + '</b>: ' + i18nService.transformNumber(this.y.toFixed(1));
      },
    },
    legend: {
      enabled: false,
    },
    series: seriesData,
    credits: {
      enabled: false,
    },
  };
}

export function generateNotRespondedReviews(
  seriesData: any,
  plotOpt: any,
  totalCount: any,
  totalCountText: string,
  i18nService: I18nService
): any {
  const legendWidth = 250;
  const pieWidth = 180;
  return {
    chart: {
      type: 'pie',
    },
    title: {
      useHTML: true,
      text: `
      <div class='pie-chart'>
          <div style='font-size: 22px; font-weight: bold'>
            ${Intl.NumberFormat().format(totalCount)}
          </div>
          <div class='chart__link' style='font-size: 14px;'>
            ${totalCountText.toUpperCase()}
          </div>
      </div>
    `,
      verticalAlign: 'middle',
      y: -5,
      x: -pieWidth + 43,
      style: {
        color: '#666666',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        zIndex: -1,
        fontSize: '12px',
      },
    },
    tooltip: {
      enabled: true,
    },
    legend: {
      enabled: true,
      align: 'right',
      width: legendWidth,
      layout: 'vertical',
      verticalAlign: 'middle',
      useHTML: true,
      labelFormatter: function () {
        const percentage =
          calcPrePer(this.percentage, 1) !== null
            ? i18nService.transformNumber(parseFloat(calcPrePer(this.percentage, 1)))
            : calcPrePer(this.percentage, 1);
        return (
          "<div style='float:left; margin-bottom:20px;min-width:45px;display:inline-block; text-align:left;'><span style='color:#007bff;min-width:45px;display:inline-block; margin-right:10px;'>" +
          this.data +
          "</span><span style='color:#969dab;min-width:45px;display:inline-block; margin-right:10px;'>" +
          percentage +
          " %</span><span style='color:#969dab; margin-right:20px;'>" +
          this.name +
          '</span></div>'
        );
      },
    },
    plotOptions: {
      pie: {
        size: pieWidth,
        allowPointSelect: false,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        innerSize: 150,
        depth: 45,
        showInLegend: true,
      },
      series: plotOpt,
    },
    series: seriesData,
    credits: {
      enabled: false,
    },
  };
}

export function generateNotRespondedReviewsV2(
  seriesData: any,
  plotOpt: any,
  totalCount: any,
  totalCountText: string,
  i18nService: I18nService
): any {
  const kpiPer = 440;
  const alignments =
    window.innerWidth > 520
      ? { align: 'right', verticalAlign: 'middle' }
      : { width: '100%', x: 0, align: 'middle', verticalAlign: 'bottom' };
  return {
    colors: ['#3bb001', '#ffc107', '#fd141f', '#007bff', '#dc3545', '#dc3545', '#dc3545', '#dc3545', '#dc3545'],
    chart: {
      backgroundColor: 'transparent',
    },
    credits: {
      enabled: false,
    },
    title: {
      useHTML: true,
      text: `
        <div class='pie-chart'>
            <div style='font-size: 22px; font-weight: bold'>
            ${i18nService.transformNumber(totalCount)}
            </div>
            <div class='chart__link' style='font-size: 14px;'>
              ${totalCountText.toUpperCase()}
            </div>
        </div>
      `,
      verticalAlign: 'middle',
      x: alignments.x,
      y: 15,
      style: {
        color: '#666666',
        textTransform: 'uppercase',
        fontWeight: '700',
        zIndex: -1,
        fontSize: '12px',
      },
    },
    tooltip: {
      enabled: true,
      //  headerFormat: '',
      // pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
      //     '   {point.namex}: <b>{point.y}</b><br/>'
    },
    legend: {
      enabled: false,
      align: alignments.align,
      layout: 'vertical',
      width: alignments.width,
      verticalAlign: alignments.verticalAlign,
      useHTML: true,
      labelFormatter: function () {
        const percentage =
          calcPrePer(this.percentage, 1) !== null
            ? i18nService.transformNumber(parseFloat(calcPrePer(this.percentage, 1)))
            : calcPrePer(this.percentage, 1);
        return (
          "<div style='float:left; margin-bottom:20px;min-width:45px;display:inline-block; text-align:left;'><span style='color:#007bff;min-width:45px;display:inline-block; margin-right:10px;'>" +
          this.data +
          "</span><span style='color:#969dab;min-width:45px;display:inline-block; margin-right:10px;'>" +
          percentage +
          " %</span><span style='color:#969dab; margin-right:20px;'>" +
          this.name +
          '</span></div>'
        );
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        borderWidth: 0,
        startAngle: 140,
        ...plotOpt,
      },
      gauge: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    pane: {
      startAngle: -220,
      endAngle: 140,
      background: [
        {
          backgroundColor: 'transparent',
          borderWidth: 0,
          innerRadius: '100%',
          outerRadius: '100%',
        },
      ],
    },
    yAxis: [
      {
        zIndex: 10,
        height: 100,
        lineWidth: 0,
        offset: 1.7,
        min: -28,
        plotBands: [
          {
            from: 0,
            to: [kpiPer],
            color: Highcharts.getOptions().colors[10],
            outerRadius: '114%',
            thickness: '1%',
          },
        ],
        max: 100,
        minorTickWidth: 0,
        minorTickLength: 8,
        minorTickPosition: 'inside',
        minorTickColor: '#fff',
        labels: {
          enabled: false,
        },
        tickWidth: '1',
        tickAmount: '120',
        tickPosition: 'inside',
        tickLength: '6',
        tickColor: '#fff',
        // tickPositioner: function () {
        // if (this.chart.series[1]) {
        //   var point = this.chart.series[1].points[0]
        //   var previousAxisTicks = this.chart.yAxis[0].tickPositions,
        //     ticks = previousAxisTicks
        //       .filter(function (elem: any) {
        //         return elem >= 0 && elem < (point.y)
        //       })
        //       .map(function (el: any) {
        //         return el
        //       });
        //   return ticks;
        // }
        // },
        innerRadius: '10%',
        zindex: 10,
        pane: 0,
      },
    ],
    series: [
      ...seriesData,
      // {
      //   name: "OBW Rate",
      //   type: "gauge",
      //   data: [{
      //     y: kpiPer,
      //     name: "OBW Rate"
      //   }],
      //   dataLabels: {
      //     padding: 5,
      //     useHTML: true,
      //     enabled: false,
      //     format: "{point.y} %",
      //     borderWidth: 0,
      //     style: {
      //       color: "#555",
      //       fontSize: 30
      //     }
      //   },
      //   tooltip: {
      //     valueSuffix: ' %',
      //     borderWidth: 0,
      //     backgroundColor: 'none',
      //     shadow: false,
      //     useHTML: true
      //   },
      //   animation: null,
      //   dial: {
      //     backgroundColor: '#999',
      //     borderColor: '#fff',
      //     borderWidth: 0,
      //     shapeType: 'path',
      //     baseWidth: 22,
      //     baseLength: "104%",
      //     rearLength: '-104%',
      //     radius: '104%',
      //   },
      //   pivot: {
      //     radius: 0
      //   }
      // }
    ],
  };
}

export function generateTop5ClosedAlerts(seriesData: any, i18nService: I18nService = null): any {
  return {
    kpiname: '',
    chart: {
      type: 'column',
      height: '290px',
    },
    title: {
      text: '',
    },
    colors: theme.colors,
    plotOptions: {
      series: {
        borderWidth: 0,
        pointWidth: 40,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return i18nService.transformNumber(this.point.y);
          },
        },
      },
    },
    xAxis: {
      type: 'category',
      gridLineWidth: 1,
      labels: {
        enabled: true,
      },
      // categories: categories,
      // labels: {
      //   style: {
      //     align: 'left',
      //     "fontSize": "13px",
      //     // "fontWeight": "bold"
      //   }
      // }
    },
    yAxis: {
      allowDecimals: false,
      labels: {
        enabled: true,
      },
      title: {
        text: '',
      },
      tooltip: {
        formatter: function () {
          return this.x + '<br>' + '<b>' + this.series.name + '</b>: ' + i18nService.transformNumber(this.y.toFixed(1));
        },
      },
      // min: 0,
      // max: 100,
    },
    tooltip: {
      formatter: function () {
        // console.log('this', this);
        return this.key + '<br>' + '<b>' + this.series.name + '</b>: ' + i18nService.transformNumber(this.y.toFixed(1));
      },
    },
    legend: {
      enabled: false,
    },
    series: seriesData,
    credits: {
      enabled: false,
    },
  };
}

export function generateSentimentCount(
  seriesData: any,
  plotOpt: any,
  totalCount: any,
  i18nService: I18nService = null,
  totalCountText: string
): any {
  function get_xaxis(count: any) {
    return 43;
  }
  const legendWidth = 250;
  const pieWidth = 180;
  return {
    colors: ['#3bb001', '#fd7e14', '#dc3545', '#7d7d7d'],
    chart: {
      type: 'pie',
      height: 250,
    },

    title: {
      useHTML: true,
      text: `
        <div class='pie-chart'>
            <div style='font-size: 22px; font-weight: bold'>
              ${i18nService.transformNumber(totalCount)}
            </div>
            <div class='chart__link' style='font-size: 14px;'>
              ${totalCountText.toUpperCase()}
            </div>
        </div>
      `,
      verticalAlign: 'middle',
      y: 16,
      labels: {
        style: {
          color: '#666666',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          zIndex: -1,
          fontSize: '12px',
        },
      },
    },
    tooltip: {
      formatter: function () {
        return `<span style="color: ${this.point.color};text-align:right;">  ${this.point.name}<br/> ${
          this.series.name
        } </span>: <b>  ${i18nService.transformNumber(this.point.y)}</b><br/>`;
      },
    },
    legend: {
      enabled: false,
      align: 'right',
      width: legendWidth,
      layout: 'vertical',
      verticalAlign: 'middle',
      useHTML: true,
      labelFormatter: function () {
        return (
          "<div style='float:left; margin-bottom:20px;min-width:45px;display:inline-block; text-align:left;'><span style='color:#007bff;min-width:45px;display:inline-block; margin-right:10px;'>" +
          i18nService.transformNumber(this.data) +
          "</span><span style='color:#969dab;min-width:45px;display:inline-block; margin-right:10px;'>" +
          i18nService.transformNumber(this.Percent) +
          " %</span><span style='color:#969dab; margin-right:20px;'>" +
          this.name +
          '</span></div>'
        );
      },
    },
    plotOptions: {
      pie: {
        size: pieWidth,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        innerSize: 150,
        depth: 45,
        showInLegend: true,
      },
      series: plotOpt,
    },
    series: seriesData,
    credits: {
      enabled: false,
    },
  };
}

export function generateStaticCountV2(
  seriesData: any,
  plotOpt: any,
  totalCount: any,
  i18nService: I18nService = null,
  totalCountText: string
): any {
  const kpiPer = 440;
  const alignments =
    window.innerWidth > 520
      ? { align: 'right', verticalAlign: 'middle' }
      : { width: '100%', x: 0, align: 'middle', verticalAlign: 'bottom' };
  return {
    colors: ['#3bb001', '#ffc107', '#fd141f', '#007bff', '#dc3545', '#dc3545', '#dc3545', '#dc3545', '#dc3545'],
    chart: {
      backgroundColor: 'transparent',
    },
    credits: {
      enabled: false,
    },
    title: {
      useHTML: true,
      text: `
        <div class='pie-chart'>
            <div style='font-size: 22px; font-weight: bold'>
              ${i18nService.transformNumber(totalCount)}
            </div>
            <div class='chart__link' style='font-size: 8px; color: #212229; font-weight: bold;'>
              ${totalCountText.toUpperCase()}
            </div>
        </div>
      `,
      verticalAlign: 'middle',
      x: alignments.x,
      y: 15,
      style: {
        color: '#666666',
        textTransform: 'uppercase',
        fontWeight: '700',
        zIndex: -1,
        fontSize: '12px',
      },
    },
    tooltip: {
      enabled: true,
      //  headerFormat: '',
      // pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
      //     '   {point.namex}: <b>{point.y}</b><br/>'
    },
    legend: {
      enabled: false,
      align: alignments.align,
      layout: 'vertical',
      width: alignments.width,
      verticalAlign: alignments.verticalAlign,
      useHTML: true,
      labelFormatter: function () {
        // tslint:disable-next-line:max-line-length
        // return '<div style=\'float:left; margin-bottom:20px;min-width:45px;display:inline-block; text-align:left;\'><span style=\'color:#007bff;min-width:45px;display:inline-block; margin-right:10px;\'>' + this.data + "</span><span style='color:#969dab;min-width:45px;display:inline-block; margin-right:10px;'>" + calcPrePer(this.percentage, 1) + " %</span><span style='color:#969dab; margin-right:20px;'>" + this.name + "</span></div>";
        return `
          <div class="widget__content" style="width: 100vh;">
            <div class="widget__counts">
              <div class="counts__listp1 counts__list counts__table">
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="count__count"><a href="javascript:void(0)">${
                    this.data
                  }</a></div>
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="count__percentage">${i18nService.transformNumber(
                    parseFloat(calcPrePer(this.percentage, 1))
                  )} %</div>
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="series__name">${
                    this.name
                  }</div>
              </div>
            </div>
          </div>
        `;
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        borderWidth: 0,
        startAngle: 140,
        ...plotOpt,
      },
      gauge: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    pane: {
      startAngle: -220,
      endAngle: 140,
      background: [
        {
          backgroundColor: 'transparent',
          borderWidth: 0,
          innerRadius: '100%',
          outerRadius: '100%',
        },
      ],
    },
    yAxis: [
      {
        zIndex: 10,
        height: 100,
        lineWidth: 0,
        offset: 1.7,
        min: -28,
        plotBands: [
          {
            from: 0,
            to: [kpiPer],
            color: Highcharts.getOptions().colors[10],
            outerRadius: '114%',
            thickness: '1%',
          },
        ],
        max: 100,
        minorTickWidth: 0,
        minorTickLength: 8,
        minorTickPosition: 'inside',
        minorTickColor: '#fff',
        labels: {
          enabled: false,
        },
        tickWidth: '1',
        tickAmount: '120',
        tickPosition: 'inside',
        tickLength: '6',
        tickColor: '#fff',
        // tickPositioner: function () {
        // if (this.chart.series[1]) {
        //   var point = this.chart.series[1].points[0]
        //   var previousAxisTicks = this.chart.yAxis[0].tickPositions,
        //     ticks = previousAxisTicks
        //       .filter(function (elem: any) {
        //         return elem >= 0 && elem < (point.y)
        //       })
        //       .map(function (el: any) {
        //         return el
        //       });
        //   return ticks;
        // }
        // },
        innerRadius: '10%',
        zindex: 10,
        pane: 0,
      },
    ],
    series: [
      ...seriesData,
      // {
      //   name: "OBW Rate",
      //   type: "gauge",
      //   data: [{
      //     y: kpiPer,
      //     name: "OBW Rate"
      //   }],
      //   dataLabels: {
      //     padding: 5,
      //     useHTML: true,
      //     enabled: false,
      //     format: "{point.y} %",
      //     borderWidth: 0,
      //     style: {
      //       color: "#555",
      //       fontSize: 30
      //     }
      //   },
      //   tooltip: {
      //     valueSuffix: ' %',
      //     borderWidth: 0,
      //     backgroundColor: 'none',
      //     shadow: false,
      //     useHTML: true
      //   },
      //   animation: null,
      //   dial: {
      //     backgroundColor: '#999',
      //     borderColor: '#fff',
      //     borderWidth: 0,
      //     shapeType: 'path',
      //     baseWidth: 22,
      //     baseLength: "104%",
      //     rearLength: '-104%',
      //     radius: '104%',
      //   },
      //   pivot: {
      //     radius: 0
      //   }
      // }
    ],
  };
}

export function generateSentimentCountV2(
  seriesData: any,
  plotOpt: any,
  totalCount: any,
  i18nService: I18nService = null,
  totalCountText: string
): any {
  const kpiPer = 440;
  const alignments =
    window.innerWidth > 520
      ? { align: 'right', verticalAlign: 'middle' }
      : { width: '100%', x: 0, align: 'middle', verticalAlign: 'bottom' };
  return {
    colors: ['#3bb001', '#ffc107', '#fd141f', '#007bff', '#dc3545', '#dc3545', '#dc3545', '#dc3545', '#dc3545'],
    chart: {
      backgroundColor: 'transparent',
    },
    credits: {
      enabled: false,
    },
    title: {
      useHTML: true,
      text: `
        <div class='pie-chart'>
            <div style='font-size: 22px; font-weight: bold'>
              ${i18nService.transformNumber(totalCount)}
            </div>
            <div class='chart__link' style='font-size: 14px;'>
              ${totalCountText.toUpperCase()}
            </div>
        </div>
      `,
      verticalAlign: 'middle',
      x: alignments.x,
      y: 15,
      style: {
        color: '#666666',
        textTransform: 'uppercase',
        fontWeight: '700',
        zIndex: -1,
        fontSize: '12px',
      },
    },
    tooltip: {
      enabled: true,
      //  headerFormat: '',
      // pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
      //     '   {point.namex}: <b>{point.y}</b><br/>'
    },
    legend: {
      enabled: false,
      align: alignments.align,
      layout: 'vertical',
      width: alignments.width,
      verticalAlign: alignments.verticalAlign,
      useHTML: true,
      labelFormatter: function () {
        // tslint:disable-next-line:max-line-length
        // return '<div style=\'float:left; margin-bottom:20px;min-width:45px;display:inline-block; text-align:left;\'><span style=\'color:#007bff;min-width:45px;display:inline-block; margin-right:10px;\'>' + this.data + "</span><span style='color:#969dab;min-width:45px;display:inline-block; margin-right:10px;'>" + calcPrePer(this.percentage, 1) + " %</span><span style='color:#969dab; margin-right:20px;'>" + this.name + "</span></div>";
        return `
          <div class="widget__content" style="width: 100vh;">
            <div class="widget__counts">
              <div class="counts__listp1 counts__list counts__table">
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="count__count"><a href="javascript:void(0)">${
                    this.data
                  }</a></div>
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="count__percentage">${i18nService.transformNumber(
                    parseFloat(calcPrePer(this.percentage, 1))
                  )} %</div>
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="series__name">${
                    this.name
                  }</div>
              </div>
            </div>
          </div>
        `;
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        borderWidth: 0,
        startAngle: 140,
        ...plotOpt,
      },
      gauge: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    pane: {
      startAngle: -220,
      endAngle: 140,
      background: [
        {
          backgroundColor: 'transparent',
          borderWidth: 0,
          innerRadius: '100%',
          outerRadius: '100%',
        },
      ],
    },
    yAxis: [
      {
        zIndex: 10,
        height: 100,
        lineWidth: 0,
        offset: 1.7,
        min: -28,
        plotBands: [
          {
            from: 0,
            to: [kpiPer],
            color: Highcharts.getOptions().colors[10],
            outerRadius: '114%',
            thickness: '1%',
          },
        ],
        max: 100,
        minorTickWidth: 0,
        minorTickLength: 8,
        minorTickPosition: 'inside',
        minorTickColor: '#fff',
        labels: {
          enabled: false,
        },
        tickWidth: '1',
        tickAmount: '120',
        tickPosition: 'inside',
        tickLength: '6',
        tickColor: '#fff',
        // tickPositioner: function () {
        // if (this.chart.series[1]) {
        //   var point = this.chart.series[1].points[0]
        //   var previousAxisTicks = this.chart.yAxis[0].tickPositions,
        //     ticks = previousAxisTicks
        //       .filter(function (elem: any) {
        //         return elem >= 0 && elem < (point.y)
        //       })
        //       .map(function (el: any) {
        //         return el
        //       });
        //   return ticks;
        // }
        // },
        innerRadius: '10%',
        zindex: 10,
        pane: 0,
      },
    ],
    series: [
      ...seriesData,
      // {
      //   name: "OBW Rate",
      //   type: "gauge",
      //   data: [{
      //     y: kpiPer,
      //     name: "OBW Rate"
      //   }],
      //   dataLabels: {
      //     padding: 5,
      //     useHTML: true,
      //     enabled: false,
      //     format: "{point.y} %",
      //     borderWidth: 0,
      //     style: {
      //       color: "#555",
      //       fontSize: 30
      //     }
      //   },
      //   tooltip: {
      //     valueSuffix: ' %',
      //     borderWidth: 0,
      //     backgroundColor: 'none',
      //     shadow: false,
      //     useHTML: true
      //   },
      //   animation: null,
      //   dial: {
      //     backgroundColor: '#999',
      //     borderColor: '#fff',
      //     borderWidth: 0,
      //     shapeType: 'path',
      //     baseWidth: 22,
      //     baseLength: "104%",
      //     rearLength: '-104%',
      //     radius: '104%',
      //   },
      //   pivot: {
      //     radius: 0
      //   }
      // }
    ],
  };
}

export function generateCorrectContactCountV3(
  seriesData: any,
  plotOpt: any,
  totalCount: any,
  i18nService: I18nService = null,
  totalCountText: string
): any {
  const kpiPer = 440;
  const alignments =
    window.innerWidth > 520
      ? { align: 'right', verticalAlign: 'middle' }
      : { width: '100%', x: 0, align: 'middle', verticalAlign: 'bottom' };
  return {
    colors: ['#3bb001', '#ffc107', '#fd141f', '#007bff', '#dc3545', '#dc3545', '#dc3545', '#dc3545', '#dc3545'],
    chart: {
      backgroundColor: 'transparent',
    },
    credits: {
      enabled: false,
    },
    title: {
      useHTML: true,
      align: 'center',
      text: `
        <div class='pie-chart'>
            <div style='font-size: 22px; font-weight: bold'>
              ${i18nService.transformNumber(totalCount)}
            </div>
            <div class="container" style='font-size: 10px;text-align:center'>
              ${i18nService.getTranslate('Total Record </br> Outstanding')}   
            </div>
        </div>
      `,
      verticalAlign: 'middle',
      x: alignments.x,
      y: 15,
      style: {
        color: '#666666',
        textTransform: 'uppercase',
        fontWeight: '700',
        zIndex: -1,
        fontSize: '12px',
      },
    },
    tooltip: {
      enabled: true,
      //  headerFormat: '',
      // pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
      //     '   {point.namex}: <b>{point.y}</b><br/>'
    },
    legend: {
      enabled: false,
      align: alignments.align,
      layout: 'vertical',
      width: alignments.width,
      verticalAlign: alignments.verticalAlign,
      useHTML: true,
      labelFormatter: function () {
        // tslint:disable-next-line:max-line-length
        // return '<div style=\'float:left; margin-bottom:20px;min-width:45px;display:inline-block; text-align:left;\'><span style=\'color:#007bff;min-width:45px;display:inline-block; margin-right:10px;\'>' + this.data + "</span><span style='color:#969dab;min-width:45px;display:inline-block; margin-right:10px;'>" + calcPrePer(this.percentage, 1) + " %</span><span style='color:#969dab; margin-right:20px;'>" + this.name + "</span></div>";
        return `
          <div class="widget__content" style="width: 100vh;">
            <div class="widget__counts">
              <div class="counts__listp1 counts__list counts__table">
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="count__count"><a href="javascript:void(0)">${
                    this.data
                  }</a></div>
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="count__percentage">${i18nService.transformNumber(
                    parseFloat(calcPrePer(this.percentage, 1))
                  )} %</div>
                  <div style="display:inline-block; min-width:45px; margin-top: 2px" class="series__name">${
                    this.name
                  }</div>
              </div>
            </div>
          </div>
        `;
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        borderWidth: 0,
        startAngle: 140,
        ...plotOpt,
      },
      gauge: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    pane: {
      startAngle: -220,
      endAngle: 140,
      background: [
        {
          backgroundColor: 'transparent',
          borderWidth: 0,
          innerRadius: '100%',
          outerRadius: '100%',
        },
      ],
    },
    yAxis: [
      {
        zIndex: 10,
        height: 100,
        lineWidth: 0,
        offset: 1.7,
        min: -28,
        plotBands: [
          {
            from: 0,
            to: [kpiPer],
            color: Highcharts.getOptions().colors[10],
            outerRadius: '114%',
            thickness: '1%',
          },
        ],
        max: 100,
        minorTickWidth: 0,
        minorTickLength: 8,
        minorTickPosition: 'inside',
        minorTickColor: '#fff',
        labels: {
          enabled: false,
        },
        tickWidth: '1',
        tickAmount: '120',
        tickPosition: 'inside',
        tickLength: '6',
        tickColor: '#fff',
        // tickPositioner: function () {
        // if (this.chart.series[1]) {
        //   var point = this.chart.series[1].points[0]
        //   var previousAxisTicks = this.chart.yAxis[0].tickPositions,
        //     ticks = previousAxisTicks
        //       .filter(function (elem: any) {
        //         return elem >= 0 && elem < (point.y)
        //       })
        //       .map(function (el: any) {
        //         return el
        //       });
        //   return ticks;
        // }
        // },
        innerRadius: '10%',
        zindex: 10,
        pane: 0,
      },
    ],
    series: [
      ...seriesData,
      // {
      //   name: "OBW Rate",
      //   type: "gauge",
      //   data: [{
      //     y: kpiPer,
      //     name: "OBW Rate"
      //   }],
      //   dataLabels: {
      //     padding: 5,
      //     useHTML: true,
      //     enabled: false,
      //     format: "{point.y} %",
      //     borderWidth: 0,
      //     style: {
      //       color: "#555",
      //       fontSize: 30
      //     }
      //   },
      //   tooltip: {
      //     valueSuffix: ' %',
      //     borderWidth: 0,
      //     backgroundColor: 'none',
      //     shadow: false,
      //     useHTML: true
      //   },
      //   animation: null,
      //   dial: {
      //     backgroundColor: '#999',
      //     borderColor: '#fff',
      //     borderWidth: 0,
      //     shapeType: 'path',
      //     baseWidth: 22,
      //     baseLength: "104%",
      //     rearLength: '-104%',
      //     radius: '104%',
      //   },
      //   pivot: {
      //     radius: 0
      //   }
      // }
    ],
  };
}
export function generateCommentsBusinessArea(
  seriesData: any,
  plotOpt: any,
  totalCount: any,
  totalCountText: string
): any {
  return {
    colors: ['#3bb001', '#ffc107', '#fd7e14', '#007bff', '#dc3545', '#dc3545', '#dc3545', '#dc3545', '#dc3545'],
    chart: {
      type: 'pie',
    },
    title: {
      useHTML: true,
      text:
        "<span class='pie-chart'><span class='chart__label'>" +
        totalCountText +
        "</span> <span class='chart__link'>" +
        totalCount +
        '</span></span>',
      verticalAlign: 'middle',
      x: -130,
      y: 0,
      style: {
        color: '#666666',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        zIndex: -1,
        fontSize: '12px',
      },
    },
    tooltip: {
      enabled: true,
    },
    legend: {
      enabled: true,
      align: 'right',
      width: 250,
      layout: 'vertical',
      verticalAlign: 'middle',
      useHTML: true,
      labelFormatter: function () {
        return (
          "<div style='float:left; margin-bottom:20px;min-width:45px;display:inline-block; text-align:left;'><span style='color:#007bff;min-width:45px;display:inline-block; margin-right:10px;'>" +
          this.data +
          "</span><span style='color:#969dab;min-width:45px;display:inline-block; margin-right:10px;'>" +
          calcPrePer(this.percentage, 1) +
          " %</span><span style='color:#969dab; margin-right:20px;'>" +
          this.name +
          '</span></div>'
        );
      },
    },
    plotOptions: {
      pie: {
        size: 180,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        innerSize: 150,
        depth: 45,
        showInLegend: true,
      },
      series: plotOpt,
    },
    series: seriesData,
    credits: {
      enabled: false,
    },
  };
}

export function generateSentimentAnalysis(seriesData: any, plotOpt: any): any {
  return {
    title: {
      text: '',
    },
    series: [
      {
        colors: ['#dc3545', '#3bb001', '#fd7e14'],
        rotation: {
          from: 0,
          to: 0,
          orientations: 0,
        },
        type: 'wordcloud',
        data: seriesData,
      },
    ],
    plotOptions: {
      // pie: {
      //   size: 180,
      //   allowPointSelect: true,
      //   cursor: 'pointer',
      //   dataLabels: {
      //     enabled: false,
      //   },
      //   innerSize: 150,
      //   depth: 45,
      //   showInLegend: true
      // },
      series: plotOpt,
    },
    tooltip: {
      enabled: true,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
}

export function generateReviewOverTimes(
  seriesData: any,
  seletedMon: any,
  yAxisTitle: any,
  i18nService: I18nService
): any {
  return {
    chart: {
      type: 'spline',
      height: '250px',
    },
    xAxis: {
      gridLineWidth: 1,

      title: {
        text: '',
      },
      labels: {
        enabled: true,
      },
      categories: seletedMon,
    },
    yAxis: {
      gridLineWidth: 1,

      title: {
        text: yAxisTitle,
        style: {
          fontWeight: 'bold',
        },
      },
      labels: {
        enabled: true,
        format: '{value}',
      },
      min: 0,
      // max: 100,
    },
    tooltip: {
      formatter: function () {
        return this.x + '<br>' + '<b>' + this.series.name + '</b>: ' + this.y;
      },
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        cursor: 'normal',
        states: {
          inactive: {
            enabled: true,
            opacity: 0.3,
          },
        },
      },
    },
    series: seriesData,
  };
}

export function generateAverageRatingOverTime(
  seriesData: any,
  seletedMon: any,
  yAxisTitle: any,
  i18nService: I18nService
): any {
  return {
    chart: {
      type: 'column',
      height: '250px',
    },
    xAxis: {
      gridLineWidth: 1,
      crosshair: true,
      title: {
        text: '',
      },
      labels: {
        enabled: true,
      },
      categories: seletedMon,
    },
    yAxis: {
      gridLineWidth: 1,

      title: {
        text: yAxisTitle,
        style: {
          fontWeight: 'bold',
        },
      },
      labels: {
        step: 2,
        enabled: true,
        format: '{value}',
      },
      min: 0,
      // max: 100,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormatter: function () {
        return (
          '<tr><td style="color:{series.color};padding:0">' +
          this.series.name +
          ': </td>' +
          '<td style="padding:1"><b>' +
          i18nService.transformNumber(this.y.toFixed(2)) +
          '</b></td></tr>'
        );
      },
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0.5,
      },
      series: {
        cursor: 'normal',
        states: {
          inactive: {
            enabled: true,
            opacity: 0.3,
          },
        },
      },
    },
    series: seriesData,
  };
}

export function generateCategoriesOverTimePeriods(
  seriesData: any,
  plotOpt: any,
  seletedMon: any,
  i18nService: I18nService = null
): any {
  return {
    colors: [
      '#007bff',
      '#ffc107',
      '#3bb000',
      '#dc3545',
      '#6f42c1',
      '#17a2b8',
      '#27d4d4',
      '#fd7e14',
      '#4ca1fe',
      '#34495e',
      '#cad0e8',
      '#d4c99e',
      '#a25016',
      '#566127',
      '#b62b6e',
    ],
    chart: {
      type: 'area',
    },
    xAxis: {
      title: {
        text: '',
      },
      labels: {
        enabled: true,
      },
      categories: seletedMon,
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        //  format: '{value:.1f} %'
        formatter: function () {
          return i18nService.transformNumber(this.value) + ' %';
        },
      },
      //  min: 50,
      max: 100,
    },
    tooltip: {
      formatter: function () {
        return (
          this.x +
          '<br>' +
          '<b>' +
          this.series.name +
          '</b>: ' +
          i18nService.transformNumber(this.series.userOptions.count[this.series.xAxis.categories.indexOf(this.x)]) +
          ' (' +
          i18nService.transformNumber(this.y.toFixed(1)) +
          '%)'
        );
      },
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },

    series: seriesData,
    plotOptions: {
      series: plotOpt,
    },
  };
}

export function generateCategoriesOverTimePeriodsBar(
  seriesData: any,
  plotOpt: any,
  categories: string[],
  i18nService: I18nService = null
): any {
  return {
    // colors: ['#058DC7'],
    colors: [
      '#007bff',
      '#ffc107',
      '#3bb000',
      '#dc3545',
      '#6f42c1',
      '#17a2b8',
      '#27d4d4',
      '#fd7e14',
      '#4ca1fe',
      '#34495e',
      '#cad0e8',
      '#d4c99e',
      '#a25016',
      '#566127',
      '#b62b6e',
    ],
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },

    plotOptions: {
      // series: {
      //   borderWidth: 0,
      //   pointWidth: 30,
      //   dataLabels: {
      //     enabled: true,
      //     format: '{y} %'
      //   }
      // }
      series: plotOpt,
    },
    xAxis: {
      type: 'category',
      gridLineWidth: 1,
      labels: {
        rotation: -80,
      },
      // categories: categories,
      // labels: {
      //   style: {
      //     align: 'left',
      //     "fontSize": "13px",
      //     // "fontWeight": "bold"
      //   }
      // }
    },
    yAxis: {
      allowDecimals: false,
      title: {
        text: '',
      },
      min: 0,
      max: 100,
      labels: {
        formatter: function () {
          return i18nService.transformNumber(this.value.toFixed(1)) + ' %';
        },
      },
    },
    series: seriesData,
    tooltip: {
      formatter: function () {
        // return this.series.data[this.x].name + '<br>' + '<b>' + this.series.name + '</b>: ' + this.series.data[this.x].z + '(' + this.y.toFixed(1) + '%)';
        return (
          '<b>' +
          this.series.data[this.x].name +
          '</b>: ' +
          i18nService.transformNumber(this.series.data[this.x].z) +
          ' (' +
          i18nService.transformNumber(this.y.toFixed(1)) +
          '%)'
        );
      },
    },
    credits: {
      enabled: false,
    },
  };
}

export function generateCategorySummary(
  seriesData: any,
  point: any,
  categories: string[],
  i18nService: I18nService = null,
  tab: string
): any {
  function Type() {
    if (tab === 'voc') {
      return 'pointer';
    } else {
      return 'normal';
    }
  }
  return {
    colors: ['#dc3545', '#fd7e14', '#3bb001'],
    chart: {
      type: 'bar',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: categories,
      labels: {
        enabled: true,
      },
      stackLabels: {
        enabled: true,
      },
    },
    yAxis: {
      type: 'category',
      min: 0,
      max: 100,
      tickInterval: 5,
      //   tickPosition: 'between',
      //   style: {
      //     fontSize: '5px',
      //     textOverflow: 'normal',
      //     whiteSpace: 'normal',
      //     fontFamily: 'Verdana, sans-serif'
      // },
      labels: {
        rotation: -45,
        format: '{value} %',
      },
    },
    legend: {
      enabled: true,
      reversed: true,
    },
    tooltip: {
      formatter: function () {
        return `
      <span style="color:${this.series.color}">
       ${this.series.name}
       </span>
       :
       <b>${i18nService.transformNumber(this.series.userOptions.count[this.point.x])} (${i18nService.transformNumber(
          this.point.y.toFixed(1)
        )}%)  </b> <br/>`; // ({point.percentage:.1f})
      },
      //   pointFormat: '<span style="color:{series.color};text-align:right;">{series.name}</span>: <b>{point.y}</b> <br/>', //({point.percentage:.1f}%)
    },
    plotOptions: {
      series: {
        cursor: Type(),
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y > 0 ? `${i18nService.transformNumber(this.y.toFixed(1))}` + `%` : '';
          },
        },

        point: point,
      },
    },
    series: seriesData,
    credits: {
      enabled: false,
    },
  };
}

export function generateCategoryScoreCorrelation(
  seriesData: any,
  plotOpt: any,
  categories: string[],
  i18nService: I18nService = null
): any {
  return {
    colors: ['#999', '#3bb001', '#058DC7'],
    chart: {
      type: 'bar',
    },
    title: {
      text: '',
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: '',
      },
      lineColor: '#ffffff',
      gridLineColor: '#ffffff',
      categories: categories,
      tickPositions: [],
      labels: {
        enabled: false,
      },
      stackLabels: {
        enabled: true,
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      min: 10,
      max: 100,
      // labels: {
      //   format: '{value} %'
      // }
      lineColor: '#ffffff',
      labels: {
        enabled: false,
      },
      gridLineColor: '#ffffff',
    },
    legend: {
      enabled: true,
      reversed: true,
    },
    // plotOptions: {
    //   // series: {
    //   //   stacking: 'normal',
    //   //   // dataLabels: {
    //   //   //   enabled: true,
    //   //   //   format: '{y} %'
    //   //   // },
    //   //   borderWidth: 0,
    //   //   pointWidth: 15,
    //   // },
    //   series: plotOpt
    // },

    plotOptions: {
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: function () {
              alert('Category: ' + this.category + ', value: ' + i18nService.transformNumber(this.y));
            },
          },
        },
      },
    },

    series: seriesData,
    credits: {
      enabled: false,
    },
  };
}

export function generateCscBar(
  seriesData: any,
  point: any,
  categories: string[],
  i18nService: I18nService = null
): any {
  return {
    colors: ['#dc3545', '#fd7e14', '#3bb001'],
    chart: {
      type: 'bar',
    },
    title: {
      text: '',
    },
    tooltip: {
      formatter: function () {
        return `<span style="color: ${this.series.color};text-align:right;">  ${
          this.series.name
        }  </span>: <b>  ${i18nService.transformNumber(this.point.y)}  %</b><br/>`;
      },
      // pointFormat: '<span style="color:{series.color};text-align:right;">{series.name}</span>: <b>{point.y}%</b><br/>',
    },
    xAxis: {
      softMin: 10.8,
      title: {
        text: '',
      },
      lineColor: '#ffffff',
      gridLineColor: '#ffffff',
      categories: categories,
      tickPositions: [],
      labels: {
        enabled: false,
      },
      stackLabels: {
        enabled: true,
      },
    },
    yAxis: {
      softMin: 10.8,
      title: {
        text: '',
      },
      min: 10,
      max: 100,
      lineColor: '#ffffff',
      labels: {
        enabled: false,
      },
      gridLineColor: '#ffffff',
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        showInLegend: true,
        cursor: 'normal',
        stacking: 'normal',
        borderWidth: 0,
        pointWidth: 20,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y > 0 ? `${i18nService.transformNumber(this.y.toFixed(1))}` + `%` : '';
          },
          style: {
            fontSize: 10,
            // fontWeight : 1
          },
        },
        point: point,
      },
    },
    series: seriesData,
    credits: {
      enabled: false,
    },
  };
}

export function generateMultiColumnGraph(data: any, i18nService: I18nService = null): any {
  return {
    colors: data.color,
    chart: {
      type: 'column',
      height: '250px',
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      max: data.xAxisMax,
      categories: data.xAxis,
      gridLineWidth: 1,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      labels: { step: 2 },
      gridLineWidth: 1,
      max: data.yAxisMax,
      title: {
        text: data.yAxisTitle,
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormatter: function () {
        return (
          '<tr><td style="color:{series.color};padding:0">' +
          this.series.name +
          ': </td>' +
          '<td style="padding:1"><b>' +
          i18nService.transformNumber(this.y.toFixed(1)) +
          '</b></td></tr>'
        );
      },
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0.5,
      },
      series: {
        cursor: 'normal',
        states: {
          inactive: {
            enabled: true,
            opacity: 0.3,
          },
        },
      },
    },
    series: data.values,
  };
}

export function generateSingleColumnGraph(data: any, clickEvent: any = null, i18nService: I18nService = null): any {
  let ploting, clickFunction;
  if (data.inverted == null) {
    data.inverted = false;
  }
  if (data.yFormatter == null) {
    data.yFormatter = '';
  }
  if (typeof data.yAxisReversed !== 'boolean') {
    data.yAxisReversed = false;
  }
  if (typeof data.xAxisOpposite !== 'boolean') {
    data.xAxisOpposite = false;
  }
  if (typeof data.xAxisLabelEnable !== 'boolean') {
    data.xAxisLabelEnable = true;
  }
  if (data.title === null) {
    data.title = '';
  }
  if (clickEvent == null) {
    clickFunction = function () {};
  } else {
    clickFunction = clickEvent;
  }

  ploting = {
    series: {
      name: data.tooltipValue,
      cursor: 'pointer',
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        //format: '{point.y:.1f}'
        formatter: function () {
          const plottxt = data.plotingEdgeText !== null ? data.plotingEdgeText : '';
          return this.point.y > 0 ? `${i18nService.transformNumber(this.point.y.toFixed(1))}` + plottxt : '';
        },
      },
      point: {
        events: {
          click: clickEvent,
        },
      },
    },
  };
  if (data.plotingEnable == null) {
    ploting.series.dataLabels.enabled = false;
  }
  // else if (data.plotingEdgeText != null) {
  //   ploting.series.dataLabels.format = ploting.series.dataLabels.format + data.plotingEdgeText;
  // }
  function getcolor(value: any) {
    let colour;
    value.forEach((element: any) => {
      if (element.y > 85 && element.y < 95) {
        colour = '#007bff';
      } else if (element.y > 80 && element.y < 85) {
        colour = '#dc3545';
      }
    });
    return colour;
  }
  return {
    title: {
      text: '',
    },
    chart: {
      inverted: data.inverted,
    },
    subtitle: {
      text: data.title,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return (
          this.x +
          '<br>' +
          '<b>' +
          i18nService.transformNumber(this.point.count) +
          '</b>: ' +
          i18nService.transformNumber(this.y.toFixed(1)) +
          data.plotingEdgeText
        );
      },
    },

    yAxis: {
      reversed: data.yAxisReversed,

      max: data.max ? data.max : 100,
      labels: {
        rotation: data.max ? null : null,
        formatter: function () {
          return this.value + data.yFormatter;
        },
      },
    },
    xAxis: {
      opposite: data.xAxisOpposite,
      categories: data.xAxis,
      labels: {
        enabled: data.xAxisLabelEnable,
      },
    },
    legend: {
      enabled: true,
    },
    plotOptions: ploting,

    series: [
      {
        type: 'column',
        color: getcolor(data.values),
        data: data.values,
        showInLegend: false,
        marker: {
          enabled: true,
        },
      },
    ],
  };
}

export function generateSentimentTrend(data: any, point: any, i18nService: I18nService = null): any {
  return {
    colors: ['#3bb001', '#fd7e14', '#dc3545', '#7d7d7d'],
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: data.xAxis,
    },
    yAxis: {
      min: 0,
      title: {
        text: data.yAxisTitle,
      },
      labels: {
        formatter: function () {
          return this.value + i18nService.transformNumber(data.yFormatter);
        },
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{i18nService.transformNumber(point.y)}</b> ({i18nService.transformNumber(point.percentage:.1f)}%)<br/>',
    },
    plotOptions: {
      column: {
        stacking: 'percent',
      },
      series: {
        point: point,
      },
    },
    series: data.values,
  };
}

export function generateCompetitiveLandscape(data: any): any {
  return {
    chart: {
      type: 'scatter',
      zoomType: 'xy',
    },
    title: {
      text: '',
    },
    xAxis: {
      title: {
        enabled: true,
        text: 'Reviews',
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
    },
    yAxis: {
      title: {
        text: 'Rating',
      },
    },
    // legend: {
    //   layout: 'vertical',
    //   align: 'left',
    //   verticalAlign: 'top',
    //   x: 100,
    //   y: 70,
    //   floating: true,
    //   // backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
    //   borderWidth: 1
    // },
    plotOptions: {
      scatter: {
        marker: {
          radius: 5,
          states: {
            hover: {
              enabled: true,
              lineColor: 'rgb(100,100,100)',
            },
          },
        },
        states: {
          hover: {
            marker: {
              enabled: false,
            },
          },
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.x} Review, {point.y} Rating',
        },
      },
    },
    // series: data,
    series: [
      {
        name: 'Dealer',
        color: 'rgba(223, 83, 83, .5)',
        data: data[0].data,
      },
      {
        name: 'Competitor',
        color: 'rgba(119, 152, 191, .5)',
        data: data[1].data,
      },
    ],
    credits: {
      enabled: false,
    },
  };
}

export function generateTrendingTopics(seriesData: any, plotOpt: any): any {
  return {
    title: {
      text: '',
    },
    series: [
      {
        colors: ['#dc3545', '#3bb001', '#fd7e14'],
        rotation: {
          from: 0,
          to: 0,
          orientations: 0,
        },
        type: 'wordcloud',
        data: seriesData,
      },
    ],
    plotOptions: {
      // pie: {
      //   size: 180,
      //   allowPointSelect: true,
      //   cursor: 'pointer',
      //   dataLabels: {
      //     enabled: false,
      //   },
      //   innerSize: 150,
      //   depth: 45,
      //   showInLegend: true
      // },
      series: plotOpt,
    },
    tooltip: {
      enabled: true,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
}

export const drawPieChartDot = (chart: any) => {
  if (chart.series[1]) {
    chart.series[1].translate = function () {
      const HC: any = Highcharts;
      var { each, merge, pInt, pick, isNumber } = HC;
      var series = this;
      var yAxis = series.yAxis;
      var options = series.options;
      var center = yAxis.center;
      series.generatePoints();
      each(series.points, function (point: any) {
        var dialOptions = merge(options.dial, point.dial),
          radius = (pInt(pick(dialOptions.radius, 80)) * center[2]) / 200,
          baseLength = (pInt(pick(dialOptions.baseLength, 70)) * radius) / 100,
          rearLength = (pInt(pick(dialOptions.rearLength, 10)) * radius) / 100,
          baseWidth = dialOptions.baseWidth || 3,
          topWidth = dialOptions.topWidth || 1,
          overshoot = options.overshoot,
          rotation = yAxis.startAngleRad + yAxis.translate(point.y, null, null, null, true);

        if (isNumber(overshoot)) {
          overshoot = (overshoot / 180) * Math.PI;
          rotation = Math.max(yAxis.startAngleRad - overshoot, Math.min(yAxis.endAngleRad + overshoot, rotation));
        } else if (options.wrap === false) {
          rotation = Math.max(yAxis.startAngleRad, Math.min(yAxis.endAngleRad, rotation));
        }
        rotation = (rotation * 180) / Math.PI;
        point.shapeType = 'path';
        point.shapeArgs = {
          d: dialOptions.path || [
            'M',
            -rearLength,
            -baseWidth / 2 - 2,
            'a',
            5,
            5,
            0,
            1,
            0,
            13,
            0,
            'a',
            5,
            5,
            0,
            1,
            0,
            -13,
            0,
          ],
          translateX: center[0],
          translateY: center[1],
          rotation: rotation,
        };
        // Positions for data label
        point.plotX = center[0];
        point.plotY = center[1];
      });
    };
    chart.series[1].redraw();
  }
};

export const getSolidGauge = (color: string, data: any): any => {
  return {
    chart: {
      type: 'solidgauge',
      margin: [0, 0, 0, 0],
      height: '60px',
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
      // size: '150%',
      margin: [0, 0, 0, 0],
      spacingTop: 0,
      spacingLeft: 0,
      center: ['50%', '50%'],
      spacingRight: 0,
      spacingBottom: 0,
      background: [
        {
          outerRadius: '120%',
          innerRadius: '120%',
          borderWidth: 0,
        },
      ],
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: new Array(),
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
        linecap: 'round',
        stickyTracking: false,
        rounded: true,
      },
    },
    series: [
      {
        name: null,
        data: [
          {
            color: color,
            borderWidth: 20,
            radius: '75%',
            innerRadius: '65%',
            y: parseFloat(data.toFixed(1)),
          },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
};

// export function generatecategorysummarykpi(point: any, seriesData: any, categories: any, title: string): any {
//   return {
//     colors: ['#FFD700', '#C0C0C0', '#CD7F32'],
//     chart: {
//       type: 'column',
//       inverted: true,
//       polar: true,
//       height: 115,
//       //  height: 115,
//       // width: 284,
//       //   y: 0,
//       // margin: 0,
//       // plotAreaWidth: 280,
//       // plotAreaHeight: 170

//       // spacingTop:-20
//     },
//     title: {
//       text: '',
//     },

//     subtitle: {
//       text: '',
//     },
//     tooltip: {
//       outside: true,
//     },
//     pane: {
//       size: '85%',
//       innerSize: '20%',
//       endAngle: 270,
//     },
//     legend: {
//       enabled: false,
//       //layout: 'horizontal',
//       // layout: 'vertical',
//       // align: 'left',
//       // verticalAlign: 'middle',
//       //  x: -15,
//       //  y: 0
//     },
//     xAxis: {
//       tickInterval: 1,
//       labels: {
//         enabled: false,
//         // align: 'right',
//         // useHTML: true,
//         // allowOverlap: true,
//         // step: 1,
//         // y: 3,
//         // style: {
//         //     fontSize: '10px'
//         // }
//       },
//       lineWidth: 0,
//       categories: categories,
//     },
//     yAxis: {
//       crosshair: {
//         enabled: false,
//         //color: '#333'
//       },
//       visible: false,
//       //lineWidth: 0,
//       tickInterval: 25,
//       reversedStacks: false,
//       endOnTick: true,
//       showLastLabel: true,
//     },
//     plotOptions: {
//       column: {
//         stacking: 'normal',
//         borderWidth: 0,
//         pointPadding: 0,
//         groupPadding: 0.15,
//       },
//       series: {
//         point: point,
//       },
//     },
//     series: seriesData,
//   };
// }
export function generatecategorysummarykpi(point: any, seriesData: any, categories: any, title: string): any {
  return {
    colors: ['#78ec6c', '#00aeef', '#f18961'],
    chart: {
      type: 'column',
      inverted: true,
      polar: true,
      height: 140,
      width: 200,
      y: 0,
      // margin: 0,
      // plotAreaWidth: 280,
      // plotAreaHeight: 170

      // marginTop: '-290',
      // spacingTop: -20,
    },
    title: {
      text: '',
    },

    subtitle: {
      text: '',
    },
    tooltip: {
      outside: true,
    },
    pane: {
      size: '120%',

      innerSize: '20%',
      endAngle: 360,
    },
    legend: {
      enabled: false,
    },
    // {
    //    layout: 'vertical',
    //    align: 'right',
    //    verticalAlign: 'middle',
    //   x: -15,
    //   y: 0
    // },
    xAxis: {
      tickInterval: 1,
      labels: {
        enabled: false,
        // align: 'right',
        // useHTML: true,
        // allowOverlap: true,
        // step: 1,
        // y: 3,
        // style: {
        //     fontSize: '10px'
        // }
      },
      lineWidth: 0,
      categories: categories,
    },
    yAxis: {
      crosshair: {
        enabled: false,
        //color: '#333'
      },
      visible: false,
      //lineWidth: 0,
      tickInterval: 25,
      reversedStacks: false,
      endOnTick: true,
      showLastLabel: true,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.15,
      },
      series: {
        point: point,
      },
    },
    series: seriesData,
  };
}
export function generateTrendingTopicsStats(
  seriesData: any,
  plotOpt: any,
  totalCount: any,
  i18nService: I18nService = null,
  totalCountText: string
): any {
  function get_xaxis(count: any) {
    return 43;
  }
  const legendWidth = 90;
  const pieWidth = 120;
  return {
    colors: ['#54c91a', '#ff972d', '#fd141f'],
    chart: {
      type: 'pie',
      height: 140,
      marginTop: '15',
    },

    title: {
      useHTML: true,
      text: `
        <div class='pie-chart'>
            <div style='font-size:16px; font-weight: bold'>
              ${i18nService.transformNumber(totalCount)}
           </div>
           <div class='chart__link' align='center' style='font-size:10px;'>
              ${totalCountText.toUpperCase()}
            </div>
            </div>
      `,
      verticalAlign: 'middle',
      y: 17,
      labels: {
        style: {
          color: '#666666',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          zIndex: -1,
          align: 'middle',
          fontSize: '12px',
        },
      },
    },
    tooltip: {
      // formatter: function () {
      //   return `<span style="color: ${this.point.color};text-align:right;">  ${this.point.name}<br/> ${
      //     this.series.name
      //    } </span>: <b>  ${i18nService.transformNumber(this.point.y)}</b><br/>`;
      //  },
    },
    legend: {
      enabled: false,
      align: 'right',
      width: legendWidth,
      layout: 'vertical',
      verticalAlign: 'middle',
      useHTML: true,
      labelFormatter: function () {
        return (
          "<div style='float:left; margin-bottom:20px;min-width:45px;display:inline-block; text-align:left;'><span style='color:#007bff;min-width:45px;display:inline-block; margin-right:10px;'>" +
          i18nService.transformNumber(this.data) +
          "</span><span style='color:#969dab;min-width:45px;display:inline-block; margin-right:10px;'>" +
          i18nService.transformNumber(this.Percent) +
          " %</span><span style='color:#969dab; margin-right:20px;'>" +
          this.name +
          '</span></div>'
        );
      },
    },
    plotOptions: {
      pie: {
        size: pieWidth,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        innerSize: 76,
        depth: 35,
        showInLegend: true,
      },
      series: plotOpt,
    },
    series: seriesData,
    credits: {
      enabled: false,
    },
  };
}
export function generateTopPerformerKPI(
  element: any,
  event: any = null,
  kpiName: string = '',
  i18nService: I18nService,
  chartWidth: any = 155
): any {
  const clickFunction = event === null ? function () {} : event;
  return {
    chart: {
      backgroundColor: null,
      borderWidth: 0,
      type: 'area',
      margin: 0,
      width: chartWidth,
      height: 50,
      skipClone: true,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      visible: false,
      categories: Object.keys(element.MonthlyChart),
      title: {
        text: null,
      },
      startOnTick: false,
      endOnTick: false,
      lineWidth: 0,
      tickPositions: [],
    },
    yAxis: [
      {
        visible: false,
        title: false,
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false,
        },
        // title: {
        //     text: null
        // },
        tickPositions: [],
      },
    ],
    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        fillOpacity: 0.3,
      },
      series: {
        cursor: 'normal',
        borderWidth: 0,
        dataLabels: {
          enabled: false,
          // format: '{point.y:.1f}'
        },
        point: {
          events: {
            click: clickFunction,
          },
        },
      },
    },
    tooltip: {
      followPointer: true,
      followTouchMove: true,
      formatter: function () {
        return (
          "<div style='font-size:smaller;font-weight: bold;'>" +
          this.x +
          "</div><div style='font-size:smaller'>: </div><span style='font-size:smaller;font-weight:bold;color:#c3002f;'>" +
          i18nService.transformNumber(this.y) +
          '</span>' +
          '<br>' +
          "<span style='font-size:smaller;'>" +
          i18nService.getTimeIntervalDetails(kpiName, this.y) +
          '</span>'
        );
      },
    },
    series: [
      {
        name: element.Name,
        showInLegend: false,
        exporting: {
          enabled: false,
        },
        data: Object.values(element.MonthlyChart),
        marker: {
          symbol: 'circle',
        },
        color: '#a9b4bd',
      },
    ],
  };
}
export function generatefrftOverviewTrends(seriesData: any, seletedMon: any, i18nService: I18nService = null): any {
  return {
    colors: ['#3bb001', '#007bff', '#dc3545', '#ffc107'],
    chart: {
      type: 'line',
    },
    xAxis: {
      title: {
        text: '',
      },
      labels: {
        enabled: true,
      },
      categories: seletedMon,
    },
    yAxis: {
      title: {
        text: '',
      },
      labels: {
        enabled: true,
      },
      min: 945,
      max: 1005,
    },

    tooltip: {
      shared: true,
      crosshairs: true,
      useHTML: true,
      pointFormatter: function () {
        return (
          '<span style="padding-right:1px;color:' +
          this.color +
          '">\u25CF <b>' +
          this.series.name +
          '</b>: </span>' +
          '<span style="padding:4px; font-weight:bold;">' +
          i18nService.transformNumber(this.y.toFixed(1)) +
          '</span><br>'
        );
      },
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    series: seriesData,
  };
}

export function generateKPINew(
  title: string,
  scrore: string,
  target: string,
  color = 'red',
  i18nService: I18nService,
  IsTarget: boolean,
  targetTranslatedstring: string,
  dialLength = 3
): any {
  const scoreValue = parseFloat(scrore);
  const targetValue = parseFloat(target);
  const scoreTranslated = i18nService.transformNumber(scoreValue, false);
  const targetTranslated = i18nService.transformNumber(targetValue, false);

  // targetArrowColor = 'orange';
  // color = '#fec42a';

  const widthMapping = (width = 3) => {
    switch (width) {
      case 5:
        return 170;
      case 4:
        return 210;
      default:
        return 220;
    }
  };
  return {
    chart: {
      heigth: '400px',
      type: 'gauge',
      spacingBottom: 10,
      spacingTop: 10,
      spacingLeft: 10,
      width: widthMapping(dialLength),
      spacingRight: 10,
      padding: 0,
      margin: 10,
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
    },

    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.series.name + '</b>: ' + i18nService.transformNumber(this.y.toFixed(1)) + ' %';
      },
    },
    pane: [
      {
        startAngle: -360,
        endAngle: 360,
        background: [
          {
            backgroundColor: '#fff',
            borderWidth: 10,
            borderColor: '#fff',
            outerRadius: '116%',
            //  innerRadius: "100%"
          },
        ],
      },
    ],
    plotOptions: {
      gauge: {
        borderWidth: 100,
        pivot: {
          radius: 0,
        },
        dial: {
          radius: '100%',
          backgroundColor: '#FF0000',
          borderColor: '#FF0000', //'#0f
          baseWidth: 3,
          topWidth: 3,
          rearLength: '-77%',
        },
      },
    },
    title: {
      text: scoreTranslated,
      align: 'center',
      verticalAlign: 'top',
      y: 85,
      style: {
        width: '20%',
        color: 'black',
        fontSize: '26px',
        fontWeight: '500',
        textTransform: 'capitalize',
      },
    },
    subtitle: {
      text: title,
      align: 'center',
      verticalAlign: 'top',
      y: 100,
      style: {
        width: '15%',
        fontSize: '9px',
        fontWeight: '400',
        color: 'black',
        textTransform: 'uppercase',
      },
    },
    // the value axis
    yAxis: {
      min: 0,
      max: 200,
      gridLineColor: 'red',
      gridLineWidth: 0,
      minorTickInterval: 'auto',
      minorTickWidth: 1.3,
      minorTickLength: 12,
      minorTickPosition: 'inside',
      minorTickColor: color,
      tickPixelInterval: 20,
      lineColor: 'transparent',
      tickWidth: 0,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: 'red',
      plotBands: [
        {
          from: 0,
          to: 200,
          color: '#D3D3D3',
          outerRadius: '75%',
          thickness: '2%',
        },
      ],
      labels: {
        step: 10,
        enabled: false,
        rotation: 'auto',
      },
    },

    series: [
      {
        data: [
          {
            y: parseFloat(scrore),
          },
        ],
        name: title,
        dataLabels: {
          enabled: false,
        },
      },
      {
        name: targetTranslatedstring,
        data: [
          {
            y: parseFloat(targetTranslated),
            id: targetTranslatedstring,
            name: color,
            marker: {
              fillColor: 'red',
              radius: 50,
            },
            dial: {
              radius: '100%',
              backgroundColor: 'red',
              borderColor: 'red', //'#0f7d4c',
              borderWidth: 1,
              borderRadius: 10,
              markerEnd: 'arrow',
              baseWidth: 15,
              topWidth: 1,
              baseLength: '80%',
            },
          },
          // {
          //   y: 60,
          //   dial: {
          //     backgroundColor: '#ff6d2a',
          //     radius: '85%',
          //     borderColor: '#ff6d2a',
          //     borderWidth: 1,
          //     baseWidth: 12,
          //     topWidth: 1,
          //     baseLength: '80%'
          //   }
          // }
        ],

        tooltip: {
          valueSuffix: '',
        },
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };
}
