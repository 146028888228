<div class="actions-next">
  <a
    class="btn"
    (click)="selectOrDeselctAll($event, true)"
    *ngIf="step === 'configuration' && !getYearSelectionState()"
  >
    {{ 'Select All' | translate }}
  </a>
  <a
    class="btn"
    (click)="selectOrDeselctAll($event, false)"
    *ngIf="step === 'configuration' && getYearSelectionState()"
  >
    {{ 'Deselect All' | translate }}
  </a>
  <a class="btn" (click)="nextOrBack($event)">
    <i class="fal fa-arrow-left" *ngIf="step !== 'configuration'"></i>
    {{ step === 'configuration' ? nextlabel : backlabel }}
    <i class="fal fa-arrow-right" *ngIf="step === 'configuration'"></i>
  </a>
</div>
<div class="ui-table-bordered" [hidden]="step !== 'configuration'">
  <div class="ui-table nostripes">
    <div class="ui-table-wrapper">
      <table>
        <tbody class="ui-table-tbody">
          <tr *ngFor="let ffields of chunk_fields">
            <td *ngFor="let field of ffields">
              <p-checkbox [(ngModel)]="field.checked" [label]="field.label" binary="true"> </p-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="my-repor-chart">
  <div [hidden]="step !== 'graph'" #bar></div>
</div>
