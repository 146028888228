import { Injectable } from '@angular/core';
import { generatePerformanceTrend } from '@clearviewshared/highchart/helperfunctions';
import { FiltersService } from '@app/shared/services/filters.service';
import { RestMessage } from '@app/core/types';
import { HttpClient } from '@angular/common/http';
//import { DataParam } from './type/DataParam';
//import { IPerformanceTrendKpiResponse, IPerformanceTrendStatusBarResponse } from './type/IPerformanceTrendResponse';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import {
  IPerformanceTrendKpiResponse,
  IPerformanceTrendStatusBarResponse,
  IPerformanceTrendWidgetResponse,
} from '../../reporting/performance-trend/type/IPerformanceTrendResponse';

@Injectable({
  providedIn: 'root',
})
export class PerformanceTrendService {
  performanceTrendUrl = '/PerformanceTrendWidget';
  dataPF = {
    Sales: {
      1: [
        {
          name: 'National',
          data: [100, 100, 120, 120, 120, 140, 130, 130, 160, 160, 160, 170],
          label: { enabled: false },
        },
        {
          name: 'GTA',
          data: [70, 70, 70, 70, 100, 100, 100, 130, 130, 130, 150, 160],
          label: { enabled: false },
        },
      ],
      2: [
        {
          name: 'Q1',
          data: [120, 120, 120, 140, 135, 140, 140, 160, 160, 160, 160, 200],
          label: { enabled: false },
        },
        {
          name: 'Q2',
          data: [200, 200, 220, 230, 220, 240, 230, 230, 260, 260, 260, 270],
          label: { enabled: false },
        },
        {
          name: 'Q3',
          data: [345, 345, 345, 360, 360, 370, 390, 390, 390, 410, 410, 420],
          label: { enabled: false },
        },
        {
          name: 'Q4',
          data: [230, 230, 230, 240, 250, 250, 250, 260, 260, 270, 270, 280],
          label: { enabled: false },
        },
        {
          name: 'Q5',
          data: [270, 280, 300, 300, 300, 310, 310, 320, 320, 330, 340, 340],
          label: { enabled: false },
        },
        {
          name: 'Q6',
          data: [200, 175, 150, 125, 100, 130, 160, 190, 195, 200, 210, 230],
          label: { enabled: false },
        },
        {
          name: 'Q7',
          data: [150, 200, 250, 300, 350, 400, 375, 310, 290, 350, 380, 400],
          label: { enabled: false },
        },
        {
          name: 'Q8',
          data: [50, 70, 80, 100, 75, 90, 110, 200, 275, 160, 280, 300],
          label: { enabled: false },
        },
        {
          name: 'Q9',
          data: [150, 200, 250, 300, 350, 400, 375, 310, 290, 350, 380, 400],
          label: { enabled: false },
        },
        {
          name: 'Q10',
          data: [200, 175, 150, 125, 100, 130, 160, 190, 195, 200, 210, 230],
          label: { enabled: false },
        },
      ],
      3: [
        {
          name: '% Client Info Collected',
          data: [655, 680, 700, 720, 730, 730, 730, 770, 780, 800, 820, 840],
          label: { enabled: false },
        },
      ],
      4: [
        {
          name: '% Issue Closed',
          data: [130, 135, 140, 140, 140, 155, 167, 143, 168, 170, 170, 180],
          label: { enabled: false },
        },
      ],
    },
    Service: {
      1: [
        {
          name: 'National',
          data: [50, 70, 80, 100, 75, 90, 110, 200, 275, 160, 280, 300],
          label: { enabled: false },
        },
        {
          name: 'GTA',
          data: [10, 30, 40, 60, 80, 200, 225, 300, 350, 180, 190, 230],
          label: { enabled: false },
        },
      ],
      2: [
        {
          name: 'Q1',
          data: [100, 125, 150, 175, 200, 225, 250, 275, 300, 260, 230, 210],
          label: { enabled: false },
        },
        {
          name: 'Q2',
          data: [30, 40, 50, 60, 100, 140, 180, 250, 270, 310, 325, 330],
          label: { enabled: false },
        },
        {
          name: 'Q3',
          data: [200, 175, 150, 125, 100, 130, 160, 190, 195, 200, 210, 230],
          label: { enabled: false },
        },
        {
          name: 'Q4',
          data: [150, 200, 250, 300, 350, 400, 375, 310, 290, 350, 380, 400],
          label: { enabled: false },
        },
        {
          name: 'Q5',
          data: [50, 70, 80, 100, 75, 90, 110, 200, 275, 160, 280, 300],
          label: { enabled: false },
        },
        {
          name: 'Q6',
          data: [150, 200, 250, 300, 350, 400, 375, 310, 290, 350, 380, 400],
          label: { enabled: false },
        },
        {
          name: 'Q7',
          data: [200, 175, 150, 125, 100, 130, 160, 190, 195, 200, 210, 230],
          label: { enabled: false },
        },
        {
          name: 'Q8',
          data: [30, 40, 50, 60, 100, 140, 180, 250, 270, 310, 325, 330],
          label: { enabled: false },
        },
        {
          name: 'Q9',
          data: [50, 70, 80, 100, 75, 90, 110, 200, 275, 160, 280, 300],
          label: { enabled: false },
        },
        {
          name: 'Q10',
          data: [200, 175, 150, 125, 100, 130, 160, 190, 195, 200, 210, 230],
          label: { enabled: false },
        },
      ],
      3: [
        {
          name: '% Client Info Collected',
          data: [50, 70, 80, 100, 75, 90, 110, 200, 275, 160, 280, 300],
          label: { enabled: false },
        },
      ],
      4: [
        {
          name: '% Issue Closed',
          data: [100, 125, 150, 175, 200, 225, 250, 275, 300, 260, 230, 210],
          label: { enabled: false },
        },
      ],
    },
    CPO: {
      1: [
        {
          name: 'National',
          data: [550, 600, 650, 700, 650, 550, 600, 650, 700, 750, 650, 700],
          label: { enabled: false },
        },
        {
          name: 'GTA',
          data: [450, 600, 550, 500, 450, 400, 600, 650, 700, 750, 650, 650],
          label: { enabled: false },
        },
      ],
      2: [
        {
          name: 'Q1',
          data: [650, 600, 700, 550, 500, 600, 650, 700, 750, 800, 850, 900],
          label: { enabled: false },
        },
        {
          name: 'Q2',
          data: [550, 500, 500, 500, 600, 650, 700, 750, 700, 750, 750, 650],
          label: { enabled: false },
        },
        {
          name: 'Q3',
          data: [550, 550, 550, 600, 650, 650, 650, 700, 700, 750, 750, 750],
          label: { enabled: false },
        },
        {
          name: 'Q4',
          data: [650, 700, 750, 750, 750, 700, 700, 700, 650, 650, 600, 600],
          label: { enabled: false },
        },
        {
          name: 'Q5',
          data: [650, 650, 650, 750, 700, 700, 750, 750, 850, 850, 900, 900],
          label: { enabled: false },
        },
        {
          name: 'Q6',
          data: [100, 125, 150, 175, 200, 225, 250, 275, 300, 260, 230, 210],
          label: { enabled: false },
        },
        {
          name: 'Q7',
          data: [30, 40, 50, 60, 100, 140, 180, 250, 270, 310, 325, 330],
          label: { enabled: false },
        },
        {
          name: 'Q8',
          data: [200, 175, 150, 125, 100, 130, 160, 190, 195, 200, 210, 230],
          label: { enabled: false },
        },
        {
          name: 'Q9',
          data: [150, 200, 250, 300, 350, 400, 375, 310, 290, 350, 380, 400],
          label: { enabled: false },
        },
        {
          name: 'Q10',
          data: [50, 70, 80, 100, 75, 90, 110, 200, 275, 160, 280, 300],
          label: { enabled: false },
        },
      ],
      3: [
        {
          name: '% Client Info Collected',
          data: [400, 450, 450, 550, 550, 500, 650, 600, 650, 650, 700, 700],
          label: { enabled: false },
        },
      ],
      4: [
        {
          name: '% Issue Closed',
          data: [700, 750, 650, 650, 600, 600, 650, 650, 700, 750, 750, 800],
          label: { enabled: false },
        },
      ],
    },
    Collision: {
      1: [
        {
          name: 'National',
          data: [130, 135, 140, 140, 140, 170, 180, 190, 210, 210, 230, 280],
          label: { enabled: false },
        },
        {
          name: 'GTA',
          data: [185, 200, 230, 240, 240, 240, 250, 260, 260, 300, 320, 340],
          label: { enabled: false },
        },
      ],
      2: [
        {
          name: 'Q1',
          data: [270, 280, 300, 300, 300, 310, 310, 320, 320, 330, 340, 340],
          label: { enabled: false },
        },
        {
          name: 'Q2',
          data: [320, 320, 320, 330, 340, 340, 350, 360, 370, 360, 380, 400],
          label: { enabled: false },
        },
        {
          name: 'Q3',
          data: [380, 400, 400, 410, 420, 420, 420, 420, 430, 430, 440, 450],
          label: { enabled: false },
        },
        {
          name: 'Q4',
          data: [130, 135, 140, 140, 140, 155, 167, 143, 168, 170, 170, 180],
          label: { enabled: false },
        },
        {
          name: 'Q5',
          data: [170, 180, 180, 180, 200, 200, 210, 210, 210, 230, 240, 240],
          label: { enabled: false },
        },
        {
          name: 'Q6',
          data: [550, 560, 560, 560, 570, 570, 580, 590, 590, 600, 610, 620],
          label: { enabled: false },
        },
        {
          name: 'Q7',
          data: [480, 500, 520, 520, 535, 540, 540, 560, 560, 560, 560, 580],
          label: { enabled: false },
        },
        {
          name: 'Q8',
          data: [200, 200, 220, 230, 220, 240, 230, 230, 260, 260, 260, 270],
          label: { enabled: false },
        },
        {
          name: 'Q9',
          data: [345, 345, 345, 360, 360, 370, 390, 390, 390, 410, 410, 420],
          label: { enabled: false },
        },
        {
          name: 'Q10',
          data: [230, 230, 230, 240, 250, 250, 250, 260, 260, 270, 270, 280],
          label: { enabled: false },
        },
      ],
      3: [
        {
          name: '% Client Info Collected',
          data: [100, 100, 120, 120, 120, 140, 130, 130, 160, 160, 160, 170],
          label: { enabled: false },
        },
      ],
      4: [
        {
          name: '% Issue Closed',
          data: [70, 70, 70, 70, 100, 100, 100, 130, 130, 130, 150, 160],
          label: { enabled: false },
        },
      ],
    },
  };

  categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  constructor(
    private http: HttpClient,
    private filtersService: FiltersService,
    public gbfService: GlobalFilterService
  ) {}
  getData(program = 'Sales', value = 1) {
    return generatePerformanceTrend(this.categories, this.dataPF[program][value]);

    // if (type === 'pt') {
    //     return{
    //         chart: {
    //         type: 'area',
    //         spacingBottom: 20,
    //         spacingTop: 0,
    //         spacingLeft: 30,
    //         spacingRight: 30,

    //         //  plotBorderColor: '#f7f7f7',
    //         //  plotBorderWidth: 2
    //       },
    //       credits: {
    //         enabled: false
    //       },
    //       legend: {
    //         align: 'center',
    //         //  color:'#',

    //         itemStyle: {
    //           color: '#b2b2b2',
    //           fontWeight: '500'
    //         },
    //         verticalAlign: 'top'
    //       },

    //       yAxis: {
    //         title: {
    //           text: ''
    //         }
    //       },
    //       title: {
    //         text: ''
    //       },
    //       plotOptions: {
    //         area: {
    //           fillOpacity: 0.05,
    //           marker: {
    //             fillColor: 'white',
    //             lineWidth: 2,
    //             lineColor: null,
    //             symbol: 'circle'
    //           }
    //         },
    //       },
    //       xAxis: {
    //         gridLineWidth: 1,
    //         categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    //       },

    //       series: [{
    //         name: 'National',
    //         data: [43934, 52503, 57177, 69658, 97031, 119931],
    //         /* marker: {
    //         symbol: 'square',
    //         fillColor: 'white',
    //         lineWidth: 2,
    //         lineColor: null
    //         },*/
    //         label: { enabled: false }

    //       }, {
    //         name: 'Zone',
    //         data: [12908, 5948, 8105, 11248, 8989, 11816],
    //         label: { enabled: false }
    //         // marker : {fillColor:'white', lineColor: null }
    //       }

    //       ]
    //     };

    // }
    // if (type === 'pt2') {

    //   return {
    //     chart: {
    //       type: 'area',
    //       spacingBottom: 20,
    //       spacingTop: 0,
    //       spacingLeft: 30,
    //       spacingRight: 30,

    //       //  plotBorderColor: '#f7f7f7',
    //       //  plotBorderWidth: 2
    //   },
    //   credits: {
    //       enabled: false
    //   },
    //   legend: {
    //       align: 'center',
    //       //  color:'#',

    //       itemStyle: {
    //           color: '#b2b2b2',
    //           fontWeight: '500'
    //       },
    //       verticalAlign: 'top'
    //   },

    //   yAxis: {
    //       title: {
    //           text: ''
    //       }
    //   },
    //   title: {
    //       text: ''
    //   },
    //   plotOptions: {
    //       area: {
    //           fillOpacity: 0.05,
    //           marker: {
    //               fillColor: 'white',
    //               lineWidth: 2,
    //               lineColor: null,
    //               symbol: 'circle'
    //           }
    //       },
    //   },
    //   xAxis: {
    //       gridLineWidth: 1,
    //       categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    //   },

    //   series: [{
    //       name: 'National',
    //       data: [43934, 52503, 57177, 69658, 97031, 119931],
    //       /* marker: {
    //        symbol: 'square',
    //        fillColor: 'white',
    //        lineWidth: 2,
    //        lineColor: null
    //        },*/
    //       label: { enabled: false }

    //   },  {
    //       name: 'Zone',
    //       data: [12908, 5948, 8105, 11248, 8989, 11816],
    //       label: { enabled: false }
    //       // marker : {fillColor:'white', lineColor: null }
    //   }

    //   ]
    // };
    // }
  }

  //FOR PERFORMANCE TREND WIDGET

  getPerformanceTrendWidgetStatusBar(isStat: boolean) {
    this.filtersService.setField('');
    const language = localStorage.getItem('language');
    const filters = this.filtersService.getFilterObject();
    const performanceTrendOrg = this.gbfService['performance_trend_orgs'];
    const processView = 'PerformanceTrendStatus';
    return (
      this.http
        .cache()
        // .disableLoader()
        .post<Array<IPerformanceTrendStatusBarResponse>>(
          `${this.performanceTrendUrl}/PerformanceTrendWidgetStatusBar/${isStat}/${language}/${processView}`,
          filters
        )
    );
  }

  getPerformanceTrendWidgetKpi(param: any, compareItem: any, gbfService: any, processView: any) {
    const PROPERTY = gbfService.currentAllowedFilters.includes('multi_org') ? 'multi_org' : 'org';
    const KEY = 'performance_trend_' + PROPERTY;
    const compareField = JSON.stringify(compareItem);
    var fields = JSON.stringify(param);
    const filters = gbfService.getFilterObject();
    const compare = Array.isArray(gbfService[KEY]) ? gbfService[KEY] : [gbfService[KEY]];
    const maxLevel = Math.max(...compare.map((obj: any) => obj.level));
    const maxLevelObjects = compare.filter((obj: any) => obj.level === maxLevel);
    //const processView = "kpi";

    filters.Fields = fields;
    if (maxLevelObjects && maxLevelObjects.length > 0) {
      maxLevelObjects
        .filter(Boolean)
        .map((org: any) => {
          return {
            //OrgName: org.NodeId, // NodeId
            OrgName: org.NodeId || org.mProps.NodeId,
            OrgParent: org.parent, // Org Parent
            OrgLevel: org.level, // Org Parent
            OrgIdentifier: org.Identifier, // Identifier
            OrgType: 'org-secondary',
          };
        })
        .forEach((c: any) => {
          const found = filters.Orgs.find((x: any) => x.OrgName === c.OrgName);
          //if (!found) {
          // if (found) {
          filters.Orgs.push(c);
          // }
          //}
        });
    }
    // console.log(filters.Orgs, compare);
    return this.http
      .cache()
      .disableLoader()
      .post<IPerformanceTrendKpiResponse>(
        `${this.performanceTrendUrl}/PerformanceTrendWidgetKpi/${processView}/${compareField}`,
        filters
      );
  }

  getPerformanceTrendWidgetCombined(param: any, gbfService: any, localStorageKey: any) {
    const KEY = localStorageKey;
    const filters = gbfService.getFilterObject();
    filters.Data = param;
    const compare = Array.isArray(gbfService[KEY]) ? gbfService[KEY] : [gbfService[KEY]];
    if (compare && compare[0] != undefined) {
      const maxLevel = Math.max(...compare.map((obj: any) => obj.level));
      const maxLevelObjects = compare.filter((obj: any) => obj.level === maxLevel);
      //const processView = "kpi";

      if (maxLevelObjects && maxLevelObjects.length > 0) {
        maxLevelObjects
          .filter(Boolean)
          .map((org: any) => {
            return {
              //OrgName: org.NodeId, // NodeId
              OrgName: org.NodeId || org.mProps.NodeId,
              OrgParent: org.parent, // Org Parent
              OrgLevel: org.level, // Org Parent
              OrgIdentifier: org.Identifier, // Identifier
              OrgType: 'org-secondary',
            };
          })
          .forEach((c: any) => {
            filters.Orgs.push(c);
          });
      }
    }

    // console.log(filters.Orgs, compare);
    return this.http
      .cache()
      .disableLoader()
      .post<IPerformanceTrendWidgetResponse>(`${this.performanceTrendUrl}/Combined`, filters);
  }
}
