import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  Injector,
} from '@angular/core';
import { Identifiers } from '@app/shared/services/app.config.type';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { FiltersService } from '@app/shared/services/filters.service';
import moment from 'moment';
import { GlobalFilterService } from '../../global-filters.service';
import { GlobalFilterHelperService } from '../../global-filters-helper.service';
import { GbfBase } from '../gbf-base';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-gbf-calendar',
  templateUrl: 'global-filter-calendar.component.html',
})
export class GlobalFilterCalendarComponent extends GbfBase implements OnInit, OnDestroy, OnChanges {
  search = '';
  currentMonth: any = moment();
  programDate: Date = new Date();
  showDateRange = false;
  yearNavigator = true;
  monthNavigator = true;
  currentCalendarDate: any = [];
  primeMoment: any = moment;
  defaultSelectedDate = {};
  dateDropDown: any = [];
  @Input() active = false;
  @Input() title = '';
  @Output() offmenu: any = new EventEmitter();
  @ViewChild('datePicker', { static: false }) datePicker: any;
  subscriptions: Subscription[] = [];
  identifer = 'MTD';
  showDonebutton = true;
  allowMonthSelect = false;
  autoDatepickerDropdownSelect: any = {};
  constructor(
    public injector: Injector,
    public translateService: TranslateService,
    public appConfig: AppConfigurationService,
    public filterService: FiltersService,
    public gbfHelperService: GlobalFilterHelperService,
    public gbfService: GlobalFilterService
  ) {
    super(injector, gbfService, gbfHelperService, translateService);
    this.subscriptions.push(
      this.gbfService.gbfChangeState.subscribe((val: { config: any; value: any; for: string }) => {
        if (val.config.name === this.config.name && val.for === 'calendar') {
          this.changeDate(val.value);
        }
      })
    );
    // this.dateDropDown.splice(3, 0, {
    //   label: range,
    //   key: range,
    //   value: (value: any = this.currentMonth) => [moment(value).startOf('year'), moment()],
    // });

    var found = this.gbfService.current_configs.find((x: any) => x.name == 'calendar1');
    if (found) {
      this.dateDropDown = this.gbfService.getCalender1DropDown();
      this.currentCalendarDate = this.gbfService.getCalender1Range();
    } else {
      this.dateDropDown = this.gbfService.getCalenderDropDown();
      this.currentCalendarDate = this.gbfService.getCalenderRange();
    }
    const programDate = this.appConfig.getByIdentifier(Identifiers.ProgramDate);
    if (programDate) {
      if (programDate.ClientConfig === null) {
        programDate.ClientConfig = programDate;
      }
      if (programDate.ClientConfig) {
        this.programDate = new Date(programDate.ClientConfig.Fields['equal']['value']);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['active']) {
    }
  }
  ngOnInit(): void {
    this.dateDropDown = this.gbfService.getCalenderDropDown({ dateDropDown: this.config?.settings?.dateDropDown });
    this.currentCalendarDate = this.gbfService.getCalenderRange();
    const programDate = this.appConfig.getByIdentifier(Identifiers.ProgramDate);
    if (programDate) {
      if (programDate.ClientConfig === null) {
        programDate.ClientConfig = programDate;
      }
      if (programDate.ClientConfig) {
        this.programDate = new Date(programDate.ClientConfig.Fields['equal']['value']);
      }
    }

    const found = localStorage.getItem('gbf_date_identifier');
    if (!found) {
      let range = 'M36';
      const intialRange = this.gbfHelperService?.gbfConfig?.filterConfig?.config?.find(
        (x: any) => x.name === 'calendar'
      );
      if (!this.gbfService[this.config.name] && intialRange && intialRange.range) {
        range = intialRange.range;
        this.gbfService.setDateIdentifier(range);
      }
    } else {
      this.gbfService.setDateIdentifier(found);
    }
    if (this.config?.settings?.listDoneButton === false) {
      this.showDonebutton = false;
    }
    if (this.config?.settings?.allowMonthSelect === true) {
      this.allowMonthSelect = true;
    }
    this.identifer = this.gbfService.getDateIdentifier();
    this.gbfService.$autoDatepickerDropdownSelect.subscribe((res) => {
      this.autoDatepickerDropdownSelect = res;
    });
  }
  onMonthSelect(event: any) {
    this.currentMonth = event;
  }
  hideDatePicker(event: any) {
    if (!event) {
    }
    if (event.type === 'dropDownRange') {
    }
    if (event.type === 'customRange') {
    }
  }

  onIdentifierChange(event: any) {
    this.identifer = event ? event : this.identifer;
  }

  onDateRangeChange(event: any) {
    if (!this.showDonebutton) {
      this.gbfService.setDateIdentifier(event.period || this.identifer);
      this.currentCalendarDate = event.dates || event;
      this.changeDate(event.dates || event);
    }
  }

  changeDate(dates: Array<string>) {
    if (typeof dates[0] === 'string' && typeof dates[1] === 'string') {
      const joinDates = dates.join('|');
      this.gbfService[this.config.name] = joinDates;
      this.filterService.inMemoryCalendar = joinDates;
      localStorage.setItem('filter_date', joinDates);
      localStorage.setItem(this.config.localStorageKey, joinDates);
      this.gbfService.gbfChange.next({
        filter: this.config,
        val: { dates: joinDates, identifier: this.gbfService.getDateIdentifier() },
      });
      this.gbfService.gbfDateChange.next({
        offmenu: !this.showDonebutton ? false : true,
        dates: joinDates,
        identifier: this.gbfService.getDateIdentifier(),
      });
    }
  }

  datePickerDone(e: any) {
    if (e === 'close') {
      this.toggleMenu('');
      return;
    }

    this.gbfService.setDateIdentifier(e.period);
    this.currentCalendarDate = e.date;
    if (
      this.currentCalendarDate &&
      typeof this.currentCalendarDate === 'object' &&
      this.currentCalendarDate.length > 1
    ) {
      this.changeDate(this.currentCalendarDate);
      // if (typeof this.currentCalendarDate[0] === 'string' && typeof this.currentCalendarDate[1] === 'string') {
      //   this.gbfService[this.config.name] = this.currentCalendarDate.join('|');
      //   this.filterService.inMemoryCalendar = this.currentCalendarDate.join('|');
      //   localStorage.setItem('filter_date', this.currentCalendarDate.join('|'));
      //   localStorage.setItem(this.config.localStorageKey, this.currentCalendarDate.join('|'));
      //   this.gbfService.gbfDateChange.next(this.currentCalendarDate.join('|'));
      // }
    }
    if (e.e) {
      e.e.stopPropagation();
      e.e.preventDefault();
    }
    this.toggleMenu('');
    this.callApiOn('done');
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
  toggleMenu(e: any) {
    this.offmenu.emit('');
  }
}
