import { Input, Directive, Output, EventEmitter, inject, Injector } from '@angular/core';
import { GlobalFilterHelperService } from '../global-filters-helper.service';
import { GlobalFilterService } from '../global-filters.service';
import { cloneDeep, memoize } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { NgForageCache } from 'ngforage';

@Directive()
export class GbfBase {
  _config: any = {};
  @Input() pindex: 1;
  @Input() settings: any;
  @Input() values: any[] = [];
  @Input() set config(_conf: any) {
    this._config = _conf;
  }
  get config(): any {
    const __config = cloneDeep(this._config);
    if (this.settings) {
      __config.settings = this.settings;
    }
    return __config;
  }
  @Input() from = 'none';
  @Output() onclick: any = new EventEmitter();
  initialState = {};
  activeTree: any = {};
  selectedItem: any;
  breadcrums: any[] = [];
  activatedItems: any[] = [];
  activatedLevel = 1;
  activatedParent = 'null';
  ngForage: NgForageCache;
  constructor(
    public injector: Injector,
    public gbfService: GlobalFilterService,
    public gbfHelperService: GlobalFilterHelperService,
    public translateService: TranslateService = null
  ) {
    this.ngForage = this.injector.get(NgForageCache);
  }
  getValues(): any[] {
    return this.values;
  }

  async setLocalStorageValue() {
    if (this.config.localStorageKey) {
      let found = '';
      if (this.config?.database === 'indexdb') {
        found = await this.ngForage.getItem(this.config.localStorageKey);
      } else {
        found = localStorage.getItem(this.config.localStorageKey);
      }
      if (found) {
        const j = JSON.parse(found);
        if (this.config.type === 'tree') {
          this.activeTree = j.tree || this.activeTree;
          this.selectedItem = j.selected || this.selectedItem;
          this.breadcrums = j.breadcrums || this.breadcrums;
        } else {
          this.selectedItem = j;
        }
        if (!this.config.settings.stright) {
          this.gbfService[this.config.name] = this.selectedItem;
          this.gbfService[this.config.name + '_breadcrums'] = this.breadcrums;
        } else {
          this.gbfService[this.config.name] = this.selectedItem;
          this.gbfService[this.config.name + '_breadcrums'] = this.breadcrums;
          this.gbfService[this.config.name + '_full'] = j;
        }
        return j;
      }
    }
    return { tree: {}, selected: {}, breadcrums: [this.initialState] };
  }
  setItemToLocalStorage(key: string, item: any) {
    this.gbfService[this.config.name + '_full'] = item;
    if (!Array.isArray(item)) {
      if (item.selected) {
        item.selected.init = true;
      }
    }
    if (this.config.database !== 'inmemory') {
      if (this.config?.database === 'indexdb' && this.config.name.indexOf('org') > -1) {
        localStorage.setItem(key, this.makeStringify({ selected: item.selected }));
        this.ngForage.setItem(key, this.makeStringify(item));
      } else {
        localStorage.setItem(key, this.makeStringify(item));
      }
    }
  }

  makeStringify(obj: any) {
    const cache: any = [];
    const s = JSON.stringify(obj, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.indexOf(value) !== -1) {
          return;
        }
        cache.push(value);
      }
      return value;
    });
    return s;
  }
  onclickevent(e: any) {
    this.onclick.emit(e);
  }
  callApiOn(callType: string = 'applyfilter') {
    if (callType !== 'selection' && callType !== 'done') {
      return;
    }
    if (this.config.settings && this.config.settings.callApiOn === callType) {
      this.gbfService.gbfOnApplyFilter.next({ rand: Math.random() * 100 });
      this.gbfService.gbfSaveConfiguration.next(true);
    }
  }
  do_get() {}
}
