<ng-template #header>
  <h2>{{ 'Comment List' | translate }}</h2>
</ng-template>
<ng-template #content>
  <cvp-table
    #dt
    [resetPageOnSort]="true"
    [columns]="cols"
    [lazy]="true"
    widht="100%"
    [lazy]="true"
    [value]="commentListData"
    [paginator]="true"
    [rows]="pageSize"
    (onPage)="paginate($event)"
    [totalRecords]="totalRecords"
    [autoLayout]="true"
  >
    <!-- (onSort)="sortData($event)" -->
    <ng-template pTemplate="header" let-columns>
      <tr id="table-header" class="sticky-row" #stickyRow>
        <!-- <th class='sticky-cell'></th> -->
        <th *ngFor="let col of cols" class="text-center">
          {{ col.header | translate }}
          <!-- <cvp-sortIcon [field]="col.field">
          </cvp-sortIcon> -->
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
      <tr *ngIf="rowIndex == 0" class="text-center" style="display: none">
        <td *ngFor="let col of columns">
          {{ col.header | translate }}
        </td>
      </tr>
      <tr>
        <td
          *ngFor="let col of columns"
          [ngClass]="{ nw: col.field === 'Comment', 'text-center': col.field === 'CustomerName' }"
        >
          <span
            *ngIf="col.field == 'Sentiment' && rowData[col.field] == 'negative'"
            class="rounded-icon negative ng-star-inserted"
          >
            <i class="far fa-thumbs-down active"></i>
          </span>
          <span
            *ngIf="col.field == 'Sentiment' && rowData[col.field] == 'positive'"
            class="rounded-icon positive ng-star-inserted"
          >
            <i class="far fa-thumbs-up active"></i>
          </span>
          <span *ngIf="col.field == 'Sentiment' && rowData[col.field] == 'neutral'" class="rounded-icon neutral">
            <i class="far fa-ban active"></i>
          </span>
          <span *ngIf="col.field == 'Sentiment' && rowData[col.field] == 'unassigned'" class="rounded-icon unassigned">
            <i class="far fa-ban active"></i>
          </span>
          <span
            *ngIf="col.field == 'Sentiment' && rowData[col.field] == 'unclassified'"
            class="rounded-icon unassigned"
          >
            <i class="far fa-ban active"></i>
          </span>

          <span *ngIf="col.field != 'Sentiment'">
            {{ rowData[col.field] }}
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <p *ngIf="pageNumber * pageSize <= totalRecords">
        {{ 'Showing' | translate }} {{ pageSize * (pageNumber - 1) + 1 }} - {{ pageNumber * pageSize }} {{'of' | translate}}
        {{ dt.totalRecords }}
      </p>
      <p *ngIf="totalRecords == 0">{{ 'Showing' | translate }} {{ 0 }} - {{ dt.totalRecords }}</p>
      <p *ngIf="pageNumber * pageSize > totalRecords && totalRecords != 0">
        {{ 'Showing' | translate }} {{ pageSize * (pageNumber - 1) + 1 }} - {{ totalRecords }} of {{ dt.totalRecords }}
      </p>
    </ng-template>
    <ng-template pTemplate="paginatorright">
      <span class="per-page">{{ label | translate }}</span>
      <p-dropdown [autoZIndex]='false'
        panelStyleClass='modal-dropdown'
        [options]="pageDropDown"
        [(ngModel)]="pageSize"
        [appendTo]="'body'"
        (onChange)="onPageSizeChange($event, dt)"
      ></p-dropdown>
    </ng-template>
  </cvp-table>
</ng-template>
<ng-template #footer>
  <!-- <button translate class="btn" (click)="saveEmail()" [disabled]="form.invalid">Save</button> -->
  <button translate class="btn" (click)="Yes()">Close</button>
</ng-template>
