import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Confirmation } from './confirmation';

@Injectable()
export class ConfirmationService {
  acceptConfirmationSource = new Subject<Confirmation>();
  requireConfirmationSource = new Subject<Confirmation>();
  requireConfirmation$ = this.requireConfirmationSource.asObservable();
  accept = this.acceptConfirmationSource.asObservable();

  confirm(confirmation: Confirmation) {
    this.requireConfirmationSource.next(confirmation);
    return this;
  }

  onAccept() {
    this.acceptConfirmationSource.next(null);
  }
}
