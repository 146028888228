import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { BreadCrumbService } from '@app/shared/services/breadcrumb.service';
import { BreadCrumb } from '@app/core/types/breadCrumb';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {
  @Input() pageTitle: string;
  home: MenuItem;
  breadCrumbs: MenuItem[];

  constructor(
    public router: Router,
    public breadCrumbService: BreadCrumbService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.breadCrumbService.getBreadCrumbObservable().subscribe((x: BreadCrumb[]) => {
      this.buildBreadCrumbs(x);
    });
    const breadcrumb = JSON.parse(sessionStorage.getItem('breadcrumb')) || []; // this.breadCrumbService.getCurrent();
    this.buildBreadCrumbs(breadcrumb);
  }

  buildBreadCrumbs(breadcrumb: BreadCrumb[]) {
    if (breadcrumb) {
      // For Global Translation
      breadcrumb.forEach((itm) => {
        itm.label = this.translateService.instant(itm.label);

        const ra = itm.routerLink.split('?');
        if (ra != null && ra.length > 1) {
          itm.routerLink = ra[0];
          const rp = ra[1].split('&');
          if (rp != null && rp.length > 0) {
            let pf = '';
            rp.forEach((p, i) => {
              const pa = p.split('=');
              if (pa != null && pa.length > 1) {
                if (rp.length - 1 === i) {
                  pf += `"${pa[0]}" : "${pa[1]}"`; // '"' + pa[0] + '":' + pa[1];
                } else {
                  pf += `"${pa[0]}" : "${pa[1]}",`;
                  // pf += '"' + pa[0] + '":' + pa[1] + ',';
                }
              }
            });
            itm.queryParm = JSON.parse('{' + pf + '}');
          }
        }
      });

      this.breadCrumbs = breadcrumb.filter((x) => x.label !== 'Home');

      this.home = {
        icon: 'pi pi-home',
        routerLink: this.router.url.includes(environment.reflecxRoutePrefix)
          ? '/' + environment.reflecxRoutePrefix
          : '/' + environment.reportingRoutePrefix,
      };

      //    this.breadCrumbs.unshift(this.home);
      // this.home = breadcrumb.filter(x => x.label === 'Home')[0];
      // if (this.home) {
      //   this.home.icon = 'pi pi-home';
      //   this.home.routerLink = breadcrumb.filter(x => x.label === 'Reporting').length > 0 ? '/reporting' : '/admin';
      // }
    }
  }
}
