import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import moment from 'moment';
import { AuthenticationService, Logger } from '@app/core';
import { GlobalFilterConfigService } from './global-filters-config.service';
import { ActivatedRoute, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { EmailEditsComponent } from 'projects/cvreporting/src/app/features/cvreporting/main-dashboard/email-edits/email-edits.component';
import { TranslateService } from '@ngx-translate/core';
import { StaticIntialState, StaticPageConfig, StaticReturnFilter } from './global-filters-static-config';
import { VersionOverlayComponent } from 'projects/cvreporting/src/app/features/cvreporting/report-generator/overlays/version-overlay.component';
import { Dropdown } from 'primeng/dropdown';
const log = new Logger('GBF_SERVICE');
import { Identifiers } from '@app/shared/services/app.config.type';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { isEmpty } from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class GlobalFilterService {
  public forceReRender: Subject<any> = new Subject();
  public gbfAppEvent: Subject<{ key: string; payload: any }> = new Subject();
  public gbfChangeState: Subject<any> = new Subject();
  public gbfTabChange: Subject<any> = new Subject();
  public gbfDateChange: Subject<any> = new Subject();
  public gbfChange: Subject<any> = new Subject();
  public gbfAllSet: Subject<any> = new Subject();
  public gbfResetState: Subject<any> = new Subject();
  public gbfApiCall: Subject<any> = new Subject();
  public gbfOnApplyFilter: Subject<any> = new Subject();
  public gbfSaveConfiguration: Subject<boolean> = new Subject();
  public $autoDatepickerDropdownSelect: BehaviorSubject<any> = new BehaviorSubject({});
  show_suggestions = true;
  current_configs: Array<any> = [];
  current_tabs: Array<any> = [];
  dateFormat = 'MMM D, YYYY';
  dateTimeFormat = 'MMM D, YYYY hh:mm:ss A';
  currentMonth: any = moment();
  currentAllowedFilters = ['dealer', 'org', 'model', 'version', 'calendar'];
  program = '';
  tab = {
    label: '',
    value: '',
  };
  survey = {
    label: '',
    value: '',
  };
  dealer = { label: 'All', value: '' };
  date_group: Array<any> = [];
  disposition: Array<any> = [];
  version_rg = { label: '', value: '', version: '', versionName: '' };
  version = { label: '', value: '', version: '', versionName: '' };
  model = { Brand: '', Model: '', Year: '' };
  sources = [{ label: 'All', value: 'All' }];
  sentiment: any = { label: 'All Sentiment', value: 'All Sentiment', type: null };
  category = {
    level: 1,
    temp: true,
    parent: 'null',
    name: 'All Categories',
    label: 'All Categories',
    active: true,
    IsChecked: true,
  };
  surveyQuestion: any = [];
  surveyResponse = [
    {
      level: -1,
      parent: 'null',
      name: 'All Survey Responses',
      label: 'All Survey Responses',
      active: true,
      IsChecked: true,
    },
  ];
  surveyScore: any;
  org_suggestions: any[] = [];
  multi_org: any[] = [];
  multi_model: any[] = [];
  org = {
    id: '',
    level: -1,
    parent: 'null',
    Identifier: '',
    NodeId: '',
    name: 'National',
    label: 'National',
    active: true,
    IsChecked: true,
  };
  suggestions: any[] = [];
  org_breadcrums: any[] = [];
  language = '';
  calendar = '';
  calendar1 = '';
  dateIdentifier = '';
  pageSize = 25;
  pageNo = 1;
  orgIdentifier: string;
  SortColumn = '';
  Sort = '';
  filterItem = '';
  field = '';
  authorizationData: any;
  currentUrl: string;
  isMultiOrg = false;
  isMultiModel = true;
  activatedUrl = '';
  currentOrgLevel = 1;
  isGreatorOrEqualMainNodeLevelSelected = false;
  isGreatorMainNodeLevelSelected = false;
  isLessMainNodeLevelSelected = false;
  calendardropdown: any = [];
  private neariestOrg: any = {};

  constructor(
    public gbfConfig: GlobalFilterConfigService,
    public authService: AuthenticationService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public translateService: TranslateService,
    private appConfig: AppConfigurationService
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
    const h = JSON.parse(localStorage.getItem('authorizationData'));
    this.authorizationData = h;
    this.orgIdentifier = h?.OrgIdentifier;
    if (h && h.FilterBarConfig) {
      const DataAccessLevels = Array.isArray(this.authorizationData?.DataAccessLevels)
        ? this.authorizationData?.DataAccessLevels
        : [];
      this.gbfConfig.filterConfig = h.FilterBarConfig.FilterConfig;
      this.gbfConfig.filterConfig.config.unshift({
        type: 'list',
        dynamic: false,
        name: 'tab',
        title: ['label'],
        icons: { leftIcon: '' },
        localStorageKey: 'gbf_tab',
        placeholder: 'Select tab',
        rootClass: 'filter__item flex4',
        settings: { multi: true, listDoneButton: false, listStyleType: 'none', showTitle: false },
        values: [
          { label: 'Service', value: 'Service' },
          { label: 'Sales', value: 'Sales' },
        ],
      });

      var surveyPrograms = this.gbfConfig.filterConfig?.initialStates?.survey;
      var surveyValues: any;

      if (surveyPrograms != null && Array.isArray(surveyPrograms) && surveyPrograms.length > 0) {
        surveyValues = surveyPrograms;
      } else {
        surveyValues = this.gbfConfig.filterConfig.tabs;
      }

      this.gbfConfig.filterConfig.config.unshift({
        type: 'list',
        dynamic: false,
        name: 'survey',
        title: ['label'],
        icons: { leftIcon: '' },
        localStorageKey: 'gbf_survey',
        placeholder: this.translateService.instant('SelectProgram.label'),
        rootClass: 'filter__item flex4',
        settings: { multi: true, listDoneButton: false, listStyleType: 'none', showTitle: false },
        //values: this.gbfConfig.filterConfig.tabs,
        values: surveyValues,
      });

      this.gbfConfig.filterConfig.config = this.gbfConfig.filterConfig.config;
      const foundTab = JSON.parse(localStorage.getItem('gbf_tab'));
      if (foundTab && foundTab.value) {
        const tabsArr = foundTab.value.split(',');
        let surveyVersion = [];
        try {
          surveyVersion = JSON.parse(localStorage.getItem('surveyVersions') || '[]');
        } catch (error) {}
        if (tabsArr.length > 1) {
          const version = surveyVersion.find((x: any) => x.Event === tabsArr[0]);
          if (version && version.value) {
            this.version = {
              label: version.Event,
              value: version.value,
              version: version.value,
              versionName: version.Version,
            };
            localStorage.setItem('gbf_version', JSON.stringify(this.version));
          }
          this.tab = tabsArr[0];
        } else {
          this.tab = foundTab;
        }
      } else {
        if (this.gbfConfig.filterConfig.initialStates['tab']) {
          this.tab = this.gbfConfig.filterConfig.initialStates['tab'];
          if (this.tab) {
            localStorage.setItem('gbf_tab', JSON.stringify(this.tab));
          }
        }
      }
      this.gbfConfig.filterConfig.config.forEach((config: any) => {
        if (config.name === 'org' || config.name === 'multi_org') {
          const DataAccessLevels = Array.isArray(this.authService?.authData?.DataAccessLevels)
            ? this.authService.authData.DataAccessLevels
            : [];
          if (DataAccessLevels.length > 1) {
            console.log(this.authService.orgListData);
            this.getNeariestLevel();
            const value = this.neariestOrg;
            if (value) {
              const UserOrg = {
                level: value.level,
                orginalLevel: value.Level,
                Identifier: value.identifier,
                NodeId: value.nodeId,
                MId: value.id,
                NodeName: value.value,
                parent: value.parent,
                name: value.value,
                label: value.label,
                active: true,
                IsChecked: true,
              };
              config = this.restoreLocalStorageValues(config, UserOrg);
            } else {
              config = this.restoreLocalStorageValues(config);
            }
          } else {
            config = this.restoreLocalStorageValues(config);
          }
        } else {
          config = this.restoreLocalStorageValues(config);
        }
      });
    } else {
      this.router.navigate(['/unauthorized']);
    }
    this.buildOrgSuggestions();
    this.calendardropdown = [
      {
        label: 'R10',
        key: 'R10',
        value: () => [moment().subtract(10, 'days'), moment()],
      },
      {
        label: 'R30',
        key: 'R30',
        value: () => [moment().subtract(30, 'days'), moment()],
      },
      {
        label: 'MTD',
        key: 'MTD',
        value: (value: any = this.currentMonth) => [moment(value).startOf('month'), moment()],
      },
      {
        label: '3MTD',
        key: '3MTD',
        //value: (value: any = this.currentMonth) => [moment(value).startOf('month'), moment()],
        value: () => [moment().startOf('month').subtract(2, 'month'), moment()],
      },
      {
        label: 'QTD',
        key: 'QTD',
        value: (value: any = this.currentMonth) => [
          moment(value).quarter(moment(value).quarter()).startOf('quarter'),
          moment(value).get('month') === moment().get('month') ? moment() : moment(value).endOf('month'),
        ],
      },
      {
        label: 'YTD',
        key: 'YTD',
        value: (value: any = this.currentMonth) => [moment(value).startOf('year'), moment()],
      },
      {
        label: 'M36',
        key: 'M36',
        value: () => [moment().subtract(-36, 'month'), moment()],
      },
      {
        // tslint:disable-next-line:max-line-length
        label: 'previous_month.label',
        key: 'PREVIOUS MONTH',
        value: () => [moment().subtract(1, 'month'), moment()],
      },
      {
        // tslint:disable-next-line:max-line-length
        label: 'previous_quarter.label',
        key: 'PREVIOUS QUARTER',
        value: () => [moment().subtract(1, 'month'), moment()],
      },
      {
        // tslint:disable-next-line:max-line-length
        label: 'previous_year.label',
        key: 'PREVIOUS YEAR',
        value: () => [moment().subtract(1, 'month'), moment()],
      },
      // tslint:disable-next-line:max-line-length
      {
        label: 'program_to_date.label',
        key: 'PROGRAM TO DATE',
        value: () => [moment().add(1, 'month').startOf('month'), moment().add(2, 'month')],
      },
      {
        label: this.translateService.instant('custom_range.label'),
        key: 'CUSTOM RANGE',
        value: (): any => [],
      },
    ];
  }

  restoreLocalStorageValues(config: any, orgDefaultValue = {}) {
    const DataAccessLevels = Array.isArray(this.authService?.authData?.DataAccessLevels)
      ? this.authService.authData.DataAccessLevels
      : [];
    if (config.localStorageKey) {
      try {
        const found = JSON.parse(localStorage.getItem(config.localStorageKey));
        if (found && config.type === 'tree') {
          if (found.selected) {
            this[config.name] = found.selected;
            this[config.name + '_full'] = {
              selected: found.selected,
              selectedTreeItems: found.selectedTreeItems,
            };
          }
          if (found.breadcrums) {
            this[config.name + '_breadcrums'] = found.breadcrums;
          }
        } else {
          if (found) {
            this[config.name] = found;
          } else {
            if (this.gbfConfig.filterConfig.initialStates[config.name]) {
              this.gbfConfig.filterConfig.initialStates[config.name].temp = true;
              if (config.name === 'org' || config.name === 'multi_org') {
                const maxdataAccessLevel = Math.max.apply(
                  Math,
                  DataAccessLevels.map((o: any) => o.Level)
                );
                const dataAccessLevel = DataAccessLevels.find((x: any) => x.Level === maxdataAccessLevel);
                if (!dataAccessLevel) {
                  this.router.navigate(['/unauthorized']);
                  return;
                }
                let UserOrg = {};
                if (isEmpty(orgDefaultValue)) {
                  UserOrg = {
                    level: dataAccessLevel.Level,
                    orginalLevel: dataAccessLevel.Level,
                    Identifier: dataAccessLevel.Identifier,
                    NodeId: dataAccessLevel.NodeId,
                    MId: dataAccessLevel.Id,
                    NodeName: dataAccessLevel.Name,
                    parent: dataAccessLevel.ParentId,
                    name: dataAccessLevel.Name,
                    label: dataAccessLevel.Label,
                    active: true,
                    IsChecked: true,
                  };
                } else {
                  UserOrg = orgDefaultValue;
                }

                this[config.name] = config.name == 'multi_org' ? [UserOrg] : UserOrg;
                this[config.name + '_breadcrums'] = [UserOrg];
                this[config.name + '_full'] = {
                  selected: UserOrg,
                  selectedTreeItems: UserOrg,
                };
                localStorage.setItem(
                  config.localStorageKey,
                  JSON.stringify({
                    breadcrums: [UserOrg],
                    selected: config.name == 'multi_org' ? [UserOrg] : UserOrg,
                    selectedTreeItems: config.name == 'multi_org' ? [UserOrg] : UserOrg,
                  })
                );
              } else {
                this[config.name] = this.gbfConfig.filterConfig.initialStates[config.name];
                localStorage.setItem(config.localStorageKey, JSON.stringify(this[config.name]));
                this[config.name + '_breadcrums'] = [this.gbfConfig.filterConfig.initialStates[config.name]];
                this[config.name + '_full'] = {
                  selected: this.gbfConfig.filterConfig.initialStates[config.name],
                  selectedTreeItems: this.gbfConfig.filterConfig.initialStates[config.name],
                };
              }
            } else {
              this[config.name + '_full'] = {
                selected: [],
                selectedTreeItems: [],
              };
            }
          }
        }
      } catch (error) {
        log.debug('parsing error');
      }
    }
    return config;
  }

  setDateIdentifier(data: any) {
    this.dateIdentifier = data;
    localStorage.setItem('gbf_date_identifier', data);
  }
  getDispositions(config: any, initialState: any) {
    return {
      Disposition: (this.disposition || []).map((disp: any) => ({
        Label: disp.label,
        Code: disp.value,
        Type: disp.type,
        Name: disp.Name,
      })),
    };
  }
  getUpdatedVersion(config: any, initialState: any) {
    return {
      version_rg: {
        label: 'V21',
        value: 'V21',
        version: 'V21',
        versionName: 'V21',
      },
    };
  }
  getDateGroups(config: any, initialState: any) {
    return {
      DateGroup: (this.date_group || []).map((dg: any) => ({ Label: dg.label, Value: dg.value, Type: dg.Type })),
    };
  }
  getDateIdentifier() {
    if (this.dateIdentifier) {
      return this.dateIdentifier;
    }
    if (localStorage.getItem('gbf_date_identifier')) {
      const dateIdentifier = localStorage.getItem('gbf_date_identifier');
      if (dateIdentifier) {
        this.dateIdentifier = dateIdentifier;
        return dateIdentifier;
      }
    }
    return null;
  }

  setSurveyVersion(version: any) {
    localStorage.setItem('gbf_version', JSON.stringify(version));
    this.version = version;
  }
  setTab(en: string, fireEvent = true) {
    let act_tab = this.current_tabs.find((x) => x.value === en);
    this.tab = act_tab;
    localStorage.setItem('gbf_tab', JSON.stringify(act_tab));
    if (fireEvent) {
      this.gbfTabChange.next(act_tab.value);
    }
  }
  getSurveyVersion() {
    if (this.version) {
      return this.version.version || this.version.value;
    } else {
      const survey = JSON.parse(localStorage.getItem('gbf_version'));
      if (survey) {
        return survey.version || survey.value;
      }
    }
  }
  getCalender1Range() {
    let calendarSettings: any = {
      name: 'calendar1',
      type: 'calendar',
      format: this.dateTimeFormat,
      localStorageKey: 'gbf_calendar1',
    };
    const found = this.gbfConfig.filterConfig.config.find((x: any) => x.name === 'calendar1');
    if (found) {
      calendarSettings = found;
    }
    calendarSettings.dateTimeFormat = this.dateTimeFormat;
    let date: any = this.calendar1;
    if (!date) {
      date = localStorage.getItem(calendarSettings.localStorageKey);
    }
    if (date) {
      date = date.split('|');
      if (date.length > 1) {
        const start = moment(date[0]).format(calendarSettings.dateTimeFormat);
        const end = moment(date[1]).endOf('day').format(calendarSettings.dateTimeFormat);
        return [start, end];
      }
    } else {
      const start = this.getStartDateRange1inNumber(
        calendarSettings,
        calendarSettings.range,
        calendarSettings.dateTimeFormat
      );
      const end = this.getEndDateRange1inNumber(calendarSettings.range, calendarSettings.dateTimeFormat);
      return [start, end];
    }
  }
  getCalenderRange(): any {
    let calendarSettings: any = {
      name: 'calendar',
      type: 'calendar',
      format: this.dateTimeFormat,
      localStorageKey: 'gbf_calendar',
    };
    const found = this.gbfConfig.filterConfig.config.find((x: any) => x.name === 'calendar');
    if (found) {
      calendarSettings = found;
    }
    calendarSettings.dateTimeFormat = this.dateTimeFormat;
    let date: any = this.calendar;
    if (!date) {
      date = localStorage.getItem(calendarSettings.localStorageKey);
    }
    if (date) {
      date = date.split('|');
      if (date.length > 1) {
        const start = moment(date[0]).format(calendarSettings.dateTimeFormat);
        const end = moment(date[1]).endOf('day').format(calendarSettings.dateTimeFormat);
        return [start, end];
      }
    } else {
      const start = this.getStartDateRangeinNumber(
        calendarSettings,
        calendarSettings.range,
        calendarSettings.dateTimeFormat
      );
      const end = this.getEndDateRangeinNumber(calendarSettings.range, calendarSettings.dateTimeFormat);
      return [start, end];
    }
  }
  public setSuggestions(name: string, items: any) {
    this.org_suggestions = items;
  }

  public buildOrgSuggestions(xlevel?: number) {
    const level = xlevel || this.getSelectedOrgLevel();
    const lastLevel = this.authService.authData.LastNodeLevel;
    const values: any[] = [];
    this.org_suggestions = this.org_suggestions.map((x) => {
      x.IsChecked = x.level < level ? false : x.IsChecked;
      return x;
    });
    for (let i = level; i <= lastLevel; i++) {
      values.push({
        level: i,
        label: this.translateService.instant(`Level${i}`),
        value: `level${i}`,
        IsChecked: this.org_suggestions.find((x) => x.value === `level${i}`)?.IsChecked || false,
        IsPartialChecked: false,
        disabled: false,
      });
    }
    this.suggestions = values;
  }

  getCalenderDropDown(dropdown: any = null) {
    let calendardropdown: any = [
      {
        label: 'R10',
        key: 'R10',
        value: () => [moment().subtract(10, 'days'), moment()],
      },
      {
        label: 'R30',
        key: 'R30',
        value: () => [moment().subtract(30, 'days'), moment()],
      },
      {
        label: 'R90',
        key: 'R90',
        value: () => [moment().subtract(90, 'days'), moment()],
      },
      {
        label: 'MTD',
        key: 'MTD',
        value: (value: any = this.currentMonth) => [moment(value).startOf('month'), moment()],
      },
      {
        label: '3MTD',
        key: '3MTD',
        //value: (value: any = this.currentMonth) => [moment(value).startOf('month'), moment()],
        value: () => [moment().startOf('month').subtract(2, 'month'), moment()],
      },
      {
        label: '6MTD',
        key: '6MTD',
        value: () => [moment().startOf('month').subtract(5, 'month'), moment()],
      },
      {
        label: 'QTD',
        key: 'QTD',
        value: (value: any = this.currentMonth) => [
          moment(value).quarter(moment(value).quarter()).startOf('quarter'),
          moment(value).get('month') === moment().get('month') ? moment() : moment(value).endOf('month'),
        ],
      },
      {
        label: 'YTD',
        key: 'YTD',
        value: (value: any = this.currentMonth) => [moment(value).startOf('year'), moment()],
      },
      {
        label: 'FYTD',
        key: 'FYTD',
        value: (value: any = this.currentMonth) => [
          moment(value).startOf('year').add(3, 'month') < moment()
            ? moment(value).startOf('year').add(3, 'month')
            : moment(value).startOf('year').subtract(1, 'year').add(3, 'month'),
          moment(),
        ],
      },
      {
        label: 'M36',
        key: 'M36',
        value: () => [moment().subtract(-36, 'month'), moment()],
      },
      {
        // tslint:disable-next-line:max-line-length
        label: this.translateService.instant('previous_month.label'),
        key: 'PREVIOUS MONTH',
        value: () => [moment().subtract(1, 'month'), moment()],
      },
      {
        // tslint:disable-next-line:max-line-length
        label: this.translateService.instant('previous_quarter.label'),
        key: 'PREVIOUS QUARTER',
        value: () => [moment().subtract(1, 'month'), moment()],
      },
      {
        // tslint:disable-next-line:max-line-length
        label: this.translateService.instant('previous_year.label'),
        key: 'PREVIOUS YEAR',
        value: () => [moment().subtract(1, 'month'), moment()],
      },
      // tslint:disable-next-line:max-line-length
      {
        label: this.translateService.instant('program_to_date.label'),
        key: 'PROGRAM TO DATE',
        value: () => [moment().add(1, 'month').startOf('month'), moment().add(2, 'month')],
      },
      {
        label: this.translateService.instant('custom_range.label'),
        key: 'CUSTOM RANGE',
        value: (): any => [],
      },
    ];
    const found = dropdown?.dateDropDown
      ? dropdown
      : this.gbfConfig.filterConfig.config.find((x: any) => x.name === 'calendar');
    if (found && found.dateDropDown) {
      let dropdown = found.dateDropDown;
      dropdown.map((obj: any) => {
        var find = this.calendardropdown.find((x: any) => x.Key == obj.Key && x.label == obj.label);
        if (find) {
          obj.key === find.key;
          obj.label = this.translateService.instant(find.label);
          obj.value = find.value;
        }
      });
      return dropdown;
    } else return null;
  }
  getCalender1DropDown() {
    const found = this.gbfConfig.filterConfig.config.find((x: any) => x.name === 'calendar1');
    if (found && found.dateDropDown) {
      let dropdown = found.dateDropDown;
      dropdown.map((obj: any) => {
        var find = this.calendardropdown.find((x: any) => x.Key == obj.Key && x.label == obj.label);
        if (find) {
          obj.key === find.key;
          obj.label = this.translateService.instant(find.label);
          obj.value = find.value;
        }
      });
      return dropdown;
    } else return null;
  }
  getStartDateRangeinNumber(calendarSettings: any, identifier: string, format: string) {
    switch (identifier) {
      case 'YTD': {
        return moment(this.currentMonth).startOf('year').format(format);
      }
      case 'R10': {
        return moment().subtract(10, 'days').format(format);
      }
      case 'R30': {
        return moment().subtract(30, 'days').format(format);
      }
      case 'MTD': {
        return moment(this.currentMonth).startOf('month').format(format);
      }
      case '3MTD': {
        return moment().startOf('month').subtract(2, 'month').format(format);
      }
      case '6MTD': {
        return moment().startOf('month').subtract(5, 'month').format(format);
      }
      case '12MTD': {
        return moment().startOf('month').subtract(11, 'month').format(format);
      }
      case 'QTD': {
        return moment(this.currentMonth).quarter(moment(this.currentMonth).quarter()).startOf('quarter');
      }
      case 'PREVIOUS MONTH': {
        return moment().subtract(1, 'month').format(format);
      }
      case 'PREVIOUS QUARTER': {
        return moment().subtract(1, 'month').format(format);
      }
      case 'PREVIOUS YEAR': {
        return moment().subtract(1, 'year').format(format);
      }
      case 'PROGRAM TO DATE': {
        return moment(new Date(calendarSettings.launchDate)).format(format);
      }
      case 'CUSTOM RANGE': {
        return moment(this.currentMonth).startOf('year').format(format);
      }
      case 'M36': {
        return moment(new Date()).add(-36, 'month').format(format);
      }
      default: {
        return moment(new Date()).startOf('month').format(format);
      }
    }
  }
  getEndDateRangeinNumber(identifier: string, format: string) {
    switch (identifier) {
      case 'YTD': {
        return moment().format(format);
      }
      case 'R10': {
        return moment().format(format);
      }
      case 'R30': {
        return moment().format(format);
      }
      case 'MTD': {
        return moment().format(format);
      }
      case '3MTD': {
        return moment().format(format);
      }
      case '6MTD': {
        return moment().format(format);
      }
      case '12MTD': {
        return moment().format(format);
      }
      case 'QTD': {
        return moment().format(format);
      }
      case 'PREVIOUS MONTH': {
        return moment().format(format);
      }
      case 'PREVIOUS QUARTER': {
        return moment().format(format);
      }
      case 'PREVIOUS YEAR': {
        return moment().format(format);
      }
      case 'PROGRAM TO DATE': {
        return moment().format(format);
      }
      case 'CUSTOM RANGE': {
        return moment().format(format);
      }
      default: {
        return moment().format(format);
      }
    }
  }
  getStartDateRange1inNumber(calendarSettings: any, identifier: string, format: string) {
    switch (identifier) {
      case 'YTD': {
        return moment(this.currentMonth).startOf('year').format(format);
      }

      case 'MTD': {
        return moment(this.currentMonth).startOf('month').format(format);
      }

      default: {
        return moment(new Date()).startOf('month').format(format);
      }
    }
  }
  getEndDateRange1inNumber(identifier: string, format: string) {
    switch (identifier) {
      case 'YTD': {
        return moment().format(format);
      }

      case 'MTD': {
        return moment().format(format);
      }

      default: {
        return moment().format(format);
      }
    }
  }
  getActiveOrgHierarchy() {
    const localOrg: any = localStorage.getItem('gbf_org');
    const rValue = {
      OrgName: this.authorizationData?.OrganizationName,
      OrgLevel: this.authorizationData?.Level,
      OrgParent: this.authorizationData?.ParentOrganization,
    };
    let org: any = this.org;
    if (!org && localOrg) {
      org = JSON.parse(localOrg).selected;
    }
    if (org) {
      rValue.OrgName = org.name;
      rValue.OrgLevel = org.level > 0 ? org.level : 1;
      rValue.OrgParent = org.parent;
    }
    return rValue;
  }
  getSurveyQuestionResponses(name: string, initialState: any) {
    let surveyResponses: any = [];
    if (this['surveyResponse_full'].selected && Array.isArray(this['surveyResponse_full'].selectedTreeItems)) {
      surveyResponses = [...this['surveyResponse_full'].selected, ...this['surveyResponse_full'].selectedTreeItems];
    } else {
      const localStorageSurveyResponses: any = JSON.parse(localStorage.getItem('gbf_survey_response'));
      if (localStorageSurveyResponses) {
        surveyResponses = [...localStorageSurveyResponses.selected, ...localStorageSurveyResponses.selectedTreeItems];
      }
    }
    const parents = surveyResponses.filter((x: any) => x && x.parent === 'null');
    const cache: any = [];
    const reponses: any = [];
    if (parents && parents.length > 0) {
      parents.forEach((val: any) => {
        if (val.mProps && cache.indexOf(val.id) === -1) {
          cache.push(val.id);
          const vResponse: any = {};
          const options = surveyResponses.filter((x: any) => x && x.parent === val.name);
          vResponse.Id = val.id;
          vResponse.QuestionId = val.mProps.QuestionId;
          vResponse.Title = val.mProps.Title;
          vResponse.Type = val.mProps.Type;
          vResponse.Options = options.map((o: any) => {
            return {
              Id: o.Id ? o.Id : o.id,
              Title: o.label,
            };
          });
          if (vResponse.Options.length > 0) {
            reponses.push(vResponse);
          }
        }
      });
    }
    return { SurveyResponses: reponses };
  }
  getlanguageCode(name: string, initialState: any) {
    let languages: any = [];
    if (this.language) {
      languages = this.language;
    } else {
      const localStorageLanguages: any = JSON.parse(localStorage.getItem('gbf_language'));
      if (localStorageLanguages) {
        languages = localStorageLanguages;
      }
    }
    let languageCodefiled: any = [];
    if (languages) {
      languages.map((x: any) => {
        if (x.IsChecked && x.Name !== 'All') {
          languageCodefiled.push(x.LanguageCode);
        }
      });
    } else {
      languageCodefiled = [];
    }
    if (languageCodefiled && languageCodefiled.length > 0) {
      return { LanguageCode: languageCodefiled };
    } else {
      return { LanguageCode: [] };
    }
  }
  getSurveyQuestion(name: string, initialState: any) {
    let surveyQuestion: any = [];
    if (this.surveyQuestion) {
      surveyQuestion = this.surveyQuestion;
    } else {
      const localStorageSurveyQuestion: any = JSON.parse(localStorage.getItem('gbf_survey_question'));
      if (localStorageSurveyQuestion) {
        surveyQuestion = localStorageSurveyQuestion;
      }
    }
    let isselectAll = [];
    if (surveyQuestion) {
      isselectAll = surveyQuestion.filter((x: any) => x.IsChecked === true);
    }
    if (isselectAll.length > 0) {
      if (surveyQuestion && surveyQuestion.length > 0) {
        surveyQuestion = surveyQuestion.filter((obj: any) => {
          delete obj.IsSelected;
          obj.Options = obj.Options.filter((o: any) => {
            const flag = o.IsChecked;
            delete o.IsChecked;
            delete o.IsSelected;
            return flag ? true : false;
          });
          return obj.Id.toLowerCase() !== 'all' && obj.IsChecked === true;
        });
      }
    } else {
      if (surveyQuestion && surveyQuestion.length > 0) {
        surveyQuestion = surveyQuestion.filter((obj: any) => {
          delete obj.IsSelected;
          obj.Options = obj.Options.filter((o: any) => {
            const flag = o.IsChecked;
            delete o.IsChecked;
            delete o.IsSelected;
            return flag ? true : false;
          });
          return obj.Id.toLowerCase() !== 'all';
        });
      }
    }
    return { SurveyQuestions: surveyQuestion };
  }
  getCategory(name: string, initialState: any) {
    let category: any = {
      id: 1,
      level: 0,
      name: 'All Category',
    };
    if (this.category) {
      category = this.category;
    } else {
      const localStorageCategory: any = JSON.parse(localStorage.getItem('gbf_category'));
      if (localStorageCategory) {
        category = localStorageCategory;
      }
    }
    return {
      CategoryId: category.id,
      CategoryLevel: category.temp ? category.level : category.level + 1,
      CategoryName: category.name,
    };
  }
  getSurveyScore(name: string, initialState: any) {
    let surveyScore: any = {
      Id: '',
      Type: 'global_filter_label.Survey Score',
      Score: this.getFilterScoreConfigurationData(),
      Types: [],
    };
    if (!this.surveyScore) {
      this.surveyScore = surveyScore;
    } else {
      const localStorageSurveyScore: any = JSON.parse(localStorage.getItem('gbf_survey_score'));
      if (localStorageSurveyScore) {
        surveyScore = localStorageSurveyScore;
      }
    }
    return {
      ScoreRangeType: surveyScore.Type,
      ScoreRangeMin: surveyScore.Score[0],
      ScoreRangeMax: surveyScore.Score[1],
    };
  }

  getFilterScoreConfigurationData() {
    const opts = this.appConfig.getByIdentifier(Identifiers.FilterBarScoreRange);
    if (opts) {
      if (opts.ClientConfig === null) {
        opts.ClientConfig = opts;
      }
      if (opts.ClientConfig) {
        return opts.ClientConfig.Fields;
      } else {
        return [0, 100];
      }
    } else {
      return [0, 100];
    }
  }
  getModel(name: string, initialState: any) {
    let model: any = {
      ActiveModal: '',
      ActiveModalClass: '',
      ActiveModalYear: '',
    };
    if (this.model) {
      model = this.model;
    } else {
      const local: any = JSON.parse(localStorage.getItem('gbf_mby'));
      if (local && local.selected) {
        model = local.selected;
      }
    }
    return {
      ActiveModal: model.Model === 'All Models' ? '' : model?.Model || model?.mProps?.Model || '',
      ActiveModalClass: model.Brand === 'All Brands' ? '' : model?.Brand || model?.mProps?.Brand || '',
      ActiveModalYear: model.Year === 'All Years' ? '' : model?.Year || model?.mProps?.Year || '',
    };
  }
  getMultiOrg(name: string, initialState: any) {
    let orgs: any = [];
    if (this.multi_org && this.multi_org.length > 0) {
      orgs = this.multi_org;
    } else {
      const orgLocal: any = JSON.parse(localStorage.getItem('gbf_multi_org'));
      if (orgLocal && orgLocal.selected) {
        orgs = orgLocal.selected;
      }
    }
    if (!Array.isArray(orgs)) {
      orgs = [orgs];
    }
    const maxOrgLevel = Math.max.apply(
      Math,
      orgs.map((o: any) => {
        return o.level;
      })
    );
    const mOrgs: any[] = [];
    orgs = orgs.filter((org: any) => org.level === maxOrgLevel);
    if (orgs) {
      orgs.forEach((org: any) => {
        mOrgs.push({
          OrgNodeName: org.NodeName || org.mProps?.NodeName, // NodeId
          OrgName: org.NodeId || org.mProps?.NodeId, // NodeId
          OrgParent: org.parent || org.mProps?.parent, // Org Parent
          OrgLevel: org.level || org.mProps?.level, // Org Parent
          OrgIdentifier: org.Identifier || org.mProps?.Identifier, // Identifier
        });
      });
    }
    if (mOrgs && mOrgs.length > 0) {
      return { Orgs: mOrgs, OrgLevel: maxOrgLevel, ...mOrgs[0] };
    } else {
      this[name] = initialState;
      return {
        Orgs: initialState,
        OrgLevel: 1,
        OrgParent: initialState.OrgParent || 'null',
        OrgName: initialState.NodeId,
        OrgNodeName: initialState.name,
        OrgIdentifier: initialState.Identifier,
      };
    }
  }
  getMultiOrgHeatMap(name: string, initialState: any) {
    let orgs: any = [];
    if (this.multi_org && this.multi_org.length > 0) {
      orgs = this.multi_org;
    } else {
      const orgLocal: any = JSON.parse(localStorage.getItem('gbf_multi_org'));
      if (orgLocal && orgLocal.selected) {
        orgs = orgLocal.selected;
      }
    }
    if (!Array.isArray(orgs)) {
      orgs = [orgs];
    }

    const mOrgs: any[] = [];
    // orgs = orgs.filter((org: any) => org.level === maxOrgLevel);
    if (orgs) {
      orgs.forEach((org: any) => {
        mOrgs.push({
          OrgNodeName: org.NodeName || org.mProps?.NodeName, // NodeId
          OrgName: org.NodeId || org.mProps?.NodeId, // NodeId
          OrgParent: org.parent || org.mProps?.parent, // Org Parent
          OrgLevel: org.level || org.mProps?.level, // Org Parent
          OrgIdentifier: org.Identifier || org.mProps?.Identifier, // Identifier
        });
      });
    }
    if (mOrgs && mOrgs.length > 0) {
      return { Orgs: mOrgs, OrgLevel: orgs, ...mOrgs[0] };
    } else {
      this[name] = initialState;
      return {
        Orgs: initialState,
        OrgLevel: 1,
        OrgParent: initialState.OrgParent || 'null',
        OrgName: initialState.NodeId,
        OrgNodeName: initialState.name,
        OrgIdentifier: initialState.Identifier,
      };
    }
  }
  getMultiModel(name: any, initialState: any) {
    const modelConfig = this.gbfConfig.filterConfig.config.find((x: any) => x.name == name.filter);
    let selectedModels: any = [];
    if (this.multi_model && this.multi_model.length > 0) {
      selectedModels = this.multi_model;
    } else {
      const modelLocal: any = JSON.parse(localStorage.getItem('gbf_multi_mby'));
      if (modelLocal && modelLocal.selected) {
        selectedModels = modelLocal.selected;
      }
    }
    let years: any[] = [];
    let models: any[] = [];
    let brands: any[] = [];
    if (selectedModels) {
      selectedModels.forEach((model: any) => {
        years.push(model.mProps.Year);
        models.push(model.mProps.Model);
        brands.push(model.mProps.Brand);
      });
    }
    const yearGroups = years.filter((x: any) => x.indexOf('YearGroup') > -1);
    if (yearGroups && yearGroups.length > 0) {
      yearGroups.forEach((yg: any) => {
        years = years.filter((y) => y != yg);
        const yearGroupYears = selectedModels.find((sm: any) => sm.name == yg)?.mProps?.yearGroupYears;
        years.push(...yearGroupYears);
      });
    }
    let result = {
      ActiveModels: [...new Set(models)].filter((x) => x != undefined && x != null),
      ActiveBrands: [...new Set(brands)].filter((x) => x != undefined && x != null),
      ActiveYears: [...new Set(years)].filter((x) => x != undefined && x != null),
    };
    return result;
  }

  getOrg(name: string, initialState: any) {
    let org: any = {
      NodeId: '',
      parent: '',
      level: '',
      Identifier: '',
    };
    if (this?.org?.NodeId) {
      org = this.org;
    } else {
      const orgLocal: any = JSON.parse(localStorage.getItem('gbf_org'));
      if (orgLocal && orgLocal.selected) {
        org = orgLocal.selected;
      } else if (orgLocal && orgLocal.NodeId) {
        org = orgLocal;
      }
    }

    const Org = {
      OrgLabel: org?.label || org?.Label || '',
      OrgNodeName: org?.NodeName, // NodeId
      Id: org.id || org?.mProps?.Name, // NodeId
      OrgName: org.NodeId || org?.mProps?.NodeId, // NodeId
      OrgParent: org.parent || org?.mProps?.parent, // Org Parent
      OrgLevel: org.orginalLevel || org.level, // Org Parent
      OrgIdentifier: org.Identifier || org?.mProps?.Identifier, // Identifier
    };

    // const Org = {
    //   OrgNodeName: org.NodeName, // NodeId
    //   Id: org.id || org.mProps.Name, // NodeId
    //   OrgName: org.NodeId || org.mProps.NodeId, // NodeId
    //   OrgParent: org.parent || org.mProps.parent, // Org Parent
    //   OrgLevel: org.level, // Org Parent
    //   OrgIdentifier: org.Identifier || org.mProps.Identifier, // Identifier
    // };
    return { Orgs: [Org], ...Org };
  }
  getDates(name: string, initialState: any) {
    const dateRange = this.getCalenderRange();
    return {
      DateIdentifier: this.getDateIdentifier(),
      StartDate: dateRange[0],
      EndDate: dateRange[1],
    };
  }
  getDatesdailyreport(name: string, initialState: any) {
    const dateRange = this.getCalender1Range();
    return {
      DateIdentifier: this.getDateIdentifier(),
      StartDate: dateRange[0],
      EndDate: dateRange[1],
    };
  }

  getSelectedOrgLevel() {
    if (this.isMultiOrg) {
      this.currentOrgLevel = this.getMultiOrg('multi_org', {}).OrgLevel;
    } else {
      this.currentOrgLevel = this.getOrg('org', {}).OrgLevel;
    }
    return this.currentOrgLevel;
  }

  setSelectedOrgLevel() {
    if (this.isMultiOrg) {
      this.currentOrgLevel = this.getMultiOrg('multi_org', {}).OrgLevel;
    } else {
      this.currentOrgLevel = this.getOrg('org', {}).OrgLevel;
    }
    this.isGreatorOrEqualMainNodeLevelSelected = this.currentOrgLevel >= this.authService.getUser().MainNodeLevel;
    this.isGreatorMainNodeLevelSelected = this.currentOrgLevel > this.authService.getUser().MainNodeLevel;
    this.isLessMainNodeLevelSelected = this.currentOrgLevel < this.authService.getUser().MainNodeLevel;
  }

  getDealer(name: string, initialState: any) {
    return {
      DealerType: this.dealer.value,
    };
  }
  getVersion(name: string, initialState: any) {
    return {
      SurveyVersion: this.version.value,
    };
  }
  getVersionQP(name: string, initialState: any) {
    return {
      SurveyVersion: (this as any).version_qp.value,
    };
  }
  setVersionQP(data: any) {
    (this as any).version_qp = data;
    localStorage.setItem('gbf_version_qp', JSON.stringify(data));
  }
  getSources(name: string, initialState: any) {
    const checked = this.sources.filter((x: any) => x.IsChecked === true);
    if (checked) {
      return {
        SourceType: this.sources.map((x: any) => {
          return {
            Id: x.value,
            IsChecked: x.IsChecked,
            Title: x.label,
          };
        }),
      };
    } else {
      return {
        SourceType: initialState,
      };
    }
  }

  setSentiment(data: any) {
    var sentiment = JSON.parse(localStorage.getItem('gbf_sentiments'));
    if (sentiment) this.sentiment = { label: sentiment.label, value: sentiment.label, type: sentiment.value };
  }

  getSentiments(name: string, initialState: any) {
    return {
      SentimentType: this.sentiment.type,
    };
  }
  getDynamicFilterObject() {
    if (this.currentUrl.includes('?')) {
      this.currentUrl = this.currentUrl.split('?')[0];
    }
    let localUrl = this.currentUrl;
    if (this.activatedUrl) {
      localUrl = this.activatedUrl;
      this.activatedUrl = '';
    }
    //let localUrl = this.currentUrl.replace(window.location.search, '');
    const filterConfig = this.gbfConfig.filterConfig;
    let tabs = this.gbfConfig.filterConfig.pageConfig['default']['tabs'];
    let configs = this.gbfConfig.filterConfig.pageConfig['default']['filter'];
    if (filterConfig.pageConfig[filterConfig.pageUrlPrefix + localUrl]) {
      configs = filterConfig.pageConfig[filterConfig.pageUrlPrefix + localUrl]['filter'];
      tabs = filterConfig.pageConfig[filterConfig.pageUrlPrefix + localUrl]['tabs'];
      const _staticPageConfig = StaticPageConfig[filterConfig.pageUrlPrefix + localUrl];
      if (_staticPageConfig && _staticPageConfig['filter']) {
        configs = [...configs, ..._staticPageConfig['filter']];
      }
      if (_staticPageConfig && _staticPageConfig['tabs']) {
        tabs = [...tabs, ..._staticPageConfig['tabs']];
      }
    }
    let filterObject: any = {};
    configs.forEach((filter: any) => {
      let f = this.gbfConfig.filterConfig.returnFilter[filter] || StaticReturnFilter[filter];
      let initialState = this.gbfConfig.filterConfig.initialStates[filter] || StaticIntialState[filter];
      if (f) {
        f.filter = filter;
        if (f.signature === 'method') {
          if (this[f.name]) {
            filterObject = { ...filterObject, ...this[f.name](f, initialState) };
          }
        } else if (f.signature === 'property') {
          if (this[f.name] && this[f.name][f.valueProp]) {
            filterObject[f.filterKey] = this[f.name][f.valueProp];
          } else if (this[f.name]) {
            filterObject[f.filterKey] = this[f.name];
          }
        }
      }
    });

    if (!this.dateIdentifier) {
      const found = this.gbfConfig.filterConfig.config.find((x: any) => x.name === 'calendar');
      if (found) {
        this.dateIdentifier = found.range;
      }
    }
    return { tabs: tabs, filters: filterObject };
  }
  getFilterObject() {
    const filter = this.getDynamicFilterObject();
    const filters = {
      PageSize: this.pageSize,
      PageNo: this.pageNo,
      Fields: this.field,
      Filters: this.filterItem,
      Status: '',
      SortColumn: this.SortColumn,
      Sort: this.Sort,
      ...filter.filters,
    };
    // if (!filter.tabs) {
    if (this.tab && this.tab.value) {
      filters.Event = this.tab.value;
    } else if (Array.isArray(this.tab) && this.tab.length > 0 && this.tab[0].value) {
      filters.Event = this.tab[0].value;
    }
    // }
    if (!this.version || !this.version.value) {
      const version = JSON.parse(localStorage.getItem('surveyVersions'))?.find((x: any) => x.Event === this.tab.value);
      if (version && version.value) {
        this.version = {
          label: version.Event,
          value: version.value,
          version: version.value,
          versionName: version.Version,
        };
        localStorage.setItem('gbf_version', JSON.stringify(this.version));
        filters.SurveyVersion = version.value;
      }
    }
    return filters;
  }

  public applyYearGroupPredicate(predicate: string, year: string): boolean {
    //"YearGroup_GraterThan-CurrentYear-Minus-7","YearGroup_LessThan-CurrentYear-Minus-6"
    const currentYear = moment().get('year');
    predicate = predicate.replace('CurrentYear', currentYear.toString());
    var predicateParts = predicate.split('_')[1].split('-');
    if (predicateParts[0] == 'GraterThan') {
      if (predicateParts[2] == 'Minus') {
        return parseInt(year) > parseInt(predicateParts[1]) - parseInt(predicateParts[3]);
      }
      if (predicateParts[2] == 'Plus') {
        return parseInt(year) > parseInt(predicateParts[1]) + parseInt(predicateParts[3]);
      }
    }
    if (predicateParts[0] == 'LessThan') {
      if (predicateParts[2] == 'Minus') {
        return parseInt(year) < parseInt(predicateParts[1]) - parseInt(predicateParts[3]);
      }
      if (predicateParts[2] == 'Plus') {
        return parseInt(year) < parseInt(predicateParts[1]) + parseInt(predicateParts[3]);
      }
    }
    return false;
  }

  private getNeariestLevel() {
    const records = this.authService.orgListData;
    let selectedLevel = this.authService?.authData?.MainNodeLevel;
    for (let level = 1; level <= selectedLevel; level++) {
      const found = records.filter((x: any) => x.level === level);
      if (found.length === 1) {
        const items = records.filter((x: any) => x.level === level + 1) || [];
        if (items.length == 0) {
          this.neariestOrg = found[0];
          break;
        } else if (items.length > 1) {
          this.neariestOrg = found[0];
          break;
        } else {
        }
      } else {
        this.neariestOrg = found[0];
        break;
      }
    }
  }
}
