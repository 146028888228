<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <div widgetArea class="card-table">
    <table [hidden]="!canRender" [attr.data-can-render]="canRender">
      <tbody>
        <tr>
          <th *ngFor="let col of cols">
            <div>{{ col.header | translate }}</div>
          </th>
        </tr>
        <tr *ngFor="let widgetData of surveyListWidget">
          <td *ngFor="let col of cols">
            <div *ngIf="col.field != 'ResponeDate' && col.field != 'Score'">{{ widgetData[col.name] }}</div>
            <div *ngIf="col.field == 'ResponeDate'">{{ widgetData[col.name] | customdateformat: 'date' }}</div>
            <div *ngIf="col.field == 'Score'">{{changeValue(widgetData[col.name]) }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-widget-dynamic>
