import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, of, EMPTY, Subject } from 'rxjs';
import { RestMessage } from '@app/core/types';
import { FileUploader } from 'ng2-file-upload';
import { Logger, AuthenticationService } from '@app/core';
import { catchError, tap, map, filter } from 'rxjs/operators';
import { OrgHierachy } from '@app/core/types/orgHierarchy';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { SurveyVersions } from '@app/core/types/suveyVesions';
import { TreeNode } from '@primeng';
import { ILanguages } from '@app/core/types/languages';
import { sentimentDropdownOptions } from '../../../../projects/cvreporting/src/app/features/cvreporting/widgets/widget-data';

const log = new Logger('SmFiltersService');

@Injectable({
  providedIn: 'root',
})
export class SmFiltersService {
  subjecttab = new Subject<any>();
  dateFormat = 'MMM D, YYYY';
  temp: any[] = [];
  orgHierarchy: OrgHierachy[] = [];
  names: string[] = [];
  org: any;
  value: any;
  doneDate: any;
  program = 'NVS';
  activeYear: string;
  activeModalType = 'Mercedes-Benz';
  activeModal: string;
  orgApiData: any;
  reportingUrl = '/reporting';
  currentOrg = 'National';
  currentLevel = 1;
  currentParent: string = null;
  dealerType = 'All';
  languageTitle = 'All Languages';
  sentimentTitle = 'All Sentiments';
  aspectCategoryTitle = 'All Categories';
  aspectCategorySelected: TreeNode;
  surveyQuestionTitle = 'Survey Questions';
  surveyScoreTitle = 'Score Range';
  sourceType = 'All';

  orgIdentifier: string;
  pageSize = 25;
  pageNo = 1;
  SortColumn = '';
  Sort = '';
  surveyVersion = '';
  allSurveyVersions: SurveyVersions[] = [];
  currentSurveyVersionList: SurveyVersions[] = [];
  filterDisabledStatus = true;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private translateService: TranslateService
  ) {
    this.program = this.authService.getProgram();
    const h = JSON.parse(localStorage.getItem('authorizationData'));
    this.orgIdentifier = h.OrgIdentifier;
    if (!this.surveyVersion) {
      this.surveyVersion = h.DefaultSurveyVersion;
    }
  }

  getAllOrgs() {
    let type;
    switch (this.dealerType) {
      case 'All':
        type = JSON.stringify(['Private', 'Corporate']);
        break;
      default:
        type = `${JSON.stringify([this.dealerType])}`;
        break;
    }
    const url = `${this.reportingUrl}/orgList?orgIdentifier=${this.orgIdentifier}&Fields=${type}`;
    return this.httpClient.get<RestMessage>(url).pipe(
      tap((_: any) => log.debug('in tap getData')),
      map((res: any) => {
        this.orgApiData = res;
        return this.mapOrgData(res);
      })
    );
  }

  public mapOrgData(res: any) {
    this.org = res;
    const orgHierarchy: any = [];
    const hierarchy = this.getNodes(1, 'null');
    for (let i = 0; i < hierarchy.length; i++) {
      orgHierarchy.push(hierarchy[i]);
    }
    return orgHierarchy;
  }

  resetDefaultFilters() {
    this.activeModalType = 'Mercedes-Benz';
    this.dealerType = 'All';
    this.languageTitle = 'All Languages';
    this.surveyQuestionTitle = 'Survey Questions';
    this.sentimentTitle = 'All Sentiments';
    this.aspectCategoryTitle = 'All Categories';
    this.surveyScoreTitle = 'Score Range';
    this.program = 'Sales';
    this.activeYear = new Date().getFullYear().toString();
    this.activeModal = '';
  }

  public getNodes(level: number, name: string) {
    const obj = this.searchNode(level, name);
    if (obj) {
      obj.forEach((element: any, index: number) => {
        obj[index].level = level;
        obj[index].label = element.Label;
        obj[index].type = element.type;
        obj[index].children = this.getNodes(level + 1, element.Name);
      });
    }
    return obj;
  }
  public getNodesByName(level: number, name: string) {
    const obj = this.searchNodeByName(level, name);
    if (obj) {
      obj.forEach((element: any, index: number) => {
        obj[index].level = level;
        obj[index].label = element.Label;
        obj[index].children = this.getNodesByName(level + 1, element.Name);
      });
    }
    return obj;
  }
  searchNode(level: number, name: string): any {
    for (let i = 0; i < this.org.length; i++) {
      if (this.org[i].Level === level) {
        for (let j = 0; j < this.org[i].Data.length; j++) {
          if (this.org[i].Data[j].Parent.toLowerCase() === name.toLowerCase()) {
            for (let k = 0; k < this.org[i].Data[j].Nodes.length; k++) {
              this.org[i].Data[j].Nodes[k].parent = this.org[i].Data[j].Parent;
            }
            return this.org[i].Data[j].Nodes;
          }
        }
      }
    }
    return [];
  }

  searchNodeByName(level: number, name: string): any {
    for (let i = 0; i < this.org.length; i++) {
      if (this.org[i].Level === level) {
        for (let j = 0; j < this.org[i].Data.length; j++) {
          // if (this.org[i].Data[j].Name === name) {
          for (let k = 0; k < this.org[i].Data[j].Nodes.length; k++) {
            if (this.org[i].Data[j].Nodes[k].Name === name) {
              return this.org[i].Data[j].Nodes;
              // this.org[i].Data[j].Nodes[k].Name = this.org[i].Data[j].Name;
            }
          }
          // return this.org[i].Data[j].Nodes;
          // }
        }
      }
    }
    return [];
  }
  updateCalendarRange(value: any) {
    this.value = value;
  }

  getValue() {
    let date: any = localStorage.getItem('filter_date') || null;
    if (date) {
      date = date.split('|');
      if (date.length > 1) {
        return [date[0], date[1]];
      }
    } else {
      const thisYear = new Date().getFullYear();
      const start = new Date('1/1/' + thisYear);
      const defaultStart = moment(start.valueOf()).toDate();
      return this.value ? this.value : [defaultStart, moment().toDate()];
    }
  }
  getLocalStorageModalType() {
    const d = JSON.parse(localStorage.getItem('modal_type'));
    return d;
  }
  getModelType() {
    const d = this.getLocalStorageModalType();
    if (d && d.activeModal && d.activeModalType) {
      return {
        activeModalType: d.activeModalType.label,
        activeModal: d.activeModal.label,
      };
    } else {
      return {
        activeModalType: this.activeModalType,
        activeModal: this.activeModal,
      };
    }
  }

  updateModel(modalYear: string = '', modalType: string = '', modal: string = '') {
    if (modalYear !== '') {
      this.activeYear = modalYear;
    } else {
      this.activeYear = '';
    }
    this.activeModalType = modalType;
    this.activeModal = modal;
  }
  getCalenderRange() {
    let date: any = localStorage.getItem('filter_date') || null;
    if (date) {
      date = date.split('|');
      if (date.length > 1) {
        const start = moment(date[0]).format('MMM D, YYYY');
        const end = moment(date[1]).format('MMM D, YYYY');
        const v = [start, end];
        localStorage.setItem('filter_date', v.join('|'));
        return v;
      }
    } else {
      const start = moment().startOf('year').format('MMM D, YYYY');
      const end = moment().format('MMM D, YYYY');
      const v = [start, end];
      localStorage.setItem('filter_date', v.join('|'));
      return this.value ? this.value : v;
    }
  }

  getActiveOrgHierarchy() {
    const h = JSON.parse(localStorage.getItem('authorizationData'));
    this.currentOrg = h.OrganizationName;
    this.currentLevel = h.Level;
    this.currentParent = h.ParentOrganization;

    const active = JSON.parse(localStorage.getItem('active'));

    if (active && active.length > 0) {
      // if (active.length == 3) {
      let lastActive = active[active.length - 1];
      if (!lastActive.active.Name) {
        lastActive = active[active.length - 2];
      }
      if (lastActive && lastActive.active) {
        let o = null;
        if (lastActive.active.parent && lastActive.active.parent.Name) {
          o = lastActive.active.parent.Name;
        } else if (lastActive.active.parent) {
          o = lastActive.active.parent;
        }
        if (typeof o === 'object') {
          o = 'null';
        }
        return {
          OrgName: lastActive.active.Name,
          OrgLevel: lastActive.active.level,
          OrgParent: o,
        };
      } else {
        return {
          OrgName: this.currentOrg, // 'National',
          OrgLevel: this.currentLevel,
          OrgParent: this.currentParent,
        };
      }
      // }
      // else {
      //     const lastActive = active[active.length - 1];
      //     if (lastActive && lastActive.active) {
      //         return {
      //             OrgName: lastActive.active.Name,
      //             OrgLevel: lastActive.active.level,
      //             OrgParent: lastActive.active.parent,
      //         };
      //     }
      //     else {
      //         return {
      //             OrgName: 'National',
      //             OrgLevel: 1,
      //             OrgParent: null,
      //         }
      //     }

      // }
    } else {
      return {
        OrgName: this.currentOrg,
        OrgLevel: this.currentLevel,
        OrgParent: this.currentParent,
      };
    }
  }

  setProgram(program: string) {
    this.program = program;
    this.resetSurveyResponse();
  }
  setSurveyVersion(version: string) {
    this.surveyVersion = version;
  }
  getSurveyVersion() {
    return this.surveyVersion;
  }
  setPaging(size: number, page: number) {
    this.pageSize = size;
    this.pageNo = page;
  }
  setSorting(field: string, order: number) {
    this.SortColumn = field;
    this.Sort = order.toString();
  }
  getDealerType() {
    const dealerType = JSON.parse(localStorage.getItem('dealer_type'));
    if (dealerType && dealerType.label) {
      return { ...dealerType, default: false };
    } else {
      return {
        label: this.translateService.instant('all_mb_(corporate_&_private)'),
        value: 'All',
        translate: 'all_mb_(corporate_&_private)',
        default: true,
      };
    }
  }

  setDealerType(value: string, label: string, translate: string) {
    localStorage.setItem('dealer_type', JSON.stringify({ value, label, translate }));
    this.dealerType = value;
  }

  setLanguage(data: ILanguages) {
    const name = data.Name;
    localStorage.setItem('voc_language', JSON.stringify(data));
    // localStorage.setItem('voc_language', JSON.stringify({ id, name }));
    this.languageTitle = data ? `${data.Name} (${data.LanguageCode})` : null;
  }

  getLanguage() {
    const vocLanguage = JSON.parse(localStorage.getItem('voc_language'));
    if (vocLanguage && vocLanguage.Name) {
      return { ...vocLanguage, default: false };
    }
    // else {
    //     return {
    //         Name: this.translateService.instant('English'),
    //         LanguageCode: 'en-CA',
    //     };
    // }
  }

  getLanguagesData() {
    const url = `/voc/languages`;
    return this.httpClient.cache().get<RestMessage>(`${url}`);
  }

  getSentimentData() {
    return sentimentDropdownOptions;
  }

  getSentiment() {
    const vocSentiment = JSON.parse(localStorage.getItem('voc_sentiment'));
    if (vocSentiment && vocSentiment.label) {
      return { ...vocSentiment, default: false };
    } else {
      return {
        label: this.translateService.instant('All Sentiments'),
        value: null,
      };
    }
  }

  setSentiment(data: any) {
    // console.log('data',data);
    localStorage.setItem('voc_sentiment', JSON.stringify(data));
    this.sentimentTitle = data.label;
  }

  getAspectCategoryData() {
    const url = `/voc/categories`;
    return this.httpClient.cache().get<RestMessage>(`${url}`);
  }

  setAspectCategory(data: any) {
    localStorage.setItem('voc_category', JSON.stringify(data));
    this.aspectCategorySelected = data;
  }

  getAspectCategory() {
    const aspectCategory = JSON.parse(localStorage.getItem('voc_category'));
    if (aspectCategory && aspectCategory.label) {
      return aspectCategory;
    } else {
      return {
        label: 'All Categories',
      };
    }
  }

  getSurveyQuestionData(): any {
    const sv = this.getSurveyVersion();
    const authData = JSON.parse(localStorage.getItem('authorizationData'));

    if (authData && authData.DefaultLanguage) {
      const url = `/voc/questions/${sv}/${authData.DefaultLanguage}`;
      return this.httpClient.cache().get<RestMessage>(`${url}`);
    } else {
      return of([]);
    }
  }

  setTab(message: string) {
    this.subjecttab.next({ text: message });
  }

  clearTab() {
    this.subjecttab.next(null);
  }

  getTab(): Observable<any> {
    return this.subjecttab.asObservable();
  }

  getSurveyQuestion() {
    const surveyQuestion = JSON.parse(localStorage.getItem('survey_question'));
    if (surveyQuestion) {
      return surveyQuestion;
    }
  }

  getSmReviewTypes() {
    var text = '[{"Id":"All"},{"Id":"Google"},{"Id":"Yelp"},{"Id":"Dealer Rater"}]';
    const smReviewTypes = JSON.parse(text);
    if (smReviewTypes) {
      return smReviewTypes;
    }
  }

  setSurveyQuestion(data: any) {
    localStorage.setItem('survey_question', JSON.stringify(data));
  }

  setSurveyResponses(data: any) {
    localStorage.setItem('survey_responses', JSON.stringify(data));
  }
  resetSurveyResponse() {
    if (localStorage.getItem('survey_responses') !== null) {
      localStorage.removeItem('survey_responses');
    }
    if (localStorage.getItem('survey_question') !== null) {
      localStorage.removeItem('survey_question');
    }
  }
  getSurveyResponses() {
    const surveyResponses = JSON.parse(localStorage.getItem('survey_responses'));
    if (surveyResponses && surveyResponses.length > 0) {
      return surveyResponses;
    }
  }

  setSurveyScore(data: any) {
    localStorage.setItem('survey_score', JSON.stringify(data));
    this.surveyScoreTitle = data.Type;
  }

  getSurveyScore() {
    const surveyScore = JSON.parse(localStorage.getItem('survey_score'));
    if (surveyScore && surveyScore.Type) {
      return { ...surveyScore, default: false };
    } else {
      return {
        Type: 'Survey Score',
        Score: [0, 100],
      };
    }
  }

  getFilterObject() {
    this.getModelType();
    const dateRange = this.getCalenderRange();
    const activeHierarchy = this.getActiveOrgHierarchy();
    const surveyResp = this.getSurveyResponses();
    if (surveyResp && surveyResp.length > 0) {
      surveyResp.forEach((val: any) => {
        delete val.IsChecked;
        delete val.IsSelected;
        val.Options = val.Options.filter((o: any) => {
          const flag = o.IsChecked;
          delete o.IsChecked;
          delete o.IsSelected;
          return flag ? true : false;
        });
      });
    }

    const filters = {
      Event: this.program,
      StartDate: dateRange[0],
      EndDate: dateRange[1],
      OrgName: activeHierarchy.OrgName,
      OrgLevel: activeHierarchy.OrgLevel,
      OrgParent: activeHierarchy.OrgParent,
      PageSize: this.pageSize,
      PageNo: this.pageNo,
      ActiveModal: this.getModelType().activeModal,
      ActiveModalClass: this.getModelType().activeModalType,
      ActiveModalYear: this.activeYear,
      DealerType: this.getDealerType().value,
      SentimentType: this.getSentiment().value,
      LanguageCode: this.getLanguage() ? this.getLanguage().LanguageCode : null,
      CategoryId: this.getAspectCategory() ? this.getAspectCategory().key : null,
      CategoryName: this.getAspectCategory() ? this.getAspectCategory().label : null,
      CategoryLevel: this.getAspectCategory() ? this.getAspectCategory().leaf : null,
      Questions: surveyResp,
      OrgIdentifier: this.orgIdentifier,
      SurveyVersion: this.surveyVersion,
      ScoreRangeType: this.getSurveyScore().Type,
      ScoreRangeMin: this.getSurveyScore().Score[0],
      ScoreRangeMax: this.getSurveyScore().Score[1],

      Fields: '',
      Filters: '',
      Status: '',
      SortColumn: this.SortColumn,
      Sort: this.Sort,
    };

    return filters;
  }

  getVindecoderData() {
    const url = `/Vindecoder/GetVindecoderFilters`;
    return this.httpClient.cache().get<RestMessage>(url);
  }
  getSurveyVersions() {
    const url = `/surveylist/GetSurveyVersions`;
    return this.httpClient.get<RestMessage>(url);
    // .pipe(
    //     tap((_: any) => log.debug('in tap getData')),
    //     map((res: any) => {
    //         return this.mapVersionData(res);
    //     })
    // );
  }
  mapVersionData(data: any) {
    this.allSurveyVersions = [];
    data.forEach((o: any) => {
      const row: SurveyVersions = {};
      row.Event = o.Event;
      row.Brand = o.Brand;
      row.SurveyId = o.SurveyId;
      row.ParentSurveyId = o.ParentSurveyId;
      row.Version = o.Version;
      row.CurrentSurvey = o.CurrentSurvey;

      this.allSurveyVersions.push(row);
    });
  }

  ResetFilters() {
    const dateRange = this.getCalenderRange();
    const activeHierarchy = this.getActiveOrgHierarchy();
    this.activeModalType = 'Mercedes-Benz';
    this.activeModal = '';
    const filters = {
      Event: this.program,
      StartDate: dateRange[0],
      EndDate: dateRange[1],
      // OrgName: 'National',
      // OrgLevel: 1,
      // OrgParent: 'null',
      // Query: '',
      PageSize: 25,
      PageNo: 0,
      ActiveModalClass: 'Mercedes-Benz',
      ActiveModalYear: '',
      DealerType: 'All',
    };
    return filters;
  }

  getProgram() {
    return this.program;
  }
}

// Extra Work

// public addChildren(i1: number, j1: number, k1: number, orgHierarchy: any) {
//     var result = orgHierarchy.find((x: any) => {
//         return x.label === this.org[i1].data[j1].parent;
//     });
//     var index = orgHierarchy.indexOf(result);
//     if (~index) {
//         orgHierarchy[index].children.push({
//             label: this.org[i1].data[j1].nodes[k1].name,
//             level: this.org[i1].level,
//             parent: orgHierarchy[index],
//             children: []
//         });
//     }
// }
// this.temp.sort((a: any, b: any) => {
//     if (a.level < b.level) {
//         return -1;
//     }
//     if (a.level > b.level) {
//         return 1;
//     }
//     return 0;
// });
// this.orgHierarchy.push({level: this.temp[0].level, parent: this.temp[0].parent, children: [], label: this.temp[0].childs[0].name})
// this.orgHierarchy.push({level: this.temp[0].level, parent: this.temp[0].parent, children: [], label: this.temp[0].childs[1].name})
// for(let i = 1 ; i < this.temp.length; i++) {
//     var result = this.orgHierarchy.find((x: any) => {
//         return x.label === this.temp[i].parent;
//     });
//     var index = this.orgHierarchy.indexOf(result);
//     if (~index) {
//         this.orgHierarchy[index].children = this.temp[i].childs;
//     }
// }

// for (let i = this.org.length - 1; i > 0; i--) {
//     let children: any = [];
//     for (let j = 0; j < this.org[i].data.length; j++) {
//         let child: any = [];
//         for (let k = 0; k < this.org[i].data[j].nodes.length; k++) {
//             child.push({ label: this.org[i].data[j].nodes[k].name })
//         }
//         children.push({ parent: this.org[i].data[j].parent, children: child, level: this.org[i].level })
//     }
//     this.temp.push(children);
// }

// for (let i = 0; i < this.org.length; i++) {
//     for (let j = 0; j < this.org[i].data.length; j++) {
//         if (this.org[i].data[j].parent) {
//             if (this.org[i].data[j].parent != 'null' && this.org[i].data[j].parent != '') {
//                 for (let k = 0; k < this.org[i].data[j].nodes.length; k++) {
//                     if (this.org[i].level == 2) {
//                         this.addChildren(i, j, k, this.orgHierarchy);
//                     } else if (this.org[i].level == 3) {
//                         for(let x = 0 ; x < this.orgHierarchy.length ;x++) {
//                             this.addChildren(i, j, k, this.orgHierarchy[x].children);
//                         }

//                     } else if (this.org[i].level == 4) {
//                         for(let x = 0 ; x < this.orgHierarchy.length ;x++) {
//                             for(let y = 0; y < this.orgHierarchy[x].children.length ; y++) {
//                                 this.addChildren(i, j, k, this.orgHierarchy[x].children[y].children);
//                             }
//                         }
//                     }

//                 }

//             } else {
//                 for (let k = 0; k < this.org[i].data[j].nodes.length; k++) {
//                     this.orgHierarchy.push({
//                         label: this.org[i].data[j].nodes[k].name,
//                         level: this.org[i].level,
//                         parent: null,
//                         children: []
//                     });
//                 }
//             }

//         }
//     }

// }
/*if (level == 1) {
    let child = [];
    for (let i = 0; i < this.org[level - 1].data[0].nodes.length; i++) {
        child.push({ children: [], level: this.org[level - 1].level, parent: this.org[level - 1].data[0].parent, label: this.org[level - 1].data[0].nodes[i].name })
    }
    // orgHierarchy.push(child);
    return child;
} else if (level != 1 && level <= 4) {
    var child = [];
    for (let i = 0; i < this.org[level - 1].data.length; i++) {
        for (let j = 0; j < this.org[level - 1].data[i].nodes.length; j++) {
            child.push({ children: [], level: this.org[level - 1].level, parent: this.org[level - 1].data[i].parent, label: this.org[level - 1].data[i].nodes[j].name })

        }
    }
    // orgHierarchy.push(child);
    return child;



}*/

// }
// recursion(orgArray: any, orgHierarchy: any) {
//     for(let i = 0 ; i < orgArray.length ; i++) {
//         for(let j = 0 ; j < orgArray[i].children.length ; j++) {
//             orgHierarchy.push({label: orgArray[i].children[j].label, children:[]})
//         }

//     }

// }
