<ng-template #header> Report Charts </ng-template>
<ng-template #content>
  <nav
    class="nav"
    id="menu"
    style="clear: both; width: 100%; height: 80px; border-bottom: 1px solid #ccc; display: block"
  >
    <ul>
      <li class="nav__link" *ngFor="let item of tabItems; index as navItemIndex" [class.active]="item.active">
        <a (click)="setViewActive(item, navItemIndex)" translate>
          <i [class]="item.icon"></i> <span translate [innerHTML]="item.name"></span>
        </a>
      </li>
    </ul>
  </nav>
  <ng-container *ngIf="currentStep === 1">
    <ng-container [ngSwitch]="activeItem.key">
      <div *ngSwitchCase="'PIE'" style="padding: 1em; min-height: 250px">
        <label style="margin-bottom: 5px">Select a Variable</label>
        <p-dropdown [autoZIndex]='false'
            panelStyleClass='modal-dropdown'
          [appendTo]="'body'"
          [options]="mColumns"
          [style]="{ width: '100%' }"
          [(ngModel)]="selectedPieValue"
          filter="true"
        >
        </p-dropdown>
      </div>
      <div *ngSwitchCase="'LINE'" style="padding: 2em">
        <div *ngFor="let column of mColumns" style="width: 33%; float: left; margin-top: 5px">
          <table style="padding: 2em">
            <tr>
              <td style="width: 70%">
                <b>{{ column.label | translate }}</b>
              </td>
              <td style="width: 80%">
                <p-dropdown [autoZIndex]='false'
            panelStyleClass='modal-dropdown'
                  [appendTo]="'body'"
                  [options]="optionsC"
                  [style]="{ width: '100%' }"
                  [(ngModel)]="selectedPieValue"
                >
                </p-dropdown>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div *ngSwitchCase="'BAR'"></div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="finalStep === currentStep">
    <ng-container [ngSwitch]="activeItem.key">
      <appc-report-pie-chart *ngSwitchCase="'PIE'" [columns]="columnHeaders"></appc-report-pie-chart>
      <appc-report-line-chart *ngSwitchCase="'LINE'" [columns]="columnHeaders"></appc-report-line-chart>
      <appc-report-bar-chart *ngSwitchCase="'BAR'" [columns]="columnHeaders"></appc-report-bar-chart>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #footer>
  <div class="footer">
    <button *ngIf="currentStep > 1" class="btn" (click)="currentStep = currentStep - 1">
      <i class="fa fa-long-arrow-alt-left"></i> &nbsp; Back
    </button>
    <button *ngIf="finalStep === currentStep + 1" (click)="currentStep = currentStep + 1" class="btn">Create</button>
    <button (click)="closeDialog()" class="btn">Cancel</button>
  </div>
</ng-template>
