import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { WidgetService } from '../widget.service';
import HighCharts from '@clearviewshared/highchart/highchart';
import { FiltersService } from '@app/shared/services/filters.service';
import { CacheService } from '@app/shared/services/cache.service';
import { TranslateService } from '@ngx-translate/core';
//import { generateFrftModelQ6no } from '@clearviewshared/highchart/helperfunctions';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { I18nService } from '@app/core';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Identifiers } from '@app/shared/services/app.config.type';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { GlobalFilterApiService } from '@app/shared/module/global-filters/global-filters.api.service';
import { GlobalFilterHelperService } from '@app/shared/module/global-filters/global-filters-helper.service';
const FrftModelQ6noData = require('../frft-model-q6no/SampleData.json');

import {
  DynamicFilters,
  FilterTypes,
  GlobalFilterRouter,
} from '@app/shared/module/global-filters/global-filter-router';
import moment from 'moment-timezone';
import {
  FrftModelQ6noStatus,
  IFrftModelQ6no,
  IFrftModelQ6noRatingData,
  IFrftModelQ6noSeries,
} from '../widget-interface';
import { isNgTemplate } from '@angular/compiler';
import { genMonthArrFromCurrMonth } from '@app/core/helpers';

@Component({
  selector: 'frft-model-q6no',
  templateUrl: './frft-model-q6no.component.html',
  styleUrls: ['./frft-model-q6no.component.scss'],
})
export class FrftModelQ6noComponent extends WidgetBase implements OnInit, OnDestroy {
  @ViewChild('containerFrftModelQ6no', { read: ElementRef, static: true }) containerFrftModelQ6no: ElementRef;

  @Input() showToggle = false;
  @Input() module = 'frft';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  selecteditem: boolean = false;
  type: string = 'CM';
  routerPrefix = '';
  canRender = false;
  isChecked = false;
  isToggleDisabled = false;
  sentiment: any;
  data: IFrftModelQ6no[];
  isAspectCategorySelected = true;
  permissions: NgxPermissionsObject;
  chart: any;
  api: Subscription;
  months: any[] = [];
  response: any[] = [];
  optiondata: IFrftModelQ6noSeries[];
  response_options: any[] = [];
  meta: any[] = [];
  listMonths: string[] = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
  private subscriptions: Subscription[] = [];
  showsWidgets: boolean = false;

  constructor(
    private router: Router,
    public widgetService: WidgetService,
    private cacheService: CacheService,
    private filterService: FiltersService,
    private gbfRouter: GlobalFilterRouter,
    private translateService: TranslateService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService,
    public ngxPermission: NgxPermissionsService,
    public gbfService: GlobalFilterService,
    public gbfapiservice: GlobalFilterApiService,
    public gbfHelperService: GlobalFilterHelperService
  ) {
    super(gbfService, translateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.canRender = true;
    this.setAspectCatPreset();
    this.loadWidget();
    this.permissions = this.ngxPermission.getPermissions();

    // this.subscriptions.push(
    //   this.cacheService.for(this.widgetService.FRFTUrl).subscribe((res: any) => {
    //     if (res) {
    // this.data = this.getFrftModelQ6noData().rating;

    // this.initData(false);
    //    this.canRender = true;

    this.subscriptions.push(
      this.widgetService.update.subscribe(() => {
        this.setAspectCatPreset();
        this.loadWidget();
      })
    );

    this.gbfService.gbfTabChange.subscribe(() => {
      this.isToggleDisabled = false;
    });
  }

  /* loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;

    if (this.api) {
      this.api.unsubscribe();
    }

    // if (this.module === 'voc') {
    //   this.api = this.widgetService.getFrftModelQ6noData(this.module, this.isChecked).subscribe((res: any) => {
    //     if (res) {
    //       }
    //       this.data = res;
    //       let isUnassigned = this.permissions[Identifiers.FRFT] ? true : false;
    //       this.initData(isUnassigned);

    this.canRender = true;
    this.widgetService.isAllLoaded = true;
    //     }
    //   });
  }*/
  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    // this.dispGraph = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getFrftQ6no(this.type).subscribe({
      next: (res: [IFrftModelQ6no]) => {
        if (res) {
          let response: any = res;
          this.getmappedvalues(res);
          // const resp: any = res.res;
          // this.data = res;
          // this.optiondata= this.generateData(res);
          this.initData(false);
          this.canRender = true;
          this.widgetService.isAllLoaded = true;
        }
      },
      complete: () => {
        this.canRender = true;
        // this.dispGraph = true;
        this.widgetService.isAllLoaded = true;
      },
      error: () => {
        this.canRender = true;
        // this.dispGraph = false;
        this.widgetService.isAllLoaded = true;
      },
    });
  }

  toggleChange() {
    if (this.selecteditem == false) {
      this.type = 'CM';
      this.loadWidget();
    } else {
      this.type = 'OM';
      this.loadWidget();
    }
  }
  getmappedvalues(response: any) {
    this.response = response.info;
    // this.meta = response.meta;
    //this.optiondata = response.response_question.options;
  }
  setAspectCatPreset() {
    const aspectCategory = this.filterService.getAspectCategory();
    if (aspectCategory.label === 'All Categories') {
      this.isAspectCategorySelected = false;
      this.isChecked = false;
    } else {
      this.isAspectCategorySelected = true;
      this.isChecked = false;
    }
  }

  disableCtrl() {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.api) {
      this.api.unsubscribe();
    }
  }

  GetFrftModelQ6noTranslation(key: string) {
    return this.translationHelper.GetFrftModelQ6noTranslation(key);
  }
  private generateData(data: any[]): IFrftModelQ6noSeries[] {
    const result: IFrftModelQ6noSeries[] = [];
    const responseQuestion = data['response_question'];
    if (data && data['response_question']) {
      for (var key in data['response_question']) {
        var obj = {
          year: responseQuestion[key]['year'],
          month: responseQuestion[key]['month'],
        };
        const options = responseQuestion[key]['options'];
        const rating = options.map((option: any) => ({
          Name: this.GetFrftModelQ6noTranslation(option['option']),
          Percentage: parseFloat(option['count']),
        }));
        //rating.push(obj);
        rating['RatingData'] = obj;
        result.push(rating);
      }
    }
    return result;
  }
  private initData(includeUnassigend: boolean) {
    const title = 'Frft Root Causes';
    let monthsList: string[] = [];
    let filter = this.gbfService.getFilterObject();
    var endDate = new Date(filter['EndDate']);
    let monthWithKeys = genMonthArrFromCurrMonth(endDate.getMonth() + 1, endDate.getFullYear());
    //monthsList = monthWithKeys.map((m: any) => this.translateService.instant(m.name + ' ' + m.year));
    monthsList = monthWithKeys.map((m: any) => this.translateService.instant(m.name) + ' ' + m.year);

    if (!this.response || this.response.length == 0) {
      this.showsWidgets = true;
    } else {
      this.showsWidgets = false;
    }
    // this.data.forEach((x: any, index: number) => {

    //   var edata: any = [];

    // monthWithKeys.forEach((m: any, i: number) => {
    //   var score = x.data.filter((f: any) => f.month == i);
    //   if (score && score.length > 0)
    //     edata.push({ month: m, score: score[0] })
    //   else
    //     edata.push({ month: m, score: 0 })
    // })

    let FrftModelQ6noSeries: IFrftModelQ6noSeries[] = [];
    this.response.forEach((x: any, index: number) => {
      var edata: any = [];
      var o = new IFrftModelQ6noSeries();
      o.name = x.option;
      o.nameText = x.option;

      monthWithKeys.forEach((m: any, i: number) => {
        // let monthData: any = new Array(12).fill(null);
        var score = x.data.filter((f: any) => f.month == m.key && f.year == m.year);
        if (score && score.length > 0) edata.push(score[0].count);
        else edata.push([0]);
      });
      o.data = edata;
      o.count = edata;
      FrftModelQ6noSeries.push(o);
    });
    const data = {
      xAxis: monthsList,
      yAxisTitle: title,
      yFormatter: '%',
      values: FrftModelQ6noSeries,
    };
    const point = {
      events: {
        click: (e: any, b: any, c: any) => {
          const url = this.module === 'frft' ? '/insights/frft/rootcause' : '/insights/frft/rootcause';
          const viewableParams: Array<DynamicFilters> = [];
          this.gbfRouter.navigate(
            [url],
            {
              queryParams: {
                status: FrftModelQ6noStatus[e.point.series.userOptions.nameText],
                date: this.getDate(e.point.category),
              },
            },
            { key: url, filters: viewableParams },
            () => {}
          );
        },
      },
    };

    const chartOptions = this.generateFrftModelQ6no(
      data,
      point,
      this.translateService.instant('Frft Model Q6 No'),
      this.i18nService
    );
    this.chart = HighCharts['Reflecx'].createOrUpdate(
      this.chart,
      this.containerFrftModelQ6no.nativeElement,
      chartOptions
    );
  }

  private getDate(param: any) {
    const val = param.split('-');
    const result: any = this.months.find((x: any) => x.value == val[0]);
    return result.key + '-' + val[1];
  }

  private generateFrftModelQ6no(data: any, point: any, yaxisTitle: any, i18nService: I18nService = null): any {
    return {
      colors: ['#3bb001', '#fd7e14', '#dc3545', '#839588', '#141680', '#f2f757', '#41fade'],
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: data.xAxis,
      },
      yAxis: {
        min: 0,
        max: 100,
        tickInterval: 20,
        // title: {
        //   text: yaxisTitle,
        // },
        labels: {
          formatter: function () {
            return this.value + data.yFormatter;
          },
        },
        // labels: {
        //   rotation: -45,
        //   format: '{value} %',
        // },
      },
      // tooltip: {
      //   formatter: function () {
      //     return `
      //   <span style="color:${this.series.color}">
      //    ${this.series.name}
      //    </span>
      //    :
      //    <b>${i18nService.transformNumber(this.series.userOptions.count[this.point.x])} (${i18nService.transformNumber(
      //       this.point.y.toFixed(1)
      //     )}%)  </b> <br/>`; // ({point.percentage:.1f})
      //   },
      // },
      plotOptions: {
        column: {
          stacking: 'normal',
          pointWidth: 50, // Adjust the width of the columns as needed
        },
        series: {
          point: point,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y > 0 ? `${i18nService.transformNumber(this.y.toFixed(1))}` + `%` : '';
            },
          },
        },
      },

      series: data.values,
    };
  }

  getFrftModelQ6noData() {
    return FrftModelQ6noData;
  }
}
