<app-widget-dynamic [rootClass]="rootClass" [styleClass]="styleClass" [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle">
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <div widgetArea class="survey-completion" [attr.data-can-render]="canRender">
    <div *ngIf="data && data.EventCount" class="completion__item">
      <div class="knob">
        <p-knob 
          valueTemplate="{value}%"
          [size]="60"
          valueColor="#89bf5d"
          [readonly]="true" [(ngModel)]="data.EventCount.TotalPercentage"></p-knob>
      </div>
      <div class="progressbar">
        <div class="info">
          <!-- ({{(data?.EventCount?.TotalEvents > 0)?100:0}} %) -->
          <p class="count">{{data?.EventCount?.TotalEvents}}</p>
          <h2 class="title">{{'Total Events' | translate}}</h2>
        </div>
        <p-progressBar class="progress--lime-green" value="{{(data?.EventCount?.TotalEvents > 0)?100:0}}"
          [showValue]="false" title="{{data?.EventCount?.TotalEvents}}">
        </p-progressBar>
        <span class="completion__percentage"></span>
      </div>
    </div>
    <div *ngIf="data && data.EventCount" class="completion__item">
      <div class="knob">
        <p-knob 
          valueTemplate="{value}%"
          [size]="60" 
          valueColor="#fd7e14"
          [readonly]="true" [(ngModel)]="data.EventCount.Percentage"></p-knob>
      </div>
      <div class="progressbar">
        <div class="info">
          <p class="count">{{data?.EventCount?.ValidEmails}}</p>
          <h2 class="title">{{'# of valid Emails' | translate}}</h2>
          <!-- <p>{{data?.EventCount?.ValidEmails}}</p> -->
           <!-- ({{data?.EventCount?.Percentage}} %) -->
        </div>
        <p-progressBar class="progress--orange" value="{{data?.EventCount?.Percentage}}" [showValue]="false"
          title="{{data?.EventCount?.ValidEmails}}"></p-progressBar>
        <span class="completion__percentage"></span>
      </div>
    </div>

    <ng-container *ngIf="data && data.Statuses">
      <div class="completion__item" *ngFor="let status of data.Statuses">
        <div class="knob">
          <p-knob 
            valueTemplate="{value}%"  
            [size]="60"
            textColor="#000"
            [valueColor]="status.Color.code"
            [readonly]="true" [(ngModel)]="status.Percentage"></p-knob>
        </div>
        <div class="progressbar">
          <div class="info">
            <!--  ({{status.Percentage}} %) -->
            <p class="count">{{status.Count}}</p>
            <h2 class="title">{{status.Header | translate}}</h2>
            <!-- <p>{{status.Count}}</p> -->
          </div>
          <p-progressBar [class]="'progress--' + status.Color.color" value="{{status.Percentage}}" [showValue]="false"
            title="{{status.Count}}"></p-progressBar>
          <span class="completion__percentage"></span>
        </div>
      </div>
    </ng-container>
  </div>
</app-widget-dynamic>