import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CustomizeDashboardService {
  widgets$: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  changeWidgets(value: any) {
    this.widgets$.emit(value);
  }

  getWidgetsObservable() {
    return this.widgets$.asObservable();
  }
}
