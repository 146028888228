import { Component, OnInit, Input } from '@angular/core';
import { Widget } from '../widgetBase';
declare const matchHeight: any;
@Component({
  selector: 'app-widget-dynamic',
  templateUrl: './widget-dynamic.component.html',
  styleUrls: ['./widget-dynamic.component.scss'],
})
export class WidgetDynamicComponent extends Widget implements OnInit {
  @Input() rootClass = '';
  @Input() dataMatchHeightClass = '';
  constructor() {
    super();
  }
  ngOnInit() {}
}
