import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { WidgetService } from '../widget.service';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '@app/shared/services/cache.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { FiltersService } from '@app/shared/services/filters.service';
import { Router, NavigationExtras } from '@angular/router';
import WidgetBase from '../widegt.base';
import { ITopBottomPerformerResponse, ITopBottomPerformerResult } from './types/ITopBottomPerformerResponse';
import { AuthenticationService, I18nService } from '@app/core';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
@Component({
  selector: 'top-bottom-performer',
  templateUrl: './top-bottom-performers.html',
  styleUrls: ['./top-bottom-performers.scss'],
})
export class TopBottomPerformerComponent extends WidgetBase implements OnInit, OnDestroy {
  params: any;
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @Input() allowDropDownShow = true;
  filters: any;
  kpiNames: any[] = [];
  name: string;
  permissions: NgxPermissionsObject;
  hasOrgSummaryPermission: boolean = false;

  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  topPerformer: Array<ITopBottomPerformerResult> = new Array<ITopBottomPerformerResult>();
  bottomPerformer: Array<ITopBottomPerformerResult> = new Array<ITopBottomPerformerResult>();
  canRender = false;
  api: Subscription;
  topPerfCol = [
    { field: 'kpi_name', header: this.translateService.instant('top_5_performers') },
    { field: 'count', header: this.translateService.instant('by_survey_count') },
    { field: 'score', header: this.translateService.instant('top_bottom_performer.kpi_score') },
  ];
  bottomPerfCol = [
    { field: 'kpi_name', header: this.translateService.instant('bottom_5_performers'), show: true },
    { field: 'count', header: this.translateService.instant('by_survey_count'), show: false },
    { field: 'score', header: this.translateService.instant('top_bottom_performer.kpi_score'), show: false },
  ];
  private subscriptions: Subscription[] = [];
  user: any;
  constructor(
    public toastService: ToastService,
    public authService: AuthenticationService,
    public widgetService: WidgetService,
    public router: Router,
    private filterService: FiltersService,
    public gbfService: GlobalFilterService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    private appConfig: AppConfigurationService,
    public ngxPermission: NgxPermissionsService,
    public i18nService: I18nService
  ) {
    super(gbfService, translateService);
    this.user = this.gbfService.authService.getUser();
  }

  changeValue(value: any) {
    const language = this.user.DefaultLanguage.split('-');
    const float: any = parseFloat(Math.round(value * 100) / 100 + '').toFixed(1);
    const score = this.i18nService.transformNumber(float);
    return language[0] == 'en' ? score.replace(new RegExp(`,`, 'g'), '') : score;
  }

  ngOnInit() {
    super.ngOnInit();
    this.fillKpiDropDown();
    this.permissions = this.ngxPermission.getPermissions();
    this.hasOrgSummaryPermission = this.permissions[Permission.REPORT_ORGANIZATION_SUMMARY] ? true : false;

    this.filters = this.filterService.getFilterObject();
    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.topBottomPerformerUrl).subscribe((res: ITopBottomPerformerResponse) => {
        if (res) {
          this.topPerformer = res.Top;
          this.bottomPerformer = res.Bottom;
        }
      })
    );
    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
      this.subscriptions.push(
        this.gbfService.gbfOnApplyFilter.subscribe(() => {
          this.loadWidget();
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }

  orgSummaryRoute(parent: string, code: string, filters: any) {
    this.router.navigate(['/insights/reports/orgsummary'], {
      queryParams: { code: code, parent: parent, previewId: 0 },
    });
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getTopBottomPerformer({ apiConfig: this.apiConfig }, this.name).subscribe(
      (res: any) => {
        this.topPerformer = res.response[0][0].highest;
        this.bottomPerformer = res.response[0][0].lowest;
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      },
      (error) => {
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      }
    );
  }
  onKPIChange(event: any) {
    this.loadWidget();
  }
  fillKpiDropDown() {
    var kpiConfig = this.appConfig.getByIdentifier('top_Bottom_Performer.process_view');

    if (kpiConfig && kpiConfig['Fields']) {
      var obj = this.filterService.getFilterObject();
      if (obj) {
        let kpisConf: [] = kpiConfig.Fields;
        var kpiList = kpisConf?.filter((f) => f['survey_id'] == obj.SurveyVersion)[0];
        //var kpiList = kpisConf.filter((f) => f['survey_id'] == "635a4e801969812dadb191ce")[0];
        if (kpiList) {
          let list: [] = kpiList['kpis'];
          this.name = kpiList['kpis'][0];
          for (var ii of list) {
            this.kpiNames.push({ label: this.translateService.instant(ii), value: ii });
          }
        }
      }
    }
  }
}
