<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <div widgetArea class="c-j-charts">
    <div class="cej_chart_container">
      <div class="cej_header">
        <div class="cej_dd_container">
          <label>{{ 'Loyality' | translate }}:</label>
          <div class="select-dropdown">
            <p-dropdown
              [autoZIndex]="false"
              [options]="repurchase"
              (onChange)="leftLoyalityChange($event)"
              [(ngModel)]="leftLoyalitySelectedValue"
              [disabled]="disableLoyality"
              [style]="{ width: '150px' }"
            >
            </p-dropdown>
          </div>
        </div>
        <div class="cej_dd_container">
          <label>NPS: </label>
          <div class="select-dropdown">
            <p-dropdown
              [autoZIndex]="false"
              [options]="nps"
              (onChange)="leftNpsChange($event)"
              [(ngModel)]="leftNpsSelectedValue"
              [disabled]="disbleNps"
              [style]="{ width: '150px' }"
            >
            </p-dropdown>
          </div>
        </div>
      </div>
      <div *ngIf="left" class="cej_chart five">
        <div
          *ngFor="let left of left.Kpis | slice:0:5;let i=index"
          class="item item{{i+1}}"
          [ngClass]="{
            negative: left.Color === 'negative',
            normal: left.Color === 'positive',
            neutral: left.Color === 'neutral'
          }"
        >
          <!-- href="javascript:void(0);" -->
          <a (click)="show(left.Name, 'left')"
            >{{ GetSurveyKpiNameTranslation(left.Name) }} <span>{{ toFixed(left.Weight) | number: '1.1-1' }}</span></a
          >
        </div>

        <div class="cej_chart-inner">
          <div class="arrows neutral"><i class="fas fa-caret-right"></i><i class="fas fa-caret-right"></i></div>
          <!-- <div class="step-dot step1 positive"></div>
          <div class="step-dot step2 neutral"></div>
          <div class="step-dot step3 neutral"></div>
          <div class="step-dot step4 negative"></div>
          <div class="step-dot step5 negative"></div> -->
          <div
          *ngFor="let left of left.Kpis | slice:0:5;let i=index"
          class="step-dot step{{i+1}}"
          [ngClass]="{
            negative: left.Color === 'negative',
            normal: left.Color === 'positive',
            neutral: left.Color === 'neutral'
          }"
        >
      </div>
          <div class="exp-container">
            <div class="exp-box">
              <div class="exp__inner">
                <h3>{{ 'Customer Health Score' | translate }}</h3>
                <small>{{ 'TME' | translate }}</small>
                <div class="exp-percentage">
                  <p-knob 
                  [strokeWidth]="3"
                  [readonly]="true"
                  valueTemplate="{value}"
                  valueColor="#89bf5d"
                    [(ngModel)]="valueright"></p-knob>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="start-btn">{{ 'Start' | translate }}</div>
      </div>
    </div>
    <div class="cej_chart_container">
      <div class="cej_header">
        <div class="cej_dd_container">
          <label translate>{{ 'Loyality' | translate }}:</label>
          <div class="select-dropdown">
            <p-dropdown
              [autoZIndex]="false"
              [options]="repurchase"
              (onChange)="rightLoyalityChange($event)"
              [disabled]="disableLoyality"
              [(ngModel)]="rightLoyalitySelectedValue"
              [style]="{ width: '150px' }"
            >
            </p-dropdown>
          </div>
        </div>
        <div class="cej_dd_container">
          <label translate>NPS: </label>
          <div class="select-dropdown">
            <p-dropdown
              [autoZIndex]="false"
              [options]="nps"
              (onChange)="rightNpsChange($event)"
              [style]="{ width: '150px' }"
              [disabled]="disbleNps"
              [(ngModel)]="rightNpsSelectedValue"
            >
            </p-dropdown>
          </div>
        </div>
      </div>
      <div *ngIf="right" class="cej_chart five">
        <div
          *ngFor="let right of right.Kpis | slice:0:5;let i=index;"
          class="item item{{i+1}}"
          [ngClass]="{
            negative: right.Color === 'negative', 
            normal: right.Color === 'positive',
            neutral: right.Color === 'neutral'
          }"
        >
          <a (click)="show(right.Name, 'right')"
            >{{ GetSurveyKpiNameTranslation(right.Name) }} <span>{{ toFixed(right.Weight) | number: '1.1-1' }}</span></a
          >
        </div>
        <div class="cej_chart-inner">
          
          <div class="arrows"><i class="fas fa-caret-right"></i><i class="fas fa-caret-right"></i></div>
          <!-- <div class="step-dot step1 positive"></div>
          <div class="step-dot step2 neutral"></div>
          <div class="step-dot step3 neutral"></div>
          <div class="step-dot step4 negative"></div>
          <div class="step-dot step5 negative"></div> -->
          <div
          *ngFor="let left of left.Kpis | slice:0:5;let i=index"
          class="step-dot step{{i+1}}"
          [ngClass]="{
            negative: left.Color === 'negative',
            normal: left.Color === 'positive',
            neutral: left.Color === 'neutral'
          }"
        >
      </div>
          <div class="exp-container">
            <div class="exp-box">
              <div class="exp__inner">
                <h3>{{ 'Customer Health Score' | translate }}</h3>
                <small>{{ 'TGB' | translate }}</small>
                <div class="exp-percentage">
                  <div class="exp-percentage">
                    <p-knob 
                    [strokeWidth]="3"
                    [readonly]="true"
                    valueTemplate="{value}"
                    valueColor="#89bf5d"
                      [(ngModel)]="valueright"></p-knob>
                  </div>                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="start-btn">{{ 'Start' | translate }}</div>
      </div>
    </div>
  </div>
</app-widget-dynamic>
