import { Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { GlobalFilterConfigService } from '@app/shared/module/global-filters/global-filters-config.service';
import { GlobalFilterHelperService } from '@app/shared/module/global-filters/global-filters-helper.service';
import { GlobalFilterApiService } from '@app/shared/module/global-filters/global-filters.api.service';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'appc-performance-trend-org',
  templateUrl: './performance-trend-org.component.html',
})
export class PerformanceTrendOrgComponent implements OnInit, OnDestroy {
  configs: any = [];
  activeState = {};
  tabs: any[] = [];
  activeFilter: any;
  active = false;
  @Input() parentComponent: string = '';
  @Output() offmenu: EventEmitter<any> = new EventEmitter();
  constructor(
    private gbfService: GlobalFilterService,
    private gbfHelper: GlobalFilterHelperService,
    private gbfConfig: GlobalFilterConfigService,
    private gbfApi: GlobalFilterApiService,
    private appConfig: AppConfigurationService
  ) {}

  ngOnInit(): void {
    this.makeOrg();
    this.gbfApi.apiChange.subscribe((x: any) => {
      if (x.name.indexOf('org') > -1) {
        this.makeOrg();
      }
    });
    this.active = true;
  }
  close() {
    this.offmenu.emit();
  }
  makeOrg() {
    const PROPERTY = this.gbfService.currentAllowedFilters.includes('multi_org') ? 'multi_org' : 'org';
    const config = this.appConfig.getClientConfig('allow_performance_trend_max_node_config');
    const isMainNodeLevelCondition = config['Fields'] == undefined ? true : config['Fields']['property'];
    const configSetting = {
      listDoneButton: false,
      callApiOn: 'none',
      allow_parent: true,
    };
    if (isMainNodeLevelCondition) {
      configSetting['maxLevel'] = this.gbfService.authorizationData.MainNodeLevel;
    }
    this.configs = cloneDeep(this.gbfConfig.filterConfig.config)
      .filter((x: any) => x.name === PROPERTY)
      .map((x: any) => {
        x.localStorageKey = 'performance_trend_' + this.parentComponent + '_' + PROPERTY;
        x.name = 'performance_trend_' + this.parentComponent + '_' + PROPERTY;
        x.settings = { ...x.settings, ...configSetting };
        return x;
      });
  }

  ngOnDestroy(): void {}
}
