<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <div class="widget__content" widgetArea width="100%" [attr.data-can-render]="canRender">
    <div class="flex-container" [ngStyle]="{ 'flex-direction': isMobile ? 'column' : 'row' }">
      <div class="flex-item" style="flex: 0.5" *ngFor="let dataModelChunk of dataModel | chunk: 5; let i = index;">
        <div class="keyperformance-container" *ngFor="let kpi of dataModelChunk; let i = index;">
          <div class="keyperformance__header">
            <div class="keyperformance__solidchart">
              <highcharts-chart [constructorType]="chartConstructor" [Highcharts]="Highcharts" [options]="kpi.ScoreChart">
              </highcharts-chart>
            </div>
            <span class="keyperformance__title"> {{GetSurveyKpiNameTranslation(kpi.KpiName)}} </span>
            <div class="keyperformance__score">{{kpi.TotalScore | number:'0.1-1'}}</div>
          </div>
          <div class="keyperformance__linechart">
            <highcharts-chart
              style="margin: 0; padding: 0"
              [constructorType]="chartConstructor"
              [Highcharts]="Highcharts"
              [options]="kpi.MonthlyChart"
            >
            </highcharts-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="nodata-wrapper" *ngIf="canRender && dataModel && dataModel.length == 0">
      <div class="nodata">
        <i class="nodata__icon fal fa-info-circle"></i>
        <p class="nodata__msg">{{ 'No Result Found' | translate }}</p>
      </div>
    </div>
  </div>
</app-widget-dynamic>
