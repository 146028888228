<ng-template #header>
  <h2>{{ 'Remove' | translate }}</h2>
</ng-template>
<ng-template #content>
  {{ message }}
</ng-template>
<ng-template #footer>
  <!-- <button translate class="btn" (click)="saveEmail()" [disabled]="form.invalid">Save</button> -->
  <button translate class="btn" (click)="Yes()">{{ 'Yes' | translate }}</button>
  <button translate class="btn btn--cancel" (click)="No()">{{ 'No' | translate }}</button>
</ng-template>
