<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
  [widgetTitleToolTip]="widgetTitleToolTip"
>
  <ng-container widgetHeader>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
    <div class="widget-node">
      <span pInputText pTooltip="{{'Widget displays current 5 Star Rating Quarter to Date only' | translate}}" tooltipPosition="top"
        style="border: none">
        <i class="fal fa-question-circle icon-question"></i>
        {{'5 Star Rating Quarterly' | translate}}
      </span>
      <div class="widget-node"  *ngIf="hasClickThroughPermission">
        <a  class="custom-handler-more" (click)="redirectToIssueReport()"><i class="fal fa-long-arrow-right"></i></a>
      </div>
    </div>

  </ng-container>
  <ng-container widgetArea>
    <div class="widget__content" [attr.data-can-render]="canRender">
      <div class="fivestar-widget">
        <div class="col__left">
          <h2 [ngClass]="{ 'ta-negative': !hasPostive_res, 'ta-positive': hasPostive_res }">{{res_ques_1}}</h2>

          <h3 class="grey"><small>vs.</small> {{res_ques_tar}}</h3>

          <div class="widget__graph">
            <div #containerDVBCount></div>
          </div>

          <h6 class="ta-negative">{{this.date}}</h6>
        </div>

        <div class="col__right">
          <h2 [ngClass]="{ 'ta-negative': !hasPositive_reach, 'ta-positive': hasPositive_reach }">
            {{this.res_ques_rea_1}} %
          </h2>

          <h3 class="grey">
            <small>vs.</small>
            {{res_ques_rea_tar}} %
          </h3>
          <div class="widget__graph">
            <div #containerDVBCount2></div>
          </div>
          <h6 class="ta-negative">{{this.date}}</h6>
        </div>
      </div>
        <h4 class="numberofsurveys">{{'QP Survey Count' | translate}}: <span (click)="route()" class="surveycount">{{survey_count}}</span></h4>
    </div>
  </ng-container>
</app-widget-dynamic>
