<app-widget-dynamic [rootClass]="rootClass" [styleClass]="styleClass" [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle">
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough && clickThroughReport">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>

  <!-- obw STATUS HTML -->
  <ng-container widgetArea>
    <div class="widget__content">
      <div class="obw-bottom-area">
          <div>
            <div style="height: 250px;" #containerTotalAlert></div>
          </div>
        </div>
      <div class="obw-bottom-area">
        <div class="widget__graph">
          <div width="100%" style="width: 100%">
            <div #containerTotalAlert2>
            </div>
           </div>
        </div>
        <div class="widget__counts">
          <div class="counts__table">
            <table>
              <tbody>
                <tr *ngFor="let d of data index as i;">
                  <td>
                    <div class="count__symbol series--one clr-{{ colors[i] }}-background"></div>
                  </td>
                  <td>
                    <div *ngIf="d.Count > 0 && clickThroughReport" class="count__count">
                      <a [routerLink]="'/' + reportingRoutePrefix + '/dashboard/surveyresend'"
                       [queryParams]="{source: paramsAlertStatus(d.Key)}"
                      >{{ d.Count | number }}</a
                    >
                    </div>
                    <div *ngIf="d.Count > 0 && !clickThroughReport" class="count__count">
                      {{ d.Count | number }}
                    </div>
                    <div *ngIf="d.Count < 1" class="count__count">{{ d.Count | number }}</div>
                  </td>
                  <td>
                    <div class="count__percentage">{{ changeValue(d.Percent) }} %</div>

                  </td>
                  <td>
                    <div class="series__name">{{(d.Key) | translate }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- <ng-container widgetArea>
    <div class="valign__content">
      <div class="valign__graph">
        <div class="widget__content" [attr.data-can-render]="canRender">
          <div class="widget__graph" [ngStyle]="{ 'width': layout === 'small' ? '100%' : '50%' }">
            <div width="100%" style="width: 100%">
              <div #containerTotalAlert></div>
            </div>
          </div>
          <div class="widget__counts" [ngStyle]="{ 'width': layout === 'small' ? '100%' : '50%' }">
            <div class="counts__table">
              <table>
                <tbody>
                  <tr *ngFor="let d of data index as i;">
                    <td>
                      <div class="count__symbol series--one clr-{{ colors[i] }}-background"></div>
                    </td>
                    <td>
                      <div *ngIf="d.Count > 0" class="count__count">
                        <a (click)="routeToAlertlist(d.Title)">{{ d.Count | number }}</a>
                      </div>
                      <div *ngIf="d.Count < 1" class="count__count"><a>{{ d.Count | number }}</a></div>
                    </td>
                    <td>
                      <div class="count__percentage">{{ d.Percent | number : '1.1-1' }} %</div>
                    </td>
                    <td>
                      <div class="series__name">{{GetHotalertStatusTranslation(d.Title) }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container> -->
</app-widget-dynamic>
