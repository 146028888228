import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, interval, fromEvent } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { WidgetService } from '../widget.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { generateKeyPerformanceIndicators } from '@clearviewshared/highchart/helperfunctions';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import { debounceTime } from 'rxjs/operators';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { I18nService } from '@app/core';
import { IKeyPerformanceIndicators } from './types/IKeyPerformanceIndicators';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
// import { Identifiers } from '@angular/compiler';

@Component({
  selector: 'key-performance-indicators-widget',
  templateUrl: './key-performance-indicators.html',
  styleUrls: ['./key-performance-indicators.component.scss'],
})
export class KeyPerformanceIndicators extends WidgetBase implements OnInit, OnDestroy {
  Highcharts = Highcharts;
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  chartConstructor = 'chart';
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  canRender = false;
  data: Array<IKeyPerformanceIndicators> = [];
  dataModel: Array<IKeyPerformanceIndicators> = [];
  api: Subscription;
  isMobile = window.innerWidth < 1024;
  private subscriptions: Subscription[] = [];

  constructor(
    public toastService: ToastService,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public gbfService: GlobalFilterService,
    private appConfig: AppConfigurationService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService
  ) {
    super(gbfService, translateService);
    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }
      fromEvent(window, 'resize')
        .pipe(debounceTime(1500))
        .subscribe((event) => {
          this.initData();
        });
      this.secondsCounter = interval(widgetThresholdVal);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.keyPerformanceIndicatorsUrl).subscribe((res: any) => {
        if (res) {
          this.data = res;
          this.initData();
          this.canRender = true;
        }
      })
    );

    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
      this.subscriptions.push(
        this.gbfService.gbfOnApplyFilter.subscribe(() => {
          this.loadWidget();
        })
      );
    }
    // this.secondsCounterSub = this.secondsCounter.subscribe((n: any) => {
    //   // this.loadWidget();
    // });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getKeyPerformanceIndicators({ apiConfig: this.apiConfig }).subscribe(
      (res: Array<IKeyPerformanceIndicators>) => {
        if (res) {
          this.data = res;
          if (Array.isArray(res) && res.length > 5) {
            this.layout = 'xl';
            this.rootClass = 'card-xl';
          }

          this.canRender = true;
          this.widgetService.isAllLoaded = true;
          setTimeout(() => {
            this.initData();
          }, 1000);
        }
      },
      (error: any) => {
        // this.toastService.error('Failed.');
        this.canRender = true;
        this.widgetService.isAllLoaded = true;
      }
    );
  }

  initData() {
    this.dataModel = [];
    const monthsList: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (this.data) {
      let index = 0;
      this.data.forEach((kpi: IKeyPerformanceIndicators, iindex: number) => {
        index = iindex > 8 ? iindex - 8 : iindex;
        const selectedList: string[] = [];
        kpi.Months.forEach((month: any, ind: number) => {
          const monthTranslation = this.translateService.instant(`${monthsList[month.Month - 1]}`);
          selectedList.push(monthTranslation + '-' + month.Year.toString().slice(-2));
        });
        const scoreData = kpi.Months.filter((x: any) => x.Score > 0).map((km: any) => {
          return parseFloat(km.Score.toFixed(1));
        });
        const color = Highcharts.getOptions().colors[index];
        console.log(kpi);
        console.log(scoreData);
        console.log(selectedList);
        const data = generateKeyPerformanceIndicators(kpi, scoreData, selectedList, color, this.i18nService);
        kpi.MonthlyChart = data.MonthlyChart;
        kpi.ScoreChart = data.ScoreChart;
      });
      this.dataModel = this.data;
    }
  }

  GetSurveyKpiNameTranslation(key: string) {
    return this.translationHelper.GetSurveyKpiNameTranslation(key);
  }
}
