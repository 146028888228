import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
})
export class ResultComponent implements OnInit {
  @Input() NLPResult: any;
  @Input() ResultProcessed: boolean = false;
  @Input() CommentText: any;
  @Input() index: any;
  @Output() DeleteIndex = new EventEmitter();

  @Input() NLPResultArray: any;

  @Input() ClassificationScheme = 0;
  constructor() {}
  ngOnInit() {}
  RemoveComment() {
    this.DeleteIndex.emit(this.index);
  }
}
