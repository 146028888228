import { Input, SimpleChanges, OnInit, OnChanges, Directive, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export default class WidgetBase implements OnInit, OnChanges {
  routerPrefix = '';
  readonly WIDGET_HOSTER = 'widgetHoster';
  @Input() apiConfig: any = {};
  @Input() extra: any = {};
  @Input() from = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() styleClass = '';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @Input() showGraph = false;
  @Input() events: any = [];
  @Input() widgetTitleToolTip: string = '';
  eventName = '';
  constructor(public gbfService: GlobalFilterService, public translationService: TranslateService) {}
  ngOnInit() {
    if (this.apiConfig?.Event && this.events.length > 1) {
      this.eventName = this.apiConfig?.Event;
      this.widgetTitle += ` (${this.translationService.instant(this.eventName)})`;
    }
    this.routerPrefix = '/' + environment.reportingRoutePrefix + this.clickThrough;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clickThrough) {
      this.routerPrefix = '/' + environment.reportingRoutePrefix + changes.clickThrough.currentValue;
    }
  }

  fireClickThrough(e: any) {
    e.preventDefault();
    if (this.apiConfig?.Event) {
      this.gbfService.setTab(this.apiConfig.Event, false);
    }
    this.gbfService.router.navigate([this.routerPrefix]);
    // if (this.routerPrefix) {
    // }
  }
}
