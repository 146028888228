import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { WidgetService } from '../widget.service';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '@app/shared/services/cache.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import WidgetBase from '../widegt.base';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
@Component({
  selector: 'year-over-year-widget',
  templateUrl: './year-over-year.html',
  styleUrls: ['./year-over-year.scss'],
})
export class YearOverYearComponent extends WidgetBase implements OnInit, OnDestroy {
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';

  data: any;
  reportingRoutePrefix = `/${environment.reportingRoutePrefix}`;
  canRender = false;
  yearOverYear: any;
  api: Subscription;
  test: any = [{ nps: 'SALES', scores: '51.4', priorYear: '44.3', year: '7.0 ppt' }];

  cols: any = [
    { field: 'EventName', header: 'Event' },
    { field: 'Current', header: 'Current Year' },
    { field: 'PriorYear', header: 'Prior Year' },
    { field: 'YOY', header: 'YOY' },
    { field: 'RegionAvg', header: 'Region AVG' },
    // { field: 'KpiScore', header: 'Kpi Score' },
  ];

  private subscriptions: Subscription[] = [];

  constructor(
    public toastService: ToastService,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public gbfService: GlobalFilterService,
    private router: Router
  ) {
    super(gbfService, translateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.yearOverYearUrl).subscribe((res: any) => {
        if (res) {
          this.data = res;
          this.initYearOverYear();
          this.canRender = true;
        }
      })
    );

    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
      this.subscriptions.push(
        this.gbfService.gbfOnApplyFilter.subscribe(() => {
          this.loadWidget();
        })
      );
    }
    // this.secondsCounterSub = this.secondsCounter.subscribe((n) => {

    // });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }

  loadWidget() {
    this.canRender = false;
    this.widgetService.isAllLoaded = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getYearOverYear({ apiConfig: this.apiConfig, extra: this.extra }).subscribe(
      (res: any) => {
        if (res) {
          this.data = res;
          this.initYearOverYear();
          this.widgetService.isAllLoaded = true;
          this.canRender = true;
        }
      },
      (error: any) => {
        this.canRender = false;
        this.widgetService.isAllLoaded = true;
      }
    );
  }

  RouteOrgSummary() {
    const route = [this.reportingRoutePrefix, environment.reportingRoutePrefix, 'orgsummary'];
    this.router.navigate(route);
  }

  initYearOverYear() {
    this.yearOverYear = [this.data];
  }
}
