import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { WidgetService } from '../widget.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { TopResolutionsService } from './top-resolutions.service';
import { CacheService } from '@app/shared/services/cache.service';
import { FiltersService } from '@app/shared/services/filters.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { I18nService } from '@app/core/i18n.service';
import { generateTop5ClosedAlerts } from '@clearviewshared/highchart/helperfunctions';
import Highcharts from 'highcharts';
import { ITop5ResolutionsResponse } from './types/ITop5ResolutionsResponse';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
@Component({
  selector: 'top-resolutions-widget',
  templateUrl: './top-resolutions.component.html',
  styleUrls: ['./top-resolutions.component.scss'],
})
export class TopResolutionsComponent extends WidgetBase implements OnInit, OnDestroy {
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @Input() showGraph = false;
  @ViewChild('graphTopClosedAlerts', { read: ElementRef, static: true }) graphTopClosedAlerts: ElementRef;
  chartModel: any = {};
  canRender = false;
  resolutions: Array<ITop5ResolutionsResponse>;
  dispGraph = false;
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  chart: any;
  api: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    public gbfService: GlobalFilterService,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public toastService: ToastService,
    public topResolutionsService: TopResolutionsService,
    private filterService: FiltersService,
    private appConfig: AppConfigurationService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService
  ) {
    super(gbfService, translateService);
    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }
      this.secondsCounter = interval(widgetThresholdVal);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.top5ResolutionsUrl).subscribe((res: Array<ITop5ResolutionsResponse>) => {
        if (res) {
          this.resolutions = res;
        }
      })
    );
    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
    }
    // this.secondsCounterSub = this.secondsCounter.subscribe((n) => {
    //   // this.loadWidget();
    // });
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    this.dispGraph = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getTop5Resolutions({ apiConfig: this.apiConfig }).subscribe({
      next: (res: Array<ITop5ResolutionsResponse>) => {
        if (res) {
          this.canRender = true;
          this.resolutions = res;
          this.dispGraph = this.resolutions && this.resolutions.length > 0 ? true : false;
          this.widgetService.isAllLoaded = true;
          this.initGraphData();
        }
      },
      complete: () => {
        this.canRender = true;
        this.dispGraph = true;
        this.widgetService.isAllLoaded = true;
      },
      error: () => {
        this.canRender = true;
        this.dispGraph = false;
        this.widgetService.isAllLoaded = true;
      },
    });
  }

  initGraphData() {
    if (this.showGraph) {
      const data = this.resolutions.map((value: any) => {
        return {
          //name: value.Title,
          name: this.getTranslatedResolution(value.Title),
          y: parseFloat(value.Count),
        };
      });

      const seriesData: any = [
        {
          name: this.translateService.instant('top_5_resolutions.title'),
          colorByPoint: true,
          data: data,
        },
      ];

      this.chartModel = generateTop5ClosedAlerts(seriesData, this.i18nService);
      this.chart = new Highcharts.Chart(this.graphTopClosedAlerts.nativeElement, this.chartModel);
    }
  }

  getTranslatedResolution(key: string) {
    return this.translationHelper.GetHotalertResolutionTranslation(key);
  }
  changeValue(value: any) {
    return parseFloat(Math.round(value * 100) / 100 + '').toFixed(1);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }
}
