import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { environment } from '@env/environment';

@Pipe({
  name: 'rdate',
})
export class RDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}
  transform(date: any) {
    if (date) {
      if (!this.locale) {
        this.locale = 'en-CA';
      }
      return formatDate(date, environment.dateFormat, this.locale);
    }
    return date;
  }
}
