<app-widget-hoster-helper
  *ngIf="reRender"
  [layout]="layout"
  [component]="component"
  [rootClass]="rootClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
  [styleClass]="styleClass"
  [extra]="extra"
  [events]="events"
  [showGraph]="showGraph"
>
</app-widget-hoster-helper>
