import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from './message';

@Injectable()
export class MessageService {
  clearSource = new Subject<string>();
  messageSource = new Subject<Message | Message[]>();
  messageObserver = this.messageSource.asObservable();
  clearObserver = this.clearSource.asObservable();

  add(message: Message) {
    if (message) {
      this.messageSource.next(message);
    }
  }

  addAll(messages: Message[]) {
    if (messages && messages.length) {
      this.messageSource.next(messages);
    }
  }

  clear(key?: string) {
    this.clearSource.next(key || null);
  }
}
