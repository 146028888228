<div class="filter filter--voc" (clickOutside)="toggleMenu('')">
  <ul>
    <li class="filter__item flex4" *ngIf="allowdealerType">
      <div class="filter-field">
        <div class="filter__trigger" (click)="showDealerType(); $event.stopPropagation()">
          <label class="field__label">{{ dealerType | translate }}</label>
          <div class="field__icons">
            <span class="icons__active"> <i class="fal fa-pencil"></i></span>
            <span class="icons__active icon--ok"><i class="fal fa-check"></i> </span>
            <i class="far fa-angle-down"></i>
          </div>
        </div>
        <div class="filter-dropdown dropdown--list" [class.active]="showdealerType">
          <div class="filter__list">
            <ul *ngFor="let ii of dealerTypesList">
              <li (click)="changeDealerType(ii.value, ii.label, ii.translate)">
                <a>{{ ii.label }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
    <li class="filter__item flex4">
      <div class="filter-field filter--hierarchy" [class.active]="showHierarchy">
        <div class="filter__trigger">
          <ng-container *ngIf="showHierarchy">
            <i class="fal fa-search"></i>
            <input
              type="text"
              placeholder="{{ 'Dealer Name' | translate }}"
              class="field__label"
              (ngModelChange)="searchDealerChange($event)"
              [(ngModel)]="dealerquery"
            />
          </ng-container>
          <ng-container *ngIf="!showHierarchy">
            <i class="fal fa-industry"></i>
            <span class="field__label" (click)="showHierarchy_(); $event.stopPropagation()">
              <ng-container *ngIf="items2.length > 0 && items2[items2.length - 1].label != undefined">
                {{ items2[items2.length - 1].label }}
              </ng-container>
              <ng-container *ngIf="items2.length == 0 || !items2">
                {{ 'nothing_selected.label' | translate }}
              </ng-container>
            </span>
          </ng-container>
          <div class="field__icons">
            <span *ngIf="showHierarchy" (click)="showHierarchy_(); $event.stopPropagation()">
              <i class="fal fa-times"></i>
            </span>
            <i class="far fa-angle-down" (click)="showHierarchy_()"></i>
          </div>
        </div>
      </div>
      <div class="filter-dropdown dropdown--hierarchy hierarchy--big" [class.active]="showHierarchy">
        <rf-breadcrumb
          (onItemClicked)="onClickInnerBreadCrumb($event)"
          *ngIf="searchItems?.length == 0 && dealerquery?.length < 2"
          [model]="items2"
          [styleClass]="'hierarchy-breadcrumbs'"
        ></rf-breadcrumb>
        <ul class="hierarchy" *ngIf="searchItems?.length == 0 && dealerquery?.length < 2">
          <ng-container *ngFor="let a of active">
            <li class="hierarchy__list" *ngIf="a.items?.length > 0">
              <!-- <ul class="mCustomScrollbar"> -->
              <cvp-scrollPanel-ul [style]="{ width: '100%', height: '205px' }">
                <li
                  [class.active]="a?.active?.label == item?.label"
                  (click)="add(a.id, item)"
                  *ngFor="let item of a.items"
                  [ngClass]="{ 'no--child': item?.children?.length > 0 ? false : true }"
                >
                  <a>{{ item?.label }}</a>
                </li>
              </cvp-scrollPanel-ul>
            </li>
          </ng-container>
        </ul>
        <!-- {{searchItems.length}} -->
        <div *ngIf="dealerquery?.length > 1">
          <ng-container *ngIf="searchItems?.length === 0 && showerrorMessage">
            <cvp-scrollPanel-ul [style]="{ width: '100%', height: '205px' }">
              <li style="text-align: center; font-weight: bold; margin-top: 12px">No record found.</li>
            </cvp-scrollPanel-ul>
          </ng-container>
          <ng-container *ngIf="searchItems?.length != 0">
            <cvp-scrollPanel-ul [style]="{ width: '100%', height: '205px' }">
              <li *ngFor="let items of searchItems">
                <rf-breadcrumb
                  [styleClass]="'hierarchy-breadcrumbs'"
                  [model]="items.dealerHierarchy"
                  (click)="selectItem(items)"
                >
                </rf-breadcrumb>
              </li>
            </cvp-scrollPanel-ul>
          </ng-container>
        </div>
        <div class="dropdown__footer">
          <a
            *ngIf="showerrorMessage === false || searchItems?.length != 0 || dealerquery?.length < 2"
            class="btn"
            (click)="orgDone(false)"
          >
            {{ 'done_button.title' | translate }}
          </a>
        </div>
      </div>
    </li>

    <li class="filter__item flex4">
      <cvp-calendar
        #datePicker
        (onDataPickerOpen)="toggleMenu('showDateRange')"
        (_onMonthSelect)="onMonthSelect($event)"
        [programDate]="programDate"
        (hideDatePicker)="hideDatePicker($event)"
        [dateDropDown]="dateDropDown"
        [currentCalendarDate]="currentCalendarDate"
        [primeCalendar]="false"
        [inline]="true"
        view="month"
        (_onDateRangeChange)="onDateRangeChange($event)"
        [showDatePicker]="showDateRange"
        [defaultSelectedDate]="defaultSelectedDate"
        (done)="datePickerDone($event)"
        [monthNavigator]="monthNavigator"
        [yearNavigator]="yearNavigator"
        [moment]="primeMoment"
      >
      </cvp-calendar>
    </li>

    <li class="filter__item flex3">
      <div class="filter-field">
        <div class="filter__trigger" (click)="showSurveyQuestion(); $event.stopPropagation()">
          <label class="field__label">Source Type</label>
          <div class="field__icons">
            <span class="icons__active"> <i class="fal fa-pencil"></i></span>
            <span class="icons__active icon--ok"><i class="fal fa-check"></i> </span>
            <i class="far fa-angle-down"></i>
          </div>
        </div>

        <div
          class="filter-dropdown dropdown--vocsurvey dropdown--surveyresponses"
          [class.active]="showSurveyQuestionCtrl"
        >
          <div class="filter__list">
            <ul>
              <li *ngFor="let ii of sourceTypeList">
                <a>
                  <p-checkbox
                    name="SurveyQuestion"
                    [(ngModel)]="ii.IsChecked"
                    value="{{ ii.Id }}"
                    label="{{ ii.Id }}"
                    (onChange)="SourceTypeSelection($event, ii.Id)"
                    binary="true"
                  ></p-checkbox>
                </a>
              </li>
            </ul>
          </div>
          <div class="dropdown__footer">
            <a class="btn" (click)="surveyQuestionDone()" translate> Done </a>
          </div>
        </div>
      </div>
    </li>

    <li class="filter__item flexbtn">
      <a class="btn btn--filter btn--disabled" *ngIf="!filterService.filterDisabledStatus">
        {{ 'apply_filters_button.title' | translate }}
      </a>
      <a (click)="closeall()" class="btn btn--filter" *ngIf="filterService.filterDisabledStatus">
        {{ 'apply_filters_button.title' | translate }}
      </a>
    </li>
  </ul>
</div>
<!-- 
  <cvp-panelMenu [model]="items___" [style]="{'width':'300px'}"></cvp-panelMenu> -->
