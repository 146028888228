import { NumberValueAccessor } from '@angular/forms';
import { FLOAT } from 'html2canvas/dist/types/css/property-descriptors/float';

export interface ITotalAlerts {
  Title: string;
  Count: number;
  // Months: ITotalAlertsData[];
  // MonthlyChart: any;
  CcoreChart: any;
  Percent: any;
  Color: string;
  Order: number;
}

export interface ITotalAlertsData {
  Year: number;
  Month: number;
  Score: number;
}

export interface INpsScore {
  Title: string;
  Key: string;
  Count: number;
  // Months: ITotalAlertsData[];
  // MonthlyChart: any;
  CcoreChart: any;
  Percent: any;
  Color: string;
  Order: number;
}

export interface Resolution {
  Name: string;
  Score: number;
  Percentage: number;
}

export interface INpsScore {
  Title: string;
  Key: string;
  Count: number;
  // Months: ITotalAlertsData[];
  // MonthlyChart: any;
  CcoreChart: any;
  Percent: any;
  Color: string;
  Order: number;
}

export interface ResolutionMockData {
  Sales: Resolution[];
  Collision: Resolution[];
  Service: Resolution[];
  CPO: Resolution[];
}

export enum AlertStatus {
  'Closed' = 0,
  'New' = 1,
  'InProgress' = 2,
  'Late' = 3,
  'CloseWithinTarget' = 4,
  'CloseOutOfTarget' = 5,
  'ExpiredAfter90Days' = 6,
  'In Progress' = 2,
  'Late (within 72 hours)' = 9,
  'Late (after 72 hours)' = 10,
}

export enum CorrectContactInfoStatus {
  'missing_info' = 'no_of_missing_information',
  'invalid_info' = 'no_of_incorrect_information',
}

export interface IMonths {
  Year: number;
  Month: number;
  Score: number;
}

export interface IKeyPerformanceIndicators {
  KpiName: string;
  TotalScore: number;
  Months: IMonths[];
  MonthlyChart: any;
  ScoreChart: any;
}

export interface IRecommendedActionsResponse {
  Kpi: IKPI[];
  ImpactData: IImpactData[];
  Heading: string;
}

export interface IKPI {
  KpiName: string;
  Score: number;
}

export interface IImpactData {
  Title: string;
  Score: number;
}

export interface INotRespondedReviews {
  Title: string;
  Count: number;
  Percent: any;
  Color: string;
  Order: number;
}

export enum NotRespondedReviewsStatus {
  'Google' = 1,
  'Yelp' = 2,
  'Dater' = 3,
}

export interface ISentimentCount {
  Title: string;
  Count: number;
  Percent: any;
  Color: string;
  Order: number;
}
export interface ITrendingTopicsStats {
  Title: string;
  Count: number;
  Percent: any;
  Color: string;
  Order: number;
}

export enum SentimentCountStatus {
  'Positive' = 1,
  'Neutral' = 2,
  'Negative' = 3,
  'Unassigned' = 4,
}

export interface ICommentsBusinessArea {
  Title: string;
  Count: number;
  Percent: any;
  Color: string;
  Order: number;
}

export enum CommentsBusinessAreaStatus {
  'General' = 0,
  'People' = 1,
  'Process' = 2,
  'Product' = 3,
  'Facility' = 4,
}

export interface ISentimentAnalysis {
  name: string;
  weight: number;
}

export interface Topics {
  Title: string;
  Count: number;
}

export interface ITrendingTopicsResponse {
  Sentiment: string;
  Topics: Topics[];
}

export interface ICategorySummaryResponse {
  Title: string;
  Data: number[];
}

export interface TreeListTopics {
  Title: string;
  Sentiment: string;
  Count: number;
}

export interface TreeListComments {
  Title: string;
  Comment: string;
  Sentiment: string;
  Count: number;
}

export interface ICategoryScoreCorrelationResponse {
  TotalSurveys: number;
  TotalComments: number;
  CommentsPercentage: any;
  CommentsCount: any;
  DataTree: TreeNode[];
}

export interface ICategoryScoreCorrelation {
  title: string;
  surveyCount: number;
  score: number;
  id: number;
  parentId: number;
}

export interface TreeNode {
  data?: any;
  children?: TreeNode[];
  leaf?: boolean;
  expanded?: boolean;
}

export interface ICategoryScoreCorrelationBar {
  Title: string;
  Data: number[];
}

export interface ISurveyResponseResponse {
  surveyResponseData: TreeNode[];
}

export interface IAspectCategoriesResponse {
  aspectCategoriesData: TreeNode[];
}

export interface IAspectCategoriesBreadcrum {
  id: number;
  title: string;
  translatedTitle: string;
  level: number;
}

export interface ICompetitiveLandscapeResponse {
  data: any;
}

export interface ICompetitiveLandscape {
  title: string;
  count: number;
  percent: any;
  color: string;
  order: number;
}

export enum CompetitiveLandscapeStatus {
  'Reviews' = 0,
  'Score' = 1,
}

export interface ITreeReportResponse {
  SentimentsTab: any[];
  Faculty: ITreeReportNode[];
}

export interface ITreeReportNode {
  Name: string;
  Data?: any;
  children?: ITreeReportNode[];
  Leaf?: boolean;
  Expanded?: boolean;
  IsSelected: boolean;
}

export interface ICategoryDetails {
  Comments: any[];
  Cloud: any[];
}

export interface ISentimentDropdownOptions {
  label: string;
  value: any;
}

export interface ISentimentTrend {
  Year: number;
  Month: number;
  TotalCount: number;
  RatingData: ISentimentRatingData[];
}

export interface ISentimentRatingData {
  Id: number;
  Name: string;
  Count: number;
  Percentage: number[];
}

export interface ISentimentTrendSeries {
  name: string;
  nameText: string;
  data: any[];
  count: any[];
}

export interface ISentimentRatingData {
  Id: number;
  Name: string;
  Count: number;
  Percentage: number[];
}

export interface ICategoryFrequencyScoreRelation {
  Category: any;
  Percentage: any;
  Avg: any;
  Id: any;
}

export interface IDominantCount {
  Title: string;
  Count: number;
  Percent: any;
  Color: string;
  Order: number;
}
export interface IFrftRootCauses {
  Year: number;
  Month: number;
  TotalCount: number;
  RatingData: IFrftRootCausesRatingData[];
}

export interface IFrftRootCausesRatingData {
  Id: number;
  Name: string;
  Count: number;
  //Percentage: number[];
  Percentage: number;
}

export class IFrftRootCausesSeries {
  name: string; // meta -->
  nameText: string; // meta-->
  data: any[]; // {10,20,30}
  count: any[]; //
}

export enum FrftRootCausesStatus {
  'Positive' = 1,
  'Neutral' = 2,
  'Negative' = 3,
  'Unassigned' = 4,
}

export enum FrftByServiceAdvisorTechnicianStatus {
  'Positive' = 1,
  'Neutral' = 2,
  'Negative' = 3,
  'Unassigned' = 4,
}

export interface IFrftByServiceAdvisorTechnician {
  Year: number;
  Month: number;
  TotalCount: number;
  RatingData: IFrftByServiceAdvisorTechnicianRatingData[];
}
export interface IFrftByServiceAdvisorTechnicianSeries {
  name: string;
  nameText: string;
  data: any[];
  count: any[];
}
export interface IFrftByServiceAdvisorTechnicianRatingData {
  Id: number;
  Name: string;
  Count: number;
  //Percentage: number[];
  Percentage: number;
}

/*export interface IFrftModelQ6no {
  header : string;
  meta: [];
  widget_config: {};
  response_question : IFrftModelQ6noRatingData[];
}

export interface IFrftModelQ6noRatingData {
  month: number;
  options: IFrftModelQ6noSeries[];
  year: number;
  //Percentage: number[];
 // Percentage: number;
}

export interface IFrftModelQ6noSeries {
  name: string;
  nameText: string;
  count: any[];
  data: string[];
}

export enum FrftModelQ6noStatus {
  'Positive' = 1,
  'Neutral' = 2,
  'Negative' = 3,
  'Unassigned' = 4,
}*/
export interface IFrftModelQ6no {
  Year: number;
  Month: number;
  TotalCount: number;
  RatingData: IFrftModelQ6noRatingData[];
}

export interface IFrftModelQ6noRatingData {
  Id: number;
  Name: string;
  Count: number;
  //Percentage: number[];
  Percentage: number;
}

export class IFrftModelQ6noSeries {
  name: string;
  nameText: string;
  data: any[];
  count: any[];
}

export enum FrftModelQ6noStatus {
  'Positive' = 1,
  'Neutral' = 2,
  'Negative' = 3,
  'Unassigned' = 4,
}
export interface IAsrUtilization {
  Title: string;
  Count: number;
  Percent: any;
  Color: string;
  Order: number;
}
export interface ISentimentSummary {
  DominantSentiments: IDominantCount[];
  CommentSentiments: ISentimentCount[];
  totalSentiments: number;
  totalDominants: number;
}
export enum SentimentSummaryStatus {
  'CommentSentiments' = 1,
  'DominantSentiments' = 2,
}
