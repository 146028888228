export const WidgetList = {
  'Global Dashboard': [
    // {name: 'KPI', show: false},
    // {name: 'Recommended Questions', value: 'Recommended Questions',  isChecked: true, image: 't-a.jpg',  show: false},
    // {name: 'Sales Questions', value: 'Sales Questions',  isChecked: true, image: 't-a.jpg',  show: false},
    // {name: 'Hot Alerts', value: 'Hot Alerts',  isChecked: true, image: 't-a.jpg',  show: false, category: 2},
    {
      name: 'Customer Journey Widget-V2',
      value: 'Customer Journey Widget-V2',
      isChecked: true,
      image: 'Customer Journey.png',
      show: true,
      label: 'Customer Journey Widget-V2',
      description:
        'Displays aggregated scores for customer journey points (Explore, Buying, Perfect Start, Service, Ownership) and allows users to compare scores by comparison points.',
    },
    {
      name: 'Survey Trend',
      value: 'Survey Trend',
      isChecked: true,
      image: 'Survey Trend.png',
      show: true,
      label: 'Survey Trend',
      description: 'The Survey Trend widget displays the comparision of # of sending surveys over completion.',
    },
    {
      name: 'Survey Completion Rate',
      value: 'Survey Completion Rate',
      isChecked: true,
      image: 'Survey Completion Rate.png',
      show: true,
      description:
        'The Survey Completion Rate widget displays a breakdown of survey disposition statistics by count and percent.',
    },
    {
      name: 'Key Performance',
      value: 'Key Performance',
      isChecked: true,
      image: 'Key Performance.png',
      show: true,
      category: 3,
      description:
        'The Key Performance widget will be question based showing the three (3) lowest scoring questions on the survey, and the three (3) highest scoring questions on the survey.',
    },
    {
      name: 'Fixed Right First Time Trend',
      value: 'Fixed Right First Time Trend',
      isChecked: true,
      image: 'Performance_Trend_Widget.png',
      show: true,
      description: 'Fixed Right First Time Trend',
    },
    {
      name: 'Performance Trend Widget',
      value: 'Performance Trend Widget',
      isChecked: true,
      image: 'Performance_Trend_Widget.png',
      show: true,
      description: 'Performance Trend Widget',
    },
    {
      name: 'Key Performance Indicators',
      value: 'Key Performance Indicators',
      isChecked: true,
      image: 'Key Performance Indicators.png',
      show: true,
      description: 'Displays a rolling 12 month trend chart on the event type specific KPI’s.',
    },
    {
      name: 'Total Alerts',
      value: 'Total Alerts',
      isChecked: true,
      image: 'Total Alerts.png',
      show: true,
      description: 'The Total Alerts widget provides a visual breakdown of the counts of all alert statuses.',
    },
    {
      name: 'Survey List Widget',
      value: 'Survey List Widget',
      isChecked: true,
      image: 't-a.jpg',
      show: true,
      description: 'Description for Survey List Widget Here.. ',
    },
    {
      name: 'Nps Score',
      value: 'Nps Score',
      isChecked: true,
      image: 'Total Alerts.png',
      show: true,
      description: 'The Nps Score Widget',
    },
    {
      name: 'Heat Map',
      value: 'Heat Map',
      isChecked: true,
      image: 'Total Alerts.png',
      show: true,
      description: 'The Heat Map Widget',
    },
    {
      name: 'Top/Bottom Performance',
      value: 'Top/Bottom Performance',
      isChecked: true,
      image: 'Top Bottom Performers.png',
      show: true,
      category: 3,
      description:
        'Based on Survey Score. The Top/Bottom Performers widget shows the top 5 and bottom 5 Dealers (or Employee’s) by survey score and survey count.',
    },
    {
      name: 'Year over Year',
      value: 'Year over Year',
      isChecked: true,
      image: 'Year over Year.png',
      show: true,
      description: 'Current vs prior year Survey Score.',
    },
    {
      name: 'Performance Trend',
      value: 'Performance Trend',
      isChecked: true,
      image: 'Performance Trend.png',
      show: true,
      description: 'The Performance Trend widget displays a rolling 12 month trend chart on the survey score.',
    },
    {
      name: 'Performance Trend Question',
      value: 'Performance Trend Question',
      isChecked: true,
      image: 'Performance Trend.png',
      show: true,
      description: 'The Performance Trend Question widget displays a rolling 12 month trend chart on the survey score.',
    },
    {
      name: 'Recommended Actions',
      value: 'Recommended Actions',
      isChecked: true,
      image: 'Recommended Actions.png',
      show: true,
      description: 'Widget that overviews survey KPI metrics and allows users to create Action Plans.',
    },
    {
      name: 'Customer Journey Widget',
      value: 'Customer Journey Widget',
      isChecked: true,
      image: 'Customer Journey.png',
      show: true,
      description:
        'Displays aggregated scores for customer journey points (Explore, Buying, Perfect Start, Service, Ownership) and allows users to compare scores by comparison points.',
    },
    {
      name: 'Dealer Performance',
      value: 'Dealer Performance',
      isChecked: true,
      image: 'Total Alerts.png',
      show: true,
      description: 'The Dealer Performance Widget',
    },
    {
      name: 'Customer Comments',
      value: 'Customer Comments',
      isChecked: true,
      image: 'Customer Comments.png',
      show: true,
      description: 'Widget that displays the ten (10) most recent customer comments.',
    },
    {
      name: 'Top 5 Closed Alerts',
      value: 'Top 5 Closed Alerts',
      isChecked: true,
      image: 'Top 5 Closed Alerts.png',
      show: true,
      description:
        'The Top 5 Resolutions displays the Top 5 Resolution Reasons entered upon alert closure as well as the count and percentage that the resolution reason was used.',
    },

    {
      name: 'Dealer Variable Bonus (DVB)',
      value: 'Dealer Variable Bonus (DVB)',
      isChecked: true,
      image: '5_Start_Rating.png',
      show: true,
      label: '5 Star Rating',
      description: 'Dealer Variable Bonus Widget',
    },
    {
      name: 'Featured Score',
      value: 'Featured Score',
      isChecked: true,
      image: 'Featured Score.png',
      show: true,
      description: 'Featured Score Widget',
    },
    {
      name: 'Portal Traffic',
      value: 'Portal Traffic',
      isChecked: true,
      image: 'Portal Traffic.png',
      show: true,
      description: 'Portal Traffic Widget',
    },
    {
      name: 'Correct Contact Info Outstanding',
      value: 'Correct Contact Info Outstanding',
      isChecked: true,
      image: 'Total Alerts.png',
      show: true,
      description: 'Correct Contact Info Outstanding Widget',
    },
    {
      name: 'Staff Performance Trend',
      value: 'Staff Performance Trend',
      isChecked: true,
      image: 'Staff_Performance_Trend.png',
      show: true,
      description: 'Staff Performance Trend Widget',
    },

    {
      name: 'Top 5 Opportunity',
      value: 'Top 5 Opportunity',
      isChecked: true,
      image: 'Recommended Actions.png',
      show: true,
      description: 'Top 5 Opportunity Widget',
    },
  ],
  'Hot Alerts': [
    {
      name: 'Total Alerts',
      value: 'Total Alerts',
      isChecked: true,
      image: 'Total Alerts.png',
      show: true,
      description:
        'The Total Alerts widget provides a visual breakdown of the counts of all alert statuses. Includes status’: New, Late, In Progress, Closed within Target, Closed out of Target.',
    },
    {
      name: 'Alerts Efficiency',
      value: 'Alerts Efficiency',
      isChecked: true,
      image: 'Alerts Efficiency.png',
      show: true,
      description:
        'The Alert Efficiency widget displays a Rolling 6 Month trend chart showing the Alert status count. Includes status’: New, In Progress, Late, Closed within Target, Closed out of Target.',
    },
    {
      name: 'Re-Contact Customers',
      value: 'Re-Contact Customers',
      isChecked: true,
      image: 'Re-Contact Customers.png',
      show: true,
      description: '',
    },
    {
      name: 'Top Bottom Performers',
      value: 'Top Bottom Performers',
      isChecked: true,
      image: 'Top Bottom Performers.png',
      show: true,
      description:
        'Based on Resolution Rate. The Top/Bottom Performers widget shows the top 5 and bottom 5 Dealers (or Employee’s) by Resolution Rate and alert count.',
    },
    {
      name: 'AlertEscalations',
      value: 'AlertEscalations',
      isChecked: true,
      image: 'Alerts Escalation.png',
      show: true,
      description:
        'The Reasons / Escalations widget displays the top five (5) reasons for Alert generation from the core surveys.',
    },
    {
      name: 'Top 5 Alert Resolutions',
      value: 'Top 5 Alert Resolutions',
      isChecked: true,
      image: 'Top 5 Alert Resolution.png',
      show: true,
      description:
        'The Top 5 Resolutions displays the Top 5 Resolution Reasons entered upon alert closure as well as the count and percentage that the resolution reason was used.',
    },
  ],
  'Social Media': [
    {
      name: 'Average Rating Over Time',
      value: 'Average Rating Over Time',
      isChecked: true,
      image: 'Average Rating Over Time.png',
      show: true,
      description: 'Trend chart displaying the rating score over a Rolling 13 Month period.',
    },
    {
      name: 'Review Over Time',
      value: 'Review Over Time',
      isChecked: true,
      image: 'Reviews Over Time.png',
      show: true,
      description: 'Trend chart displaying the count of reviews, by review site, over a Rolling 13 Month period.',
    },
    {
      name: 'Sentiment Count',
      value: 'Sentiment Count',
      isChecked: true,
      image: 'Sentiment Count.png',
      show: true,
      description: 'Chart displaying the breakdown of reviews, by sentiment.',
    },
    {
      name: 'Sentiment Trend',
      value: 'Sentiment Trend',
      isChecked: true,
      image: 'Sentiment Trend.png',
      show: true,
      description: 'Chart displaying a Rolling 12 Month trend of comment sentiment statistics',
    },
    {
      name: 'Category Summary',
      value: 'Category Summary',
      isChecked: true,
      image: 'Category Summary.png',
      show: true,
      description: 'Chart displaying comment sentiment statistics by Top Level Category.',
    },
    {
      name: 'Trending Topics',
      value: 'Trending Topics',
      isChecked: true,
      image: 'Trending Topics.png',
      show: true,
      description: 'Visual displaying keywords and phrases commonly used by sentiment.',
    },
    {
      name: 'Categories Over Time Period',
      value: 'Categories Over Time Period',
      isChecked: true,
      image: 'Categories Displayed Over Time Period.png',
      show: true,
      description:
        'Visual and dynamic widget allowing users to view the amount of comments received, per top level, over a Rolling 12 Month period.',
    },
    {
      name: 'Rating Distribution',
      value: 'Rating Distribution',
      isChecked: true,
      image: 'Ratings Distribution.png',
      show: true,
      description: 'Chart displaying the counts of reviews, by review rating.',
    },
    {
      name: 'Reviews Sites Distribution',
      value: 'Reviews Sites Distribution',
      isChecked: true,
      image: 'Review Sites Distribution.png',
      show: true,
      description: 'Chart displaying the counts of reviews, by review source.',
    },
    {
      name: 'Top Bottom Performer',
      value: 'Top Bottom Performer',
      isChecked: true,
      image: 'Top Bottom Performers.png',
      show: true,
      description: 'The widget displays Top Five and Bottom Five Dealers based on their social media score.',
    },
    {
      name: 'Dealer Profile',
      value: 'Dealer Profile',
      isChecked: true,
      image: 'Dealer Profile.PNG',
      show: true,
    },
    {
      name: 'Not Responded Reviews',
      value: 'Not Responded Reviews',
      isChecked: true,
      image: 'Reviews.png',
      show: true,
      description: 'Widget displays Include Not Responded Reviews',
    },
    {
      name: 'Performance Trend',
      value: 'Performance Trend',
      isChecked: true,
      image: 'Performance Trend.png',
      show: true,
      description: 'Widget displays Performance Trend',
    },
    {
      name: 'Regional Map',
      value: 'Regional Map',
      isChecked: true,
      image: 'Regional Map.png',
      show: true,
      description:
        'The Sentiment by Region widget allows Corporate Users to view comment statistics on a map visual. The widget displays the ratio of positive to negative comments on a map view.',
    },
    // { name: 'Sentiment Count', value: 'Sentiment Count', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Top Bottom Performers', value: 'Top Bottom Performers', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Average Rating Over Time', value: 'Average Rating Over Time', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Rating Distribution', value: 'Rating Distribution', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Reviews Sites Distribution', value: 'Reviews Sites Distribution', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Sentiment Trend', value: 'Sentiment Trend', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Emerging Trends By Category', value: 'Emerging Trends By Category', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Category Frequency And Score Corelation', value: 'Category Frequency And Score Corelation', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Category Summary', value: 'Category Summary', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Sentiment By Region', value: 'Sentiment By Region', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Category Summary', value: 'Category Summary', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Category Summary', value: 'Category Summary', isChecked: true, image: 't-a.jpg', show: true },
  ],
  VOC: [
    {
      name: 'Regional Map',
      value: 'Regional Map',
      isChecked: true,
      image: 'Regional Map.png',
      show: true,
      description:
        'The Sentiment by Region widget allows Corporate Users to view comment statistics on a map visual. The widget displays the ratio of positive to negative comments on a map view.',
    },
    {
      name: 'Sentiment Count',
      value: 'Sentiment Count',
      isChecked: true,
      image: 'Sentiment Count.png',
      show: true,
      description: 'Chart displaying the breakdown of comment counts and percentages by sentiment.',
    },
    {
      name: 'Sentiment Trend',
      value: 'Sentiment Trend',
      isChecked: true,
      image: 'Sentiment Trend.png',
      show: true,
      description: 'Chart displaying a Rolling 12 Month trend of comment sentiment statistics.',
    },
    {
      name: 'Category Summary',
      value: 'Category Summary',
      isChecked: true,
      image: 'Category Summary.png',
      show: true,
      description: 'Chart displaying comment sentiment statistics by Top Level Category.',
    },
    {
      name: 'Trending Topics',
      value: 'Trending Topics',
      isChecked: true,
      image: 'Trending Topics.png',
      show: true,
      description: 'Visual displaying keywords and phrases commonly used by sentiment.',
    },
    {
      name: 'Categories Over Time Period',
      value: 'Categories Over Time Period',
      isChecked: true,
      image: 'Categories Displayed Over Time Period.png',
      show: true,
      description:
        'Visual and dynamic widget allowing users to view the amount of comments received, per top level, over a Rolling 12 Month period.',
    },
    {
      name: 'Category Score Correlation',
      value: 'Category Score Correlation',
      isChecked: true,
      image: 'Category Score Correlation.png',
      show: true,
      description:
        'Visual and dynamic widget allowing users to review the survey score data compared to comment categorization.',
    },
    {
      name: 'Category Frequency And Score Correlation',
      value: 'Category Frequency And Score Correlation',
      isChecked: true,
      image: 'Category Frequency And Score Correlation.png',
      show: true,
      description:
        'Chart displaying the Top 10 categories (by frequency + score) and the associated Survey Score for the category.',
    },

    // { name: 'Comments By Business Area', value: 'Comments By Business Area', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Review Over Time', value: 'Review Over Time', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Categories Displayed Over Time Period', value: 'Categories Displayed Over Time Period', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Category Summary', value: 'Category Summary', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Category Score Correlation', value: 'Category Score Correlation', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Not Responded Reviews', value: 'Not Responded Reviews', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Competitive Landscape', value: 'Competitive Landscape', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Sentiment Count', value: 'Sentiment Count', isChecked: true, image: 't-a.jpg', show: true },
    // { name: 'Sentiment Analysis', value: 'Sentiment Analysis', isChecked: true, image: 't-a.jpg', show: true },
  ],
  'Day of Week Report': [
    {
      name: 'Scores',
      value: 'Scores',
      isChecked: true,
      image: 'Scores.png',
      show: true,
      description: '',
    },
    {
      name: 'RO % Distribution',
      value: 'RO % Distribution',
      isChecked: true,
      image: 'RO % Distribution.png',
      show: true,
      description: '',
    },
    {
      name: 'Overall CSE',
      value: 'Scores',
      isChecked: true,
      image: 'Scores.png',
      show: true,
      description: '',
    },
    {
      name: 'MPI Perc Yes',
      value: 'MPI Perc Yes',
      isChecked: true,
      image: 'MPI % Yes.png',
      show: true,
      description: '',
    },
    {
      name: 'MPI Yes vs No',
      value: 'MPI Yes vs No',
      isChecked: true,
      image: 'MPI Yes vs No.png',
      show: true,
      description: '',
    },
    {
      name: 'Impact of MPI',
      value: 'Impact of MPI',
      isChecked: true,
      image: 'Impact of MPI.png',
      show: true,
      description: '',
    },
    {
      name: 'MPI Yes',
      value: 'MPI Yes',
      isChecked: true,
      image: 'MPI Yes.png',
      show: true,
      description: '',
    },
    {
      name: 'MPI No',
      value: 'MPI No',
      isChecked: true,
      image: 'MPI No.png',
      show: true,
      description: '',
    },
    {
      name: 'OC Maint/Yes',
      value: 'OC Maint/Yes',
      isChecked: true,
      image: 'OC Maint/Yes.png',
      show: true,
      description: '',
    },
    {
      name: 'OC Maint/No',
      value: 'OC Maint/No',
      isChecked: true,
      image: 'OC Maint/No.png',
      show: true,
      description: '',
    },
  ],
  'FRFT Reports': [
    {
      name: 'Frft Overview',
      value: 'Frft Overview',
      isChecked: true,
      image: 'frft_overview.png',
      show: true,
      description: '',
    },
    {
      name: 'Ftft Root Causes',
      value: 'Ftft Root Causes',
      isChecked: true,
      image: 'frft_root_causes.png',
      show: true,
      description: '',
    },
    {
      name: 'Frft By Model Q6 No',
      value: 'Frft By Model Q6 No',
      isChecked: true,
      image: 'frft_model_q6no.png',
      show: true,
      description: '',
    },
    {
      name: 'Frft By Service Advisor Technicial',
      value: 'Frft By Service Advisor Technicial',
      isChecked: true,
      image: 'frft_service_advisor_technicial.png',
      show: true,
      description: '',
    },
    {
      name: 'Frft Reasons Technician 3mr',
      value: 'Frft Reasons Technician 3mr',
      isChecked: true,
      image: 'frft_reasons_technician_3mr.png',
      show: true,
      description: '',
    },
    {
      name: 'Frft Customer Comments',
      value: 'Frft Customer Comments',
      isChecked: true,
      image: 'frft_customer_comments.png',
      show: true,
      description: '',
    },
    {
      name: 'Frft Nissan University Certification',
      value: 'Frft Nissan University Certification',
      isChecked: true,
      image: 'frft_nissan_university_certification.png',
      show: true,
      description: '',
    },
    {
      name: 'Frft Asr Utilization',
      value: 'Frft Asr Utilization',
      isChecked: true,
      image: 'frft_asr_utilization.png',
      show: true,
      description: '',
    },
  ],
};

export const WidgetPermission = {
  'Global Dashboard': {
    'Total Alerts': 'reporting_globaldahsboard_hotalerts_widget',
    'Key Performance': 'reporting_globaldahsboard_keyperformance_widget',
    'Top/Bottom Performance': 'reporting_globaldahsboard_topbottomperformance_widget',
    'Survey Completion Rate': 'reporting_globaldahsboard_surveycompletionreport_widget',
    'Nps Score': 'reporting_globaldahsboard_nps_widget',
    'Heat Map': 'reporting_globaldahsboard_heatmap_widget',
    'Performance Trend Question': 'reporting_globaldahsboard_performancetrend_question_widget',
    'Key Performance Indicators': 'reporting_globaldahsboard_keyperformanceindicators_widget',
    'Performance Trend': 'reporting_globaldahsboard_performancetrend_widget',
    'Recommended Actions': 'reporting_globaldahsboard_recommendedactions_widget',
    'Customer Comments': 'reporting_globaldahsboard_customercomments_widget',
    'Year over Year': 'reporting_globaldahsboard_yearoveryear_widget',
    'Customer Journey Widget': 'reporting_globaldahsboard_customerJourney_Widget',
    'Customer Journey Widget-V2': 'reporting_globaldahsboard_customerJourney_Widget_V2',
    'Top 5 Closed Alerts': 'reporting_globaldahsboard_top_5_closed_alerts',
    'Survey List Widget': 'reporting_surveylistwidget',
    'Survey Trend': 'reporting_globaldahsboard_surveytrend_widget',
    'Dealer Performance': 'reporting_globaldahsboard_dealer_performance_widget',
    'Fixed Right First Time Trend': 'reporting_frft_widget_fixed_right_first_time_trend',
    'Performance Trend Widget': 'reporting_widget_performance_trend_widget',
    'Top 5 Opportunity': 'reporting_widget_top_opportunity_widget',
    'Dealer Variable Bonus (DVB)': 'reporting_globaldashboard_dvb_widget',
    'Correct Contact Info Outstanding': 'reporting_globaldahsboard_correct_contactinfo_outstanding_widget',
    'Staff Performance Trend': 'reporting_globaldahsboard_staff_performancetrend_widget',
  },
  'Social Media': {
    'Average Rating Over Time': 'reporting_sm_ratings_over_time',
    'Review Over Time': 'reporting_sm_reviews_over_time',
    'Regional Map': 'reporting_socialmedia_regionalmap',
    'Sentiment Count': 'reporting_socialmedia_sentimentcount',
    'Sentiment Trend': 'reporting_socialmedia_sentimenttrend',
    'Category Summary': 'reporting_socialmedia_categorysummary',
    'Trending Topics': 'reporting_socialmedia_trendingtopics',
    'Categories Over Time Period': 'reporting_socialmedia_categorydisplayedovertime',
    'Rating Distribution': 'reporting_sm_ratings_distribution',
    'Reviews Sites Distribution': 'reporting_sm_review_sites_distribution',
    'Top Bottom Performer': 'reporting_sm_top_bottom_performers',
    'Not Responded Reviews': 'reporting_sm_not_responded_reviews',
    'Dealer Profile': 'reporting_sm_dealer_profile',
    'Performance Trend': 'reporting_sm_performance_trend',
  },
  VOC: {
    'Regional Map': 'reporting_vocdashboard_sentimentbyregion',
    'Sentiment Count': 'reporting_vocdashboard_sentiment_count',
    'Sentiment Trend': 'reporting_vocdashboard_sentiment_trend',
    'Category Summary': 'reporting_vocdashboard_category_summary',
    'Trending Topics': 'reporting_vocdashboard_trending_topics',
    'Categories Over Time Period': 'reporting_vocdashboard_categories_displayed_over_time_period',
    'Category Score Correlation': 'reporting_vocdashboard_category_score_correlation',
    'Category Frequency And Score Correlation': 'reporting_vocdashboard_category_frequency_and_score_corelation',
  },
  'Hot Alerts': {
    'Total Alerts': 'reporting_hotalert_totalalerts_widget',
    'Alerts Efficiency': 'reporting_hotalert_alertsefficiency_widget',
    'Top Bottom Performers': 'reporting_hotalert_topbottomperformers_widget',
    AlertEscalations: 'reporting_hotalert_reasonsescalations_widget',
    'Top 5 Alert Resolutions': 'reporting_hotalert_top5resolutions_widget',
    'Re-Contact Customers': 'reporting_re_contact_customers_widget',
  },
  'Day of Week Report': {
    Scores: 'reporting_day_of_week_report_score',
    'RO % Distribution': 'reporting_day_of_week_report_ro_perc_distribution',
    'Overall CSE': 'reporting_day_of_week_report_hace_ace_index',
    'MPI Perc Yes': 'reporting_day_of_week_report_mpi_perc_yes',
    'MPI Yes vs No': 'reporting_day_of_week_report_mpi_yes_vs_no',
    'Impact of MPI': 'reporting_day_of_week_report_impact_of_mpi',
    'MPI Yes': 'reporting_day_of_week_report_mpi_yes',
    'MPI No': 'reporting_day_of_week_report_mpi_no',
    'OC Maint/Yes': 'reporting_day_of_week_report_oc_maint_yes',
    'OC Maint/No': 'reporting_day_of_week_report_oc_maint_no',
  },
  'FRFT Reports': {
    'Frft Overview': 'reporting_frft_widget_frft_overview',
    'Ftft Root Causes': 'reporting_frft_widget_ftft_root_causes',
    'Frft By Model Q6 No': 'reporting_frft_widget_frft_by_model_q6no',
    'Frft By Service Advisor Technicial': 'reporting_frft_widget_frft_by_service_advisor_technicial_q6no',
    'Frft Reasons Technician 3mr': 'reporting_frft_widget_frft_reasons_by_technician_3MR',
    'Frft Customer Comments': 'reporting_frft_widget_frft_customer_comments',
    'Frft Nissan University Certification': 'reporting_frft_widget_frft_nissan_university_certification',
    'Frft Asr Utilization': 'reporting_frft_widget_frft_asr_utilization',
  },
};

export const WidgetCategories = [
  {
    Id: 1,
    Name: 'Global Dashboard',
    show: true,
    permission: 'reporting_globaldashboard',
  },
  // {
  //   Id: 2,
  //   Name: 'Main Dashboard'
  // },
  {
    Id: 3,
    Name: 'Hot Alerts',
    show: true,
    permission: 'reporting_hotalerts',
  },
  {
    Id: 4,
    Name: 'VOC',
    show: true,
    permission: 'reporting_textanalytics',
  },
  {
    Id: 5,
    Name: 'Social Media',
    show: true,
    permission: 'reporting_socialmedia',
  },
];
