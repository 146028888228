import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ChangeDetectorRef,
  Injector,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GlobalFilterHelperService } from '../../global-filters-helper.service';
import { GlobalFilterApiService } from '../../global-filters.api.service';
import { GlobalFilterService } from '../../global-filters.service';
import { GbfBase } from '../gbf-base';
@Component({
  selector: 'app-gbf-list',
  templateUrl: 'global-filter-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .example-viewport {
        height: 200px;
      }
      .example-item {
        height: 50px;
      }
    `,
  ],
})
export class GlobalFilterListComponent extends GbfBase implements OnInit, OnChanges, OnDestroy {
  @Input() active = false;
  @Input() title = '';
  @Input() configName = '';
  @Input() from = 'list';
  @Input() listRootClassName = 'filter__list';
  @Input() breadcrums: any[] = [];
  @Output() onitemclick: any = new EventEmitter();
  @Output() onbreadcrumclick: any = new EventEmitter();
  @Output() offmenu: any = new EventEmitter();
  @ViewChild('ulscroll') scrollBar: any;
  search = '';
  ctx = this;
  subscriptions: Subscription[] = [];

  constructor(
    public injector: Injector,
    public gbfService: GlobalFilterService,
    public gbfHelperService: GlobalFilterHelperService,
    public gbfApiService: GlobalFilterApiService,
    public translateService: TranslateService,
    public cd: ChangeDetectorRef
  ) {
    super(injector, gbfService, gbfHelperService, translateService);
    this.subscriptions.push(
      this.gbfService.gbfChangeState.subscribe((val: { config: any; value: any; for: string }) => {
        if (val.config.name === this.config.name && val.for === 'list') {
          const event = { stopPropagation: () => {}, preventDefault: () => {} };
          if (!this.config.settings.multi) {
            this.itemClicked(event, val.value, 'list');
          } else {
            if (Array.isArray(val.value) && val.value.length > 0) {
              const values = val.value.filter((x) => x.IsChecked);
              this.gbfService[this.config.name] = values;
              this.gbfService.gbfChange.next({ filter: this.config, val: val.value });
              if (this.config.localStorageKey) {
                this.setItemToLocalStorage(this.config.localStorageKey, values);
              }
            }
          }
        }
      }),
      this.gbfService.forceReRender.subscribe((val: any) => {
        this.moveBar();
      })
    );
  }
  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  moveBar() {
    if (this.scrollBar && this.scrollBar?.moveBar) {
      this.scrollBar.moveBar();
      setTimeout(() => {
        this.scrollBar.moveBar();
      }, 0);
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.moveBar();
  }

  async ngOnInit() {
    if (this.gbfApiService.apiConfig[this.config.name] !== undefined && this.config.settings.remote) {
      if (this.gbfApiService[this.config.property] && this.gbfApiService[this.config.property].length <= 0) {
        this.gbfApiService.apiConfig[this.config.name](this.config);
      }
    }
    if (this.from == 'list') {
      await this.setLocalStorageValue();
    }
    this.moveBar();
  }

  breadcrumClick(index: number, item: any) {
    this.onbreadcrumclick.emit({ item, index });
  }

  itemClicked(e: any, valParam: any, el: string) {
    if (el === '__ALL__') {
      this.values.forEach((v: any) => {
        if (v) {
          v.active = false;
        }
      });
      const parsel: any = {};
      const vv = { ...valParam, ...valParam.mProps };
      Object.keys(vv).forEach((k) => {
        if (k !== 'level') {
          parsel[k] = '';
        } else {
          parsel[k] = vv[k];
        }
        if (k === 'label') {
          parsel[k] = 'All';
        }
        if (k === 'name') {
          parsel[k] = '__All__';
        }
        parsel['Brand'] = 'All Brands';
        parsel['Model'] = 'All Models';
        parsel['Year'] = 'All Years';
      });
      this.onitemclick.emit({ items: [parsel], item: parsel, el, pindex: this.pindex });
      return;
    }
    let isRadio = this.config.settings.listStyleType === 'radio' ? true : false;
    if (isRadio && el !== 'crl') {
      return;
    }
    isRadio =
      this.config.settings.listStyleType === 'radio' || this.config.settings.listStyleType === 'none' ? true : false;
    e.stopPropagation();
    this.values.forEach((v: any) => {
      if (v) {
        v.active = false;
      }
    });
    let val = valParam;
    val.active = true;
    if (this.config.settings.multi) {
      if (this.config.settings.partial !== true) {
        val = this.values.filter((x: any) => x && x.IsChecked);
        this.onitemclick.emit({ items: val, item: { ...valParam, ...valParam.mProps }, el });
      } else {
        const tval: any[] = [];
        this.values
          .filter((x: any) => x.id === valParam.id)
          .forEach((x: any) => {
            tval.push({ ...x, ...x.mProps });
          });
        val = tval;
        this.onitemclick.emit({ items: val, item: { ...valParam, ...valParam.mProps }, el });
      }
    } else {
      if (isRadio) {
        el = 'crl';
      }
      this.onitemclick.emit({ items: [val], item: { ...valParam, ...valParam.mProps, isRadio }, el });
    }
    if (this.from === 'list') {
      if (!this.config.settings.listDoneButton) {
        this.gbfService[this.config.name] = val;
        this.gbfService.gbfChange.next({ filter: this.config, val: this.selectedItem });
        if (this.config.localStorageKey) {
          this.setItemToLocalStorage(this.config.localStorageKey, val);
        }
        if (!this.config.settings.multi) {
          this.offmenu.emit(e);
        }
      } else {
        this.selectedItem = val;
      }
    }
    const checkedItems = this.values.filter((x: any) => x.IsChecked);
    if (this.config.name === 'tab') {
      if (!this.config.settings.multi) {
        this.gbfService.gbfTabChange.next(valParam.value);
        localStorage.setItem('gbf_tab', JSON.stringify(valParam));
      } else {
        this.gbfService.gbfTabChange.next(checkedItems);
        localStorage.setItem('gbf_tab', JSON.stringify(checkedItems));
      }
    }

    if (this.from === 'list') {
      this.callApiOn('selection');
    }
  }

  getTitle() {
    if (this.config.settings.multi) {
      let title = '';
      if (
        this.config.title &&
        this.config.title.length > 0 &&
        this.gbfService[this.config.name] &&
        this.gbfService[this.config.name].length > 0
      ) {
        const item = this.gbfService[this.config.name][0];
        this.config.title.forEach((t: string) => {
          if (item[t]) {
            title += this.translateService.instant(item[t]);
          }
        });
        const more = this.translateService.instant('More');
        const isAnySelected = this.values.find((x) => x.IsChecked === true);
        if (this.gbfService[this.config.name].length > 1 && isAnySelected !== undefined) {
          title += ` ${this.translateService.instant('and')} ${
            this.gbfService[this.config.name].length - 1
          } ${more}...`;
        } else if (this.gbfService[this.config.name].length > 0 && isAnySelected !== undefined) {
          title = this.translateService.instant(isAnySelected.label);
        } else {
          title = this.translateService.instant(this.config.placeholder);
        }
      } else {
        title = this.translateService.instant(this.config.placeholder);
      }
      return title;
    } else {
      let title = '';
      if (this.config.title && this.config.title.length > 0 && this.gbfService[this.config.name]) {
        this.config.title.forEach((t: string) => {
          if (this.gbfService[this.config.name] && this.gbfService[this.config.name][t]) {
            title += this.translateService.instant(this.gbfService[this.config.name][t]) + ' ';
          }
        });
      } else {
        title = this.translateService.instant(this.config.placeholder);
      }
      return title;
    }
  }

  selectOrDeSelectAll(event: any, selection: boolean) {
    event.preventDefault();
    event.stopPropagation();
    const config_name = this.configName || this.config.name;
    const disabledValues = this.values
      .filter((hir: any) => {
        if (hir.disabled === true) {
          return hir;
        }
      })
      .filter(Boolean);

    if (disabledValues.length === this.values.length) {
      return;
    }

    this.values = this.values.filter((hir: any) => {
      if (hir) {
        hir.IsChecked = selection;
        return hir;
      }
    });
    const checked = this.values.filter((x: any) => x && x.IsChecked);
    //  const checked = this.values;
    this.gbfService[config_name] = checked;
    if (!this.config.settings.listDoneButton) {
      this.gbfService.gbfChange.next({ filter: this.config, val: checked });
      if (this.config.localStorageKey) {
        this.setItemToLocalStorage(this.config.localStorageKey, checked);
      }
      if (!this.config.settings.multi) {
        this.offmenu.emit('');
      }
    } else {
      this.selectedItem = checked;
    }

    const checkedItems = this.values.filter((x: any) => x.IsChecked);
    if (config_name === 'tab') {
      if (!this.config.settings.multi) {
        // this.gbfService.gbfTabChange.next(valParam.value);
        // localStorage.setItem('gbf_tab', JSON.stringify(valParam));
      } else {
        this.gbfService.gbfTabChange.next(checkedItems);
        localStorage.setItem('gbf_tab', JSON.stringify(checkedItems));
      }
    }

    this.onitemclick.emit({ items: checked, item: this.values[0], type: 'selection', v: selection });
    // this.onitemclick.emit({ items: [this.values[0]], item: this.values[0], type: 'anchor', v: selection });
    this.cd.detectChanges();
  }

  clearSearch(event: Event) {
    if (this.search && this.search.length > 0) {
      this.search = this.search.slice(0, -1);
      event.stopPropagation();
    }
  }

  getSelectionCount() {
    if (!this.values) {
      return '';
    }
    const l = this.values.filter((x: any) => x && x.IsChecked === true).length || 0;
    const count_label = this.translateService.instant('Count');
    return `${count_label} ${l}`;
  }

  getSelectionState() {
    if (!this.values) {
      return false;
    }
    return this.values.find((x: any) => x && (!x.IsChecked || x.IsChecked === false)) ? true : false;
  }
  close(e: any) {
    if (e.stopPropagation && e.preventDefault) {
      e.stopPropagation();
      e.preventDefault();
      this.offmenu.emit(e);
    }
  }
  done(e: any) {
    this.gbfService[this.config.name] = this.selectedItem;
    this.offmenu.emit(e);
    this.gbfService.gbfChange.next({ filter: this.config, val: this.selectedItem });
    if (this.config.localStorageKey) {
      this.setItemToLocalStorage(this.config.localStorageKey, this.selectedItem);
    }
    this.callApiOn('done');
  }
}
