import { Component, OnInit, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import HighCharts from '@clearviewshared/highchart/highchart';
import { Subscription, interval } from 'rxjs';
import { WidgetService } from '../widget.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { generatePerformanceTrends } from '@clearviewshared/highchart/helperfunctions';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import WidgetBase from '../widegt.base';
import { I18nService } from '@app/core/i18n.service';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { ExportService } from '@clearviewshared/services/export.service';
import { FilterService } from 'primeng/api';
import { FiltersService } from '@app/shared/services/filters.service';
import {
  IPerformanceTrendQuestionResponse,
  IPerformanceTrendResponse,
} from '../performance-trend/types/IPerformanceTrendResponse';
import { Permission } from '@clearviewshared/constant/permission';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'dashboard-performance-trend-question-widget',
  templateUrl: './performance-trend-questions.html',
  styleUrls: ['./performance-trend-questions.scss'],
})
export class PerformanceTrendQuestionsComponent extends WidgetBase implements OnInit, OnDestroy, OnChanges {
  routerPrefix = '';
  @Input() makeRequest = true;
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() removeWidgetClass = false;
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @ViewChild('containerPerTreWidget') containerPerTreWidget: ElementRef;
  @Input() setDataFrominput: any = '';
  @Input() allowDropDownShow = true;

  secondsCounter: any;
  secondsCounterSub: Subscription;
  data: Array<IPerformanceTrendQuestionResponse>;
  chartOptions: any = {};
  canRender = false;
  api: Subscription;
  name: string;
  permissions: NgxPermissionsObject;
  clickThroughReport: boolean;

  private subscriptions: Subscription[] = [];
  chart: any;
  kpiNames: any[] = [];

  constructor(
    public toastService: ToastService,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    private appConfig: AppConfigurationService,
    public i18nService: I18nService,
    public filterService: FiltersService,
    public exportService: ExportService,
    public ngxPermission: NgxPermissionsService,
    public gbfService: GlobalFilterService
  ) {
    super(gbfService, translateService);
    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }

      this.secondsCounter = interval(widgetThresholdVal);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    super.ngOnInit();

    this.fillKpiDropDown();

    //this.menuCustom();
    if (this.makeRequest) {
      this.loadWidget();
    } else {
      this.loadWidgetByProp();
    }
    this.subscriptions.push(
      this.cacheService
        .for(this.widgetService.performanceTrendUrl)
        .subscribe((res: Array<IPerformanceTrendQuestionResponse>) => {
          if (res) {
            this.data = this.setDataFrominput === '' ? res : this.setDataFrominput;
            // this.data =  res;
            this.initData();
            this.canRender = true;
          }
        })
    );

    this.subscriptions.push(
      this.widgetService.update.subscribe(() => {
        this.loadWidget();
      })
    );

    this.permissions = this.ngxPermission.getPermissions();
    this.setPermissions();

    // this.secondsCounterSub = this.secondsCounter.subscribe((n: any) => {
    //   // this.loadWidget();
    // });
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }

  fillKpiDropDown() {
    var kpiConfig = this.appConfig.getByIdentifier('performance_trend_widget_kpis');

    if (kpiConfig && kpiConfig['Fields']) {
      var obj = this.filterService.getFilterObject();
      if (obj) {
        let kpisConf: [] = kpiConfig.Fields?.config;
        var kpiList = kpisConf?.filter((f) => f['survey_id'] == obj.SurveyVersion)[0];
        if (kpiList) {
          let list: [] = kpiList['kpis'];
          this.name = kpiList['kpis'][0];
          for (var ii of list) {
            this.kpiNames.push({ label: this.translateService.instant(ii), value: ii });
          }
        }
      }
    }
  }

  loadWidgetByProp() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.setDataFrominput) {
      this.data = this.setDataFrominput;
      this.initData();
      this.widgetService.isAllLoaded = true;
      this.canRender = true;
    }
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.api) {
      this.api.unsubscribe();
    }

    this.api = this.widgetService.getPerformanceTrendQuestion({ apiConfig: this.apiConfig }, this.name).subscribe(
      (res: Array<IPerformanceTrendQuestionResponse>) => {
        if (res) {
          this.data = this.setDataFrominput === '' ? res : this.setDataFrominput;

          // this.data = res;
          this.initData();
          this.widgetService.isAllLoaded = true;
          this.canRender = true;
        }
      },
      (error: any) => {
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      }
    );
  }

  setPermissions() {
    this.clickThroughReport = this.permissions[Permission.REPORT_PERFORMANCE_TREND] ? true : false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fillKpiDropDown();
    super.ngOnChanges(changes);
    if (this.makeRequest) {
      this.loadWidget();
    } else {
      this.loadWidgetByProp();
    }
  }

  // async menuCustom() {
  //   await this.exportService.sendMessageFromGlobalComponent('menu-no-print-export');
  // }

  onKPIChange(event: any) {
    this.loadWidget();
  }

  initData() {
    this.chartOptions = {};
    const monthsList: string[] = [
      this.translateService.instant('Calender_months.jan'),
      this.translateService.instant('Calender_months.feb'),
      this.translateService.instant('Calender_months.mar'),
      this.translateService.instant('Calender_months.apr'),
      this.translateService.instant('Calender_months.may'),
      this.translateService.instant('Calender_months.jun'),
      this.translateService.instant('Calender_months.jul'),
      this.translateService.instant('Calender_months.aug'),
      this.translateService.instant('Calender_months.sep'),
      this.translateService.instant('Calender_months.oct'),
      this.translateService.instant('Calender_months.nov'),
      this.translateService.instant('Calender_months.dec'),
    ];
    const selectedList: string[] = [];
    const seriesData: any = [];
    const checkIfNotThere: any = {};
    if (this.data) {
      // this.data.forEach((data: any) => {
      var records: any[] = null;
      var records2: any[] = null;

      if (Array.isArray(this.data) == true) {
        records = this.data;
      } else {
        records = this.data;
      }
      try {
        records.forEach((kpi: any, index: number) => {
          if (!checkIfNotThere[kpi.code]) {
            if (index > 9) {
              index = 0;
            }
            if (index < 1) {
              if (Array.isArray(kpi)) {
                kpi[0].data.forEach((month: any, ind: number) => {
                  selectedList.push(monthsList[month.Month - 1] + '-' + month.Year.toString().slice(-2));
                });
              } else {
                kpi.data.forEach((month: any, ind: number) => {
                  selectedList.push(monthsList[month.Month - 1] + '-' + month.Year.toString().slice(-2));
                });
              }
            }
            let mapKpiMonths;
            let kpiName = '';

            if (Array.isArray(kpi)) {
              mapKpiMonths = kpi[0].data.map((km: any) => {
                return km.Score !== null ? parseFloat(km.Score.toFixed(1)) : km.Score;
              });
              kpiName = kpi[0].code;
            } else {
              mapKpiMonths = kpi.data.map((km: any) => {
                return km.Score !== null ? parseFloat(km.Score.toFixed(1)) : km.Score;
              });
              kpiName = kpi.code;
            }
            // const mapKpiMonths = kpi.Months.map((km: any) => {
            //   return km.Score !== null ? parseFloat(km.Score.toFixed(1)) : km.Score;
            // });

            seriesData.push({
              marker: {
                enabled: true,
              },
              name: kpiName,
              data: mapKpiMonths,
              events: {
                click: function (event: any) {},
                legendItemClick: function (event: any, b: any, c: any) {},
              },
            });
            checkIfNotThere[kpi.code] = kpi;
          }
        });
        // });

        seriesData.sort((a: any, b: any) => a.name.localeCompare(b.name, 'en', { numeric: true }));

        this.chartOptions = generatePerformanceTrends(seriesData, selectedList, this.i18nService);
        this.chartOptions['chart']['type'] = 'line';
        // tslint:disable-next-line:max-line-length
        if (this.containerPerTreWidget) {
          this.chart = HighCharts['Reflecx'].createOrUpdate(
            this.chart,
            this.containerPerTreWidget.nativeElement,
            this.chartOptions
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
}
