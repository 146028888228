import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { DialogService } from '@primeng';
import { ImageDialogComponent } from '../dialog/image.dialog.component';
import { ImageCroppedEvent } from '../dialog/image-cropper/interfaces';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [DialogService],
})
export class ProfileComponent implements OnInit {
  user: any;
  @Input() service: AuthenticationService;
  @Output() _Close = new EventEmitter();
  @Output() _Submit = new EventEmitter();
  cropable = { enable: true, skippable: false, restrictMode: false, height: 500 };
  language: any;
  imageChangedEvent: any = '';
  image: any = '';
  demoExpiryMessage = '';

  constructor(private toastService: ToastService, public dialogService: DialogService) {}

  ngOnInit() {
    this.user = this.service.getUser();
    this.language = this.user.Markets[0].Languages.find((x: any) => {
      return x.Code == this.user.DefaultLanguage;
    });
    if (this.user.ProfilePic) {
      if (this.user.ProfilePic != null || this.user.ProfilePic != '') {
        this.image = this.user.ProfilePic;
      }
    }

    const dMessage = localStorage.getItem('DemoExpiryMessage');
    if (dMessage != undefined && dMessage != '') {
      this.demoExpiryMessage = dMessage;
    }
  }

  close() {
    this._Close.emit(false);
  }

  save(isNewPic: boolean) {
    if (isNewPic) {
      this._Submit.emit(this.image);
    } else {
      this._Submit.emit();
    }
  }

  openFileBrowse(file: HTMLInputElement) {
    file.value = '';
    file.click();
  }

  fileChangeEvent(event: any): void {
    if (event.target.files.length == 0) {
      return;
    }
    if (event.target.files[0].type.indexOf('image') < 0) {
      this.toastService.error('Please upload only image files');
      return;
    }
    this.imageChangedEvent = event;
    this.showImageDialog();
  }

  showImageDialog() {
    const dialogRef = this.dialogService.open(ImageDialogComponent, {
      data: {
        imageEvent: this.imageChangedEvent,
        cropSettings: this.cropable,
      },
      closable: false,
      styleClass: 'ui-dialog--small',
    });
    dialogRef.onClose.subscribe((result: any) => {
      if (result) {
        this.image = result;
        this.save(true);
      }
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.image = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}
