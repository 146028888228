<app-widget-dynamic [rootClass]="rootClass" [styleClass]="styleClass" [widgetTitle]="widgetTitle"
  [clickThrough]="clickThrough" [widgetTitleToolTip]="widgetTitleToolTip">
  <ng-container widgetHeader>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
    <div class="widget-node widget-node__print" *ngIf="allowDropDownShow">
      <p-dropdown [autoZIndex]="false" class="cba-ctrl" [options]="kpiNames"
        placeholder="{{'Service Advisor' | translate }}" (onChange)="onKPIChange($event)" [(ngModel)]="name"
        [style]="{'width':'200px'}">
      </p-dropdown>
    </div>

  </ng-container>
  <div class="card-table" style="margin-top: 25px">
    <cvp-scrollPanel [style]="{width: '100%', height: '500px'}">
      <div class="nodata-wrapper" [hidden]="showsWidgets">
        <div class="nodata">
          <i class="nodata__icon fal fa-info-circle"></i>
          <p class="nodata__msg">{{ 'Please select a Dealer from the Hierarchy Filter' | translate }}</p>
        </div>
      </div>
      <div class="nodata-wrapper" *ngIf="noResults">
        <div class="nodata">
          <i class="nodata__icon fal fa-info-circle"></i>
          <p class="nodata__msg">{{'No Result Found' |translate}}</p>
        </div>
      </div>
      <div [hidden]="noResults">
        <div [hidden]="!showsWidgets">
          <table class="widget__top">
            <tbody>
              <tr>
                <th *ngFor="let col of cols">
                  <ng-container *ngIf="col.field == 'EmplpoyeeName' || col.field == 'CertificationStatus'">
                    <div>
                      {{ col.header }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="col.field == 'Tenure'">
                    <div style="text-align: center;">
                      {{ col.header }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="col.field == 'TechnicianLevel' && this.name=='Service Technician'">
                    <div style="text-align: center;">
                      {{ col.header }}
                    </div>
                  </ng-container>
                </th>
              </tr>
              <tr *ngFor="let yoy of Certification; let i = index"
                [style.background-color]="i % 2 === 0 ? '#F6F6F6' : 'white'">
                <td *ngFor="let col of cols">
                  <div>
                    <ng-container *ngIf="col.field == 'CertificationStatus'">
                      <div [style.color]="yoy['CertificationStatus'] == 'Certified' ? '#478c32' : '#d94343'">
                        {{ yoy[col.field] }}
                      </div>
                    </ng-container>
                    <ng-container *ngIf="col.field == 'EmplpoyeeName'">
                      <div>
                        {{ yoy[col.field] }}
                      </div>
                    </ng-container>
                    <ng-container *ngIf="col.field == 'Tenure'">
                      <div style="text-align: center;" [style.color]="(yoy['Tenure'] == '0') ? '#d94343' : '#478c32'">
                        {{ yoy[col.field] }}
                      </div>
                    </ng-container >
                    <ng-container *ngIf="col.field == 'TechnicianLevel' && this.name=='Service Technician'">
                      <div >
                        {{ yoy[col.field] }}
                      </div>
                    </ng-container>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </cvp-scrollPanel>
  </div>
</app-widget-dynamic>
