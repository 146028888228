import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { ChartBase } from '../chart.base';
import * as HighCharts from 'highcharts';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { ReportGeneratorHelperService } from '../../report-generator-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/core';

@Component({
  selector: 'appc-report-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent extends ChartBase implements OnInit, AfterViewInit {
  @Output() stepEmitter = new EventEmitter();
  @ViewChild('bar', { read: ElementRef }) bar: ElementRef;
  chart: any;
  nextlabel = '';
  backlabel = '';
  // chunk=4;
  constructor(
    public toaster: ToastService,
    private rgHelper: ReportGeneratorHelperService,
    public translateService: TranslateService,
    public i18nService: I18nService
  ) {
    super(translateService);
  }
  ngAfterViewInit(): void {
    if (this.module !== 'RG') {
      this.step = 'graph';
      const onlyChecked = this.chartConfig;
      if (onlyChecked.length === 0) {
        this.toaster.info(this.translateService.instant('Please check at least one field.'));
        return;
      }
      //const ModelData: any = this.makeChart(onlyChecked, this.i18nService);
      this.nextOrBack(onlyChecked);
      //this.chart = null;
      //this.chart = new HighCharts.Chart(this.bar.nativeElement, ModelData);
      //this.chart = HighCharts['Reflecx'].createOrUpdate(this.chart, this.bar.nativeElement, ModelData);
    }
  }
  ngOnInit(): void {
    super.OnInit();
    this.nextlabel = this.translateService.instant('Next');
    this.backlabel = this.translateService.instant('Back');
    // this.chart = new HighCharts.Chart(this.line.nativeElement, dataModel);
  }
  nextOrBack(e: any) {
    if (this.step === 'configuration') {
      const onlyChecked = this.fields.filter((field: any) => field.checked);
      if (onlyChecked.length === 0) {
        this.toaster.info('Please check at least one field.');
        return;
      }
      this.step = 'graph';
      this.rgHelper.chartConfig = onlyChecked;
      if (onlyChecked.length < this.Max_Level) {
        const ModelData: any = this.makeChart(onlyChecked, this.i18nService);
        this.chart = new HighCharts.Chart(this.bar.nativeElement, ModelData);
      } else {
        this.chart = new HighCharts.Chart(this.bar.nativeElement, {});
        this.toaster.info(this.translateService.instant(this.Max_Limit_Error_Key));
      }
    } else {
      this.step = 'configuration';
    }
    this.stepEmitter.emit(this.step);
  }
  makeChart(fields: any, i18nService: I18nService) {
    const labels: any = [];
    const fieldWiseData: any = [];
    fields.forEach((field: any) => {
      const val = field.value;
      const fieldMeta = super.getFieldByKey(val);
      const data = this.records.map((record: any) => {
        let dval = typeof record[field.field] === 'number' ? parseFloat(record[val]) : parseFloat(record[val]);
        if (isNaN(dval) || dval == -999999) {
          dval = 0;
        }
        const label = this.getLabel(record);

        if (labels.indexOf(label) === -1) {
          labels.push(label);
        }
        return { name: label, y: dval, chartType: field.chartTypeLine, field: val, record, fieldMeta: fieldMeta };
      });
      fieldWiseData.push(data);
    });
    const series = fieldWiseData.map((fw: any) => {
      const first = fw[0];
      const title =
        first.fieldMeta.MutableProps.Label !== '' ? first.fieldMeta.MutableProps.Label : first.fieldMeta.Translate;
      const data = fw.map((f: any) => {
        return { name: f.name, y: f.y };
      });
      return {
        name: title,
        data: [...data],
      };
    });
    const model = {
      chart: {
        type: 'bar',
        /*events: {
          load: function () {
            let categoryHeight = 25;
            this.update({
              chart: {
                height: categoryHeight * this.pointCount + (this.chartHeight - this.plotHeight),
              },
            });
          },
        },*/
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return i18nService.transformNumber(this.point.y);
            },
          },
        },
      },
      legend: {
        verticalAlign: 'bottom',
        align: 'center',
        x: 0,
        y: 0,
        navigation: {
          enabled: false,
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: labels,
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.x + '</b>: ' + i18nService.transformNumber(this.y.toFixed(1));
        },
      },
      series: [...series],
    };
    return model;
  }
}
