import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FiltersService } from '@app/shared/services/filters.service';
import { GlobalFilterConfigService } from './global-filters-config.service';
import { GlobalFilterService } from './global-filters.service';
import { GlobalFilterHelperService } from './global-filters-helper.service';
import { GlobalFilterApiService } from './global-filters.api.service';
import { WidgetService } from '../../../../../projects/cvreporting/src/app/features/cvreporting/widgets/widget.service';
import { HeaderTabService } from '@app/shared/services/header-tab.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Subscription, merge, delay } from 'rxjs';
import { NgForageCache } from 'ngforage';
import { AuthenticationService } from '@app/core';

@Component({
  selector: 'app-gbf-filter',
  templateUrl: 'global-filters.component.html',
  styleUrls: ['global-filters.component.scss'],
})
export class GlobalFilterComponent implements OnInit, OnDestroy {
  @ViewChild('cmp', { static: false }) cmp: any;
  configs: any = [];
  activeState = {};
  tabs: any[] = [];
  activeFilter: any;
  subscriptions: Subscription[] = [];
  ngForage: NgForageCache;
  constructor(
    private authService: AuthenticationService,
    public headerTabService: HeaderTabService,
    public filtersService: FiltersService,
    public translateService: TranslateService,
    public gbfService: GlobalFilterService,
    public widgetService: WidgetService,
    public gbfApiService: GlobalFilterApiService,
    public gbfHelperService: GlobalFilterHelperService,
    public gbfConfig: GlobalFilterConfigService,
    private router: Router,
    public injector: Injector
  ) {
    const payload = {};
    this.ngForage = this.injector.get(NgForageCache);
    this.subscriptions.push(
      gbfService.gbfDateChange.subscribe((date: string) => {
        this.handleDateChange(date);
      }),
      merge(this.gbfService.gbfSaveConfiguration, this.gbfService.gbfTabChange)
        .pipe(delay(2000))
        .subscribe((result) => {
          this.gbfConfig.filterConfig.config.map(async (value: any) => {
            let localVal = null;
            if (value?.database === 'indexdb') {
              localVal = await this.ngForage.getItem(value.localStorageKey);
            } else {
              localVal = localStorage.getItem(value.localStorageKey);
            }
            if (localVal) {
              payload[value.localStorageKey] = localVal;
            }
          });
          payload['gbf_date_identifier'] = localStorage.getItem('gbf_date_identifier');
          this.gbfApiService.saveConfiguration(payload, 'Global Filter', 'gf');
        })
    );
  }
  handleDateChange(date: any) {
    let dates = date.dates;
    if (!Array.isArray(date.dates)) dates = date.dates.split('|');
  }
  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });
    }
  }
  isMultiTabAllowed() {
    const localUrl = this.gbfService.currentUrl;
    const filterConfig = this.gbfConfig.filterConfig;
    const overRideFilters = filterConfig.pageConfig[filterConfig.pageUrlPrefix + localUrl];
    if (!overRideFilters?.multiSurvey) {
      let surveyVersion = [];
      try {
        surveyVersion = JSON.parse(localStorage.getItem('surveyVersions') || '[]');
      } catch (error) {}
      const tab = JSON.parse(localStorage.getItem('gbf_tab'));
      if (tab?.value?.split(',').length > 1 || this.gbfService?.tab?.value?.split(',').length > 1) {
        this.gbfService.tab = this.gbfService.current_tabs[0];
        if (this.gbfService.tab) {
          this.gbfService.gbfTabChange.next(this.gbfService.tab.value);
          const version = surveyVersion.find((x: any) => x.Event === this.gbfService.current_tabs[0].value);
          if (version && version.value) {
            this.gbfService.version = {
              label: version.Event,
              value: version.value,
              version: version.value,
              versionName: version.Version,
            };
            localStorage.setItem('gbf_version', JSON.stringify(this.gbfService.version));
          }
          localStorage.setItem('gbf_tab', JSON.stringify(this.gbfService.tab));
        }
      }
    }
  }
  ngOnInit(): void {
    if (this.gbfService.currentUrl.includes('?')) {
      this.gbfService.currentUrl = this.gbfService.currentUrl.split('?')[0];
    }
    let localUrl = this.gbfService.currentUrl;
    if (localUrl.indexOf('/PublishedReports/') > 0) {
      localUrl = localUrl.substr(0, localUrl.lastIndexOf('/'));
    }
    const filterConfig = this.gbfConfig.filterConfig;
    this.activeState = this.gbfConfig.filterConfig.activeState;
    let configs = this.gbfConfig.filterConfig.pageConfig['default']['filter'];
    let tabs = this.gbfConfig.filterConfig.tabs;
    const overRideFilters = filterConfig.pageConfig[filterConfig.pageUrlPrefix + localUrl];
    let overrideFilterSettings = {};
    if (overRideFilters) {
      if (overRideFilters['filter']) {
        configs = overRideFilters['filter'];
      }
      if (overRideFilters['tabs']) {
        tabs = Array.isArray(overRideFilters['tabs']) ? overRideFilters['tabs'] : tabs;
      }
      if (overRideFilters['overrideFilterSettings']) {
        overrideFilterSettings = overRideFilters['overrideFilterSettings'] || {};
      }
    }
    if (tabs) {
      this.gbfService.current_tabs = tabs.map((x: any) => {
        return {
          ...x,
          label: this.translateService.instant(x.label),
          command: (e: any) => this.onTabSelect(e, x),
        };
      });
    }
    let isMultiOrg = false;
    this.gbfService.isMultiOrg = isMultiOrg;
    this.gbfService.currentAllowedFilters = configs;
    if (configs && Array.isArray(configs)) {
      const configToBeLoad: any[] = [];
      configs.forEach((configName: any) => {
        const found = cloneDeep(filterConfig.config.find((x: any) => x.name === configName));
        if (found) {
          let settings = found.settings || {};
          if (overrideFilterSettings[configName]) {
            found.settings = { ...settings, ...overrideFilterSettings[configName] };
          }
          if (found.settings && !found.settings.remote) {
            found.values = this.gbfApiService.getCheckedValues(found.name, found.values, found.settings.multi);
            configToBeLoad.push(found);
          } else {
            configToBeLoad.push(found);
          }
        }
        if (configName === 'multi_org') {
          isMultiOrg = true;
        }
      });
      this.gbfService.current_configs = configToBeLoad;
    }
    this.isMultiTabAllowed();
    this.gbfService.isMultiOrg = isMultiOrg;
    this.gbfService.gbfAllSet.next(true);
    this.gbfService.getSelectedOrgLevel();
  }
  getActiveItem() {
    if (this.gbfService.current_tabs && this.gbfService.current_tabs.length > 0) {
      const p = this.gbfService.tab;
      if (p) {
        return this.gbfService.current_tabs.find((x) => x.label === this.translateService.instant(p.label));
      }
    }
  }
  onTabSelect(e: any, tab: any) {
    e.originalEvent.preventDefault();
    if (tab.url) {
      let url = tab.url;
      const variables = tab.url.match(/\{\{([A-z, \d]+)\}\}/g);
      if (Array.isArray(variables) && variables.length > 0) {
        variables.forEach((variable: string) => {
          const rvar = variable.replace(/[{{}} ]/g, '');
          const localval = localStorage.getItem(rvar);
          if (localval) {
            url = url.replaceAll(variable, localval);
          } else {
            url = url.replaceAll(variable, '');
          }
        });
      }
      window.open(url, tab.target || '_self');
      return;
    } else {
      this.gbfService.tab = tab;
      localStorage.setItem('gbf_tab', JSON.stringify(tab));
      if (this.authService.authData.ClientName === 'infiniti') {
        const isBulletinModule = this.gbfService?.currentUrl === '/insights/bu';
        const bulletinTab = tab;
        if (isBulletinModule) {
          tab = JSON.parse(localStorage.getItem('gbf_tab_bulletin'));
          localStorage.setItem('gbf_tab', JSON.stringify(bulletinTab));
        }
        const allSurveyVersions = JSON.parse(localStorage.getItem('surveyVersions'));
        const versionQP = allSurveyVersions.find((v: any) => v.Event == tab.value && v.label === 'QP');
        const seletedVersion = JSON.parse(localStorage.getItem('gbf_version')).label;
        const version = allSurveyVersions.find((v: any) => v.Event == tab.value && v.label === seletedVersion);
        this.gbfService.setVersionQP({
          label: versionQP?.Version,
          value: versionQP?.value,
          version: versionQP?.value,
          versionName: versionQP?.Version,
        });

        this.gbfService.setSurveyVersion({
          label: version?.SurveyLabel || version?.Version,
          value: version?.SurveyId,
          version: version?.SurveyId,
          versionName: version?.Version,
        });
      }
      this.gbfService.gbfTabChange.next(tab.value);
      // Todo: will handle from config
      localStorage.removeItem('gbf_survey_response');
      localStorage.removeItem('gbf_survey_question');
    }
  }
  toggleMenu(e: any, config: any) {
    const filter = config.name || '';
    if (typeof config !== 'string' || this.activeState[filter] !== undefined) {
      this.activeFilter = filter;
      e.stopPropagation();
      if (config.settings && config.settings.listDoneButton) {
        this['temp_' + config.name] = {
          listButtonDone: config.settings.listDoneButton,
          property: config.property,
          value: cloneDeep(this.gbfHelperService[config.property]),
        };
      }
      e.init = false;
      this.setAllInactive(e).then(() => {});
      document.body.click();
      let isOverlayPanel = document.getElementsByClassName('p-overlaypanel-content');
      if (isOverlayPanel && isOverlayPanel.length !== 0 && this.router.url == '/insights/rg') {
        let a: any = document.getElementsByClassName('report-generator');
        a[0].click();
      }
      this.activeState[filter] = !this.activeState[filter];
    } else {
      this.setAllInactive(e).then(() => {});
    }
    this.gbfService.buildOrgSuggestions(this.gbfService.getSelectedOrgLevel() + 1);
  }
  mapConfigValues() {
    this.gbfService.current_configs = this.gbfService.current_configs.map((config: any) => {
      if (config.localStorageKey && this.gbfConfig.filterConfig.initialStates[config.name]) {
        if (!localStorage.getItem(config.localStorageKey)) {
          config = this.gbfService.restoreLocalStorageValues(config);
        }
      }
      return this.mapValues(config);
    });
  }
  mapValues(config: any): any {
    const inMemory = this.gbfService[config.name];
    if (!config?.settings?.remote && Array.isArray(config.values)) {
      config.values = config.values.map((val: any) => {
        if (config.settings.multi && Array.isArray(inMemory)) {
          const found = inMemory.find((x) => x.value === val.value);
          if (found) {
            val.IsChecked = true;
          } else {
            val.IsChecked = false;
          }
        }
        return val;
      });
    }
    return config;
  }

  setAllInactive(e: any) {
    return new Promise((resolve, reject) => {
      Object.keys(this.activeState).forEach((key: string) => {
        if (
          this.activeFilter === key &&
          e.init !== false &&
          !e.done &&
          this['temp_' + key] &&
          this['temp_' + key].property &&
          this['temp_' + key].listButtonDone
        ) {
          this.gbfHelperService[this['temp_' + key].property] = this['temp_' + key].value;
          this.gbfService.gbfResetState.next(key);
        }
        this.activeState[key] = false;
      });
      resolve(true);
    });
  }
  applyFilter(e: any) {
    this.setAllInactive(e).then(() => {
      this.gbfService.getSelectedOrgLevel();
      setTimeout(() => {
        this.gbfService.gbfOnApplyFilter.next({ rand: Math.random() * 100 });
        this.gbfService.gbfSaveConfiguration.next(true);
      }, 0);
      // this.widgetService.update.next({});
    });
  }
}
