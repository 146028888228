<app-widget-dynamic
  [rootClass]="'card-xl'"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <ng-container widgetArea>
    <div class="fullpage--widget" [attr.data-can-render]="canRender">
      <div
        class="main-kpi kpi--threecol"
        [ngClass]="{
          'kpi-twocol': kpiData.length === 2,
          'kpi--threecol': kpiData.length === 3,
          'kpi--fourcol': kpiData.length === 4,
          'kpi--fivecol': kpiData.length === 5
        }"
      >
      <ng-container  *ngFor="let kpi of kpiData">

        <dial-meter
          [data]="kpi.data"
          [dialsLength]="kpiData.length || 0"
          [rank]="kpi.rank"
        > 
        <ng-container *ngIf="kpi.bottomDetails !== null && kpi.bottomDetails !== undefined 
                    && kpi.bottomDetails.Label !== null && kpi.bottomDetails.Label !== '' 
                    && kpi.bottomDetails.Label !== undefined">
          <div class="kpi__footer">
            <p> {{kpi.bottomDetails.Label | translate}} : <a class="pField"  (click)="route($event, kpi.bottomDetails)">
              <span>{{kpi.bottomDetails.Value}}</span></a
            ></p>
          </div>
      </ng-container>
      </dial-meter>


      </ng-container>
        
      </div>
    </div>
  </ng-container>
</app-widget-dynamic>
