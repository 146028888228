import { Directive, HostListener, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelperService } from '@clearviewshared/services/helper.service';

@Directive({
  selector: '[appResourceDownload]',
})
export class ResourceDownloadDirective {
  constructor(private httpClient: HttpClient, private helperService: HelperService) {}

  // tslint:disable-next-line: member-ordering
  @Input()
  resource: { filePath: string; fileName: string; enableDownload: boolean };

  @HostListener('click', ['$event']) onClick($event: any) {
    if (this.resource.enableDownload) {
      this.downloadResource(this.resource.filePath, this.resource.fileName);
    }
  }

  downloadResource(filepath: string, fileName: string) {
    const url = `/resources/get`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.httpClient
      .post(url, { Path: filepath }, { responseType: 'arraybuffer', observe: 'response', headers: headers })
      .subscribe((res) => {
        this.helperService.downloadResource(res, fileName);
      });
  }
}
