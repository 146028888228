import { Component } from '@angular/core';

@Component({
  selector: 'app-widget-spinner',
  template: `
    <div class="temp-loader">
      <a class="handler more" (click)="fireClickThrough($event)" href="javascript:void(0)">
        <p-progressSpinner [style]="{ width: '22px', height: '22px' }" strokeWidth="9" animationDuration=".5s">
        </p-progressSpinner>
      </a>
    </div>
  `,
})
export class WidgetSpinnerComponent {
  constructor() {}

  fireClickThrough(e: any) {
    console.log(e);
  }
}
