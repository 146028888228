<div class="modal-form mCustomScrollbar secondary--theme">
  <div class="row">
    <h3 translate>My Profile</h3>
    <span *ngIf="demoExpiryMessage !='' && demoExpiryMessage!= undefined" class="profile-notification"> <i class="far fa-info-circle"></i> {{ demoExpiryMessage }}</span>
  </div>
  <div class="row photo--row">
    <div class="col-left">
      <div class="upload-field upload-photo">
        <div class="field__controls">
          <div class="profile-photo">
            <i *ngIf="image == ''" class="fas fa-user-circle"></i>
            <img *ngIf="image != ''" src="{{ image }}" />
          </div>
          <!-- <img [src]="image" /> -->
          <label>
            <span class="btn" (click)="openFileBrowse(imageFile)"> <i class="fas fa-camera"></i> </span>
          </label>
        </div>
      </div>
    </div>
    <div class="col-right">
      <div class="info">
        <h4>{{ user.FirstName + ' ' + user.LastName }}</h4>
        <p>{{ user.Role }}</p>
      </div>
    </div>
  </div>

  <input #imageFile type="file" (change)="fileChangeEvent($event)" accept="image/*" style="display: none" />

  <!-- <div class="row profile--row">
    <div class="col-left"><label>Full Name:</label></div>

    <div class="col-right">
      <div class="info">
        <p>{{ user.FirstName + " " + user.LastName }}</p>
      </div>
    </div>
  </div> -->
  <div class="row profile--row" *ngIf="user?.Email">
    <div class="col-left">
      <label>{{ 'Email' | translate }}:</label>
    </div>

    <div class="col-right">
      <div class="info">
        <p class="color-blue">{{ user.Email }}</p>
      </div>
    </div>
  </div>
  <div class="row profile--row">
    <div class="col-left">
      <label>{{ 'Role' | translate }}:</label>
    </div>

    <div class="col-right">
      <div class="info">
        <p>{{ user.Role }}</p>
      </div>
    </div>
  </div>

  <div class="row profile--row" *ngIf="language?.DisplayName">
    <div class="col-left">
      <label>{{ 'Language' | translate }}:</label>
    </div>

    <div class="col-right">
      <div class="info">
        <p>{{ language?.DisplayName }}</p>
      </div>
    </div>
  </div>
</div>
