<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div
      class="toggle widget-node widget-node__print"
      [attr.disabled]="isAspectCategorySelected"
      (click)="disableCtrl()"
    >
    <span  pInputText pTooltip="{{'Graph displays % of selected reasons whenever QP Q6 is responded with a No' |translate}}" tooltipPosition="top" style="border: none;">
      <i class="fal fa-question-circle icon-question"></i>
    </span>
      <cvc-inputSwitch
        onLabel="{{'Service Advisor' | translate}}"
        offLabel="{{'Technician' | translate}}"
        [disabled]="isAspectCategorySelected || !canRender|| isToggleDisabled"
        (onChange)="toggleChange()"
        [(ngModel)]="selecteditem"


      >
      </cvc-inputSwitch>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>

  <div widgetArea class="widget__content">
    <div class="widget__graph">
      <div class="nodata-wrapper" [hidden]="showsWidgets">
        <div class="nodata">
          <i class="nodata__icon fal fa-info-circle"></i>
          <p class="nodata__msg">{{ 'Please select a Dealer from the Hierarchy Filter' | translate }}</p>
        </div>
      </div>
      <div [hidden]="!showsWidgets">
        <div #containerFrftRootCauses ></div>
      </div>
    </div>
  </div>

</app-widget-dynamic>
