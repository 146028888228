<li class="filter__item flex4 survey-score-container">
  <!-- <label class="filter__label">All Models</label> -->
  <div class="filter-field">
    <div class="filter__trigger">
      <label class="field__label"
        >{{ surveyscore | translate }}:
        {{ scoreval[0] + ' - ' + scoreval[1] }}</label
      >
      <div class="field__icons">
        <span class="icons__active"><i class="fal fa-pencil"></i></span>
        <span class="icons__active icon--ok"><i class="fal fa-check"></i></span>
        <i class="far fa-angle-down"></i>
      </div>
    </div>
    <div class="filter-dropdown dropdown--surveyscore" [ngClass]="{ active: active }">
      <div class="survey-score-heading">
        {{ surveyscore | translate }}:
        {{ scoreval[0] + ' - ' + scoreval[1] }}
      </div>
      <ul class="survey-score-ranges">
        <li
          (click)="silderBarRange('Promoters', 90, 100)"
          [class.active]="type === 'Promoters'"
        >
          {{ 'Promoters' | translate }}
          <span class="survey-score-range-value">{{getRange(90,100)}}</span>
        </li>
        <li
          (click)="silderBarRange('Passives', 70, 89)"
          [class.active]="type === 'Passives'"
        >
          {{ 'Passives' | translate }} <span class="survey-score-range-value">{{getRange(70,89)}}</span>
        </li>
        <li
          (click)="silderBarRange('Detractors', 0, 69)"
          [class.active]="type === 'Detractors'"
        >
          {{ 'Detractors' | translate }}
          <span class="survey-score-range-value">{{getRange(0,69)}}</span>
        </li>
      </ul>
      <ng-container>
        <p-slider
          [(ngModel)]="scoreval"
          [min]="min"
          [max]="max"
          [step]="1"
          (onChange)="changeSlider($event)"
          [range]="true"
        >
        </p-slider>
      </ng-container>
    </div>
  </div>
</li>
