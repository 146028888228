import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { HeaderItems } from '@app/core/types/headerItems';
import { environment } from '@env/environment';
import { AuthenticationService } from '@app/core';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import { AppConfig } from 'src/global.config';

@Injectable({
  providedIn: 'root',
})
export class HeaderTabService {
  headerItems: HeaderItems[];
  EditModeObservor: Subject<boolean> = new Subject<boolean>();
  activeItem = 'Global Dashboard';
  headerlist: any;
  public headerTabs: Subject<HeaderItems[]> = new Subject<HeaderItems[]>();
  public headerReportModule: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public headerReportActiveRemove: BehaviorSubject<boolean> = new BehaviorSubject(false);
  permissions: NgxPermissionsObject;
  activeModule = '';
  customizeWidgetPayload: any = {};
  constructor(
    private authService: AuthenticationService,
    public ngxPermission: NgxPermissionsService,
    private appConfig: AppConfigurationService
  ) {
    this.permissions = ngxPermission.getPermissions();
    this.headerlist = this.appConfig.getByIdentifier(Identifiers.HeaderListOrder);
    //this.activeItem = this.headerlist.Fields.Dashboard[0];
  }

  public getHeaderItems(): Observable<HeaderItems[]> {
    return this.headerTabs.asObservable();
  }

  public setHeaderItems(tabs: HeaderItems[]): void {
    this.headerItems = tabs;
    this.headerTabs.next(tabs);
  }

  public getHeaderTabs() {
    return this.headerItems;
  }
  createReportingHeader(activeModule: string) {
    const headers: HeaderItems[] = [];
    if (this.headerlist) {
      if (this.headerlist.ClientConfig === null) {
        this.headerlist.ClientConfig = this.headerlist;
      }
      if (this.headerlist.ClientConfig) {
        this.headerlist.ClientConfig.Fields.Dashboard.forEach((header: any) => {
          if (header === 'Global Dashboard' && this.permissions[Permission.MENU_GLOBAL_DASHBOARD] ? true : false) {
            headers.push({
              active: activeModule === 'Global Dashboard',
              //active: false,
              icon: 'fal fa-tachometer-alt',
              html: 'Dashboard',
              name: 'Dashboard',
              anchorId: 'app.globaldashboard',
              module: 'dashboard',
              url: `/${environment.reportingRoutePrefix}/dashboard`,
            });
          } else if (header === 'Hot Alerts' && this.permissions[Permission.MENU_HOTALERT] ? true : false) {
            headers.push({
              active: activeModule === 'Hot Alerts',
              //active: false,
              icon: 'fal fa-exclamation-circle',
              html: 'Alerts',
              name: 'Alerts',
              anchorId: 'app.hotalert',
              module: 'alerts',
              url: `/${environment.reportingRoutePrefix}/alerts`,
            });
          } else if (header === 'Reporting' && this.permissions[Permission.MENU_REPORTS_SECTION] ? true : false) {
            headers.push({
              active: activeModule === 'Reporting',
              //active: false,
              icon: 'fal fa-list-alt',
              html: 'Reports',
              name: 'Reports',
              anchorId: 'app.reporting',
              module: 'reports',
              url: `/${environment.reportingRoutePrefix}/reports`,
            });
          } else if (
            header === 'Report Generator' && this.permissions[Permission.MENU_REPORT_GENERATOR] ? true : false
          ) {
            headers.push({
              active: activeModule === 'Report Generator',
              //active: false,
              icon: 'fal fa-sliders-v',
              html: 'Report <br/> Generator',
              name: 'Report Generator',
              anchorId: 'app.reportgenerator',
              module: 'rg',
              url: `/${environment.reportingRoutePrefix}/rg`,
            });
          } else if (header === 'Cross Tabs' && this.permissions[Permission.MENU_CROSSTAB] ? true : false) {
            headers.push({
              active: activeModule === 'Cross Tabs',
              //active: false,
              icon: 'fal fa-book',
              html: 'Cross <br/> Tabs',
              anchorId: 'app.crosstab',
              name: 'Cross Tabs',
              module: 'ct',
              url: `/${environment.reportingRoutePrefix}/ct`,
            });
          } else if (header === 'Bulletin' && this.permissions[Permission.MENU_BULLETIN] ? true : false) {
            headers.push({
              active: activeModule === 'Bulletin',
              //active: false,
              icon: 'fal fal fa-window-restore',
              html: 'Bulletin',
              anchorId: 'app.bulletin',
              name: 'Bulletin',
              module: 'bu',
              url: `/${environment.reportingRoutePrefix}/bu`,
            });
          } else if (header === 'Coach' && this.permissions[Permission.REPORT_COACH] ? true : false) {
            headers.push({
              active: activeModule === 'Coach',
              //active: false,
              icon: 'fal fa-window-restore',
              html: 'Coach <br/> Tabs',
              anchorId: 'app.coach',
              name: 'Coach',
              module: 'coach',
              url: `/${environment.reportingRoutePrefix}/coach`,
            });
          } else if (header === 'Appeals' && this.permissions[Permission.MENU_APPEALS] ? true : false) {
            headers.push({
              active: activeModule === 'Appeals',
              //active: false,
              icon: 'fal fa-clipboard-list',
              html: 'Appeals',
              name: 'Appeals',
              anchorId: 'app.appeal',
              module: 'appeal',
              url: `/${environment.reportingRoutePrefix}/appeal`,
            });
          } else if (header === 'VOC' && this.permissions[Permission.REPORT_TEXTANALYTIC_VOCDASHBOARD] ? true : false) {
            headers.push({
              active: activeModule === 'VOC',
              //active: false,
              icon: 'fal fa-chart-line',
              html: 'Text Analytics',
              anchorId: 'app.voc',
              name: 'Text Analytics',
              module: 'voc',
              url: `/${environment.reportingRoutePrefix}/voc`,
            });
          } else if (header === 'Social Media' && this.permissions[Permission.MENU_SOCIALMEDIA] ? true : false) {
            headers.push({
              active: activeModule === 'Social Media',
              //active: false,
              icon: 'fal fa-users',
              html: 'Reputation <br/> Management',
              name: 'Reputation Management',
              anchorId: 'app.socialmedia',
              module: 'rm',
              url: `/${environment.reportingRoutePrefix}/rm`,
            });
          } else if (header === 'VOC Demo' && this.permissions[Permission.MENU_VOCDEMO] ? true : false) {
            headers.push({
              active: activeModule === 'VOC Demo',
              //active: false,
              icon: 'fal fa-chart-line',
              html: 'VOC <br/> Demo',
              name: 'VOC Demo',
              anchorId: 'app.vocdemo',
              module: 'vocdemo',
              url: `/${environment.reportingRoutePrefix}/vocdemo`,
            });
          }
        });

        let activeItems = headers.find((r) => r.active === true);
        if (activeItems === null || activeItems === undefined) {
          headers[0].active = true;
        }
      }
    }
    // if (this.permissions[Permission.MENU_MAIN_DASHBOARD] ? true : false) {
    //   headers.push({
    //     active: activeModule === 'Main Dashboard',
    //     icon: 'fal fa-th-large',
    //     html: 'Main <br/> Dashboard',
    //     name: 'Main Dashboard',
    //     anchorId: 'app.maindashboard',
    //     url: `${environment.reportingRoutePrefix}/maindashboard`
    //   });
    // }

    // if (this.permissions[Permission.MENU_TEXT_ANALYTICS] ? true : false) {
    //   headers.push({
    //     active: activeModule === 'Text Analytics',
    //     icon: 'fal fa-file',
    //     html: 'Text <br/> Analytics',
    //     name: 'Text Analytics',
    //     anchorId: 'app.textanalytic',
    //     url: `/${environment.reportingRoutePrefix}/textanalytics`
    //   });
    // }

    // if (this.permissions[Permission.MENU_HOTALERT] ? true : false) {
    //   headers.push({
    //     active: activeModule === 'Social-VOC',
    //     icon: 'fal fa-chart-line',
    //     html: 'Social-Voc',
    //     anchorId: 'app.socialvoc',
    //     name: 'Social-Voc',
    //     url: `/${environment.reportingRoutePrefix}/socialvoc`
    //   });
    // }

    return headers;
  }

  createAdminHeader(activeModule: string) {
    const headers: HeaderItems[] = [];
    if (this.headerlist) {
      if (this.headerlist.ClientConfig === null) {
        this.headerlist.ClientConfig = this.headerlist;
      }
      if (this.headerlist.ClientConfig) {
        this.headerlist.ClientConfig.Fields.Admin.forEach((header: any) => {
          if (header === 'datasource' && this.permissions[Permission.MENU_DATASOURCE] ? true : false) {
            headers.push({
              active: activeModule == 'Data Sources',
              icon: 'fal fa-list-alt',
              html: 'Data Sources',
              name: 'Data Sources',
              anchorId: 'app.datasource',
              module: 'datasource',
              url: `/${environment.reflecxRoutePrefix}/datasource`,
            });
          } else if (header === 'survey' && this.permissions[Permission.MENU_SURVEY] ? true : false) {
            headers.push({
              active: activeModule == 'Surveys',
              icon: 'fal fa-list-ul',
              html: 'Surveys',
              name: 'Surveys',
              anchorId: 'app.survey',
              module: 'survey',
              url: `/${environment.reflecxRoutePrefix}/survey`,
            });
          } else if (header === 'transmission' && this.permissions[Permission.MENU_TRANSMISSION] ? true : false) {
            headers.push({
              active: activeModule == 'Transmissions',
              icon: 'fal fa-retweet',
              html: 'Transmissions',
              name: 'Transmissions',
              anchorId: 'app.transmission',
              module: 'tranmission',
              url: `/${environment.reflecxRoutePrefix}/transmission`,
            });
          } else if (header === 'insights' && this.permissions[Permission.MENU_INSIGHT] ? true : false) {
            headers.push({
              active: activeModule == 'Insights',
              icon: 'fal fa-chart-bar',
              html: 'Insights',
              name: 'Insights',
              anchorId: 'app.insight',
              module: 'insight',
              url: `/${environment.reflecxRoutePrefix}/insight`,
            });
          } else if (header === 'LibraryModule' && this.permissions[Permission.MENU_LIBRARY] ? true : false) {
            headers.push({
              active: activeModule == 'Library',
              icon: 'fal fa-copy',
              html: 'Library',
              name: 'Library',
              anchorId: 'app.library',
              module: 'library',
              url: `/${environment.reflecxRoutePrefix}/library`,
            });
          } else if (header === 'user' && this.permissions[Permission.MENU_USER] ? true : false) {
            headers.push({
              active: activeModule == 'Users',
              icon: 'fal fa-user-cog',
              html: 'Users',
              name: 'Users',
              anchorId: 'app.user',
              module: 'admin',
              url: `/${environment.reflecxRoutePrefix}/admin`,
            });
          }
        });

        if (headers.length > 0) {
          headers[0].active = true;
        }
      }
    }

    return headers;
  }
  public setItems(module: string, activeModule: string) {
    this.activeItem = activeModule;
    switch (module) {
      case environment.reportingRoutePrefix:
        const headerTabs = this.createReportingHeader(activeModule);
        this.setHeaderItems(headerTabs);
        break;
      case environment.reflecxRoutePrefix:
        const tabs = this.createAdminHeader(activeModule);
        this.setHeaderItems(tabs);
        break;
    }
  }

  public setActiveItems(item: string) {
    this.activeItem = item;
    if (this.headerItems) {
      this.headerItems.map((x, index) => {
        x.active = false;
        if (item === x.name) {
          x.active = true;
        }
      });
    }
  }

  public getEditModeObservor() {
    return this.EditModeObservor.asObservable();
  }

  public reset() {
    if (this.headerItems) {
      this.headerItems.map((x, index, arr) => {
        arr[index].active = false;
      });
    }
  }
}
