import { Component, OnInit, Output, EventEmitter, ViewChild, Input, OnDestroy } from '@angular/core';
import { MenuItem, TreeNode } from 'primeng/api';
import moment from 'moment';

import { Subject, forkJoin, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@app/core';

import { ModelType } from '@app/core/types/modaltype';
import { DealerType } from '@app/core/types/dealertypes';
import { Model, ModelListSearchItem } from '@app/core/types/model';
import { CalenderFilter } from '@app/core/types/calenderFilter';
import { ActiveOrgHierachy, OrgHierachySearchItem, OrgHierachy } from '@app/core/types/orgHierarchy';
import { FiltersService } from '@app/shared/services/filters.service';
import { isEmpty, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import _ from 'lodash';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
import { Identifiers } from '@app/shared/services/app.config.type';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ExportService } from '@clearviewshared/services/export.service';
import { SurveyVersions } from '@app/core/types/suveyVesions';
import { SliderModule } from 'primeng/slider';
import { Calendar } from 'primeng/calendar';
import { ILanguages } from '@app/core/types/languages';
import { IAspectCategory } from '@app/core/types/vocCategories';
import { ISurveyQuestion, ISurveyQuestionOption } from '@app/core/types/surveyQuestion';
import { aspectCategoriesResponse } from '../../../../../projects/cvreporting/src/app/features/cvreporting/widgets/widget-data';
import {
  IAspectCategoriesBreadcrum,
  ISentimentDropdownOptions,
  ISurveyResponseResponse,
} from '../../../../../projects/cvreporting/src/app/features/cvreporting/widgets/widget-interface';
import { numberFormat } from 'highcharts';
import { ISurveyScore } from '@app/core/types/surveyScore';

@Component({
  selector: 'app-voc-filters',
  templateUrl: './voc-filters.component.html',
  styleUrls: ['./voc-filters.component.sass'],
})
export class VocFiltersComponent implements OnInit {
  @Input() showSentimentFilters = false;

  showHierarchy = false;
  showMenu = false;
  showDateRange = false;
  yearNavigator = true;
  monthNavigator = true;

  languagesList: ILanguages[] = [];
  showLanguagesCtrl: boolean;
  languageTitle = 'All Languages';

  sentimentList: ISentimentDropdownOptions[] = [];
  sentimentListFiltered: TreeNode[] = [];
  showSentimentCtrl: boolean;
  sentimentTitle = 'All Sentiments';
  sentimentSearch = '';

  aspectCategoriesList: TreeNode[] = [];
  aspectCategoriesListChild: TreeNode[] = [];
  aspectCategoriesListFiltered: TreeNode[] = [];
  aspectCategorySelected: TreeNode = {};
  aspectCategoriesBreadcrum: IAspectCategoriesBreadcrum[] = [];
  showAspectCategoryCtrl: boolean;
  aspectCategoryTitle = 'All Categories';

  surveyQuestionsListBak: ISurveyQuestion[] = [];
  surveyQuestionsList: ISurveyQuestion[] = [];
  showSurveyQuestionCtrl: boolean;
  surveyQuestionTitle = 'Survey Questions';
  surveyQuestionSearch = '';

  surveyResponsesList: ISurveyQuestion[] = [];
  surveyResponsesListFiltered: ISurveyQuestion[] = [];
  surveyResponsesOptions: ISurveyQuestionOption[] = [];

  showSurveyResponsesCtrl: boolean;
  surveyResponsesTitle = 'Survey Response List';
  surveyResponsesSearch = '';
  surveyResponsesSelcted: any;
  surveyResponsesParentSelcted: any;
  surveyResponsesChildSelected: any;

  surveyScore: ISurveyScore = {
    Id: '',
    Type: '',
    Score: [0, 100],
    Types: ['Promoters', 'Passives', 'Detractors'],
  };
  showSurveyScoreCtrl: boolean;
  surveyScoreTitle = `Survey Score: ${this.surveyScore.Score[0]} - ${this.surveyScore.Score[1]}`;

  showDealerTypes = '';
  showSurveyVersionYear: boolean;
  dealerNameChanged: Subject<string> = new Subject<string>();
  filterItems: MenuItem[];
  items2: MenuItem[];
  maxDate: any = moment();
  currentMonth: any = moment();
  modelsTypes: ModelType[] = [];

  activeModalType: ModelType;
  activeModal: Model;
  items___: MenuItem[];
  currentOrg: string;
  currentLevel: number;
  currentParent = 'null';
  permissions: NgxPermissionsObject;
  showdealerType: boolean;
  allowdealerType: boolean;
  modalYear: string[] = ['2019', '2018', '2017', '2016', '2015', '2014'];
  modalYearCopy: string[] = ['2019', '2018', '2017', '2016', '2015', '2014'];
  selectActiveYear = false;
  @Input() hideSomeFilters = true;
  @Input() showVocFilters = false;
  @Input() showCustomRange = false;
  @Output() filterEmitter: EventEmitter<MenuItem[]> = new EventEmitter<MenuItem[]>();
  @Output() OnFilter: EventEmitter<any> = new EventEmitter<any>();
  defaultSelectedDate: CalenderFilter = { label: 'YTD', value: () => [this.currentMonth.startOf('year'), moment()] };
  @ViewChild('datePicker') datePicker: any;
  dateDropDown: CalenderFilter[] = [
    {
      label: 'R10',
      key: 'R10',
      value: () => [moment().subtract(10, 'days'), moment()],
    },
    {
      label: 'R30',
      key: 'R30',
      value: () => [moment().subtract(30, 'days'), moment()],
    },
    {
      label: 'MTD',
      key: 'MTD',
      value: (value: any = this.currentMonth) => [moment(value).startOf('month'), moment()],
    },
    {
      label: 'QTD',
      key: 'QTD',
      value: (value: any = this.currentMonth) => [
        moment(value).quarter(moment(value).quarter()).startOf('quarter'),
        moment(value).get('month') === moment().get('month') ? moment() : moment(value).endOf('month'),
      ],
    },
    {
      label: 'YTD',
      key: 'YTD',
      value: (value: any = this.currentMonth) => [moment(value).startOf('year'), moment()],
    },
    {
      label: this.translateService.instant('previous_month.label'),
      key: 'PREVIOUS MONTH',
      value: () => [moment().subtract(1, 'month'), moment()],
    },
    {
      label: this.translateService.instant('previous_quarter.label'),
      key: 'PREVIOUS QUARTER',
      value: () => [moment().subtract(1, 'month'), moment()],
    },
    {
      label: this.translateService.instant('previous_year.label'),
      key: 'PREVIOUS YEAR',
      value: () => [moment().subtract(1, 'month'), moment()],
    },
    {
      label: this.translateService.instant('program_to_date.label'),
      key: 'PROGRAM TO DATE',
      value: () => [moment().add(1, 'month').startOf('month'), moment().add(2, 'month')],
    },
    {
      label: this.translateService.instant('custom_range.label'),
      key: 'CUSTOM RANGE',
      value: (): any => [],
    },
  ];
  primeMoment: any;
  value: any;
  active: ActiveOrgHierachy[];
  logActive: any[];
  activeYear: string;
  yearquery = '';
  dealerquery = '';
  modelquery: string;
  modalsOfYear: ModelType[] = [];

  dealerTypesList: DealerType[];
  dealerType = 'all_mb_(corporate_&_private)';
  activeSurvey = '';

  searchItems: OrgHierachySearchItem[] = [];
  itemsTest: OrgHierachy[];
  items: OrgHierachy[];
  currentCalendarDate: any = [];
  modalList: ModelListSearchItem[] = [];
  programDate: Date = new Date();
  dealerChanged: Subject<string> = new Subject<string>();
  brandModelYearChanged: Subject<string> = new Subject<string>();
  showerrorMessage = false;
  showerrorMessageModel = false;
  surveyVersionChangeSubscripton: Subscription;
  allSurveyVersions: SurveyVersions[] = [];
  currentSurveyVersionList: SurveyVersions[] = [];
  acrecFlag = true;
  sourceTypeList: any[] = [];
  message: any;
  subscription: Subscription;

  private subscriptions: Subscription[] = [];

  constructor(
    private appConfig: AppConfigurationService,
    public filterService: FiltersService,
    public ngxPermission: NgxPermissionsService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private auth: AuthenticationService,
    private exportService: ExportService,
    private router: Router
  ) {
    this.currentCalendarDate = this.filterService.getValue();
    this.dealerNameChanged.pipe(debounceTime(250)).subscribe((x) => {
      this.showerrorMessage = true;
      this.onDealerSearch(x);
    });

    this.dealerChanged.pipe(debounceTime(250), distinctUntilChanged()).subscribe((model) => {
      this.searchDealer(model);
    });
    this.brandModelYearChanged.pipe(debounceTime(250), distinctUntilChanged()).subscribe((model) => {
      this.showerrorMessageModel = true;
      this.searchYear(model);
    });

    this.subscription = this.filterService.getTab().subscribe((message) => {
      this.resetQuestion();
    });

    const h = JSON.parse(localStorage.getItem('authorizationData'));
    let dealerTypes = h['FilterType'] || '';
    const dealerTypesList = [];
    dealerTypes = dealerTypes.split(',').sort();
    const All = {
      label: this.translateService.instant('all_mb_(corporate_&_private)'),
      translate: 'all_mb_(corporate_&_private)',
      value: 'All',
    };
    const Private = {
      label: this.translateService.instant('mb_private_only'),
      translate: 'mb_private_only',
      value: 'Private',
    };
    const Corporate = {
      label: this.translateService.instant('mb_corporate_only'),
      translate: 'mb_corporate_only',
      value: 'Corporate',
    };
    const Collision = {
      label: this.translateService.instant('collision_centres'),
      translate: 'collision_centres',
      value: 'Collision',
    };
    let currentFilter = 'All';
    let cTranslate = 'all_mb_(corporate_&_private)';
    let label = this.translateService.instant('all_mb_(corporate_&_private)');
    const takeFirst: any = [];
    if (dealerTypes.find((x: string) => x.toLowerCase() === 'all')) {
      dealerTypesList.push(All);
      dealerTypesList.push(Private);
      dealerTypesList.push(Corporate);
      dealerTypesList.push(Collision);
      takeFirst[0] = All;
    } else {
      if (dealerTypes.find((x: string) => x.toLowerCase() === 'private')) {
        dealerTypesList.push(Private);
        currentFilter = 'Private';
        label = this.translateService.instant('mb_private_only');
        cTranslate = 'mb_private_only';
        if (takeFirst.length === 0) {
          takeFirst[0] = Private;
        }
      }
      if (dealerTypes.find((x: string) => x.toLowerCase() === 'corporate')) {
        dealerTypesList.push(Corporate);
        currentFilter = 'Corporate';
        label = this.translateService.instant('mb_corporate_only');
        cTranslate = 'mb_corporate_only';
        if (takeFirst.length === 0) {
          takeFirst[0] = Corporate;
        }
      }
      if (dealerTypes.find((x: string) => x.toLowerCase() === 'collision')) {
        dealerTypesList.push(Collision);
        currentFilter = 'Collision';
        label = this.translateService.instant('collision_centres');
        cTranslate = 'collision_centres';
        if (takeFirst.length === 0) {
          takeFirst[0] = Collision;
        }
      }
    }

    this.getSurveyVersionsData(true);
    const d = this.filterService.getDealerType();
    if (!d.default) {
      this.filterService.setDealerType(d.value, d.label, d.translate);
    } else {
      if (takeFirst.length !== 0) {
        this.filterService.setDealerType(takeFirst[0].value, takeFirst[0].label, takeFirst[0].translate);
      } else {
        this.filterService.setDealerType(currentFilter, label, cTranslate);
      }
    }

    this.dealerTypesList = dealerTypesList;
    this.items2 = [{ label: 'MBC' }, { label: 'MBC Somewhere' }, { label: 'MBC Another' }, { label: 'Somewhere' }];

    this.searchItems = [];
    this.primeMoment = moment;
    this.currentOrg = h.OrganizationName;
    this.currentLevel = h.Level;
    this.currentParent = h.ParentOrganization;

    //    this.activeYear = this.filterService.activeYear ? this.filterService.activeYear : '2019';
    this.activeYear = ''; /// this.filterService.activeYear ? this.filterService.activeYear : '2019';
    this.activeModalType = { label: this.filterService.activeModalType ? this.filterService.activeModalType : '' };
    this.activeModal = { label: this.filterService.activeModal ? this.filterService.activeModal : '' };
    // tslint:disable-next-line:max-line-length
    const dtype = this.dealerTypesList.find(
      (x: DealerType) => x.value.toLowerCase() === this.filterService.dealerType.toLowerCase()
    );
    if (!d.default) {
      this.dealerType = d.translate;
    } else {
      this.dealerType = dtype ? dtype.translate : this.dealerTypesList[0].translate;
    }
    this.filterService.getVindecoderData().subscribe((res: any) => {
      this.modelsTypes = res;
      // this.activeYear = this.modalYear[0];
      this.modalsOfYear = this.modelsTypes;
      if (this.modalsOfYear && this.modalsOfYear.length > 0) {
        const type = this.modalsOfYear.find((x: ModelType) => x.label === this.activeModalType.label);
        if (type) {
          this.activeModalType = type;
          // this.setModalType(type);
        }
      }
    });

    const programDate = this.appConfig.getByIdentifier(Identifiers.ProgramDate);
    if (programDate) {
      if (programDate.ClientConfig === null) {
        programDate.ClientConfig = programDate;
      }
      if (programDate.ClientConfig) {
        this.programDate = new Date(programDate.ClientConfig.Fields['equal']['value']);
      }
    }

    this.getAndSetupData();
  }

  resetQuestion() {
    localStorage.removeItem('survey_responses');
    localStorage.removeItem('survey_question');
    this.initQuestionsCtrl();
  }

  initVocDashboard() {
    if (this.showVocFilters) {
      this.filterService.filterDisabledStatus = true;
      this.initLanguageCtrl();
      this.initQuestionsCtrl();
      this.initAspectCategoryCtrl();
      const includeUnassigned = this.permissions[Permission.VOC_SHOW_UNASSIGNED_COMMENTS] ? true : false;
      this.sentimentList = this.filterService.getSentimentData(includeUnassigned);
      //this.sentimentList = this.filterService.getSentimentData();
    }

    this.sentimentTitle = this.filterService.getSentiment().label;
    this.surveyScore = this.filterService.getSurveyScore();
    if (this.surveyScore.Type !== '') {
      this.surveyScoreTitle = `${this.surveyScore.Type}: ${this.surveyScore.Score[0]} - ${this.surveyScore.Score[1]}`;
    } else {
      this.surveyScoreTitle = `Survey Score: ${this.surveyScore.Score[0]} - ${this.surveyScore.Score[1]}`;
    }
  }

  showLanguages() {
    if (this.showLanguagesCtrl) {
      this.showLanguagesCtrl = false;
      return;
    }
    this.toggleMenu('showLanguagesCtrl');
  }

  changeLanguage(data: ILanguages) {
    this.filterService.setLanguage(data);
    this.showLanguagesCtrl = false;
    // if (data.LanguageCode === null) {
    //   data.LanguageCode = '';
    // }
    this.languageTitle = data && data.LanguageCode !== null ? `${data.Name} (${data.LanguageCode})` : `${data.Name}`;

    // localStorage.removeItem('survey_question');
    // localStorage.removeItem('survey_responses');
    // this.initQuestionsCtrl(true);
  }

  showSentiment() {
    if (this.showSentimentCtrl) {
      this.showSentimentCtrl = false;
      return;
    }
    this.toggleMenu('showSentimentCtrl');
  }

  changeSentiment(data: any) {
    this.filterService.setSentiment(data);
    this.showSentimentCtrl = false;
    this.sentimentTitle = data.label;
  }

  showAspectCategory() {
    if (this.showAspectCategoryCtrl) {
      this.showAspectCategoryCtrl = false;
      return;
    }
    this.toggleMenu('showAspectCategoryCtrl');
  }

  aspectCategoriesDrill(response: any) {
    this.aspectCategoriesListFiltered.forEach((cat: any) => {
      cat.isSelected = false;
    });

    if (response != null && response.children != null && response.children.length > 0) {
      this.aspectCategoriesListFiltered = response.children;

      this.aspectCategoriesBreadcrum.push({
        id: response.Name,
        title: response.Label,
        translatedTitle: response.Label,
        level: this.aspectCategoriesBreadcrum.length,
      });
    } else {
      response.isSelected = true;
    }
    this.aspectCategorySelected.label = response.Label;
    this.aspectCategorySelected.key = response.Name;
    this.aspectCategorySelected.leaf = response.Level;
    if (this.aspectCategorySelected) {
      this.filterService.setAspectCategory(this.aspectCategorySelected);
    }
    this.aspectCategoryTitle = this.aspectCategorySelected.label;
  }

  aspectCategoriesBreadcrumReset(breadcrum: IAspectCategoriesBreadcrum) {
    if (breadcrum == null) {
      this.aspectCategoryTitle = 'All Categories';
      this.aspectCategoriesListFiltered = this.aspectCategoriesList;
      this.aspectCategoriesBreadcrum = [];
      this.aspectCategorySelected = {};
      // this.filterService.setAspectCategory({ id: 0, name: this.aspectCategoryTitle });
      localStorage.removeItem('voc_category');
      this.aspectCategoriesRec2(this.aspectCategoriesListFiltered);
    } else {
      this.aspectCategoriesRec(breadcrum, this.aspectCategoriesList);
      this.acrecFlag = true;
    }
  }

  aspectCategoriesRec(bc: any, aspectCategoriesLst: any): any {
    aspectCategoriesLst.forEach((val: any, index: number, arr: any) => {
      if (this.acrecFlag) {
        if (val.Name === bc.id) {
          this.acrecFlag = false;
          if (val.children && val.children.length > 0) {
            this.aspectCategoriesListFiltered = val.children;
            this.aspectCategorySelected = val;
            let ind = this.aspectCategoriesBreadcrum.indexOf(bc);
            ++ind;
            this.aspectCategoriesBreadcrum.splice(ind, this.aspectCategoriesBreadcrum.length - ind);
            return val.children;
          }
        }

        if (val.children && val.children.length > 0) {
          this.aspectCategoriesRec(bc, val.children);
        }
      }
    });
  }

  aspectCategoriesRec2(aspectCategoriesLst: TreeNode[]): any {
    aspectCategoriesLst.forEach((val: any) => {
      val.isSelected = false;
      if (val.children && val.children.length > 0) {
        this.aspectCategoriesRec2(val.children);
      }
    });
  }

  aspectCategoryDone() {
    if (this.aspectCategorySelected) {
      this.filterService.setAspectCategory(this.aspectCategorySelected);
    }
    // this.showAspectCategoryCtrl = false;
    this.aspectCategoryTitle = this.aspectCategorySelected.label;
  }

  initAspectCategoryCtrl() {
    this.filterService.getAspectCategoryData().subscribe(
      (res: any) => {
        if (res) {
          const categories = this.unflatten(res).map((x: any) => {
            x.isSelected = false;
            if (x.children) {
              x.children.map((y: any) => {
                y.isSelected = false;
                if (y.children) {
                  y.children.map((z: any) => {
                    z.isSelected = false;
                    return z;
                  });
                }
                return y;
              });
            }
            return x;
          });

          this.aspectCategoriesList = categories;
          this.aspectCategoriesListFiltered = this.aspectCategoriesList;

          const locCat = this.filterService.getAspectCategory();
          if (locCat) {
            this.aspectCategoryTitle = locCat.label;
          }
        }
      },
      (error: any) => {}
    );
  }

  unflatten(arr: any) {
    let tree: any = [];
    arr.forEach((element: any) => {
      switch (element.Level) {
        case 2: {
          tree = element.Data[0].Nodes;
          tree.forEach((ele: any) => {
            ele.Level = element.Level;
          });
          break;
        }
        case 3: {
          element.Data.forEach((l2: any) => {
            for (let i = 0; i < tree.length; i++) {
              if (tree) {
                if (tree[i].Name === l2.Parent) {
                  tree[i]['children'] = l2.Nodes;
                  tree[i]['children'].forEach((ele: any) => {
                    ele.Level = element.Level;
                  });
                }
              }
            }
          });
          break;
        }
        case 4: {
          element.Data.forEach((l3: any) => {
            if (tree) {
              for (let i = 0; i < tree.length; i++) {
                if (tree[i].children) {
                  for (let j = 0; j < tree[i].children.length; j++) {
                    if (tree[i].children[j].Name === l3.Parent) {
                      tree[i].children[j]['children'] = l3.Nodes;
                      tree[i].children[j]['children'].forEach((ele: any) => {
                        ele.Level = element.Level;
                      });
                    }
                  }
                }
              }
            }
          });
          break;
        }
      }
    });
    return tree;
  }

  initLanguageCtrl() {
    this.filterService.getLanguagesData().subscribe(
      (res: any) => {
        if (res) {
          this.languagesList = res;
          this.languagesList.unshift({
            Label: null,
            Name: 'All Languages',
            DisplayName: null,
            Value: null,
            LanguageCode: null,
            Translate: null,
            checked: true,
          });

          // this.languagesList.push(
          //   {
          //     Id: null,
          //     Label: null,
          //     Name: 'All Languages',
          //     DisplayName: null,
          //     Value: null,
          //     LanguageCode: null,
          //     Translate: null,
          //   }
          // );

          const language = this.filterService.getLanguage();
          if (language) {
            this.languageTitle = language.LanguageCode
              ? `${language.Name} (${language.LanguageCode})`
              : (this.languageTitle = `${language.Name}`);
            // this.languageTitle = `${language.Name} (${language.LanguageCode})`;
            // this.initQuestionsCtrl(true);
          }
        }
      },
      (error: any) => {}
    );
  }

  initQuestionsCtrl(isCache: boolean = false) {
    this.surveyResponsesSearch = '';
    this.surveyQuestionsList = this.filterService.getSurveyQuestion();
    if (this.surveyQuestionsList && this.surveyQuestionsList.length > 0) {
      this.surveyQuestionsList = this.surveyQuestionsList;

      if (this.surveyQuestionsList[0] && this.surveyQuestionsList[0].Id.toLocaleLowerCase() !== 'all') {
        this.surveyQuestionsList.unshift({
          Id: 'All',
          QuestionId: '',
          Title: 'All',
          Type: 'toggle',
          Options: [],
          IsChecked: true,
          IsSelected: false,
          IsAddClass: '',
        });
      }

      const sr = this.filterService.getSurveyResponses();
      if (sr !== undefined && sr.length > 0) {
        this.surveyResponsesList = sr;
        this.surveyResponsesList.forEach((itm: any) => {
          if (itm.IsSelected) {
            this.surveyResponsesOptions = itm.Options;
          }
        });
      } else {
        this.surveyResponsesList = this.surveyQuestionsList.filter(function (val: any) {
          return val.Id.toLowerCase() !== 'all';
        });
      }
    } else {
      this.mapSurveyQuestionsList();
    }
    this.surveyQuestionsListBak = this.surveyQuestionsList;
  }

  showSurveyQuestion() {
    if (this.showSurveyQuestionCtrl) {
      this.showSurveyQuestionCtrl = false;
      return;
    }
    this.toggleMenu('showSurveyQuestionCtrl');
  }

  SurveyQuestionSelection(event: any, sq: any) {
    this.surveyResponsesSearch = '';
    if (sq.toLowerCase() === 'All'.toLowerCase()) {
      this.surveyQuestionsList.forEach(function (val: any) {
        val.IsChecked = event;
      });
    }

    const cnt = this.surveyQuestionsList.length;
    this.surveyResponsesList = this.surveyQuestionsList.filter(function (val: any) {
      return val.Id.toLowerCase() !== 'all' && val.IsChecked === true;
    });

    const flag = this.surveyResponsesList.length === cnt - 1 ? true : false;
    if (this.surveyQuestionsList[0] && this.surveyQuestionsList[0].Id.toLocaleLowerCase() === 'all') {
      this.surveyQuestionsList[0].IsChecked = flag;
    }

    this.surveyQuestionsListBak = this.surveyQuestionsList;

    this.filterService.setSurveyQuestion(this.surveyQuestionsList);
  }

  surveyQuestionChange($event: any, sq: ISurveyQuestion) {
    sq.IsChecked = !sq.IsChecked;
    this.surveyResponsesList = this.surveyQuestionsList.filter(function (val: any) {
      return val.IsChecked === true;
    });
  }

  onSurveyQuestionSearch(event: any) {
    if (event.target.value !== null || event.target.value !== '') {
      this.surveyQuestionsList = this.surveyQuestionsListBak.filter((val: any) => {
        return val.Id.toLowerCase().includes(event.target.value.toLowerCase());
      });
    }
  }

  surveyQuestionDone() {
    this.surveyResponsesList = this.surveyQuestionsList.filter(function (val: any) {
      return val.Id.toLowerCase() !== 'all' && val.IsChecked === true;
    });
    this.showSurveyQuestionCtrl = false;
  }

  showSurveyScore() {
    if (this.showSurveyScoreCtrl) {
      this.showSurveyScoreCtrl = false;
      return;
    }
    this.toggleMenu('showSurveyScoreCtrl');
  }

  silderBarRange(type: string, firstValue: number, secondValue: number) {
    this.surveyScore = {
      Id: '',
      Type: type,
      Score: [firstValue, secondValue],
      Types: [],
    };
    this.filterService.setSurveyScore(this.surveyScore);
    this.surveyScoreTitle = `${this.surveyScore.Type}: ${this.surveyScore.Score[0]} - ${this.surveyScore.Score[1]}`;
  }

  changeSurveyScore(event: any) {
    this.surveyScore.Type = 'Survey Score';
    this.filterService.setSurveyScore(this.surveyScore);
    this.surveyScoreTitle = `Survey Score: ${this.surveyScore.Score[0]} - ${this.surveyScore.Score[1]}`;
  }

  surveyScoreDone() {
    this.filterService.setSurveyScore(this.surveyScore);
    this.showSurveyScoreCtrl = false;
    this.surveyScoreTitle = `${this.surveyScore.Type}: ${this.surveyScore.Score[0]} - ${this.surveyScore.Score[1]}`;
  }

  showSurveyResponses() {
    if (this.showSurveyResponsesCtrl) {
      this.showSurveyResponsesCtrl = false;
      return;
    }
    this.toggleMenu('showSurveyResponsesCtrl');
  }

  surveyResponsesDrill(response: ISurveyQuestion) {
    // console.log('res', response);
    this.surveyResponsesList.forEach((val: any, index: number) => {
      val.IsSelected = false;
      if (val.Id === response.Id) {
        val.IsSelected = true;
      }
    });

    if (response != null && response.Options != null) {
      this.surveyResponsesOptions = [];
      this.surveyResponsesOptions = response.Options;
    } else {
      this.surveyResponsesOptions = [];
    }
  }

  surveyResponsesOptSelected(opt: any) {
    this.surveyResponsesOptions.forEach((obj: any, index: number) => {
      obj.IsSelected = false;
      if (obj.Title === opt.Title) {
        obj.IsChecked = !obj.IsChecked;
        obj.IsSelected = true;
      }
    });
  }

  surveyResponsesDone() {
    this.filterService.setSurveyResponses(this.surveyResponsesList);
    // this.showSurveyQuestionCtrl = false;
    this.showSurveyResponsesCtrl = false;
  }

  onSurveyResponsesSearch(event: any) {
    if (event.target.value !== null || event.target.value !== '') {
      // Removed - ok
      this.surveyResponsesList.forEach((val: any) => {
        val.Options = val.Options.filter((o: any) => {
          return o.Title.toLowerCase() === event.target.value.toLowerCase() && o.IsChecked === true ? false : true;
        });
      });

      this.surveyResponsesOptions = this.surveyResponsesOptions.filter((o: any) => {
        return o.Title.toLowerCase() === event.target.value.toLowerCase() ? false : true;
      });

      this.surveyResponsesList = this.surveyQuestionsList.filter((val: any) => {
        // let flag = val.Id.toLowerCase().includes(event.target.value.toLowerCase());
        return val.Id.toLowerCase().includes(event.target.value.toLowerCase()) && val.IsChecked === true;
      });
    }
  }

  bindFilterIfNull() {
    this.items2 = [{ label: 'MBC' }, { label: 'MBC Somewhere' }, { label: 'MBC Another' }, { label: 'Somewhere' }];

    this.searchItems = [];
    this.primeMoment = moment;
    const h = JSON.parse(localStorage.getItem('authorizationData'));
    this.currentOrg = h.OrganizationName;
    this.currentLevel = h.Level;
    this.currentParent = h.ParentOrganization;

    this.filterService.getAllOrgs().subscribe((res: any) => {
      this.items = res;
      this.active = JSON.parse(localStorage.getItem('active'));
      this.logActive = res;
      if (!this.active) {
        this.active = [];
        this.active.push({ id: 0, active: this.items[0], items: this.items });

        this.buildBreadCrumb(this.active);
      }
      if (this.currentLevel && this.currentParent) {
        this.setData();
      }

      this.items2 = JSON.parse(localStorage.getItem('hierarchy'));
      if (!this.items2) {
        this.items2 = [
          {
            label: this.active[0].active.label,
          },
        ];
      }
      this.filterEmitter.emit(this.items2);
    });
  }

  getSurveyVersionsData(isRouteChange: boolean) {
    this.allSurveyVersions = JSON.parse(localStorage.getItem('surveyVersions'));
    if (isRouteChange || !this.allSurveyVersions) {
      this.filterService.getSurveyVersions().subscribe((res: any) => {
        if (res) {
          this.allSurveyVersions = res;
          localStorage.setItem('surveyVersions', JSON.stringify(this.allSurveyVersions));

          this.mapVersionList(isRouteChange);
        }
      });
    } else {
      this.mapVersionList(isRouteChange);
    }
  }
  mapVersionList(isRouteChange: Boolean) {
    this.currentSurveyVersionList = this.allSurveyVersions.filter(
      (x) => x.Brand === this.filterService.getModelType().activeModalType && x.Event === this.filterService.program
    );

    if (this.currentSurveyVersionList && this.currentSurveyVersionList.length > 0) {
      if (isRouteChange && this.filterService.getSurveyVersion()) {
        const activeVersion = this.currentSurveyVersionList.find(
          (x) =>
            x.SurveyId === this.filterService.getSurveyVersion() &&
            x.Brand === this.filterService.getModelType().activeModalType &&
            x.Event === this.filterService.program
        );
        if (activeVersion && activeVersion.SurveyId) {
          this.activeSurvey = activeVersion.Version;
          this.filterService.setSurveyVersion(activeVersion.SurveyId);
        } else {
          const activeVersion = this.currentSurveyVersionList.find(
            (x) =>
              x.CurrentSurvey === true &&
              x.Brand === this.filterService.getModelType().activeModalType &&
              x.Event === this.filterService.program
          );
          if (activeVersion && activeVersion.SurveyId) {
            this.activeSurvey = activeVersion.Version;
            this.filterService.setSurveyVersion(activeVersion.SurveyId);
          } else {
            const active = this.currentSurveyVersionList.find(
              (x) =>
                x.Brand === this.filterService.getModelType().activeModalType && x.Event === this.filterService.program
            );
            if (active) {
              this.activeSurvey = active.Version;
              this.filterService.setSurveyVersion(active.SurveyId);
            } else {
              this.activeSurvey = '';
              this.filterService.setSurveyVersion('');
            }
          }
        }
      } else {
        const activeVersion = this.currentSurveyVersionList.find(
          (x) =>
            x.CurrentSurvey === true &&
            x.Brand === this.filterService.getModelType().activeModalType &&
            x.Event === this.filterService.program
        );
        if (activeVersion && activeVersion.SurveyId) {
          this.activeSurvey = activeVersion.Version;
          this.filterService.setSurveyVersion(activeVersion.SurveyId);
        } else {
          const active = this.currentSurveyVersionList.find(
            (x) =>
              x.Brand === this.filterService.getModelType().activeModalType && x.Event === this.filterService.program
          );
          if (active) {
            this.activeSurvey = active.Version;
            this.filterService.setSurveyVersion(active.SurveyId);
          } else {
            this.activeSurvey = '';
            this.filterService.setSurveyVersion('');
          }
        }
      }
    } else {
      this.activeSurvey = '';
      this.filterService.setSurveyVersion('');
    }
  }

  mapSurveyQuestionsList() {
    this.filterService.resetSurveyResponse();
    this.filterService.getSurveyQuestionData().subscribe(
      (res: ISurveyQuestion[]) => {
        if (res) {
          localStorage.removeItem('survey_question');

          res.map((val: any) => {
            val.IsChecked = true;
            val.IsSelected = false;
          });

          res.forEach((val: any) => {
            val.IsChecked = true;
            val.IsSelected = false;

            val.Options.forEach((opt: any) => {
              opt.IsChecked = true;
              opt.IsSelected = false;
            });
          });

          this.surveyResponsesOptions = [];
          this.surveyResponsesList = res.map((x) => Object.assign({}, x));

          res.unshift({
            Id: 'All',
            QuestionId: '',
            Title: 'All',
            Type: 'toggle',
            Options: [],
            IsChecked: true,
            IsSelected: false,
            IsAddClass: '',
          });

          this.surveyQuestionsListBak = res;
          this.surveyQuestionsList = res;
        }
      },
      (error: any) => {
        // console.log(error);
      }
    );
  }

  showDealerType() {
    if (this.showdealerType) {
      this.showdealerType = false;
      return;
    }
    this.toggleMenu('showdealerType');
  }

  changeDealerType(value: string, label: string, translate: string) {
    localStorage.setItem('active', '[]');
    localStorage.setItem('hierarchy', '[]');
    this.filterService.setDealerType(value, label, translate);
    this.showdealerType = false;
    this.dealerType = translate;
    this.getAndSetupData(false);
    this.closeall();
    // this.setData();
  }
  showSurveyVersion() {
    if (this.showSurveyVersionYear) {
      this.showSurveyVersionYear = false;
      return;
    }
    this.toggleMenu('showSurveyVersionYear');
  }
  selectSurveyVersionYear(value: string, surveyId: string) {
    if (!this.showVocFilters) {
      this.showSurveyVersionYear = !this.showSurveyVersionYear;
    }
    // if (JSON.parse(localStorage.getItem('survey_version'))) {
    //   this.activeSurvey = JSON.parse(localStorage.getItem('survey_version'));
    // }
    this.activeSurvey = value;
    this.filterService.setSurveyVersion(surveyId);
  }
  UpdateSurveyQuestions() {
    this.showSurveyVersionYear = !this.showSurveyVersionYear;
    this.mapSurveyQuestionsList();
  }
  onClickInnerBreadCrumb(e: any) {}
  setData() {
    if (this.currentLevel === 1) {
      const firstLevel = this.filterService.getNodes(1, 'null');
      if (firstLevel) {
        this.setFilterByCurrentLevel(0, firstLevel);
        this.buildBreadCrumb(this.active);
      }
    }
    if (this.currentLevel === 2) {
      let secondLevel = this.filterService.getNodes(this.currentLevel, this.currentParent);
      const firstLevel = this.filterService.getNodes(1, 'null');
      if (firstLevel) {
        secondLevel = this.filterValue(secondLevel, 'Name', this.currentOrg);
        this.setFilterByCurrentLevel(0, firstLevel[0]);
        if (secondLevel) {
          this.setFilterByCurrentLevel(1, secondLevel);
        }
        this.removeObject(this.active[0].active.children, this.currentOrg);
        this.buildBreadCrumb(this.active);
      }
    }
    if (this.currentLevel === 3) {
      let thirdLevel = this.filterService.getNodes(this.currentLevel, this.currentParent);
      if (thirdLevel) {
        thirdLevel = this.filterValue(thirdLevel, 'Name', this.currentOrg);
      }
      let secondLevel = this.filterService.getNodes(2, 'National');
      if (thirdLevel) {
        secondLevel = this.filterValue(secondLevel, 'Name', thirdLevel.parent);
        const firstLevel = this.filterService.getNodes(1, 'null');
        if (firstLevel) {
          this.setFilterByCurrentLevel(0, firstLevel[0]);
        }
        if (secondLevel) {
          this.setFilterByCurrentLevel(1, secondLevel);
        }
        if (thirdLevel) {
          this.setFilterByCurrentLevel(2, thirdLevel);
        }
        if (this.active && this.active[0]) {
          this.removeObject(this.active[0].active.children, this.currentParent);
          this.removeObject(this.active[1].active.children, this.currentOrg);
          this.buildBreadCrumb(this.active);
        }
      }
    }
    if (this.currentLevel === 4) {
      let fourthLevel = this.filterService.getNodes(this.currentLevel, this.currentParent);
      fourthLevel = this.filterValue(fourthLevel, 'Name', this.currentOrg);
      if (fourthLevel) {
        const thirdLevelParent = this.filterService.getNodesByName(3, fourthLevel.parent);
        const thirdLevel = this.filterValue(thirdLevelParent, 'Name', fourthLevel.parent);
        // var secondLevelParent = this.filterService.getNodesByName(2, thirdLevel.parent);
        // var secondLevel = this.filterValue(secondLevelParent, 'Name', thirdLevel.parent);
        let secondLevel = this.filterService.getNodes(2, 'National');
        if (thirdLevel) {
          secondLevel = this.filterValue(secondLevel, 'Name', thirdLevel.parent);
        }
        const firstLevel = this.filterService.getNodes(1, 'null');
        if (firstLevel) {
          this.setFilterByCurrentLevel(0, firstLevel[0]);
        }
        if (secondLevel) {
          this.setFilterByCurrentLevel(1, secondLevel);
        }
        if (thirdLevel) {
          this.setFilterByCurrentLevel(2, thirdLevel);
        }
        if (!fourthLevel && !secondLevel) {
          return;
        }
        if (fourthLevel) {
          this.setFilterByCurrentLevel(3, fourthLevel);
          this.removeObject(this.active[0].active.children, secondLevel.Name);
          this.removeObject(this.active[1].active.children, this.currentParent);
          this.removeObject(this.active[2].active.children, this.currentOrg);
          this.removeObject(this.active[3].active.children, fourthLevel.Name);
          this.buildBreadCrumb(this.active);
        }
      }
    }
    if (this.currentLevel === 5) {
      let fifthLevel = this.filterService.getNodes(this.currentLevel, this.currentParent);
      fifthLevel = this.filterValue(fifthLevel, 'Name', this.currentOrg);
      if (fifthLevel) {
        let fourthLevel = this.filterService.getNodesByName(4, fifthLevel.parent);
        fourthLevel = this.filterValue(fourthLevel, 'Name', fifthLevel.parent);
        const thirdLevelParent = this.filterService.getNodesByName(3, fourthLevel.parent);
        const thirdLevel = this.filterValue(thirdLevelParent, 'Name', fourthLevel.parent);
        let secondLevel = this.filterService.getNodes(2, 'National');
        if (thirdLevel) {
          secondLevel = this.filterValue(secondLevel, 'Name', thirdLevel.parent);
          const firstLevel = this.filterService.getNodes(1, 'null');
          this.setFilterByCurrentLevel(0, firstLevel[0]);
          if (secondLevel) {
            this.setFilterByCurrentLevel(1, secondLevel);
          }
          if (thirdLevel) {
            this.setFilterByCurrentLevel(2, thirdLevel);
          }
        }
        if (fourthLevel) {
          this.setFilterByCurrentLevel(3, fourthLevel);
        }
        if (fifthLevel) {
          this.setFilterByCurrentLevel(4, fifthLevel);
        }
        if (secondLevel && fourthLevel) {
          this.removeObject(this.active[0].active.children, secondLevel.Name);
          this.removeObject(this.active[1].active.children, this.currentParent);
          this.removeObject(this.active[2].active.children, this.currentOrg);
          this.removeObject(this.active[3].active.children, fourthLevel.Name);
          this.buildBreadCrumb(this.active);
        }
      }
    }
    // if (this.currentLevel === 5) {
    //   let fifthLevel = this.filterService.getNodes(this.currentLevel, this.currentParent);
    //   fifthLevel = this.filterValue(fifthLevel, 'Name', this.currentOrg);

    //   let fourthLevel = this.filterService.getNodes(4, fifthLevel.parent);
    //   fourthLevel = this.filterValue(fourthLevel, 'Name', fifthLevel.Name);

    //   let thirdLevel = this.filterService.getNodes(3, 'National');
    //   thirdLevel = this.filterValue(thirdLevel, 'Name', fourthLevel.parent);

    //   const secondLevel = this.filterService.getNodes(2, 'MBC');
    //   const firstLevel = this.filterService.getNodes(1, 'null');

    //   this.setFilterByCurrentLevel(0, firstLevel[0]);
    //   this.setFilterByCurrentLevel(1, secondLevel[0]);
    //   if (thirdLevel) {
    //     this.setFilterByCurrentLevel(2, thirdLevel);
    //   }
    //   if (fourthLevel) {
    //     this.setFilterByCurrentLevel(3, fourthLevel);
    //   }

    //   this.removeObject(this.active[1].active.children, this.currentParent);
    //   this.removeObject(this.active[2].active.children, this.currentOrg);
    //   this.buildBreadCrumb(this.active);
    // }
  }

  removeObject(obj: OrgHierachy[], value: string) {
    const index = _.findIndex(obj, { Name: value });
    if (index === 0) {
      obj.splice(1, obj.length - 1);
    } else if (index === obj.length - 1) {
      obj.splice(0, obj.length - 1);
    } else {
      obj.splice(0, index);
      obj.splice(1, obj.length);
    }
  }
  filterValue(obj: any, key: string, value: string) {
    return obj.find(function (v: any) {
      return v[key] === value;
    });
  }
  searchModelBrandYearChange(dealer: string) {
    this.showerrorMessageModel = false;
    this.brandModelYearChanged.next(dealer);
  }
  searchDealerChange(dealer: string) {
    this.showerrorMessage = false;
    this.dealerNameChanged.next(dealer);
  }

  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  onDealerSearch(event: any, items: any = this.items, recrsive: boolean = false, parent: any = { children: [] }) {
    if (!recrsive) {
      this.searchItems = [];
    }
    if (event.length < 2) {
      return;
    }
    items.forEach((item: any) => {
      item.parent = parent;
      const level = parseInt(item['level'], 0);
      // tslint:disable-next-line:max-line-length
      if (
        event &&
        item['label'].toLowerCase().search(this.escapeRegExp(event.toLowerCase())) !== -1 &&
        level >= this.currentLevel
      ) {
        let result: any = [];
        result = this.buildSearchHierachy(item, result).reverse();
        const newSearch: OrgHierachySearchItem = {
          dealerHierarchy: result,
          label: 'National',
        };
        this.searchItems.push(newSearch);
      }
      if (item['children'].length > 0) {
        this.onDealerSearch(event, item.children, true, item);
      }
    });
  }

  buildSearchHierachy(item: any, memory: any) {
    memory = [...memory];
    if (item.label !== undefined) {
      memory.push(item);
    }
    if (item.parent !== undefined) {
      memory = this.buildSearchHierachy(item.parent, memory);
    }
    return memory;
  }

  searchDealer(dealer: string, items: OrgHierachy[] = this.items, parent: OrgHierachy = null) {
    if (dealer === '' || dealer === undefined) {
      this.searchItems = [];
      return;
    }
    if (!parent) {
      this.searchItems = [];
    }
    items.forEach((x: OrgHierachy, index: number, arr: OrgHierachy[]) => {
      x.parent = parent;
      // tslint:disable-next-line:max-line-length
      if (
        x.label.toLowerCase().includes(this.escapeRegExp(dealer.toLowerCase())) &&
        parseInt(x.level, 0) > this.currentLevel
      ) {
        this.addToSearch(x);
      }
      if (x.children && x.children.length > 0) {
        this.searchDealer(dealer, x.children, x);
      }
      // if (x.children && x.children.length > 0) {
      //   this.searchDealer(dealer, x.children, x);
      // } else if (x.label.toLowerCase().includes(dealer.toLowerCase())) {
      //   this.addToSearchQuery(x);
      // }
    });
  }
  mapActiveFromSearch(selectedDealer: any) {
    const active = [];
    let x = selectedDealer[selectedDealer.length - 1];
    while (x.parent != null) {
      x.parent.children = this.mapChildren(x.parent.children);
      active.push({
        id: -1,
        active: { label: x.label, Name: x.Name, level: x.level, parent: x.parent, children: x.children },
        items: x.parent.children.map((y: OrgHierachy) => {
          return {
            label: y.label,
            children: y.children,
            level: y.level,
            Name: y.Name,
            parent: x.parent,
          };
        }),
      });
      x = x.parent;
    }
    // x.parent.children = this.mapChildren(x.parent.children);
    active.push({
      id: -1,
      active: { label: x.label, Name: x.Name, level: x.level, parent: x.parent, children: x.children },
      items: this.items,
    });
    active.reverse().map((x: ActiveOrgHierachy, index, arr) => {
      arr[index].id = index;
    });
    return active;
  }
  addToSearch(x: OrgHierachy) {
    let dealerHierarchy = [];
    while (x.parent != null) {
      dealerHierarchy.push({
        label: x.label,
        Name: x.Name,
        level: x.level,
        parent: x.parent,
      });
      x.parent.children = this.mapChildren(x.parent.children);
      x = x.parent;
    }
    dealerHierarchy.push({
      label: x.label,
      Name: x.Name,
      level: x.level,
      parent: x.parent,
    });
    dealerHierarchy = dealerHierarchy.reverse();

    this.searchItems.push({ dealerHierarchy, active: [] });
  }
  mapChildren(item: OrgHierachy[]): OrgHierachy[] {
    return item.map((x: OrgHierachy) => {
      if (x.parent && Object.keys(x.parent).length > 0 && x.level !== '1') {
        return {
          label: x.label,
          Name: x.Name ? x.Name : '',
          level: x.level ? x.level : '',
          parent: this.mapParent(x.parent),
          children: x.children && x.children.length > 0 ? this.mapChildren(x.children) : [],
        };
      } else {
        const dataItem = this.filterService.getNodesByName(Number(x.level), x.Name);
        return {
          label: x.label,
          Name: x.Name ? x.Name : '',
          level: x.level ? x.level : '',
          parent: dataItem && dataItem.length > 0 ? dataItem.parent[0] : null,
          children: x.children && x.children.length > 0 ? this.mapChildren(x.children) : [],
        };
      }
    });
  }

  mapParent(item: OrgHierachy): OrgHierachy {
    if (item) {
      return {
        label: item.label,
        Name: item.Name,
        level: item.level,
      };
    }
  }

  addToSearchQuery(x: OrgHierachy) {
    let dealerHierarchy = [];
    const active = [];
    while (x.parent != null) {
      dealerHierarchy.push({
        label: x.label,
        Name: x.Name,
        level: x.level,
        parent: x.parent,
      });
      x.parent.children = this.mapChildren(x.parent.children);
      active.push({
        id: -1,
        active: { label: x.label, Name: x.Name, level: x.level, parent: x.parent, children: x.children },
        items: x.parent.children.map((y: OrgHierachy) => {
          return {
            label: y.label,
            children: y.children,
            level: x.level,
            Name: x.Name,
            parent: x.parent,
          };
        }),
      });
      x = x.parent;
    }
    dealerHierarchy.push({
      label: x.label,
      Name: x.Name,
      level: x.level,
      parent: x.parent,
    });
    // x.parent.children = this.mapChildren(x.parent.children);
    active.push({
      id: -1,
      active: { label: x.label, Name: x.Name, level: x.level, parent: x.parent, children: x.children },
      items: this.items,
    });
    active.reverse().map((x: ActiveOrgHierachy, index, arr) => {
      arr[index].id = index;
    });

    dealerHierarchy = dealerHierarchy.reverse();

    this.searchItems.push({ dealerHierarchy, active });
    // this.showHierarchy = true;
  }

  searchYear(event: string) {
    this.modalList = [];
    if (event === '' || event === undefined) {
      this.modalList = [];
      return;
    }
    this.modelsTypes.forEach((element: ModelType, index: number, arr: ModelType[]) => {
      element.children.forEach((elementChild: Model, indexChild: number, arrChild: Model[]) => {
        if (elementChild.label) {
          // tslint:disable-next-line:max-line-length
          if (
            elementChild.label.toLowerCase().includes(this.escapeRegExp(event.toLowerCase())) ||
            elementChild.year.toLowerCase().includes(this.escapeRegExp(event.toLowerCase()))
          ) {
            this.addToSearchListModals(elementChild, element);
          }
        }
      });
    });
    // this.modalYear = this.modalYearCopy.filter((x => x.includes(event)));
  }

  addToSearchListModals(modal: Model, modalType: ModelType) {
    const modalHierarchy = [];
    modalHierarchy.push({
      label: modalType.year,
    });
    modalHierarchy.push({
      label: modalType.label,
    });
    modalHierarchy.push({
      label: modal.label,
    });
    this.modalList.push({
      year: modalType.year,
      modalType: modalType,
      modal: modal,
      modalHierarchy: modalHierarchy,
    });
    this.showMenu = true;
  }

  setDate(filter: CalenderFilter) {
    this.value = filter.value;
  }

  selectModalItem(item: ModelListSearchItem) {
    this.activeYear = item.year;
    this.activeModalType = item.modalType;
    this.activeModal = item.modal;
    this.modalList = [];
  }

  hideDatePicker(event: any) {
    if (!event) {
    }
    if (event.type === 'dropDownRange') {
      //      this.startDate = event.data.value[0];
      //    this.endDate = event.data.value[1];
    }
    if (event.type === 'customRange') {
      //  this.startDate = event.startDate;
      //   this.endDate = event.endDate;
    }
    // this.datePicker.overlayVisible = false;
  }

  setFilterByCurrentLevel(index: number, item: OrgHierachy) {
    this.active[index].active = JSON.parse(this.makeStringify(item));
    if (this.active.length === index + 1) {
      if (item.children && item.children.length > 0) {
        this.active[index + 1] = {
          id: index + 1,
          active: {},
          items: JSON.parse(this.makeStringify(item.children)),
        };
      }
    } else if (this.active.length > index + 1) {
      this.active = this.active.splice(0, index + 1);
      if (item.children && item.children.length > 0) {
        this.active[index + 1] = {
          id: index + 1,
          active: {},
          items: JSON.parse(this.makeStringify(item.children)),
        };
      }
    }
    this.buildBreadCrumb(this.active);
  }

  getObject(doList: any[], item: OrgHierachy) {
    let result: any;
    for (const x of doList) {
      // tslint:disable-next-line:max-line-length
      if (
        this.escapeRegExp(x.Name.toLowerCase()).localeCompare(this.escapeRegExp(item.Name.toLowerCase())) === 0 &&
        this.escapeRegExp(x.label.toLowerCase()).localeCompare(this.escapeRegExp(item.label.toLowerCase())) === 0
      ) {
        result = x;
        return result;
      } else {
        result = this.getObject(x.children, item);
        if (result) {
          return result;
        }
      }
    }
    return result;
  }
  add(index: number, i: OrgHierachy) {
    const item = this.getObject(this.logActive, i);
    // const item = {...result};
    // tslint:disable-next-line:max-line-length
    if (
      item !== undefined &&
      (this.currentLevel - 1 < index || (this.currentLevel - 1 === index && this.currentOrg === item.Name))
    ) {
      this.active[index].active = JSON.parse(this.makeStringify(item));
      if (this.active.length === index + 1) {
        // this.active.push({})
        if (item.children && item.children.length > 0) {
          this.active[index + 1] = {
            id: index + 1,
            active: {},
            items: JSON.parse(this.makeStringify(item.children)),
          };
        }
      } else if (this.active.length > index + 1) {
        this.active = this.active.splice(0, index + 1);
        if (item.children && item.children.length > 0) {
          this.active[index + 1] = {
            id: index + 1,
            active: {},
            items: JSON.parse(this.makeStringify(item.children)),
          };
        }
      }
    } else {
    }

    this.buildBreadCrumb(this.active);
  }
  selectYear(selected: boolean) {
    this.activeYear = this.activeYear;
    this.selectActiveYear = selected;

    // this.modalsOfYear = this.modelsTypes.filter(
    //   (x: ModelType) => x.year === this.activeYear
    // );
    // if (this.modalsOfYear && this.modalsOfYear.length > 0) {
    //   this.activeModalType = { label: '' };
    //   this.activeModal = { label: '' };
    // } else {
    //   this.activeModalType = null;
    //   this.activeModal = null;
    // }
  }

  buildBreadCrumb(items: ActiveOrgHierachy[]) {
    this.items2 = [];
    this.active.forEach((x: ActiveOrgHierachy) => {
      if (x.active !== undefined) {
        if (x.active.label) {
          this.items2.push({
            label: x.active.label,
          });
        }
      }
    });
    localStorage.setItem('active', this.makeStringify(this.active));
    localStorage.setItem('hierarchy', this.makeStringify(this.items2));
    this.filterEmitter.emit(this.items2);
  }
  makeStringify(obj: any) {
    const cache: any = [];
    const s = JSON.stringify(obj, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.indexOf(value) !== -1) {
          return;
        }
        cache.push(value);
      }
      return value;
    });
    return s;
  }
  selectItem(items: OrgHierachySearchItem) {
    this.active = this.mapActiveFromSearch(items.dealerHierarchy);
    this.items2 = items.dealerHierarchy;
    this.searchItems = [];
    const active = this.makeStringify(this.active);
    const hierarchy = this.makeStringify(this.items2);
    localStorage.setItem('active', active);
    localStorage.setItem('hierarchy', hierarchy);
    this.filterEmitter.emit(this.items2);
  }

  showHierarchy_() {
    if (this.showHierarchy) {
      this.orgDone('showHierarchy_');
      this.showHierarchy = false;
      this.searchItems = [];
      return;
    }
    this.toggleMenu('showHierarchy');
    if (this.showDateRange) {
      this.datePicker.showDateRange_({});
    }
  }

  setModalType(item: ModelType) {
    this.activeModalType = item;
    this.activeModal = { label: '' };
    this.activeYear = '';
    this.selectActiveYear = false;
    localStorage.setItem(
      'modal_type',
      this.makeStringify({
        activeModalType: this.activeModalType,
        activeModal: this.activeModal,
        activeYear: this.activeYear,
      })
    );
  }

  setModal(item: Model) {
    this.activeModal = JSON.parse(JSON.stringify(item));
    this.activeYear = JSON.parse(JSON.stringify(item.year));
    this.selectActiveYear = false;
    localStorage.setItem(
      'modal_type',
      this.makeStringify({
        activeModalType: this.activeModalType,
        activeModal: this.activeModal,
        activeYear: this.activeYear,
      })
    );
    // this.activeModalType = item;
    // this.activeModal = { label: '' };
  }

  showMenu_() {
    if (this.showMenu) {
      this.showMenu = false;
      return;
    }
    this.toggleMenu('showMenu');
    if (this.showDateRange) {
      this.datePicker.showDateRange_({});
    }
  }

  showDateRange_() {
    this.toggleMenu('showDateRange');
  }

  openDatePicker() {
    this.showHierarchy = false;
    this.showMenu = false;
    this.showDateRange = true;
  }

  closeall() {
    this.OnFilter.emit('');
    this.showHierarchy = false;
    this.showMenu = false;
    this.showDateRange = false;
    this.filterService.filterDisabledStatus = false;
    if (this.showVocFilters) {
      this.filterService.filterDisabledStatus = true;
      this.showSurveyScoreCtrl = false;
      this.showAspectCategoryCtrl = false;
    }
    if (this.showDateRange) {
      this.datePicker.showDateRange_({});
    }
  }

  orgDone(event: any) {
    let selected = this.active[this.active.length - 1].active;
    if (selected) {
      if (this.isEmpty(selected)) {
        selected = this.active[this.active.length - 2].active;
      }
    }

    if (!event) {
      this.showHierarchy = false;
    }
  }

  isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  modelDone() {
    if (this.selectActiveYear === false) {
      this.activeYear = '';
    }
    this.showMenu = false;
    // this.filterService.updateModel('', this.activeModalType.label, this.activeModal.label);
    this.getSurveyVersionsData(false);
    // this.filterService.updateModel(this.activeYear, this.activeModalType.label, this.activeModal.label);
    this.filterService.updateModel(this.activeYear, this.activeModalType.label, this.activeModal.label);
    this.mapSurveyQuestionsList();
  }

  onMonthSelect(event: any) {
    this.currentMonth = event;
  }
  ngOnDestroy(): void {
    this.surveyVersionChangeSubscripton.unsubscribe();

    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
  ngOnInit() {
    const d = this.filterService.getLocalStorageModalType();
    if (d && d.activeModal && d.activeModalType) {
      this.activeModal = d.activeModal;
      this.activeModalType = d.activeModalType;
    }
    this.dealerType = this.filterService.getDealerType().translate;
    // this.surveyQuestionTitle = this.filterService.getSurveyQuestion().Id;
    this.permissions = this.ngxPermission.getPermissions();
    this.allowdealerType = this.permissions[Permission.REPORTING_SHOW_DEALERTYPE_DROPDOWN] ? true : false;

    this.surveyVersionChangeSubscripton = this.exportService.getSurveyVersion().subscribe((message: any) => {
      this.getSurveyVersionsData(false);
    });

    this.initVocDashboard();

    // const filters = this.filterService.getFilterObject();
    // this.activeModalType = { label: filters.ActiveModalClass };
    // this.activeModal = { label: filters.ActiveModal };
    // this.activeModal = { label: filters.ActiveModalYear };

    // this.filterService.getAllOrgs().subscribe((res: any) => {

    //   this.items = res;
    //   this.active = JSON.parse(localStorage.getItem('active'));
    //   this.logActive = res;

    //   if (!this.active) {
    //     this.active = [];
    //     this.active.push({ id: 0, active: this.items[0], items: this.items });
    //     this.buildBreadCrumb(this.active);
    //   }

    //   if (this.currentLevel && this.currentParent) {
    //     this.setData();
    //   }

    //   this.items2 = JSON.parse(localStorage.getItem('hierarchy'));
    //   if (!this.items2) {
    //     this.items2 = [
    //       {
    //         label: this.active[0].active.label
    //       }
    //     ];
    //   }
    //   this.filterEmitter.emit(this.items2);

    //     this.active.forEach((element: ActiveOrgHierachy, index: number, arr: ActiveOrgHierachy[]) => {
    //                 element.items.forEach((elementchild: OrgHierachy, index: number, arr: OrgHierachy[]) => {
    //                     if(element.active[0].Name === filters.OrgName) {
    //                       this.add(element.id, elementchild);
    //                     }
    //                  });
    //      });

    // });

    // this.active.forEach((element: ActiveOrgHierachy, index: number, arr: ActiveOrgHierachy[]) => {
    // });

    // this.filterService.getVindecoderData().subscribe((res: any) => {
    //   this.modelsTypes = res;
    //   this.activeYear = this.modalYear[0];
    //   this.modalsOfYear = this.modelsTypes.filter(
    //     (x: any) => x.year === this.activeYear
    //   );
    //       this.modalsOfYear = this.modelsTypes;

    //       this.modalsOfYear.forEach((element: ModelType, index: number, arr: ModelType[]) => {
    //         if ( element.label === filters.ActiveModalClass) {
    //           this.setModalType(element);
    //         }
    //       //   element.children.forEach((elementChild: Model, indexChild: number, arrChild: Model[]) => {
    //       //     if ( elementChild.label === filters.ActiveModal) {
    //       //       this.setModal(elementChild);
    //       //     }
    //       //     if (elementChild.year === filters.ActiveModalYear) {
    //       //       this.selectYear(true);
    //       //       this.selectActiveYear = true;
    //       //     }
    //       //  });
    //       });
    //   });
  }

  toggleMenu(field: string) {
    this.showHierarchy = false;
    this.showMenu = false;
    this.showDateRange = false;
    this.showdealerType = false;
    this.showSurveyVersionYear = false;
    this.showLanguagesCtrl = false;
    this.showSentimentCtrl = false;
    this.showAspectCategoryCtrl = false;
    this.showSurveyQuestionCtrl = false;
    this.showSurveyScoreCtrl = false;
    this.showSurveyResponsesCtrl = false;
    this.dealerquery = '';
    this.yearquery = '';
    this.modalList = [];
    this.searchItems = [];
    if (!field) {
      this.filterService.updateCalendarRange(this.currentCalendarDate);
      return;
    }

    switch (field.trim()) {
      case 'showHierarchy':
        this.showHierarchy = true;
        break;
      case 'showMenu':
        this.showMenu = true;
        break;
      case 'showDateRange':
        this.showDateRange = true;
        break;
      case 'showdealerType':
        this.showdealerType = true;
        break;
      case 'showSurveyVersionYear':
        this.showSurveyVersionYear = true;
        break;
      case 'showLanguagesCtrl':
        this.showLanguagesCtrl = true;
        break;
      case 'showSentimentCtrl':
        this.showSentimentCtrl = true;
        break;
      case 'showAspectCategoryCtrl':
        this.showAspectCategoryCtrl = true;
        break;
      case 'showSurveyQuestionCtrl':
        this.showSurveyQuestionCtrl = true;
        break;
      case 'showSurveyScoreCtrl':
        this.showSurveyScoreCtrl = true;
        break;
      case 'showSurveyResponsesCtrl':
        this.showSurveyResponsesCtrl = true;
        break;
    }
  }

  datePickerDone(e: any) {
    this.currentCalendarDate = e.date;
    // tslint:disable-next-line:max-line-length
    if (
      this.currentCalendarDate &&
      typeof this.currentCalendarDate === 'object' &&
      this.currentCalendarDate.length > 1
    ) {
      if (typeof this.currentCalendarDate[0] === 'string' && typeof this.currentCalendarDate[1] === 'string') {
        localStorage.setItem('filter_date', this.currentCalendarDate.join('|'));
      }
    }
    this.filterService.updateCalendarRange(e.date);
    if (e.close) {
      this.toggleMenu('');
    }
  }

  onDateRangeChange(event: any) {
    // this.filterService.updateCalendarRange(event);
  }

  private getAndSetupData(reset: boolean = true) {
    this.filterService.getAllOrgs().subscribe((res: any) => {
      this.setUpDate(reset, res);
    });
  }
  private setUpDate(reset: boolean, res: any) {
    if (reset) {
      this.items = res;
      // console.log(this.items);
      this.active = JSON.parse(localStorage.getItem('active'));
      this.logActive = res;
      if (this.active && this.active.length > 0) {
        this.buildBreadCrumb(this.active);
      } else {
        this.active = [];
        this.active.push({ id: 0, active: this.items[0], items: this.items });
        this.buildBreadCrumb(this.active);
        if (this.currentLevel && this.currentParent) {
          this.setData();
        }
      }
      this.items2 = JSON.parse(localStorage.getItem('hierarchy'));
      if (!this.items2) {
        this.items2 = [
          {
            label: this.active[0].active.label,
          },
        ];
      }
    } else {
      this.logActive = res;
      this.items = res;
      this.setData();
    }
  }
}
