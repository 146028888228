import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, Type } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { WidgetService } from '../widget.service';
import HighCharts from '@clearviewshared/highchart/highchart';
import { FiltersService } from '@app/shared/services/filters.service';
import { CacheService } from '@app/shared/services/cache.service';
import { TranslateService } from '@ngx-translate/core';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { I18nService } from '@app/core';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';

// import { Identifiers } from '@app/shared/services/app.config.type';
// import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
// import { GlobalFilterApiService } from '@app/shared/module/global-filters/global-filters.api.service';
// import { GlobalFilterHelperService } from '@app/shared/module/global-filters/global-filters-helper.service';
// import moment from 'moment-timezone';
// import { generateSurveyTrend } from '@clearviewshared/highchart/helperfunctions';

import { GlobalFilterRouter } from '@app/shared/module/global-filters/global-filter-router';
import { ISurveyTrendWidgetModel } from './type/survey-trend-widget.model';
import { generateSurveyTrend } from '@clearviewshared/highchart/helperfunctions';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';

@Component({
  selector: 'widget-survey-trend',
  templateUrl: './survey-trend-widget.component.html',
  styleUrls: ['./survey-trend-widget.component.scss'],
})
export class SurveyTrendWidgetComponent extends WidgetBase implements OnInit, OnDestroy {
  @Input() showToggle = false;
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @ViewChild('containerSurveyTrend') containerSurveyTrend: ElementRef;

  canRender = false;
  isChecked = false;
  isToggleDisabled = false;
  isAspectCategorySelected = true;
  sentiment: any;
  chart: any;
  api: Subscription;
  months: any[] = [];
  data: ISurveyTrendWidgetModel[];
  permissions: NgxPermissionsObject;
  listMonths: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    public widgetService: WidgetService,
    private cacheService: CacheService,
    private filterService: FiltersService,
    private gbfRouter: GlobalFilterRouter,
    private translateService: TranslateService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService,
    public ngxPermission: NgxPermissionsService,
    public gbfService: GlobalFilterService
  ) {
    super(gbfService, translateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.canRender = true;
    this.permissions = this.ngxPermission.getPermissions();

    this.subscriptions.push(
      this.cacheService.for(this.widgetService.surveytrendWidgetUrl).subscribe((res: any) => {
        if (res) {
          this.data = res;
          this.initChartWork();
          this.canRender = true;
        }
      })
    );

    this.subscriptions.push(
      this.widgetService.update.subscribe(() => {
        this.setAspectCatPreset();
        this.loadWidget();
      })
    );

    this.setAspectCatPreset();
    this.loadWidget();
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getSurveyTrendWidget({ apiConfig: this.apiConfig }).subscribe((res: any) => {
      if (res) {
        this.data = res;
        this.initChartWork();
        this.canRender = true;
        this.widgetService.isAllLoaded = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.api) {
      this.api.unsubscribe();
    }
  }

  getTranslation(key: string) {
    return this.translationHelper.GetSurveyTrendWidgetTranslation(key);
  }

  initChartWork() {
    //  var categories = this.data.map((f)=>this.translateService.getTranslation(this.listMonths[f.Month-1])+" "+f.Year);
    var categories = this.data.map((f) => this.translateService.instant(this.listMonths[f.Month - 1]) + ' ' + f.Year);
    var completionCount = this.data.map((f) => f.Completed);
    var responseRate = this.data.map((f) => f.CompletionRate);

    const chartOptions = generateSurveyTrend(categories, completionCount, responseRate, this.i18nService);
    this.chart = HighCharts['Reflecx'].createOrUpdate(
      this.chart,
      this.containerSurveyTrend.nativeElement,
      chartOptions
    );
  }

  setAspectCatPreset() {
    //throw new Error('Method not implemented.');
  }
}
