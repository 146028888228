<li [attr.class]="config?.rootClass">
  <!-- <label class="filter__label">All Models</label> -->
  <div class="filter-field">
    <div class="filter__trigger">
      <label class="field__label">{{ config?.title }}</label>
      <div class="field__icons">
        <span class="icons__active"> <i class="fal fa-pencil"></i></span>
        <span class="icons__active icon--ok"><i class="fal fa-check"></i> </span>
        <i class="far fa-angle-down"></i>
      </div>
    </div>
    <div class="filter-dropdown dropdown--list dropdown--languagelist" [class.active]="active">
      <app-gbf-list
        (onitemclick)="onItemClicked($event)"
        (onbreadcrumclick)="onListBreadcrumClick($event)"
        [config]="config"
        [from]="'tree'"
        [title]="'Title...'"
        [breadcrums]="breadcrums"
        [listRootClassName]="'voc-category-list'"
        [values]="activatedItems"
        [active]="active"
      >
      </app-gbf-list>
    </div>
  </div>
</li>
