import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { FiltersService } from '@app/shared/services/filters.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GlobalFilterHelperService } from '../../global-filters-helper.service';
import { GlobalFilterService } from '../../global-filters.service';
import { GbfBase } from '../gbf-base';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';

@Component({
  selector: 'app-gbf-slider',
  templateUrl: 'global-filter-slider.component.html',
})
export class GlobalFilterSliderComponent extends GbfBase implements OnInit {
  @Input() active = false;
  render = true;
  surveyscore: string = 'Survey Score';
  ctx = this;
  min: number = 0;
  max: number = 100;
  scoreval: any = [0, 100];
  type: string = 'Promoters';
  constructor(
    public injector: Injector,
    public gbfHelperService: GlobalFilterHelperService,
    public gbfService: GlobalFilterService,
    public filtersService: FiltersService,
    public cd: ChangeDetectorRef,
    public translateService: TranslateService,
    private appConfig: AppConfigurationService
  ) {
    super(injector, gbfService, gbfHelperService, translateService);
  }
  async ngOnInit() {
    this.setLocalStorageValue();
    let rangeConfiguration = this.getScoreConfigurationData();
    this.min = rangeConfiguration[0];
    this.max = rangeConfiguration[1];
    this.scoreval = rangeConfiguration;
    this.type = this.gbfService[this.config.name] ?? this.gbfService[this.config.name].Type;
    var kpi = this.getKPIConfigurationData().filter((array: any) => {
      return array.surveyTypes.includes(this.filtersService.getProgram());
    });
    this.surveyscore = kpi[0].label ?? 'Survey Score';
    this.gbfService.gbfTabChange.subscribe(() => {
      var kpi = this.getKPIConfigurationData().filter((array: any) => {
        return array.surveyTypes.includes(this.filtersService.getProgram());
      });
      this.surveyscore = kpi[0].label ?? 'Survey Score';
    });
  }

  getKPIConfigurationData() {
    const opts = this.appConfig.getByIdentifier(Identifiers.ConfigEventsKPI);
    if (opts) {
      if (opts.ClientConfig === null) {
        opts.ClientConfig = opts;
      }
      if (opts.ClientConfig) {
        return opts.ClientConfig.Fields;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  getScoreConfigurationData() {
    const opts = this.appConfig.getByIdentifier(Identifiers.FilterBarScoreRange);
    if (opts) {
      if (opts.ClientConfig === null) {
        opts.ClientConfig = opts;
      }
      if (opts.ClientConfig) {
        return opts.ClientConfig.Fields;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  getFilterScoreConfigurationData() {
    const opts = this.appConfig.getByIdentifier(Identifiers.FilterBarScoreRange);
    if (opts) {
      if (opts.ClientConfig === null) {
        opts.ClientConfig = opts;
      }
      if (opts.ClientConfig) {
        return opts.ClientConfig.Fields;
      } else {
        return [0, 100];
      }
    } else {
      return [0, 100];
    }
  }
  silderBarRange(type: string, firstValue: number, secondValue: number) {
    var rangemultiplyer = this.max / 100;
    firstValue = firstValue * rangemultiplyer;
    secondValue = secondValue * rangemultiplyer;

    this.gbfService[this.config.name] = {
      Id: '',
      Type: type,
      Score: [firstValue, secondValue],
      Types: [],
    };
    this.scoreval[0] = firstValue;
    this.scoreval[1] = secondValue;
    this.type = type;
    this.setItemToLocalStorage(this.config.localStorageKey, this.gbfService[this.config.name]);
    this.callApiOn('selection');
  }
  getRange(firstValue: number, secondValue: number) {
    var rangemultiplyer = this.max / 100;
    firstValue = firstValue * rangemultiplyer;
    secondValue = secondValue * rangemultiplyer;
    return firstValue + ' to ' + secondValue;
  }

  changeSlider(e: any) {
    if (this.gbfService[this.config.name]) {
      this.gbfService[this.config.name].Score = e.values;
      this.gbfService[this.config.name].Type = 'global_filter_label.Survey Score';
      this.setItemToLocalStorage(this.config.localStorageKey, this.gbfService[this.config.name]);
      this.callApiOn('selection');
    }
  }
}
