import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  Renderer2,
  ViewChild,
  HostListener,
} from '@angular/core';
import { MenuItem } from '@primeng';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BreadCrumbService } from '@app/shared/services/breadcrumb.service';
import { filter, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { BreadCrumb } from '@app/core/types/breadCrumb';
import { HeaderTabService } from '@app/shared/services/header-tab.service';
import { AuthenticationService, I18nService } from '@app/core';
import { FiltersService } from '@app/shared/services/filters.service';
import { NgxPermissionsService, NgxPermissionsObject } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
import { TranslateService } from '@ngx-translate/core';
import { ExportService } from '@clearviewshared/services/export.service';
import { SidebarService } from '@app/shared/services/sidebar.service';
import { ISurveyQuestion } from '@app/core/types/surveyQuestion';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { OnDestroy } from '@angular/core';
import { GlobalFilterApiService } from '@app/shared/module/global-filters/global-filters.api.service';
import { memoize } from 'lodash';
import { GlobalFilterConfigService } from '@app/shared/module/global-filters/global-filters-config.service';

const noMobileModules = ['report-generator', 'cross-tabs'];
@Component({
  selector: 'appc-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent implements OnInit, OnDestroy {
  @Input() pageTitle: string;
  @Input() showFilter: boolean;
  @Input() showDynamicFilters: boolean = false;
  @Input() showTabs: boolean;
  @Input() showApplyFilterButton = true;
  @Input() showHirerchy = true;
  @Input() showModel = true;
  @Input() showVersion = true;
  @Input() showDealer = true;
  @Input() applyDirective = false;
  @Input() hideSomeFilters = true;
  @Input() showProgramDate = true;
  @Input() showlanguage = false;
  @Input() showResponseList = false;
  @Input() showCategoryList = false;
  @Input() showSurveyScoreList = false;
  @Input() showsurveyquestion = true;
  @Input() showVocFilters = false;
  @Input() showSmVocFilters = false;
  @Input() showSMFilters = false;
  @Input() allowDateFilters = false;
  @Input() showVersionList = false;
  @Input() showCarModel = false;
  @Input() showSourceTypeFilter = false;
  @Input() isSMCompetitorReport = false;
  @ViewChild('morgFilters', { static: false }) morgFilters: ElementRef;
  @Input() orgMaxLevel = 6;
  @Input() showSentimentFilters = false;
  @Input() showCategoryFilters = false;
  @Input() showCustomRange = false;
  @Input() showBreadCrumOrg = true;
  @Output() TabSelect: EventEmitter<string> = new EventEmitter<string>();
  @Input() showLanguageChFilters = false;
  @Output() FilterChange: EventEmitter<MenuItem[]> = new EventEmitter<MenuItem[]>();
  @Output() FilterApply: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('filterBar', { static: true }) filterBar: any;
  menu: any;
  fullmenu: any;
  program: any;
  tabs: any[];
  canRender = false;
  items: MenuItem[];
  home: MenuItem;
  // breadCrumbs: any;
  pageName: string;
  ClientConfiguration: any;
  TabPermission: boolean;
  FiltersPermission: boolean;
  permissions: NgxPermissionsObject;
  event = 'CPO';
  multiOrgSingleTitles: any[] = [];
  multiOrgExecuteCounter = 0;
  multiOrgCacheTitle: any = [];
  mOverLay = false;
  mOverLayContext = '';
  visible = false;
  multiOrgCacheTitleToolTip: any = [];
  private subscriptions: Subscription[] = [];
  constructor(
    private i18Service: I18nService,
    private activatedRoute: ActivatedRoute,
    public breadCrumbService: BreadCrumbService,
    public headerTabService: HeaderTabService,
    private sideBarService: SidebarService,
    private filtersService: FiltersService,
    public gbfService: GlobalFilterService,
    public gbfApiService: GlobalFilterApiService,
    private renderer: Renderer2,
    private el: ElementRef,
    private router: Router,
    public ngxPermission: NgxPermissionsService,
    public authService: AuthenticationService,
    private translateService: TranslateService,
    private exportService: ExportService,
    public gbfConfig: GlobalFilterConfigService
  ) {
    this.tabs = [];
    this.ClientConfiguration = this.authService.authData.ClientInfo;
    this.program = this.filtersService.getProgram();
    // this.tabs = this.ClientConfiguration.Events;
    // this.tabs = this.tabs.map((x) => {
    //   return {
    //     label: x.Label,
    //     command: () => this.onTabSelect(x.Label),
    //     Label: x.Label,
    //   };
    // });
    // if (this.ClientConfiguration.OverAllEventReporting) {
    // this.tabs.unshift({
    //   label: 'Over All',
    //   command: () => this.onTabSelect('Over All')
    // });
    //  }
    this.activatedRoute.data.subscribe((x) => {
      this.pageName = this.convertToClassName(x.breadcrumb);
    });

    this.items = JSON.parse(localStorage.getItem('hierarchy'));
    if (!this.items) {
      this.items = [{ label: this.gbfService.getActiveOrgHierarchy().OrgName }];
    }
    this.subscriptions.push(
      this.gbfService.gbfOnApplyFilter.subscribe((e: any) => {
        this.FilterApply.emit('');
      })
    );
    this.subscriptions.push(
      this.gbfService.gbfTabChange.subscribe((e: any) => {
        if (Array.isArray(e)) {
          this.onTabSelect(e[0]);
        } else {
          this.onTabSelect(e);
        }
      })
    );
    // this.home = {icon: 'pi pi-home'};
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });
  }

  convertToClassName(value: string): string {
    return value.toLowerCase().split(' ').join('-');
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: any): void {
    if (
      this.mOverLay == true &&
      this.morgFilters?.nativeElement &&
      !this.morgFilters?.nativeElement?.contains(event.target)
    ) {
      this.mOverLay = false;
    }
  }

  public setOrgMaxLevelProp() {
    const active = this.filtersService.inMemoryActiveHierarchy || JSON.parse(localStorage.getItem('active')) || [];
    if (active.length > this.orgMaxLevel) {
      const nActive: any = [];
      active.forEach((act: any, index: number) => {
        if (act.id <= this.orgMaxLevel) {
          if (act.active.level === undefined || act.active.level <= this.orgMaxLevel) {
            nActive.push(act);
          }
        }
      });
      this.filtersService.inMemoryActiveHierarchy = nActive;
      localStorage.setItem('active', JSON['makeStringify'](nActive));
    }
  }

  onFilterEmit(breadcrumbs: MenuItem[]) {
    this.items = breadcrumbs;
    this.FilterChange.emit(breadcrumbs);
  }
  onFilterApply(event: any) {
    this.FilterApply.emit('');
  }

  shouldDisplay(pageName: string) {
    if (noMobileModules.find((p) => p === pageName) && window.innerWidth < 1024) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.setOrgMaxLevelProp();
    this.permissions = this.ngxPermission.getPermissions();
    this.fullmenu = this.sideBarService.getItems();
    this.TabPermission = this.permissions[Permission.MENU_ProgramTabs] ? true : false;
    this.FiltersPermission = this.permissions[Permission.MENU_Filter] ? true : false;

    this.headerTabService.getEditModeObservor().subscribe((x) => {
      document.querySelectorAll('.widget').forEach((widgets) => {
        if (x) {
          widgets.classList.add('edit--translation');
        } else {
          widgets.classList.remove('edit--translation');
        }
      });
    });

    // For Global Translation
    this.tabs.forEach((element: any, index: number) => {
      if (element.label.toLowerCase() !== 'over all') {
        element.label = this.translateService.instant('event_' + element.label.toLowerCase() + '.title');
      }
    });
    try {
      const program = JSON.parse(localStorage.getItem('program'));
      if (program) {
        this.gbfService.program = program;
        this.filtersService.program = program;
        // this.onTabSelect(program);
      } else {
        // this.onTabSelect(this.filtersService.program);
      }
    } catch (e) {
      // console.log(e);
    }
    // this.onTabSelect("Sales");
    // this.onTabSelect(this.program);
    this.canRender = true;
    const LCurrenTab = JSON.parse(localStorage.getItem('gbf_tab'));
    this.hideFrftReport(LCurrenTab.value);
  }

  openModalFilters(e: any) {
    setTimeout((x) => {
      this.mOverLay = true;
    }, 0);
  }

  onTabSelect(program: string) {
    const url = this.router.url;
    this.gbfService.program = program;
    this.gbfApiService.mapVersionList({});
    let moduleName = localStorage.getItem('language-tab');
    if (!moduleName) {
      moduleName = url.substr(url.lastIndexOf('/') + 1, url.length - url.lastIndexOf('/'));
    }
    let hasFRFTReport = this.hideFrftReport(program);
    if (moduleName !== 'summary' && moduleName !== 'list' && !hasFRFTReport) {
      this.sideBarService.setSidebarItems(this.fullmenu);
    }
    this.filtersService.setProgram(program);
    const chkprogram = localStorage.getItem('program');
    localStorage.setItem('program', JSON.stringify(program));
    this.exportService.setSurveyVerion('event change');
    if (url) {
      const checkvoc = url.split('/');
      if (checkvoc && checkvoc.includes('voc')) {
        this.checkvocsurveyQuestion(program, chkprogram).then((res) => {
          this.filtersService.setTab(program);
          this.TabSelect.emit(program);
        });
      } else {
        this.TabSelect.emit(program);
      }
    } else {
      this.TabSelect.emit(program);
    }
  }
  hideFrftReport(program: any) {
    let hasFRFTReport = false;
    if (program === 'Sale') {
      let hasExist = this.fullmenu.some((route: any) => route.name === 'FRFT Dashboard');
      if (hasExist) {
        {
          hasFRFTReport = true;
          let _fullmenu = this.fullmenu.filter((item: any) => item.name !== 'FRFT Dashboard');
          this.sideBarService.setSidebarItems(_fullmenu);
        }
      }
    }
    return hasFRFTReport;
  }
  getActiveItem() {
    if (this.tabs && this.tabs.length > 0) {
      const p = this.filtersService.getProgram();
      if (p) {
        return this.tabs.find((x) => x.Label === p);
      } else {
        return this.tabs.find((x) => x.Label === this.ClientConfiguration.DealerEvent);
      }
    }
  }

  checkvocsurveyQuestion(program: any, chkprogram: any) {
    if (chkprogram) {
      chkprogram = chkprogram.replace('"', '').replace('"', '');
    }
    if (chkprogram !== program) {
      localStorage.removeItem('Preserve_survey_responses');
    }
    const p1 = new Promise((resolve, reject) => {
      this.gbfApiService.getSurveyQuestion('', (n: any) => {
        resolve(1);
      });
    });
    const p2 = new Promise((resolve, reject) => {
      const responseConfig = this.gbfConfig.filterConfig.config.find((x: any) => x.property == 'surveyResponses');
      this.gbfApiService.getSurveyResponse(responseConfig, (n: any) => {
        resolve(1);
      });
    });
    return Promise.all([p1, p2]);
    // this.filtersService.getSurveyQuestionData().subscribe(
    //   (res: ISurveyQuestion[]) => {
    //     if (res) {
    //       localStorage.removeItem('survey_question');
    //       res.map((val: any) => {
    //         val.IsChecked = true;
    //         val.IsSelected = false;
    //       });
    //       res.forEach((val: any) => {
    //         val.IsChecked = true;
    //         val.IsSelected = false;
    //         val.Title = val.Title.replace(/(<([^>]+)>)/gi, '')
    //           .replace('{{', '')
    //           .replace('}}', '');
    //         val.Options.forEach((opt: any) => {
    //           opt.IsChecked = true;
    //           opt.IsSelected = false;
    //         });
    //       });

    //       res.unshift({
    //         Id: 'All',
    //         QuestionId: '',
    //         Title: 'All',
    //         Type: 'toggle',
    //         Options: [],
    //         IsChecked: true,
    //         IsSelected: false,
    //         IsAddClass: '',
    //       });
    //       this.filtersService.setSurveyQuestion(res);
    //       this.TabSelect.emit(program);
    //     }
    //   },
    //   (error: any) => {}
    // );
  }
  getMultiOrgTitle() {
    if (this.mOverLay) {
      return this.multiOrgCacheTitle;
    }
    if (this.multiOrgExecuteCounter > 0 && this.multiOrgExecuteCounter <= 20) {
      this.multiOrgExecuteCounter += 1;
      return this.multiOrgCacheTitle;
    } else {
      this.multiOrgExecuteCounter = 0;
    }
    let title: any = [];
    if (this.gbfService?.multi_org?.length > 0) {
      const maxOrgLevel = Math.max.apply(
        Math,
        this.gbfService['multi_org'].map((o: any) => {
          return o.level;
        })
      );
      const levelSelection = this.gbfService['multi_org'].filter((x: any) => x.level === maxOrgLevel);
      if (levelSelection.length > 1) {
        const orgSelectedList: any = [];
        levelSelection.forEach((ls: any) => {
          let tempOrg: any = ls;
          const multiOrgSingleTitles: any = [];
          while (tempOrg && (tempOrg.parent !== 'null' || tempOrg.parent !== null)) {
            multiOrgSingleTitles.push({ label: tempOrg.label });
            tempOrg = this.gbfService['multi_org'].find((x: any) => x.id === tempOrg.parent);
          }
          multiOrgSingleTitles.reverse();
          orgSelectedList.push(multiOrgSingleTitles);
        });
        this.multiOrgCacheTitleToolTip = orgSelectedList;
        title = [{ label: `${levelSelection[0].label} and ${levelSelection.length - 1} More Selected` }];
      } else if (levelSelection.length === 1) {
        const multiOrgSingleTitles: any = [];
        title = `${levelSelection[0].label}`;
        let tempOrg: any = levelSelection[0];
        while (tempOrg && (tempOrg.parent !== 'null' || tempOrg.parent !== null)) {
          multiOrgSingleTitles.push({ label: tempOrg.label });
          tempOrg = this.gbfService['multi_org'].find((x: any) => x.id === tempOrg.parent);
        }
        title = multiOrgSingleTitles.reverse();
        this.multiOrgCacheTitleToolTip = [];
      } else {
        title = [{ label: `Selected` }];
        this.multiOrgCacheTitleToolTip = [];
      }
    }
    this.multiOrgExecuteCounter += 1;
    this.multiOrgCacheTitle = title;
    return title;
  }
}
