<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
  [widgetTitleToolTip]="widgetTitleToolTip"
>
<ng-container widgetHeader>
  <div class="widget-node">
    <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
  </div>
</ng-container>
  <ng-container widgetArea>
    <div class="valign__content">
      <div class="valign__graph">
        <div class="nodata-wrapper" [hidden]="showsWidgets">
          <div class="nodata">
            <i class="nodata__icon fal fa-info-circle"></i>
            <p class="nodata__msg">{{ 'Please select a Dealer from the Hierarchy Filter' | translate }}</p>
          </div>
        </div>
        <div [hidden]="!showsWidgets">
          <div class="widget__content" [attr.data-can-render]="canRender">
            <div class="asr-utilization">

              <div class="col__left">
                <div  #containerDVBCount></div>
      </div>
         <div class="col__right">
          <div  #containerDVBCount2></div>
      </div>
      </div>
            
          </div>
        </div>

      </div>
    </div>
    <!-- <div class="nodata-wrapper" *ngIf="noResult">
      <div class="nodata">
          <i class="nodata__icon fal fa-info-circle"></i>
          <p class="nodata__msg">{{'No Result  Found' |translate}}</p>
      </div>
    </div> -->
  </ng-container>
</app-widget-dynamic>
