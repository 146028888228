<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
<ng-container widgetHeader>
  <div class="widget-node widget-node__print" *ngIf="allowDropDownShow">
      <p-dropdown
      [autoZIndex]="false"
      class="cba-ctrl"
      [options]="kpiNames"
      placeholder="{{'Overall' | translate }}"
      (onChange)="onKPIChange($event)"
      [(ngModel)]="name"
      [style]="{'width':'200px'}"
    >
    </p-dropdown>


  </div>

  <div class="widget-node"  *ngIf="clickThrough && hasOrgSummaryPermission">
    <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
  </div>
  <!-- <div class="widget-node" *ngIf="clickThrough">
    <a class="handler more" (click)="fireClickThrough($event)" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
  </div> -->
  <div class="widget-node">
    <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
  </div>
</ng-container>
<div widgetArea class="widget__content tb-performers-dropdown" *ngIf="canRender" [attr.data-can-render]="canRender">
  <div class="tb-performers-scores">
      <table class="table-reporting">
      <tbody>
        <tr>
          <th *ngFor="let col of topPerfCol; let ii = index" [class.clr-green]="ii == 0">
            {{ col.field === 'score' ? name : col.header }}
          </th>
        </tr>
        <tr *ngFor="let tp of topPerformer;let ii = index">
          <td *ngFor="let col of topPerfCol">
            <span *ngIf="col.field!='score' && col.field!='kpi_name'">
              <!-- [routerLink]="performer.route[col.value]" -->
              {{ tp[col.field] }}
            </span>
            <a *ngIf="col.field=='kpi_name'" (click)="orgSummaryRoute(tp['kpi_name'], tp['code'], filters)">
              <!-- [routerLink]="performer.route[col.value]" -->
              {{ tp[col.field] }}
            </a>

            <div *ngIf="col.field=='score'" class="percentage-topbottom">
              <h3>
                <span> {{changeValue(tp[col.field])}}</span>
              </h3>
              <p-progressBar class="progress--green" [value]="tp[col.field]" [showValue]="false"> </p-progressBar>
            </div>

            <!-- <p-progressBar *ngIf="col.field=='Score'" class="progress--green"
              value="{{removeCharacter(tp[col.field])}}"
              [showValue]="false"
              ></p-progressBar>
              <span *ngIf="col.field=='Score'" class="completion__percentage"></span> -->
          </td>
        </tr>
        <tr>
          <th class="clr-red" *ngFor="let col of bottomPerfCol">
            <span *ngIf="col.show">
              {{ col.header }}
            </span>
          </th>
        </tr>
        <tr *ngFor="let bp of bottomPerformer">
          <td *ngFor="let col of bottomPerfCol">
            <span *ngIf="col.field!='score' && col.field!='kpi_name'">
              <!-- [routerLink]="performer.route[col.value]" -->
              {{ bp[col.field] }}
            </span>
            <a *ngIf="col.field=='kpi_name'" (click)="orgSummaryRoute(bp['kpi_name'], bp['code'], filters)">
              <!-- [routerLink]="performer.route[col.value]" -->
              {{ bp[col.field] }}
            </a>
            <div *ngIf="col.field=='score'" class="percentage-topbottom">
              <h3>
                <span> {{ changeValue(bp[col.field]) }} </span>
              </h3>
              <p-progressBar class="progress--red" [value]="bp[col.field]" [showValue]="false"></p-progressBar>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</app-widget-dynamic>
<!-- </div> -->
