import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'grid-body-menu',
  templateUrl: './body-menu.component.html',
  styleUrls: ['./body-menu.component.scss'],
})
export class BodyMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
