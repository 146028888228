<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <ng-container widgetArea>
    <div class="valign__content">
      <div class="valign__graph">
        <div class="widget__content" [attr.data-can-render]="canRender">
          <div class="widget__graph">
            <div class="nodata-wrapper" *ngIf="canRender && calc_points.length === 0">
              <div class="nodata">
                <i class="nodata__icon fal fa-info-circle"></i>
                <p class="nodata__msg">{{ 'No Result Found' | translate }}</p>
              </div>
            </div>
            <div style="width: 101%" #chart></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-widget-dynamic>
