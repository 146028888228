import { Identifiers } from '@app/shared/services/app.config.type';
import { AreaOfImprovementComponent } from './areas-for-improvement/areas-for-improvement';
import { CustomerCommentsComponent } from './customer-comments/customer-comments.component';
import { CustomerJourneyWidgetComponent } from './customer-journey-widget/customer-journey-widget.component';
import { DealerShipPerformanceComponent } from './dealer-ship-performance/dealer-ship-performance.component';
import { HeatMapComponent } from './heat-map/heat-map.component';
import { KeyPerformanceIndicators } from './key-performance-indicators/key-performance-indicators.component';
import { KpiMeterComponent } from './kpi-meter/kpi-meter.component';
import { NPSScoreComponent } from './nps-score/nps-score.component';
import { PerformanceTrendQuestionsComponent } from './performance-trend-questions/performance-trend-questions.component';
import { PerformanceTrendQuestionsModule } from './performance-trend-questions/performance-trend-questions.module';
import { PerformanceTrendComponent } from './performance-trend/performance-trend.component';
import { ReasonsEscalationsComponent } from './reasons-escalations/reasons-escalations.component';
import { RecommendedActionsComponent } from './recommended-actions/recommended-actions.component';
import { SurveyCompletionRateComponent } from './survey-completion-rate/survey-completion.component';
import { SurveyListWidgetComponent } from './survey-list-widget/survey-list-widget.component';
import { SurveyTrendWidgetComponent } from './survey-trend-widget/survey-trend-widget.component';
import { TopBottomPerformerComponent } from './top-bottom-performers/top-bottom-performers.component';
import { TopResolutionsComponent } from './top-resolutions/top-resolutions.component';
import { TotalAlertsComponent } from './total-alerts/total-alerts.component';
import { YearOverYearComponent } from './year-over-year/year-over-year.component';
import { CustomerJourneyWidgetComponentV2 } from './customer-journey-widgetv2/customer-journey-widget-v2.component';
import { StaffPerformanceTrendComponent } from './staff-performance-trend/staff-performance-trend.component';
import { CorrectContactInfoOutstandingComponent } from './correct-contact-info-outstanding/correct-contact-info-outstanding.component';
import { FrftByServiceAdvisorTechnicianComponent } from './frft-by-service-advisor-technician/frft-by-service-advisor-technician.component';
import { NissanUniversityCertificationComponent } from './nissan-university-certification/nissan-university-certification.component';
import { AsrUtilizationComponent } from './asr-utilization/asr-utilization.component';
import { KpiMeterNewComponent } from './kpi-meter-new/kpi-meter-new.component';
import { PortalTrafficWidgetComponent } from './portal-traffic-widget/portal-traffic-widget.component';
import { DVBWidgetComponent } from './dealer-variable-bonus-widget/dealer-variable-bonus-widget.component';
import { FrftModelQ6noComponent } from './frft-model-q6no/frft-model-q6no.component';
import { FrftRootCausesComponent } from './frft-root-causes/frft-root-causes.component';
import { FrftCustomerCommentsComponent } from './frft-customer-comments/frft-customer-comments.component';
import { FixedRightFirstTimeTrendComponent } from './fixed-right-first-time-trend/fixed-right-first-time-trend.component';
import { PerformanceTrendWidgetComponent } from './performance-trend-widget/performance-trend-widget.component';
import { Top5OpportunityComponent } from './top-5-opportunity/top-5-opportunity.component';
import { FeaturedScoreComponent } from './featured-score/featured-score.component';

export class WidgetHelperService {
  static dealerPerofrmaceSettings(context: any, settings: any) {
    const { allEvents, appConfig } = settings;
    const config = appConfig.getByIdentifier(Identifiers.DealerPerformanceEvents);
    if (config && config.Fields && context.component === 'dealer-ship-performance') {
      const dealerMapping = JSON.parse(config.Fields.replace(/\'/g, '"'));
      // console.log(dealerMapping);
      if (context.events.length > 1) {
        context.events = [context.events[0]];
        context.rootClass = 'card-xl';
      } else {
        const tempEvents: any = [];
        (dealerMapping[context.events[0].Event] || []).forEach((event: string) => {
          const found = allEvents.find((x: any) => x.Event === event);
          if (found) {
            tempEvents.push(found);
          }
        });
        context.extra = { events: [...context.events, ...tempEvents] };
        context.events = tempEvents;
        if (tempEvents.length < 2) {
          context.rootClass = 'card-xl';
        } else {
          context.rootClass = 'card-medium';
        }
      }
    }
  }
}

export const WidgetsRegistry = {
  reasonsescalations: { component: ReasonsEscalationsComponent },
  'kpi-meter': { component: KpiMeterComponent },
  'year-over-year-widget': { component: YearOverYearComponent },
  'app-customer-journey-widget': { component: CustomerJourneyWidgetComponent },
  'app-customer-journey-widget-v2': { component: CustomerJourneyWidgetComponentV2 },
  'recommended-actions-widget': { component: RecommendedActionsComponent },
  'key-performance-indicators-widget': { component: KeyPerformanceIndicators },
  'top-resolutions-widget': { component: TopResolutionsComponent },
  'nps-score-widget': { component: NPSScoreComponent },
  'performance-trend-questions-widget': { component: PerformanceTrendQuestionsComponent },
  'total-alerts-widget': { component: TotalAlertsComponent },
  'survey-completion-widget': { component: SurveyCompletionRateComponent },
  'heat-map-widget': { component: HeatMapComponent },
  'dashboard-performance-trend-widget': { component: PerformanceTrendComponent },
  'area-of-improvement': { component: AreaOfImprovementComponent },
  'top-bottom-performer': { component: TopBottomPerformerComponent },
  'survey-list-widget': { component: SurveyListWidgetComponent },
  'widget-survey-trend': { component: SurveyTrendWidgetComponent },
  'frft-root-causes': { component: FrftRootCausesComponent },
  'frft-model-q6no': { component: FrftModelQ6noComponent },
  'staff-performance-trend': { component: StaffPerformanceTrendComponent },
  'correct-contact-info-outstanding': { component: CorrectContactInfoOutstandingComponent },
  'frft-by-service-advisor-technician': { component: FrftByServiceAdvisorTechnicianComponent },
  'nissan-university-certification': { component: NissanUniversityCertificationComponent },
  'asr-utilization': { component: AsrUtilizationComponent },
  'dealer-variable-bonus-widget': { component: DVBWidgetComponent },
  'kpi-meter-new-widget': { component: KpiMeterNewComponent },
  'portal-traffic-widget': { component: PortalTrafficWidgetComponent },
  'top-5-opportunity': { component: Top5OpportunityComponent },
  'featured-score': { component: FeaturedScoreComponent },
  'customer-comments-widget': { component: CustomerCommentsComponent },
  'frft-customer-comments-widget': { component: FrftCustomerCommentsComponent },

  'dealer-ship-performance': {
    component: DealerShipPerformanceComponent,
    beforeInit: WidgetHelperService.dealerPerofrmaceSettings,
  },
  'fixed-right-first-time-trend': { component: FixedRightFirstTimeTrendComponent },
  'performance-trend-widget': { component: PerformanceTrendWidgetComponent },
};
