import { Injectable } from '@angular/core';
import { Logger } from '@app/core';

const log = new Logger('Widget Mapper Service');

@Injectable()
export class WidgetMapperService {
  mapTopBottomPerformer(res: any) {
    res.Top.forEach((elem: any, index: number) => {
      res.Top[index].Score = parseFloat(elem.Score.toFixed(2)) + '%';
    });
    res.Bottom.forEach((elem: any, index: number) => {
      res.Bottom[index].Score = parseFloat(elem.Score.toFixed(2)) + '%';
    });
    return res;
  }
}
