<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
  [widgetTitleToolTip]="widgetTitleToolTip"
>
  <ng-container widgetArea>
    <div class="valign__content">
      <div class="valign__graph">
        <!-- <div class="nodata-wrapper" *ngIf="showsWidgets">
          <div class="nodata">
            <i class="nodata__icon fal fa-info-circle"></i>
            <p class="nodata__msg">{{ 'No Result Found' | translate }}</p>
          </div>
        </div> -->
        <div  style="display: flex; align-items: center; justify-content: center;" [attr.data-can-render]="canRender">
          <div>
            <div class="clear" #containerDVBCount></div>
          </div>
        </div>
        <div style="text-align: center; margin-bottom: 20px; color: gray;">
          <h2>MTD QP Overall Statisfication Score</h2>
          {{ kpiScore}}
          </div>
      </div>
    </div>
  </ng-container>
</app-widget-dynamic>
