import { Component, OnInit, Input } from '@angular/core';
import { Notification } from '@app/core/types/notifications';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { FiltersService } from '@app/shared/services/filters.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
  @Input() Notifications: Notification[];
  timeZoneCode: string;

  public config: PerfectScrollbarConfigInterface = {};

  constructor(
    private notificationService: NotificationsService,
    private filterService: FiltersService,
    private toastService: ToastService
  ) {}
  ngOnInit() {
    this.timeZoneCode = this.filterService.timeZoneCode;
  }
  decorateMessage(message: string): string {
    return this.toastService.decorateMessgaeTranslation(message, false);
  }
}
