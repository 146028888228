<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <ng-container widgetArea>
    <div style="text-align: right" [attr.data-can-render]="canRender">
      <div #recommendedactions [style.height]="showdiv ? '400px' : '0px'"></div>
      <br />
      <div *ngIf="grid" class="widget__content">
        <table class="table-reporting" *ngIf="grid">
          <tbody>
            <tr *ngIf="showdiv">
              <th>
                <ng-container *ngIf="IsAppButtonVisible"
                  >+AP {{'recommanded_action_sub_heading' | translate}}
                </ng-container>
              </th>
              <th></th>
            </tr>
            <ng-container *ngIf="IsAppButtonVisible">
              <ng-container *ngFor="let impactData of grid.Heirarcy;let ii = index;">
                <tr *ngIf="ii<lastNode">
                  <td style="width: 90%">
                    <a (click)="createActionRoute(impactData);" style="color: #058dc7"
                      >{{ impactData.DealerName | translate }}</a
                    >
                  </td>
                  <td>
                    <a
                      (click)="orgSummaryRoute(impactData,filters);"
                      style="font-weight: bold; margin-right: 10px; color: #058dc7"
                      >{{impactData.Score.toFixed(1) | number }}</a
                    >
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <div class="nodata-wrapper" *ngIf="!showdiv">
      <div class="nodata">
        <i class="nodata__icon fal fa-info-circle"></i>
        <p class="nodata__msg">{{'No Result Found' |translate}}</p>
      </div>
    </div>
  </ng-container>
</app-widget-dynamic>
