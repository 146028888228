import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replacer',
})
export class ReplacerPipe implements PipeTransform {
  transform(val: any, token: string = ''): string {
    if (val !== undefined && val !== null) {
      var regex = new RegExp(`/[${String.fromCharCode(160)}]/g`);
      // here we just remove the commas from value
      return val.toString().replace(/[ ,]/g, '');
    } else {
      return '';
    }
  }
}
