import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'grid-body-header',
  templateUrl: './body-header.component.html',
  styleUrls: ['./body-header.component.scss'],
})
export class BodyHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
