import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  OnChanges,
  SimpleChanges,
  AfterContentInit,
  Directive,
} from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '@app/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class Widget {
  @Input() widgetTitle: string;
  @Input() widgetTitleToolTip: string;
  @Input() removeWidgetClass = false;
  @Input() headerTemplate: TemplateRef<any>;
  // @ViewChild('header')
  @ViewChild('wh') wh: any;
  @Input() styleClass: string;
  @Input() dataMatchHeightClass = '';
  @Input() clickThrough: string;
  @Input() showMenu: boolean;
  @Input() group = 'large';
}

@Component({
  selector: 'widget-base',
  templateUrl: './widget-base.html',
})
export class WidgetBase extends Widget implements OnInit, OnChanges {
  routerPrefix = '';
  activeFilter = false;
  items = [
    {
      label: this.translateService.instant('Export'),
      icon: 'fal fa-file-excel',
      command: () => {
        //      this.showModal('table');
      },
    },
    {
      label: this.translateService.instant('translate.label'),
      icon: 'fal fa-language',
      command: () => {
        localStorage.setItem('module', this.i18nService.moduleName);
        localStorage.setItem('tab', this.i18nService.tabName);

        this.i18nService.filterTranslationData = this.i18nService.translationData.Data.find(
          (x: any) => x.Module === this.i18nService.moduleName && x.TabName === this.i18nService.tabName
        );

        const data = this.i18nService.filterTranslationData.Values.find((x) => x.Value.Value === this.widgetTitle);
        if (data) {
          const widget = data.Key.split('.')[0];
          localStorage.setItem('widget', widget);
        }
        this.route.navigate([`/${environment.reportingRoutePrefix}/actionitems/portaltranslation`]);
        //    this.onLogout();
      },
    },
    {
      label: this.translateService.instant('print.label'),
      icon: 'fal fa-print',
      command: () => {
        //    this.onLogout();
      },
    },
  ];
  hasWidgetChartClass: boolean;
  constructor(private route: Router, private i18nService: I18nService, private translateService: TranslateService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clickThrough) {
      //    HighCharts.chart(this.container.nativeElement, changes.data.currentValue);
      this.routerPrefix = '/' + environment.reportingRoutePrefix + changes.clickThrough.currentValue;
    }
  }
  ngOnInit() {
    this.routerPrefix = '/' + environment.reportingRoutePrefix + this.clickThrough;
    this.hasWidgetChartClass = this.styleClass ? this.styleClass.includes('widget--chart') : false;
  }
}
