<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <div widgetArea class="">
    <div class="c-j-charts">
      <div class="cej_chart_container">
        <div class="cej_header">
          <div class="cej_dd_container"></div>
          <div class="cej_dd_container"></div>
        </div>
        <div class="cej_chart" style="max-width: 500px; margin: 0 auto">
          <div class="item item1 neutral">
            <a href="javascript:void(0);">{{ 'DISCOVERY' | translate }} <span>76.5</span></a>
          </div>
          <div class="item item2 negative">
            <a href="javascript:void(0);">{{ 'FINANCIAL SERVICES' | translate }} <span>67.3</span></a>
          </div>
          <div class="item item3 negative">
            <a href="javascript:void(0);">{{ 'SUBSCRIBE' | translate }} <span>61.9</span></a>
          </div>
          <div class="item item4 normal">
            <a href="javascript:void(0);">{{ 'DELIVERY' | translate }} <span>86.1</span></a>
          </div>
          <div class="item item5 neutral">
            <a href="javascript:void(0);">{{ 'EXPERIENCE' | translate }} <span>74.2</span></a>
          </div>
          <div class="cej_chart-inner">
            <div class="arrows"><i class="fas fa-caret-right"></i><i class="fas fa-caret-right"></i></div>
            <div class="step-dot step1 neutral"></div>
            <div class="step-dot step2 negative"></div>
            <div class="step-dot step3 negative"></div>
            <div class="step-dot step4 positive"></div>
            <div class="step-dot step5 neutral"></div>
            <div class="exp-container">
              <div class="exp-box">
                <div class="exp__inner">
                  <h3>{{ 'Customer Health Score (TME)' | translate }}</h3>
                  <div class="exp-percentage">
                    <app-donut-static-widget
                      title="Customer Health Score"
                      [score]="valueleft"
                      [colors]="['#ffc107']"
                    ></app-donut-static-widget>
                    <!-- <p-knob
                    [strokeWidth]="5"
                    [readonly]="true"
                    valueTemplate="{value}"
                    valueColor="#89bf5d"
                      [(ngModel)]="valueleft"></p-knob> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="start-btn">{{ 'Start' | translate }}</div>
        </div>
      </div>
      <div class="cej_chart_container">
        <div class="cej_header">
          <div class="cej_dd_container"></div>
          <div class="cej_dd_container"></div>
        </div>
        <div class="cej_chart" style="max-width: 500px; margin: 0 auto">
          <div class="item item1 normal">
            <a href="javascript:void(0);">{{ 'DISCOVERY' | translate }} <span>75.2</span></a>
          </div>
          <div class="item item2 neutral">
            <a href="javascript:void(0);">{{ 'FINANCIAL SERVICES' | translate }} <span>78.7</span></a>
          </div>
          <div class="item item3 negative">
            <a href="javascript:void(0);">{{ 'SUBSCRIBE' | translate }} <span>63.6</span></a>
          </div>
          <div class="item item4 normal">
            <a href="javascript:void(0);">{{ 'DELIVERY' | translate }} <span>73.4</span></a>
          </div>
          <div class="item item5 neutral">
            <a href="javascript:void(0);">{{ 'EXPERIENCE' | translate }} <span>90.7</span></a>
          </div>
          <div class="cej_chart-inner" style="max-width: 500px; margin: 0 auto">
            <div class="arrows neutral"><i class="fas fa-caret-right"></i><i class="fas fa-caret-right"></i></div>
            <div class="step-dot step1 positive"></div>
            <div class="step-dot step2 neutral"></div>
            <div class="step-dot step3 negative"></div>
            <div class="step-dot step4 positive"></div>
            <div class="step-dot step5 neutral"></div>
            <div class="exp-container">
              <div class="exp-box">
                <div class="exp__inner">
                  <h3>{{ 'Customer Health Score (MM5)' | translate }}</h3>
                  <div class="exp-percentage">
                    <div class="exp-percentage">
                      <app-donut-static-widget
                        title="Customer Health Score"
                        [score]="valueright"
                        [colors]="['#ffc107']"
                      ></app-donut-static-widget>
                      <!-- <p-knob
                      [strokeWidth]="5"
                      [readonly]="true"
                      valueTemplate="{value}"
                      valueColor="#89bf5d"
                        [(ngModel)]="valueright"></p-knob> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="start-btn">{{ 'Start' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</app-widget-dynamic>
