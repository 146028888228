<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <ng-container widgetArea>
    <div class="valign__content">
      <div class="valign__graph">
        <div class="widget__content" style="display:flex; align-items: center; justify-content: center;" [attr.data-can-render]="canRender">
          <div class="widget__graph">
            <div style="width: 100%">
              <div #containerNpsScore></div>
            </div>
          </div>
          <div class="widget__counts">
            <div class="counts__table">
              <table>
                <tbody>
                  <tr *ngFor="let d of data index as i;">
                    <td>
                      <div class="count__symbol series--one clr-{{ colors[i] }}-background"></div>
                    </td>
                    <td>
                      <div class="count__count">
                        <ng-container *ngIf="eventName">
                          <a [routerLink]="'/' + reportingRoutePrefix + '/reports/surveylist'" [queryParams]="{nps: d.Key, en: eventName}">{{
                            d.Count | number }}</a>
                        </ng-container>
                        <ng-container *ngIf="!eventName">
                          <a [routerLink]="'/' + reportingRoutePrefix + '/reports/surveylist'" [queryParams]="{nps: d.Key}">{{
                            d.Count | number }}</a>
                        </ng-container>
                      </div>
                    </td>
                    <td>
                      <div class="count__percentage">{{ d.Percent | number : '1.1-1' }} %</div>
                    </td>
                    <td>
                      <div class="series__name">{{d.Title | translate }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="nodata-wrapper" *ngIf="data && data.length == 0">
          <div class="nodata">
            <i class="nodata__icon fal fa-info-circle"></i>
            <p class="nodata__msg">{{ 'No Result Found' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="nodata-wrapper" *ngIf="noResult">
      <div class="nodata">
          <i class="nodata__icon fal fa-info-circle"></i>
          <p class="nodata__msg">{{'No Result  Found' |translate}}</p>
      </div>
    </div> -->
  </ng-container>
</app-widget-dynamic>
