<ng-template #header>
  <h2 translate>
    <span><i class="fal fa-broadcast-tower"></i></span>Transmission Monitoring
  </h2>
</ng-template>
<ng-template #content>
  <div class="modal-form">
    <div class="row-grid">
      <div class="col col-4" *ngFor="let transmission of this.realTimeNotificationService.transmissions">
        <div class="{{ transmission.classes }}">
          <div class="notification-card">
            <div class="icon">
              <i class="{{ transmission.icon }}"></i>
            </div>
            <div class="details">
              <h3>{{ transmission.count }}</h3>
              <p>{{ transmission.title | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row-grid">
      <div class="col col-12">
        <div class="notification-listing">
          <div class="notification__item" *ngFor="let listing of realTimeNotificationService.notificationListing">
            <h4>{{ listing.heading }}</h4>
            <div class="notification__info">
              <span class="imported"
                ><i class="fal fa-arrow-to-top"></i> {{ 'trans_monitoring_publish_records' | translate }}
                <strong>{{
                  listing.report.success > 0 || listing.report.failed > 0 ? 0 : listing.report.total
                }}</strong></span
              >
              <span class="successful"
                ><i class="fal fa-check-double"></i> {{ 'trans_monitoring_successful_records' | translate }}
                <strong>{{ listing.report.success }}</strong></span
              >
              <span class="failed"
                ><i class="fal fa-times-circle"></i> {{ 'trans_monitoring_failed_records' | translate }}
                <strong>{{ listing.report.failed }}</strong></span
              >
            </div>
            <!-- <div *ngIf ="listing.report.success > 0" class="notification__globe">
              <span><a (click)="gotoDashboard(listing.id)"><i class="fal fa-arrow-right"></i></a></span>
            </div> -->
            <div class="notification__date">
              <span><i class="far fa-calendar"></i> {{ listing.date | customdateformat: 'datetime' }}</span>
            </div>
            <div class="notification__status">
              <span *ngIf="listing.progress == 'In_Progress'"
                ><i class="far fa-sync-alt icon--rotating"></i> {{ 'Publishing' | translate }}</span
              >
              <span *ngIf="listing.progress == 'Completed'"
                ><i class="fal fa-check-double"></i> {{ 'Successful' | translate }}</span
              >
              <span *ngIf="listing.progress == 'Error'"
                ><i class="fal fa-times-circle"></i> {{ 'Failed' | translate }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #footer>
  <button class="btn btn--cancel" (click)="onClose()" translate>Cancel</button>
</ng-template>
