<div class="filter filter--voc" (clickOutside)="toggleMenu('')">
  <ul>
    <li class="filter__item flex4" *ngIf="allowdealerType && showDealer">
      <div class="filter-field filter--modal">
        <div class="filter__trigger" (click)="showDealerType(); $event.stopPropagation()">
          <label class="field__label">{{ dealerType | translate }}</label>
          <div class="field__icons">
            <span class="icons__active"> <i class="fal fa-pencil"></i></span>
            <span class="icons__active icon--ok"><i class="fal fa-check"></i> </span>
            <i class="far fa-angle-down"></i>
          </div>
        </div>
        <div class="filter-dropdown dropdown--list" [class.active]="showdealerType">
          <div class="filter__list">
            <ul *ngFor="let ii of dealerTypesList">
              <li (click)="changeDealerType(ii.value, ii.label, ii.translate)">
                <a>{{ ii.label }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
    <!-- <li class="filter__item flex4" *ngIf="showHirerchy">
        <div class="filter-field filter--hierarchy"
          [class.active]="showHierarchy">
          <div class="filter__trigger">
            <ng-container *ngIf="showHierarchy">
              <i class="fal fa-search"></i>
              <input
                type="text"
                placeholder="{{'Dealer Name' | translate}}"
                class="field__label"
                (ngModelChange)="searchDealerChange($event)"
                [(ngModel)]="dealerquery"
                />
            </ng-container>
            <ng-container *ngIf="!showHierarchy">
              <i class="fal fa-industry"></i>
              <span class="field__label"
                (click)="showHierarchy_();$event.stopPropagation();">
                <ng-container *ngIf="items2.length> 0 && items2[items2.length -
                  1].label != undefined">
                  {{ items2[items2.length - 1].label }}
                </ng-container>
                <ng-container *ngIf="items2.length == 0 || !items2">
                  {{'nothing_selected.label' | translate}}
                </ng-container>
              </span>
            </ng-container>
            <div class="field__icons">
              <span *ngIf="showHierarchy"
                (click)="showHierarchy_();$event.stopPropagation();">
                <i class="fal fa-times"></i>
              </span>
              <i class="far fa-angle-down" (click)="showHierarchy_()"></i>
            </div>
          </div>
        </div>
        <div class="filter-dropdown dropdown--list" [class.active]="showdealerType">
          <div class="filter__list">
            <ul *ngFor="let ii of dealerTypesList">
              <li (click)='changeDealerType(ii.value, ii.label, ii.translate)'><a>{{ii.label}}</a></li>
            </ul>
          </div>
        </div>
    </li> -->
    <li class="filter__item flex4" *ngIf="showHirerchyViaProp">
      <div class="filter-field filter--hierarchy" [class.active]="showHierarchy">
        <div class="filter__trigger">
          <ng-container *ngIf="showHierarchy">
            <i class="fal fa-search"></i>
            <input
              type="text"
              placeholder="{{ 'Dealer Name' | translate }}"
              class="field__label"
              (ngModelChange)="searchDealerChange($event)"
              [(ngModel)]="dealerquery"
            />
          </ng-container>
          <ng-container *ngIf="!showHierarchy">
            <i class="fal fa-industry"></i>
            <span class="field__label" (click)="showHierarchy_(); $event.stopPropagation()">
              <ng-container *ngIf="items2.length > 0 && items2[items2.length - 1].label != undefined">
                {{ items2[items2.length - 1].label }}
              </ng-container>
              <ng-container *ngIf="items2.length == 0 || !items2">
                {{ 'nothing_selected.label' | translate }}
              </ng-container>
            </span>
          </ng-container>
          <div class="field__icons">
            <span *ngIf="showHierarchy" (click)="showHierarchy_(); $event.stopPropagation()">
              <i class="fal fa-times"></i>
            </span>
            <i class="far fa-angle-down" (click)="showHierarchy_()"></i>
          </div>
        </div>
      </div>
      <div class="filter-dropdown dropdown--hierarchy hierarchy--big" [class.active]="showHierarchy">
        <rf-breadcrumb
          (onItemClicked)="onClickInnerBreadCrumb($event)"
          *ngIf="searchItems?.length == 0 && dealerquery?.length < 2"
          [model]="items2"
          [styleClass]="'hierarchy-breadcrumbs'"
        ></rf-breadcrumb>
        <ul class="hierarchy" *ngIf="searchItems?.length == 0 && dealerquery?.length < 2">
          <ng-container *ngFor="let a of active">
            <li class="hierarchy__list" *ngIf="a.items?.length > 0">
              <!-- <ul class="mCustomScrollbar"> -->
              <cvp-scrollPanel-ul [style]="{ width: '100%', height: '205px' }">
                <ng-container *ngFor="let item of a.items">
                  <li
                    *ngIf="!item?.hide"
                    [class.active]="a?.active?.label == item?.label"
                    (click)="add(a.id, item)"
                    [ngClass]="{
                      'no--child': item?.children?.length === 0 || item?.level >= orgMaxLevel ? true : false
                    }"
                  >
                    <a>{{ item?.label | translate }}</a>
                  </li>
                </ng-container>
              </cvp-scrollPanel-ul>
            </li>
          </ng-container>
        </ul>
        <!-- {{searchItems.length}} -->
        <div *ngIf="dealerquery?.length > 1">
          <ng-container *ngIf="searchItems?.length === 0 && showerrorMessage">
            <cvp-scrollPanel-ul [style]="{ width: '100%', height: '205px' }">
              <li style="text-align: center; font-weight: bold; margin-top: 12px">No record found.</li>
            </cvp-scrollPanel-ul>
          </ng-container>
          <ng-container *ngIf="searchItems?.length != 0">
            <cvp-scrollPanel-ul [style]="{ width: '100%', height: '205px' }">
              <li *ngFor="let items of searchItems">
                <rf-breadcrumb
                  [styleClass]="'hierarchy-breadcrumbs'"
                  [model]="items.dealerHierarchy"
                  (click)="selectItem(items)"
                >
                </rf-breadcrumb>
              </li>
            </cvp-scrollPanel-ul>
          </ng-container>
        </div>
        <div class="dropdown__footer">
          <a
            *ngIf="showerrorMessage === false || searchItems?.length != 0 || dealerquery?.length < 2"
            class="btn"
            (click)="orgDone(false)"
          >
            {{ 'done_button.title' | translate }}
          </a>
        </div>
      </div>
    </li>

    <li class="filter__item flex3" *ngIf="showSourceTypeFilter && !isSMCompetitorReport">
      <div class="filter-field">
        <div class="filter__trigger" (click)="showSourceType(); $event.stopPropagation()">
          <label class="field__label">{{ sourceTypeTitle | translate }}</label>
          <div class="field__icons">
            <span class="icons__active"> <i class="fal fa-pencil"></i></span>
            <span class="icons__active icon--ok"><i class="fal fa-check"></i> </span>
            <i class="far fa-angle-down"></i>
          </div>
        </div>

        <div class="filter-dropdown dropdown--vocsurvey dropdown--surveyresponses" [class.active]="showSourceTypeCtrl">
          <div class="filter__list">
            <ul>
              <li *ngFor="let ii of sourceTypeList">
                <a>
                  <p-checkbox
                    name="SurveyQuestion"
                    [(ngModel)]="ii.IsChecked"
                    value="{{ ii.Id }}"
                    label="{{ ii.Id | translate }}"
                    (onChange)="SourceTypeSelection($event, ii.Id)"
                    binary="true"
                  ></p-checkbox>
                </a>
              </li>
            </ul>
          </div>
          <div class="dropdown__footer">
            <a class="btn" (click)="sourceTypeDone()" translate> Done </a>
          </div>
        </div>
      </div>
    </li>

    <li class="filter__item flex4" *ngIf="showModel && hideSomeFilters">
      <div class="filter-field filter--modal filter--hierarchy" [class.active]="showMenu">
        <div class="filter__trigger">
          <ng-container *ngIf="showMenu">
            <i class="fal fa-search"></i>
            <input
              type="text"
              class="field__label"
              placeholder="{{ 'search__model_filtertext_reporting.placeholder' | translate }}"
              name="year"
              [(ngModel)]="yearquery"
              (ngModelChange)="searchModelBrandYearChange($event)"
            />
          </ng-container>
          <ng-container *ngIf="!showMenu">
            <i class="fal fa-car"></i>
            <span class="field__label" (click)="showMenu_(); $event.stopPropagation()">
              <ng-container *ngIf="activeModalType && activeModalType.label">
                {{ activeModalType.label | translate }}
              </ng-container>
              <ng-container *ngIf="activeModal && activeModal.label">
                {{ activeModal.label | translate }}
              </ng-container>
              <ng-container *ngIf="activeYear">
                {{ activeYear }}
              </ng-container>
              <ng-container *ngIf="!activeModalType && !activeModal && !activeYear"
                >{{ 'nothing_selected.label' | translate }}
              </ng-container>
            </span>
          </ng-container>
          <div class="field__icons">
            <span *ngIf="showMenu" (click)="showMenu_(); $event.stopPropagation()">
              <i class="fal fa-times"></i>
            </span>
            <i class="far fa-angle-down" (click)="showMenu_()"></i>
          </div>
        </div>
        <div class="filter-dropdown dropdown--allmodels" [class.active]="showMenu">
          <div
            class="model-column"
            *ngIf="modalList.length == 0 && (showerrorMessageModel == false || yearquery?.length == 0)"
          >
            <!-- <div class="model-search"></div> -->
            <h4 class="model__title" translate>
              {{ 'brands' | translate }}
              <!-- <span>({{modalsOfYear.length}})</span> -->
            </h4>
            <cvp-scrollPanel-ul
              *ngIf="modalsOfYear && modalsOfYear.length > 0"
              [style]="{ width: '100%', height: '150px' }"
              [styleClass]="'model__list'"
            >
              <li
                [class.active]="activeModalType.label == item.label"
                (click)="setModalType(item)"
                *ngFor="let item of modalsOfYear"
              >
                <a>{{ item.label | translate }}</a>
              </li>
            </cvp-scrollPanel-ul>
          </div>

          <div
            class="model-column"
            *ngIf="modalList.length == 0 && (showerrorMessageModel == false || yearquery?.length == 0)"
          >
            <h4 class="model__title" translate>
              {{ 'all_models' | translate }}
              <!-- <span>({{(activeModalType | filter: modelquery:'label').length}})</span> -->
            </h4>
            <cvp-scrollPanel-ul
              *ngIf="activeModalType"
              [style]="{ width: '100%', height: '150px' }"
              [styleClass]="'model__list'"
            >
              <li
                [class.active]="activeModal.label == item.label"
                (click)="setModal(item)"
                *ngFor="let item of activeModalType.children | filter: modelquery:'label'"
              >
                <a>{{ item.label | translate }}</a>
              </li>
            </cvp-scrollPanel-ul>
          </div>

          <div
            class="model-column"
            *ngIf="modalList.length == 0 && (showerrorMessageModel == false || yearquery?.length == 0)"
          >
            <h4 class="model__title" *ngIf="modalList.length == 0" translate>{{ 'model_year' | translate }}</h4>
            <cvp-scrollPanel-ul
              [style]="{ width: '100%', height: '150px' }"
              [styleClass]="'model__list all__models'"
              *ngIf="modalList.length == 0"
            >
              <li [class.active]="selectActiveYear == true" (click)="selectYear(true)">
                <a>{{ activeYear }}</a>
              </li>
            </cvp-scrollPanel-ul>
          </div>
          <ng-container *ngIf="yearquery?.length != 0">
            <cvp-scrollPanel-ul
              [style]="{ width: '100%', height: '150px' }"
              *ngIf="modalList.length == 0 && showerrorMessageModel"
            >
              <li style="text-align: center; font-weight: bold; margin-top: 12px">No record found.</li>
            </cvp-scrollPanel-ul>
            <cvp-scrollPanel-ul [style]="{ width: '100%', height: '150px' }" *ngIf="modalList.length != 0">
              <li *ngFor="let items of modalList" (click)="selectModalItem(items)">
                <rf-breadcrumb [model]="items.modalHierarchy" [styleClass]="'hierarchy-breadcrumbs'"> </rf-breadcrumb>
              </li>
            </cvp-scrollPanel-ul>
          </ng-container>

          <div class="dropdown__footer">
            <a
              *ngIf="showerrorMessageModel === false || modalList?.length != 0 || yearquery?.length === 0"
              class="btn"
              (click)="modelDone()"
              translate
            >
              Done
            </a>
          </div>
        </div>
      </div>
    </li>
    <li class="filter__item flex2" *ngIf="showVersion && hideSomeFilters">
      <div class="filter-field filter--modal">
        <div class="filter__trigger" (click)="showSurveyVersion(); $event.stopPropagation()">
          <label class="field__label">{{ activeSurvey }}</label>
          <div class="field__icons">
            <span class="icons__active"> <i class="fal fa-pencil"></i></span>
            <span class="icons__active icon--ok">
              <i class="fal fa-check"></i>
            </span>
            <i class="far fa-angle-down"></i>
          </div>
        </div>
        <div class="filter-dropdown dropdown--list dropdown--versionlist" [class.active]="showSurveyVersionYear">
          <div class="filter__list">
            <ul>
              <li
                *ngFor="let ii of currentSurveyVersionList"
                (click)="selectSurveyVersionYear(ii.Version, ii.SurveyId)"
              >
                <a>{{ ii.Version }}</a>
              </li>
            </ul>
            <div *ngIf="showVocFilters" class="dropdown__footer">
              <a class="btn" (click)="SurveyVersionDone()" translate> Done </a>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li class="filter__item flex4" *ngIf="allowDateFilters">
      <div class="filter-field filter--modal">
        <div class="filter__trigger" (click)="showDateFiltersType(); $event.stopPropagation()">
          <label class="field__label">{{ filterService.dateFilter.label | translate }}</label>
          <div class="field__icons">
            <span class="icons__active"> <i class="fal fa-pencil"></i></span>
            <span class="icons__active icon--ok"><i class="fal fa-check"></i> </span>
            <i class="far fa-angle-down"></i>
          </div>
        </div>
        <div class="filter-dropdown dropdown--list" [class.active]="showDateFilter">
          <div class="filter__list">
            <ul *ngFor="let ii of filterService.dateFilterList">
              <li (click)="changeDateFilter(ii.value, ii.label, ii.translate, ii.expr)">
                <a>{{ ii.label }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
    <li class="filter__item flex4">
      <cvp-calendar
        #datePicker
        (onDataPickerOpen)="toggleMenu('showDateRange')"
        (_onMonthSelect)="onMonthSelect($event)"
        [programDate]="programDate"
        (hideDatePicker)="hideDatePicker($event)"
        [dateDropDown]="dateDropDown"
        [currentCalendarDate]="currentCalendarDate"
        [primeCalendar]="false"
        [inline]="true"
        view="month"
        (_onDateRangeChange)="onDateRangeChange($event)"
        [showDatePicker]="showDateRange"
        [defaultSelectedDate]="defaultSelectedDate"
        (done)="datePickerDone($event)"
        [monthNavigator]="monthNavigator"
        [yearNavigator]="yearNavigator"
        [moment]="primeMoment"
      >
      </cvp-calendar>
    </li>
    <li class="filter__item flexbtn" *ngIf="showApplyFilterButton">
      <!-- <a class="btn btn--filter btn--disabled" *ngIf="!filterService.filterDisabledStatus">
    {{"apply_filters_button.title" | translate }}
  </a> -->
      <a (click)="closeall()" class="btn btn--filter">
        {{ 'apply_filters_button.title' | translate }}
      </a>
    </li>
    <li class="filter__item flexbreak" *ngIf="showVocFilters || showlanguage || showCategoryFilters"></li>
    <li class="filter__item flex3" *ngIf="(showVocFilters && !showLanguageChFilters) || showlanguage">
      <div class="filter-field">
        <div class="filter__trigger" (click)="showLanguages(); $event.stopPropagation()">
          <label class="field__label">{{ languageTitle | translate }}</label>
          <div class="field__icons">
            <span class="icons__active"> <i class="fal fa-pencil"></i></span>
            <span class="icons__active icon--ok"><i class="fal fa-check"></i> </span>
            <i class="far fa-angle-down"></i>
          </div>
        </div>
        <div class="filter-dropdown dropdown--list dropdown--languagelist" [class.active]="showLanguagesCtrl">
          <div class="filter__list">
            <!-- <ul *ngFor="let data of languagesList">
          <li *ngIf="data.LanguageCode" (click)='changeLanguage(data)'><a>{{data.Name}}</a>
          </li>
          <li *ngIf="!data.LanguageCode || data.LanguageCode === null" (click)='changeLanguage(data)'>
            <p-checkbox name="Multilanguage" [(ngModel)]="data.Name" value="{{data.Name}}" label="{{data.Name}}"
                 (onChange)="SurveyQuestionSelection($event, ii.Id)" binary="true"></p-checkbox>
          </li>
        </ul> -->

            <cvp-scrollPanel-ul [style]="{ width: '100%', height: '208px' }">
              <li *ngFor="let data of languagesList" title="{{ data.Name }}">
                <a>
                  <p-checkbox
                    name="languageCode"
                    [(ngModel)]="data.checked"
                    value="{{ data.Name }}"
                    label="{{ data.Name | translate }}"
                    (onChange)="LanguageSelection($event, data)"
                    binary="true"
                  ></p-checkbox>
                </a>
              </li>
            </cvp-scrollPanel-ul>
          </div>
        </div>
      </div>
    </li>

    <li class="filter__item flex3" *ngIf="showSentimentFilters || showSMFilters">
      <div class="filter-field">
        <div class="filter__trigger" (click)="showSentiment(); $event.stopPropagation()">
          <label class="field__label">{{ sentimentTitle | translate }}</label>
          <div class="field__icons">
            <span class="icons__active"> <i class="fal fa-pencil"></i></span>
            <span class="icons__active icon--ok"><i class="fal fa-check"></i> </span>
            <i class="far fa-angle-down"></i>
          </div>
        </div>
        <div class="filter-dropdown dropdown--list" [class.active]="showSentimentCtrl">
          <div class="filter__list">
            <ul *ngFor="let ii of sentimentListMapping">
              <li (click)="changeSentiment(ii)">
                <a>{{ ii.label }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
    <li class="filter__item flex3" *ngIf="showVocFilters">
      <div class="filter-field">
        <div class="filter__trigger" (click)="showSurveyQuestion(); $event.stopPropagation()">
          <label class="field__label">{{ surveyQuestionTitle | translate }}</label>
          <div class="field__icons">
            <span class="icons__active"> <i class="fal fa-pencil"></i></span>
            <span class="icons__active icon--ok"><i class="fal fa-check"></i> </span>
            <i class="far fa-angle-down"></i>
          </div>
        </div>
        <div class="filter-dropdown dropdown--vocsurvey" [class.active]="showSurveyQuestionCtrl">
          <div class="filter-search-wrapper">
            <div class="filter-search">
              <input
                type="text"
                maxlength="200"
                [(ngModel)]="surveyQuestionSearch"
                (keyup)="onSurveyQuestionSearch($event)"
                pInputText
                class=""
                placeholder="{{ 'Search' | translate }}"
              />
            </div>
          </div>
          <div class="filter__list">
            <cvp-scrollPanel-ul [style]="{ width: '100%', height: '150px' }">
              <li *ngFor="let ii of surveyQuestionsList" title="{{ ii.Title }}">
                <a>
                  <p-checkbox
                    name="SurveyQuestion"
                    [(ngModel)]="ii.IsChecked"
                    value="{{ ii.Id }}"
                    label="{{ ii.Id | translate }}"
                    (onChange)="SurveyQuestionSelection($event, ii.Id)"
                    binary="true"
                  ></p-checkbox>
                </a>
              </li>
            </cvp-scrollPanel-ul>
            <!-- <ul id="conSurQues" class="applx">
          <li *ngFor="let ii of surveyQuestionsList" title="{{ii.Title}}">
            <a>
              <p-checkbox name="SurveyQuestion" [(ngModel)]="ii.IsChecked" value="{{ii.Id}}" label="{{ii.Id}}"
                (onChange)="SurveyQuestionSelection($event, ii.Id)" binary="true"></p-checkbox>
            </a>
          </li>
        </ul> -->
          </div>
          <div class="dropdown__footer">
            <a class="btn" (click)="surveyQuestionDone()" translate> Done </a>
          </div>
        </div>
      </div>
    </li>

    <li class="filter__item flex4" *ngIf="showVocFilters">
      <div class="filter-field">
        <div class="filter__trigger" (click)="showSurveyResponses(); $event.stopPropagation()">
          <label class="field__label">{{ removeHtmlTags(surveyResponsesTitle) | translate }}</label>
          <div class="field__icons">
            <span class="icons__active"> <i class="fal fa-pencil"></i></span>
            <span class="icons__active icon--ok"><i class="fal fa-check"></i> </span>
            <i class="far fa-angle-down"></i>
          </div>
        </div>

        <div class="filter-dropdown dropdown--surveyresponses" [class.active]="showSurveyResponsesCtrl">
          <div class="filter-search-wrapper">
            <div class="filter-search">
              <input
                type="text"
                [(ngModel)]="surveyResponsesSearch"
                (keyup)="onSurveyResponsesSearch($event)"
                pInputText
                class=""
                placeholder="{{ 'Search' | translate }}"
              />
            </div>
          </div>
          <div class="model-column">
            <h4 class="model__title">{{ 'All Survey Questions' | translate }}</h4>
            <cvp-scrollPanel-ul class="model__list" [style]="{ width: '100%', height: '150px' }">
              <li
                [class.has-child]="response.Options.length > 0"
                [class.active]="response.IsSelected"
                title="{{ response.Title | translate }}"
                *ngFor="let response of surveyResponsesList"
                (click)="surveyResponsesDrill_bak($event, response)"
              >
                <a>
                  <p-checkbox
                    [styleClass]="response.IsAddClass"
                    name="SurveyResponses"
                    [(ngModel)]="response.IsChecked"
                    value="{{ response.Id }}"
                    label="{{ response.Id | translate }}"
                    (onChange)="surveyResponsesDrill($event, response)"
                    binary="true"
                  >
                  </p-checkbox>
                </a>
              </li>

              <!-- <li [class.has-child]="response.Options.length > 0" [class.active]="response.IsSelected" title="{{response.Title}}"
  *ngFor="let response of surveyResponsesList" (click)="surveyResponsesDrill(response)"><a>{{response.Id}}</a>
</li> -->
            </cvp-scrollPanel-ul>
          </div>

          <div class="model-column">
            <h4 class="model__title">{{ 'All Survey Responses' | translate }}</h4>
            <div class="filter__list">
              <cvp-scrollPanel-ul class="model__list" [style]="{ width: '100%', height: '150px' }">
                <li *ngFor="let option of surveyResponsesOptions" [class.active]="option.IsSelected">
                  <a>
                    <p-checkbox
                      [(ngModel)]="option.IsChecked"
                      value="{{ option.Id || '' }}"
                      label="{{ removeHtmlTags(option.Title) }}"
                      (onChange)="surveyResponsesOptSelected(option)"
                      binary="true"
                    ></p-checkbox>
                  </a>
                </li>
              </cvp-scrollPanel-ul>
              <!-- <ul id="conSurQuesResOpt" class="model__list">
            <li *ngFor="let option of surveyResponsesOptions">
              <a>
                <p-checkbox [(ngModel)]="option.IsChecked" value="{{option.Id || ''}}" label="{{option.Title}}"
                  (onChange)="surveyResponsesOptSelected(option);" binary="true"></p-checkbox>
              </a>
            </li>
          </ul> -->
            </div>
          </div>
          <div class="dropdown__footer">
            <a class="btn" (click)="surveyResponsesDone()" translate> Done </a>
          </div>
        </div>
      </div>
    </li>
    <!-- <li class="filter__item flex4" *ngIf="(showVocFilters && showCategoryFilters)
                                      || (showSMFilters === true) || (showSmVocFilters)"> -->
    <li class="filter__item flex4" *ngIf="showCategoryFilters">
      <div class="filter-field">
        <div class="filter__trigger" (click)="showAspectCategory(); $event.stopPropagation()">
          <label class="field__label text__ellipsis">{{ aspectCategoryTitle | translate }}</label>
          <div class="field__icons">
            <span class="icons__active"> <i class="fal fa-pencil"></i></span>
            <span class="icons__active icon--ok"><i class="fal fa-check"></i> </span>
            <i class="far fa-angle-down"></i>
          </div>
        </div>
        <div class="filter-dropdown dropdown--categories" [class.active]="showAspectCategoryCtrl">
          <div class="voc-filter-breadcrumbs">
            <ul class="breadcrumbs_list">
              <li (click)="aspectCategoriesBreadcrumReset(null)">
                <a>{{ 'All' | translate }}</a>
              </li>
              <li
                *ngFor="let breadcrum of aspectCategoriesBreadcrum"
                (click)="aspectCategoriesBreadcrumReset(breadcrum); $event.stopPropagation()"
              >
                <a>{{ GetCategoryTranslation(breadcrum.title) }}</a>
              </li>
            </ul>
          </div>
          <div class="voc-category-list">
            <cvp-scrollPanel-ul [style]="{ width: '100%', height: '180px' }">
              <li
                [class.active]="response.isSelected"
                [class.has-child]="response.children && response.children.length > 0"
                *ngFor="let response of aspectCategoriesListFiltered"
                (click)="aspectCategoriesDrill(response); $event.stopPropagation()"
              >
                <a>{{ GetCategoryTranslation(response.Label) }}</a>
              </li>
            </cvp-scrollPanel-ul>
            <!-- <ul>
          <li [class.has-child]="response.children && response.children.length > 0"
            *ngFor="let response of aspectCategoriesListFiltered"
            (click)="aspectCategoriesDrill(response);$event.stopPropagation();"><a>{{response.Label}}</a></li>
        </ul> -->
          </div>
        </div>
      </div>
    </li>
    <li class="filter__item flex4 survey-score-container" *ngIf="showVocFilters && showSurveyScore">
      <div class="filter-field">
        <div class="filter__trigger" (click)="showSurveyScore(); $event.stopPropagation()">
          <label class="field__label">{{ surveyScoreTitle | translate }}</label>
          <div class="field__icons">
            <span class="icons__active"> <i class="fal fa-pencil"></i></span>
            <span class="icons__active icon--ok"><i class="fal fa-check"></i> </span>
            <i class="far fa-angle-down"></i>
          </div>
        </div>
        <div class="filter-dropdown dropdown--surveyscore" [class.active]="showSurveyScoreCtrl">
          <div class="survey-score-heading">
            {{ 'Survey Score' | translate }}: {{ surveyScore.Score[0] + ' - ' + surveyScore.Score[1] }}
          </div>
          <ul class="survey-score-ranges">
            <li (click)="silderBarRange('Promoters', 90, 100)" [class.active]="surveyScore.Type === 'Promoters'">
              {{ 'Promoters' | translate }}
              <span class="survey-score-range-value">90 to 100</span>
            </li>
            <li (click)="silderBarRange('Passives', 70, 89)" [class.active]="surveyScore.Type === 'Passives'">
              {{ 'Passives' | translate }} <span class="survey-score-range-value">70 to 89</span>
            </li>
            <li (click)="silderBarRange('Detractors', 0, 69)" [class.active]="surveyScore.Type === 'Detractors'">
              {{ 'Detractors' | translate }}
              <span class="survey-score-range-value">0 to 69</span>
            </li>
          </ul>
          <ng-container *ngIf="showCustomRange">
            <p-slider
              [(ngModel)]="surveyScore.Score"
              [min]="0"
              [max]="100"
              [step]="1"
              [range]="true"
              (onChange)="changeSurveyScore($event)"
            ></p-slider>
          </ng-container>
        </div>
      </div>
    </li>
  </ul>
</div>
