<ng-template #header>
  <h2>
    <span><i class="fal fa-desktop"></i></span>Notification <span>({{ notifications.length }})</span>
  </h2>
  <p-tabMenu [model]="tabs" styleClass="tabs-secondary tabs--right" [activeItem]="tabs[0]">
    <ng-template pTemplate="item" let-item let-i="index">
      {{ item.heading | translate }}
      <span *ngIf="item.icon != ''"
        ><span class="tab__count">({{ item.count }})</span></span
      >
    </ng-template>
  </p-tabMenu>
</ng-template>
<ng-template #content>
  <div [perfectScrollbar]="config">
    <app-notification-list [Notifications]="filteredNotifications"></app-notification-list>
  </div>
</ng-template>
<ng-template #footer>
  <a class="btn btn--cancel" (click)="close()" translate>Close</a>
</ng-template>
