import { CurrencyPipe, DatePipe, DecimalPipe, LowerCasePipe, PercentPipe, UpperCasePipe } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { CustomDateFormatPipe } from '../pipes/customdateformat.pipe';
import { TruncateGridTextPipe } from '../pipes/truncategridtext.pipe';
const ColumnTypes = { Date: 'date', String: 'string', Number: 'number' };
export const CIDS = ['648999a99367b70380f2274c', '64846603467601bce1bb43db'];
export interface ColumnMeta {
  Type: string;
  Align: string;
  Conditions: Array<any>;
  Translate: boolean;
  FormatterArgs: Array<string>;
  Formatter: string;
  ShowText: boolean;
  Visibility: boolean;
  Renderer: Array<any>;
  Key: string;
}
export interface QueryGeneratorObject {
  Value: string;
  Text: any;
  Formatter: string;
}
interface Placeholder {
  variable: string; // Placeholder
  key: string; // {{Placeholder}}
}

@Directive({
  selector: '[reflecxColumn]',
  providers: [CurrencyPipe, DatePipe, DecimalPipe, LowerCasePipe, PercentPipe, UpperCasePipe],
})
export class ReflecxColumnDirective implements OnInit {
  @Input() columnMeta: ColumnMeta;
  @Input() rowData: any;
  @Output() on: EventEmitter<any> = new EventEmitter(true);

  pipeConfigs: any = {
    uppercase: { pipe: this.upperCasePipe, default: [] },
    lowercase: { pipe: this.lowerCasePipe, default: [] },
    currency: { pipe: this.currencyPipe, default: [] },
    decimal: { pipe: this.decimalPipe, default: [] },
    percentage: { pipe: this.percentagePipe, default: [] },
    date: { pipe: this.datePipe, default: [] },
    customdate: { pipe: this.customDatePipe, default: ['date'] },
    truncategridtext: { pipe: this.truncatedTextPipe, default: [30] },
  };
  constructor(
    private sanitizer: DomSanitizer,
    public router: Router,
    public el: ElementRef,
    public upperCasePipe: UpperCasePipe,
    public lowerCasePipe: LowerCasePipe,
    public currencyPipe: CurrencyPipe,
    public translateService: TranslateService,
    public decimalPipe: DecimalPipe,
    public percentagePipe: PercentPipe,
    public datePipe: DatePipe,
    public customDatePipe: CustomDateFormatPipe,
    public truncatedTextPipe: TruncateGridTextPipe
  ) {}
  ngOnInit(): void {
    if (this.columnMeta.Visibility == false) {
      return;
    }
    if (this.columnMeta.ShowText !== false) {
      if (this.rowData[this.columnMeta.Key] || typeof this.rowData[this.columnMeta.Key] === 'number') {
        this.el.nativeElement.textContent = this.getFormatedData(this.rowData[this.columnMeta.Key], this.columnMeta);
      }
    }
    if (typeof this.columnMeta?.Align == 'string' && this.columnMeta?.Align != '') {
      this.el.nativeElement.style.textAlign = this.columnMeta?.Align;
    } else {
      this.el.nativeElement.style.textAlign = 'left';
    }
    const context = this.buildContext();
    this.getArray(this.columnMeta?.Renderer).forEach((info: any) => {
      if (Array.isArray(info.equal) && info.equal.includes('*')) {
        this.populateToDomTree(info.inject, context, this.el.nativeElement, this.columnMeta);
      }
    });
  }

  buildContext() {
    let reportingBaseUrl = environment.reportingRoutePrefix;
    if (environment.production) {
      reportingBaseUrl = `portal/${environment.reportingRoutePrefix}`;
    }
    return {
      reportingRoutePrefix: reportingBaseUrl,
      ...this.columnMeta,
      ...this.rowData,
    };
  }

  getConditionResult(condition: any, columnData: string = '') {
    const { rowData } = this;
    if (condition.equal && condition.attach && Array.isArray(condition.attach) && condition.attach.length > 0) {
      if (this.translate(condition.equal) === columnData) {
        columnData +=
          ` ${condition.separator || '-'} ` +
          condition.attach
            .map((key: string) => {
              return this.translate(rowData[key]) || undefined;
            })
            .filter(Boolean)
            .join(condition.separator || '-');
      }
    }
    return columnData;
  }

  translate(key: string) {
    if (key && typeof key === 'string') {
      return this.translateService.instant(key);
    }
    return key;
  }
  getFormatedData(val: any, columnMeta: any) {
    if (this.getArray(Object.keys(columnMeta || {})).length === 0) {
      return val;
    }
    const { pipeConfigs } = this;
    let columnData: any;
    if (typeof val === 'number') {
      columnData = val;
    } else {
      columnData = this.translate(val) || '';
    }
    //let columnData = this.translate(val) || '';
    const pipeConfig = pipeConfigs[columnMeta.Formatter] || null;

    if (columnMeta?.Conditions?.length > 0) {
      columnMeta?.Conditions.forEach((condition: any) => {
        columnData = this.getConditionResult(condition, columnData);
      });
    }

    if (columnMeta.Formatter && pipeConfig && pipeConfig.pipe && columnData) {
      if (columnMeta.FormatterArgs.length > 0) {
        return pipeConfig.pipe.transform.apply(pipeConfig.pipe, [columnData, ...columnMeta.FormatterArgs]);
      } else {
        return pipeConfig.pipe.transform.apply(pipeConfig.pipe, [columnData, pipeConfig.default]);
      }
    } else if (this.columnMeta.Type == ColumnTypes.Date) {
      columnData = columnData.trim();
      return columnData ? this.customDatePipe.transform(columnData, 'date') : '';
    } else if (this.columnMeta.Type == ColumnTypes.Number) {
      return this.decimalPipe.transform(columnData, 'number');
    }
    return columnData;
  }

  checkCondition(left: string, right: any, operator: string) {
    switch (operator) {
      case '===':
      case '==':
        return String(left) === String(right);
      case '!=':
      case '!==':
        return String(left) != String(right);
      case '>=':
        return parseInt(left) >= parseInt(right);
      case '>':
        return parseInt(left) > parseInt(right);
      case '<':
        return parseInt(left) < parseInt(right);
      case '<=':
        return parseInt(left) <= parseInt(right);
      case 'contains':
        return left.indexOf(right) > -1;
      case 'length':
        return left.length === right.length;
      case 'exists':
        return left.length && right.length ? true : false;
      case 'includes':
        return this.getArray(right).includes(String(left));
      default:
        return false;
    }
  }

  populateToDomTree(registry: any, context: any, parentElement: HTMLElement, columnMeta: any) {
    this.getArray(registry).forEach((meta: any) => {
      // TODO:: region::if this is evil need to fix
      let display = true;
      let skip_condition = false;
      if (this.getArray(meta.conditions).length > 0) {
        this.getArray(meta.conditions).forEach((condition) => {
          if (!skip_condition) {
            const left = this.parseToVariable(condition.left, context);
            const right = this.parseToVariable(condition.right, context);
            if (condition.gate === 'or') {
              display = this.checkCondition(left, right, condition.operator) || display;
            } else {
              display = this.checkCondition(left, right, condition.operator) && display;
            }
            if (condition.inverse) {
              display = !display;
            }
          }
        });
      }

      let el = document.createElement(meta.tag);
      if (meta.events && Object.keys(meta.events).length > 0) {
        for (let event in meta.events) {
          el[event] = (e: any) => this.fn(e, meta, meta.events[event], el);
        }
      }

      for (let prop in meta.props) {
        var valueCheck = this.parseToVariable(meta.text, context);
        const parsedVal = this.parseToVariable(meta.props[prop], context);
        if (valueCheck && meta.text_not_empty) {
          if (prop != 'title') {
            el[prop] = parsedVal;
          } else {
            el[prop] = meta.translate ? this.translate(parsedVal) : parsedVal;
          }
        } else if (context['ShowText'] == false && !meta.text_not_empty) {
          if (prop != 'title') {
            el[prop] = parsedVal;
          } else {
            el[prop] = meta.translate ? this.translate(parsedVal) : parsedVal;
          }
        }
      }

      if (meta.text) {
        var parsedVal = this.parseToVariable(meta.text, context);
        if (meta.translate) {
          parsedVal = this.translate(parsedVal);
        }
        el.innerHTML += this.getFormatedData(parsedVal, columnMeta);
        el.title += parsedVal;
      }
      if (display) {
        parentElement.appendChild(el);
      }
      if (this.getArray(meta.childrens).length > 0) {
        this.populateToDomTree(meta.childrens, context, el, columnMeta);
      }
    });
  }

  fn(e: any, meta: any, event: any, element: HTMLElement) {
    e.preventDefault();
    if (event.fn === 'navigate') {
      let href = element.getAttribute('href')?.replace('/portal', '');
      // if (this.rowData.customer_id === '64846603467601bce1bb43db' || this.rowData?.CustomerId === '64846603467601bce1bb43db') {
      if (CIDS.includes(this.rowData.customer_id) || CIDS.includes(this.rowData.CustomerId)) {
        this.router.navigate([`insights/profile/${this.rowData.CustomerId}/beta`], { queryParams: { type: 2 } });
      } else {
        this.router.navigate([href]);
      }
    }
    this.on && this.on.emit({ e, meta, event, element, data: this.rowData, column: this.columnMeta });
  }

  parseToVariable(val: string, context: any) {
    const placeholders = this.getPlaceholders(val);
    placeholders.forEach((placeholder: Placeholder, index: number) => {
      const varval = context[placeholder.variable];
      if (varval != null && varval != undefined) {
        val = val.replace(placeholder.key, varval);
      } else {
        val = val.replace(placeholder.key, '');
      }
    });
    return val;
  }

  getArray(val: Array<any>) {
    if (Array.isArray(val) && val.length > 0) {
      return val;
    }
    return [];
  }

  getPlaceholders(val: string) {
    try {
      const regex = /\{\{[a-zA-Z0-9_ ]+\}\}/g;
      const placeholders = (val.match(regex) || []).map((placeholder: string) => {
        return {
          variable: placeholder.replace(/[\{\}\{\{]/g, ''),
          key: placeholder,
        };
      });
      return placeholders;
    } catch (error) {
      return new Array();
    }
  }
}
