<div class="modal-form">
  <div class="row">
    <h3 translate>Change Password</h3>
  </div>
  <div class="row">
    <div class="col-left"><label translate>Old Password</label></div>
    <div class="col-right">
      <input
        pInputText
        type="password"
        minlength="6"
        autocomplete="false"
        maxlength="20"
        name="oldPassword"
        #oldPassword="ngModel"
        [(ngModel)]="user.OldPassword"
        [ngClass]="{ error: oldPassword.errors && submitted }"
        placeholder="Enter Current Password"
        required
        pInputText
      />
      <div class="info error" *ngIf="oldPassword.errors && submitted">
        <p *ngIf="oldPassword.errors.minlength">Old password is too short.</p>
        <p *ngIf="oldPassword.errors.required">Old password is required.</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-left"><label translate>New Password</label></div>
    <div class="col-right">
      <input
        pInputText
        type="password"
        autocomplete="false"
        minlength="6"
        maxlength="20"
        name="newPassword"
        #newPassword="ngModel"
        [(ngModel)]="user.NewPassword"
        [ngClass]="{ error: newPassword.errors && submitted }"
        placeholder="Enter New Password"
        required
        pInputText
      />
      <div class="info error" *ngIf="newPassword.errors && submitted">
        <p *ngIf="newPassword.errors.minlength">New password is too short.</p>
        <p *ngIf="newPassword.errors.required">New password is required.</p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-left"><label translate>Re-type Password</label></div>
    <div class="col-right">
      <input
        pInputText
        type="password"
        minlength="6"
        maxlength="20"
        name="confirmPassword"
        autocomplete="false"
        #confirmPassword="ngModel"
        [(ngModel)]="user.ConfrmPassword"
        [ngClass]="{ error: confirmPassword.errors && submitted }"
        placeholder="Enter Current Password"
        required
        pInputText
      />
      <div class="info error" *ngIf="confirmPassword.errors && submitted">
        <p *ngIf="confirmPassword.errors.minlength">Confirm password is too short.</p>
        <p *ngIf="confirmPassword.errors.required">Confirm password is required.</p>
      </div>
    </div>
  </div>
</div>
<div class="modal__footer btn--action">
  <a class="btn btn--cancel" (click)="_Close.emit()" translate>Cancel</a>
  <a class="btn" translate (click)="submit([oldPassword.errors, newPassword.errors, confirmPassword.errors])">Save</a>
</div>
