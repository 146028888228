import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'appc-layout-xl',
  templateUrl: './layout-xl.component.html',
  styleUrls: ['./layout-xl.component.scss'],
})
export class LayoutXlComponent implements OnInit {
  @Input() style = '';
  constructor() {}

  ngOnInit() {}
}
