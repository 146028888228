import { Component, OnInit, ViewChild, ElementRef, SimpleChanges, OnDestroy, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '@app/shared/services/cache.service';
import { WidgetService } from '../widget.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { FiltersService } from '@app/shared/services/filters.service';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { ICustomerComments, ICustomerCommentsResponse } from './types/ICustomerCommentsResponse';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';

@Component({
  selector: 'customer-comments-widget',
  templateUrl: './customer-comments.html',
  styleUrls: ['./customer-comments.scss'],
})
export class CustomerCommentsComponent extends WidgetBase implements OnInit, OnDestroy {
  routerPrefix = '';
  @ViewChild('scrollBar') scrollBar: any;
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  ProgramSubscribtion: Subscription;
  FilterSubscribtion: Subscription;
  resolutions: any;
  isMobile = window.innerWidth < 1024;
  data: ICustomerCommentsResponse = {
    CustomerComments: [],
  };
  chartModel: any = {};
  chart: any;
  canRender = false;
  api: Subscription;
  permissions: NgxPermissionsObject;
  hasCommentListPermission: boolean = false;

  private subscriptions: Subscription[] = [];
  constructor(
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public gbfService: GlobalFilterService,
    public toastService: ToastService,
    private filterService: FiltersService,
    public ngxPermission: NgxPermissionsService,
    private translationHelperService: TranslationHelperService
  ) {
    super(gbfService, translateService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.permissions = this.ngxPermission.getPermissions();
    this.hasCommentListPermission = this.permissions[Permission.REPORT_TEXTANALYTIC_COMMENTLIST] ? true : false;

    setTimeout(() => {
      this.scrollBar.moveBar();
    }, 20);

    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.customerCommentsUrl).subscribe((res: ICustomerCommentsResponse) => {
        if (res) {
          this.data = res;
          this.initData();
          this.canRender = true;
        }
      })
    );

    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
      this.subscriptions.push(
        this.gbfService.gbfOnApplyFilter.subscribe(() => {
          this.loadWidget();
        })
      );
    }
    // this.secondsCounterSub = this.secondsCounter.subscribe((n) => {
    //   // this.loadWidget();
    // });
  }

  GetFormattedDate(date: any) {
    return this.translationHelperService.GetFormattedDate(date);
  }

  loadWidget() {
    this.canRender = false;
    this.widgetService.isAllLoaded = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getCustomerComments({ apiConfig: this.apiConfig, extra: this.extra }).subscribe(
      (res: ICustomerCommentsResponse) => {
        if (res) {
          this.data = res;
          this.initData();
          this.widgetService.isAllLoaded = true;
          this.canRender = true;
        }
      },
      (error: any) => {
        this.canRender = false;
        this.widgetService.isAllLoaded = true;
      }
    );
  }

  initData() {
    if (this.data && this.data.CustomerComments && this.data.CustomerComments.length > 0) {
      this.data.CustomerComments.map((value: ICustomerComments, index: number, array: ICustomerComments[]) => {
        if (value && value?.Title) {
          const titleArr = value.Title.split(' ');
          if (titleArr.length > 0) {
            switch (titleArr.length) {
              case 1:
                value.Label = titleArr[0].substr(0, 1);
                break;
              case 2:
                value.Label = titleArr[0].substr(0, 1) + titleArr[1].substr(0, 1);
                break;
              case 3:
                value.Label = titleArr[0].substr(0, 1) + titleArr[1].substr(0, 1) + titleArr[2].substr(0, 1);
                break;
              default:
                value.Label = titleArr[0].substr(0, 1) + titleArr[1].substr(0, 1) + titleArr[2].substr(0, 1);
                break;
            }
          }
        }
        return value;
      });
    }
    this.canRender = true;
  }
  refresh(e: any) {
    e.preventDefault();
    this.loadWidget();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }
}
