import { Component, OnInit, ViewChild, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { FiltersService } from '@app/shared/services/filters.service';

@Component({
  selector: 'app-org-hirerchy-multi-filter',
  templateUrl: './org-hirerchy.multi-filter.component.html',
})
export class OrgHirerchyMultiFilterComponent implements OnInit {
  @Output() change = new EventEmitter();
  @Output() toggle = new EventEmitter();
  @Input() showMultiOrgHirerchy = false;
  @Input() levels: any = [];
  @Input() multiLevels: any = [];
  @Input() selectedOrgs: any = [];
  activatedLevel = 1;
  userLevel = -1;
  activatedHierarch: any[] = [];
  lastLevel = 2;
  parentId: any = -1;
  orgList: any;
  hierarchies: any[] = [];
  columns: any[] = [];
  searchOrg = '';
  level1: any = {
    HasChild: true,
    Id: 'National',
    IsChecked: false,
    Level: 1,
    Name: 'National',
    Parent: -1,
    Type: 'Org',
    Value: 'National',
  };
  constructor(private filterService: FiltersService) {
    this.buildHirerchy();
    this.filter = this.filter.bind(this);
  }
  onHide() {
    this.toggle.emit('');
  }
  setUp() {
    this.activatedLevel = 1;
    this.userLevel = -1;
    this.hierarchies = [];
    this.activatedHierarch = [];
    this.lastLevel = 2;
    this.parentId = -1;
    this.hierarchies = [];
    this.columns = [];
    this.toggle.emit('showMultiOrgHirerchy');
    this.ngOnInit();
  }
  ngOnInit(): void {
    this.levels = this.multiLevels || [];
    this.selectedOrgs = this.selectedOrgs || [];
    const userLevel = JSON.parse(localStorage.getItem('authorizationData'));
    if (userLevel) {
      this.userLevel = userLevel.Level;
    }
    this.orgList = this.filterService.orgList;
    if (this.orgList) {
      this.makeHirerchy(this.orgList);
      const checked = this.hierarchies.filter((hir: any) => {
        return hir.IsChecked;
      });
      this.buildHirerchy();
      if (this.userLevel > 2 && (this.levels.length < 2 || checked.length === 0)) {
        this.levels = [];
        this.changeLevel({}, this.level1);
        const level2 = this.activatedHierarch.find((x: any) => x.Level === 2);
        this.changeLevel({}, level2);
      } else if (this.userLevel > 1 && (this.levels.length < 1 || checked.length === 0)) {
        this.levels = [];
        this.changeLevel({}, this.level1);
      } else {
        if (this.userLevel <= 1) {
          const level2AndChecked = this.activatedHierarch.find((x: any) => x.Level === 2 && x.IsChecked);
          if ((this.levels.length === 1 || checked.length === 0) && !level2AndChecked) {
            this.levels = [];
          } else {
            if (this.activatedHierarch.length === 1) {
              this.levels = [];
              this.buildHirerchy();
            }
          }
        }
      }
    }
  }

  clickThrough(hir: any, i: number) {
    this.activatedLevel = hir.Level + 1;
    this.levels = this.levels.slice(0, i + 1);
    this.parentId = hir.Id;
    this.resetSelection();
    this.buildHirerchy();
    // this.change({  type: 'OrgMulti', payload: [], columns: [], levels: this.levels });
  }

  makeHirerchy(list: any) {
    list.forEach((element: any) => {
      const found = this.selectedOrgs.find((x: any) => x.Id === element.Name);
      if (found) {
        this.activatedLevel = found.Level;
        this.parentId = found.Parent;
      }
      const insertElement = {
        Id: element.Name,
        Level: element.level,
        Name: element.Label,
        Value: element.Name,
        Parent: element.parent === 'null' ? -1 : element.parent,
        IsChecked: found ? true : false,
        HasChild: false,
      };
      if (element.children && element.children.length > 0) {
        insertElement['HasChild'] = true;
        this.hierarchies.push(insertElement);
        this.makeHirerchy(element.children);
      } else {
        this.hierarchies.push(insertElement);
      }
    });
  }

  getSelectionState() {
    return this.activatedHierarch.find((x: any) => x.IsChecked === false) ? true : false;
  }

  buildHirerchy() {
    const activatedHierarch = this.hierarchies.filter((hierarchy: any) => {
      return hierarchy.Level === this.activatedLevel && hierarchy.Parent === this.parentId;
    });
    this.activatedHierarch = activatedHierarch;
  }

  changeLevel(event: any, hir: any) {
    this.activatedLevel = hir.Level + 1;
    this.levels.push(hir);
    this.parentId = hir.Id;
    this.resetSelection();
    this.buildHirerchy();
    // this.change({  type: 'OrgMulti', payload: [], columns: [], levels: this.levels });
    if (event.preventDefault) {
      event.preventDefault();
    }
  }

  selectOrDeSelectAll(event: any, selection: boolean) {
    event.preventDefault();
    this.hierarchies
      .filter((hierarchy: any) => {
        return hierarchy.Level === this.activatedLevel && hierarchy.Parent === this.parentId;
      })
      .forEach((hir: any) => {
        hir.IsChecked = selection;
      });
  }

  orgDone(event: any) {
    const checked = this.hierarchies
      .filter((hir: any) => {
        hir.Type = 'Org';
        return hir.IsChecked;
      })
      .sort((a, b) => a.Level - b.Level);
    const l = checked.length > 0 ? this.levels : [];
    this.toggle.emit('');
    this.change.emit({ type: 'OrgMulti', payload: checked, levels: l });
  }

  makeRecursiveColumns(col: any) {
    if (col.Parent !== -1) {
      this.columns.push(col);
      const found = this.hierarchies.find((x) => x.Id.toLowerCase() === col.Parent.toLowerCase());
      if (found) {
        this.makeRecursiveColumns(found);
      }
    } else {
      this.columns.push(col);
    }
  }

  resetSelection() {
    this.hierarchies.forEach((hir: any) => {
      hir.IsChecked = false;
    });
  }

  back(event: any) {
    this.activatedLevel = this.activatedLevel - 1;
    const level = this.levels.pop();
    if (level) {
      this.parentId = level.Parent;
    }
    if (this.levels.length === 0) {
      this.parentId = -1;
    }
    this.resetSelection();
    this.buildHirerchy();
    // this.change({  type: 'OrgMulti', payload: [], columns: [], levels: this.levels });
    event.preventDefault();
  }

  filter(item: any, property: string, searchField: string) {
    if (
      item !== null &&
      item !== undefined &&
      item[property] !== null &&
      item !== undefined &&
      this[searchField] !== null &&
      item !== undefined
    ) {
      return item[property].toLowerCase().search(this[searchField].toLowerCase()) === -1 ? false : true;
    }
    return false;
  }
}
