import { Directive, ElementRef, AfterViewChecked, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[myMatchHeight]',
})
export class MatchHeightDirective implements AfterViewChecked {
  // class name to match height
  @Input()
  myMatchHeight: string;

  constructor(private el: ElementRef) {}

  ngAfterViewChecked() {
    // call our matchHeight function here later
    this.matchHeight(this.el.nativeElement, this.myMatchHeight);
  }

  @HostListener('window:resize')
  onResize() {
    // call our matchHeight function here later
    this.matchHeight(this.el.nativeElement, this.myMatchHeight);
  }

  getRowsWithSameTop(x: HTMLElement, children: Element[]) {
    if (x.style.height !== 'initial') {
      return null;
    }

    const rows: HTMLElement[] = [];
    const tolerance = 5;
    const top = x.offsetTop - (x.style.marginTop !== '' ? parseFloat(x.style.marginTop) : 0);
    const lastRow = rows.length > 0 ? rows[rows.length - 1] : null;

    Array.from(children).forEach((y: HTMLElement) => {
      if (Math.abs(y.offsetTop - (x.style.marginTop !== '' ? parseFloat(x.style.marginTop) : 0) - top) <= tolerance) {
        rows.push(y);
      }
    });

    return rows;
  }

  matchHeight(parent: HTMLElement, className: string) {
    // match height logic here

    if (!parent) {
      return;
    }
    const children = parent.getElementsByClassName(className);

    if (!children) {
      return;
    }
    // reset all children height
    Array.from(children).forEach((x: HTMLElement) => {
      x.style.height = 'initial';
    });

    Array.from(children).forEach((y: HTMLElement, index, arr) => {
      // gather all height
      const rows = this.getRowsWithSameTop(y, arr);
      if (rows) {
        const itemHeights = Array.from(rows).map((x) => x.getBoundingClientRect().height);
        // find max height
        const maxHeight = itemHeights.reduce((prev, curr) => {
          return curr > prev ? curr : prev;
        }, 0);
        // apply max height
        Array.from(rows).forEach((x: HTMLElement) => (x.style.minHeight = `${maxHeight}px`));
      }
    });
  }
}
