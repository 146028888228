<header #navbar class="header" data-html2canvas-ignore="true">
  <div class="menu-wrapper">
    <div class="hamburger-menu" (click)="toggleSidebar($event)"></div>
  </div>
  <div class="logo l-logo" ngClass="{{ logoClass }}-logo">
    <a href="/"><img [src]="[logo]" /></a>
  </div>
  <!--  <p-tabMenu [styleClass]="'nav'" [model]="headeritems"></p-tabMenu>-->
  <appc-header-tabs #headerTabs></appc-header-tabs>

  <div class="navbar-right">
    <div class="user-info">
      <a
        [class.active]="menu.visible"
        (click)="menu.toggle($event); displaymenu = !displaymenu"
        class="user-dropdown-link"
      >
        <!-- <span class="user-name" translate>{{'Welcome' | translate}}, {{
          user.FirstName }} {{ user.LastName }}</span> -->
        <i *ngIf="image == '' || image == null" class="fas fa-user-circle"></i>
        <img *ngIf="image != '' && image != null" src="{{ image }}" />
        <!-- <img src="assets/images/user.jpg" alt="Ronald Henry">-->
        <!-- <a class="handler"
        ></a>-->
        <cvc-menu
          [autoZIndex]="false"
          topPosition="55px"
          [styleClass]="'action--menu'"
          [appendTo]="navbar"
          data-html2canvas-ignore="true"
          #menu
          [popup]="true"
          [model]="items"
        >
          <ng-template ngxPermissionsOnly="Admin">
            <div class="user-mode" *ngIf="showAdminMode">
              <cvc-inputSwitch [label]="adminMode" (onChange)="handleChange($event)" [(ngModel)]="mode">
              </cvc-inputSwitch>
            </div>
          </ng-template>
          <div class="user">
            <div class="user__inital">
              {{ user.FirstName[0] }}
            </div>
            <p>
              {{ 'Welcome' | translate }} {{ 'Back' | translate }},
              <strong>{{ user.FirstName }} {{ user.LastName }}</strong>
            </p>
          </div>
        </cvc-menu>
      </a>
    </div>
    <div class="user-features">
      <ng-template ngxPermissionsOnly="activity_monitoring">
        <div
          class="feature-node notification-node notification has-notification notification-box"
          click-outside
          (clickOutside)="notificationMenu($event)"
        >
          <div *ngIf="IconPermission"  class="noti-dot">
            <span class="dot"></span>
            <span class="pulse"></span>
          </div>
          <!-- <a class="handler"><i class="fal fa-bell"></i></a>-->

          <a *ngIf="IconPermission"
            class="handler"
            translate
            (click)="activeNotification = !activeNotification"
            pTooltip="{{ 'header.activitymonitoring.tooltip' | translate }}"
            tooltipPosition="top"
            (click)="getNotificationsData()"
          >
            <i class="fal fa-desktop"></i
          ></a>

          <div class="notification-popup active" *ngIf="activeNotification">
            <div class="top-bar">
              <span>Notifications ({{ notificationsCount }})</span>
            </div>
            <ul>
              <li *ngFor="let notification of notifications">
                <div [ngClass]="notification.classes">
                  <i class="{{ notification.icon }}"></i>{{ notification.message }}
                  <span><i class="far fa-calendar"></i>{{ notification.date | customdateformat: 'datetime' }}</span>
                </div>
              </li>
              <li *ngIf="notifications.length == 0" translate>No notifications found</li>
              <li *ngIf="notifications.length > 0">
                <a (click)="showModal('notification')" translate>View All</a>
              </li>
            </ul>
          </div>
        </div>
      </ng-template>
      <ng-template ngxPermissionsOnly="reporting_transmission_monitoring">
        <div class="feature-node edit-node notification">
          <!-- <a class="handler"><i class="fal fa-pencil"></i></a>-->
          <a
            class="handler"
            [ngClass]="{
              'show--count': realTimeNotificationService.notificationCount > 0
            }"
            pTooltip="{{ 'header.transmissionmonitoring.tooltip' | translate }}"
            tooltipPosition="top"
            (click)="showModal('real-time-notification')"
            ><i class="far fa-broadcast-tower"></i
            ><span class="count">{{ realTimeNotificationService.notificationCount }}</span></a
          >
        </div>
      </ng-template>
    </div>
    <div class="menu-node nav-toggle">
      <a class="handler" href="#." (click)="mobileSlideDown($event)"><i class="far fa-stream"></i></a>
    </div>
    <!-- <div class="language-switcher">
      <p-dropdown [autoZIndex]='false'
        *ngFor="let market of markets"
        [options]="market.Languages"
        [dropdownIcon]="'far fa-angle-down'"
        optionLabel="DisplayName"
        (onChange)="show('update', $event)"
        [(ngModel)]="defaultLanguage"
      >
        <ng-template let-item pTemplate="selectedItem">
          <img
            src="assets/styles/portal/build/assets/images/blank.gif"
            class="flag flag-{{ defaultLanguage.Code.split('-')[1] }}"
          />
          <span>{{ item.label }}</span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <img src="assets/styles/portal/build/assets/images/blank.gif" class="flag {{ market.Flag }}" />
          <span>{{ item.label }}</span>
        </ng-template>
      </p-dropdown>
    </div> -->
  </div>
</header>
