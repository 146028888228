<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>

  <ng-container widgetArea>
    <div style="text-align: right" [attr.data-can-render]="canRender">
        <div [hidden]="!showGraph" #graphTopClosedAlerts [style.height]="dispGraph ? '280px' : '0px'"></div>
      <br />
      <div *ngIf="resolutions && resolutions.length > 0" class="widget__content">
        <table class="table-reporting">
          <tbody>
            <tr>
              <th style="color: #222">{{ 'top_5_resolutions.title' | translate }}</th>
              <th style="color: #222">{{ 'top_5_resolutions.bycount' | translate }}</th>
            </tr>
            <tr *ngFor="let resolution of resolutions">
              <td>
                <!-- <a style="cursor:default">{{ getTranslatedResolution(resolution.Title) }}</a> -->
               {{ getTranslatedResolution(resolution.Title) }}
              </td>
              <td>
                <span style="font-weight: bold; margin-right: 10px">{{ resolution.Count }}</span>
                <span style="color: #969dab">({{ resolution.Percentage.toFixed(1) | number }}%)</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="nodata-wrapper" *ngIf="resolutions && resolutions.length == 0">
      <div class="nodata">
        <i class="nodata__icon fal fa-info-circle"></i>
        <p class="nodata__msg">{{ 'No Result Found' | translate }}</p>
      </div>
    </div>
  </ng-container>
</app-widget-dynamic>
