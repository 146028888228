import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { Notification } from '@app/core/types/notifications';
import { Tab } from '@app/core/types/Tab';
import { MatDialogRef } from '@angular/material/dialog';
import { DynamicDialogRef } from '@primeng';
import { SignalrService } from '@app/core/signalr.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @ViewChild('header', { static: true }) headerRef: any;
  @ViewChild('footer', { static: true }) footerRef: any;
  @ViewChild('content', { static: true }) contentRef: any;
  notifications: Notification[] = [];
  filteredNotifications: Notification[] = [];
  tabs: Tab[] = [];
  selectedTab: string;
  config: any;
  constructor(
    private notificationService: NotificationsService,
    private signalrService: SignalrService,
    public ref: DynamicDialogRef
  ) {
    this.getNotifications();

    this.signalrService.notificationReload.subscribe((res) => {
      if (res) {
        this.refreshNotification();
      }
    });
  }

  ngOnInit() {}

  close() {
    this.ref.close();
  }

  refreshNotification() {
    // For refreshing selected tab
    const item = this.tabs.filter((i) => {
      return i.active === true;
    })[0];
    if (item != null) {
      this.selectedTab = item.heading;
    }
    // For updating notification
    this.getNotifications();
    // Unsubscribing
    this.signalrService.notificationReload.next(false);
  }

  select(tab: string) {
    const that = this;
    reset();
    switch (tab) {
      case 'View All':
        this.filteredNotifications = this.notifications.sort((a: any, b: any) => {
          return a.date - b.date;
        });
        break;
      default: {
        this.filteredNotifications = this.notifications.filter((noti: Notification) => {
          return noti.notificationType === tab;
        });
        this.filteredNotifications.sort((a: any, b: any) => {
          return a.date - b.date;
        });
      }
    }
    function reset() {
      that.tabs.forEach((element: Tab) => {
        if (element.heading === tab) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    }
  }

  getNotifications() {
    this.notificationService.getAllRecoredsAsync('date').subscribe((data) => {
      this.notifications = this.filteredNotifications = data;

      // Filter selected tab notification
      if (this.selectedTab !== 'View All' && this.selectedTab !== undefined && this.selectedTab !== null) {
        this.filteredNotifications = data.filter((noti: Notification) => {
          return noti.notificationType === this.selectedTab;
        });
      }

      this.filteredNotifications.sort((a: any, b: any) => (new Date(a.createdon) > new Date(b.createdon) ? 1 : -1));

      if (this.tabs.length > 0) {
        this.tabs.forEach((type) => {
          if (type.heading === 'View All') {
            type.count = data.length;
          } else {
            type.count = data.filter((notif: Notification) => {
              return notif.notificationType === type.heading;
            }).length;
          }
        });
      } else {
        this.tabs.push({
          disabled: false,
          count: data.length,
          icon: '',
          classes: 'alert no-icon',
          active: true,
          heading: 'View All',
          command: () => this.select('View All'),
        });
        this.notificationService.notificationTypes.forEach((type) => {
          const count = data.filter((notif: Notification) => {
            return notif.notificationType === type.name;
          }).length;
          this.tabs.push({
            disabled: false,
            count: count,
            icon: type.icon,
            classes: type.classes,
            active: false,
            heading: type.name,
            command: () => this.select(type.name),
          });
        });
      }
    });
  }
}
