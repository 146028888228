import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  Renderer2,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
declare var SpeechRecognition: any;
@Component({
  selector: 'app-cvp-textarea',
  templateUrl: './cvp-textarea.component.html',
})
export class CvpTextareaComponent implements OnInit, AfterViewInit {
  @Input() hasMic = false;
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() disabled = '';
  @Input() textareaClass = '';
  @Input() model = '';
  @Input() maxlength = 10000;
  @Input() cols = '';
  @Input() rows = '';
  @Input() id = '';
  @Output() onChange = new EventEmitter();
  @ViewChild('myTextArea', { read: ElementRef, static: true }) MyInput: ElementRef;
  @ViewChild('voiceButton', { read: ElementRef, static: false }) VoiceButton: ElementRef;

  listening = false;
  recognition: any;

  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {}

  ngOnInit() {}
  ngOnDestroy() {
    if (this.VoiceButton) {
      this.VoiceButton.nativeElement.removeEventListener('mousedown', function (event: any) {});
    }
    //this.VoiceButton.nativeElement.removeEventListener('mousedown', function (event: any) {});
  }
  ngAfterViewInit() {
    if (this.VoiceButton) {
      this.VoiceButton.nativeElement.addEventListener('mousedown', function (event: any) {});

      var transcription = '';
      this.VoiceButton.nativeElement.addEventListener(
        'onSpeech',
        function (event: any) {
          transcription = event.detail.speechResult;
          // console.log('Speech response: ', event.detail.speechResult);
          this.onChange.emit(event.detail.speechResult);
          this.model = event.detail.speechResult;
          this.cdr.detectChanges();
          if (event.detail.isFinal) {
            this.onChange.emit(event.detail.speechResult);
            this.model = event.detail.speechResult;
            this.cdr.detectChanges();
            // transcriptionEl.classList.remove('interim');
          }
        }.bind(this)
      );

      this.VoiceButton.nativeElement.addEventListener('onStateChange', function (event: any) {
        // console.log('state:', event.detail.newValue);
      });
    }
  }

  get_browser() {
    var ua = navigator.userAgent,
      tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  }

  onResult(event: any) {
    this.onChange.emit(event.results[event.resultIndex][0].transcript);
    this.model = event.results[event.resultIndex][0].transcript;
    this.cdr.detectChanges();
  }

  listen() {
    if (this.listening && this.recognition) {
      this.listening = false;
      // this.recognition.stop();
    } else if (this.recognition) {
      this.listening = true;
      this.model = '';
      // this.recognition.start();
    }
  }

  modelChange(event: any) {
    this.model = event.target.value;
    this.onChange.emit(event.target.value);
  }

  input(event: any) {
    // console.log(event);
  }
}
