import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'gbfSort',
})
export class GBFSortPipe implements PipeTransform {
  constructor(public translateService: TranslateService) {}

  transform(items: any[], config: any): any[] {
    if (!items) {
      return [];
    }
    var ctrl = config.name;
    if (Array.isArray(items)) {
      items = items
        .map((item: any) => {
          if (item) {
            if (ctrl === 'language' || ctrl === 'dealer') {
              item.label = this.translateService.instant(item?.label);
            } else {
              item.label = item?.label;
            }
            item.IsChecked = item.IsChecked || false;
            return item;
          }
        })
        .filter(Boolean);
    }
    if (config.settings.sort === 'checked' && Array.isArray(items)) {
      const v = items.sort((a: any, b: any) => {
        let aNum = Number(a.IsChecked);
        let bNum = Number(b.IsChecked);
        // if (isNaN(aNum)) { aNum = 0 };
        // if (isNaN(bNum)) { bNum = 0 };
        return aNum - bNum || b.label - a.label;
      });
      return v.reverse();
    } else if (config.settings.sort === 'desc' && Array.isArray(items)) {
      return items.sort().reverse();
    } else if (config.settings.sort === 'asc' && Array.isArray(items)) {
      return items.sort();
    } else {
      if (Array.isArray(items)) {
        return items;
      }
      return [];
    }
  }
}
