<div>
  <cvc-menubarSub [autoZIndex]="false"
    (onItemSelect)="onItemSelect.emit($event)"
    [rootClass]="rootClass"
    [item]="model"
    root="root"
    [autoDisplay]="autoDisplay"
    [baseZIndex]="baseZIndex"
    [autoZIndex]="autoZIndex"
  >
    <ng-content></ng-content>
  </cvc-menubarSub>
  <div class="ui-menubar-custom">
    <ng-content></ng-content>
  </div>
</div>
