<ng-template #header> Settings </ng-template>
<ng-template #content>
  <div class="items" style="padding: 1em">
    <div class="item" *ngFor="let item of itemKeys; index as i">
      <p style="margin: 2px 2px 4px 0px">
        <label class="label">{{ item }}</label>
      </p>
      <input
        style="width: 100%"
        pInputText
        type="text"
        [(ngModel)]="parselItem.Item.MutableProps[item]"
        placeholder="Enter {{ item }}"
      />
    </div>
  </div>
</ng-template>
<ng-template #footer>
  <div class="footer">
    <button (click)="onDone()" class="btn">Done</button>
    <button (click)="closeDialog()" class="btn">Cancel</button>
  </div>
</ng-template>
