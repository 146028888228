<div class="filter">
    <ul>
        <ng-container *ngFor="let config of configs">
            <app-gbf-tree 
                [config]="config" 
                (offmenu)="close()"
                [class]="config.rootClass"
                [active]="true">
            </app-gbf-tree>
        </ng-container>
    </ul>
</div>