<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
  [widgetTitleToolTip]="widgetTitleToolTip"
>
  <ng-container widgetHeader>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
    <span  pInputText pTooltip="{{'Graph displays % of selected reasons whenever QP Q6 is responded with a No' | translate}}" tooltipPosition="left" style="border: none;">
      <i class="fal fa-question-circle icon-question"></i>
    </span>
   </ng-container>

  <div widgetArea class="widget__content">
    <div class="widget__graph">
      <div #containerFrftRootCauses></div>
    </div>
  </div>
</app-widget-dynamic>
