import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { Widget } from '../widgetBase';

@Component({
  selector: 'appc-widget-small',
  templateUrl: './widget-small.component.html',
  styleUrls: ['./widget-small.component.scss'],
})
export class WidgetSmallComponent extends Widget implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
