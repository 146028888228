import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'appc-layout-small',
  templateUrl: './layout-small.component.html',
  styleUrls: ['./layout-small.component.scss'],
})
export class LayoutSmallComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
