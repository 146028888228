import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { SideBarItems } from '@app/core/types/sidebarItems';
import { NavService } from '@reflecxshared/services/nav/nav.service';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  sidebarItems: SideBarItems[];
  isLocked = false;
  isOpen = false;
  isSidebar = false;
  isCustomizeDashboard: boolean = true;
  public closeSideBar: EventEmitter<void> = new EventEmitter<void>();
  public sideBarTabs: Subject<SideBarItems[]> = new Subject<SideBarItems[]>();
  activeType = new BehaviorSubject<string>(null);

  constructor(private navService: NavService) {}

  public getSidebarItems(): Observable<SideBarItems[]> {
    return this.sideBarTabs.asObservable();
  }

  public setSidebarItems(tabs: SideBarItems[]): void {
    this.sidebarItems = tabs;
    this.sideBarTabs.next(tabs);
  }

  public getItems() {
    return this.sidebarItems;
  }

  public lockSideBar() {
    this.isLocked = true;
  }

  public unLockSideBar() {
    this.isLocked = false;
  }

  public isSideBarLocked() {
    return this.isLocked;
  }

  public setActiveItems(item: string) {
    this.navService.setTitleClearView(item);
    if (this.sidebarItems) {
      this.sidebarItems.map((x, index, arr) => {
        arr[index].active = false;
        arr[index].showChild = false;
        if (item === x.name) {
          arr[index].active = true;
        }
        if (x.children && x.children.length > 0 && x.children.findIndex((y) => y.name === item) > 0) {
          arr[index].active = true;
          arr[index].showChild = true;
        }
      });
    }
  }

  public reset() {
    if (this.sidebarItems) {
      this.sidebarItems.map((x, index, arr) => {
        arr[index].active = false;
        arr[index].showChild = false;
      });
    }
  }
}
