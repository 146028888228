<app-widget-dynamic
  [removeWidgetClass]="removeWidgetClass"
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
>
  <ng-container widgetHeader>
    <div class="widget-node widget-node__print" *ngIf="allowDropDownShow">
      <p-dropdown
        [autoZIndex]="false"
        class="cba-ctrl"
        [options]="kpiNames"
        
        placeholder="{{'Overall' | translate }}"
        (onChange)="onKPIChange($event)"
        [(ngModel)]="name"
        [style]="{'width':'200px'}"
      >
      </p-dropdown>
    </div>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <!-- <div *ngFor="let code of colorCode" [style.background]=code> {{code}} </div>  -->
  <div widgetArea [attr.data-can-render]="canRender">
    <div #containerPerTreWidget></div>
  </div>
</app-widget-dynamic>
