import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/shared/services/toast/toast.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  user: any = {};
  submitted = false;
  @Input() authService: AuthenticationService;
  @Output() _Close = new EventEmitter();
  constructor(
    public ref: MatDialogRef<ChangePasswordComponent>,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.user = {
      Id: this.authService.getUser().Id,
      OldPassword: '',
      NewPassword: '',
      ConfrmPassword: '',
    };
  }

  submit(errors: any) {
    this.submitted = true;

    const error = errors.some((x: any) => {
      return x != null;
    });
    if (!error) {
      this.authService.changePassword(this.user.Id, this.user).subscribe((res) => {
        this.submitted = false;
        this.user.OldPassword = '';
        this.user.NewPassword = '';
        this.user.ConfirmPassword = '';
        this.toastService.success('Password updated successfully');
        this.ref.close();
      });
    }
  }
}
