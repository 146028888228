import { Component, OnInit } from '@angular/core';
import { Widget } from '../widgetBase';
declare const matchHeight: any;
@Component({
  selector: 'appc-widget-medium',
  templateUrl: './widget-medium.component.html',
  styleUrls: ['./widget-medium.component.scss'],
})
export class WidgetMediumComponent extends Widget implements OnInit {
  constructor() {
    super();
  }
  ngOnInit() {}
}
