<ng-template #header>
  <h2>
    <span><i class="fal fa-arrows"></i></span>{{ 'Customized Dashboard' | translate }}
  </h2>
  <div class="input-search">
    <input
      type="text"
      [(ngModel)]="searchFilter"
      (ngModelChange)="onSearchChange($event)"
      placeholder="{{ 'Search Widget' | translate }}"
      pInputText
    />
  </div>
</ng-template>
<ng-template #content>
  <div class="modal__sidebar">
    <h2>{{ 'Dashboards' | translate }}</h2>
    <ul class="left-nav">
      <li *ngFor="let cat of categories" [ngClass]="{disabled: activeTab !== cat.Name}" (click)="onCategoryChange(cat)">
        <a *ngIf="cat.show" [ngClass]="{
                active: activeTab == cat.Name
              }"><i class="fal fa-th-large"></i>{{ cat.Name | translate }}</a>
      </li>
    </ul>
  </div>
  <div class="modal__content">
    <!-- <div id="helper-drag" class="customized-widgets"  cdkDropList (cdkDropListDropped)="drop($event)"> -->
    <div id="helper-drag" class="customized-widgets">
      <div class="customized-widgets__top">
        <div> <p-dropdown *ngIf="moduleTabsObj.allowModuleTab == activeTab && moduleTabsObj.isModuleTab"
            [options]="moduleTabsObj.currentModuleTabs" [(ngModel)]="moduleTabsObj.selectedModuleTab"
            (onChange)="onTabChange($event)" [style]="{ width: '200px' }">
          </p-dropdown></div>
        <div>
          <p class="widget__selected" *ngIf="isSearchFilterTextShow == 'normal'">
            {{ getSelectedItems() }} {{ 'items selected out of' | translate }} {{ widgetsShow.length }}
          </p>
          <p class="widget__selected" *ngIf="isSearchFilterTextShow == 'No result found'">
            {{ isSearchFilterTextShow | translate }}
          </p>
        </div>
      </div>
      <div
        *ngFor="
          let widget of widgetsShow | filter: searchFilter:'label';
          let i = index;
          let first = first;
          let last = last
        "
      >
        <!-- <div class="widget__item sortable-item"  cdkDrag [cdkDragData]="widget" [cdkDragDisabled]="!widget.show"  (cdkDragMoved)="dragMoved($event)"> -->
        <div class="widget__item sortable-item">
          <div class="widget__image">
            <img
              src="assets/styles/portal/build/assets/images/customized-dashboard-thumbnails/{{ activeTab }}/{{
                widget.image
              }}"
            />
          </div>
          <h2>{{ widget.label | translate }}</h2>
          <p>{{ widget.description }}</p>
          <!-- <a *ngIf="!widget.show" (click)="widget.show = true; widget.isChecked = true;" href="JavaScript:void(0);" class="btn">{{'Add' | translate}}</a>
              <a *ngIf="widget.show" (click)="widget.show = false; widget.isChecked = false;" href="JavaScript:void(0);"
              class="btn btn--danger">{{'Remove' | translate}}</a> -->
          <div class="reordering-icons">
            <a (click)="changeOrder(i, i - 1)" [ngClass]="{ disabled: first }"
              ><i class="fas fa-arrow-alt-square-up"></i
            ></a>
            <a (click)="changeOrder(i, i + 1)" [ngClass]="{ disabled: last }"
              ><i class="fas fa-arrow-alt-square-down"></i
            ></a>
            <a *ngIf="!widget.show" (click)="widget.show = true; widget.isChecked = true" href="JavaScript:void(0);"
              ><i class="fas fa-plus-square"></i
            ></a>
            <a
              class="remove"
              *ngIf="widget.show"
              (click)="widget.show = false; widget.isChecked = false"
              href="JavaScript:void(0);"
              ><i class="fas fa-window-close"></i
            ></a>
          </div>
          <!-- <div class="customized-widgets" *cdkDragPreview>
                  <div class="widget__item sortable-item">
                      <div class="widget__image">  
                        <img 
                          [src]="'assets/styles/portal/build/assets/images/customized-dashboard/' + widget.image"
                        />
                    </div>
                  </div>
              </div>  -->
          <!-- <div class="example-custom-placeholder" *cdkDragPlaceholder></div> -->
          <div class="example-custom-placeholder"></div>
        </div>
      </div>
      <p id="reached" style="clear: both"></p>
    </div>
  </div>
</ng-template>
<ng-template #footer>
  <div>
    <a class="btn" (click)="save()">{{ 'Save' | translate }}</a>
    <a class="btn btn--cancel" (click)="close()">{{ 'Cancel' | translate }}</a>
  </div>
</ng-template>
