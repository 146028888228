import { Component, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { TreeNode, MenuItem } from 'primeng/api';
import {
  ITreeReportResponse,
  ICategoryDetails,
  ITreeReportNode,
  TreeListTopics,
  TreeListComments,
  SentimentCountStatus,
} from '../../../widgets/widget-interface';
import { BreadCrumbService } from '@app/shared/services/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { VocDashboardService } from '../../voc-dashboard.service';
import { WidgetService } from '../../../widgets/widget.service';
import { FiltersService } from '@app/shared/services/filters.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '@reflecxshared/services/nav/nav.service';
import { SidebarService } from '@app/shared/services/sidebar.service';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { ExportService } from '@clearviewshared/services/export.service';
import { CacheService } from '@app/shared/services/cache.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-tree-report',
  templateUrl: './tree-report.component.html',
  styleUrls: ['./tree-report.component.scss'],
})
export class TreeReportComponent implements OnInit {
  isMobile = window.innerWidth < 1024;
  program: any = '';
  curSentTab: MenuItem;
  currentSid: any;
  treeReport: ITreeReportResponse = {
    SentimentsTab: [],
    Faculty: [],
  };
  categoryList: ITreeReportNode[] = [];
  categoryListLevel1: ITreeReportNode[] = [];
  categoryListLevel2: ITreeReportNode[] = [];
  categoryListLevel3: ITreeReportNode[] = [];
  categoryDetails: ICategoryDetails = {
    Comments: [],
    Cloud: [],
  };
  sentimentsTab: MenuItem[];
  @ViewChild('sp2') sp2: any;
  @ViewChild('sp') sp: any;
  @ViewChild('sp3') sp3: any;
  @ViewChild('sp4') sp4: any;
  treeListTopics: TreeListTopics[] = [];
  tlTopFil: TreeListTopics[] = [];
  treeListComments: TreeListComments[] = [];
  tlComFil: TreeListComments[] = [];
  spinProg = 0;
  paraCat: any = {};
  currentFaculty: any;
  currentFacultyLvl: any;
  arrivedParam: ParamMap;
  repmod: string;
  BreakException: any = {};
  subscriptionCache: Subscription[] = [];
  isVoc: boolean = true;
  firstChildrenCategory: any;

  constructor(
    private route: ActivatedRoute,
    private navService: NavService,
    private sideBarService: SidebarService,
    private breadCrumbService: BreadCrumbService,
    private fitlerService: FiltersService,
    private vocDashboardService: VocDashboardService,
    public widgetService: WidgetService,
    private exportService: ExportService,
    public translateService: TranslateService,
    public cacheService: CacheService,
    private translationHelper: TranslationHelperService
  ) {}

  onSenTabSel(tab: any) {
    this.currentFaculty = undefined;
    this.currentFacultyLvl = undefined;
    this.resetTreeRep();
    this.curSentTab = tab;
    this.sentimentsTab.forEach((val: any, index: number) => {
      val.IsSelected = false;
      if (val.label === tab.label) {
        val.IsSelected = true;
        // if (tab.label === 'Unassigned') {
        //   // this.currentSid = SentimentCountStatus[tab.label];
        //   this.currentSid = SentimentCountStatus['Unassign'];
        // } else {
        this.currentSid = SentimentCountStatus[tab.label];
        // }
        if (this.currentSid) {
          --this.currentSid;
        }
      }
    });
    this.iTreeReport();
  }

  onFilterChange(event: MenuItem[]) {
    this.vocDashboardService.changeFilter(event);
  }

  GetCategoryTranslation(key: string) {
    return this.translationHelper.GetCategoryTranslation(key);
  }

  GetSentimentTranslation(key: string) {
    if (key !== 'All') {
      return this.translationHelper.GetSentimentTranslation(key);
    }
    return this.translateService.instant('All');
  }
  ngOnInit() {
    setTimeout(() => {
      this.sp.moveBar();
      this.sp2.moveBar();
      this.sp3.moveBar();
      this.sp4.moveBar();
    }, 20);
    this.GetMenuItems();
    this.navService.setTitleClearView(this.translateService.instant('Tree Report'));
    this.sideBarService.setActiveItems('Tree Report');
    this.navService.IsDynamicServices = true;

    // this.repmod = this.route.snapshot.paramMap.get('id');
    this.repmod = this.route.snapshot.paramMap.get('mod');
    // if (this.repmod === 'sm') {
    //   this.subscriptionCache.push(
    //     this.cacheService.for('/api/v1/vocreports/commentsreport').subscribe((res: any) => {
    //       if (res) {

    //       }
    //     })
    //   )
    // } else {

    // }
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.arrivedParam = params;
      if (this.arrivedParam && this.arrivedParam.keys.length <= 1) {
      }
    });

    // this.route.queryParamMap.subscribe((params: ParamMap) => {
    this.route.paramMap.subscribe((params: ParamMap) => {
      // this.arrivedParam = params;
      // if (this.arrivedParam && this.arrivedParam.keys.length <= 1) {

      // if (this.arrivedParam.get('mod') === 'sm') {
      if (this.repmod === 'sm') {
        this.isVoc = false;
        this.breadCrumbService.resetModule();
        this.breadCrumbService.url = '/insights/rm';
        this.breadCrumbService.updateBreadCrumb(this.translateService.instant('Reputation Management'));
        this.breadCrumbService.url = '/insights/rm/treereport/sm';
      } else {
        this.breadCrumbService.resetModule();
        this.breadCrumbService.url = '/insights/voc';
        this.breadCrumbService.updateBreadCrumb(this.translateService.instant('VOC Dashboard'));
        this.breadCrumbService.url = '/insights/voc/treereport';
      }
      // }

      this.breadCrumbService.updateBreadCrumb(this.translateService.instant('Tree Report'));

      const sta = this.arrivedParam.get('status');
      const cat = this.arrivedParam.get('category');
      const cid = this.arrivedParam.get('id');
      const lvl = this.arrivedParam.get('level');
      const mod = this.arrivedParam.get('mod');

      // if (mod && mod === 'sm'){
      //   this.paraCat.CategoryLevel = lvl;
      // }

      if (sta) {
        this.currentSid = parseInt(sta);
        if (this.currentSid > 0) {
          this.currentSid--;
        }
      }
      if (cid && cat && lvl) {
        this.paraCat.CategoryId = cid;
        this.paraCat.CategoryName = cat;
        this.paraCat.CategoryLevel = lvl;
        this.currentFaculty = undefined;
        this.currentFacultyLvl = undefined;
      }
      this.itreeReportSentTab();
    });
  }
  async GetMenuItems() {
    await this.exportService.sendMessageFromGlobalComponent('menu-no-print-export');
  }
  onTabSelect(program: string) {
    if (program) {
      this.program = program;
      this.vocDashboardService.changeProgram(program);
      this.fitlerService.setTab(this.program);
      this.itreeReportSentTab();
    }
  }

  resetTreeRep() {
    this.categoryListLevel1 = [];
    this.categoryListLevel2 = [];
    this.categoryListLevel3 = [];
    this.treeListTopics = [];
    this.tlTopFil = [];
    this.treeListComments = [];
    this.tlComFil = [];
    this.categoryDetails.Comments = [];
    this.categoryDetails.Cloud = [];
  }

  mapSenTab() {
    if (this.treeReport && this.treeReport.SentimentsTab) {
      this.sentimentsTab = this.treeReport.SentimentsTab.map((val: any) => {
        return { label: val.Title, count: val.Count, icon: 'pi pi-fw pi-cog' };
      });
      const sentimentId = this.arrivedParam.get('status');
      if (sentimentId) {
        const sentiment = SentimentCountStatus[parseInt(sentimentId)];
        this.sentimentsTab.forEach((itm: any) => {
          if (itm.label === sentiment) {
            this.curSentTab = itm;
          }
        });
      }
      if (this.sentimentsTab && this.sentimentsTab[0] && !this.curSentTab) {
        this.curSentTab = this.sentimentsTab[0];
      } else {
        this.sentimentsTab.forEach((itm: any) => {
          if (this.curSentTab.label === itm.label) {
            this.curSentTab = itm;
          }
        });
      }
      this.iTreeReport();
    }
  }

  catLstLvlIn(category: ITreeReportNode, level: number) {
    this.currentFaculty = category;
    this.currentFacultyLvl = level;
    this.spinProg += 1;

    if (this.spinProg > 1) {
      this.spinProg -= 1;
      return;
    }
    this.treeListTopics = [];
    this.tlTopFil = [];
    this.treeListComments = [];
    this.tlComFil = [];
    this.categoryDetails.Comments = [];
    this.categoryDetails.Cloud = [];

    switch (level) {
      case 1:
        this.categoryListLevel2 = [];
        this.categoryListLevel3 = [];
        if (category.children && category.children.length > 0) {
          this.categoryListLevel2 = category.children;
        }
        this.seltopLvl(category.children, category);
        this.unSelmidLvl(category.children, null);
        this.unSeldetLvl(category.children, null);
        break;
      case 2:
        this.categoryListLevel3 = [];
        if (category.children && category.children.length > 0) {
          this.categoryListLevel3 = category.children;
        }
        this.unSelmidLvl(category.children, category);
        this.unSeldetLvl(category.children, null);
        break;
      case 3:
        this.unSeldetLvl(category.children, category);
        break;
    }

    setTimeout(() => {
      this.sprecur();
    }, 10);

    if (category.Name === 'All') {
      if (category.children && category.children.length > 0) {
        category.IsSelected = true;
        // category.children[0].IsSelected = true;
        // this.getTopCom(category.children[0], (level + 1));
        this.getTopCom(category, level + 1);
        // If All else comment
        // this.spinProg = 0;
      } else {
        this.getTopCom(category, level);
      }
    } else {
      this.getTopCom(category, level);
    }
  }

  onInitialloadSelectCategory(category: ITreeReportNode, level: number) {
    switch (level) {
      case 1:
        this.categoryListLevel2 = [];
        this.categoryListLevel3 = [];
        if (category.children && category.children.length > 0) {
          this.categoryListLevel2 = category.children;
        }
        this.categoryListLevel1.forEach((val: any, index: number) => {
          val.IsSelected = false;

          if (category !== null) {
            if (val.Name === category.Name) {
              val.IsSelected = true;
            }
          }
        });

        break;
    }
  }

  itreeReportSentTab() {
    // if (this.arrivedParam.get('mod') === 'sm') {
    if (this.repmod === 'sm') {
      this.vocDashboardService.getTreeReportSentimentTabSocial(this.currentSid, this.paraCat).subscribe(
        (res: any) => {
          if (res && res.SentimentsTab) {
            this.treeReport.SentimentsTab = res.SentimentsTab;
            this.mapSenTab();
          }
        },
        (error: any) => {}
      );
    } else {
      this.vocDashboardService.getTreeReportSentimentTab(this.currentSid, this.paraCat).subscribe(
        (res: any) => {
          if (res && res.SentimentsTab) {
            this.treeReport.SentimentsTab = res.SentimentsTab;
            this.mapSenTab();
          }
        },
        (error: any) => {}
      );
    }
  }

  getTopCom(category: ITreeReportNode, level: number) {
    // this.widgetService.getCategoryTopicsAndComments((this.arrivedParam.get('mod') === 'sm') ? 'sm-reports' : 'voc', category, level, this.currentSid).subscribe((res: any) => {
    this.widgetService
      .getCategoryTopicsAndComments(this.repmod === 'sm' ? 'sm-reports' : 'voc', category, level, this.currentSid)
      .subscribe(
        (res: any) => {
          if (res) {
            if (res.TrendingTopics) {
              res.TrendingTopics.forEach((lst: any) => {
                lst.Topics.forEach((topic: any) => {
                  this.treeListTopics.push({
                    Title: topic.Title,
                    Sentiment: lst.Sentiment,
                    Count: topic.Count,
                  });
                });
              });
            }
            if (res.CategoryComments) {
              this.treeListComments = res.CategoryComments;
            }
            this.spinProg = 0;
            // this.filterTopics();
            this.filterTopicsSim();
          }
        },
        (error: any) => {}
      );
  }

  filterTopics() {
    if (this.curSentTab.label.toLowerCase() !== 'all') {
      this.tlTopFil = this.treeListTopics.filter((topic: any) => {
        return topic.Sentiment.toLowerCase() === this.curSentTab.label.toLowerCase();
      });
    } else {
      this.tlTopFil = this.treeListTopics;
    }

    this.tlTopFil = this.tlTopFil.sort((a, b) => (a.Count < b.Count ? 1 : -1)).slice(0, 10);
    this.tlTopFil = this.shuffle(this.tlTopFil);

    if (this.curSentTab.label.toLowerCase() !== 'all') {
      this.tlComFil = this.treeListComments.filter((comment: any) => {
        return comment.Sentiment.toLowerCase() === this.curSentTab.label.toLowerCase();
      });
    } else {
      this.tlComFil = this.treeListComments;
    }
    this.tlComFil = this.tlComFil.sort((a, b) => (a.Sentiment.toLowerCase() === 'negative' ? 1 : -1));
  }

  mapCategory(res: any) {
    const categories = this.unflatten(res.VocFaculty).map((x: any) => {
      x.IsSelected = false;
      if (x.children) {
        x.children.map((y: any) => {
          y.IsSelected = false;
          if (y.children) {
            y.children.map((z: any) => {
              z.IsSelected = false;
              return z;
            });
          }
          return y;
        });
      }
      return x;
    });

    let category = this.arrivedParam.get('id');
    if (category) {
      try {
        this.facCatRec(categories, category);
      } catch (e) {}
    } else {
      category = 'All';
      if (categories && categories.length > 0) {
        this.categoryListLevel1 = categories;

        const i = categories[0].children.findIndex((k: any) => k.children);
        const dCat = categories[0];
        dCat.IsSelected = true;
        if (i > -1) {
          const dCat1 = categories[0].children[i];
          this.onInitialloadSelectCategory(dCat, dCat.Level - 1);
          this.catLstLvlIn(dCat1, dCat1.Level - 1);
        } else {
          this.catLstLvlIn(dCat, dCat.Level - 1);
        }

        this.spinProg = 1;
      }
    }
  }

  facCatRec(facultyCategoy: ITreeReportNode[], searchFac: any): any {
    facultyCategoy.forEach((val: any) => {
      if (val.Label !== 'All') {
        switch (val.Level) {
          case 2:
            this.categoryListLevel1 = facultyCategoy;
            break;
          case 3:
            this.categoryListLevel2 = facultyCategoy;
            break;
          case 4:
            this.categoryListLevel3 = facultyCategoy;
            break;
        }

        if (val.Name === searchFac) {
          val.IsSelected = true;
          this.catLstLvlIn(val, val.Level - 1);
          // this.spinProg = 0;
          throw this.BreakException;
        } else {
          if (val.children && val.children.length > 0) {
            val.IsSelected = true;
            this.facCatRec(val.children, searchFac);
            facultyCategoy.forEach((val2: any) => {
              val2.IsSelected = false;
            });
          }
        }
      }
    });
  }

  unSelmidLvl(children: ITreeReportNode[], category: ITreeReportNode) {
    this.categoryListLevel2.forEach((val: any, index: number) => {
      val.IsSelected = false;
      if (category !== null) {
        if (val.Name === category.Name) {
          val.IsSelected = true;
        }
      }
    });
  }

  unflatten(arr: any) {
    let tree: any = [];
    arr.forEach((element: any) => {
      switch (element.Level) {
        case 2: {
          tree = element.Data[0].Nodes;
          tree.forEach((ele: any) => {
            ele.Level = element.Level;
          });
          break;
        }
        case 3: {
          element.Data.forEach((l2: any) => {
            for (let i = 0; i < tree.length; i++) {
              if (tree[i].Name === l2.Parent) {
                tree[i]['children'] = l2.Nodes;
                tree[i]['children'].forEach((ele: any) => {
                  ele.Level = element.Level;
                });
              }
            }
          });
          break;
        }
        case 4: {
          element.Data.forEach((l3: any) => {
            for (let i = 0; i < tree.length; i++) {
              if (tree[i].children) {
                for (let j = 0; j < tree[i].children.length; j++) {
                  if (tree[i].children[j].Name === l3.Parent) {
                    tree[i].children[j]['children'] = l3.Nodes;
                    tree[i].children[j]['children'].forEach((ele: any) => {
                      ele.Level = element.Level;
                    });
                  }
                }
              }
            }
          });
          break;
        }
      }
    });
    return tree;
  }

  seltopLvl(children: ITreeReportNode[], category: ITreeReportNode) {
    this.categoryListLevel1.forEach((val: any, index: number) => {
      val.IsSelected = false;

      if (category !== null) {
        if (val.Name === category.Name && category.Name !== 'All') {
          val.IsSelected = true;
        }
      }
    });
  }

  shuffle(array: any) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  filterTopicsSim() {
    if (this.curSentTab.label.toLowerCase() !== 'all') {
      this.tlTopFil = this.treeListTopics.filter((topic: any) => {
        return topic.Sentiment.toLowerCase() === this.curSentTab.label.toLowerCase();
      });
    } else {
      this.tlTopFil = this.treeListTopics;
    }

    this.tlTopFil = this.tlTopFil.sort((a, b) => (a.Count < b.Count ? 1 : -1)).slice(0, 10);
    // this.tlTopFil = this.shuffle(this.tlTopFil);
    this.tlComFil = this.treeListComments.sort((a, b) => (a.Sentiment.toLowerCase() === 'negative' ? 1 : -1));
    setTimeout(() => {
      this.sprecur();
    }, 50);
  }

  unSeldetLvl(children: ITreeReportNode[], category: ITreeReportNode) {
    this.categoryListLevel3.forEach((val: any, index: number) => {
      val.IsSelected = false;
      if (category !== null) {
        if (val.Name === category.Name) {
          val.IsSelected = true;
        }
      }
    });
  }

  sprecur() {
    if (this.sp4) {
      this.sp4.moveBar();
    }
    if (this.sp2) {
      this.sp2.moveBar();
    }
  }

  iTreeReport() {
    // if (this.arrivedParam.get('mod') === 'sm') {
    if (this.repmod === 'sm') {
      this.vocDashboardService.getAspectCategoriesSocial(this.currentSid, this.paraCat).subscribe(
        (res: any) => {
          if (res && res.VocFaculty) {
            this.treeReport.Faculty = res.VocFaculty;
            if (res.VocFaculty && res.VocFaculty.length > 0) {
              this.mapCategory(res);
              if (this.currentFaculty && this.currentFacultyLvl) {
                // this.catLstLvlIn(this.currentFaculty, this.currentFacultyLvl);
              } else {
                // if(this.categoryListLevel1 && this.categoryListLevel1[0])
              }
            } else {
              this.resetTreeRep();
            }
          }
        },
        (error: any) => {}
      );
    } else {
      this.vocDashboardService.getAspectCategories(this.currentSid, this.paraCat).subscribe(
        (res: any) => {
          if (res && res.VocFaculty) {
            this.treeReport.Faculty = res.VocFaculty;
            if (res.VocFaculty && res.VocFaculty.length > 0) {
              this.mapCategory(res);
              if (this.currentFaculty && this.currentFacultyLvl) {
                // this.catLstLvlIn(this.currentFaculty, this.currentFacultyLvl);
              } else {
                // if(this.categoryListLevel1 && this.categoryListLevel1[0])
              }
            } else {
              this.resetTreeRep();
            }
          }
        },
        (error: any) => {}
      );
    }
  }

  applyFilters() {
    this.vocDashboardService.changeProgram(this.program);
    this.itreeReportSentTab();
  }
}
