import { Component, OnInit, Inject, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from '@primeng';
@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @ViewChild('header', { static: true }) headerRef: any;
  @ViewChild('footer', { static: true }) footerRef: any;
  @ViewChild('content', { static: true }) contentRef: any;
  data: any;
  message: string;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.data = this.config['data'];
    this.message = this.data.message;
  }

  ngOnInit() {}

  Yes() {
    this.ref.close(true);
  }

  No() {
    this.ref.close(false);
  }
}
