<ng-template #header>
  <h2>
    <span><i class="fal fa-cog"></i></span>{{ 'Account Setting' | translate }}
  </h2>
</ng-template>
<ng-template #content>
  <div class="modal__sidebar">
    <ul class="left-nav">
      <li (click)="select('profile')">
        <a [class.active]="activeType == 'profile'">
          <i class="fal fa-user"></i>
          {{ 'My Profile' | translate }}
        </a>
      </li>
      
      <ng-template *ngIf="!isSSO" ngxPermissionsOnly="accset_ChangePassword">
        <li (click)="select('changepassword')">
          <a [class.active]="activeType == 'changepassword'">
            <i class="fal fa-bullseye"></i>
            {{ 'Change Password' | translate }}
          </a>
        </li>
      </ng-template>
      <ng-template ngxPermissionsOnly="accset_ChangeLanguage">
        <li (click)="select('changelanguage')">
          <a [class.active]="activeType == 'changelanguage'">
            <i class="fal fa-language"></i>
            {{ 'Change Language' | translate }}
          </a>
        </li>
      </ng-template>
      <!-- <ng-template ngxPermissionsOnly="two_factor_settings"> -->
      <div *ngIf="is2faClientLevel == false && is2faAccountLevel == true">
        <li (click)="select('2fa')">
          <a [class.active]="activeType == '2fa'">
            <i class="fal fa-wrench"></i>
            {{ 'Two Factor Auth' | translate }}
          </a>
        </li>
      </div>
      <ng-template ngxPermissionsOnly="accset_Settings">
        <li (click)="select('settings')">
          <a [class.active]="activeType == 'settings'">
            <i class="fal fa-wrench"></i>
            {{ 'Settings' | translate }}
          </a>
        </li>
      </ng-template>

      <!-- <li (click)="select('bugreport')">
        <a [class.active]="activeType == 'bugreport'"><i class=""></i> Bug Report</a>
      </li> -->
      <!-- <li (click)="select('timezone')">
        <a [class.active]="activeType == 'timezone'"
          ><i class="far fa-clock"></i> Time Zone Selection</a
        >
      </li> -->
    </ul>
  </div>
  <div [ngSwitch]="activeType" class="modal__content">
    <ng-container *ngSwitchCase="'profile'">
      <app-profile [service]="service" (_Submit)="submitProfilePic($event)"></app-profile>
    </ng-container>
    <ng-template ngxPermissionsOnly="accset_ChangePassword">
      <ng-container *ngSwitchCase="'changepassword'">
        <!-- <app-change-password [isPasswordChange]="isPasswordChange" [authService]="service" (_Close)="onClose($event)"></app-change-password> -->
        <div class="modal-form">
          <form [formGroup]="registerForm">
            <div class="row">
              <h3>{{ 'Change Password' | translate }}</h3>
            </div>
            <div class="row">
              <div class="col-left">
                <label>{{ 'Current_password.title' | translate }}</label>
              </div>
              <div class="col-right">
                <input
                  type="password"
                  formControlName="password"
                  class="ui-inputtext form-control"
                  autocomplete="new-password"
                  onblur="this.setAttribute('readonly', 'readonly');"
                  onfocus="this.removeAttribute('readonly');"
                  readonly
                  autocomplete="off"
                  [ngClass]="{ error: f.password.errors && submitted }"
                  placeholder="{{ 'enter_current_password.placeholder' | translate }}"
                />

                <div class="info error" *ngIf="f.password.errors && submitted">
                  <p *ngIf="f.password.errors.minlength">
                    {{ 'Current password is too short.' | translate }}
                  </p>
                  <p *ngIf="f.password.errors.required">
                    {{ 'Current password is required.' | translate }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-left">
                <label>{{ 'new_password.label' | translate }}</label>
              </div>
              <div class="col-right">
                <div class="password-strength">
                  <!-- <input type="password" class="ui-inputtext form-control" placeholder="Enter New Password" />-->
                  <input
                    type="password"
                    formControlName="newPassword"
                    (keyup)="onPaswordChange($event.target.value)"
                    class="ui-inputtext form-control"
                    [ngClass]="{ error: f.newPassword.errors && submitted }"
                    autocomplete="off"
                    onblur="this.setAttribute('readonly', 'readonly');"
                    onfocus="this.removeAttribute('readonly');"
                    readonly
                    placeholder="{{ 'enter_current_password.placeholder' | translate }}"
                  />
                  <div class="password-strength-bar">
                    <div *ngIf="strengthColor == 'red'" class="password-strength-block weak"></div>

                    <div *ngIf="strengthColor == 'orange'" class="password-strength-block moderate"></div>
                    <div *ngIf="strengthColor == 'orange'" class="password-strength-block moderate"></div>

                    <div *ngIf="strengthColor == 'green'" class="password-strength-block strong"></div>
                    <div *ngIf="strengthColor == 'green'" class="password-strength-block strong"></div>
                    <div *ngIf="strengthColor == 'green'" class="password-strength-block strong"></div>
                  </div>
                </div>
                <div class="info error" *ngIf="submitted">
                  <p *ngIf="f.newPassword.errors && f.newPassword.errors?.required">
                    {{ 'New password is required.' | translate }}
                  </p>
                  <p *ngIf="!f.newPassword.errors?.required && passwordPolicy != null && isPasswordPolicyError">
                    {{ passwordPolicyError | translate }}
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-left">
                <label>{{ 'Re-type Password' | translate }}</label>
              </div>
              <div class="col-right">
                <input
                  type="password"
                  formControlName="confirmPassword"
                  class="ui-inputtext form-control"
                  autocomplete="new-password"
                  onblur="this.setAttribute('readonly', 'readonly');"
                  onfocus="this.removeAttribute('readonly');"
                  readonly
                  autocomplete="off"
                  [ngClass]="{ error: f.confirmPassword.errors && submitted }"
                  placeholder="{{ 'enter_current_password.placeholder' | translate }}"
                />
                <div class="info error" *ngIf="f.confirmPassword.errors && submitted">
                  <p *ngIf="f.confirmPassword.errors.minlength">
                    {{ 'Confirm password is too short.' | translate }}
                  </p>
                  <p *ngIf="f.confirmPassword.errors.required">
                    {{ 'Confirm password is required.' | translate }}
                  </p>

                  <p *ngIf="f.confirmPassword.errors.mustMatch">
                    {{ 'Password must Match.' | translate }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="passwordPolicy != null">
              <div class="password-hint">
                <p>
                  {{ 'change_password_message' | translate }}
                </p>
                <ul>
                  <li *ngIf="passwordPolicy.IsUpperCase">{{ 'change_password_message1' | translate }}</li>
                  <li *ngIf="passwordPolicy.IsLowerCase">{{ 'change_password_message6' | translate }}</li>
                  <li *ngIf="passwordPolicy.IsSpecialCharacter">{{ 'change_password_message2' | translate }}</li>
                  <li *ngIf="passwordPolicy.IsDigit">{{ 'change_password_message3' | translate }}</li>
                  <li *ngIf="passwordPolicy.Length > 0">{{ lengthMessage }}</li>
                  <li *ngIf="passwordPolicy.LastUsedPassword > 0">{{ lastUsedPasswordMessage }}</li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </ng-container>
    </ng-template>
    <ng-template ngxPermissionsOnly="accset_ChangeLanguage">
      <ng-container *ngSwitchCase="'changelanguage'">
        <div class="modal-form">
          <div class="row">
            <h3>{{ 'Change Language' | translate }}</h3>
          </div>
          <div class="row">
            <div class="col-left">
              <label>{{ 'Language' | translate }}</label>
            </div>
            <div class="col-right">
              <div class="language-switcher">
                <p-dropdown
                  [autoZIndex]="false"
                  panelStyleClass="modal-dropdown"
                  *ngFor="let market of markets"
                  [appendTo]="'body'"
                  [style]="{ width: '80%' }"
                  [options]="portalLanguages"
                  [dropdownIcon]="'far
                  fa-angle-down'"
                  optionLabel="DisplayName"
                  [(ngModel)]="defaultLanguage"
                >
                  <ng-template let-item pTemplate="selectedItem">
                    <img
                      src="assets/styles/portal/build/assets/images/blank.gif"
                      class="flag flag-{{ defaultLanguage.Code.split('-')[1] }}"
                    />
                    <span>{{ item?.DisplayName }}</span>
                  </ng-template>

                  <ng-template let-item pTemplate="item">
                    <img src="assets/styles/portal/build/assets/images/blank.gif" class="flag {{ market.Flag }}" />
                    <span>{{ item?.DisplayName }}</span>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <!-- <ng-template ngxPermissionsOnly="two_factor_settings"> -->
    <div *ngIf="is2faClientLevel == false && is2faAccountLevel == true">
      <ng-container *ngSwitchCase="'2fa'">
        <div class="modal-form">
          <div class="row">
            <h3>{{ 'Two Factor Authentication' | translate }}</h3>
          </div>
          <ul class="navigator-list">
            <li class="icon--item"><i class="fal fa-comment"></i></li>
            <li>{{ 'Enable/Disable' | translate }}</li>
            <li>
              <cvc-inputSwitch [(ngModel)]="twoFactorAuthMode"> </cvc-inputSwitch>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
    <ng-template ngxPermissionsOnly="accset_Settings">
      <ng-container *ngSwitchCase="'settings'">
        <div class="modal-form">
          <div class="row">
            <h3>{{ 'Settings' | translate }}</h3>
          </div>

          <ng-template ngxPermissionsOnly="tour_guide">
            <div class="row">
              <div class="navigator">
                <ul class="navigator-list">
                  <li class="icon--item"><i class="fal fa-comment"></i></li>
                  <li>{{ 'Tour Guide' | translate }}</li>
                  <li>
                    <cvc-inputSwitch [(ngModel)]="mode"> </cvc-inputSwitch>
                  </li>
                </ul>
              </div>
            </div>
          </ng-template>

          <!-- <div class="row">
            <div class="navigator">
            <ul class="navigator-list">
                <li class="icon--item"><i class="fal fa-hand-point-up"></i></li>
              <li>Smart Navigator</li>
              <li>

              </li>
            </ul>
            </div>

          </div> -->
        </div>
      </ng-container>
    </ng-template>
    <ng-container *ngSwitchCase="'bugreport'">
      <app-bug-report [service]="service" (_Close)="onClose($event)"></app-bug-report>
    </ng-container>
    <ng-container *ngSwitchCase="'timezone'">
      <app-timezone></app-timezone>
    </ng-container>
  </div>
</ng-template>
<ng-template #footer>
  <div *ngIf="activeType == 'profile'">
    <a class="btn" *ngIf="!currentUser?.isSSO" (click)="saveProfilePic($event)" translate>Save</a>
    <a class="btn btn--cancel" (click)="onClose($event)" translate>Cancel</a>
  </div>
  <div *ngIf="activeType == 'changepassword'">
    <a class="btn" (click)="submit()" translate>Save</a>
    <a class="btn btn--cancel" (click)="onClose($event)" translate>Cancel</a>
  </div>
  <div *ngIf="activeType == 'bugreport'">
    <a class="btn" translate>Save</a>
    <a class="btn btn--cancel" click)="onClose($event)" translate>Cancel</a>
  </div>
  <div *ngIf="activeType == 'changelanguage'">
    <a class="btn" (click)="changeLanguage()" translate>Save</a>
    <a class="btn btn--cancel" (click)="onClose($event)" translate>Cancel</a>
  </div>
  <div *ngIf="activeType == '2fa'">
    <a class="btn" (click)="save2FASettings()" translate>Save</a>
    <a class="btn btn--cancel" (click)="onClose($event)" translate>Cancel</a>
  </div>
  <div *ngIf="activeType == 'settings'">
    <a class="btn" (click)="saveUserSettings()" translate>Save</a>
    <a class="btn btn--cancel" (click)="onClose($event)" translate>Cancel</a>
  </div>
</ng-template>
