import { Input, EventEmitter, Directive } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DefaultColumnsKeys } from '../report_items';

@Directive()
export abstract class ChartBase {
  @Input() module: any = 'RG';
  @Input() chartConfig: any = '';
  @Input() activatedLevel: any = 1;
  steps = ['configuration', 'graph'];
  step = 'configuration';
  fields: any = [];
  Max_Level = 50;
  Max_Limit_Error_Key: any = 'Please_select_less_fields';
  chunk_fields: any = [];
  selectedField: any;
  @Input() columns: any = [];
  @Input() records: any = [];
  chunk: any = 3;
  isAllFieldSelection = false;
  constructor(public translateService: TranslateService) {}
  OnInit() {
    this.isAllFieldSelection = false;
    this.fields = this.columns.map((fm: any) => {
      const title = fm.MutableProps.Label !== '' ? fm.MutableProps.Label : fm.Translate;
      return { label: title, value: fm.Key, chartTypeLine: 'line' };
    });
    this.chunk_fields = this.fields.chunk(this.chunk);
  }

  getYearSelectionState() {
    let rVal = false;
    this.chunk_fields.forEach((root: any) => {
      root.forEach((child: any) => {
        if (child.checked === true) {
          rVal = true;
        }
      });
    });
    return rVal;
  }

  selectOrDeselctAll(e: any, val: boolean) {
    this.selection(val);
    this.isAllFieldSelection = val;
  }

  selection(val: boolean) {
    this.chunk_fields.forEach((root: any) => {
      root.forEach((child: any) => {
        child.checked = val;
      });
    });
  }

  getFieldByKey(key: string) {
    const f = this.columns.find((x: any) => x.Key === key);
    if (f) {
      return f;
    } else {
      return '';
    }
  }

  getLabel(record: any) {
    let ifOrg = '';
    for (let i = 1; i <= this.activatedLevel; i++) {
      const levelLabel = 'Level' + i;
      if (record[levelLabel] && record[levelLabel].trim() != '') {
        ifOrg += ` ${record[levelLabel]}`;
      }
    }
    const ifProgram = record[DefaultColumnsKeys.Program]
      ? ` ${this.translateService.instant(record[DefaultColumnsKeys.Program])}`
      : '';
    const ifVerion = record[DefaultColumnsKeys.Version]
      ? ` ${this.translateService.instant(record[DefaultColumnsKeys.Version])}`
      : '';
    const ifDatePeriod = record[DefaultColumnsKeys.DatePeriod]
      ? ` ${this.translateService.instant(record[DefaultColumnsKeys.DatePeriod])}`
      : '';
    const ifBrand = record[DefaultColumnsKeys.Brand]
      ? ` ${this.translateService.instant(record[DefaultColumnsKeys.Brand])}`
      : '';
    const ifModel = record[DefaultColumnsKeys.Model]
      ? ` ${this.translateService.instant(record[DefaultColumnsKeys.Model])}`
      : '';
    const ifYear = record[DefaultColumnsKeys.ModelYear]
      ? ` ${this.translateService.instant(record[DefaultColumnsKeys.ModelYear])}`
      : '';
    const label = `${ifProgram}${ifVerion}${ifOrg}${ifDatePeriod}${ifBrand}${ifModel}${ifYear}`;
    return label.trim();
  }
}
