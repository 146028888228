import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'appc-layout-medium',
  templateUrl: './layout-medium.component.html',
  styleUrls: ['./layout-medium.component.scss'],
})
export class LayoutMediumComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
