import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { WidgetService } from '../widget.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { CacheService } from '@app/shared/services/cache.service';
import { FiltersService } from '@app/shared/services/filters.service';
import { AuthenticationService } from '@app/core';
import { DialogService } from '@primeng';
import { CommentListModalComponent } from '@clearviewshared/components/comment-list-modal/comment-list-modal.component';
import { ConfirmationModalComponent } from '@clearviewshared/components/confirmation-modal/confirmation-modal.component';
import { SelectItem } from 'primeng/api';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { ICustomerJourneyResponse } from './types/ICustomerJourneyResponse';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';

@Component({
  selector: 'app-customer-journey-widget',
  templateUrl: './customer-journey-widget.component.html',
  styleUrls: ['./customer-journey-widget.component.scss'],
})
export class CustomerJourneyWidgetComponent extends WidgetBase implements OnInit, OnDestroy {
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  ProgramSubscribtion: Subscription;
  FilterSubscribtion: Subscription;
  right: any = [];
  data: any;
  filters: any;
  left: any = [];
  nps: [{ label: any; value: any }];
  selectedrepurchase: '';
  selectednps: '';
  repurchase: [{ label: any; value: any }];
  canRender = false;
  disableLoyality = false;
  disbleNps = false;
  rightNpsSelectedValue = 'All';
  leftNpsSelectedValue = 'All';
  rightLoyalitySelectedValue = 'All';
  leftLoyalitySelectedValue = 'All';
  valueleft: number = 71.2;
  valueright: number = 75.4;

  api: Subscription;
  private subscriptions: Subscription[] = [];
  constructor(
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public toastService: ToastService,
    private filterService: FiltersService,
    public gbfService: GlobalFilterService,
    private translationHelper: TranslationHelperService,
    private authService: AuthenticationService,
    public dialogService: DialogService
  ) {
    super(gbfService, translateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.filters = this.filterService.getFilterObject();

    this.left.TotalExperience = 0;
    this.loadWidget('', '');
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.customerJourneyUrl).subscribe((res: ICustomerJourneyResponse) => {
        if (res) {
          this.data = res;
          this.right = this.data.RightSection;
          this.left = this.data.LeftSection;
          this.nps = this.right.Loyalities;
          this.repurchase = this.left.Loyalities;
          this.canRender = true;
        }
      })
    );
    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget('', '');
        })
      );
      this.subscriptions.push(
        this.gbfService.gbfOnApplyFilter.subscribe(() => {
          this.loadWidget('', '');
        })
      );
    }
  }
  loadWidget(key: string, value: string) {
    this.canRender = false;
    if (this.filters.Event && this.filterService.getProgram() === 'Collision') {
      this.disableLoyality = true;
      this.disbleNps = true;
    } else {
      this.disbleNps = false;
      this.disableLoyality = false;
    }

    this.widgetService.isAllLoaded = false;
    this.rightLoyalitySelectedValue = 'All';
    this.leftLoyalitySelectedValue = 'All';
    this.rightNpsSelectedValue = 'All';
    this.leftNpsSelectedValue = 'All';

    if (this.api) {
      this.api.unsubscribe();
    }

    this.api = this.widgetService
      .getCustomerJourney(key, value, { apiConfig: this.apiConfig, extra: this.extra })
      .subscribe(
        (res: ICustomerJourneyResponse) => {
          if (res) {
            this.data = res;

            this.data.LeftSection.Loyalities.forEach((element: any) => {
              element.label = this.GetCustomerJourneyLoyalitiesTranslation(element.label);
            });

            this.data.RightSection.Loyalities.forEach((element: any) => {
              element.label = this.GetCustomerJourneyNPSTranslation(element.label);
            });

            // this.data.RightSection.Kpis.forEach((element: any) => {
            //   element.Name = this.GetSurveyKpiNameTranslation(element.Name);
            // });

            this.right = this.data.RightSection;
            this.left = this.data.LeftSection;
            this.nps = this.right.Loyalities;
            this.repurchase = this.left.Loyalities;
            this.widgetService.isAllLoaded = true;
            this.canRender = true;
          }
        },
        (error: any) => {
          this.canRender = false;
          this.widgetService.isAllLoaded = true;
        }
      );
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });
  }
  leftNpsChange(event: any) {
    if (event.value === 'All') {
      this.loadWidget('', '');
    } else {
      this.leftNpsSelectedValue = event.value;
      this.leftLoyalitySelectedValue = 'All';

      this.disableLoyality = true;
      this.canRender = false;
      this.widgetService.isAllLoaded = false;
      this.widgetService
        .getCustomerJourney('leftNps', event.value, { apiConfig: this.apiConfig, extra: this.extra })
        .subscribe(
          (res: any) => {
            if (res) {
              this.left = res.LeftSection;
              this.widgetService.isAllLoaded = true;
              this.canRender = true;
            }
          },
          (error: any) => {
            this.canRender = false;
            this.widgetService.isAllLoaded = true;
          }
        );
    }
  }
  rightNpsChange(event: any) {
    if (event.value === 'All') {
      this.loadWidget('', '');
    } else {
      this.rightNpsSelectedValue = event.value;
      this.rightLoyalitySelectedValue = 'All';

      this.disableLoyality = true;
      this.canRender = false;
      this.widgetService.isAllLoaded = false;
      this.widgetService
        .getCustomerJourney('rightNps', event.value, { apiConfig: this.apiConfig, extra: this.extra })
        .subscribe(
          (res: any) => {
            if (res) {
              this.right = res.RightSection;
              this.widgetService.isAllLoaded = true;
              this.canRender = true;
            }
          },
          (error: any) => {
            this.canRender = false;
            this.widgetService.isAllLoaded = true;
          }
        );
    }
  }
  leftLoyalityChange(event: any) {
    if (event.value === 'All') {
      this.loadWidget('', '');
    } else {
      this.leftLoyalitySelectedValue = event.value;
      this.leftNpsSelectedValue = 'All';

      this.canRender = false;
      this.disbleNps = true;
      this.widgetService.isAllLoaded = false;
      this.widgetService
        .getCustomerJourney('leftLoyality', event.value, { apiConfig: this.apiConfig, extra: this.extra })
        .subscribe(
          (res: any) => {
            if (res) {
              this.left = res.LeftSection;
              this.widgetService.isAllLoaded = true;
              this.canRender = true;
            }
          },
          (error: any) => {
            this.canRender = false;
            this.widgetService.isAllLoaded = true;
          }
        );
    }
  }
  rightLoyalityChange(event: any) {
    if (event.value === 'All') {
      this.loadWidget('', '');
    } else {
      this.rightLoyalitySelectedValue = event.value;
      this.rightNpsSelectedValue = 'All';

      this.canRender = false;
      this.disbleNps = true;
      this.widgetService.isAllLoaded = false;
      this.widgetService
        .getCustomerJourney('rightLoyality', event.value, { apiConfig: this.apiConfig, extra: this.extra })
        .subscribe(
          (res: any) => {
            if (res) {
              this.right = res.RightSection;
              this.widgetService.isAllLoaded = true;
              this.canRender = true;
            }
          },
          (error: any) => {
            this.canRender = false;
            this.widgetService.isAllLoaded = true;
          }
        );
    }
  }
  toFixed(n: number) {
    if (n && n > 0) {
      return n.toFixed(1);
    }
    return 0;
  }
  show(kpiName: string, dataval: any) {
    let loyality = '';
    let nps = '';
    if (dataval === 'left') {
      loyality = this.leftLoyalitySelectedValue;
      nps = this.leftNpsSelectedValue;
    } else {
      loyality = this.rightLoyalitySelectedValue;
      nps = this.rightNpsSelectedValue;
    }
    const ref = this.dialogService.open(CommentListModalComponent, {
      data: {
        loyality: loyality,
        Nps: nps,
        kpiName: kpiName,
        message:
          status === 'Approved'
            ? this.translateService.instant('appeal_confirm_approve_message')
            : this.translateService.instant('appeal_confirm_deny_message'),
      },
      showHeader: true,
      closable: false,
      styleClass: 'ui-dialog--medium ui-dialog--fullwidthcontent',
    });
    ref.onClose.subscribe((result) => {
      // console.log(result);
    });
  }

  GetSurveyKpiNameTranslation(key: string) {
    return this.translationHelper.GetSurveyKpiNameCJTranslation(key);
  }

  GetCustomerJourneyLoyalitiesTranslation(key: string): string {
    return this.translationHelper.GetCustomerJourneyLoyalitiesTranslation(key);
  }

  GetCustomerJourneyNPSTranslation(key: string): string {
    return this.translationHelper.GetCustomerJourneyNPSTranslation(key);
  }
}
