import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'gpflistitem',
})
export class GPListItemPipe implements PipeTransform {
  constructor(public translateService: TranslateService) {}
  transform(values: any[], config: any): any[] {
    if (!values) {
      return [];
    }
    var ctrl = config.name;
    if (Array.isArray(values)) {
      values = values
        .map((item: any) => {
          if (item) {
            if (ctrl === 'language' || ctrl === 'dealer') {
              item.label = this.translateService.instant(item?.label);
            } else {
              item.label = item?.label;
            }
            return item;
          }
        })
        .filter(Boolean);
    }
    if (config.settings.sort === 'checked' && Array.isArray(values)) {
      const v = values.sort((a: any, b: any) => {
        return Number(a.IsChecked) - Number(b.IsChecked) || b.label - a.label;
      });
      return v.reverse();
    } else if (config.settings.sort === 'desc' && Array.isArray(values)) {
      return values.sort().reverse();
    } else if (config.settings.sort === 'asc' && Array.isArray(values)) {
      return values.sort();
    } else {
      if (Array.isArray(values)) {
        return values;
      }
      return [];
    }
  }
}
