<ng-container *ngIf="from === 'list'">
  <li [attr.class]="config?.rootClass" (click)="onclickevent($event)">
    <ng-container *ngTemplateOutlet="listtemplate; context: ctx"></ng-container>
  </li>
</ng-container>
<ng-container *ngIf="from === 'tree'">
  <ng-container *ngTemplateOutlet="listtemplate; context: ctx"> </ng-container>
</ng-container>
<ng-template #listtemplate let-config="config" let-active="active">
  <div *ngIf="active && from === 'tree' && config?.settings?.showTitle">
    <h4 class="filter__list-heading" translate>{{ title | translate }}</h4>
    <div *ngIf="config?.settings?.multi" class="filter__list-topbar">
      <span class="select-deselect" *ngIf="!getSelectionState()" (click)="selectOrDeSelectAll($event, false)">
        <a> {{ 'Deselect All' | translate }} ({{ values?.length }})</a>
      </span>
      <span class="select-deselect" *ngIf="getSelectionState()" (click)="selectOrDeSelectAll($event, true)">
        <a> {{ 'Select All' | translate }} ({{ values?.length }})</a>
      </span>
    </div>
  </div>
  <label *ngIf="from === 'list'" class="filter__label">List Filter</label>
  <div class="filter-field filter--noicon">
    <div class="filter__trigger">
      <ng-container *ngIf="active">
        <i class="fal fa-search"></i>
        <input
          type="text"
          class="field__label"
          autocomplete="off"
          [(ngModel)]="search"
          placeholder="{{ 'Search...' | translate }}"
          name="search"
        />
      </ng-container>
      <ng-container *ngIf="!active">
        <i *ngIf="config?.icons?.leftIcon" [attr.class]="config?.icons?.leftIcon"></i>
        <span class="field__label">{{ getTitle() }}</span>
        <div class="field__icons">
          <span class="icons__active"><i class="fal fa-pencil"></i></span>
          <span class="icons__active icon--ok">
            <i class="fal fa-check"></i>
          </span>
          <i class="far fa-angle-down"></i>
        </div>
      </ng-container>
      <div class="field__icons">
        <span
          *ngIf="active && search && search.length > 0"
          [ngStyle]="{ 'margin-right': from === 'list' ? '10px' : '0px' }"
        >
        <i class="fal fa-backspace" (click)="clearSearch($event)"></i>
        </span>
        <span *ngIf="active && from === 'list'">
          <i class="fal fa-times" (click)="close($event)"></i>
        </span>
      </div>
    </div>
    <div [ngClass]="{ 'filter-dropdown dropdown--list': from === 'list' }" [class.active]="active">
      <div class="tree-breadcrumbs" *ngIf="config?.settings?.stright">
        <ul class="breadcrumbs_list">
          <li (click)="breadcrumClick(-1, {})">
            <a>{{ gbfService?.gbfConfig?.filterConfig?.initialStates[config?.name]?.label | translate }}</a>
          </li>
          <li *ngFor="let breadcrum of breadcrums; let i = index" (click)="breadcrumClick(i, breadcrum)">
            <a>{{ breadcrum.label | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="filter__list" [attr.class]="listRootClassName">
        <div
          class="filter__list-topbar"
          *ngIf="from === 'list' && config?.settings?.showTitle && config?.settings?.multi"
        >
          <span>{{ getSelectionCount() }}</span>
          <a>
            <span class="select-deselect" *ngIf="!getSelectionState()" (click)="selectOrDeSelectAll($event, false)">{{
              'Deselect All' | translate
            }}</span>
            <span class="select-deselect" *ngIf="getSelectionState()" (click)="selectOrDeSelectAll($event, true)">{{
              'Select All' | translate
            }}</span>
          </a>
        </div>
        <ul
          #ulscroll
          [style]="{ 'max-height': values.length > 4 ? '200px' : 'auto', width: '100%', 'overflow-x': 'hidden' }"
        >
          <li *ngIf="values.length == 0" class="no--child text-center">
            <a (click)="itemClicked($event, values[0], '__ALL__'); $event.stopPropagation()">{{
              'no option found' | translate
            }}</a>
          </li>
          <li
            *ngIf="config?.settings?.allSelection === true"
            (click)="itemClicked($event, values[0], '__ALL__'); $event.stopPropagation()"
            class="no--child"
          >
            <a (click)="itemClicked($event, values[0], '__ALL__'); $event.stopPropagation()"> All </a>
          </li>
          <cdk-virtual-scroll-viewport itemSize="50" class="example-viewport">
              <ng-container *ngFor="let value of values | gpflistitem: config | gpfilter: search:'label' | gbfSort: config; let ii = index">
              <li
                *ngIf="value && !value.hide"
                [class.no--child]="from === 'tree' && !value?.hasChild"
                [class.has-child]="from === 'tree' && value?.hasChild"
                [class.active]="value.active"
                [class.disabled]="value.canNotBeSelected"
                [ngStyle]="{ 'pointer-events': value.disabled ? 'none' : 'auto' }"
                (click)="itemClicked($event, value, 'list'); $event.stopPropagation()">
                <a
                  [ngStyle]="{ 'pointer-events': value.disabled ? 'none' : 'auto' }"
                  (click)="itemClicked($event, value, 'anchor'); $event.stopPropagation()">
                  <!-- <label class="checkbox-top-label" *ngIf="value?.parentName && config?.settings?.multi">{{
                    value?.parentName | translate
                  }}</label> -->
                  <!-- <p-checkbox
                    (click)="itemClicked($event, value, 'crl'); $event.stopPropagation()"
                    *ngIf="config?.settings?.multi"
                    [disabled]="value.disabled"
                    [ngStyle]="{ 'pointer-events': value.disabled ? 'none' : 'auto' }"
                    (click)="itemClicked($event, value, 'anchor'); $event.stopPropagation()"
                  > </p-checkbox> -->
                    <label class="checkbox-top-label" *ngIf="value?.parentName && config?.settings?.multi && config?.mergeChilds!=true">{{
                      value?.parentName | translate
                    }}</label>
                    <div *ngIf="config?.settings?.multi" [ngStyle]="{'display': 'flex', 'align-items': 'center'}">
                      <input 
                        style="min-width: 15px; width: 15px;"
                        [(ngModel)]="value.IsChecked" 
                        type="checkbox" 
                        (click)="
                        value.IsChecked = !value.IsChecked; 
                        itemClicked($event, value, 'crl'); 
                        $event.stopPropagation()" 
                      />
                      <label
                        (click)="
                        value.IsChecked = !value.IsChecked; 
                        itemClicked($event, value, 'crl'); 
                        $event.stopPropagation()" 
                        >
                        {{ value?.label | translate}}
                      </label>
                    </div>
                    <!-- <p-checkbox
                      (click)="itemClicked($event, value, 'crl'); $event.stopPropagation()"
                      *ngIf="config?.settings?.multi"
                      [disabled]="value.disabled"
                      [ngStyle]="{ 'pointer-events': value.disabled ? 'none' : 'auto' }"
                      [styleClass]="value.IsPartialChecked ? 'secondary-checkbox' : ''"
                      [(ngModel)]="value.IsChecked"
                      [label]="value.label"
                      binary="true"
                    >
                    </p-checkbox> -->
                    <ng-container *ngIf="!config?.settings?.multi && config?.settings?.listStyleType === 'radio'">
                      <input
                        type="radio"
                        style="width: inherit"
                        [disabled]="value.disabled"
                        [ngStyle]="{ 'pointer-events': value.disabled ? 'none' : 'auto' }"
                        (click)="itemClicked($event, value, 'crl'); $event.stopPropagation()"
                        [name]="config?.name + '_' + value.level + '_' + pindex + '_' + ii"
                        [checked]="value.IsChecked"
                        [value]="value.IsChecked ? false : true"
                      />
                      <label
                        [ngStyle]="{ 'pointer-events': value.disabled ? 'none' : 'auto' }"
                        (click)="itemClicked($event, value, 'crl'); $event.stopPropagation()"
                        [for]="config?.name + '_' + value.level + '_' + pindex + '_' + ii"
                        >{{ value.label | translate }}</label
                      >
                    </ng-container>
                    <ng-container *ngIf="!config?.settings?.multi && config?.settings?.listStyleType === 'none'">
                      <label
                        [ngStyle]="{ 'pointer-events': value.disabled ? 'none' : 'auto' }"
                        (click)="itemClicked($event, value, 'crl'); $event.stopPropagation()"
                        [for]="value.value"
                        >{{ value.label | translate }}</label
                      >
                    </ng-container>
                  </a>
                </li>
              </ng-container>
          </cdk-virtual-scroll-viewport>
          <!-- </cdk-virtual-scroll-viewport> -->
        </ul>
      </div>
      <div class="dropdown__footer" *ngIf="config?.settings?.listDoneButton && from === 'list'">
        <a class="btn" (click)="done($event); $event.stopPropagation()" translate>Done</a>
      </div>
    </div>
  </div>
</ng-template>
