<div class="ui-radiobutton ui-widget" (click)="onClick($event)">
  <div class="ui-helper-hidden-accessible">
    <input type="radio" [checked]="checked" (focus)="onFocus()" (blur)="onBlur()" [disabled]="disabled" />
  </div>
  <div
    #box
    [ngClass]="{'ui-radiobutton-box ui-widget ui-state-default':true,
        'ui-state-active':checked, 'ui-state-disabled':disabled}"
  >
    <span class="ui-radiobutton-icon ui-clickable" [ngClass]="{'pi pi-circle-on':checked}"></span>
  </div>
</div>
