import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModeService {
  mode = false;

  private mode_: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  public getMode() {
    return this.mode;
  }

  public setMode(mode: boolean) {
    this.mode = mode;
    this.mode_.next(mode);
  }

  public getModeObservable() {
    return this.mode_.asObservable();
  }
}
