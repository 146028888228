import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { ExportService } from '@clearviewshared/services/export.service';
import { MenuItem, SelectItem } from 'primeng/api';
import { I18nService, AuthenticationService } from '@app/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { ModeService } from '@app/shared/services/mode.service';
import { User } from '@reflecxfeatures/administration/types/User';
import { Subject, merge, Subscription } from 'rxjs';
import { HeaderTabService } from '@app/shared/services/header-tab.service';
import { DialogService } from '@primeng';
import { CustomizeDashboardComponent } from '../customize-dashboard/customize-dashboard.component';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { Notification } from '@app/core/types/notifications';
import { RealTimeNotificationService } from '@app/shared/services/realTimeNotification/real-time-notification.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
// import { RealTimeNotificationComponent } from
// '@reflecxshared/components/real-time-notification/real-time-notification.component';
import { AccountSettingComponent } from '@app/shared/components/account-setting/account-setting.component';
// import { NotificationsComponent } from '@reflecxshared/components/notifications/notifications.component';
import { SignalrService } from '@app/core/signalr.service';
import { environment } from '@env/environment';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { FiltersService } from '@app/shared/services/filters.service';
import { NotificationsComponent } from '@app/shared/components/notifications/notifications.component';
import { RealTimeNotificationComponent } from '@app/shared/components/real-time-notification/real-time-notification.component';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Identifiers } from '@app/shared/services/app.config.type';
import { AuthServiceCleaner } from '@app/shared/services/auth.service.cleaner';
import { LoaderService } from '@app/core/loader.service';
import { SidebarService } from '@app/shared/services/sidebar.service';
import { PrintService } from '@app/shared/services/print.service';
import { AppConfig } from 'src/global.config';
import { Permission } from '@clearviewshared/constant/permission';

// import { MatDialog } from '@angular/material';

interface Language {
  DisplayName: string;
  Code: string;
}

@Component({
  selector: 'appc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('headerTabs', { static: true }) headerTabs: any;
  menuHidden = true;
  displaymenu = false;
  module: string;
  timeZoneCode: string;
  languageKey = 'language';
  notificationToDisplay = 3;
  notificationsCount = 0;
  notificationOrderBy = 'date';
  notifications: Notification[] = [];
  arrLanguages: any = [];
  // notificationCount = 0; -- it is replaced by notificationCount from signalR service
  displayName: any;
  defaultLanguage: any;
  user: any;
  markets: any;
  // notificationMenu = false;
  authMenu = false;
  dialoagRef: any;

  items: MenuItem[];
  headeritems: MenuItem[];
  mode = false;
  languages_dropdown: Language[];
  selectedLanguage: Language;
  showPrint = true;
  // items: MenuItem[];
  // languages_dropdown: Language[];
  // selectedLanguage: Language;
  EditMode = false;
  // mode: boolean;
  // user: User;
  // headeritems: MenuItem[];
  activeNotification = false;
  showAdminMode = true;
  image: any = '';
  logo = '';
  logoClass = '';
  notificationReload: boolean = false;
  subscription: Subscription;
  adminMode: string;
  permissions: NgxPermissionsObject;
  rgsubscription: Subscription;
  private activeAccount = false;
  public IconPermission: boolean = false;
  favicon: HTMLLinkElement = document.querySelector('#favicon');
  allCustomizeDashboard: Array<string> = ['Global Dashboard', 'Main Dashboard', 'VOC', 'Social Media', 'Hot Alerts'];
  defaultMenu: boolean = true;

  constructor(
    private router: Router,
    private headerTabService: HeaderTabService,
    private modeService: ModeService,
    private toastService: ToastService,
    private notificationService: NotificationsService,
    public realTimeNotificationService: RealTimeNotificationService,
    private signalRService: SignalrService,
    private dialogService: DialogService,
    private filterService: FiltersService,
    // public dialoag: MatDialog,
    public authCleaner: AuthServiceCleaner,
    private authService: AuthenticationService,
    private authenticationService: AuthenticationService,
    private i18nService: I18nService,
    private translateService: TranslateService,
    public printService: PrintService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private exportService: ExportService,
    public sidebarService: SidebarService,
    private loaderService: LoaderService,
    public ngxPermission: NgxPermissionsService
  ) {
    this.permissions = ngxPermission.getPermissions();
    this.signalRService.notificationReload.subscribe((notificationReload: boolean) => {
      if (notificationReload) {
        this.notificationReload = true;
      }
    });
  }

  ngOnInit() {
    this.IconPermission = this.permissions[Permission.DASHBOARD_Activity_Monitoring_Icon_Visible] ? true : false;
    this.timeZoneCode = this.filterService.timeZoneCode;
    this.subscription = this.exportService.getMessageFromGlobalComponent().subscribe((message: any) => {
      this.defaultMenu = false;
      if (message && message.text && message.text === 'menu-dashboard') {
        this.MenuItemInitializeForDashboard(true);
      } else if (message && message.text && message.text === 'menu-report') {
        this.MenuItemInitializeForReport();
      } else if (message && message.text && message.text === 'menu-no-print-export') {
        this.MenuItemInitializeForNoPrintAndExport();
      } else if (message && message.text && message.text === 'menu-export-only') {
        this.MenuItemInitializeForReportWithExportOnly();
      } else {
        this.MenuItemInitializeForReport();
      }
    });

    this.rgsubscription = this.exportService.gettRG().subscribe((message: any) => {
      this.ReportGeneratorMenuItems();
    });
    this.adminMode = this.translateService.instant('Admin Mode:');
    this.UserConfigurationInitialize();
    this.HeaderVanigationInitialize();

    if (this.defaultMenu) {
      this.MenuItemInitializeForReport();
    }
  }

  mobileSlideDown(e: any) {
    e.preventDefault();
    this.headerTabs.toggleShowDiv();
  }
  toggleSidebar(e: any): void {
    if (!this.sidebarService.isSideBarLocked()) {
      this.sidebarService.isSidebar = !this.sidebarService.isSidebar;
      this.sidebarService.isOpen = this.sidebarService.isSidebar;
    }
  }
  UserConfigurationInitialize() {
    if (!environment.Apps.ReflecxApp || !environment.Apps.ReportingApp) {
      this.showAdminMode = false;
    }
    this.user = this.authenticationService.getUser();
    this.languages_dropdown = [
      {
        DisplayName: 'English',
        Code: 'en-CA',
      },
      {
        DisplayName: 'French',
        Code: 'fr-CA',
      },
    ];
    const url = this.router.url;
    this.module = url.substr(url.lastIndexOf('/') + 1, url.length - url.lastIndexOf('/'));
    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
    this.markets = this.user.Markets || [];
    const arr = [];
    for (let i = 0; i < this.markets.length; i++) {
      for (let j = 0; j < this.markets[i].Languages.length; j++) {
        this.markets[i].Languages[j].MarketId = this.markets[i].Id;
        if (this.user.DefaultLanguage === this.markets[i].Languages[j].Code) {
          this.defaultLanguage = this.markets[i].Languages[j];
          this.defaultLanguage.MarketId = this.markets[i].Id;
        }
        this.arrLanguages.push(this.markets[i].Languages[j]);
      }
      this.markets[i].Languages.sort((a: any, b: any) => {
        if (a.DisplayName > b.DisplayName) {
          return 1;
        }
        if (a.DisplayName < b.DisplayName) {
          return -1;
        }
        return 0;
      });
    }
    const language = localStorage.getItem(this.languageKey);
    if (language != null) {
      const currentLanguage = this.markets[0].Languages.find((x: { Code: string }) => x.Code === language);
      if (currentLanguage !== undefined) {
        this.defaultLanguage = currentLanguage;
      }
    }
    this.authService.image.subscribe((image: string) => (this.image = image));
    if (this.user.ProfilePic) {
      if (this.user.ProfilePic != null || this.user.ProfilePic !== '') {
        this.image = this.user.ProfilePic;
      }
    }
    const logo = this.authService.getClientLogo();
    const fabicon = this.authService.getClientFabIcon();
    let random = Math.random();
    if (fabicon && this.favicon) {
      this.favicon.href = `${environment.apiUrl}${fabicon}?v=${random}`;
    } else {
      this.favicon.href = `favicon.ico?v=${random}`;
    }

    this.logo = `${environment.apiUrl}${logo}?v=${random}`;
    if (this.logo) {
      this.logoClass = logo.split('/')[2];
    }
  }

  ReportGeneratorMenuItems() {
    this.items = [];
    if (this.permissions[Identifiers.AccountSettings] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Account Setting'),
        icon: 'fal fa-cog',
        command: () => {
          this.showModal('table');
        },
      });
    }
    const InsightAliasReport = this.permissions[Identifiers.InsightAliasReport] ? true : false;
    if (InsightAliasReport) {
      this.items.push({
        label: this.translateService.instant('Alias Module'),
        icon: 'fal fa-street-view',
        command: () => {
          this.router.navigate([`/${environment.reportingRoutePrefix}/reports/aliasreport`]);
        },
      });
    }
    if (this.permissions[Identifiers.PortalTranslation] ? true : false) {
      this.items.push({
        label: this.translateService.instant('menu_portal_translation'),
        icon: 'fal fas fa-language',
        command: () => {
          this.onPortalTranslation();
        },
      });
    }
    const authData = this.authService.getUser();
    if (!authData.IsSSO) {
      this.items.push({
        label: this.translateService.instant('Logout'),
        icon: 'fal fa-power-off',
        command: () => {
          this.onLogout();
        },
      });
    } else {
      if (this.permissions[Identifiers.AllowSSOLogout] ? true : false) {
        this.items.push({
          label: this.translateService.instant('Logout'),
          icon: 'fal fa-power-off',
          command: () => {
            this.onLogout();
          },
        });
      }
    }
  }

  MenuItemInitializeForDashboard(hasCustomizedDashboard: boolean = false) {
    this.items = [];

    if (this.permissions[Identifiers.AccountSettings] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Account Setting'),
        icon: 'fal fa-cog',
        command: () => {
          this.showModal('table');
        },
      });
    }
    let customizeDashboard = this.permissions[Identifiers.CustomizeDashboard] ? true : false;
    if (customizeDashboard && this.sidebarService.isCustomizeDashboard) {
      this.items.push({
        label: this.translateService.instant('Customize Dashboard'),
        icon: 'fal fa-arrows',
        command: () => {
          this.onCustomizeDashboard();
        },
      });
    }

    const InsightAliasReport = this.permissions[Identifiers.InsightAliasReport] ? true : false;
    if (InsightAliasReport) {
      this.items.push({
        label: this.translateService.instant('Alias Module'),
        icon: 'fal fa-street-view',
        command: () => {
          this.router.navigate([`/${environment.reportingRoutePrefix}/reports/aliasreport`]);
        },
      });
    }

    if (this.permissions[Identifiers.PortalTranslation] ? true : false) {
      this.items.push({
        label: this.translateService.instant('menu_portal_translation'),
        icon: 'fal fas fa-language',
        command: () => {
          this.onPortalTranslation();
        },
      });
    }

    if (environment.isPrintAllowed) {
      if ((this.permissions[Identifiers.PrintDashboard] ? true : false) && hasCustomizedDashboard) {
        this.items.push({
          label: this.translateService.instant('print.label'),
          icon: 'fal fa-print',
          command: (e: any) => {
            this.printService.beforePrint.next('beforePrint');
            if (!this.printService.shouldWait) {
              document.body.click();
              if (e.preventDefault) {
                e.preventDefault();
              }
              setTimeout(() => {
                const content = document.getElementsByTagName('html')[0].outerHTML;
                this.printService.printPdf(content);
              }, 500);
            }
          },
        });
      }
    }

    if (this.permissions[Identifiers.TrainingVideos] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Training Videos'),
        icon: 'fal fa-play',
        command: () => {
          this.onTrainingVideos();
        },
      });
    }

    // NotificationTemplate and Resource Permission needs to be added in db
    if (this.permissions[Identifiers.NotificationTemplate] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Notification Template'),
        icon: 'fal fa-file-alt',
        command: () => {
          this.onNotificationTemplate();
        },
      });
    }

    if (this.permissions[Identifiers.Resources] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Resources'),
        icon: 'fal fa-file-pdf',
        command: () => {
          this.onResources();
        },
      });
    }

    if (this.permissions[Identifiers.Help] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Help'),
        icon: 'fa fa-info-circle',
        command: () => {
          this.onHelp();
        },
      });
    }

    if (this.permissions[Identifiers.Helpdesk] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Help Desk'),
        icon: 'fa fa-info-circle',
        command: () => {
          this.onHelpDesk();
        },
      });
    }

    const authData = this.authService.getUser();
    if (!authData.IsSSO) {
      this.items.push({
        label: this.translateService.instant('Logout'),
        icon: 'fal fa-power-off',
        command: () => {
          this.onLogout();
        },
      });
    } else {
      if (this.permissions[Identifiers.AllowSSOLogout] ? true : false) {
        this.items.push({
          label: this.translateService.instant('Logout'),
          icon: 'fal fa-power-off',
          command: () => {
            this.onLogout();
          },
        });
      }
    }
  }

  MenuItemInitializeForReport() {
    this.items = [];

    if (this.permissions[Identifiers.AccountSettings] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Account Setting'),
        icon: 'fal fa-cog',
        command: () => {
          this.showModal('table');
        },
      });
    }
    // let customizeDashboard = this.permissions[Identifiers.CustomizeDashboard] ? true : false;
    // if (customizeDashboard && this.sidebarService.isCustomizeDashboard) {
    //   this.items.push({
    //     label: this.translateService.instant('Customize Dashboard'),
    //     icon: 'fal fa-arrows',
    //     command: () => {
    //       this.onCustomizeDashboard();
    //     },
    //   });
    // }

    if (this.permissions[Identifiers.PortalTranslation] ? true : false) {
      this.items.push({
        label: this.translateService.instant('menu_portal_translation'),
        icon: 'fal fas fa-language',
        command: () => {
          this.onPortalTranslation();
        },
      });
    }

    if (this.permissions[Identifiers.ExportDashboard] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Export'),
        icon: 'fal fa-question-circle',
        //         command: () => {
        //           let printContents, popupWin: any;
        //           printContents = document.getElementById('print-section').innerHTML;
        //           popupWin = window.open(
        //             '',
        //             '_blank', 'top=0,left=0,height=100%,width=auto'
        //           );
        //           popupWin.document.open();
        //           popupWin.document.write
        // // tslint:disable-next-line: max-line-length
        //           ('<html><head><link rel="stylesheet" type="text/css" href="assets/styles/portal/styles/print.css" /></head><body onload="window.print()">'
        //            + printContents + '</html>');
        //          popupWin.document.close();
        //           popupWin.focus();
        //         }
        command: () => {
          this.export().then((x) => {});
          // let printContents, popupWin: any;
          // printContents = document.getElementsByClassName('main')[0].innerHTML;
          // popupWin = window.open(
          //   '',
          //   '_blank',
          //   'top=0,left=0,height=100%,width=auto'
          // );
          // popupWin.document.open();
          // popupWin.document.write('<html><head><title></title>');
          // popupWin.document.write('<link rel="stylesheet" href="/assets/styles/portal/build/assets/styles/print.css" type="text/css" media="all"/>');
          // popupWin.document.write('</head><body >');
          // popupWin.document.write(printContents);
          // popupWin.document.write('</body></html>');
          // popupWin.document.close();
          // popupWin.focus();
          // setTimeout(function () { popupWin.print(); }, 1000);
          // popupWin.document.write(`
          //   <html>
          //     <head>
          //       <title>Print tab</title>
          //       <script
          //         href="assets/print.js"
          //       ></script>
          //     </head>
          //     <body onload="">${printContents}</body>
          //   </html>`);
        },
      });
    }
    if (this.permissions[Identifiers.ExportDashboard] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Export All'),
        icon: 'fal fa-question-circle',
        command: () => {
          this.exportAll().then((x) => {});
        },
      });
    }
    const InsightAliasReport = this.permissions[Identifiers.InsightAliasReport] ? true : false;
    if (InsightAliasReport) {
      this.items.push({
        label: this.translateService.instant('Alias Module'),
        icon: 'fal fa-street-view',
        command: () => {
          this.router.navigate([`/${environment.reportingRoutePrefix}/reports/aliasreport`]);
        },
      });
    }
    if (this.permissions[Identifiers.TrainingVideos] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Training Videos'),
        icon: 'fal fa-play',
        command: () => {
          this.onTrainingVideos();
        },
      });
    }

    // NotificationTemplate and Resource Permission needs to be added in db
    if (this.permissions[Identifiers.NotificationTemplate] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Notification Template'),
        icon: 'fal fa-file-alt',
        command: () => {
          this.onNotificationTemplate();
        },
      });
    }

    if (this.permissions[Identifiers.Resources] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Resources'),
        icon: 'fal fa-file-pdf',
        command: () => {
          this.onResources();
        },
      });
    }

    if (this.permissions[Identifiers.Help] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Help'),
        icon: 'fa fa-info-circle',
        command: () => {
          this.onHelp();
        },
      });
    }
    if (this.permissions[Identifiers.Helpdesk] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Help Desk'),
        icon: 'fa fa-info-circle',
        command: () => {
          this.onHelpDesk();
        },
      });
    }

    // if ((this.permissions[Identifiers.PrintDashboard] ? true : false) && environment.isPrintAllowed) {
    //   this.items.push({
    //     label: this.translateService.instant('print.label'),
    //     icon: 'fal fa-print',
    //     command: (e: any) => {
    //       document.body.click();
    //       if (e.preventDefault) {
    //         e.preventDefault();
    //       }
    //       setTimeout(() => {
    //         const content = document.getElementsByTagName('html')[0].outerHTML;
    //         this.printService.printPdf(content);
    //       }, 500);
    //     },
    //   });
    // }

    const authData = this.authService.getUser();
    if (!authData.IsSSO) {
      this.items.push({
        label: this.translateService.instant('Logout'),
        icon: 'fal fa-power-off',
        command: () => {
          this.onLogout();
        },
      });
    } else {
      if (this.permissions[Identifiers.AllowSSOLogout] ? true : false) {
        this.items.push({
          label: this.translateService.instant('Logout'),
          icon: 'fal fa-power-off',
          command: () => {
            this.onLogout();
          },
        });
      }
    }
  }

  MenuItemInitializeForReportWithExportOnly() {
    this.items = [];

    if (this.permissions[Identifiers.AccountSettings] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Account Setting'),
        icon: 'fal fa-cog',
        command: () => {
          this.showModal('table');
        },
      });
    }
    // let customizeDashboard = this.permissions[Identifiers.CustomizeDashboard] ? true : false;
    // if (customizeDashboard && this.sidebarService.isCustomizeDashboard) {
    //   this.items.push({
    //     label: this.translateService.instant('Customize Dashboard'),
    //     icon: 'fal fa-arrows',
    //     command: () => {
    //       this.onCustomizeDashboard();
    //     },
    //   });
    // }
    const InsightAliasReport = this.permissions[Identifiers.InsightAliasReport] ? true : false;
    if (InsightAliasReport) {
      this.items.push({
        label: this.translateService.instant('Alias Module'),
        icon: 'fal fa-street-view',
        command: () => {
          this.router.navigate([`/${environment.reportingRoutePrefix}/reports/aliasreport`]);
        },
      });
    }

    if (this.permissions[Identifiers.PortalTranslation] ? true : false) {
      this.items.push({
        label: this.translateService.instant('menu_portal_translation'),
        icon: 'fal fas fa-language',
        command: () => {
          this.onPortalTranslation();
        },
      });
    }

    if (this.permissions[Identifiers.ExportDashboard] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Export'),
        icon: 'fal fa-question-circle',
        //         command: () => {
        //           let printContents, popupWin: any;
        //           printContents = document.getElementById('print-section').innerHTML;
        //           popupWin = window.open(
        //             '',
        //             '_blank', 'top=0,left=0,height=100%,width=auto'
        //           );
        //           popupWin.document.open();
        //           popupWin.document.write
        // // tslint:disable-next-line: max-line-length
        //           ('<html><head><link rel="stylesheet" type="text/css" href="assets/styles/portal/styles/print.css" /></head><body onload="window.print()">'
        //            + printContents + '</html>');
        //          popupWin.document.close();
        //           popupWin.focus();
        //         }
        command: () => {
          this.export().then((x) => {});
          // let printContents, popupWin: any;
          // printContents = document.getElementsByClassName('main')[0].innerHTML;
          // popupWin = window.open(
          //   '',
          //   '_blank',
          //   'top=0,left=0,height=100%,width=auto'
          // );
          // popupWin.document.open();
          // popupWin.document.write('<html><head><title></title>');
          // popupWin.document.write('<link rel="stylesheet" href="/assets/styles/portal/build/assets/styles/print.css" type="text/css" media="all"/>');
          // popupWin.document.write('</head><body >');
          // popupWin.document.write(printContents);
          // popupWin.document.write('</body></html>');
          // popupWin.document.close();
          // popupWin.focus();
          // setTimeout(function () { popupWin.print(); }, 1000);
          // popupWin.document.write(`
          //   <html>
          //     <head>
          //       <title>Print tab</title>
          //       <script
          //         href="assets/print.js"
          //       ></script>
          //     </head>
          //     <body onload="">${printContents}</body>
          //   </html>`);
        },
      });
    }
    // if (this.permissions[Identifiers.ExportDashboard] ? true : false) {
    //   this.items.push({
    //     label: this.translateService.instant('Export All'),
    //     icon: 'fal fa-question-circle',
    //     command: () => {
    //       this.exportAll().then((x) => { });
    //     },
    //   });
    // }

    if (this.permissions[Identifiers.TrainingVideos] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Training Videos'),
        icon: 'fal fa-play',
        command: () => {
          this.onTrainingVideos();
        },
      });
    }

    // NotificationTemplate and Resource Permission needs to be added in db
    if (this.permissions[Identifiers.NotificationTemplate] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Notification Template'),
        icon: 'fal fa-file-alt',
        command: () => {
          this.onNotificationTemplate();
        },
      });
    }

    if (this.permissions[Identifiers.Resources] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Resources'),
        icon: 'fal fa-file-pdf',
        command: () => {
          this.onResources();
        },
      });
    }

    if (this.permissions[Identifiers.Help] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Help'),
        icon: 'fa fa-info-circle',
        command: () => {
          this.onHelp();
        },
      });
    }
    if (this.permissions[Identifiers.Helpdesk] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Help Desk'),
        icon: 'fa fa-info-circle',
        command: () => {
          this.onHelpDesk();
        },
      });
    }

    // if ((this.permissions[Identifiers.PrintDashboard] ? true : false) && environment.isPrintAllowed) {
    //   this.items.push({
    //     label: this.translateService.instant('print.label'),
    //     icon: 'fal fa-print',
    //     command: (e: any) => {
    //       document.body.click();
    //       if (e.preventDefault) {
    //         e.preventDefault();
    //       }
    //       setTimeout(() => {
    //         const content = document.getElementsByTagName('html')[0].outerHTML;
    //         this.printService.printPdf(content);
    //       }, 500);
    //     },
    //   });
    // }

    const authData = this.authService.getUser();
    if (!authData.IsSSO) {
      this.items.push({
        label: this.translateService.instant('Logout'),
        icon: 'fal fa-power-off',
        command: () => {
          this.onLogout();
        },
      });
    } else {
      if (this.permissions[Identifiers.AllowSSOLogout] ? true : false) {
        this.items.push({
          label: this.translateService.instant('Logout'),
          icon: 'fal fa-power-off',
          command: () => {
            this.onLogout();
          },
        });
      }
    }
  }

  MenuItemInitializeForNoPrintAndExport() {
    this.items = [];

    if (this.permissions[Identifiers.AccountSettings] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Account Setting'),
        icon: 'fal fa-cog',
        command: () => {
          this.showModal('table');
        },
      });
    }
    // let customizeDashboard = this.permissions[Identifiers.CustomizeDashboard] ? true : false;
    // if (customizeDashboard && this.sidebarService.isCustomizeDashboard) {
    //   this.items.push({
    //     label: this.translateService.instant('Customize Dashboard'),
    //     icon: 'fal fa-arrows',
    //     command: () => {
    //       this.onCustomizeDashboard();
    //     },
    //   });
    // }
    const InsightAliasReport = this.permissions[Identifiers.InsightAliasReport] ? true : false;
    if (InsightAliasReport) {
      this.items.push({
        label: this.translateService.instant('Alias Module'),
        icon: 'fal fa-street-view',
        command: () => {
          this.router.navigate([`/${environment.reportingRoutePrefix}/reports/aliasreport`]);
        },
      });
    }
    if (this.permissions[Identifiers.PortalTranslation] ? true : false) {
      this.items.push({
        label: this.translateService.instant('menu_portal_translation'),
        icon: 'fal fas fa-language',
        command: () => {
          this.onPortalTranslation();
        },
      });
    }

    if (this.permissions[Identifiers.TrainingVideos] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Training Videos'),
        icon: 'fal fa-play',
        command: () => {
          this.onTrainingVideos();
        },
      });
    }

    // NotificationTemplate and Resource Permission needs to be added in db
    if (this.permissions[Identifiers.NotificationTemplate] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Notification Template'),
        icon: 'fal fa-file-alt',
        command: () => {
          this.onNotificationTemplate();
        },
      });
    }

    if (this.permissions[Identifiers.Resources] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Resources'),
        icon: 'fal fa-file-pdf',
        command: () => {
          this.onResources();
        },
      });
    }

    if (this.permissions[Identifiers.Help] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Help'),
        icon: 'fa fa-info-circle',
        command: () => {
          this.onHelp();
        },
      });
    }
    if (this.permissions[Identifiers.Helpdesk] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Help Desk'),
        icon: 'fa fa-info-circle',
        command: () => {
          this.onHelpDesk();
        },
      });
    }

    const authData = this.authService.getUser();
    if (!authData.IsSSO) {
      this.items.push({
        label: this.translateService.instant('Logout'),
        icon: 'fal fa-power-off',
        command: () => {
          this.onLogout();
        },
      });
    } else {
      if (this.permissions[Identifiers.AllowSSOLogout] ? true : false) {
        this.items.push({
          label: this.translateService.instant('Logout'),
          icon: 'fal fa-power-off',
          command: () => {
            this.onLogout();
          },
        });
      }
    }
  }

  ExMenuItemInitializeHiddenExportAll() {
    this.items = [];

    if (this.permissions[Identifiers.AccountSettings] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Account Setting'),
        icon: 'fal fa-cog',
        command: () => {
          this.showModal('table');
        },
      });
    }
    let customizeDashboard = this.permissions[Identifiers.CustomizeDashboard] ? true : false;
    if (customizeDashboard && this.sidebarService.isCustomizeDashboard) {
      this.items.push({
        label: this.translateService.instant('Customize Dashboard 909'),
        icon: 'fal fa-arrows',
        command: () => {
          this.onCustomizeDashboard();
        },
      });
    }
    const InsightAliasReport = this.permissions[Identifiers.InsightAliasReport] ? true : false;
    if (InsightAliasReport) {
      this.items.push({
        label: this.translateService.instant('Alias Module'),
        icon: 'fal fa-street-view',
        command: () => {
          this.router.navigate([`/${environment.reportingRoutePrefix}/reports/aliasreport`]);
        },
      });
    }
    if (this.permissions[Identifiers.PortalTranslation] ? true : false) {
      this.items.push({
        label: this.translateService.instant('menu_portal_translation'),
        icon: 'fal fas fa-language',
        command: () => {
          this.onPortalTranslation();
        },
      });
    }

    if (this.permissions[Identifiers.ExportDashboard] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Print / Export'),
        icon: 'fal fa-question-circle',
        //         command: () => {
        //           let printContents, popupWin: any;
        //           printContents = document.getElementById('print-section').innerHTML;
        //           popupWin = window.open(
        //             '',
        //             '_blank', 'top=0,left=0,height=100%,width=auto'
        //           );
        //           popupWin.document.open();
        //           popupWin.document.write
        // // tslint:disable-next-line: max-line-length
        //           ('<html><head><link rel="stylesheet" type="text/css" href="assets/styles/portal/styles/print.css" /></head><body onload="window.print()">'
        //            + printContents + '</html>');
        //          popupWin.document.close();
        //           popupWin.focus();
        //         }
        command: () => {
          this.export().then((x) => {});
          // let printContents, popupWin: any;
          // printContents = document.getElementsByClassName('main')[0].innerHTML;
          // popupWin = window.open(
          //   '',
          //   '_blank',
          //   'top=0,left=0,height=100%,width=auto'
          // );
          // popupWin.document.open();
          // popupWin.document.write('<html><head><title></title>');
          // popupWin.document.write('<link rel="stylesheet" href="/assets/styles/portal/build/assets/styles/print.css" type="text/css" media="all"/>');
          // popupWin.document.write('</head><body >');
          // popupWin.document.write(printContents);
          // popupWin.document.write('</body></html>');
          // popupWin.document.close();
          // popupWin.focus();
          // setTimeout(function () { popupWin.print(); }, 1000);
          // popupWin.document.write(`
          //   <html>
          //     <head>
          //       <title>Print tab</title>
          //       <script
          //         href="assets/print.js"
          //       ></script>
          //     </head>
          //     <body onload="">${printContents}</body>
          //   </html>`);
        },
      });
    }

    if (this.permissions[Identifiers.TrainingVideos] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Training Videos'),
        icon: 'fal fa-play',
        command: () => {
          this.onTrainingVideos();
        },
      });
    }

    // NotificationTemplate and Resource Permission needs to be added in db
    if (this.permissions[Identifiers.NotificationTemplate] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Notification Template'),
        icon: 'fal fa-file-alt',
        command: () => {
          this.onNotificationTemplate();
        },
      });
    }

    if (this.permissions[Identifiers.Resources] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Resources'),
        icon: 'fal fa-file-pdf',
        command: () => {
          this.onResources();
        },
      });
    }

    if (this.permissions[Identifiers.Help] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Help'),
        icon: 'fa fa-info-circle',
        command: () => {
          this.onHelp();
        },
      });
    }
    if (this.permissions[Identifiers.Helpdesk] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Help Desk'),
        icon: 'fa fa-info-circle',
        command: () => {
          this.onHelpDesk();
        },
      });
    }

    // { label: 'Admin Module', icon: 'fal fa-plus' },

    // ,
    //  },
    // ,
    // ,
    // ,

    const authData = this.authService.getUser();
    if (!authData.IsSSO) {
      this.items.push({
        label: this.translateService.instant('Logout'),
        icon: 'fal fa-power-off',
        command: () => {
          this.onLogout();
        },
      });
    } else {
      if (this.permissions[Identifiers.AllowSSOLogout] ? true : false) {
        this.items.push({
          label: this.translateService.instant('Logout'),
          icon: 'fal fa-power-off',
          command: () => {
            this.onLogout();
          },
        });
      }
    }
  }

  ExMenuItemInitializeForPrint() {
    this.items = [];

    if (this.permissions[Identifiers.AccountSettings] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Account Setting'),
        icon: 'fal fa-cog',
        command: () => {
          this.showModal('table');
        },
      });
    }
    if (this.permissions[Identifiers.CustomizeDashboard] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Customize Dashboard 1072'),
        icon: 'fal fa-arrows',
        command: () => {
          this.onCustomizeDashboard();
        },
      });
    }

    const InsightAliasReport = this.permissions[Identifiers.InsightAliasReport] ? true : false;
    if (InsightAliasReport) {
      this.items.push({
        label: this.translateService.instant('Alias Module'),
        icon: 'fal fa-street-view',
        command: () => {
          this.router.navigate([`/${environment.reportingRoutePrefix}/reports/aliasreport`]);
        },
      });
    }

    if (this.permissions[Identifiers.PortalTranslation] ? true : false) {
      this.items.push({
        label: this.translateService.instant('menu_portal_translation'),
        icon: 'fal fas fa-language',
        command: () => {
          this.onPortalTranslation();
        },
      });
    }

    if (this.permissions[Identifiers.TrainingVideos] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Training Videos'),
        icon: 'fal fa-play',
        command: () => {
          this.onTrainingVideos();
        },
      });
    }

    // NotificationTemplate and Resource Permission needs to be added in db
    if (this.permissions[Identifiers.NotificationTemplate] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Notification Template'),
        icon: 'fal fa-file-alt',
        command: () => {
          this.onNotificationTemplate();
        },
      });
    }

    if (this.permissions[Identifiers.Resources] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Resources'),
        icon: 'fal fa-file-pdf',
        command: () => {
          this.onResources();
        },
      });
    }

    if (this.permissions[Identifiers.Help] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Help'),
        icon: 'fa fa-info-circle',
        command: () => {
          this.onHelp();
        },
      });
    }

    if (this.permissions[Identifiers.Helpdesk] ? true : false) {
      this.items.push({
        label: this.translateService.instant('Help Desk'),
        icon: 'fa fa-info-circle',
        command: () => {
          this.onHelpDesk();
        },
      });
    }

    const authData = this.authService.getUser();
    if (!authData.IsSSO) {
      this.items.push({
        label: this.translateService.instant('Logout'),
        icon: 'fal fa-power-off',
        command: () => {
          this.onLogout();
        },
      });
    } else {
      if (this.permissions[Identifiers.AllowSSOLogout] ? true : false) {
        this.items.push({
          label: this.translateService.instant('Logout'),
          icon: 'fal fa-power-off',
          command: () => {
            this.onLogout();
          },
        });
      }
    }
  }

  HeaderVanigationInitialize() {
    this.headeritems = [
      {
        label: 'Global Dashboard',
        icon: 'fal fa-tachometer-alt',
      },
      {
        label: 'Global Dashboard',
        icon: 'fal fa-th-large',
      },
      {
        label: 'Hot Alerts',
        icon: 'fal fa-exclamation-circle',
      },
      {
        label: 'Reporting',
        icon: 'fal fa-list-alt',
      },
      {
        label: 'Report Generator',
        icon: 'fal fa-newspaper',
      },
      {
        label: 'Text Analytics',
        icon: 'fal fa-file-alt',
      },
      {
        label: 'Cross Tab',
        icon: 'fal fa-columns',
      },
      {
        label: 'Social Media',
        icon: 'fas fa-users',
      },
    ];
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.rgsubscription) {
      this.rgsubscription.unsubscribe();
    }
  }
  notificationMenu(event: Object) {
    if (event && event['value'] === true) {
      this.activeNotification = false;
    }
  }
  updateDefaultLanguage() {
    const userUpdate = Object.assign({}, this.user);
    userUpdate.DefaultLanguage = this.defaultLanguage.Code;
    userUpdate.MarketId = this.defaultLanguage.MarketId;
    this.authService.updateMarket(userUpdate).subscribe((resp) => {
      if (!resp.Status) {
        return;
      }
      this.user.DefaultLanguage = this.defaultLanguage.Code;
      this.authService.updateLanguage(this.defaultLanguage);
      this.hideModal('update');
      this.toastService.success('Language Updated!');
      localStorage.removeItem('translations');
      this.toastService.info('Reloading Designer!');
      setTimeout(() => {
        location.reload();
      }, 2000);
    });
  }

  showModal(type: any) {
    this.activeNotification = false;
    switch (type) {
      case 'notification':
        this.dialogService.open(NotificationsComponent, {
          data: {},
          styleClass: 'ui-dialog--medium ui-dialog--notifications ui-dialog--fixheight-medium',
          showHeader: true,
          closable: false,
        });
        break;
      case 'table':
        this.dialogService.open(AccountSettingComponent, {
          data: {},
          styleClass: 'ui-dialog--medium ui-dialog--account-settings',
          closable: false,
          showHeader: true,
        });
        break;
      case 'update':
        this.displayName = this.defaultLanguage.Code;
        // this.modalService.open('update-language');
        break;
      case 'real-time-notification':
        this.realTimeNotificationService.notificationCount = 0;
        this.dialogService.open(RealTimeNotificationComponent, {
          data: {},
          styleClass: 'ui-dialog--medium ui-dialog--notifications',
          closable: false,
          showHeader: true,
        });
        break;
    }
  }

  hideModal(type: any) {
    switch (type) {
      case 'update':
        this.dialoagRef.close();
        this.defaultLanguage = this.arrLanguages.find((x: any) => x.Code === this.user.DefaultLanguage);
        break;
    }
  }

  show(type: string, language: any) {
    switch (type) {
      case 'update':
        // tslint:disable-next-line:max-line-length
        this.toastService.confirmationDialog(
          `Are you sure you want to update lanugage to ${language.value.DisplayName}`,
          `Update Language`,
          () => {
            this.updateDefaultLanguage();
            this.setLanguage(language.value.Code);
          },
          () => {}
        );
        break;
    }
  }

  getNotificationsData() {
    this.notificationService.CountAsync().subscribe((count) => {
      this.notificationsCount = count;
      this.notificationService
        .getByCountAsync(this.notificationToDisplay, this.notificationOrderBy)
        .subscribe((res: Notification[]) => {
          this.notifications = res;
          //   this.notificationMenu = !this.notificationMenu;
          // this.authMenu = false;
        });
    });
  }

  onLogout() {
    this.loaderService.showMain = true;
    this.authCleaner.cleanDataAndLogout();
  }

  async export() {
    await this.exportService.sendMessage('Message from Home Component to App Component!');
  }
  async exportAll() {
    await this.exportService.sendMessageForExportAll('Export All');
  }
  onCustomizeDashboard() {
    if (this.allCustomizeDashboard.includes(this.headerTabService.activeItem)) {
      this.dialogService.open(CustomizeDashboardComponent, {
        data: {
          component: this.headerTabService.activeItem,
          customizeWidgetPayload: this.headerTabService.customizeWidgetPayload,
        },
        styleClass: 'ui-dialog--medium ui-dialog--customized-dashboard',
        closable: false,
        showHeader: true,
      });
    }
  }

  onPortalTranslation() {
    // this.authenticationService.logout();
    this.router.navigate([`/${environment.reportingRoutePrefix}/actionitems/portaltranslation`]);
  }

  toggleEdit() {
    this.EditMode = !this.EditMode;
    this.headerTabService.EditModeObservor.next(this.EditMode);
  }

  onNotificationTemplate() {
    this.router.navigate([`/${environment.reportingRoutePrefix}/actionitems/notificationtemplate`]);
  }

  onResources() {
    this.router.navigate([`/${environment.reportingRoutePrefix}/actionitems/resources`]);
  }
  onHelp() {
    let route = '';
    let parentroute = '';
    route = this.router.url.split('?')[0].split('/').pop();
    parentroute = this.router.url.split('?')[0].split('/')[this.router.url.split('?')[0].split('/').length - 2];
    // console.log('parentroute', parentroute);
    // console.log('route', this.router.url.split('?')[0].split('/'));
    const mapper = {
      summary: 'Alerts.htm#_Alert_Dashboard',
      list: 'Alerts.htm#_Alert_List',
      surveylist: 'Response',
      deleted: 'SurveyList-DeletedSurvey',
      customersearch: 'CustomerSearch',
      orgsummary: 'OrganizationSummary',
      voc: 'TextAnalytics',
      treereport: 'TreeReport',
      dynamictopic: 'DynamicTopicSearch',
      commentlist: 'CommentList',
      dashboard: 'SocialMedia',
      reviewlist: 'ReviewList',
      dealercompetitors: 'CompetitorsList',
      statistics: 'ContactStatistics',
      loginsummary: 'LoginSummary',
      'actionplan-list': 'ActionPlan',
      'contactreport-list': 'ActionPlan',
      trafficdetails: 'LoginAndTrafficStatsDetails',
      trafficsummary: 'LoginAndSiteTrafficSummary',
      undefined: 'NotFound',
      globaldashboard: 'Dashboard',
    };
    const language = localStorage.getItem('language');
    if (mapper[route]) {
      if (route === 'voc' && parentroute === 'treereport') {
        window.open(`webhelp/${language}/TreeReport.htm`);
      } else {
        window.open(`webhelp/${language}/${mapper[route]}.htm`);
      }
    } else if (parentroute === 'customer-history') {
      window.open(`webhelp/${language}/CustomerHistory.htm`);
    } else if (parentroute === 'performancetrend') {
      window.open(`webhelp/${language}/PerformanceTrend.htm`);
    } else {
      window.open(`webhelp/${language}/NotFound.htm`);
    }
  }
  onHelpDesk() {
    const languageSelected = localStorage.getItem('language');
    window.open(`webhelp/${languageSelected}/${this.translateService.instant('helpdesk.pdf')}`);
  }

  onTrainingVideos() {
    this.router.navigate([`/${environment.reportingRoutePrefix}/actionitems/trainingvideos`]);
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  handleChange(event: any) {
    this.modeService.setMode(this.mode);
    document.body.classList.add('admin--body');
    this.router.navigateByUrl('/' + environment.reflecxRoutePrefix);
  }

  logout() {
    this.onLogout();
    // this.authenticationService
    //   .logout()

    //   .subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  // get username(): string | null {
  //   const credentials = this.authenticationService.credentials;
  //   return credentials ? credentials.username : null;
  // }
}
