import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cvc-primeng',
  template: ` <p>primeng works!</p> `,
})
export class PrimengComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
