import { ContextMenuRow } from 'primeng/table';
import { Directive } from '@angular/core';

@Directive({
  selector: '[cvpContextMenuRow]',
  host: {
    '[class.ui-contextmenu-selected]': 'selected',
  },
})
export class CVPContextMenuRow extends ContextMenuRow {}
