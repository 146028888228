import { CVPTableComponent } from '../table.component';
import { CVPEditableRow } from './editableRow.directive';
import { InitEditableRow } from 'primeng/table';
import { Directive, HostListener } from '@angular/core';
import { EditableRow } from 'primeng/table';

@Directive({
  selector: '[cvpInitEditableRow]',
  providers: [CVPEditableRow],
})
export class CVPInitEditableRow extends InitEditableRow {
  constructor(public dt: CVPTableComponent, public editableRow: EditableRow) {
    super(dt, editableRow);
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    this.dt.initRowEdit(this.editableRow.data);
    this.dt.scrollToRight();
    event.preventDefault();
  }
}
