<div class="comment-mic" [class.mic--active]="listening" (click)="listen()" *ngIf="hasMic">
  <div class="animation-outer"></div>
  <voice-button #voiceButton cloud-speech-api-key="AIzaSyAkUDloJwxS5jouGnY43tcQ2VaArZQcyRM" autodetect> </voice-button>
</div>
<textarea
  [id]="id"
  [maxLength]="maxlength"
  [rows]="rows"
  [cols]="cols"
  [placeholder]="placeholder"
  [ngClass]="'with--mic ' + textareaClass"
  (change)="modelChange($event)"
  (input)="modelChange($event)"
  [disabled]="disabled"
  #myTextArea
  [value]="model ? model : ''"
></textarea>
