/*
 * Public API Surface of ngx-tour-ngx-popper
 */

export { INgxpStepOption as IStepOption } from './lib/step-option.interface';
export { NgxpTourService as TourService } from './lib/ngx-popper-tour.service';
export { TourNgxPopperModule, TourAnchorNgxPopperDirective, TourStepTemplateComponent } from './lib/ngx-popper.module';

export * from './lib/tour-anchor.directive';
export * from './lib/popper/popper-content';
export * from './lib/popper/popper-directive';
