<appc-page-layout
  [pageTitle]="'Tree Report' | translate"
  [showTabs]="isVoc ? true : false"
  [showFilter]="true"
  [showCustomRange]="true"
  [showVocFilters]="isVoc ? true : false"
  [hideSomeFilters]="isVoc ? false : true"
  [showSentimentFilters]="false"
  [showCategoryFilters]="false"
  [orgMaxLevel]="isVoc ? 5 : 3"
  [showSmVocFilters]="isVoc ? false : true"
  [showResponseList]="isVoc ? true : false"
  [applyDirective]="isVoc ? false : true"
  [showSurveyScoreList]="isVoc ? true : false"
  [showVersion]="isVoc ? true : false"
  [showModel]="isVoc ? true : false"
  [showSourceTypeFilter]="true"
  (TabSelect)="onTabSelect($event)"
  (FilterChange)="onFilterChange($event)"
  (FilterApply)="applyFilters()"
>
  <!-- <appc-page-layout  [orgMaxLevel]="3" [pageTitle]="'socialmedia_dashboard.title' | translate" [applyDirective]="true"
  (TabSelect)="onTabSelect($event)" [showFilter]="true" (FilterChange)="onFilterChange($event)" [showTabs]="false"
  (FilterApply)="applyFilters()" [hideSomeFilters]="true" [showSmVocFilters]="true" [showProgramDate]="false"
  [showSourceTypeFilter]="true" [showCustomRange]="true" [showVocFilters]="false" [showlanguage]="false"
  [showResponseList]="false" [showCategoryList]="false" [showSurveyScoreList]="false" [showVersion]="false"
  [showModel]="false"> -->

  <main class="page-treereport main">
    <div class="card-xl">
      <div class="widget fullpage--widget">
        <div class="widget__filters filter--white">
          <div class="filter-item filter-item--tabs">
            <p-tabMenu [model]="sentimentsTab" [activeItem]="curSentTab" styleClass="tabs-secondary">
              <ng-template pTemplate="tab" let-tab let-i="index">
                <span (click)="onSenTabSel(tab)" style="cursor: pointer">
                  {{ GetSentimentTranslation(tab.label) }} ({{ tab.count }})
                </span>
              </ng-template>
            </p-tabMenu>
          </div>
        </div>
        <div class="tree-report">
          <div class="report__col">
            <h2 class="col__title textupper">{{ 'Top Level' | translate }}</h2>
            <div class="col__inner">
              <cvp-scrollPanel-ul
                #sp
                class="report-list list--area"
                *ngIf="categoryListLevel1"
                [style]="{ height: !isMobile ? '550px' : '100%' }"
              >
                <li
                  *ngFor="let category of categoryListLevel1"
                  (click)="catLstLvlIn(category, 1)"
                  class="list__item"
                  [class.has-child]="category.children != null && category.children.length > 0"
                  [class.active]="category.IsSelected"
                >
                  <a [class]="category.children != null && category.IsSelected ? 'active' : ''">
                    {{ GetCategoryTranslation(category?.Label) }} <em>({{ category.Count }})</em>
                  </a>
                </li>
              </cvp-scrollPanel-ul>
            </div>
          </div>
          <div class="report__col">
            <h2 class="col__title textupper" translate>Mid Level</h2>
            <div class="col__inner">
              <cvp-scrollPanel-ul
                #sp2
                class="report-list"
                *ngIf="categoryListLevel2"
                [style]="{ width: '100%', height: !isMobile ? '550px' : '100%' }"
              >
                <li
                  *ngFor="let category of categoryListLevel2"
                  (click)="catLstLvlIn(category, 2)"
                  class="list__item"
                  [class.has-child]="category.children != null && category.children.length > 0"
                  [class.active]="category.IsSelected"
                >
                  <a
                    >{{ GetCategoryTranslation(category?.Label) }}
                    <em>({{ category.Count }})</em>
                  </a>
                </li>
              </cvp-scrollPanel-ul>
            </div>
          </div>
          <div class="report__col">
            <h2 style="cursor: pointer" class="col__title textupper">{{ 'Detail Level' | translate }}</h2>
            <div class="col__inner">
              <cvp-scrollPanel-ul
                #sp3
                class="report-list"
                *ngIf="categoryListLevel3"
                [style]="{ width: '100%', height: !isMobile ? '550px' : '100%' }"
              >
                <li
                  *ngFor="let category of categoryListLevel3"
                  (click)="catLstLvlIn(category, 3)"
                  class="list__item"
                  [class.has-child]="category.children != null && category.children.length > 0"
                  [class.active]="category.IsSelected"
                >
                  <a
                    >{{ GetCategoryTranslation(category?.Label) }} <em>({{ category.Count }})</em></a
                  >
                </li>
              </cvp-scrollPanel-ul>
            </div>
          </div>
        </div>
        <div class="tree-comments">
          <h2 class="col__title textupper">{{ 'Related Topics & Comments' | translate }}</h2>
          <div class="comments__inner">
            <cvp-scrollPanel #sp4 [style]="{ width: '100%', height: !isMobile ? '550px' : '100%' }">
              <div class="comments__inner__content">
                <app-widget-spinner [hidden]="spinProg == 0"></app-widget-spinner>
                <div class="view-more-wrapper" *ngIf="tlTopFil && tlTopFil.length > 0">
                  <a
                    *ngIf="curSentTab && curSentTab.label === 'All' && currentFaculty.Label === 'All'"
                    class="view-more noprint"
                    [routerLink]="[isVoc ? '/insights/voc/commentlist' : '/insights/rm/reviewlist']"
                    >{{ 'View All' | translate }}</a
                  >
                  <a
                    *ngIf="curSentTab && curSentTab.label !== 'All' && currentFaculty.Label === 'All'"
                    class="view-more noprint"
                    [routerLink]="[isVoc ? '/insights/voc/commentlist' : '/insights/rm/reviewlist']"
                    [queryParams]="{ status: curSentTab.label, isNonCategoryClickThrough: true }"
                    >{{ 'View All' | translate }}</a
                  >
                  <a
                    *ngIf="curSentTab && curSentTab.label === 'All' && currentFaculty.Label !== 'All'"
                    class="view-more noprint"
                    [routerLink]="[isVoc ? '/insights/voc/commentlist' : '/insights/rm/reviewlist']"
                    [queryParams]="{
                      categoryId: currentFaculty.Name,
                      categoryName: currentFaculty.Label,
                      isNonCategoryClickThrough: true
                    }"
                    >{{ 'View All' | translate }}</a
                  >
                  <a
                    *ngIf="curSentTab && curSentTab.label !== 'All' && currentFaculty.Label !== 'All'"
                    class="view-more noprint"
                    [routerLink]="[isVoc ? '/insights/voc/commentlist' : '/insights/rm/reviewlist']"
                    [queryParams]="{
                      status: curSentTab.label,
                      categoryId: currentFaculty.Name,
                      categoryName: currentFaculty.Label,
                      isNonCategoryClickThrough: true
                    }"
                    >{{ 'View All' | translate }}</a
                  >
                </div>
                <div>
                  <div class="tags-section">
                    <ul *ngIf="!currentFaculty" class="tags tags--treereport tags--smaller">
                      <li
                        *ngFor="let topic of tlTopFil"
                        style="cursor: pointer"
                        class="tags__item tag--one"
                        [ngClass]="
                          topic.Sentiment == 'Positive'
                            ? 'clr-green'
                            : topic.Sentiment == 'Neutral'
                            ? 'clr-orange'
                            : 'clr-pink'
                        "
                        [routerLink]="[isVoc ? '/insights/voc/commentlist' : '/insights/rm/reviewlist']"
                        [queryParams]="{ status: topic.Sentiment, topic: topic.Title, isNonCategoryClickThrough: true }"
                      >
                        {{ topic.Title }} <span href="" class="tags__count">({{ topic.Count }})</span>
                      </li>
                    </ul>
                    <ul *ngIf="currentFaculty && currentFaculty.Name" class="tags tags--treereport tags--smaller">
                      <li
                        *ngFor="let topic of tlTopFil"
                        style="cursor: pointer"
                        class="tags__item tag--one"
                        [ngClass]="
                          topic.Sentiment == 'Positive'
                            ? 'clr-green'
                            : topic.Sentiment == 'Neutral'
                            ? 'clr-orange'
                            : 'clr-pink'
                        "
                        [routerLink]="[isVoc ? '/insights/voc/commentlist' : '/insights/rm/reviewlist']"
                        [queryParams]="{
                          status: topic.Sentiment,
                          topic: topic.Title,
                          categoryId: currentFaculty.Name,
                          categoryName: currentFaculty.Label,
                          isNonCategoryClickThrough: true
                        }"
                      >
                        {{ topic.Title }} <span href="" class="tags__count">({{ topic.Count }})</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <ul class="comments-list" *ngIf="tlComFil">
                    <li
                      *ngFor="let comment of tlComFil || []"
                      class="list__item"
                      [ngClass]="{
                        positive: comment != null && comment.Sentiment === 'Positive',
                        negative: comment != null && comment.Sentiment === 'Negative',
                        neutral: comment != null && comment.Sentiment === 'Neutral',
                        unassigned: comment != null && comment.Sentiment === 'Unassigned'
                      }"
                    >
                      <p>{{ comment.Comment }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </cvp-scrollPanel>
          </div>
        </div>
      </div>
    </div>
  </main>
</appc-page-layout>
