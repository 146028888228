import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';

@Component({
  selector: 'app-bug-report',
  templateUrl: './bug-report.component.html',
  styleUrls: ['./bug-report.component.scss'],
})
export class BugReportComponent implements OnInit {
  user: any;
  options = {
    types: ['Network', 'Data', 'Application', 'Others'],
    priority: ['Low', 'Normal', 'High'],
  };

  bugreport: any = {};

  @Input() service: AuthenticationService;
  @Output() _Close = new EventEmitter();
  constructor() {}

  ngOnInit() {
    this.user = this.service.getUser();
    this.bugreport = {
      Type: this.options.types[0],
      Priority: this.options.priority[0],
      Errorlog: '',
      ClientId: this.user.ClientId,
      CreatedBy: this.user.CreatedBy,
      ModifiedBy: this.user.ModifiedBy,
    };
  }

  save() {}
  close() {
    this._Close.emit(false);
  }
}
