export interface DataParam {
  Event: string;
  QueryType: number;
}

export interface CompareItem {
  Name: string;
  Level: string;
  Parent: any;
}

export enum QueryTypes {
  HotAlert = 1,
  Survey = 2,
  Custome = 3,
  Question = 4,
  NumberofSurvey = 5,
}
