import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import HighCharts, { theme } from '@clearviewshared/highchart/highchart';
import { TranslateService } from '@ngx-translate/core';
import { generateKPINew } from '@clearviewshared/highchart/helperfunctions';
import { Subscription, interval } from 'rxjs';
import { CacheService } from '@app/shared/services/cache.service';
import { WidgetService } from '../widget.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import WidgetBase from '../widegt.base';
import { I18nService } from '@app/core';
import { IKpisResponse } from './types/IKpisNewResponse';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';

@Component({
  selector: 'kpi-meter-new-widget',
  templateUrl: './kpi-meter-new.component.html',
  styleUrls: ['./kpi-meter-new.component.scss'],
})
export class KpiMeterNewComponent extends WidgetBase implements OnInit, OnDestroy {
  @Input() data: any;
  @Input() rank: any;
  @ViewChild('container', { static: true }) container: ElementRef;
  Highcharts = HighCharts;
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  rankKpi: any;
  kpiData: any[] = [];
  canRender = false;
  dialsLength = 0;
  api: Subscription;
  private subscriptions: Subscription[] = [];
  constructor(
    public toastService: ToastService,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public i18nService: I18nService,
    private appConfig: AppConfigurationService,
    public gbfService: GlobalFilterService
  ) {
    super(gbfService, translateService);
    HighCharts.setOptions(theme);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.kpiUrl).subscribe((res: Array<IKpisResponse>) => {
        if (res) {
          this.generateKPIChart(res);
        }
      })
    );
    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
    }

    // this.secondsCounterSub = this.secondsCounter.subscribe((n) => {
    //   // this.loadWidget();
    // });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getKpis({ apiConfig: this.apiConfig }).subscribe({
      next: (res: Array<IKpisResponse>) => {
        if (res) {
          this.generateKPIChart(res);
          this.canRender = true;
          this.widgetService.isAllLoaded = true;
        }
      },
      error: (error: any) => {
        this.toastService.error('Failed.');
        this.canRender = true;
        this.widgetService.isAllLoaded = true;
      },
      complete: () => {
        this.canRender = true;
      },
    });
  }
  private getDialsLength(dialsMeta: any) {
    let dialsLength = 0;
    for (const dialMeta of dialsMeta) {
      if (dialMeta.Label.length > 0) {
        try {
          if (dialMeta.Active && dialMeta.IsDashboardKPI) {
            dialsLength += 1;
          }
        } catch (e) {}
      }
    }
    this.dialsLength = dialsLength;
    return this.dialsLength;
  }
  private generateKPIChart(res: any) {
    this.rankKpi = res;
    this.kpiData = [];
    if (res) {
      const dialsLength = this.getDialsLength(res);
      this.dialsLength = dialsLength;
      for (const oo of res) {
        if (oo.Label.length > 0) {
          try {
            oo.Label = this.translateService.instant(oo.Label);
            const target = this.translateService.instant('Target');
            if (oo.Active && oo.IsDashboardKPI) {
              this.kpiData.push({
                data: generateKPINew(
                  `${oo.Label}`,
                  '950',
                  parseFloat((oo.Target * 100) / 100 + '').toFixed(1),
                  'red',
                  this.i18nService,
                  oo.IsTarget,
                  target,
                  dialsLength
                ),
                rank: oo.KpiRanks,
              });
            }
            break;
          } catch (e) {}
        }
      }
    }
  }
}
