import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, interval, fromEvent } from 'rxjs';
import HighCharts, { theme } from '@clearviewshared/highchart/highchart';
import { KeyPerformance } from '../../reporting/types/key-perforamnce';
import { WidgetService } from '../widget.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { debounceTime } from 'rxjs/operators';
import WidgetBase from '../widegt.base';
import { I18nService } from '@app/core/i18n.service';
import { TranslationService } from '@reflecxfeatures/Survey/translation-editor/translation.service';
import { Router } from '@angular/router';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';

@Component({
  selector: 'area-of-improvement',
  templateUrl: './areas-for-improvement.html',
})
export class AreaOfImprovementComponent extends WidgetBase implements OnInit, OnDestroy {
  chart1: any;
  chart2: any;
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @ViewChild('containerAOI', { read: ElementRef }) containerAOI: ElementRef;
  @ViewChild('containerAOI2', { read: ElementRef }) containerAOI2: ElementRef;
  @Input() data: any;

  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  areaOfImprovmentDataHighest: any;
  areaOfImprovmentDataLowest: any;
  keyPerformanceHighestGridList: KeyPerformance[];
  keyPerformanceLowestGridList: KeyPerformance[];
  keyPerformanceLowestSelectedScore: number;
  keyPerformanceHighestSelectedScore: number;
  keyPerformanceLowestQuestion: any = {};
  keyPerformanceHighestQuestion: any = {};
  Highcharts = HighCharts;
  keyPerformanceLowestGridQuestion: string;
  keyPerformanceHighestGridQuestion: string;
  lowestScore: number;
  highestScore: number;
  responseData: any;
  keyPerformanceLowest: KeyPerformance[];
  keyPerformanceHighest: KeyPerformance[];
  KeyPerformanceHighestParent: KeyPerformance[];
  KeyPerformanceLowestParent: KeyPerformance[];
  canRender = false;
  api: Subscription;
  permissions: NgxPermissionsObject;
  hasCommentPermission: boolean = false;
  hasCommentListPermission: boolean = false;

  private subscriptions: Subscription[] = [];
  constructor(
    public toastService: ToastService,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    public i18nService: I18nService,
    public gbfService: GlobalFilterService,
    private cacheService: CacheService,
    private router: Router,
    public ngxPermission: NgxPermissionsService
  ) {
    super(gbfService, translateService);
    HighCharts.setOptions(theme);
    fromEvent(window, 'resize')
      .pipe(debounceTime(1500))
      .subscribe((event) => {
        this.initData();
      });
  }

  initData() {
    this.generateAreaOfImprovementHighChart(this.responseData, () => {
      // tslint:disable-next-line:max-line-length
      this.chart1 = HighCharts['Reflecx'].createOrUpdate(
        this.chart1,
        this.containerAOI.nativeElement,
        this.areaOfImprovmentDataLowest
      );
      // tslint:disable-next-line:max-line-length
      this.chart2 = HighCharts['Reflecx'].createOrUpdate(
        this.chart2,
        this.containerAOI2.nativeElement,
        this.areaOfImprovmentDataHighest
      );
    });
  }

  displayCustomerQuestionComment(selection: any) {
    if (this.hasCommentPermission && selection.questionType !== undefined && selection.questionType != null) {
      if (selection.questionType !== undefined && selection.questionType != null) {
        if (selection.questionType === 'variable') {
          this.router.navigate(['/insights/dashboard/customerquestioncomment'], {
            queryParams: { vcode: selection.cd },
          });
        } else if (selection.questionType === 'question') {
          this.router.navigate(['/insights/dashboard/customerquestioncomment'], {
            queryParams: { qcode: selection.cd },
          });
        }
      }
    }
  }

  ngOnInit() {
    this.permissions = this.ngxPermission.getPermissions();
    this.hasCommentPermission = this.permissions[Permission.GLOBALDASHBOARD_CUSTOMERQUESTIONCOMMENT] ? true : false;

    this.loadWidget();
    super.ngOnInit();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.keyperformance).subscribe(
        (res: any) => {
          if (res) {
            theme['plotOptions']['series']['animation']['duration'] = 0;
            HighCharts.setOptions(theme);
            this.generateAreaOfImprovementHighChart(res, () => {
              // tslint:disable-next-line:max-line-length
              this.chart1 = HighCharts['Reflecx'].createOrUpdate(
                this.chart1,
                this.containerAOI.nativeElement,
                this.areaOfImprovmentDataLowest
              );
              // tslint:disable-next-line:max-line-length
              this.chart2 = HighCharts['Reflecx'].createOrUpdate(
                this.chart2,
                this.containerAOI2.nativeElement,
                this.areaOfImprovmentDataHighest
              );
              this.canRender = true;
            });
          }
        },
        (error) => {
          this.canRender = true;
        }
      )
    );
    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
      this.subscriptions.push(
        this.gbfService.gbfOnApplyFilter.subscribe(() => {
          this.loadWidget();
        })
      );
    }
    // this.secondsCounterSub = this.secondsCounter.subscribe((n) => {
    //   //this.loadWidget();
    // });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }

  changeValue(value: any) {
    return parseFloat((value * 100) / 100 + '').toFixed(1);
  }

  private loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getAreaOfImprovementData({ apiConfig: this.apiConfig }).subscribe(
      (res) => {
        this.responseData = res;
        this.generateAreaOfImprovementHighChart(res, () => {
          this.canRender = true;
          // tslint:disable-next-line:max-line-length
          this.chart1 = HighCharts['Reflecx'].createOrUpdate(
            this.chart1,
            this.containerAOI.nativeElement,
            this.areaOfImprovmentDataLowest
          );
          // tslint:disable-next-line:max-line-length
          this.chart2 = HighCharts['Reflecx'].createOrUpdate(
            this.chart2,
            this.containerAOI2.nativeElement,
            this.areaOfImprovmentDataHighest
          );
          this.widgetService.isAllLoaded = true;
        });
      },
      (error: any) => {
        this.canRender = true;
        this.widgetService.isAllLoaded = true;
      }
    );
  }

  private generateAreaOfImprovementHighChart(res: any, callback: Function) {
    if (res && res.hasOwnProperty('Lowest') && res.hasOwnProperty('Highest')) {
      this.KeyPerformanceLowestParent = res.ParentLowest;
      this.KeyPerformanceHighestParent = res.ParentHighest;
      this.keyPerformanceLowest = res.Lowest;
      this.keyPerformanceHighest = res.Highest;

      this.areaOfImprovmentDataLowest = this.generateAreaOfImprovement(
        this.translateService.instant('Your Lowest Scoring Questions'),
        res.Lowest.map((x: any) => x.Code),
        res.Lowest.map((x: any) => parseFloat(this.changeValue(x.QuestionAvgScore))),
        {
          Key: 'keyPerformanceLowest',
          Question: 'keyPerformanceLowestQuestion',
          Parent: 'KeyPerformanceLowestParent',
          SelectedScore: 'keyPerformanceLowestSelectedScore',
          GridList: 'keyPerformanceLowestGridList',
          color: '#d92f66',
        },
        this.i18nService,
        this.translateService,
        this.layout === 'xl' ? 70 : 40
      );
      this.areaOfImprovmentDataHighest = this.generateAreaOfImprovement(
        this.translateService.instant('Your Highest Scoring Questions'),
        res.Highest.map((x: any) => x.Code),
        res.Highest.map((x: any) => parseFloat(this.changeValue(x.QuestionAvgScore))),
        {
          Key: 'keyPerformanceHighest',
          Question: 'keyPerformanceHighestQuestion',
          Parent: 'KeyPerformanceHighestParent',
          SelectedScore: 'keyPerformanceHighestSelectedScore',
          GridList: 'keyPerformanceHighestGridList',
          color: '#89bf5d',
        },
        this.i18nService,
        this.translateService,
        this.layout === 'xl' ? 70 : 35
      );
      let qq = this.keyPerformanceHighest.filter((o) => o.Code === res.Highest[0].Code)[0];
      if (qq) {
        this.keyPerformanceHighestQuestion['qs'] = qq.Question;
        this.keyPerformanceHighestQuestion['sc'] = qq.QuestionAvgScore;
        this.keyPerformanceHighestQuestion['cd'] = qq.Code;
        this.keyPerformanceHighestQuestion['questionType'] = qq.QuestionType;
      } else {
        this.keyPerformanceHighestQuestion['qs'] = '';
        this.keyPerformanceHighestQuestion['sc'] = 0;
        this.keyPerformanceHighestQuestion['cd'] = '';
      }
      if (this.KeyPerformanceHighestParent && res.Highest) {
        // tslint:disable-next-line:max-line-length
        this.keyPerformanceHighestGridList = this.KeyPerformanceHighestParent.filter(
          (o: any) => o.Code === res.Highest[0].Code
        );
        this.keyPerformanceHighestSelectedScore = res.Highest.length ? res.Highest[0].QuestionAvgScore : 0;
      } else {
        this.keyPerformanceHighestGridList = [];
      }
      qq = this.keyPerformanceLowest.filter((o: any) => o.Code === res.Lowest[0].Code)[0];
      if (qq) {
        this.keyPerformanceLowestQuestion['qs'] = qq.Question;
        this.keyPerformanceLowestQuestion['sc'] = qq.QuestionAvgScore;
        this.keyPerformanceLowestQuestion['cd'] = qq.Code;
        this.keyPerformanceLowestQuestion['questionType'] = qq.QuestionType;
      } else {
        this.keyPerformanceLowestQuestion['qs'] = '';
        this.keyPerformanceLowestQuestion['sc'] = 0;
        this.keyPerformanceLowestQuestion['cd'] = '';
      }
      if (this.KeyPerformanceLowestParent && res.Lowest) {
        // tslint:disable-next-line:max-line-length
        this.keyPerformanceLowestGridList = this.KeyPerformanceLowestParent.filter(
          (o: any) => o.Code === res.Lowest[0].Code
        );
        this.keyPerformanceLowestSelectedScore = res.Lowest.length ? res.Lowest[0].QuestionAvgScore : 0;
      } else {
        this.keyPerformanceLowestGridList = [];
      }
    } else {
      this.keyPerformanceLowestQuestion['qs'] = '';
      this.keyPerformanceLowestQuestion['qs'] = '';
    }
    callback();
  }

  private generateAreaOfImprovement(
    title: string,
    questions: string[],
    scores: number[],
    data: any,
    i18nService: I18nService,
    translateService: TranslateService,
    pointsWidth = 80
  ): any {
    const that = this;
    return {
      chart: {
        type: 'column',
        spacingBottom: 20,
        spacingTop: 20,
        spacingLeft: 30,
        spacingRight: 30,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: title,
        style: {
          color: data['color'],
          fontWeight: '400',
          fontSize: '14px',
        },
      },
      // tooltip: {
      //   enabled: false
      // },
      xAxis: {
        gridLineWidth: 0,
        categories: questions, // ['Q1', 'Q2', 'Q3', 'Q4', 'Q5']
      },
      yAxis: {
        min: 0,
        max: 100,
        allowDecimals: true,
        title: {
          text: '',
        },
        tooltip: {
          pointFormat: "<span style='color:{series.color}'>Score</span>: </b> ({point.y:.1f})<br/>",
        },
      },
      // tooltip: {
      //   pointFormat:
      //     '<span style=\'color:{series.color}\'>{series.name}</span>: <b>({e.point.y}%)<br/>',
      //   shared: false
      // },
      /* plotOptions: {
       },*/
      plotOptions: {
        series: {
          pointWidth: pointsWidth,
          cursor: 'pointer',
          events: {
            click: (e: any) => {
              const qq = that[data['Key']].filter((o: any) => o.Code === e.point.category);
              if (qq) {
                that[data['Question']]['qs'] = qq[0].Question;
                that[data['Question']]['sc'] = qq[0].QuestionAvgScore;
                that[data['Question']]['cd'] = qq[0].Code;
                that[data['Question']]['questionType'] = qq[0].QuestionType;
              } else {
                that[data['Question']]['qs'] = '';
                that[data['Question']]['sc'] = 0;
                that[data['Question']]['cd'] = '';
              }
              if (that[data['Parent']]) {
                that[data['SelectedScore']] = e.point.y;
                // tslint:disable-next-line:max-line-length
                that[data['GridList']] = that[data['Parent']].filter((o: any) => o.Code === e.point.category);
              } else {
                that[data['GridList']] = [];
              }
            },
          },
        },
        column: {
          stacking: 'normal',
          borderWidth: 0,
        },
      },
      series: [
        {
          name: '',
          showInLegend: false,
          color: data['color'],
          tooltip: {
            pointFormatter: function () {
              return (
                "<span style='color:{series.color}'>" +
                translateService.instant('Score') +
                '</span>: (' +
                i18nService.transformNumber(this.y, false) +
                ')'
              );
            },
          },
          data: scores, // [5, 3, 4, 7, 2],
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: '#FFFFFF',
            align: 'right',
            verticalAlign: 'top',
            //  format: '{point.y:.1f}', // one decimal,
            formatter: function () {
              return i18nService.transformNumber(this.point.y, false);
            },
            y: 5, // 10 pixels down from the top
            style: {
              fontSize: '13px',
              // fontFamily: 'Verdana, sans-serif'
            },
          },
        },
        // {
        //   name: 'Score',
        //   showInLegend: false,
        //   color: '#f4f5f7',
        //   data: scores // [5, 3, 4, 7, 2]
        // }
      ],
    };
  }
}
