import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Drilldown from 'highcharts/modules/drilldown';
import SolidGauge from 'highcharts/modules/solid-gauge';
import TreeMap from 'highcharts/modules/treemap';
import Accessibility from 'highcharts/modules/accessibility';
import HeatMap from 'highcharts/modules/heatmap';

Highcharts.setOptions({
  lang: {
    numericSymbols: null,
    thousandsSep: '',
  },
});
More(Highcharts);
// Accessibility(Highcharts);
HeatMap(Highcharts);
TreeMap(Highcharts);
Drilldown(Highcharts);
SolidGauge(Highcharts);

export const extraColors = [
  '#DB843D',
  '#92A8CD',
  '#A47D7C',
  '#B5CA92',
  '#1aadce',
  '#492970',
  '#4572A7',
  '#AA4643',
  '#89A54E',
  '#80699B',
  '#3D96AE',
  '#cad0e8',
  '#2f7ed8',
  '#8bbc21',
];
export const getExtraColors = (index: number) => {
  return extraColors[index];
};

export const theme: any = {
  //colors: ['#3bb001', '#FFC107', '#FD7E14', '#007BFF', '#DC3545', '#27D4D4', '#17A2B8', '#ffc107', '#cad0e8'],
  colors: ['#007bff', '#3bb001', '#dc3545', '#6f42c1', '#17a2b8', '#27d4d4', '#fd7e14', '#ffc107', '#cad0e8'],
  chart: {
    style: {
      fontFamily: 'Roboto',
    },
    backgroundColor: {
      backgroundColor: '#fff',
      stops: [
        [0, 'rgb(255, 255, 255)'],
        [1, 'rgb(240, 240, 255)'],
      ],
    },
  },
  // pane: {
  //   center: ['50%', '85%'],
  //   size: '140%',
  // },
  title: {
    style: {
      color: '#34495e',
      //  fontWeight: '700',
      fontSize: '18px',
      //  textTransform: 'uppercase',
    },
    text: ``,
  },
  subtitle: {
    style: {
      color: '#34495e',
      //  fontSize: '11px',
      //  fontWeight: '500',
      //   textTransform: 'uppercase',
    },
    text: ``,
  },
  legend: {
    itemStyle: {
      color: 'black',
    },
    itemHoverStyle: {
      color: 'gray',
    },
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  xAxis: {
    gridLineWidth: 1,
    title: { text: '' },
    type: '',
  },
  plotOptions: {
    series: {
      cursor: 'pointer',
      animation: {
        duration: 1000,
      },
    },
  },
  yAxis: {
    gridLineWidth: 1,
    title: { text: '' },
    min: 0,
  },
};
// Apply the theme
Highcharts.setOptions(theme);
Highcharts['Reflecx'] = {};
Highcharts['Reflecx'].createOrUpdate = (instance: any, container: any, options: any) => {
  if (instance && instance.update) {
    instance.update(options, true, true);
    return instance;
  } else {
    return Highcharts.chart(container, options);
  }
};
export default Highcharts;
