import { SelectableRowDblClick } from 'primeng/table';
import { Directive } from '@angular/core';

@Directive({
  selector: '[cvpSelectableRowDblClick]',
  host: {
    '[class.ui-state-highlight]': 'selected',
  },
})
export class CVPSelectableRowDblClick extends SelectableRowDblClick {}
