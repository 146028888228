import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked, AfterContentInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from '@primeng';
import { WidgetCategories, WidgetList, WidgetPermission } from './widgets';
import { clone } from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CustomizeDashboardService } from './customize-dashboard-service';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CVPActions } from '@gstate/actions';
import { ConfigurationService } from '@reflecxshared/services/configuration/configuration.service';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { isEmpty } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-customize-dashboard',
  templateUrl: './customize-dashboard.component.html',
  styleUrls: ['./customize-dashboard.component.scss'],
  providers: [CustomizeDashboardService, ConfigurationService],
})
export class CustomizeDashboardComponent implements OnInit {
  @ViewChild('header', { static: true }) headerRef: any;
  @ViewChild('footer', { static: true }) footerRef: any;
  @ViewChild('content', { static: true }) contentRef: any;
  dragElement: any = null;
  helper: any = null;
  containerModal: any = null;
  widgets: any = [];
  widgetsShow: any = [];
  widgetsHide: any = [];
  permissionWidget: any = [];
  widgetList: any;
  widgetsTemp: any = [];
  permissions: NgxPermissionsObject;
  show = true;
  categories: Array<any> = [];
  searchText: string;
  activeTab: string;
  selectAll: boolean;
  count = 0;
  searchFilter = '';
  isSearchFilterTextShow: string = 'normal';
  moduleTabsObj: any = {
    currentModuleTabs: [],
    selectedModuleTab: '',
    isModuleTab: false,
    allowModuleTab: '',
  };
  constructor(
    private store: Store<{ count: number }>,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private service: CustomizeDashboardService,
    private configService: ConfigurationService,
    private cd: ChangeDetectorRef,
    public ngxPermission: NgxPermissionsService,
    private translateService: TranslateService
  ) {
    console.log(WidgetList);
    this.permissions = this.ngxPermission.getPermissions();
    console.log(this.config.data);
    this.categories = JSON.parse(JSON.stringify(WidgetCategories));
    // this.widgetList = JSON.parse(localStorage.getItem('widgets'));
    // if (this.widgetList) {
    //   this.widgets = this.widgetList[this.config.data.component];
    //   this.widgetsShow = this.widgetList[this.config.data.component].filter((x: any) => x.show);
    //   this.widgetsHide = this.widgetList[this.config.data.component].filter((x: any) => !x.show);
    // } else {
    //   this.widgetList = WidgetList;
    //   this.widgets = WidgetList[this.config.data.component]; // [...WidgetList[this.config.data.component]];
    //   this.widgetsShow = WidgetList[this.config.data.component].filter((x: any) => x.show);
    //   this.widgetsHide = WidgetList[this.config.data.component].filter((x: any) => !x.show);
    // }
    if (!isEmpty(this.config.data.customizeWidgetPayload)) {
      console.log(this.config.data);
      this.moduleTabsObj.isModuleTab = this.config.data.customizeWidgetPayload.isCustomizedWidgetTab;
      this.activeTab = this.config.data.customizeWidgetPayload.selectedModule;
      this.moduleTabsObj.allowModuleTab = this.config.data.customizeWidgetPayload.selectedModule;
      this.config.data.customizeWidgetPayload.currentTabs.forEach((tab: any) => {
        this.moduleTabsObj.currentModuleTabs.push({
          label: `${this.translateService.instant(tab.label)}`,
          value: tab.value,
        });
      });
      this.moduleTabsObj.selectedModuleTab = this.config.data.customizeWidgetPayload.currentTab;
    } else {
      this.activeTab = this.config.data.component;
    }
    const module = this.moduleTabsObj.isModuleTab
      ? `${this.activeTab} ${this.moduleTabsObj.selectedModuleTab}`
      : this.activeTab;
    this.configService.get(module, '').subscribe((resp) => {
      if (resp) {
        this.initalPermissionWidgetLoad(resp.list);
        this.widgetList = this.permissionWidget;
        this.widgetsTemp = this.permissionWidget;
        this.widgetsShow = this.permissionWidget;
      } else {
        this.initalPermissionWidgetLoad(WidgetList[this.activeTab]);
        this.widgetList = this.permissionWidget;
        this.widgetsTemp = this.permissionWidget;
        this.widgetsShow = this.permissionWidget;
      }
    });
    this.categories.forEach((category: any) => {
      category.show = this.permissions[category.permission] ? true : false;
    });
  }

  public onTabChange(e: any) {
    console.log(e);
    const module = `${this.activeTab} ${e.value}`;
    this.widgetsTemp[this.activeTab] = this.widgetsShow;
    this.configService.get(module, '').subscribe((resp) => {
      if (resp) {
        this.initalPermissionWidgetLoad(JSON.parse(JSON.stringify(resp.list)));
        this.widgetList = this.permissionWidget;
        this.widgetsTemp = this.permissionWidget;
        this.widgetsShow = this.permissionWidget;
      } else {
        this.initalPermissionWidgetLoad(WidgetList[this.activeTab]);
        this.widgetList = this.permissionWidget;
        this.widgetsTemp = this.permissionWidget;
        this.widgetsShow = this.permissionWidget;
      }
    });
    this.isSearchFilterTextShow = 'normal';
    this.searchFilter = '';
  }
  initalPermissionWidgetLoad(res: any) {
    this.permissionWidget = [];
    res.map((res: any) => {
      if (this.widgetPermission(res)) {
        let widgetImage = WidgetList[this.activeTab].find((widgetImage: any) => {
          return res.name == widgetImage.name;
        });
        if (widgetImage) {
          res.image = widgetImage.image;
          res.description = widgetImage.description;
          res.label = widgetImage.label || widgetImage.name;
        }
        this.permissionWidget.push(res);
      }
    });
  }
  widgetPermission(widget: any) {
    if (WidgetPermission[this.activeTab][widget.name] == 'true') {
      return true;
    }
    return this.permissions[WidgetPermission[this.activeTab][widget.name]] ? true : false;
  }
  getSelectedItems() {
    const selected = this.widgetsShow.filter((widget: any) => {
      return widget.isChecked === true;
    });
    return selected ? selected.length : 0;
  }

  onCategoryChange(obj: any) {
    if (this.activeTab !== obj.Name) return;
    let module = obj.Name;
    if (this.moduleTabsObj.allowModuleTab == obj.Name) {
      module = this.moduleTabsObj.isModuleTab ? `${obj.Name} ${this.moduleTabsObj.selectedModuleTab}` : obj.Name;
    }
    this.widgetsTemp[this.activeTab] = this.widgetsShow;
    this.activeTab = obj.Name;
    this.configService.get(module, '').subscribe((resp) => {
      if (resp) {
        this.initalPermissionWidgetLoad(JSON.parse(JSON.stringify(resp.list)));
        this.widgetList = this.permissionWidget;
        this.widgetsTemp = this.permissionWidget;
        this.widgetsShow = this.permissionWidget;
      } else {
        this.initalPermissionWidgetLoad(WidgetList[this.activeTab]);
        this.widgetList = this.permissionWidget;
        this.widgetsTemp = this.permissionWidget;
        this.widgetsShow = this.permissionWidget;
      }
    });
    this.isSearchFilterTextShow = 'normal';
    this.searchFilter = '';
  }

  changeWidgets(widgets: any) {
    switch (this.config.data.component) {
      case 'Main Dashboard':
        widgets['name'] = this.activeTab;
        widgets['tab'] = this.moduleTabsObj.isModuleTab ? `${this.moduleTabsObj.selectedModuleTab}` : '';
        this.store.dispatch(new CVPActions.MainDashBoardActions.ChangeState(widgets));
        break;
      case 'Global Dashboard':
        widgets['name'] = 'Global Dashboard';
        widgets['tab'] = this.moduleTabsObj.isModuleTab ? `${this.moduleTabsObj.selectedModuleTab}` : '';
        this.store.dispatch(new CVPActions.GlobalDashBoardActions.ChangeState(widgets));
        break;
      case 'Hot Alerts':
        widgets['name'] = this.activeTab;
        widgets['tab'] = this.moduleTabsObj.isModuleTab ? `${this.moduleTabsObj.selectedModuleTab}` : '';
        this.store.dispatch(new CVPActions.HotAlertWidgetsAction.ChangeState(widgets));
        break;
      case 'VOC':
        widgets['name'] = this.activeTab;
        widgets['tab'] = this.moduleTabsObj.isModuleTab ? `${this.moduleTabsObj.selectedModuleTab}` : '';
        this.store.dispatch(new CVPActions.VOCWidgetsAction.ChangeState(widgets));
        break;
      case 'Social Media':
        widgets['name'] = this.activeTab;
        widgets['tab'] = this.moduleTabsObj.isModuleTab ? `${this.moduleTabsObj.selectedModuleTab}` : '';
        this.store.dispatch(new CVPActions.SMWidgetsAction.ChangeState(widgets));
        break;
    }
  }

  ngOnInit() {
    this.permissions = this.ngxPermission.getPermissions();
  }

  onSelectAll() {
    if (this.selectAll) {
      this.widgets.map((x: any) => {
        x.show = true;
      });
      this.count = this.widgets.length;
    } else {
      this.widgets.map((x: any) => {
        x.show = false;
      });
      this.count = 0;
    }
  }
  onSearchChange(e: any) {
    // console.log(this.searchFilter);
    if (this.searchFilter) {
      this.isSearchFilterTextShow = '';
      let searchFilter = this.searchFilter.toLowerCase();
      let index = this.widgetsShow.findIndex((res: any) => {
        return res['name'].toLowerCase().includes(searchFilter);
      });
      // console.log(index);
      if (index == -1) {
        this.isSearchFilterTextShow = 'No result found';
      }
    } else {
      this.isSearchFilterTextShow = 'normal';
    }
  }
  onChange(field: any) {
    this.widgets.find((x: any) => {
      return x.name === field.name;
    }).show = field.show;

    this.widgetsShow = this.widgets.filter((x: any) => x.show);
    this.widgetsHide = this.widgets.filter((x: any) => !x.show);
    // this.widgetsClone.find((x: any) => {
    //   return x.name === field.name;
    // }).show = field.show;

    this.checkSelectAll();
  }

  getCount() {
    this.checkSelectAll();
    return (
      this.widgets.filter((item: any) => {
        return item.show === true;
      }).length || 0
    );
  }

  getCountRemove() {
    this.checkSelectAll();
    return (
      this.widgets.filter((item: any) => {
        return item.show === false;
      }).length || 0
    );
  }

  dragMoved(event: any) {
    if (event['pointerPosition'].y) {
      if (this.dragElement === null) {
        this.dragElement = document.querySelector('.cdk-drag-preview');
      }
      if (this.containerModal === null) {
        this.containerModal = document.querySelector('div.ui-dialog-content.ui-widget-content');
      }
      if (event['event'].clientY < 140 + 200) {
        this.containerModal.scrollBy(0, window.screenY - 2);
      }
      if (window.innerHeight - event['event'].clientY < 130 + 200) {
        this.containerModal.scrollBy(0, window.screenY + 2);
      }
      const translate = this.dragElement.style['transform'].split('(')[1].split(',');
      // tslint:disable-next-line:radix
      const x = parseInt(translate[2]);
      // tslint:disable-next-line:radix
      const z = parseInt(translate[0]);
      const t = `translate3d(${z}px, ${event['event'].clientY - 10}px, ${x}px)`;
      this.dragElement.style['transform'] = t;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.helper === null) {
      this.helper = document.querySelector('#helper-drag');
    }
    const widgets = JSON.parse(JSON.stringify(this.widgetsShow));
    moveItemInArray(widgets, event.previousIndex, event.currentIndex);
    this.widgetsShow = widgets;
    this.cd.detectChanges();
    if (this.helper !== null) {
      let reached = false;
      Object.values(this.helper.children).forEach((element: any) => {
        if (reached) {
          this.helper.removeChild(element);
        }
        if (element.id === 'reached') {
          reached = true;
        }
      });
    }
  }

  changeOrder(previousIndex: number, currentIndex: number) {
    const widgets = JSON.parse(JSON.stringify(this.widgetsShow));
    moveItemInArray(widgets, previousIndex, currentIndex);
    this.widgetsShow = widgets;
  }

  removeAll() {
    this.selectAll = false;
    this.count = 0;
    this.widgets.map((x: any, index: number, arr: any) => {
      arr[index].show = false;
    });
    this.widgetsShow = this.widgets.filter((x: any) => x.show);
    this.widgetsHide = this.widgets.filter((x: any) => !x.show);
  }

  addAll() {
    this.selectAll = true;
    this.count = this.widgets.lenght;
    this.widgets.map((x: any, index: number, arr: any) => {
      arr[index].show = true;
    });
    this.widgetsShow = this.widgets.filter((x: any) => x.show);
    this.widgetsHide = this.widgets.filter((x: any) => !x.show);
  }

  save() {
    let module = this.activeTab;
    if (this.moduleTabsObj.allowModuleTab == this.activeTab) {
      module = this.moduleTabsObj.isModuleTab
        ? `${this.activeTab} ${this.moduleTabsObj.selectedModuleTab}`
        : this.activeTab;
    }
    this.widgetList[this.config.data.component] = clone(this.widgets);
    localStorage.setItem('widgets', null);
    localStorage.setItem('widgets', JSON.stringify(this.widgetList));
    this.service.changeWidgets(this.widgets);
    this.changeWidgets(this.widgetsShow);
    this.configService.set({ id: '', list: this.widgetsShow }, module, '');
    this.ref.close();
  }

  close() {
    this.ref.close();
  }

  checkSelectAll() {
    this.selectAll = false;
    this.count = 0;
    const that = this;
    this.widgets.map((x: any) => {
      if (x.show) {
        that.count++;
      }
    });
    if (this.widgets.length === this.count) {
      this.selectAll = true;
    }
  }
}
