import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DynamicDialogRef } from '@primeng';
@Component({
  selector: 'app-timezone',
  templateUrl: './timezone.component.html',
  styleUrls: ['./timezone.component.scss'],
})
export class TimezoneComponent implements OnInit {
  // tslint:disable-next-line:no-output-on-prefix
  @Output() _Close = new EventEmitter();

  constructor(public ref: DynamicDialogRef) {}

  ngOnInit() {}

  close() {
    this._Close.emit(false);
  }
}
