import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { Widget } from '../widgetBase';

@Component({
  selector: 'appc-widget-child',
  templateUrl: './widget-child.component.html',
  styleUrls: ['./widget-child.component.scss'],
})
export class WidgetChildComponent extends Widget implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
