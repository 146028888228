import { Component, OnInit, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Subscription, interval, Observable, fromEvent, debounceTime } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import HighCharts from '@clearviewshared/highchart/highchart';
import { WidgetService } from '../widget.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { AuthenticationService, I18nService } from '@app/core';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';

@Component({
  selector: 'dealer-ship-performance',
  templateUrl: './dealer-ship-performance.component.html',
  styleUrls: ['./dealer-ship-performance.component.scss'],
})
export class DealerShipPerformanceComponent extends WidgetBase implements OnInit, OnDestroy {
  _HighCharts = HighCharts;
  canRender = false;
  noResult = false;
  chartOptions: any = {};
  chart: any;
  name: string;
  plotLineX = 2.5;
  plotLineY = 2.05;
  show = false;
  @ViewChild('chart', { read: ElementRef }) chartContainer: ElementRef;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public toastService: ToastService,
    public gbfService: GlobalFilterService,
    public authService: AuthenticationService,
    private appConfig: AppConfigurationService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService
  ) {
    super(gbfService, translateService);
    fromEvent(window, 'resize')
      .pipe(debounceTime(1500))
      .subscribe((event) => {
        this.loadChart();
      });
  }
  isCallable() {
    const orgLevel = this.gbfService.org?.level;
    const authLevel = this.authService.authData?.MainNodeLevel;
    if (!orgLevel || !authLevel || orgLevel >= authLevel) {
      this.show = false;
      return false;
    }
    this.show = true;
    return true;
  }
  ngOnInit() {
    this.canRender = true;
    super.ngOnInit();
    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
      this.subscriptions.push(
        this.gbfService.gbfOnApplyFilter.subscribe(() => {
          this.loadWidget();
        })
      );
    }
    this.loadWidget();
  }
  loadWidget() {
    if (!this.isCallable()) {
      return;
    }
    const quadrant: any = {
      upperRight: { data: [], color: '#00FF00' },
      upperLeft: { data: [], color: '#FFA500' },
      lowerLeft: { data: [], color: '#FF0000' },
      lowerRight: { data: [], color: '#FFFF00' },
    };
    this.canRender = false;
    this.widgetService.getDealerPerformance({ apiConfig: this.apiConfig, extra: this.extra }).subscribe(
      (res: any) => {
        const events = this.extra.events.map((x: any) => x.Event);
        const xAxis = events[0];
        const yAxis = events[1];
        const sources: any[] = [];
        res.forEach((info: any) => {
          const source = { x: 0, y: 0, label: info.Name, xLabel: '', yLabel: '' };
          if (Array.isArray(info?.Data) && info.Data?.length > 0) {
            source.x = info?.Data?.find((x: any) => x.Event === xAxis)?.Value || 0;
            source.xLabel = xAxis;
            source.yLabel = yAxis;
          }
          if (Array.isArray(info?.Data) && info.Data.length > 1) {
            source.y = info?.Data?.find((x: any) => x.Event === yAxis)?.Value || 0;
            source.yLabel = yAxis;
            source.xLabel = xAxis;
          }
          if (source.x === 0 && source.y === 0) {
            source.xLabel = xAxis;
            source.yLabel = yAxis;
          }
          sources.push(source);
        });
        const maxY = Math.max.apply(
          Math,
          sources.map(function (o) {
            return o.y;
          })
        );
        const maxX = Math.max.apply(
          Math,
          sources.map(function (o) {
            return o.x;
          })
        );
        this.plotLineX = maxX / 2;
        this.plotLineY = maxY / 2;
        sources.forEach((item) => {
          const { x, y } = item;
          const vertical = y > this.plotLineY ? 'upper' : 'lower';
          const horizontal = x > this.plotLineX ? 'Right' : 'Left';
          const target = vertical + horizontal;
          quadrant[target].data.push(item);
        });
        // console.log(sources);
        const series = [quadrant.upperRight, quadrant.upperLeft, quadrant.lowerLeft, quadrant.lowerRight];
        this.loadChart(series);
        this.canRender = true;
      },
      (error) => {
        this.canRender = true;
      }
    );
  }

  loadChart(points = new Array()) {
    if (this.chartContainer && this.chartContainer.nativeElement && this._HighCharts) {
      const chartOptions = {
        chart: {
          height: '450px',
          type: 'bubble',
          plotBorderWidth: 1,
          zoomType: 'x',
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        subtitle: {
          display: false,
          text: '',
        },
        title: {
          display: false,
          text: '',
        },
        xAxis: {
          startOnTick: false,
          endOnTick: false,
          gridLineWidth: 1,
          title: {
            enabled: true,
          },
          legend: {
            enabled: true,
          },
          labels: {
            enabled: true,
          },
          plotLines: [
            {
              color: '#ccc',
              dashStyle: 'stroke',
              width: 1,
              value: this.plotLineX,
            },
          ],
        },
        yAxis: {
          startOnTick: false,
          endOnTick: false,
          gridLineWidth: 1,
          legend: {
            enabled: false,
          },
          title: {
            enabled: true,
          },
          labels: {
            enabled: true,
          },
          plotLines: [
            {
              color: '#ccc',
              dashStyle: 'stroke',
              width: 1,
              value: this.plotLineY,
            },
          ],
        },
        tooltip: {
          useHTML: true,
          headerFormat: '<table>',
          pointFormat:
            '<tr><th colspan="2"><h3>{point.label}</h3></th></tr>' +
            '<tr><th>{point.xLabel}:</th><td>{point.x}</td></tr>' +
            '<tr><th>{point.yLabel}:</th><td>{point.y}</td></tr>' +
            '<tr><th>({point.xLabel}, {point.yLabel}): ({point.x}, {point.y})</td></tr><table>',
          footerFormat: '</table>',
          followPointer: true,
        },
        plotOptions: {
          bubble: {
            minSize: 15,
            maxSize: 20,
          },
          series: {
            dataLabels: {
              enabled: false,
              format: '{point.SummaryId}',
            },
          },
        },
        series: points,
      };
      this.chart = this._HighCharts['Reflecx'].createOrUpdate(
        this.chart,
        this.chartContainer.nativeElement,
        chartOptions
      );
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });
  }
}
