<div class="main-sidebar" [class.active]="this.sidebarService.isSidebar" data-html2canvas-ignore="true">
  <button
    (click)="onBack($event)"
    *ngIf="gbfRouter.length()"
    class="btn"
    style="width: 100%; border-radius: 0; text-align: right; padding-right: 18px"
  >
    {{ 'Back' | translate }}
  </button>
  <div class="sidebar-header">
    <div class="menu-wrapper" (click)="toggleSidebar()">
      <div class="hamburger-menu" [class.animate]="this.sidebarService.isSidebar"></div>
    </div>
  </div>
  <nav class="sidebar-menu">
    <ul class="">
      <li
        *ngFor="let item of items"
        [class.active]="item.active"
        [class.selected]="item.showChild"
        (contextmenu)="onContextMenu(item.showChild)"
      >
        <a *ngIf="!item.children && item.click" (click)="onRoute(item)">{{ item.name | translate }}</a>
        <a *ngIf="item.children && !item.click" (click)="onRoute(item)">{{ item.name | translate }}</a>
        <a *ngIf="!item.children && !item.click" (click)="onRoute(item)" [routerLink]="item.route">{{
          item.name | translate
        }}</a>

        <ul *ngIf="item.showChild">
          <li *ngFor="let child of item.children" [class.active]="checkActive(child.route)" contextmenu="return true">
            <a (click)="onRoute(child)">{{ child.name | translate }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</div>

<div (click)="toggleSidebar()" class="sidebar-overlay" *ngIf="this.sidebarService.isSidebar"></div>
