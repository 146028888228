import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import HighCharts, { theme } from '@clearviewshared/highchart/highchart';
@Component({
  selector: 'dial-meter-new',
  templateUrl: './dial-meter-new.component.html',
  styleUrls: ['./dial-meter-new.component.scss'],
})
export class DialMeterNewComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() rank: any;
  @Input() dialsLength: any = 0;

  Highcharts = HighCharts;
  @ViewChild('container', { read: ElementRef, static: true }) container: ElementRef;
  chart: any;
  constructor() {
    HighCharts.setOptions(theme);
  }

  ngOnInit() {
    if (this.data) {
      this.chart = HighCharts['Reflecx'].createOrUpdate(this.chart, this.container.nativeElement, this.data);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      // tslint:disable-next-line:max-line-length
      this.chart = HighCharts['Reflecx'].createOrUpdate(
        this.chart,
        this.container.nativeElement,
        changes.data.currentValue
      );
    }
  }
}
