import { TableHeaderCheckbox } from 'primeng/table';
import { ElementRef, ViewChild, Component } from '@angular/core';

@Component({
  selector: 'cvp-tableHeaderCheckbox',
  templateUrl: './templates/tableheadercheckbox.html',
})
export class CVPTableHeaderCheckbox extends TableHeaderCheckbox {
  @ViewChild('box') boxViewChild: ElementRef;
}
