import { Component, OnInit, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import HighCharts from '@clearviewshared/highchart/highchart';
import { Subscription, interval } from 'rxjs';
import { WidgetService } from '../widget.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { generatePerformanceTrends } from '@clearviewshared/highchart/helperfunctions';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import WidgetBase from '../widegt.base';
import { I18nService } from '@app/core/i18n.service';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { ExportService } from '@clearviewshared/services/export.service';
import { FilterService } from 'primeng/api';
import { FiltersService } from '@app/shared/services/filters.service';
import { Permission } from '@clearviewshared/constant/permission';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { genMonthArrFromCurrMonth } from '@app/core/helpers';

@Component({
  selector: 'staff-performance-trend',
  templateUrl: './staff-performance-trend.component.html',
  styleUrls: ['./staff-performance-trend.component.scss'],
})
export class StaffPerformanceTrendComponent extends WidgetBase implements OnInit, OnDestroy, OnChanges {
  routerPrefix = '';
  @Input() makeRequest = true;
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() removeWidgetClass = false;
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @ViewChild('containerPerTreWidget') containerPerTreWidget: ElementRef;
  @Input() setDataFrominput: any = '';
  @Input() allowDropDownShow = true;

  secondsCounter: any;
  secondsCounterSub: Subscription;
  data: any = [];
  chartOptions: any = {};
  canRender = false;
  api: Subscription;
  name: string;
  records: any = [];
  colors = ['green', 'orange', 'blue'];
  permissions: NgxPermissionsObject;
  clickThroughReport: boolean;
  showsWidgets: boolean = false;
  basicColorCode = [
    '#808080',
    '#fc352f',
    '#2066e5',
    '#9f95f8',
    '#7a1935',
    '#000000',
    '#FFA500',
    '#00FF00',
    '#808000',
    '#800000',
  ];
  colorCode = [
    '#8ee223',
    '#8e8014',
    '#da5758',
    '#43f1bc',
    '#af180a',
    '#935c2e',
    '#5d741b',
    '#440bac',
    '#fab475',
    '#d8f93b',
    '#fc352f',
    '#1e6820',
    '#4c0e85',
    '#4897b8',
    '#e37cd2',
    '#90e9eb',
    '#dc23a0',
    '#9ea312',
    '#2eaf',
    '#b210cd',
    '#8d5f77',
    '#33567c',
    '#d6ab02',
    '#3ea9d4',
    '#b30a3a',
    '#9f95f8',
    '#f8dac9',
    '#15cf16',
    '#40d7a3',
    '#4c16f5',
    '#5ea898',
    '#16a7c2',
    '#2a5471',
    '#a8d040',
    '#ac675b',
    '#2066e5',
    '#82a1f3',
    '#c849f4',
    '#6332e6',
    '#80f70f',
    '#c4ac8c',
    '#4596ff',
    '#2e6398',
    '#862f65',
    '#583a94',
    '#a9be91',
    '#6a1910',
    '#7cc09a',
    '#e649bb',
    '#46905d',
    '#9f5197',
    '#473e3b',
    '#a8b4df',
    '#30af97',
    '#4532c6',
    '#8d91a5',
    '#3cc7bf',
    '#f73f56',
    '#b69e78',
    '#dcd69b',
    '#843953',
    '#b41879',
    '#74f180',
    '#8289f5',
    '#c438ea',
    '#fdf364',
    '#5a8930',
    '#bb98bd',
    '#b87354',
    '#4cfeb9',
    '#a82db1',
    '#7c05a4',
    '#9515fd',
    '#7a1935',
    '#4c0b4f',
    '#221ed9',
    '#c2387f',
    '#6aac10',
    '#f7b129',
    '#bd7a98',
    '#1bb5bc',
    '#6f3b3b',
    '#cab25c',
    '#acb9b8',
    '#f92c20',
    '#355f40',
    '#cf754b',
    '#b290c4',
    '#314baf',
    '#544008',
    '#afb23a',
    '#6eea11',
  ];
  private subscriptions: Subscription[] = [];
  chart: any;
  kpiNames: any[] = [];
  MonthsSelect: any[] = [];
  MonthOption: string;

  constructor(
    public toastService: ToastService,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    private appConfig: AppConfigurationService,
    public i18nService: I18nService,
    public filterService: FiltersService,
    public exportService: ExportService,
    public ngxPermission: NgxPermissionsService,
    public gbfService: GlobalFilterService
  ) {
    super(gbfService, translateService);
    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }

      this.secondsCounter = interval(widgetThresholdVal);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    super.ngOnInit();

    this.fillKpiDropDown();
    this.fillMtdDropDown();

    //this.menuCustom();
    if (this.makeRequest) {
      this.loadWidget();
    } else {
      this.loadWidgetByProp();
    }
    /* this.subscriptions.push(
      this.cacheService
        .for(this.widgetService.staffPerformanceTrendUrl)
        .subscribe((res: Array<ISatffPerformanceTrendQuestionResponse>) => {
          if (res) {
            this.data = res;
            debugger;
            // this.data =  res;
            console.log(this.data);
            this.initData();
            this.canRender = true;
          }
        })
    );*/

    this.subscriptions.push(
      this.widgetService.update.subscribe(() => {
        this.loadWidget();
      })
    );

    this.permissions = this.ngxPermission.getPermissions();
    this.setPermissions();

    // this.secondsCounterSub = this.secondsCounter.subscribe((n: any) => {
    //   // this.loadWidget();
    // });
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }

  fillKpiDropDown() {
    var kpiConfig = this.appConfig.getByIdentifier('staff_performance_trend.process_view');
    if (kpiConfig && kpiConfig['Fields']) {
      var obj = this.filterService.getFilterObject();
      if (obj) {
        let kpisConf: [] = kpiConfig.Fields;
        var kpiList = kpisConf?.filter((f) => f['survey_id'] == obj.SurveyVersion)[0];
        if (kpiList) {
          let list: [] = kpiList['kpis'];
          this.name = kpiList['kpis'][1];
          for (var ii of list) {
            this.kpiNames.push({ label: this.translateService.instant(ii), value: ii });
          }
        }
      }
    }
  }
  fillMtdDropDown() {
    this.MonthsSelect.push({ label: '3MTD', value: '3MTD' });
    this.MonthsSelect.push({ label: '6MTD', value: '6MTD' });
    this.MonthsSelect.push({ label: '12MTD', value: '12MTD' });
    this.MonthOption = '12MTD';
  }
  loadWidgetByProp() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.setDataFrominput) {
      this.data = [];
      this.initData();
      this.widgetService.isAllLoaded = true;
      this.canRender = true;
    }
  }
  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    // this.dispGraph = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getstaffPerformanceTrend(this.name).subscribe({
      next: (res: Array<any>) => {
        if (res) {
          //let response: any = res;
          this.getmappedrecords(res);
          this.initData();
          //this.previewRecords = this.mappedResponseToObjects(response);
          this.canRender = true;
          this.widgetService.isAllLoaded = true;
        }
      },
      complete: () => {
        this.canRender = true;
        // this.dispGraph = true;
        this.widgetService.isAllLoaded = true;
      },
      error: () => {
        this.canRender = true;
        // this.dispGraph = false;
        this.widgetService.isAllLoaded = true;
      },
    });
  }
  getmappedrecords(record: any) {
    this.data = [];
    var cal: any;
    record.response.forEach((f: any) => {
      f.forEach((o: any) => {
        this.data.push(o);
      });
    });
  }

  setPermissions() {
    this.clickThroughReport = this.permissions[Permission.REPORT_PERFORMANCE_TREND] ? true : false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fillKpiDropDown();
    super.ngOnChanges(changes);
    if (this.makeRequest) {
      this.loadWidget();
    } else {
      this.loadWidgetByProp();
    }
  }

  onKPIChange(event: any) {
    this.loadWidget();
  }

  initData() {
    this.chartOptions = {};
    let colors = this.data.length <= 10 ? this.basicColorCode : this.colorCode;
    const selectedList: string[] = [];
    const seriesData: any = [];
    const checkIfNotThere: any = {};
    let monthsList: string[] = [];
    let filter = this.gbfService.getFilterObject();
    var endDate = new Date(filter['EndDate']);
    let monthWithKeys = genMonthArrFromCurrMonth(endDate.getMonth() + 1, endDate.getFullYear());
    //monthsList = monthWithKeys.map((m: any) => this.translateService.instant(m.name + ' ' + m.year));
    monthsList = monthWithKeys.map((m: any) => this.translateService.instant(m.name) + ' ' + m.year);
    if (this.MonthOption == '3MTD') {
      monthWithKeys = monthWithKeys.slice(9, 12);
      monthsList = monthsList.slice(9, 12);
    }
    if (this.MonthOption == '6MTD') {
      monthWithKeys = monthWithKeys.slice(6, 12);
      monthsList = monthsList.slice(6, 12);
    }
    if (this.MonthOption == '12MTD') {
      monthWithKeys = monthWithKeys.slice(0, 12);
      monthsList = monthsList.slice(0, 12);
    }
    if (!this.data || this.data.length == 0) {
      this.showsWidgets = true;
    } else {
      this.showsWidgets = false;
    }
    this.data.forEach((x: any, index: number) => {
      var edata: any = [];
      monthWithKeys.forEach((m: any, i: number) => {
        var score = x.data.filter((f: any) => f.month == m.key);
        if (score && score.length > 0) edata.push(score[0].reach_count);
        else edata.push(0);
      });

      seriesData.push({
        color: colors[index],
        data: edata,
        name: (x.emp_type ?? this.translateService.instant(x.emp_type)) + ' ' + this.translateService.instant(x.dealer),
      });
    });
    this.chartOptions = generatePerformanceTrends(seriesData, monthsList, this.i18nService, colors);
    this.chartOptions['plotOptions'] = {
      areaspline: {
        fillOpacity: 0.1,
      },
    };
    // tslint:disable-next-line:max-line-length
    if (this.containerPerTreWidget) {
      this.chart = HighCharts['Reflecx'].createOrUpdate(
        this.chart,
        this.containerPerTreWidget.nativeElement,
        this.chartOptions
      );
    }
  }

  private getRandomColor() {
    const colorPattern = [];
    for (let i = 0; i < this.data.length; i++) {
      const randomColor = Math.floor(Math.random() * (16777215 + i + 2)).toString(16);
      colorPattern.push(`#${randomColor}`);
    }
    return colorPattern;
  }
}
