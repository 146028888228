import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelperService } from '@clearviewshared/services/helper.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable({ providedIn: 'root' })
export class ReportGeneratorHelperService {
  chartConfig: any;
  sortColumnPostfix: string = '_sortValue';
  constructor(
    private http: HttpClient,
    private helperService: HelperService,
    private translateService: TranslateService
  ) {}
  exortAllExcelReport(payload: any, filterExport: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post(
        `/reportgenerator/export_excel`,
        { RGPayload: payload, Filters: filterExport, IsExportAll: true, ExportType: 'DetailedExtracts' },
        { responseType: 'arraybuffer', observe: 'response', headers: headers }
      )
      .subscribe((response: any) => {
        this.helperService.exportExelFile(response, this.translateService.instant('RG_Export'));
      });
  }
  excelReport(tableData: any, filterExport: any, payload: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post(
        `/reportgenerator/export_excel`,
        { Data: tableData, Filters: filterExport, RGPayload: payload },
        { responseType: 'arraybuffer', observe: 'response', headers: headers }
      )
      .subscribe((response: any) => {
        this.helperService.exportExelFile(response, this.translateService.instant('RG_Export'));
      });
  }

  pdfReport(tableData: any, tableHeaders: any, type: string) {
    const doc: any = new jsPDF('l', 'mm', 'a4');
    doc.setFont('helvetica');
    doc.setFontType('bold');
    doc.setFontSize(8);
    const data = this.makeTable(tableHeaders, tableData, '');
    const content = document.getElementsByTagName('html')[0].innerHTML;
    if (false) {
      this.http
        .post('http://localhost:3000', { payload: { html: content } }, { responseType: 'arraybuffer' })
        .subscribe((res: any) => {
          const dd = new Date();
          const blob = new Blob([res]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `rg-${dd.getTime()}.pdf`;
          link.click();
        });
    }
    const specialElementHandlers = {};
    doc.fromHTML(data, 5, 5, {
      width: 2048,
      elementHandlers: specialElementHandlers,
    });
    const d = new Date();
    if (type === 'PRINT') {
      doc.autoPrint();
      doc.output('dataurlnewwindow');
    } else {
      doc.save(`rg-${d.getTime()}.pdf`);
    }
  }

  makeTable(tableHeaders: any, tableData: any, style?: string) {
    const head = `
            <html>
                <head>
                    <style>
                        body {
                            width: 100%;
                        }
                    </style>
                </head>
            <body style="width: 100%;">
        `;
    const footer = `
            </body>
            </html>
        `;
    let tab_text = `${head}<table border='2px' style="width:100%; ${style}">`;
    const tr = `<tr width="100%" style="width:100%; text-align: center; ${style}">`;
    let trs = '';
    let trh = tr;
    tableHeaders.forEach((tth: any) => {
      trh += `<td width="100%" style="text-align:center;width:100%;font-weight:'bold'; padding:'1ex';">${tth['header']}</td>`;
    });
    trh += '</tr>';
    tableData.forEach((tData: any) => {
      let trl = tr;
      tableHeaders.forEach((tth: any) => {
        // tslint:disable-next-line:max-line-length
        trl += `<td width="100%" style="text-align:center;width:100%;padding:'1ex'">${tData[tth['field']]}</td>`;
      });
      trl += '</tr>';
      trs += trl;
    });
    tab_text += trh;
    tab_text += trs;
    tab_text = tab_text + '</table>';
    tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ''); // remove if u want links in your table
    tab_text = tab_text.replace(/<img[^>]*>/gi, ''); // remove if u want images in your table
    tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ''); // reomves input params
    tab_text += footer;
    return tab_text;
  }

  MakeSortColumnName(key: string): string {
    return key + this.sortColumnPostfix;
  }
}
