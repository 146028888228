import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem, DynamicDialogConfig, DynamicDialogRef } from '@primeng';
import * as _ from 'lodash';

@Component({
  selector: 'appc-report-settings',
  templateUrl: './report.setting.component.html',
  styleUrls: ['./report.setting.component.scss'],
})
export class ReportSettingComponent implements OnInit {
  @ViewChild('header', { static: true }) headerRef: any;
  @ViewChild('footer', { static: true }) footerRef: any;
  @ViewChild('content', { static: true }) contentRef: any;
  parselItem: any = { MutableProps: { Label: '' } };
  itemKeys: Array<string> = [];
  item: any;
  constructor(public dialogConfig: DynamicDialogConfig, public ref: DynamicDialogRef) {
    const parsel = this.dialogConfig.data;
    this.parselItem = _.cloneDeep(parsel.item);
    Object.keys(this.parselItem.Item.MutableProps).forEach((key: any) => {
      this.itemKeys.push(key);
    });
  }
  ngOnInit(): void {}
  onDone() {
    this.ref.close(this.parselItem);
  }
  closeDialog() {
    this.ref.close();
  }
}
