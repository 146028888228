<appc-layout-medium>
  <widget-base
    [widgetTitle]="widgetTitle"
    [styleClass]="styleClass"
    [clickThrough]="clickThrough"
    [headerTemplate]="headerTemplate"
    [group]="'medium'"
  >
    <ng-container widgetHeaderBase>
      <ng-content select="[widgetHeader]"></ng-content>
    </ng-container>
    <ng-content select="[widgetArea]"></ng-content>
    <ng-content></ng-content>
  </widget-base>
</appc-layout-medium>
