<!-- <div class="filter-field">
    <div class="filter__trigger">
        <i class="fal fa-industry"></i>
        <span class="field__label" style="padding-left: 20px;" (click)="setUp($event); $event.stopPropagation();">
            <ul class="model__title" style="font-weight: bold;" *ngIf="levels?.length > 0">
                <li style="display: inline-block; margin: 5px;" *ngFor="let l of levels index as i">
                    <a>{{ l.Name }} </a> &nbsp;<i class="fa fa-angle-right" *ngIf="levels?.length != i + 1"></i>
                </li>
            </ul>
            <ng-container *ngIf="levels?.length < 1">Somewhere</ng-container>
        </span>
        <div class="field__icons">
            <span>
                <i class="fal fa-times" *ngIf="showMultiOrgHirerchy"
                    (click)="onHide($event); $event.stopPropagation();"></i>
            </span>
            <i class="far fa-angle-down" *ngIf="!showMultiOrgHirerchy"
                (click)="setUp($event); $event.stopPropagation();"></i>
        </div>
    </div>
    <div class="filter-dropdown" [class.active]="showMultiOrgHirerchy" style="width: auto; padding: 0;">
        <div class="model-column filter-column" style="width: 300px; padding: 0;">
            <h4 class="model__title" *ngIf="levels?.length === 0">{{ 'Org Hierarchy' | translate }}</h4>
            <ul class="model__title" style="font-weight: bold;" *ngIf="levels?.length > 0">
                <li style="display: inline-block; margin: 5px;" (click)="clickThrough({ Id: -1, Level: 0 }, -1)">
                    <a>MBC </a> &nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li style="display: inline-block; margin: 5px;" *ngFor="let l of levels index as i"
                    (click)="clickThrough(l, i)">
                    <a>{{ l.Name }} </a> &nbsp;<i class="fa fa-angle-right" *ngIf="levels?.length != i + 1"></i>
                </li>
            </ul>
            <div class="filter-search-wrapper"
                style="padding: 10px; background: #fcfcfc; border-bottom: 1px solid #ebebeb;">
                <div class="filter-search">
                    <input placeholder="{{ 'Search' | translate }}" type="text" pInputText [(ngModel)]="searchOrg" />
                </div>
            </div>
            <div class="select-deselect" style="padding: 10px;
                text-align: right;
                font-size: 13px;
                color: #666666;
                border-bottom: 1px solid #ebebeb;">
                <a *ngIf="userLevel === activatedLevel">&nbsp;</a>
                <a style="float: left;" *ngIf="levels?.length !== 0 && userLevel < activatedLevel"
                    (click)="back($event); $event.stopPropagation();"><i class="fa fa-arrow-left"></i> &nbsp; back</a>
                <a *ngIf="!getSelectionState()"
                    (click)="selectOrDeSelectAll($event, false); $event.stopPropagation();">{{ 'Deselect All' | translate }}</a>
                <a *ngIf="getSelectionState()"
                    (click)="selectOrDeSelectAll($event, true); $event.stopPropagation();">{{ 'Select All' | translate }}</a>
            </div>
            <div class="filter__list">
                <cvp-scrollPanel-ul [style]="{ width: '100%', height: '158px' }">
                <li *ngFor="let hir of activatedHierarch | appCallback: filter:'Name':'searchOrg'">
                    <a>
                        <p-checkbox
                            [(ngModel)]="hir.IsChecked"
                            [label]="hir.Name"
                            binary="true">
                        </p-checkbox>
                        <i *ngIf="hir.HasChild" style="color: #ccc; font-weight:bold; float: right;" class="fa fa-chevron-right" (click)="changeLevel($event, hir); $event.stopPropagation();"></i>
                    </a>
                </li>
            </cvp-scrollPanel-ul>
            </div>
        </div>
        <div class="dropdown__footer">
            <a class="btn" (click)="orgDone($event) ; $event.stopPropagation();">
                {{'done_button.title' | translate}}
            </a>
        </div>
    </div>
</div> -->
