<div class="notification-modal">
  <ul>
    <li class="new" *ngFor="let notification of Notifications">
      <div [ngClass]="notification.classes">
        <i class="{{ notification.icon }}"></i>
        {{ decorateMessage(notification.message) }}
        <span><i class="far fa-calendar"></i>{{ notification.date | customdateformat: 'datetime' }}</span>
      </div>
    </li>
    <li *ngIf="Notifications.length == 0" translate>No Notifications Found</li>
  </ul>
</div>
