<div class="ui-chkbox ui-widget" (click)="onClick($event)">
  <div class="ui-helper-hidden-accessible">
    <input type="checkbox" [checked]="checked" (focus)="onFocus()" (blur)="onBlur()" [disabled]="disabled" />
  </div>
  <div
    #box
    [ngClass]="{'ui-chkbox-box ui-widget ui-state-default':true,
      'ui-state-active':checked, 'ui-state-disabled':disabled}"
  >
    <span class="ui-chkbox-icon ui-clickable" [ngClass]="{'pi pi-check':checked}"></span>
  </div>
</div>
