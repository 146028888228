<app-widget-dynamic [rootClass]="rootClass" [styleClass]="styleClass" [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle">
  <ng-container widgetHeader>
    <!-- <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" [routerLink]="routerPrefix"><i class="fal fa-long-arrow-right"></i></a>
    </div> -->
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="records && records.length != 0" widgetArea>
    <div style="text-align: right" [attr.data-can-render]="canRender">
      <br />
      <div class="widget__content">
        <table class="table-reporting">
          <tbody>
            <tr>
              <th style="font-size: 12px; color: #000; font-weight: bold">Report Name</th>
              <th style="font-size: 12px; color: #000; font-weight: bold" *ngFor="let key of header">
                {{ key | translate }}
              </th>
            </tr>
            <tr *ngFor="let rec of records; let even = even"
              [ngStyle]="{'background-color': even ? 'white' : '#f4f5f9'}">
              <td>
                <span style="font-size: 12px">{{ rec.label }}</span>
              </td>
              <td *ngFor="let data of rec.data">
                <span style="font-size: 11px; font-weight: bold; margin-right: 3px">{{ data.output }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="nodata-wrapper" *ngIf="records && records.length == 0">
      <div class="nodata">
        <i class="nodata__icon fal fa-info-circle"></i>
        <p class="nodata__msg">{{ 'No Result Found' | translate }}</p>
      </div>
    </div>
  </ng-container>
</app-widget-dynamic>