import { Component, OnInit, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import HighCharts from '@clearviewshared/highchart/highchart';
import { Subscription, interval } from 'rxjs';
import { WidgetService } from '../widget.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { generatePerformanceTrends } from '@clearviewshared/highchart/helperfunctions';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import WidgetBase from '../widegt.base';
import { I18nService } from '@app/core/i18n.service';
import { IPerformanceTrendResponse } from './types/IPerformanceTrendResponse';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { ExportService } from '@clearviewshared/services/export.service';
import { FilterService } from 'primeng/api';
import { FiltersService } from '@app/shared/services/filters.service';
import { Logger } from '@app/core';
import moment from 'moment';
import { CustomDateFormatPipe } from '@app/shared/pipes/customdateformat.pipe';
const logger = new Logger('PerformanceTrendComponent');
@Component({
  selector: 'dashboard-performance-trend-widget',
  templateUrl: './performance-trend.html',
  styleUrls: ['./performance-trend.scss'],
})
export class PerformanceTrendComponent extends WidgetBase implements OnInit, OnDestroy, OnChanges {
  routerPrefix = '';
  @Input() makeRequest = true;
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() removeWidgetClass = false;
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @ViewChild('containerPerTreWidget') containerPerTreWidget: ElementRef;
  @Input() setDataFrominput: any = '';
  @Input() allowDropDownShow = true;
  @Input() isAllowedTarget = false;

  secondsCounter: any;
  secondsCounterSub: Subscription;
  data: Array<IPerformanceTrendResponse>;
  chartOptions: any = {};
  canRender = false;
  api: Subscription;
  name: string;
  basicColorCode = [
    '#808080',
    '#fc352f',
    '#2066e5',
    '#9f95f8',
    '#7a1935',
    '#000000',
    '#FFA500',
    '#00FF00',
    '#808000',
    '#800000',
  ];
  colorCode = [
    '#8ee223',
    '#8e8014',
    '#da5758',
    '#43f1bc',
    '#af180a',
    '#935c2e',
    '#5d741b',
    '#440bac',
    '#fab475',
    '#d8f93b',
    '#fc352f',
    '#1e6820',
    '#4c0e85',
    '#4897b8',
    '#e37cd2',
    '#90e9eb',
    '#dc23a0',
    '#9ea312',
    '#2eaf',
    '#b210cd',
    '#8d5f77',
    '#33567c',
    '#d6ab02',
    '#3ea9d4',
    '#b30a3a',
    '#9f95f8',
    '#f8dac9',
    '#15cf16',
    '#40d7a3',
    '#4c16f5',
    '#5ea898',
    '#16a7c2',
    '#2a5471',
    '#a8d040',
    '#ac675b',
    '#2066e5',
    '#82a1f3',
    '#c849f4',
    '#6332e6',
    '#80f70f',
    '#c4ac8c',
    '#4596ff',
    '#2e6398',
    '#862f65',
    '#583a94',
    '#a9be91',
    '#6a1910',
    '#7cc09a',
    '#e649bb',
    '#46905d',
    '#9f5197',
    '#473e3b',
    '#a8b4df',
    '#30af97',
    '#4532c6',
    '#8d91a5',
    '#3cc7bf',
    '#f73f56',
    '#b69e78',
    '#dcd69b',
    '#843953',
    '#b41879',
    '#74f180',
    '#8289f5',
    '#c438ea',
    '#fdf364',
    '#5a8930',
    '#bb98bd',
    '#b87354',
    '#4cfeb9',
    '#a82db1',
    '#7c05a4',
    '#9515fd',
    '#7a1935',
    '#4c0b4f',
    '#221ed9',
    '#c2387f',
    '#6aac10',
    '#f7b129',
    '#bd7a98',
    '#1bb5bc',
    '#6f3b3b',
    '#cab25c',
    '#acb9b8',
    '#f92c20',
    '#355f40',
    '#cf754b',
    '#b290c4',
    '#314baf',
    '#544008',
    '#afb23a',
    '#6eea11',
  ];
  private subscriptions: Subscription[] = [];
  chart: any;
  kpiNames: any[] = [];
  constructor(
    public toastService: ToastService,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    private appConfig: AppConfigurationService,
    public i18nService: I18nService,
    public gbfService: GlobalFilterService,
    public filterService: FiltersService,
    public exportService: ExportService,
    public customDatePipe: CustomDateFormatPipe
  ) {
    super(gbfService, translateService);
    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }
      this.secondsCounter = interval(widgetThresholdVal);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    super.ngOnInit();

    this.fillKpiDropDown();

    // this.menuCustom();
    if (this.makeRequest) {
      this.loadWidget();
    } else {
      this.loadWidgetByProp();
    }
    this.subscriptions.push(
      this.cacheService
        .for(this.widgetService.performanceTrendUrl)
        .subscribe((res: Array<IPerformanceTrendResponse>) => {
          if (res) {
            this.data = this.setDataFrominput === '' ? res : this.setDataFrominput;
            // this.data =  res;
            this.initData();
            this.canRender = true;
          }
        })
    );

    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
    }

    // this.secondsCounterSub = this.secondsCounter.subscribe((n: any) => {
    //   // this.loadWidget();
    // });
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }

  async menuCustom() {
    await this.exportService.sendMessageFromGlobalComponent('menu-no-print-export');
  }

  fillKpiDropDown() {
    var kpiConfig = this.appConfig.getByIdentifier('performance_trend_widget_kpis');

    if (kpiConfig && kpiConfig['Fields']) {
      var obj = this.filterService.getFilterObject();
      if (obj) {
        let kpisConf: [] = kpiConfig.Fields?.Config;
        var kpiList = kpisConf?.filter((f) => f['survey_id'] == obj.SurveyVersion)[0];
        if (kpiList) {
          let list: [] = kpiList['kpis'];
          this.name = kpiList['kpis'][0];
          for (var ii of list) {
            this.kpiNames.push({ label: this.translateService.instant(ii), value: ii });
          }
        }
      }
    }
  }

  loadWidgetByProp() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.setDataFrominput) {
      this.data = this.setDataFrominput;
      this.initData();
      this.widgetService.isAllLoaded = true;
      this.canRender = true;
    }
  }

  loadWidget() {
    if (this.name) {
      this.widgetService.isAllLoaded = false;
      this.canRender = false;
      if (this.api) {
        this.api.unsubscribe();
      }
      this.api = this.widgetService.getPerformanceTrend({ apiConfig: this.apiConfig }, this.name).subscribe(
        (res: Array<IPerformanceTrendResponse>) => {
          if (res) {
            this.data = this.setDataFrominput === '' ? res : this.setDataFrominput;

            // this.data = res;
            this.initData();
            this.widgetService.isAllLoaded = true;
            this.canRender = true;
          }
        },
        (error: any) => {
          this.widgetService.isAllLoaded = true;
          this.canRender = true;
        }
      );
    } else {
      logger.error('configuration name not found.');
      this.widgetService.isAllLoaded = true;
      this.canRender = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fillKpiDropDown();
    super.ngOnChanges(changes);
    if (this.makeRequest) {
      this.loadWidget();
    } else {
      this.loadWidgetByProp();
    }
  }

  onKPIChange(event: any) {
    this.loadWidget();
  }

  initData() {
    this.chartOptions = {};
    const monthsList: string[] = [
      this.translateService.instant('Calender_months.jan'),
      this.translateService.instant('Calender_months.feb'),
      this.translateService.instant('Calender_months.mar'),
      this.translateService.instant('Calender_months.apr'),
      this.translateService.instant('Calender_months.may'),
      this.translateService.instant('Calender_months.jun'),
      this.translateService.instant('Calender_months.jul'),
      this.translateService.instant('Calender_months.aug'),
      this.translateService.instant('Calender_months.sep'),
      this.translateService.instant('Calender_months.oct'),
      this.translateService.instant('Calender_months.nov'),
      this.translateService.instant('Calender_months.dec'),
    ];
    const selectedList: string[] = [];
    const seriesData: any = [];
    const checkIfNotThere: any = {};
    const programDate: any = this.appConfig.getByIdentifier(Identifiers.ProgramDate);
    let targetMonthScore: Array<number> = [];
    let startProgramMonth = 0;
    if (programDate) {
      if (programDate.ClientConfig === null) {
        programDate.ClientConfig = programDate;
      }
      if (programDate.ClientConfig) {
        const dateValue: any = this.customDatePipe.transform(
          programDate.ClientConfig.Fields['equal']['value'],
          'date-est'
        );
        console.log(dateValue.split(',')[0]);
        if (dateValue) {
          startProgramMonth = Number(moment(dateValue.split(',')[0]).add(2, 'month').format('M'));
        }
      }
    }
    if (this.data) {
      // this.data.forEach((data: any) => {
      var records: any[] = null;
      var records2: any[] = null;

      if (Array.isArray(this.data) == true) {
        records = this.data;
      } else {
        records = this.data;
      }
      records.forEach((kpi: any, index: number) => {
        if (!checkIfNotThere[kpi.OrgName]) {
          if (index > 9) {
            index = 0;
          }
          if (index < 1) {
            if (Array.isArray(kpi)) {
              kpi[0].Months.forEach((month: any, ind: number) => {
                selectedList.push(monthsList[month.Month - 1] + '-' + month.Year.toString().slice(-2));
              });
            } else {
              kpi.Months.forEach((month: any, ind: number) => {
                targetMonthScore.push(0);
                selectedList.push(monthsList[month.Month - 1] + '-' + month.Year.toString().slice(-2));
              });
            }
          }
          let mapKpiMonths;
          let kpiName = '';
          if (Array.isArray(kpi)) {
            mapKpiMonths = kpi[0].Months.map((km: any) => {
              return km.Score !== null ? parseFloat(km.Score.toFixed(1)) : 0;
            });
            kpiName = kpi[0].OrgName;
          } else {
            mapKpiMonths = kpi.Months.map((km: any) => {
              return km.Score !== null ? parseFloat(km.Score.toFixed(1)) : 0;
            });
            kpiName = kpi.OrgName;
          }
          // const mapKpiMonths = kpi.Months.map((km: any) => {
          //   return km.Score !== null ? parseFloat(km.Score.toFixed(1)) : km.Score;
          // });

          seriesData.push({
            marker: {
              enabled: true,
            },
            name: this.translateService.instant(kpiName),
            data: mapKpiMonths,
            events: {
              click: function (event: any) {},
              legendItemClick: function (event: any, b: any, c: any) {},
            },
          });
          checkIfNotThere[kpi.OrgName] = kpi;
          //Added Target line
          if (index === records.length - 1 && this.isAllowedTarget) {
            if (startProgramMonth) {
              const montIndex = kpi.Months.findIndex((month: any) => {
                return month.Month == startProgramMonth;
              });
              const target = kpi.Target ? kpi.Target : 0;
              targetMonthScore.forEach((score, index) => {
                if (montIndex <= index) {
                  targetMonthScore[index] = target;
                }
              });
            }
            seriesData.push({
              marker: {
                enabled: true,
              },
              name: this.translateService.instant('Target'),
              data: targetMonthScore.length ? targetMonthScore : new Array(12).fill(0),
              events: {
                click: function (event: any) {},
                legendItemClick: function (event: any, b: any, c: any) {},
              },
              dashStyle: 'dot',
            });
          }
        }
      });
      // let colors = this.getRandomColor();
      let colors = this.data.length <= 10 ? this.basicColorCode : this.colorCode;
      this.chartOptions = generatePerformanceTrends(
        seriesData,
        selectedList,
        this.i18nService,
        colors,
        this.translateService.instant(this.widgetTitle)
      );
      // tslint:disable-next-line:max-line-length
      this.chartOptions['plotOptions'] = {
        areaspline: {
          fillOpacity: 0.2,
        },
      };
      if (this.containerPerTreWidget) {
        this.chart = HighCharts['Reflecx'].createOrUpdate(
          this.chart,
          this.containerPerTreWidget.nativeElement,
          this.chartOptions
        );
      }
    }
  }
  private getRandomColor() {
    const colorPattern = [];
    for (let i = 0; i < this.data.length; i++) {
      const randomColor = Math.floor(Math.random() * (16777215 + i + 2)).toString(16);
      colorPattern.push(`#${randomColor}`);
    }
    if (this.isAllowedTarget) colorPattern.push('#000000');
    return colorPattern;
  }
}
