<div class="modal-form">
  <div class="row"><h3>Time Zone Selection</h3></div>
  <div class="row">
    <div class="col-left"><label>Time Zone</label></div>
    <div class="col-right">
      <!-- <mat-select
        data-live-search="true"
        title="Select"
        selectpicker
        toggle-dropdown
        data-container="body"
        data-size="6"
        data-width="100%"
      >
        <mat-option selected>Option 01</mat-option>
        <mat-option>Option 02</mat-option>
        <mat-option>Option 03</mat-option>
        <mat-option>Option 04</mat-option>
      </mat-select> -->
    </div>
  </div>
  <div class="row">
    <div class="col-left"><label>Time Zone</label></div>

    <div class="col-right">
      <div class="info">
        <p>June 6, 2018 <span>3:30 PM</span></p>
      </div>
    </div>
  </div>
</div>
