import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ChartBase } from '../chart.base';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import ExportingData from 'highcharts/modules/export-data';
import { ReportGeneratorHelperService } from '../../report-generator-helper.service';
import * as HighCharts from 'highcharts';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/core';
import { ToastService } from '@app/shared/services/toast/toast.service';

Exporting(HighCharts);
OfflineExporting(HighCharts);
ExportingData(HighCharts);

@Component({
  selector: 'appc-report-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent extends ChartBase implements OnInit {
  @Output() stepEmitter = new EventEmitter();
  fields: any = [];
  @ViewChild('pie', { read: ElementRef, static: true }) pie: ElementRef;
  chart: any;
  chunk = 2;
  nextlabel = '';
  backlabel = '';
  labels: any = [];
  fieldWiseData: any = [];
  onlyChecked: any = [];
  constructor(
    private rgHelper: ReportGeneratorHelperService,
    public translateService: TranslateService,
    public toaster: ToastService,
    public i18nService: I18nService
  ) {
    super(translateService);
  }
  ngOnInit(): void {
    super.OnInit();
    this.nextlabel = this.translateService.instant('Next');
    this.backlabel = this.translateService.instant('Back');
    const data = this.records.map((record: any) => {
      var res: any = {};
      this.fields.forEach((field: any) => {
        const val = field.value;
        let dval =
          typeof record[field.field] === 'number'
            ? parseFloat(record[val].replace(/,/g, ''))
            : parseFloat(record[val].replace(/,/g, ''));
        if (isNaN(dval) || dval == -999999) {
          dval = 0;
        }
        res[val] = dval;
      });
      const label = this.getLabel(record);

      if (this.labels.indexOf(label) === -1) {
        this.labels.push(label);
      }
      res.name = label;
      return res;
    });
    this.fieldWiseData = data;
    if (this.module === 'RG') {
      if (this.labels.length > 0) {
        this.selectedField = this.labels[0];
        this.rgHelper.chartConfig = this.selectedField;
        //this.makeChart({ value: this.selectedField }, this.i18nService);
      }
    } else {
      this.selectedField = this.chartConfig;
    }
  }
  onFieldChange(i18nService: I18nService) {
    this.makeChart(i18nService);
  }
  nextOrBack(e: any) {
    if (this.step === 'configuration') {
      this.onlyChecked = this.fields.filter((field: any) => field.checked);
      if (this.onlyChecked.length === 0) {
        this.toaster.info(this.translateService.instant('Please check at least one field.'));
        return;
      }
      this.step = 'graph';
      this.rgHelper.chartConfig = this.onlyChecked;
      this.selectedField = this.labels[0];
      this.makeChart(this.i18nService);
    } else {
      this.step = 'configuration';
    }
    this.stepEmitter.emit(this.step);
  }
  makeChart(i18nService: I18nService) {
    const fields = this.onlyChecked;
    const selected = this.fieldWiseData.find((x: any) => x.name == this.selectedField);
    const dataForChartModel: any = [];
    fields.forEach((x: any) => {
      dataForChartModel.push({ name: x.label, y: selected[x.value] });
    });
    if (dataForChartModel.length < this.Max_Level) {
      const ModelData: any = this.makeModel(dataForChartModel, this.i18nService);
      this.chart = null;
      this.chart = new HighCharts.Chart(this.pie.nativeElement, ModelData);
    } else {
      this.chart = new HighCharts.Chart(this.pie.nativeElement, {});
      this.toaster.info(this.translateService.instant(this.Max_Limit_Error_Key));
    }
  }
  makeModel(data: any, i18nService: I18nService) {
    const title = this.selectedField;
    const dataModel = {
      chart: {
        plotShadow: false,
        type: 'pie',

        /*events: {
          load: function () {
            let categoryHeight = 50;
            this.update({
              chart: {
                height: categoryHeight * this.pointCount + (this.chartHeight - this.plotHeight),
              },
            });
          },
        },*/
      },
      title: {
        text: `${title}`,

        style: {
          fontSize: '16px',
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        //  pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        formatter: function () {
          return `<span style="color: ${this.point.color};text-align:right;">  ${
            this.point.name
          }</span>: <b>  ${i18nService.transformNumber(this.point.y.toFixed(1))}</b><br/>`;
        },
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `<span style="color: ${this.point.color};text-align:right;">  ${
                this.point.name
              } </span>: <b>  ${i18nService.transformNumber(
                this.point.y.toFixed(1)
              )}</b><br/><span style="color: white">Line Break</span>`;
            },
          },
        },
      },
      series: [
        {
          name: `${this.selectedField}`,
          colorByPoint: true,
          data: data,
        },
      ],
    };
    return dataModel;
  }
}
