<ng-container *ngIf="show">
  <app-widget-dynamic
    [rootClass]="rootClass"
    [styleClass]="styleClass"
    [clickThrough]="clickThrough"
    [widgetTitle]="widgetTitle"
  >
    <ng-container widgetHeader>
      <div class="widget-node" *ngIf="clickThrough">
        <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
      </div>
      <div class="widget-node">
        <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
      </div>
    </ng-container>
    <ng-container widgetArea>
      <div class="valign__content">
        <div class="valign__graph">
          <div class="widget__content" [attr.data-can-render]="canRender">
            <div class="widget__graph">
              <div width="100%" style="width: 100%">
                <div #chart></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </app-widget-dynamic>
</ng-container>
