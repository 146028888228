import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { WidgetService } from '../widget.service';
import HighCharts from '@clearviewshared/highchart/highchart';
import { FiltersService } from '@app/shared/services/filters.service';
import { CacheService } from '@app/shared/services/cache.service';
import { TranslateService } from '@ngx-translate/core';
//import { generateFrftRootCauses } from '@clearviewshared/highchart/helperfunctions';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { I18nService } from '@app/core';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Identifiers } from '@app/shared/services/app.config.type';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { GlobalFilterApiService } from '@app/shared/module/global-filters/global-filters.api.service';
import { GlobalFilterHelperService } from '@app/shared/module/global-filters/global-filters-helper.service';
import {
  DynamicFilters,
  FilterTypes,
  GlobalFilterRouter,
} from '@app/shared/module/global-filters/global-filter-router';
import moment from 'moment-timezone';
import {
  FrftByServiceAdvisorTechnicianStatus,
  IFrftByServiceAdvisorTechnician,
  IFrftByServiceAdvisorTechnicianRatingData,
  IFrftByServiceAdvisorTechnicianSeries,
} from '../widget-interface';
import { genMonthArrFromCurrMonth } from '@app/core/helpers';

@Component({
  selector: 'frft-by-service-advisor-technician',
  templateUrl: './frft-by-service-advisor-technician.component.html',
  styleUrls: ['./frft-by-service-advisor-technician.component.css'],
})
export class FrftByServiceAdvisorTechnicianComponent extends WidgetBase implements OnInit, OnDestroy {
  @ViewChild('containerFrftRootCauses', { read: ElementRef, static: true }) containerFrftRootCauses: ElementRef;

  @Input() showToggle = false;
  @Input() module = 'frft';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';

  routerPrefix = '';
  canRender = false;
  isChecked = false;
  isToggleDisabled = false;
  sentiment: any;
  data: IFrftByServiceAdvisorTechnician[];
  isAspectCategorySelected = true;
  permissions: NgxPermissionsObject;
  chart: any;
  api: Subscription;
  months: any[] = [];
  private subscriptions: Subscription[] = [];
  response: any[];
  selecteditem: boolean = false;
  type: string = 'A';

  showsWidgets: boolean = false;
  constructor(
    private router: Router,
    public widgetService: WidgetService,
    private cacheService: CacheService,
    private filterService: FiltersService,
    private gbfRouter: GlobalFilterRouter,
    private translateService: TranslateService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService,
    public ngxPermission: NgxPermissionsService,
    public gbfService: GlobalFilterService,
    public gbfapiservice: GlobalFilterApiService,
    public gbfHelperService: GlobalFilterHelperService
  ) {
    super(gbfService, translateService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setAspectCatPreset();
    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.frftOverviewUrl).subscribe((res: any) => {
        if (res) {
          this.initData();
          this.canRender = true;
        }
      })
    );

    this.subscriptions.push(
      this.widgetService.update.subscribe(() => {
        this.setAspectCatPreset();
        this.loadWidget();
      })
    );

    this.gbfService.gbfTabChange.subscribe(() => {
      this.isToggleDisabled = false;
    });
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    this.loadView();

    if (this.api) {
      this.api.unsubscribe();
    }
    if (this.module === 'frft') {
      this.api = this.widgetService.getfrftServiceAdvisorTechnician(this.type).subscribe((res: any) => {
        if (res) {
          this.data = res;
          this.canRender = true;
          this.widgetService.isAllLoaded = true;
          this.getmappedvalues(res);
          this.initData();
        }
      });
    }
  }

  toggleChange() {
    if (this.selecteditem == false) {
      this.type = 'A';
      this.loadWidget();
    } else {
      this.type = 'T';
      this.loadWidget();
    }
  }

  setAspectCatPreset() {
    const aspectCategory = this.filterService.getAspectCategory();
    if (aspectCategory.label === 'All Categories') {
      this.isAspectCategorySelected = false;
      this.isChecked = false;
    } else {
      this.isAspectCategorySelected = true;
      this.isChecked = false;
    }
  }
  getmappedvalues(response: any) {
    this.response = response.response_question;
  }
  disableCtrl() {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.api) {
      this.api.unsubscribe();
    }
  }

  GetFrftRootCausesTranslation(key: string) {
    return this.translationHelper.GetFrftRootCausesTranslation(key);
  }

  private initData() {
    const title = 'Frft Root Causes';
    //let monthsList = [];
    var filter = this.gbfService.getFilterObject();

    let monthWithKeys = genMonthArrFromCurrMonth(
      new Date(filter['EndDate']).getMonth() + 1,
      new Date(filter['EndDate']).getFullYear()
    );
    //let monthsList = monthWithKeys.map((m: any) => this.translateService.instant(m.name + ' ' + m.year));
    let monthsList = monthWithKeys.map((m: any) => this.translateService.instant(m.name) + ' ' + m.year);
    const FrftRootCausesSeries: IFrftByServiceAdvisorTechnicianSeries[] = [];

    this.response.forEach((responseItem: any) => {
      var options = responseItem.option;
      var month = false;
      responseItem.data = responseItem.data.sort((f: any) => f.year);
      let monthData: any = new Array(12).fill(null);
      monthWithKeys.forEach((m: any, i: number) => {
        var obj = responseItem.data.filter((f: any) => f.month == m.key && f.year == m.year);
        if (obj.length > 0) {
          month = obj[0].month == m.key && obj[0].year == m.year;
        }
        if (obj && obj.length > 0) monthData[i] = obj[0]['count'];
      });
      const seriesItem: IFrftByServiceAdvisorTechnicianSeries = {
        name: options,
        nameText: options,
        data: monthData,
        count: monthData,
      };
      FrftRootCausesSeries.push(seriesItem);
    });

    const data = {
      xAxis: monthsList,
      yAxisTitle: title,
      yFormatter: '%',
      values: FrftRootCausesSeries,
    };

    const point = {
      events: {
        click: (e: any, b: any, c: any) => {
          const url = this.module === 'frft' ? '/insights/frft/rootcause' : '/insights/frft/rootcause';
          const viewableParams: Array<DynamicFilters> = [];
          this.gbfRouter.navigate(
            [url],
            {
              queryParams: {
                status: FrftByServiceAdvisorTechnicianStatus[e.point.series.userOptions.nameText],
                date: this.getDate(e.point.category),
              },
            },
            { key: url, filters: viewableParams },
            () => {}
          );
        },
      },
    };

    const chartOptions = this.generateFrftRootCauses(
      data,
      point,
      // this.translateService.instant('frft_root_causes.title'),
      this.translateService.instant('FRFT Root Causes'),
      this.i18nService
    );
    this.chart = HighCharts['Reflecx'].createOrUpdate(
      this.chart,
      this.containerFrftRootCauses.nativeElement,
      chartOptions
    );
  }
  private getDate(param: any) {
    const val = param.split('-');
    const result: any = this.months.find((x: any) => x.value == val[0]);
    return result.key + '-' + val[1];
  }

  private generateFrftRootCauses(data: any, point: any, yaxisTitle: any, i18nService: I18nService = null): any {
    const chartOptions: any = {
      colors: ['#3bb001', '#fd7e14', '#dc3545', '#839588', '#141680', '#f2f757', '#41fade'],
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '', // Empty by default, will be updated if no data is found
        style: {
          fontSize: '20px', // Set the font size to 20px
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: data.xAxis,
      },
      yAxis: {
        min: 0,
        max: 100,
        tickInterval: 20,
        labels: {
          formatter: function () {
            return this.value + data.yFormatter;
          },
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          pointWidth: 50, // Adjust the width of the columns as needed
        },
        series: {
          point: point,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y > 0 ? `${i18nService.transformNumber(this.y.toFixed(1))}` + `%` : '';
            },
          },
        },
      },
      series: data.values,
    };

    return chartOptions;
  }
  loadView() {
    const mainNodeLevel = this.gbfService.authorizationData.MainNodeLevel;
    const selectedOrgLevel = this.gbfService.getFilterObject().OrgLevel;

    this.showsWidgets = selectedOrgLevel >= mainNodeLevel;
    return this.showsWidgets;
  }
}
