import { Component, Input } from '@angular/core';

@Component({
  selector: 'cvp-widget-dropdown',
  template: `
    <div class="widget-dropdown">
      <label *ngIf="label">{{ label }}</label>
      <div class="dropdown-container">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class WidgetDropdownComponent {
  @Input() label: string;
  constructor() {}
}

export class WidgetDropdownModule {}
