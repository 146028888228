import { Component, OnInit, ViewChild, ElementRef, SimpleChanges, OnDestroy, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '@app/shared/services/cache.service';
import { WidgetService } from '../widget.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { FiltersService } from '@app/shared/services/filters.service';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { IFrftCustomerCommentsResponse, IFrftCustomerComments } from './types/IFrftCustomerCommentsResponse';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { Router } from '@angular/router';

@Component({
  selector: 'frft-customer-comments-widget',
  templateUrl: './frft-customer-comments.html',
  styleUrls: ['./frft-customer-comments.scss'],
})
export class FrftCustomerCommentsComponent extends WidgetBase implements OnInit, OnDestroy {
  routerPrefix = '';
  @ViewChild('scrollBar') scrollBar: any;
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = 'Frft Customer Comments';
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  ProgramSubscribtion: Subscription;
  FilterSubscribtion: Subscription;
  resolutions: any;
  isMobile = window.innerWidth < 1024;
  data: IFrftCustomerCommentsResponse = {
    CustomerComments: [],
  };
  response: IFrftCustomerComments[] = [];
  chartModel: any = {};
  chart: any;
  canRender = false;
  api: Subscription;
  permissions: NgxPermissionsObject;
  hasCommentListPermission: boolean = false;
  clickThroughReport: boolean;

  private subscriptions: Subscription[] = [];
  constructor(
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public gbfService: GlobalFilterService,
    public toastService: ToastService,
    private filterService: FiltersService,
    public ngxPermission: NgxPermissionsService,
    private translationHelperService: TranslationHelperService,
    private router: Router
  ) {
    super(gbfService, translateService);
  }

  ngOnInit() {
    this.permissions = this.ngxPermission.getPermissions();
    this.setPermissions();
    super.ngOnInit();
    setTimeout(() => {
      this.scrollBar.moveBar();
    }, 20);

    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.frftcustomercomments).subscribe((res: IFrftCustomerCommentsResponse) => {
        if (res) {
          this.data = res;
          this.initData();
          this.canRender = true;
        }
      })
    );

    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
      this.subscriptions.push(
        this.gbfService.gbfOnApplyFilter.subscribe(() => {
          this.loadWidget();
        })
      );
    }
  }

  GetFormattedDate(date: any) {
    return this.translationHelperService.GetFormattedDate(date);
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    // this.dispGraph = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getFrftCustomerComments().subscribe({
      next: (res: Array<any>) => {
        if (res) {
          this.getmappedrecords(res);
          this.initData();
          this.canRender = true;
          this.widgetService.isAllLoaded = true;
        }
      },
      complete: () => {
        this.canRender = true;
        // this.dispGraph = true;
        this.widgetService.isAllLoaded = true;
      },
      error: () => {
        this.canRender = true;
        // this.dispGraph = false;
        this.widgetService.isAllLoaded = true;
      },
    });
  }
  commentlistclick(e: any) {
    this.router.navigate(['/insights/voc/commentlist'], {
      queryParams: { question: '6,6a' },
    });
  }
  getmappedrecords(res: any) {
    this.response = res.voc.map((item: any) => ({
      Title: item.customer_name,
      Label: 'LG',
      QuestionCode: item.question_code,
      VariablesAlerttag: '',
      OptionsAlerttag: '',
      QuestionComments: item.question_comments,
      VariableComments: '',
      OptionComments: '',
      CompletedCreatedOn: item.completed_created_on,
      CreatedOn: item.completed_created_on,
    }));
  }
  initData() {
    if (this.data && this.response && this.response.length > 0) {
      this.response.map((value: IFrftCustomerComments, index: number, array: IFrftCustomerComments[]) => {
        if (value && value?.Title) {
          const titleArr = value.Title.split(' ');
          if (titleArr.length > 0) {
            switch (titleArr.length) {
              case 1:
                value.Label = titleArr[0].substr(0, 1);
                break;
              case 2:
                value.Label = titleArr[0].substr(0, 1) + titleArr[1].substr(0, 1);
                break;
              case 3:
                value.Label = titleArr[0].substr(0, 1) + titleArr[1].substr(0, 1) + titleArr[2].substr(0, 1);
                break;
              default:
                value.Label = titleArr[0].substr(0, 1) + titleArr[1].substr(0, 1) + titleArr[2].substr(0, 1);
                break;
            }
          }
        }
        return value;
      });
    }
    this.canRender = true;
  }
  refresh(e: any) {
    e.preventDefault();
    this.loadWidget();
  }
  setPermissions() {
    this.clickThroughReport = this.permissions[Permission.REPORT_TEXTANALYTIC_COMMENTLIST] ? true : false;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }
}
