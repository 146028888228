<appc-page-layout
  [pageTitle]="'Dynamic Topic Search' | translate"
  [showFilter]="true"
  [showTabs]="true"
  [hideSomeFilters]="true"
  (FilterApply)="applyFilters()"
  [showVocFilters]="true"
  [showCategoryFilters]="false"
  (TabSelect)="onTabSelect($event)"
>
  <appc-widget-xl [styleClass]="'fullpage--widget no-padding'" [widgetTitle]="''">
    <div class="ds__filters">
      <div class="filter-item">
        <!-- <label class="item__label" for="" translate>Organization: Region</label>
                <label class="item__label" for="" translate>Sentiment: All</label> -->

        <ul class="tags-list">
          <li>
            <span>{{ 'Organization.title' | translate }}:</span> {{ Organization | translate }}
          </li>
          <li>
            <span>{{ 'Sentiment.title' | translate }}:</span> {{ qureyStatus ? this.qureyStatus : ('All' | translate) }}
          </li>
          <li>
            <span>{{ 'Category' | translate }}:</span> {{ 'All' | translate }}
          </li>
        </ul>
      </div>
    </div>
    <div class="dynamic-topic-search">
      <aside class="ds-drag-column ds-drag-column">
        <ng-container *ngIf="isDropFixed">
          <ul class="drag__list">
            <ng-container *ngFor="let item of drag">
              <li class="list__item list__item--drag disabled">
                <a title="{{ dynamicDescription(item.name) }}" translate
                  >{{ item.name.toLowerCase() + '.title' }}
                  <i class="far fa-angle-right action"></i>
                </a></li
            ></ng-container>
          </ul>
        </ng-container>
        <ng-container *ngIf="!isDropFixed">
          <ul cdkDropList cdkDropListConnectedTo="dropItems" class="drag__list">
            <ng-container *ngFor="let item of drag">
              <li
                class="list__item list__item--drag disabled"
                [ngClass]="{
                  disabled: item.isSelected
                }"
                cdkDrag
                [cdkDragData]="item.name"
                [cdkDragDisabled]="item.isSelected"
              >
                <a title="{{ dynamicDescription(item.name) }}" translate
                  >{{ item.name.toLowerCase() + '.title' }}
                  <i
                    class="far fa-angle-right action"
                    (click)="$event.stopPropagation(); onDrop($event, 'click', item.name)"
                  ></i>
                </a></li
            ></ng-container>
          </ul>
        </ng-container>
      </aside>
      <div class="ds-drop-column">
        <div
          class="drag-content"
          id="dropItems"
          cdkDropList
          cdkDropListOrientation="horizontal"
          (cdkDropListDropped)="onDrop($event, 'drop', '')"
        >
          <ng-container *ngIf="drop.length == 0">
            <div class="drag__msg">
              <i class="fal fa-arrows-alt"></i>
              <p translate>To create table columns, drag & drop required fields here.</p>
            </div>
          </ng-container>
          <ul class="drop-list">
            <li
              class="list__item list__item--drop"
              *ngFor="let item of drop; let i = index"
              cdkDragBoundary=".drop-list"
              cdkDrag
            >
              <ng-container *ngIf="item.name === 'INPUT'">
                <!-- (mouseover)="onFocus()" (mouseout)="onFocus()" #input1 -->
                <a class="input-holder" translate>
                  <input
                    type="text"
                    [(ngModel)]="drop[i].value"
                    (keydown.space)="$event.preventDefault()"
                    (keydown.enter)="$event.preventDefault()"
                    (keydown.tab)="$event.preventDefault()"
                    (change)="OnTextChange(drop[i].value, i)"
                    (mouseover)="onFocus($event)"
                    id="input-{{ i }}"
                /></a>
                <i class="fal fa-times" (click)="removeSelectedAction(item.name, i)"></i>
                <ng-container *ngIf="drop[i].value.length > 50"
                  ><span style="color: red">{{ 'Input Text Should be 50 or less than' | translate }}</span>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="item.name != 'INPUT'">
                <a translate>{{ item.name.toLowerCase() + '.title' }}</a>
                <i class="fal fa-times" (click)="removeSelectedAction(item.name, i)"></i>
              </ng-container>
            </li>
          </ul>
        </div>

        <div class="draggable-control">
          <a
            class="btn"
            [ngClass]="{
              'btn--disabled': drop.length == 0
            }"
            (click)="searchAll('search')"
            >{{ 'Search All' | translate }}</a
          >
          <a class="btn btn--cancel" (click)="removeAll()">{{ 'clear.all.title' | translate }}</a>
        </div>
        <!--<div>-->
        <!--<span>-->
        <!--<span>friendly <span>12</span> <span>14</span> <span>20</span></span>-->
        <!--</span>-->
        <!--</div>-->
        <div class="search-result" *ngIf="data.length !== 0">
          <h2>{{ 'Result' | translate }} ({{ totalSize }})</h2>
          <ul
            infiniteScroll
            [scrollWindow]="false"
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="100"
            (scrolled)="onScroll()"
          >
            <li *ngFor="let value of data">
              <a>
                <span>{{ validateOutput(value.keyword) }}</span>
                <span class="pills-container">
                  <span
                    class="pills positive"
                    [routerLink]="[isVoc ? '/insights/voc/commentlist' : '/insights/rm/reviewlist']"
                    [queryParams]="{ status: 'Positive', topic: value.keywordWithSpecialCharacters, isNonCategoryClickThrough: true }"
                  >
                    <span *ngIf="value.SentimentWiseCount['Positive'] && value.SentimentWiseCount['Positive'] > 0">{{
                      value.SentimentWiseCount['Positive']
                    }}</span>
                    <span *ngIf="!value.SentimentWiseCount['Positive'] || value.SentimentWiseCount['Positive'] == 0"
                      >0</span
                    >
                  </span>
                  <span
                    class="pills neutral"
                    [routerLink]="[isVoc ? '/insights/voc/commentlist' : '/insights/rm/reviewlist']"
                    [queryParams]="{ status: 'Neutral', topic: value.keywordWithSpecialCharacters, isNonCategoryClickThrough: true }"
                  >
                    <span *ngIf="value.SentimentWiseCount['Neutral'] && value.SentimentWiseCount['Neutral'] > 0">{{
                      value.SentimentWiseCount['Neutral']
                    }}</span>
                    <span *ngIf="!value.SentimentWiseCount['Neutral'] || value.SentimentWiseCount['Neutral'] == 0"
                      >0</span
                    >
                  </span>
                  <span
                    class="pills negative"
                    [routerLink]="[isVoc ? '/insights/voc/commentlist' : '/insights/rm/reviewlist']"
                    [queryParams]="{ status: 'Negative', topic: value.keywordWithSpecialCharacters, isNonCategoryClickThrough: true }"
                  >
                    <span *ngIf="value.SentimentWiseCount['Negative'] && value.SentimentWiseCount['Negative'] > 0">{{
                      value.SentimentWiseCount['Negative']
                    }}</span>
                    <span *ngIf="!value.SentimentWiseCount['Negative'] || value.SentimentWiseCount['Negative'] == 0"
                      >0</span
                    >
                  </span>
                  <span
                    *ngIf="includeUnassigend === true"
                    class="pills unassigned"
                    [routerLink]="[isVoc ? '/insights/voc/commentlist' : '/insights/rm/reviewlist']"
                    [queryParams]="{ status: 'Unassigned', topic: value.keywordWithSpecialCharacters, isNonCategoryClickThrough: true }"
                  >
                    <span
                      *ngIf="value.SentimentWiseCount['Unassigned'] && value.SentimentWiseCount['Unassigned'] > 0"
                      >{{ value.SentimentWiseCount['Unassigned'] }}</span
                    >
                    <span *ngIf="!value.SentimentWiseCount['Unassigned'] || value.SentimentWiseCount['Unassigned'] == 0"
                      >0</span
                    >
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="search-result" *ngIf="data.length == 0">
          <h2 translate>No Result Found</h2>
        </div>
      </div>
    </div>
    <!-- <div class="widget__footer">
            <a class="btn" [ngClass]="{
                'btn--disabled': data.length == 0}"
                (click)="getDynamicTopicsExport()">{{'export_excel_button.title' | translate}}</a>
        </div> -->
  </appc-widget-xl>
</appc-page-layout>
