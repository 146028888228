import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalFilterConfigService } from './global-filters-config.service';
import { DynamicFilters, FilterTypes, GlobalFilterRouter } from './global-filter-router';

@Component({
  selector: 'app-gbf-dynamic-filter',
  templateUrl: 'global-dynamic-filter-component.html',
})
export class GlobalDynamicFilterComponent implements OnInit {
  show: boolean = false;
  filters: Array<DynamicFilters> = new Array<DynamicFilters>();
  viewAblefilters: Array<DynamicFilters> = new Array<DynamicFilters>();
  constructor(public gbfConfig: GlobalFilterConfigService, public gbfRouter: GlobalFilterRouter) {}

  ngOnInit(): void {
    const filters = this.gbfRouter.getFiltersByPage();
    this.viewAblefilters = filters.filter((x: DynamicFilters) => x.type === FilterTypes.Dynamic);
    this.filters = filters;
  }
  toggle(e: any, config: any) {
    this.show = false;
  }
}
