import { Component, OnInit, ViewChild, EventEmitter, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { I18nService, AuthenticationService } from '@app/core';
import { DynamicDialogRef } from '@primeng';
import { DialogService } from '@primeng';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { merge } from 'rxjs';
import { AccountSettingService } from './account-setting.service';
import { UserSettingsDTO } from '@app/core/types/user';
import { NgxPermissionsService } from 'ngx-permissions';
import { Identifiers } from '@app/shared/services/app.config.type';
import { TourEmitterService } from '@app/shared/services/tourEmitterService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BreadCrumbService } from '@app/shared/services/breadcrumb.service';
// import custom validator to validate that password and confirm password fields match
import { MustMatch } from '@app/core/helpers';
import { identityServerAngularConfig } from '@env/environment';
import { LoaderService } from '@app/core/loader.service';

interface Language {
  DisplayName: string;
  Code: string;
}

@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.scss'],
})
export class AccountSettingComponent implements OnInit {
  @ViewChild('header', { static: true, read: TemplateRef }) headerRef: any;
  @ViewChild('footer', { static: true, read: TemplateRef }) footerRef: any;
  @ViewChild('content', { static: true, read: TemplateRef }) contentRef: any;
  activeType = 'profile';
  user: any = {};
  currentUser: any;
  arrLanguages: any = [];
  languages_dropdown: Language[];
  markets: any;
  defaultLanguage: any;
  portalLanguages: any = [];
  module: string;
  selectedLanguage: Language;
  languageKey = 'language';
  image: any;
  mode = false;

  registerForm: FormGroup;
  submitted = false;
  permissions: any;
  strengthColor = '';
  isStrongStrength = false;
  passwordPolicy: any = null;
  isPasswordPolicyError = false;
  passwordPolicyError = '';
  lengthMessage = '';
  lastUsedPasswordMessage = '';
  twoFactorAuthMode: boolean;
  twoFactorAuthStatus = false;
  is2faAccountLevel = false;
  is2faClientLevel = false;
  isSSO = false;

  constructor(
    public service: AuthenticationService,
    public ref: DynamicDialogRef,
    private toastService: ToastService,
    private i18nService: I18nService,
    private accountService: AccountSettingService,
    private authService: AuthenticationService,
    public dialogService: DialogService,
    public ngxPermission: NgxPermissionsService,
    public tourEmitterService: TourEmitterService,
    private formBuilder: FormBuilder,
    public translateService: TranslateService,
    private breadcrumbService: BreadCrumbService,
    private changeDetectorRef: ChangeDetectorRef,
    private loaderService: LoaderService
  ) {
    this.permissions = ngxPermission.getPermissions();
    this.is2faAccountLevel = this.permissions[Identifiers.TWO_FACTOR_ACCOUNT_LEVEL_SETTINGS] ? true : false;
    this.is2faClientLevel = this.permissions[Identifiers.TWO_FACTOR_CLIENT_LEVEL_SETTINGS] ? true : false;
    var authData = this.authService.getUser();
    if (authData) this.isSSO = authData?.IsSSO ? true : false;

    this.registerForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: MustMatch('newPassword', 'confirmPassword'),
      }
    );
    this.currentUser = this.service.getUser();
    if (this.currentUser.UserSettings !== null) {
      this.mode = this.currentUser.UserSettings.TourGuide;
    }

    if (this.permissions[Identifiers.TWO_FACTOR_ACCOUNT_LEVEL_SETTINGS] ? true : false) {
      this.getTwoFactorStatus(this.currentUser.Id);
    }
    if (this.is2faAccountLevel && !this.is2faClientLevel) {
      this.getTwoFactorStatus(this.currentUser.Id);
    }

    this.user = {
      Id: this.service.getUser().Id,
      OldPassword: '',
      NewPassword: '',
      ConfirmPassword: '',
    };
    //     const url = this.router.url;
    //     this.module = url.substr(url.lastIndexOf('/') + 1, url.length - url.lastIndexOf('/'));
    //     const onNavigationEnd = this.router.events.pipe(
    //       filter(event => event instanceof NavigationEnd)
    //     );
    //  // Change page title on navigation or language change, based on route data
    //     merge(this.translateService.onLangChange, onNavigationEnd)
    //     .pipe(
    //       map(() => {
    //         let route = this.activatedRoute;
    //         while (route.firstChild) {
    //           route = route.firstChild;
    //         }
    //         return route;
    //       }),
    //       filter((route: any) => route.outlet === 'primary'),
    //       mergeMap((route: any) => route.data)
    //     )
    //     .subscribe((event: any) => {
    //       const title = event.title;
    //       if (title) {
    //         this.titleService.setTitle(this.translateService.instant(title));
    //       }
    //     });
    this.languages_dropdown = [
      {
        DisplayName: 'English',
        Code: 'en-CA',
      },
      {
        DisplayName: 'French',
        Code: 'fr-CA',
      },
    ];
    this.portalLanguages = this.currentUser.ClientInfo.PortalLanguages || [];
    if (!this.portalLanguages || this.portalLanguages.length === 0) {
      this.portalLanguages.push({
        Name: 'English',
        DisplayName: 'English',
        Code: 'en-CA',
      });
    }
    this.markets = this.currentUser.Markets || [];
    this.portalLanguages = this.portalLanguages.map((lang: any) => {
      if (this.currentUser.DefaultLanguage === lang.Code) {
        this.defaultLanguage = lang;
        this.defaultLanguage.MarketId = this.currentUser.MarketId;
      }
      lang.MarketId = this.currentUser.MarketId;
      this.arrLanguages.push(lang);
      return lang;
    });
    this.portalLanguages.sort((a: any, b: any) => {
      if (a.DisplayName > b.DisplayName) {
        return 1;
      }
      if (a.DisplayName < b.DisplayName) {
        return -1;
      }
      return 0;
    });
    const language = localStorage.getItem(this.languageKey);
    if (language != null) {
      const currentLanguage = this.portalLanguages.find((x: { Code: string }) => x.Code === language);
      if (currentLanguage !== undefined) {
        this.defaultLanguage = currentLanguage;
      }
    }
    this.checkPasswordPolicy();
  }

  checkPasswordPolicy() {
    this.accountService.getPasswordPolicy(this.currentUser.Id).subscribe((res: any) => {
      if (res) {
        this.passwordPolicy = res;
        if (this.passwordPolicy !== null) {
          this.lengthMessage = FormatString(
            this.translateService.instant(this.translateService.instant('change_password_message4')),
            this.passwordPolicy.Length
          );
          this.lastUsedPasswordMessage = FormatString(
            this.translateService.instant(this.translateService.instant('change_password_message5')),
            this.passwordPolicy.LastUsedPassword
          );
        }
      }
    });
  }

  ngOnInit() {}

  validatePasswordPolicy() {
    if (this.passwordPolicy !== null) {
      const newPassword = this.registerForm.controls['newPassword'].value;
      if (!this.verifyBlankSpece(newPassword)) {
        return;
      }

      if (this.passwordPolicy.Length > 0) {
        this.passwordPolicyError = FormatString(
          this.translateService.instant('new_password_length.validationMessage'),
          this.passwordPolicy.Length
        );
        this.verifyLength(newPassword);
      } else {
        this.passwordPolicyError = this.translateService.instant('new_password_base.validationMessage');
      }
      let errorString = '';
      if (this.passwordPolicy.IsUpperCase) {
        errorString += this.translateService.instant('new_password_uppercase_short.validationMessage');
        this.verifyUppercase(newPassword);
      }
      if (this.passwordPolicy.IsLowerCase) {
        this.verifyLowercase(newPassword);
        if (errorString !== '') {
          errorString += ', ';
        }
        errorString += this.translateService.instant('new_password_lowercase_short.validationMessage');
      }
      if (this.passwordPolicy.IsDigit) {
        if (errorString !== '') {
          errorString += ', ';
        }
        errorString += this.translateService.instant('new_password_digit_short.validationMessage');
        this.verifyDigit(newPassword);
      }
      if (this.passwordPolicy.IsSpecialCharacter) {
        if (errorString !== '') {
          errorString += ' ';
        }
        errorString += this.translateService.instant('new_password_symbol.validationMessage');
        this.verifySpecialCharacter(newPassword);
      }

      this.passwordPolicyError += errorString;
      if (this.isPasswordPolicyError) {
        return;
      }
    }
  }
  verifyBlankSpece(newPassword: string) {
    if (newPassword.startsWith(' ') || newPassword.endsWith(' ')) {
      this.isPasswordPolicyError = true;
      this.passwordPolicyError = 'new_password_blank_space.validationMessage';
      return false;
    }
    return true;
  }

  verifyLength(newPassword: string) {
    if (newPassword.length < this.passwordPolicy.Length) {
      this.isPasswordPolicyError = true;
    }
  }

  verifySpecialCharacter(newPassword: string) {
    if (!(/^[a-zA-Z0-9- ]*$/.test(newPassword) === false)) {
      this.isPasswordPolicyError = true;
    }
  }
  verifyDigit(newPassword: string) {
    if (!this.hasDigit(newPassword)) {
      this.isPasswordPolicyError = true;
    }
  }
  verifyLowercase(newPassword: string) {
    if (!this.hasLowerCase(newPassword)) {
      this.isPasswordPolicyError = true;
    }
  }
  verifyUppercase(newPassword: string) {
    if (!this.hasUpperCase(newPassword)) {
      this.isPasswordPolicyError = true;
    }
  }

  hasLowerCase(str: string) {
    return /[a-z]/.test(str);
  }
  hasUpperCase(str: string) {
    return /[A-Z]/.test(str);
  }
  hasDigit(str: string) {
    return /[0-9]/.test(str);
  }

  getTwoFactorStatus(id: string) {
    this.accountService.getTwoFactorStatus(id).subscribe((res: any) => {
      if (res && res === true) {
        this.twoFactorAuthStatus = true;
        this.twoFactorAuthMode = true;
      } else {
        this.twoFactorAuthStatus = false;
        this.twoFactorAuthMode = false;
      }
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  select(type: string) {
    this.activeType = type;
  }

  onClose(e: any) {
    this.ref.close();
  }

  save2FASettings() {
    this.loaderService.showMain = true;
    if (this.twoFactorAuthStatus === this.twoFactorAuthMode) {
      this.loaderService.showMain = false;
      return;
    }
    if (this.twoFactorAuthStatus === false && this.twoFactorAuthMode === true) {
      window.location.href = identityServerAngularConfig.authority + '/Manage/TwoFactorAuthentication';
    } else if (this.twoFactorAuthStatus === true && this.twoFactorAuthMode === true) {
      window.location.href = identityServerAngularConfig.authority + '/Manage/TwoFactorAuthentication';
    } else if (this.twoFactorAuthStatus === true && this.twoFactorAuthMode === false) {
      window.location.href = identityServerAngularConfig.authority + '/Manage/Disable2faWarning';
    } else {
      window.location.href = identityServerAngularConfig.authority + '/Manage/TwoFactorAuthentication';
    }
  }

  // handleChange(event: any) {
  //   const obj: UserSettingsDTO = {
  //     Id : this.service.getUser().Id,
  //     TourGuide : this.mode
  //   };
  // }
  submit() {
    this.submitted = true;
    this.user.OldPassword = this.registerForm.value.password;
    this.user.NewPassword = this.registerForm.value.newPassword;
    this.user.ConfirmPassword = this.registerForm.value.confirmPassword;
    if (!this.user.OldPassword) {
      return;
    }
    if (!this.user.NewPassword) {
      return;
    }
    if (!this.user.ConfirmPassword) {
      return;
    }
    if (this.user.ConfirmPassword !== this.user.NewPassword) {
      return;
    }
    if (this.passwordPolicy != null) {
      this.validatePasswordPolicy();
      if (this.isPasswordPolicyError) {
        return;
      }
    }
    if (!this.registerForm.valid) {
      return;
    }

    this.service.changePassword(this.user.Id, this.user).subscribe((res) => {
      this.submitted = false;
      this.user.OldPassword = '';
      this.user.NewPassword = '';
      this.user.ConfirmPassword = '';
      this.toastService.success('Password changed successfully');
      this.ref.close();
    });
  }

  saveUserSettings() {
    if (this.permissions[Identifiers.TourGuide] ? true : false) {
      const obj: UserSettingsDTO = {
        Id: this.service.getUser().Id,
        TourGuide: this.mode,
      };
      this.accountService.updateUserSettings(obj).subscribe((resp) => {
        if (!resp) {
          return;
        }
        const settingObj = JSON.parse(localStorage.getItem('authorizationData'));
        if (settingObj.UserSettings === null) {
          settingObj.UserSettings = {
            TourGuide: this.mode,
          };
        } else {
          settingObj.UserSettings.TourGuide = this.mode;
        }
        localStorage.setItem('authorizationData', JSON.stringify(settingObj));
        this.service.OnTourSettingChange.emit(settingObj.UserSettings.TourGuide);
        if (this.service.authData.UserSettings) {
          this.service.authData.UserSettings.TourGuide = this.mode;
        }
        this.toastService.success('User setting update for tour guide');
      });
    } else {
      this.toastService.error('Not Allowed to update User Settings');
    }
  }

  changeLanguage() {
    this.submitted = true;
    this.updateDefaultLanguage();
  }

  updateDefaultLanguage() {
    const userUpdate = Object.assign({}, this.user);
    userUpdate.DefaultLanguage = this.defaultLanguage.Code;
    userUpdate.MarketId = this.defaultLanguage.MarketId;
    this.service.updateMarket(userUpdate).subscribe((resp) => {
      if (this.permissions[Identifiers.Multilingual] ? true : false) {
        this.i18nService.language = this.defaultLanguage.Code;
      }
      this.user.DefaultLanguage = this.defaultLanguage.Code;
      this.service.updateLanguage(this.defaultLanguage);
      this.toastService.success('Language Updated');
      localStorage.removeItem('translations');
      localStorage.removeItem('survey_question');
      this.ref.close();
      setTimeout(() => {
        location.reload();
      }, 500);
    });
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }
  submitProfilePic(event: any) {
    this.image = event;
  }

  saveProfilePic(event: any) {
    if (this.image) {
      this.user.Image = this.image;
      this.accountService.addProfilePic(this.user).subscribe((res) => {
        const that = this;
        const localUser = this.service.getUser();
        localUser.ProfilePic = that.image;
        this.service.authData = localUser;

        localStorage.setItem('authorizationData', JSON.stringify(this.service.mapData(this.service.authData)));
        this.service.changeCurrentImage(localUser.ProfilePic);
        this.toastService.success('Profile Picture Updated');
        this.ref.close();
      });
    } else {
      this.ref.close();
    }
  }

  onPaswordChange(val: string) {
    this.isPasswordPolicyError = false;
    this.passwordPolicyError = '';
    const color = this.testPasswordStrength(val);
    this.styleStrengthLine(color, val);
  }

  styleStrengthLine(color: string, value: any) {
    this.strengthColor = '';
    if (value) {
      this.strengthColor = color;
      this.changeDetectorRef.detectChanges();
    }
  }

  testPasswordStrength(value: any) {
    this.isStrongStrength = false;
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=/()%ยง!@#$%^&*])(?=.{8,})');
    const mediumRegex = new RegExp(
      '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
    );

    if (strongRegex.test(value)) {
      this.isStrongStrength = true;
      return 'green';
    } else if (mediumRegex.test(value)) {
      return 'orange';
    } else {
      return 'red';
    }
    this.changeDetectorRef.detectChanges();
  }
}

export function FormatString(str: string, ...val: string[]) {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}
