import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { WidgetService } from '../widget.service';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '@app/shared/services/cache.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import WidgetBase from '../widegt.base';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { I18nService } from '@app/core';
@Component({
  selector: 'survey-list-widget',
  templateUrl: './survey-list-widget.html',
  styleUrls: ['./survey-list-widget.scss'],
})
export class SurveyListWidgetComponent extends WidgetBase implements OnInit, OnDestroy {
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  routerPrefix = '';
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  data: any;
  reportingRoutePrefix = `/${environment.reportingRoutePrefix}`;
  canRender = false;
  surveyListWidget: any[];
  api: Subscription;
  isMobile = window.innerWidth < 1024;
  cols: any = [];
  user: any;
  private subscriptions: Subscription[] = [];

  constructor(
    public toastService: ToastService,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    private router: Router,
    public appConfig: AppConfigurationService,
    public gbfService: GlobalFilterService,
    public i18nService: I18nService
  ) {
    super(gbfService, translateService);
    this.user = this.gbfService.authService.getUser();
    this.cols = this.appConfig.getHideColumnsV2(this.cols, 'name', 'isShow', 'SurveyListWidget');
    this.cols = this.cols.sort((a: any, b: any) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
    this.cols = this.cols.filter((data: any) => {
      return data.isShow === true;
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.surveyListWidgetUrl).subscribe((res: any) => {
        if (res) {
          this.data = res;
          this.initSurveyListWidget();
          this.canRender = true;
        }
      })
    );

    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }
  }

  loadWidget() {
    this.canRender = false;
    this.widgetService.isAllLoaded = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getSurveyListWidget({ apiConfig: this.apiConfig }).subscribe(
      (res: any) => {
        if (res) {
          this.data = res;
          this.initSurveyListWidget();
          this.widgetService.isAllLoaded = true;
          this.canRender = true;
        }
      },
      (error: any) => {
        this.canRender = false;
        this.widgetService.isAllLoaded = true;
      }
    );
  }
  RouteOrgSummary() {
    const route = [this.reportingRoutePrefix, 'reporting', 'orgsummary'];
    this.router.navigate(route);
  }

  initSurveyListWidget() {
    // this.surveyListWidget = [this.data];
    this.surveyListWidget = this.data;
  }

  changeValue(value: any) {
    const language = this.user.DefaultLanguage.split('-');
    const float: any = parseFloat(Math.round(value * 100) / 100 + '').toFixed(1);
    const score = this.i18nService.transformNumber(float);
    return language[0] == 'en' ? score.replace(new RegExp(`,`, 'g'), '') : score;
  }
}
