import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { generateReasonsEscalations } from '@clearviewshared/highchart/helperfunctions';
import { ResolutionMockData } from '../widget-interface';

@Injectable({
  providedIn: 'root',
})
export class ReasonsEscalationsService {
  defaultEventName = this.authService.authData.ClientInfo.DealerEvent;
  resolutions: ResolutionMockData = {
    Sales: [
      { Name: 'Mercedes-Benz Gatineau', Score: 12, Percentage: 6 },
      { Name: "O'Regan's Mercedes-Benz", Score: 32, Percentage: 20 },
      { Name: 'Mercedes-Benz St-Nicolas', Score: 22, Percentage: 10 },
      { Name: 'Mercedes-Benz Sherbrooke', Score: 24, Percentage: 5 },
      { Name: 'Franke Ste-Agathe', Score: 25, Percentage: 6 },
    ],
    Collision: [
      { Name: 'Mercedes-Benz Gatineau', Score: 24, Percentage: 50 },
      { Name: 'Mercedes-Benz Sherbrooke', Score: 12, Percentage: 53 },
      { Name: 'Franke Ste-Agathe', Score: 22, Percentage: 62 },
      { Name: 'Mercedes-Benz St-Nicolas', Score: 24, Percentage: 60 },
      { Name: "O'Regan's Mercedes-Benz", Score: 32, Percentage: 32 },
    ],
    Service: [
      { Name: 'Mercedes-Benz Sherbrooke', Score: 32, Percentage: 23 },
      { Name: 'Mercedes-Benz Gatineau', Score: 24, Percentage: 65 },
      { Name: 'Mercedes-Benz St-Nicolas', Score: 22, Percentage: 5 },
      { Name: 'Franke Ste-Agathe', Score: 24, Percentage: 55 },
      { Name: "O'Regan's Mercedes-Benz", Score: 52, Percentage: 43 },
    ],
    CPO: [
      { Name: 'Mercedes-Benz St-Nicolas', Score: 22, Percentage: 60 },
      { Name: 'Mercedes-Benz Sherbrooke', Score: 24, Percentage: 6 },
      { Name: 'Franke Ste-Agathe', Score: 21, Percentage: 75 },
      { Name: 'Mercedes-Benz Gatineau', Score: 52, Percentage: 43 },
      { Name: "O'Regan's Mercedes-Benz", Score: 52, Percentage: 60 },
    ],
  };
  data = {
    Sales: [
      {
        name: 'Midtown',
        data: [62.74],
      },
      {
        name: 'Country Hills',
        data: [10.57],
      },
    ],
    Service: [
      {
        name: 'Midtown',
        data: [62.74],
      },
      {
        name: 'Country Hills',
        data: [10.57],
      },
    ],
    CPO: [
      {
        name: 'Midtown',
        data: [62.74],
      },
      {
        name: 'Country Hills',
        data: [10.57],
      },
    ],
    Collision: [
      {
        name: 'Midtown',
        data: [62.74],
      },
      {
        name: 'Country Hills',
        data: [10.57],
      },
    ],
  };
  categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  constructor(public authService: AuthenticationService) {}

  getData(program = this.defaultEventName): any {
    return generateReasonsEscalations(this.categories, this.data[program]);
  }
  getResolutions(program = this.defaultEventName) {
    let tmp: any;

    if (program == 'Sales') {
      tmp = this.resolutions.Sales;
    } else if (program == 'Service') {
      tmp = this.resolutions.Service;
    } else if (program == 'CPO') {
      tmp = this.resolutions.CPO;
    } else if (program == 'Collision') {
      tmp = this.resolutions.Collision;
    }

    //let data = this.resolutions[program];
    return tmp;
  }
}
