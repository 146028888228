<div class="comment-classification" *ngIf="!ResultProcessed">
  <div class="classification__comment">
    <p>{{CommentText}}</p>
    <div class="remove__comment" (click)="RemoveComment()">
      <i class="far fa-trash-alt"></i>
    </div>
  </div>
</div>



<div *ngIf="ResultProcessed" class="comment-classification">
  <div class="classification__comment">

    <p><b>{{CommentText}}</b></p>
    <div class="remove__comment" (click)="RemoveComment()">
      <i class="far fa-trash-alt"></i>
    </div>

    <div class="comments-sentiments" *ngIf="this.ClassificationScheme == 2">
      <ng-container *ngIf="NLPResultArray[0].sentiment_automobile_label.toLowerCase()=='positive'">
        <div class="sentiment__box positive">

          <h4>Overall Sentiment</h4>
          <i class="fal fa-thumbs-up"></i>
          <h5>{{NLPResultArray[0].sentiment_automobile_label}}</h5>

        </div>

      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].sentiment_automobile_label.toLowerCase()=='neutral'">
        <div class="sentiment__box neutral">

          <h4>Overall Sentiment</h4>
          <i class="fal fa-meh"></i>
          <h5>{{NLPResultArray[0].sentiment_automobile_label}}</h5>

        </div>

      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].sentiment_automobile_label.toLowerCase()=='negative'">
        <div class="sentiment__box negative">

          <h4>Overall Sentiment</h4>
          <i class="fal fa-thumbs-down"></i>
          <h5>{{NLPResultArray[0].sentiment_automobile_label}}</h5>

        </div>

      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].sentiment_automobile_label.toLowerCase()=='unassigned'">
        <div class="sentiment__box unassigned">

          <h4>Overall Sentiment</h4>
          <i class="fal fa-question-circle"></i>
          <h5>{{NLPResultArray[0].sentiment_automobile_label}}</h5>

        </div>

      </ng-container>

      <ng-container *ngIf="NLPResultArray[0].intent_automobile.dominant.id.toLowerCase()=='10000'">
        <div class="sentiment__box all">

          <h4>Overall Intent</h4>
          <i class="fal fa-border-all"></i>
          <h5>{{NLPResultArray[0].intent_automobile.dominant.label}}</h5>

        </div>

      </ng-container>

      <ng-container *ngIf="NLPResultArray[0].intent_automobile.dominant.id.toLowerCase()=='10001'">
        <div class="sentiment__box risk">
          <h4>Overall Intent</h4>
          <i class="fal fa-exclamation-triangle"></i>
          <h5>{{NLPResultArray[0].intent_automobile.dominant.label}}</h5>
        </div>

      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].intent_automobile.dominant.id.toLowerCase()=='10002'">
        <div class="sentiment__box complain">
          <h4>Overall Intent</h4>
          <i class="fal fa-info-circle"></i>
          <h5>{{NLPResultArray[0].intent_automobile.dominant.label}}</h5>

        </div>

      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].intent_automobile.dominant.id.toLowerCase()=='10003'">
        <div class="sentiment__box suggestion">
          <h4>Overall Intent</h4>
          <i class="fal fa-comment-dots"></i>
          <h5>{{NLPResultArray[0].intent_automobile.dominant.label}}</h5>

        </div>

      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].intent_automobile.dominant.id.toLowerCase()=='10004'">
        <div class="sentiment__box recommendation">
          <h4>Overall Intent</h4>
          <i class="fal fa-thumbs-up"></i>
          <h5>{{NLPResultArray[0].intent_automobile.dominant.label}}</h5>

        </div>

      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].intent_automobile.dominant.id.toLowerCase()=='10005'">
        <div class="sentiment__box neutral">
          <h4>Overall Intent</h4>
          <i class="fal fa-meh"></i>
          <h5>{{NLPResultArray[0].intent_automobile.dominant.label}}</h5>

        </div>

      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].intent_automobile.dominant.id.toLowerCase()=='10006'">
        <div class="sentiment__box praise">
          <h4>Overall Intent</h4>
          <i class="fal fa-hand-paper"></i>
          <h5>{{NLPResultArray[0].intent_automobile.dominant.label}}</h5>

        </div>

      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].intent_automobile.dominant.id.toLowerCase()=='10009'">
        <div class="sentiment__box unassigned">
          <h4>Overall Intent</h4>
          <i class="fal fa-question-circle"></i>
          <h5>{{NLPResultArray[0].intent_automobile.dominant.label}}</h5>

        </div>

      </ng-container>





      <ng-container *ngIf="NLPResultArray[0].text_emotion_automobile.dominant.id.toLowerCase()=='10000'">
        <div class="sentiment__box all">
          <h4>Overall Emotion</h4>
          <i class="fal fa-border-all"></i>
          <h5>{{NLPResultArray[0].text_emotion_automobile.dominant.label}}</h5>
        </div>
      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].text_emotion_automobile.dominant.id.toLowerCase()=='10001'">
        <div class="sentiment__box happy">
          <h4>Overall Emotion</h4>
          <i class="fal fa-smile"></i>
          <h5>{{NLPResultArray[0].text_emotion_automobile.dominant.label}}</h5>
        </div>
      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].text_emotion_automobile.dominant.id.toLowerCase()=='10002'">
        <div class="sentiment__box anticipation">
          <h4>Overall Emotion</h4>
          <i class="fal fa-info-circle"></i>
          <h5>{{NLPResultArray[0].text_emotion_automobile.dominant.label}}</h5>
        </div>
      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].text_emotion_automobile.dominant.id.toLowerCase()=='10004'">
        <div class="sentiment__box sad">
          <h4>Overall Emotion</h4>
          <i class="far fa-frown"></i>
          <h5>{{NLPResultArray[0].text_emotion_automobile.dominant.label}}</h5>
        </div>
      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].text_emotion_automobile.dominant.id.toLowerCase()=='10005'">
        <div class="sentiment__box acceptance">
          <h4>Overall Emotion</h4>
          <i class="fal fa-check-circle"></i>
          <h5>{{NLPResultArray[0].text_emotion_automobile.dominant.label}}</h5>
        </div>
      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].text_emotion_automobile.dominant.id.toLowerCase()=='10003'">
        <div class="sentiment__box angry">
          <h4>Overall Emotion</h4>
          <i class="fal fa-angry"></i>
          <h5>{{NLPResultArray[0].text_emotion_automobile.dominant.label}}</h5>
        </div>
      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].text_emotion_automobile.dominant.id.toLowerCase()=='10009'">
        <div class="sentiment__box unassigned">
          <h4>Overall Emotion</h4>
          <i class="fal fa-question-circle"></i>
          <h5>{{NLPResultArray[0].text_emotion_automobile.dominant.label}}</h5>
        </div>
      </ng-container>
      <ng-container *ngIf="NLPResultArray[0].text_emotion_automobile.dominant.id.toLowerCase()=='10006'">
        <div class="sentiment__box neutral">
          <h4>Overall Emotion</h4>
          <i class="fal fa-meh"></i>
          <h5>{{NLPResultArray[0].text_emotion_automobile.dominant.label}}</h5>
        </div>
      </ng-container>



    </div>


  </div>

  <ng-container *ngIf="this.ClassificationScheme == 2">
    <cvp-table-container [striped]="true" [paginator]="false" [rows]="NLPResult?.categorization_automobile">
      <cvp-table-cell header="Segment" headerClass="text-left" field="text_chunk" cellClass="text-left">
      </cvp-table-cell>
      <cvp-table-cell header="Category" headerClass="text-left" cellClass="text-left">
        <ng-template #template let-element>
          <ul class="category-breadcrumbs">
            <ng-container *ngFor="let cat of element.category_label.split('#')">
              <li>{{cat}}</li>
            </ng-container>
          </ul>
        </ng-template>
      </cvp-table-cell>
      <cvp-table-cell header="Sentiment" headerClass="" cellClass="sentiment-tags">
        <ng-template #template let-element>
          <span [ngClass]="element.sentiment_automobile_label | lowercase">{{element.sentiment_label}}</span>
        </ng-template>
      </cvp-table-cell>

      <cvp-table-cell *ngIf="NLPResult.categorization_automobile[0].text_Emotion_generic"
        header="Textual Emotion Analysis" headerClass="text-center" cellClass="sentiment-tags">
        <ng-template #template let-element>
          <i [ngClass]="element.text_Emotion_generic.emoji_class" class="icon-sent"></i>
          <p><strong>{{element.text_Emotion.emoji_label}} ({{element.text_Emotion.emoji_percent}}%)</strong>
          </p>

        </ng-template>
      </cvp-table-cell>


      <cvp-table-cell header="Emotion" headerClass="text-center" cellClass="text-center">
        <ng-template #template let-element>
          <i [ngClass]="element.text_emotion_label" class="icon-sent"></i>
          <p><strong>{{element.text_emotion_label}}</strong>
          </p>

        </ng-template>
      </cvp-table-cell>

      <cvp-table-cell header="Intent" headerClass="text-center" cellClass="text-center">
        <ng-template #template let-element>
          <i [ngClass]="element.text_emotion_label" class="icon-sent"></i>
          <p><strong>{{element.intent_label}}</strong>
          </p>

        </ng-template>
      </cvp-table-cell>


    </cvp-table-container>
  </ng-container>


  <ng-container *ngIf="this.ClassificationScheme == 1">
    <cvp-table-container [striped]="true" [paginator]="false" [rows]="NLPResult?.categorization_generic">
      <cvp-table-cell header="Segment" headerClass="text-left" field="text_chunk" cellClass="text-left">
      </cvp-table-cell>
      <cvp-table-cell header="Category" headerClass="text-left" cellClass="text-left">
        <ng-template #template let-element>
          <ul class="category-breadcrumbs">
            <ng-container *ngFor="let cat of element.category_label.split('#')">
              <li>{{cat}}</li>
            </ng-container>
          </ul>
        </ng-template>
      </cvp-table-cell>
      <cvp-table-cell header="Sentiment" headerClass="" cellClass="sentiment-tags">
        <ng-template #template let-element>
          <span [ngClass]="element.sentiment_label | lowercase">{{element.sentiment_label}}</span>
        </ng-template>
      </cvp-table-cell>

      <cvp-table-cell *ngIf="NLPResult.categorization_generic[0].text_Emotion_generic" header="Textual Emotion Analysis"
        headerClass="text-center" cellClass="text-center sentiment-tags">
        <ng-template #template let-element>
          <i [ngClass]="element.text_Emotion_generic.emoji_class" class="icon-sent"></i>
          <p><strong>{{element.text_Emotion.emoji_label}} ({{element.text_Emotion.emoji_percent}}%)</strong>
          </p>

        </ng-template>
      </cvp-table-cell>
      <cvp-table-cell header="Emotion" headerClass="text-center" cellClass="text-center">
        <ng-template #template let-element>
          <i [ngClass]="element.text_emotion_label" class="icon-sent"></i>
          <p><strong>{{element.text_emotion_label}}</strong>
          </p>

        </ng-template>
      </cvp-table-cell>

      <cvp-table-cell header="Intent" headerClass="text-center" cellClass="text-center">
        <ng-template #template let-element>
          <i [ngClass]="element.text_emotion_label" class="icon-sent"></i>
          <p><strong>{{element.intent_label}}</strong>
          </p>

        </ng-template>
      </cvp-table-cell>

    </cvp-table-container>
  </ng-container>



  <div class="classification__footer">
    <div class="result-panel panel--partspeach">
      <h2>Part of speech</h2>
      <div class="panel__content">
        <div class="speach__part" *ngFor="let result of NLPResult.linguistic_features">
          <span class="neutral">{{result.p}}</span>
          <p>{{result.t}}</p>
        </div>
      </div>
    </div>
  </div>

</div>