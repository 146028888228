<app-widget-dynamic
  [rootClass]="rootClass"
  [styleClass]="styleClass"
  [clickThrough]="clickThrough"
  [widgetTitle]="widgetTitle"
>
  <ng-container widgetHeader>
    <div class="widget-node" *ngIf="clickThrough">
      <a class="handler more" (click)="fireClickThrough($event)"><i class="fal fa-long-arrow-right"></i></a>
    </div>
    <div class="widget-node">
      <app-widget-spinner [hidden]="canRender"></app-widget-spinner>
    </div>
  </ng-container>
  <ng-container widgetArea>
    <div class="widget__content" [attr.data-can-render]="canRender">
      <div class="widget__graph">
        <dial-meter-new
          *ngFor="let kpi of kpiData"
          [data]="kpi.data"
          [dialsLength]="kpiData.length || 0"
          [rank]="kpi.rank"
        ></dial-meter-new>

      </div>
    </div>
  </ng-container>
</app-widget-dynamic>
