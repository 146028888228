import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { HttpClient } from '@angular/common/http';
import { Configuration, SettingObj, Mapping } from '@app/core/types/configuration';
import { Observable, Observer } from 'rxjs';
import { RestMessage, ColumnMapping } from '@app/core/types';
const Images = {
  'Performance Trend': 'Performance-Trend.png',
  'Hot Alerts': 'hot-alerts.png',
  'Area of Improvement': 'areas-of-improvement.png',
  'Top/Bottom Performance': 'top-bottom-performer.png',
};

@Injectable()
export class ConfigurationService {
  user: any;
  sortEvent: boolean = false;
  timerReference: any;
  constructor(private authService: AuthenticationService, private httpClient: HttpClient) {
    this.user = this.authService.getUser();
  }

  getConfiguration(id: string, module: string): Observable<Configuration> {
    const url =
      '/configuration?Id=' + id + '&UserId=' + this.user.Id + '&ClientId=' + this.user.ClientId + '&Module=' + module;
    return this.httpClient.disableLoader().disableHttpCancel().get<Configuration>(url);
  }

  updateConfiguration(data: Configuration): Observable<RestMessage> {
    const url = '/configuration';
    return this.httpClient.put<RestMessage>(url, data);
  }

  set(config: Mapping, module: string, key: string): void {
    const obj: SettingObj = this.map(config);
    this.save(obj, module, config.pinnedIds);
    localStorage.setItem(`config-${module}${key}`, JSON.stringify(config));
  }

  get(module: string, key: string): Observable<Mapping> {
    return new Observable((observer: Observer<Mapping>) => {
      let localKey = `config-${module}${key}`;
      if (!key) {
        localKey = `config-${module}`;
      }
      let config: Mapping = JSON.parse(localStorage.getItem(localKey));
      if (config) {
        observer.next(config);
      } else {
        this.getConfiguration(key, module).subscribe((resp) => {
          const data = resp;
          if (data.Settings) {
            config = this.deserialize(data.Settings);
            config.pinnedIds = data.PinnedIds;
            localStorage.setItem(localKey, JSON.stringify(config));
            observer.next(config);
          } else {
            observer.next(config);
          }
        });
      }
    });
  }

  save(obj: SettingObj, module: string, pinnedIds: any) {
    this.updateConfiguration({
      UserId: this.user.Id,
      ClientId: this.user.ClientId,
      Module: module,
      Settings: obj,
      CreatedBy: this.user.Id,
      ModifiedBy: this.user.Id,
      CreatedOn: new Date(),
      ModifiedOn: new Date(),
      IsDeleted: false,
      Id: null,
      PinnedIds: pinnedIds,
    }).subscribe((resp) => {
      this.timerReference = setInterval(this.timer.bind(this), 1000);
    });
  }
  timer() {
    let isloader =
      document.getElementsByClassName('main-loader')[0].classList.value == 'main-loader active' ? true : false;
    if (!isloader) {
      this.sortEvent = false;
      this.endTimer();
    }
  }
  endTimer() {
    clearInterval(this.timerReference);
  }
  deserialize(obj: any): Mapping {
    const config: Mapping = {
      list: [],
    };
    if (obj.id) {
      config.id = obj.id;
    }

    obj.ordering = obj.ordering.substring(0, obj.ordering.length - 1);
    const columns = obj.ordering.split(',');
    const values = obj.values.split(',');
    const selection = obj.selection.split(',');
    const orderBy = obj.orderBy;
    const sortColumn = obj.sortColumn;
    const sortable = obj.sortable ? obj.sortable.split(',') : '';
    const userEvent = obj.event ? obj.event.split(',') : '';
    config.list = columns.map((x: any, index: any) => {
      return {
        name: x,
        label: x,
        image: Images[x] ? Images[x] : 't-a.jpg',
        value: values[index],
        isChecked: selection.includes(x), // .includes(selection, x),
        show: selection.includes(x), // .includes(selection, x),
        orderBy: sortColumn === values[index],
        sortable: sortable ? sortable.includes(values[index]) : true,
        order: sortColumn === values[index] ? orderBy : 'asc',
        isEvent: userEvent ? userEvent.includes(values[index]) : false,
      };
    });
    return config;
  }

  map(obj: Mapping): SettingObj {
    const config: SettingObj = {
      ordering: '',
      selection: '',
      values: '',
      sortColumn: '',
      orderBy: '',
      sortable: '',
      event: '',
    };

    if (obj.id) {
      config.id = obj.id;
    }

    obj.list.map((x: ColumnMapping) => {
      config.ordering += x.name + ',';
      config.values += x.value + ',';
      if (x.isChecked) {
        config.selection += x.name + ',';
      }
      if (x.orderBy) {
        config.sortColumn = x.value;
        config.orderBy = x.order;
      }
      if (x.sortable) {
        config.sortable += x.value + ',';
      }
      if (x.isEvent) {
        config.event += x.value + ',';
      }
    });
    return config;
  }

  sortColumnsByValue(firstElem: any, secondElem: any) {
    if (firstElem.value < secondElem.value) {
      return -1;
    } else if (firstElem.value > secondElem.value) {
      return 1;
    }
    return 0;
  }

  sortColumnsByName(firstElem: any, secondElem: any) {
    if (firstElem.name < secondElem.name) {
      return -1;
    } else if (firstElem.name > secondElem.name) {
      return 1;
    }
    return 0;
  }

  isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}
