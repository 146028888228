import { Component, OnInit, Output, EventEmitter, ViewChild, Input, OnDestroy } from '@angular/core';
import moment from 'moment';
import { ModelType } from '@app/core/types/modaltype';
import { DealerType } from '@app/core/types/dealertypes';
import { Model, ModelListSearchItem } from '@app/core/types/model';
import { CalenderFilter } from '@app/core/types/calenderFilter';
import { ActiveOrgHierachy, OrgHierachySearchItem, OrgHierachy } from '@app/core/types/orgHierarchy';
import { FiltersService } from '@app/shared/services/filters.service';
import { isEmpty, filter, debounceTime, distinctUntilChanged, ignoreElements } from 'rxjs/operators';
import _, { cloneDeep } from 'lodash';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Permission } from '@clearviewshared/constant/permission';
import { Identifiers } from '@app/shared/services/app.config.type';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ExportService } from '@clearviewshared/services/export.service';
import { SurveyVersions } from '@app/core/types/suveyVesions';
import { ISurveyScore } from '@app/core/types/surveyScore';
import { TooltipModule } from 'primeng/tooltip';
import { MenuItem, TreeNode } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { SliderModule } from 'primeng/slider';
import { Calendar } from 'primeng/calendar';
import { ILanguages } from '@app/core/types/languages';
import { IAspectCategory } from '@app/core/types/vocCategories';
import { ISurveyQuestion, ISurveyQuestionOption } from '@app/core/types/surveyQuestion';
import { aspectCategoriesResponse } from '../../../../../projects/cvreporting/src/app/features/cvreporting/widgets/widget-data';
import {
  IAspectCategoriesBreadcrum,
  ISentimentDropdownOptions,
  ISurveyResponseResponse,
} from '../../../../../projects/cvreporting/src/app/features/cvreporting/widgets/widget-interface';
import { numberFormat } from 'highcharts';
import { Subject, forkJoin, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';

@Component({
  selector: 'appc-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Input() showSentimentFilters = false;
  @Input() showLanguageChFilters = false;
  @Input() showCategoryFilters = false;
  @Input() orgMaxLevel = 6;
  showHierarchy = false;
  showMenu = false;
  showDateRange = false;
  yearNavigator = true;
  monthNavigator = true;
  showDealerTypes = '';
  @Input() showVersion = true;
  @Input() showApplyFilterButton = true;
  @Input() showDealer = true;
  @Input() showModel = true;
  @Input() showHirerchyViaProp = true;
  showSurveyVersionYear: boolean;
  dealerNameChanged: Subject<string> = new Subject<string>();
  filterItems: MenuItem[];
  items2: MenuItem[];
  maxDate: any = moment();
  currentMonth: any = moment();
  modelsTypes: ModelType[] = [];
  api: Subscription;

  sourceTypeList: any[] = []; // for this seperate model required
  sourceTypeListBak: ISurveyQuestion[] = []; // for this seperate model required

  activeModalType: ModelType;
  activeModal: Model;
  items___: MenuItem[];
  currentOrg: string;
  currentLevel: number;
  currentParent = 'null';
  permissions: NgxPermissionsObject;
  showdealerType: boolean;
  allowdealerType: boolean;
  modalYear: string[] = ['2019', '2018', '2017', '2016', '2015', '2014'];
  modalYearCopy: string[] = ['2019', '2018', '2017', '2016', '2015', '2014'];
  selectActiveYear = false;

  languagesList: ILanguages[] = [];
  showLanguagesCtrl: boolean;
  languageTitle = 'All Languages';
  sourceTypeTitle = 'All Sources';
  surveyresponsefiltertiltle = '';
  sentimentList: ISentimentDropdownOptions[] = [];
  sentimentListMapping: ISentimentDropdownOptions[] = [];
  sentimentListFiltered: TreeNode[] = [];
  showSentimentCtrl: boolean;
  sentimentTitle = 'All Sentiment.label';
  sentimentSearch = '';
  aspectCategoriesList: TreeNode[] = [];
  aspectCategoriesListChild: TreeNode[] = [];
  aspectCategoriesListFiltered: TreeNode[] = [];
  aspectCategorySelected: TreeNode = {};
  aspectCategoriesBreadcrum: IAspectCategoriesBreadcrum[] = [];
  showAspectCategoryCtrl: boolean;
  aspectCategoryTitle = 'All Categories';
  surveyQuestionsList: ISurveyQuestion[] = [];
  surveyQuestionsListBak: ISurveyQuestion[] = [];
  showSurveyQuestionCtrl: boolean;
  showSourceTypeCtrl: boolean;
  surveyQuestionTitle = 'All Comment Questions';
  surveyQuestionSearch = '';
  surveyResponsesList: ISurveyQuestion[] = [];
  surveyResponsesListBak: ISurveyQuestion[] = [];
  surveyResponsesListSearch: ISurveyQuestion[] = [];
  surveyResponsesOptions: ISurveyQuestionOption[] = [];
  sourceTypeResponsesOptions: ISurveyQuestionOption[] = [];
  showSurveyResponsesCtrl: boolean;
  surveyResponsesTitle = 'All Survey Responses';
  surveyResponsesSearch = '';
  surveyResponsesSelcted: any;
  surveyResponsesParentSelcted: any;
  surveyResponsesChildSelected: any;
  languageCodefiled: any[] = [];
  surveyresponsefiled: any[] = [];
  IsAllSelected = false;
  surveyScore: ISurveyScore = {
    Id: '',
    Type: '',
    Score: [0, 100],
    Types: ['Promoters', 'Passives', 'Detractors'],
  };
  showSurveyScoreCtrl: boolean;
  surveyScoreTitle = `Survey Score: ${this.surveyScore.Score[0]} - ${this.surveyScore.Score[1]}`;
  @Input() hideSomeFilters = true;
  @Input() showProgramDate = true;
  @Input() showVocFilters = false;
  @Input() showSMFilters = false;
  @Input() showSmVocFilters = false;
  @Input() showlanguage = false;
  // @Input() showResponseList = false;
  @Input() allowDateFilters = false;
  // @Input() showCategoryList = false;
  @Input() showSurveyScoreList = false;
  @Input() showVersionList = false;
  @Input() showCarModel = false;
  @Input() showSourceTypeFilter = false;
  @Input() isSMCompetitorReport = false;
  @Input() showCustomRange = false;
  @Output() filterEmitter: EventEmitter<MenuItem[]> = new EventEmitter<MenuItem[]>();
  @Output() OnFilter: EventEmitter<any> = new EventEmitter<any>();
  defaultSelectedDate: CalenderFilter = { label: 'YTD', value: () => [this.currentMonth.startOf('year'), moment()] };
  @ViewChild('datePicker') datePicker: any;
  dateDropDown: CalenderFilter[] = [
    {
      label: 'R10',
      key: 'R10',
      value: () => [moment().subtract(10, 'days'), moment()],
    },
    {
      label: 'R30',
      key: 'R30',
      value: () => [moment().subtract(30, 'days'), moment()],
    },
    {
      label: 'MTD',
      key: 'MTD',
      value: (value: any = this.currentMonth) => [moment(value).startOf('month'), moment()],
    },
    {
      label: 'QTD',
      key: 'QTD',
      value: (value: any = this.currentMonth) => [
        moment(value).quarter(moment(value).quarter()).startOf('quarter'),
        moment(value).get('month') === moment().get('month') ? moment() : moment(value).endOf('month'),
      ],
    },
    {
      label: 'YTD',
      key: 'YTD',
      value: (value: any = this.currentMonth) => [moment(value).startOf('year'), moment()],
    },
    {
      // tslint:disable-next-line:max-line-length
      label: this.translateService.instant('previous_month.label'),
      key: 'PREVIOUS MONTH',
      value: () => [moment().subtract(1, 'month'), moment()],
    },
    {
      // tslint:disable-next-line:max-line-length
      label: this.translateService.instant('previous_quarter.label'),
      key: 'PREVIOUS QUARTER',
      value: () => [moment().subtract(1, 'month'), moment()],
    },
    {
      // tslint:disable-next-line:max-line-length
      label: this.translateService.instant('previous_year.label'),
      key: 'PREVIOUS YEAR',
      value: () => [moment().subtract(1, 'month'), moment()],
    },
    // tslint:disable-next-line:max-line-length
    {
      label: this.translateService.instant('program_to_date.label'),
      key: 'PROGRAM TO DATE',
      value: () => [moment().add(1, 'month').startOf('month'), moment().add(2, 'month')],
    },
    {
      label: this.translateService.instant('custom_range.label'),
      key: 'CUSTOM RANGE',
      value: (): any => [],
    },
  ];
  acrecFlag = true;
  primeMoment: any;
  value: any;
  active: any = [];
  logActive: any[];
  activeYear: string;
  yearquery = '';
  dealerquery = '';
  modelquery: string;
  modalsOfYear: ModelType[] = [];
  dateFilterList: any = [];
  dealerTypesList: DealerType[];
  dealerType = 'all_mb_(corporate_&_private)';

  activeSurvey = '';
  searchItems: OrgHierachySearchItem[] = [];
  itemsTest: OrgHierachy[];
  items: OrgHierachy[];
  currentCalendarDate: any = [];
  modalList: ModelListSearchItem[] = [];
  programDate: Date = new Date();
  dealerChanged: Subject<string> = new Subject<string>();
  brandModelYearChanged: Subject<string> = new Subject<string>();
  showerrorMessage = false;
  showerrorMessageModel = false;
  surveyVersionChangeSubscripton: Subscription;
  allSurveyVersions: SurveyVersions[] = [];
  showDateFilter = false;
  dateFilter = 'Ingestion Date';
  currentSurveyVersionList: SurveyVersions[] = [];
  message: any;
  subscription: Subscription;
  private subscriptions: Subscription[] = [];
  constructor(
    public appConfig: AppConfigurationService,
    public filterService: FiltersService,
    public ngxPermission: NgxPermissionsService,
    public route: ActivatedRoute,
    public translateService: TranslateService,
    public auth: AuthenticationService,
    public exportService: ExportService,
    public router: Router,
    public translationHelper: TranslationHelperService
  ) {
    this.currentCalendarDate = this.filterService.getValue();
    this.dealerNameChanged.pipe(debounceTime(250)).subscribe((x) => {
      this.showerrorMessage = true;
      this.onDealerSearch(x);
    });

    this.dealerChanged.pipe(debounceTime(250), distinctUntilChanged()).subscribe((model) => {
      this.searchDealer(model);
    });
    this.brandModelYearChanged.pipe(debounceTime(250), distinctUntilChanged()).subscribe((model) => {
      this.showerrorMessageModel = true;
      this.searchYear(model);
    });

    if (this.showVocFilters) {
      this.subscription = this.filterService.getTab().subscribe((message) => {
        // this.resetQuestion();
      });
    }
    const h = JSON.parse(localStorage.getItem('authorizationData'));
    let dealerTypes = h['FilterType'] || '';
    const dealerTypesList = [];
    const dateFilterList = [
      {
        label: 'Ingestion Date',
        translate: '',
        value: 'ingestion_date',
      },
      {
        label: 'Transmission Date',
        translate: '',
        value: 'transmission_date',
      },
      {
        label: 'Response Date',
        translate: '',
        value: 'response_date',
      },
    ];
    this.dateFilterList = dateFilterList;
    dealerTypes = dealerTypes.split(',').sort();
    const All = {
      label: this.translateService.instant('all_mb_(corporate_&_private)'),
      translate: 'all_mb_(corporate_&_private)',
      value: 'All',
    };
    const Private = {
      label: this.translateService.instant('mb_private_only'),
      translate: 'mb_private_only',
      value: 'Private',
    };
    const Corporate = {
      label: this.translateService.instant('mb_corporate_only'),
      translate: 'mb_corporate_only',
      value: 'Corporate',
    };
    const Collision = {
      label: this.translateService.instant('collision_centres'),
      translate: 'collision_centres',
      value: 'Collision',
    };
    let currentFilter = 'All';
    let cTranslate = 'all_mb_(corporate_&_private)';
    let label = this.translateService.instant('all_mb_(corporate_&_private)');
    const takeFirst: any = [];
    if (dealerTypes.find((x: string) => x.toLowerCase() === 'all')) {
      dealerTypesList.push(All);
      dealerTypesList.push(Private);
      dealerTypesList.push(Corporate);
      dealerTypesList.push(Collision);
      takeFirst[0] = All;
    } else {
      if (dealerTypes.find((x: string) => x.toLowerCase() === 'private')) {
        dealerTypesList.push(Private);
        currentFilter = 'Private';
        label = this.translateService.instant('mb_private_only');
        cTranslate = 'mb_private_only';
        if (takeFirst.length === 0) {
          takeFirst[0] = Private;
        }
      }
      if (dealerTypes.find((x: string) => x.toLowerCase() === 'corporate')) {
        dealerTypesList.push(Corporate);
        currentFilter = 'Corporate';
        label = this.translateService.instant('mb_corporate_only');
        cTranslate = 'mb_corporate_only';
        if (takeFirst.length === 0) {
          takeFirst[0] = Corporate;
        }
      }
      if (dealerTypes.find((x: string) => x.toLowerCase() === 'collision')) {
        dealerTypesList.push(Collision);
        currentFilter = 'Collision';
        label = this.translateService.instant('collision_centres');
        cTranslate = 'collision_centres';
        if (takeFirst.length === 0) {
          takeFirst[0] = Collision;
        }
      }
    }

    this.getSurveyVersionsData(true);
    const d = this.filterService.getDealerType();
    if (!d.default) {
      this.filterService.setDealerType(d.value, d.label, d.translate, false);
    } else {
      if (takeFirst.length !== 0) {
        this.filterService.setDealerType(takeFirst[0].value, takeFirst[0].label, takeFirst[0].translate, false);
      } else {
        this.filterService.setDealerType(currentFilter, label, cTranslate, false);
      }
    }
    this.dealerTypesList = dealerTypesList;
    this.items2 = [{ label: 'level 1' }, { label: 'level 2' }, { label: 'level 3' }, { label: 'level 4' }];
    this.searchItems = [];
    this.primeMoment = moment;
    this.currentOrg = h.OrganizationName;
    this.currentLevel = h.Level;
    this.currentParent = h.ParentOrganization;

    //    this.activeYear = this.filterService.activeYear ? this.filterService.activeYear : '2019';
    this.activeYear = ''; /// this.filterService.activeYear ? this.filterService.activeYear : '2019';
    this.activeModalType = { label: this.filterService.activeModalType ? this.filterService.activeModalType : '' };
    this.activeModal = { label: this.filterService.activeModal ? this.filterService.activeModal : '' };
    // tslint:disable-next-line:max-line-length
    const dtype = this.dealerTypesList.find(
      (x: DealerType) => x.value.toLowerCase() === this.filterService.dealerType.toLowerCase()
    );
    if (!d.default) {
      this.dealerType = d.translate;
    } else {
      this.dealerType = dtype ? dtype.translate : this.dealerTypesList[0].translate;
    }
    this.filterService.getVindecoderData().subscribe((res: any) => {
      this.filterService.modelsTypes = res;
      this.modelsTypes = res;
      // this.activeYear = this.modalYear[0];
      this.modalsOfYear = this.modelsTypes;
      if (this.modalsOfYear && this.modalsOfYear.length > 0) {
        const type = this.modalsOfYear.find((x: ModelType) => x.label === this.activeModalType.label);
        if (type) {
          this.activeModalType = type;
          // this.setModalType(type);
        }
      }
    });

    const programDate = this.appConfig.getByIdentifier(Identifiers.ProgramDate);
    if (programDate) {
      if (programDate.ClientConfig === null) {
        programDate.ClientConfig = programDate;
      }
      if (programDate.ClientConfig) {
        this.programDate = new Date(programDate.ClientConfig.Fields['equal']['value']);
      }
    }
    this.getAndSetupData();
  }

  showSourceType() {
    // this.sourceTypeList = [];
    // this.sourceTypeList = this.sourceTypeListBak.map((itm) => {
    //   return itm;
    // });

    if (this.showSourceTypeCtrl) {
      this.showSourceTypeCtrl = false;
      return;
    }
    this.toggleMenu('showSourceTypeCtrl');
  }
  SourceTypeSelection(event: any, sq: any) {
    if (sq.toLowerCase() === 'All'.toLowerCase()) {
      this.sourceTypeList.forEach(function (val: any) {
        val.IsChecked = event;
      });
    }

    let totalSources = 0;
    let isSelectedSource = 0;
    if (this.sourceTypeList) {
      totalSources = this.sourceTypeList.filter((x) => x.Title !== 'All').length;
      isSelectedSource = this.sourceTypeList.filter((x) => x.Title !== 'All' && x.IsChecked === true).length;
    }

    if (totalSources === isSelectedSource) {
      this.sourceTypeTitle = 'All Sources';
    } else if (isSelectedSource > 1) {
      this.sourceTypeTitle = 'Multiple Selected';
    } else if (isSelectedSource === 1) {
      this.sourceTypeTitle = this.sourceTypeList.find((x) => x.IsChecked === true)
        ? this.sourceTypeList.find((x) => x.IsChecked === true).Title
        : '';
    } else {
      this.sourceTypeTitle = 'All Sources';
    }

    this.AllSmSelectionToggle();
    // this.surveyQuestionsListBak = this.surveyQuestionsList;
    this.filterService.setSourceType(this.sourceTypeList);
  }
  AllSmSelectionToggle() {
    const cnt = this.sourceTypeList.length;
    const surveyResponsesListTmp = this.sourceTypeList.filter(function (val: any) {
      return val.Id.toLowerCase() !== 'all' && val.IsChecked === true;
    });
    const flag = surveyResponsesListTmp.length === cnt - 1 ? true : false;
    if (this.sourceTypeList[0] && this.sourceTypeList[0].Id.toLocaleLowerCase() === 'all') {
      this.sourceTypeList[0].IsChecked = flag;
    } else {
      this.sourceTypeList.forEach(function (val: any) {
        val.IsChecked = false;
      });
    }
  }

  sourceTypeDone() {
    this.sourceTypeList.forEach((itm: any) => {
      this.sourceTypeListBak.forEach((qb) => {
        if (qb.Id === itm.Id) {
          qb.IsChecked = itm.IsChecked;
        }
      });
    });

    this.showSourceTypeCtrl = false;
  }

  bindFilterIfNull() {
    this.items2 = [{ label: 'MBC' }, { label: 'MBC Somewhere' }, { label: 'MBC Another' }, { label: 'Somewhere' }];

    this.searchItems = [];
    this.primeMoment = moment;
    const h = JSON.parse(localStorage.getItem('authorizationData'));
    this.currentOrg = h.OrganizationName;
    this.currentLevel = h.Level;
    this.currentParent = h.ParentOrganization;

    this.filterService.getAllOrgs().subscribe((res: any) => {
      this.items = res;
      this.active = JSON.parse(localStorage.getItem('active'));
      this.logActive = res;
      if (!this.active) {
        this.active = [];
        this.active.push({ id: 0, active: this.items[0], items: this.items });

        this.buildBreadCrumb(this.active);
      }
      if (this.currentLevel && this.currentParent) {
        this.setData();
      }

      this.items2 = JSON.parse(localStorage.getItem('hierarchy'));
      if (!this.items2) {
        this.items2 = [
          {
            label: this.active[0].active.label,
          },
        ];
      }
      this.filterEmitter.emit(this.items2);
    });
  }

  getSurveyVersionsData(isRouteChange: boolean) {
    this.allSurveyVersions = JSON.parse(localStorage.getItem('surveyVersions'));
    if (isRouteChange || !this.allSurveyVersions) {
      this.filterService.getSurveyVersions().subscribe((res: any) => {
        if (res) {
          this.allSurveyVersions = res;
          localStorage.setItem('surveyVersions', JSON.stringify(this.allSurveyVersions));
          this.mapVersionList(isRouteChange);
        }
      });
    } else {
      this.mapVersionList(isRouteChange);
    }
  }

  mapVersionList(isRouteChange: Boolean) {
    const LScurrentVersion = localStorage.getItem('survey_version');
    let currentVersion: any = { version: '', versionName: '' };
    if (LScurrentVersion) {
      currentVersion = JSON.parse(LScurrentVersion);
    }
    this.currentSurveyVersionList = this.allSurveyVersions.filter(
      (x) => x.Brand === this.filterService.getModelType().activeModalType && x.Event === this.filterService.program
    );
    currentVersion.version = currentVersion.version;
    currentVersion.versionName = currentVersion.versionName;
    let activeVersion = this.currentSurveyVersionList.find((x) => x.Version === currentVersion.versionName);
    if (this.currentSurveyVersionList && this.currentSurveyVersionList.length > 0) {
      if (isRouteChange && this.filterService.getSurveyVersion()) {
        if (!activeVersion) {
          activeVersion = this.currentSurveyVersionList.find(
            (x) =>
              x.SurveyId === this.filterService.getSurveyVersion() &&
              x.Brand === this.filterService.getModelType().activeModalType &&
              x.Event === this.filterService.program
          );
        }
        if (activeVersion && activeVersion.SurveyId) {
          this.activeSurvey = activeVersion.Version;
          this.filterService.setSurveyVersion(this.activeSurvey, activeVersion.SurveyId);
        } else {
          if (!activeVersion) {
            activeVersion = this.currentSurveyVersionList.find(
              (x) =>
                x.CurrentSurvey === true &&
                x.Brand === this.filterService.getModelType().activeModalType &&
                x.Event === this.filterService.program
            );
          }
          if (activeVersion && activeVersion.SurveyId) {
            this.activeSurvey = activeVersion.Version;
            this.filterService.setSurveyVersion(this.activeSurvey, activeVersion.SurveyId);
          } else {
            if (!activeVersion) {
              activeVersion = this.currentSurveyVersionList.find(
                (x) =>
                  x.Brand === this.filterService.getModelType().activeModalType &&
                  x.Event === this.filterService.program
              );
            }
            if (activeVersion) {
              this.activeSurvey = activeVersion.Version;
              this.filterService.setSurveyVersion(this.activeSurvey, activeVersion.SurveyId);
            } else {
              this.activeSurvey = '';
              this.filterService.setSurveyVersion('');
            }
          }
        }
      } else {
        if (!activeVersion) {
          activeVersion = this.currentSurveyVersionList.find(
            (x) =>
              x.CurrentSurvey === true &&
              x.Brand === this.filterService.getModelType().activeModalType &&
              x.Event === this.filterService.program
          );
        }
        if (activeVersion && activeVersion.SurveyId) {
          this.activeSurvey = activeVersion.Version;
          this.filterService.setSurveyVersion(this.activeSurvey, activeVersion.SurveyId);
        } else {
          if (!activeVersion) {
            activeVersion = this.currentSurveyVersionList.find(
              (x) =>
                x.Brand === this.filterService.getModelType().activeModalType && x.Event === this.filterService.program
            );
          }
          if (activeVersion) {
            this.activeSurvey = activeVersion.Version;
            this.filterService.setSurveyVersion(this.activeSurvey, activeVersion.SurveyId);
          } else {
            this.activeSurvey = '';
            this.filterService.setSurveyVersion('');
          }
        }
      }
    } else {
      this.activeSurvey = '';
      this.filterService.setSurveyVersion('');
    }
  }

  showDateFiltersType() {
    if (this.showDateFilter) {
      this.showDateFilter = false;
      return;
    }
    this.toggleMenu('showDateFilter');
  }

  showDealerType() {
    if (this.showdealerType) {
      this.showdealerType = false;
      return;
    }
    this.toggleMenu('showdealerType');
    this.filterService.closecontext.next({ VIA: 'FILTER', rand: Math.random() * 1000 });
  }

  changeDateFilter(value: string, label: string, translate: string, expr: string) {
    this.filterService.setDateFilter(value, label, translate, expr);
    this.showDateFilter = false;
    this.dateFilter = label;
    this.closeall();
  }

  changeDealerType(value: string, label: string, translate: string) {
    localStorage.setItem('active', '[]');
    localStorage.setItem('hierarchy', '[]');
    this.filterService.setDealerType(value, label, translate);
    this.showdealerType = false;
    this.dealerType = translate;
    this.getAndSetupData(false);
    this.closeall();
    // this.setData();
  }

  showSurveyVersion() {
    if (this.showSurveyVersionYear) {
      this.showSurveyVersionYear = false;
      return;
    }
    this.toggleMenu('showSurveyVersionYear');
  }

  selectSurveyVersionYear(value: string, surveyId: string) {
    if (!this.showVocFilters) {
      this.showSurveyVersionYear = !this.showSurveyVersionYear;
    }
    // if (JSON.parse(localStorage.getItem('survey_version'))) {
    //   this.activeSurvey = JSON.parse(localStorage.getItem('survey_version'));
    // }
    this.activeSurvey = value;
    this.filterService.setSurveyVersion(value, surveyId);
  }

  onClickInnerBreadCrumb(e: any) {}

  setData() {
    if (this.currentLevel === 1) {
      const firstLevel = this.filterService.getNodes(1, 'null');
      if (firstLevel) {
        this.setFilterByCurrentLevel(0, firstLevel);
        this.buildBreadCrumb(this.active);
      }
    }
    if (this.currentLevel === 2) {
      let secondLevel = this.filterService.getNodes(this.currentLevel, this.currentParent);
      const firstLevel = this.filterService.getNodes(1, 'null');
      if (firstLevel) {
        secondLevel = this.filterValue(secondLevel, 'Name', this.currentOrg);
        this.setFilterByCurrentLevel(0, firstLevel[0]);
        if (secondLevel) {
          this.setFilterByCurrentLevel(1, secondLevel);
        }
        this.removeObject(this.active[0].active.children, this.currentOrg);
        this.buildBreadCrumb(this.active);
      }
    }
    if (this.currentLevel === 3) {
      let thirdLevel = this.filterService.getNodes(this.currentLevel, this.currentParent);
      if (thirdLevel) {
        thirdLevel = this.filterValue(thirdLevel, 'Name', this.currentOrg);
      }
      let secondLevel = this.filterService.getNodes(2, 'National');
      if (thirdLevel) {
        secondLevel = this.filterValue(secondLevel, 'Name', thirdLevel.parent);
        const firstLevel = this.filterService.getNodes(1, 'null');
        if (firstLevel) {
          this.setFilterByCurrentLevel(0, firstLevel[0]);
        }
        if (secondLevel) {
          this.setFilterByCurrentLevel(1, secondLevel);
        }
        if (thirdLevel) {
          this.setFilterByCurrentLevel(2, thirdLevel);
        }
        if (this.active && this.active[0]) {
          this.removeObject(this.active[0].active.children, this.currentParent);
          this.removeObject(this.active[1].active.children, this.currentOrg);
          this.buildBreadCrumb(this.active);
        }
      }
    }
    if (this.currentLevel === 4) {
      let fourthLevel = this.filterService.getNodes(this.currentLevel, this.currentParent);
      fourthLevel = this.filterValue(fourthLevel, 'Name', this.currentOrg);
      if (fourthLevel) {
        const thirdLevelParent = this.filterService.getNodesByName(3, fourthLevel.parent);
        const thirdLevel = this.filterValue(thirdLevelParent, 'Name', fourthLevel.parent);
        // var secondLevelParent = this.filterService.getNodesByName(2, thirdLevel.parent);
        // var secondLevel = this.filterValue(secondLevelParent, 'Name', thirdLevel.parent);
        let secondLevel = this.filterService.getNodes(2, 'National');
        if (thirdLevel) {
          secondLevel = this.filterValue(secondLevel, 'Name', thirdLevel.parent);
        }
        const firstLevel = this.filterService.getNodes(1, 'null');
        if (firstLevel) {
          this.setFilterByCurrentLevel(0, firstLevel[0]);
        }
        if (secondLevel) {
          this.setFilterByCurrentLevel(1, secondLevel);
        }
        if (thirdLevel) {
          this.setFilterByCurrentLevel(2, thirdLevel);
        }
        if (!fourthLevel && !secondLevel) {
          return;
        }
        if (fourthLevel) {
          this.setFilterByCurrentLevel(3, fourthLevel);
          this.removeObject(this.active[0].active.children, secondLevel.Name);
          this.removeObject(this.active[1].active.children, this.currentParent);
          this.removeObject(this.active[2].active.children, this.currentOrg);
          this.removeObject(this.active[3].active.children, fourthLevel.Name);
          this.buildBreadCrumb(this.active);
        }
      }
    }
    if (this.currentLevel === 5) {
      let fifthLevel = this.filterService.getNodes(this.currentLevel, this.currentParent);
      fifthLevel = this.filterValue(fifthLevel, 'Name', this.currentOrg);
      if (fifthLevel) {
        let fourthLevel = this.filterService.getNodesByName(5, fifthLevel.parent);
        fourthLevel = this.filterValue(fourthLevel, 'Name', fifthLevel.parent);
        const thirdLevelParent = this.filterService.getNodesByName(4, fourthLevel.parent);
        const thirdLevel = this.filterValue(thirdLevelParent, 'Name', fourthLevel.parent);

        const secondevelParent = this.filterService.getNodesByName(3, thirdLevel.parent);
        const secondLevel = this.filterValue(secondevelParent, 'Name', thirdLevel.parent);

        if (thirdLevel) {
          const firstLevel = this.filterService.getNodes(1, 'null');
          this.setFilterByCurrentLevel(0, firstLevel[0]);
          if (secondLevel) {
            this.setFilterByCurrentLevel(1, secondLevel);
          }
          if (thirdLevel) {
            this.setFilterByCurrentLevel(2, thirdLevel);
          }
        }
        if (fourthLevel) {
          this.setFilterByCurrentLevel(3, fourthLevel);
        }
        if (fifthLevel) {
          this.setFilterByCurrentLevel(4, fifthLevel);
        }
        if (secondLevel && fourthLevel) {
          this.removeObject(this.active[0].active.children, secondLevel.Name);
          this.removeObject(this.active[1].active.children, this.currentParent);
          this.removeObject(this.active[2].active.children, this.currentOrg);
          this.removeObject(this.active[3].active.children, fourthLevel.Name);
          this.buildBreadCrumb(this.active);
        }
      }
    }
    if (this.currentLevel === 6) {
      let fifthLevel = this.filterService.getNodes(this.currentLevel, this.currentParent);
      fifthLevel = this.filterValue(fifthLevel, 'Name', this.currentOrg);
      if (fifthLevel) {
        let fourthLevel = this.filterService.getNodesByName(4, fifthLevel.parent);
        fourthLevel = this.filterValue(fourthLevel, 'Name', fifthLevel.parent);
        const thirdLevelParent = this.filterService.getNodesByName(3, fourthLevel.parent);
        const thirdLevel = this.filterValue(thirdLevelParent, 'Name', fourthLevel.parent);
        let secondLevel = this.filterService.getNodes(2, 'National');
        if (thirdLevel) {
          secondLevel = this.filterValue(secondLevel, 'Name', thirdLevel.parent);
          const firstLevel = this.filterService.getNodes(1, 'null');
          this.setFilterByCurrentLevel(0, firstLevel[0]);
          if (secondLevel) {
            this.setFilterByCurrentLevel(1, secondLevel);
          }
          if (thirdLevel) {
            this.setFilterByCurrentLevel(2, thirdLevel);
          }
        }
        if (fourthLevel) {
          this.setFilterByCurrentLevel(3, fourthLevel);
        }
        if (fifthLevel) {
          this.setFilterByCurrentLevel(4, fifthLevel);
        }
        if (secondLevel && fourthLevel) {
          this.removeObject(this.active[0].active.children, secondLevel.Name);
          this.removeObject(this.active[1].active.children, this.currentParent);
          this.removeObject(this.active[2].active.children, this.currentOrg);
          this.removeObject(this.active[3].active.children, fourthLevel.Name);
          this.buildBreadCrumb(this.active);
        }
      }
    }
    // if (this.currentLevel === 5) {
    //   let fifthLevel = this.filterService.getNodes(this.currentLevel, this.currentParent);
    //   fifthLevel = this.filterValue(fifthLevel, 'Name', this.currentOrg);

    //   let fourthLevel = this.filterService.getNodes(4, fifthLevel.parent);
    //   fourthLevel = this.filterValue(fourthLevel, 'Name', fifthLevel.Name);

    //   let thirdLevel = this.filterService.getNodes(3, 'National');
    //   thirdLevel = this.filterValue(thirdLevel, 'Name', fourthLevel.parent);

    //   const secondLevel = this.filterService.getNodes(2, 'MBC');
    //   const firstLevel = this.filterService.getNodes(1, 'null');

    //   this.setFilterByCurrentLevel(0, firstLevel[0]);
    //   this.setFilterByCurrentLevel(1, secondLevel[0]);
    //   if (thirdLevel) {
    //     this.setFilterByCurrentLevel(2, thirdLevel);
    //   }
    //   if (fourthLevel) {
    //     this.setFilterByCurrentLevel(3, fourthLevel);
    //   }

    //   this.removeObject(this.active[1].active.children, this.currentParent);
    //   this.removeObject(this.active[2].active.children, this.currentOrg);
    //   this.buildBreadCrumb(this.active);
    // }
  }

  removeObject(obj: OrgHierachy[], value: string) {
    const index = _.findIndex(obj, { Name: value });
    if (index === 0) {
      obj.splice(1, obj.length - 1);
    } else if (index === obj.length - 1) {
      obj.splice(0, obj.length - 1);
    } else {
      obj.splice(0, index);
      obj.splice(1, obj.length);
    }
  }
  filterValue(obj: any, key: string, value: string) {
    return obj.find(function (v: any) {
      return v[key] === value;
    });
  }

  searchModelBrandYearChange(dealer: string) {
    this.showerrorMessageModel = false;
    this.brandModelYearChanged.next(dealer);
  }

  searchDealerChange(dealer: string) {
    this.showerrorMessage = false;
    this.dealerNameChanged.next(dealer);
  }

  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  onDealerSearch(event: any, items: any = this.items, recrsive: boolean = false, parent: any = { children: [] }) {
    if (!recrsive) {
      this.searchItems = [];
    }
    if (event.length < 2) {
      return;
    }
    items.forEach((item: any) => {
      item.parent = parent;
      const level = parseInt(item['level'], 0);
      if (level > this.orgMaxLevel) {
        return;
      }
      if (item.IsTerminate && item.TerminationDate) {
        const found = moment(item.TerminationDate).format(this.filterService.dateFormat);
        if (
          !moment(found).isBetween(
            this.filterService.getCalenderRange()[0],
            this.filterService.getCalenderRange()[1],
            undefined,
            '(]'
          )
        ) {
          return;
        }
      }
      // tslint:disable-next-line:max-line-length
      if (
        event &&
        item['label'].toLowerCase().search(this.escapeRegExp(event.toLowerCase())) !== -1 &&
        level >= this.currentLevel
      ) {
        let result: any = [];
        result = this.buildSearchHierachy(item, result).reverse();
        const newSearch: OrgHierachySearchItem = {
          dealerHierarchy: result,
          label: 'National',
        };
        this.searchItems.push(newSearch);
      }
      if (item['children'].length > 0) {
        this.onDealerSearch(event, item.children, true, item);
      }
    });
  }

  buildSearchHierachy(item: any, memory: any) {
    memory = [...memory];
    if (item.label !== undefined) {
      item.label = this.translateService.instant(item.label);
      memory.push(item);
    }
    if (item.parent !== undefined) {
      memory = this.buildSearchHierachy(item.parent, memory);
    }
    return memory;
  }

  searchDealer(dealer: string, items: OrgHierachy[] = this.items, parent: OrgHierachy = null) {
    if (dealer === '' || dealer === undefined) {
      this.searchItems = [];
      return;
    }
    if (!parent) {
      this.searchItems = [];
    }
    items.forEach((x: OrgHierachy, index: number, arr: OrgHierachy[]) => {
      x.parent = parent;
      // tslint:disable-next-line:max-line-length
      if (
        x.label.toLowerCase().includes(this.escapeRegExp(dealer.toLowerCase())) &&
        parseInt(x.level, 0) > this.currentLevel
      ) {
        this.addToSearch(x);
      }
      if (x.children && x.children.length > 0) {
        this.searchDealer(dealer, x.children, x);
      }
      // if (x.children && x.children.length > 0) {
      //   this.searchDealer(dealer, x.children, x);
      // } else if (x.label.toLowerCase().includes(dealer.toLowerCase())) {
      //   this.addToSearchQuery(x);
      // }
    });
  }

  mapActiveFromSearch(selectedDealer: any) {
    const active = [];
    let x = selectedDealer[selectedDealer.length - 1];
    while (x.parent != null) {
      x.parent.children = this.mapChildren(x.parent.children);
      active.push({
        id: -1,
        active: {
          label: x.label,
          Name: x.Name,
          level: x.level,
          parent: x.parent,
          isTerminate: x.isTerminate,
          terminationDate: x.terminationDate,
          children: x.children,
        },
        items: x.parent.children.map((y: OrgHierachy) => {
          return {
            label: y.label,
            children: y.children,
            level: y.level,
            Name: y.Name,
            parent: x.parent,
            isTerminate: x.isTerminate,
            terminationDate: x.terminationDate,
          };
        }),
      });
      x = x.parent;
    }
    // x.parent.children = this.mapChildren(x.parent.children);
    active.push({
      id: -1,
      active: {
        label: x.label,
        Name: x.Name,
        level: x.level,
        parent: x.parent,
        isTerminate: x.isTerminate,
        terminationDate: x.terminationDate,
        children: x.children,
      },
      items: this.items,
    });
    active.reverse().map((x: ActiveOrgHierachy, index, arr) => {
      arr[index].id = index;
    });
    return active;
  }

  addToSearch(x: OrgHierachy) {
    let dealerHierarchy = [];
    while (x.parent != null) {
      dealerHierarchy.push({
        label: this.translateService.instant(x.label),
        Name: x.Name,
        level: x.level,
        parent: x.parent,
        isTerminate: x.isTerminate,
        terminationDate: x.terminationDate,
      });
      x.parent.children = this.mapChildren(x.parent.children);
      x = x.parent;
    }
    dealerHierarchy.push({
      label: this.translateService.instant(x.label),
      Name: x.Name,
      level: x.level,
      parent: x.parent,
      isTerminate: x.isTerminate,
      terminationDate: x.terminationDate,
    });
    dealerHierarchy = dealerHierarchy.reverse();

    this.searchItems.push({ dealerHierarchy, active: [] });
  }

  mapChildren(item: OrgHierachy[]): OrgHierachy[] {
    return item.map((x: OrgHierachy) => {
      if (x.parent && Object.keys(x.parent).length > 0 && x.level !== '1') {
        return {
          label: x.label,
          Name: x.Name ? x.Name : '',
          level: x.level ? x.level : '',
          parent: this.mapParent(x.parent),
          isTerminate: x.isTerminate,
          terminationDate: x.terminationDate,
          children: x.children && x.children.length > 0 ? this.mapChildren(x.children) : [],
        };
      } else {
        const dataItem = this.filterService.getNodesByName(Number(x.level), x.Name);
        return {
          label: x.label,
          Name: x.Name ? x.Name : '',
          level: x.level ? x.level : '',
          parent: dataItem && dataItem.length > 0 ? dataItem.parent[0] : null,
          isTerminate: x.isTerminate,
          terminationDate: x.terminationDate,
          children: x.children && x.children.length > 0 ? this.mapChildren(x.children) : [],
        };
      }
    });
  }

  mapParent(item: OrgHierachy): OrgHierachy {
    if (item) {
      return {
        label: item.label,
        Name: item.Name,
        level: item.level,
      };
    }
  }

  addToSearchQuery(x: OrgHierachy) {
    let dealerHierarchy = [];
    const active = [];
    while (x.parent != null) {
      dealerHierarchy.push({
        label: this.translateService.instant(x.label),
        Name: x.Name,
        level: x.level,
        parent: x.parent,
        isTerminate: x.isTerminate,
        terminationDate: x.terminationDate,
      });
      x.parent.children = this.mapChildren(x.parent.children);
      active.push({
        id: -1,
        active: { label: x.label, Name: x.Name, level: x.level, parent: x.parent, children: x.children },
        items: x.parent.children.map((y: OrgHierachy) => {
          return {
            label: y.label,
            children: y.children,
            level: x.level,
            Name: x.Name,
            parent: x.parent,
            isTerminate: x.isTerminate,
            terminationDate: x.terminationDate,
          };
        }),
      });
      x = x.parent;
    }
    dealerHierarchy.push({
      label: this.translateService.instant(x.label),
      Name: x.Name,
      level: x.level,
      parent: x.parent,
      isTerminate: x.isTerminate,
      terminationDate: x.terminationDate,
    });
    // x.parent.children = this.mapChildren(x.parent.children);
    active.push({
      id: -1,
      active: {
        label: x.label,
        Name: x.Name,
        level: x.level,
        parent: x.parent,
        children: x.children,
        isTerminate: x.isTerminate,
        terminationDate: x.terminationDate,
      },
      items: this.items,
    });
    active.reverse().map((x: ActiveOrgHierachy, index, arr) => {
      arr[index].id = index;
    });

    dealerHierarchy = dealerHierarchy.reverse();

    this.searchItems.push({ dealerHierarchy, active });
    // this.showHierarchy = true;
  }

  searchYear(event: string) {
    this.modalList = [];
    if (event === '' || event === undefined) {
      this.modalList = [];
      return;
    }
    this.modelsTypes.forEach((element: ModelType, index: number, arr: ModelType[]) => {
      element.children.forEach((elementChild: Model, indexChild: number, arrChild: Model[]) => {
        if (elementChild.label) {
          // tslint:disable-next-line:max-line-length
          if (
            elementChild.label.toLowerCase().includes(this.escapeRegExp(event.toLowerCase())) ||
            elementChild.year.toLowerCase().includes(this.escapeRegExp(event.toLowerCase()))
          ) {
            this.addToSearchListModals(elementChild, element);
          }
        }
      });
    });
    // this.modalYear = this.modalYearCopy.filter((x => x.includes(event)));
  }

  addToSearchListModals(modal: Model, modalType: ModelType) {
    const modalHierarchy = [];
    modalHierarchy.push({
      label: modalType.year,
    });
    modalHierarchy.push({
      label: modalType.label,
    });
    modalHierarchy.push({
      label: modal.label,
    });
    this.modalList.push({
      year: modalType.year,
      modalType: modalType,
      modal: modal,
      modalHierarchy: modalHierarchy,
    });
    this.showMenu = true;
  }

  setDate(fil: CalenderFilter) {
    this.value = fil.value;
  }

  selectModalItem(item: ModelListSearchItem) {
    this.activeYear = item.year;
    this.activeModalType = item.modalType;
    this.activeModal = item.modal;
    this.modalList = [];
  }

  hideDatePicker(event: any) {
    if (!event) {
    }
    if (event.type === 'dropDownRange') {
      //      this.startDate = event.data.value[0];
      //    this.endDate = event.data.value[1];
    }
    if (event.type === 'customRange') {
      //  this.startDate = event.startDate;
      //   this.endDate = event.endDate;
    }
    // this.datePicker.overlayVisible = false;
  }

  setFilterByCurrentLevel(index: number, item: OrgHierachy) {
    this.active[index].active = JSON.parse(this.makeStringify(item));
    if (this.active.length === index + 1) {
      if (item.children && item.children.length > 0) {
        this.active[index + 1] = {
          id: index + 1,
          active: {},
          items: JSON.parse(this.makeStringify(item.children)),
        };
      }
    } else if (this.active.length > index + 1) {
      this.active = this.active.splice(0, index + 1);
      if (item.children && item.children.length > 0) {
        this.active[index + 1] = {
          id: index + 1,
          active: {},
          items: JSON.parse(this.makeStringify(item.children)),
        };
      }
    }
    this.buildBreadCrumb(this.active);
  }

  getObject(doList: any[], item: OrgHierachy) {
    let result: any;
    for (const x of doList) {
      // tslint:disable-next-line:max-line-length
      if (x.Name.localeCompare(item.Name) === 0 && x.label.localeCompare(item.label) === 0) {
        result = x;
        if (result) {
          if (result && result.children) {
            result.children.forEach((element: any) => {
              if (element.IsTerminate && element.TerminationDate) {
                const found = moment(element.TerminationDate).format(this.filterService.dateFormat);
                if (
                  moment(found).isBetween(
                    this.filterService.getCalenderRange()[0],
                    this.filterService.getCalenderRange()[1],
                    undefined,
                    '(]'
                  )
                ) {
                  element.hide = false;
                } else {
                  element.hide = true;
                }
              } else {
                element.hide = false;
              }
            });
          }
          return result;
        }
        return result;
      } else {
        result = this.getObject(x.children, item);
        if (result) {
          if (result && result.children) {
            result.children.forEach((element: any) => {
              if (element.IsTerminate && element.TerminationDate) {
                const found = moment(element.TerminationDate).format(this.filterService.dateFormat);
                if (
                  moment(found).isBetween(
                    this.filterService.getCalenderRange()[0],
                    this.filterService.getCalenderRange()[1],
                    undefined,
                    '(]'
                  )
                ) {
                  element.hide = false;
                } else {
                  element.hide = true;
                }
              } else {
                element.hide = false;
              }
            });
          }
          return result;
        }
      }
    }
    return result;
  }

  add(index: number, i: OrgHierachy) {
    const item = this.getObject(this.logActive, i);
    if (
      item !== undefined &&
      (this.currentLevel - 1 < index || (this.currentLevel - 1 === index && this.currentOrg === item.NodeId))
    ) {
      this.active[index].active = JSON.parse(this.makeStringify(item));
      if (parseInt(i.level, 0) >= parseInt(this.orgMaxLevel + '', 0)) {
        this.active = this.active.splice(0, index + 1);
        if (item.children && item.children.length > 0) {
          this.active[index + 1] = {
            id: index + 1,
            active: {},
            items: [],
          };
        }
        this.buildBreadCrumb(this.active);
        return;
      }
      if (this.active.length === index + 1) {
        // this.active.push({})
        if (item.children && item.children.length > 0) {
          this.active[index + 1] = {
            id: index + 1,
            active: {},
            items: JSON.parse(this.makeStringify(item.children)),
          };
        }
      } else if (this.active.length > index + 1) {
        this.active = this.active.splice(0, index + 1);
        if (item.children && item.children.length > 0) {
          this.active[index + 1] = {
            id: index + 1,
            active: {},
            items: JSON.parse(this.makeStringify(item.children)),
          };
        }
      }
    } else {
    }
    this.buildBreadCrumb(this.active);
  }

  selectYear(selected: boolean) {
    this.activeYear = this.activeYear;
    this.selectActiveYear = selected;

    // this.modalsOfYear = this.modelsTypes.filter(
    //   (x: ModelType) => x.year === this.activeYear
    // );
    // if (this.modalsOfYear && this.modalsOfYear.length > 0) {
    //   this.activeModalType = { label: '' };
    //   this.activeModal = { label: '' };
    // } else {
    //   this.activeModalType = null;
    //   this.activeModal = null;
    // }
  }

  buildBreadCrumb(items: ActiveOrgHierachy[]) {
    this.items2 = [];
    this.active.forEach((x: ActiveOrgHierachy) => {
      if (x.active !== undefined) {
        if (x.active.label) {
          this.items2.push({
            label: this.translateService.instant(x.active.label),
          });
        }
      }
    });
    this.filterService.inMemoryActiveHierarchy = this.active;
    localStorage.setItem('active', this.makeStringify(this.active));
    localStorage.setItem('hierarchy', this.makeStringify(this.items2));
    this.filterEmitter.emit(this.items2);
  }

  makeStringify(obj: any) {
    const cache: any = [];
    const s = JSON.stringify(obj, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.indexOf(value) !== -1) {
          return;
        }
        cache.push(value);
      }
      return value;
    });
    return s;
  }

  selectItem(items: OrgHierachySearchItem) {
    this.active = this.mapActiveFromSearch(items.dealerHierarchy);
    this.items2 = items.dealerHierarchy;
    this.searchItems = [];
    const active = this.makeStringify(this.active);
    const hierarchy = this.makeStringify(this.items2);
    this.filterService.inMemoryActiveHierarchy = this.active;
    localStorage.setItem('active', active);
    localStorage.setItem('hierarchy', hierarchy);
    this.filterEmitter.emit(this.items2);
  }

  showHierarchy_() {
    if (this.showHierarchy) {
      this.orgDone('showHierarchy_');
      this.showHierarchy = false;
      this.searchItems = [];
      return;
    }
    this.toggleMenu('showHierarchy');
    if (this.showDateRange) {
      this.datePicker.showDateRange_({});
    }
  }

  setModalType(item: ModelType) {
    this.activeModalType = item;
    this.activeModal = { label: '' };
    this.activeYear = '';
    this.selectActiveYear = false;
    this.filterService.inMemoryModel = this.makeStringify({
      activeModalType: this.activeModalType,
      activeModal: this.activeModal,
      activeYear: this.activeYear,
    });
    localStorage.setItem(
      'modal_type',
      this.makeStringify({
        activeModalType: this.activeModalType,
        activeModal: this.activeModal,
        activeYear: this.activeYear,
      })
    );
  }

  setModal(item: Model) {
    this.activeModal = JSON.parse(JSON.stringify(item));
    this.activeYear = JSON.parse(JSON.stringify(item.year));
    this.selectActiveYear = false;
    this.filterService.inMemoryModel = this.makeStringify({
      activeModalType: this.activeModalType,
      activeModal: this.activeModal,
      activeYear: this.activeYear,
    });
    localStorage.setItem(
      'modal_type',
      this.makeStringify({
        activeModalType: this.activeModalType,
        activeModal: this.activeModal,
        activeYear: this.activeYear,
      })
    );
    // this.activeModalType = item;
    // this.activeModal = { label: '' };
  }

  showMenu_() {
    if (this.showMenu) {
      this.showMenu = false;
      return;
    }
    this.toggleMenu('showMenu');
    if (this.showDateRange) {
      this.datePicker.showDateRange_({});
    }
  }

  showDateRange_() {
    this.toggleMenu('showDateRange');
  }

  openDatePicker() {
    this.showHierarchy = false;
    this.showMenu = false;
    this.showDateRange = true;
    this.showSourceTypeFilter = false;
  }

  orgDone(event: any) {
    let selected = this.active[this.active.length - 1].active;
    if (selected) {
      if (this.isEmpty(selected)) {
        selected = this.active[this.active.length - 2].active;
      }
    }

    if (!event) {
      this.showHierarchy = false;
    }
  }

  isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  modelDone() {
    if (this.selectActiveYear === false) {
      this.activeYear = '';
    }
    this.showMenu = false;
    // this.filterService.updateModel('', this.activeModalType.label, this.activeModal.label);
    this.getSurveyVersionsData(false);
    // this.filterService.updateModel(this.activeYear, this.activeModalType.label, this.activeModal.label);
    this.filterService.updateModel(this.activeYear, this.activeModalType.label, this.activeModal.label);
    this.mapSurveyQuestionsList();
    this.mapSurveyResponseList();
  }

  onMonthSelect(event: any) {
    this.currentMonth = event;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
    if (this.surveyVersionChangeSubscripton) {
      this.surveyVersionChangeSubscripton.unsubscribe();
    }
    if (this.showVocFilters && this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
    const d = this.filterService.getLocalStorageModalType();
    if (d && d.activeModal && d.activeModalType) {
      this.activeModal = d.activeModal;
      this.activeModalType = d.activeModalType;
    }
    this.dealerType = this.filterService.getDealerType().translate;
    this.permissions = this.ngxPermission.getPermissions();
    this.allowdealerType = this.permissions[Permission.REPORTING_SHOW_DEALERTYPE_DROPDOWN] ? true : false;

    this.surveyVersionChangeSubscripton = this.exportService.getSurveyVersion().subscribe((message: any) => {
      this.getSurveyVersionsData(false);
    });
    if (this.showlanguage) {
      this.initLanguageCtrl();
    }
    if (this.showCategoryFilters) {
      this.initAspectCategoryCtrl();
    }
    if (this.showVocFilters) {
      this.initVocDashboard();
    }

    if (this.showSourceTypeFilter) {
      this.initSmDashboard();
    }
    if (this.showSmVocFilters) {
      this.initSmVocDashboard();
    }

    // const filters = this.filterService.getFilterObject();
    // this.activeModalType = { label: filters.ActiveModalClass };
    // this.activeModal = { label: filters.ActiveModal };
    // this.activeModal = { label: filters.ActiveModalYear };

    // this.filterService.getAllOrgs().subscribe((res: any) => {

    //   this.items = res;
    //   this.active = JSON.parse(localStorage.getItem('active'));
    //   this.logActive = res;

    //   if (!this.active) {
    //     this.active = [];
    //     this.active.push({ id: 0, active: this.items[0], items: this.items });
    //     this.buildBreadCrumb(this.active);
    //   }

    //   if (this.currentLevel && this.currentParent) {
    //     this.setData();
    //   }

    //   this.items2 = JSON.parse(localStorage.getItem('hierarchy'));
    //   if (!this.items2) {
    //     this.items2 = [
    //       {
    //         label: this.active[0].active.label
    //       }
    //     ];
    //   }
    //   this.filterEmitter.emit(this.items2);

    //     this.active.forEach((element: ActiveOrgHierachy, index: number, arr: ActiveOrgHierachy[]) => {
    //                 element.items.forEach((elementchild: OrgHierachy, index: number, arr: OrgHierachy[]) => {
    //                     if(element.active[0].Name === filters.OrgName) {
    //                       this.add(element.id, elementchild);
    //                     }
    //                  });
    //      });

    // });

    // this.active.forEach((element: ActiveOrgHierachy, index: number, arr: ActiveOrgHierachy[]) => {
    // });

    // this.filterService.getVindecoderData().subscribe((res: any) => {
    //   this.modelsTypes = res;
    //   this.activeYear = this.modalYear[0];
    //   this.modalsOfYear = this.modelsTypes.filter(
    //     (x: any) => x.year === this.activeYear
    //   );
    //       this.modalsOfYear = this.modelsTypes;

    //       this.modalsOfYear.forEach((element: ModelType, index: number, arr: ModelType[]) => {
    //         if ( element.label === filters.ActiveModalClass) {
    //           this.setModalType(element);
    //         }
    //       //   element.children.forEach((elementChild: Model, indexChild: number, arrChild: Model[]) => {
    //       //     if ( elementChild.label === filters.ActiveModal) {
    //       //       this.setModal(elementChild);
    //       //     }
    //       //     if (elementChild.year === filters.ActiveModalYear) {
    //       //       this.selectYear(true);
    //       //       this.selectActiveYear = true;
    //       //     }
    //       //  });
    //       });
    //   });
  }

  toggleMenu(field: string) {
    this.showHierarchy = false;
    this.showMenu = false;
    this.showDateRange = false;
    this.showdealerType = false;
    this.showSurveyVersionYear = false;
    this.showLanguagesCtrl = false;
    this.showSentimentCtrl = false;
    this.showAspectCategoryCtrl = false;
    this.showSurveyQuestionCtrl = false;
    this.showSurveyScoreCtrl = false;
    this.showSourceTypeCtrl = false;
    this.showSurveyResponsesCtrl = false;
    this.showDateFilter = false;
    this.dealerquery = '';
    this.yearquery = '';
    this.modalList = [];
    this.searchItems = [];
    if (!field) {
      this.filterService.updateCalendarRange(this.currentCalendarDate);
      return;
    }
    switch (field.trim()) {
      case 'showHierarchy':
        this.showHierarchy = true;
        break;
      case 'showMenu':
        this.showMenu = true;
        break;
      case 'showDateRange':
        this.showDateRange = true;
        break;
      case 'showdealerType':
        this.showdealerType = true;
        break;
      case 'showSurveyVersionYear':
        this.showSurveyVersionYear = true;
        break;
      case 'showLanguagesCtrl':
        this.showLanguagesCtrl = true;
        break;
      case 'showSentimentCtrl':
        this.showSentimentCtrl = true;
        break;
      case 'showAspectCategoryCtrl':
        this.showAspectCategoryCtrl = true;
        break;
      case 'showSurveyQuestionCtrl':
        this.showSurveyQuestionCtrl = true;
        break;
      case 'showSourceTypeCtrl':
        this.showSourceTypeCtrl = true;
        break;
      case 'showSurveyScoreCtrl':
        this.showSurveyScoreCtrl = true;
        break;
      case 'showSurveyResponsesCtrl':
        this.showSurveyResponsesCtrl = true;
        break;
      case 'showDateFilter':
        this.showDateFilter = true;
        break;
    }
  }

  GetCategoryTranslation(key: string) {
    return this.translationHelper.GetCategoryTranslation(key);
  }
  datePickerDone(e: any) {
    this.filterService.setDateIdentifier(e.period);
    this.filterService.period = e.period;
    this.currentCalendarDate = e.date;
    // tslint:disable-next-line:max-line-length
    if (
      this.currentCalendarDate &&
      typeof this.currentCalendarDate === 'object' &&
      this.currentCalendarDate.length > 1
    ) {
      if (typeof this.currentCalendarDate[0] === 'string' && typeof this.currentCalendarDate[1] === 'string') {
        this.filterService.inMemoryCalendar = this.currentCalendarDate.join('|');
        localStorage.setItem('filter_date', this.currentCalendarDate.join('|'));
      }
    }
    this.filterService.updateCalendarRange(e.date);
    const activeTemp = cloneDeep(this.active);
    if (activeTemp) {
      let hide4thLevel = false;
      activeTemp.forEach((ac: any) => {
        ac.items.forEach((element: any) => {
          if (element.IsTerminate && element.TerminationDate) {
            const found = moment(element.TerminationDate).format(this.filterService.dateFormat);
            if (
              moment(found).isBetween(
                this.filterService.getCalenderRange()[0],
                this.filterService.getCalenderRange()[1],
                undefined,
                '(]'
              )
            ) {
              element.hide = false;
            } else {
              hide4thLevel = true;
              element.hide = true;
              ac.active = {};
              return;
            }
          } else {
            element.hide = false;
          }
        });
      });
      if (hide4thLevel) {
        activeTemp.splice(3, 1);
        this.items2.splice(2, 1);
        localStorage.setItem('hierarchy', this.makeStringify(this.items2));
      }
      this.filterService.inMemoryActiveHierarchy = activeTemp;
      localStorage.setItem('active', this.makeStringify(activeTemp));
      this.active = activeTemp;
    }
    if (e.close) {
      this.toggleMenu('');
    }
  }

  onDateRangeChange(event: any) {
    // this.filterService.updateCalendarRange(event);
  }

  SurveyVersionDone() {
    const sv = this.filterService.getSurveyVersion();
    if (sv) {
      this.filterService.surveyVersion = sv;
    }
    this.showSurveyVersionYear = !this.showSurveyVersionYear;
    this.mapSurveyQuestionsList();
    this.mapSurveyResponseList();
  }

  initVocDashboard() {
    if (this.showVocFilters) {
      this.filterService.filterDisabledStatus = true;
      this.initLanguageCtrl();
      this.initQuestionsCtrl();
      this.initSurveyResponseCtrl();
      this.initAspectCategoryCtrl();
      const includeUnassigned = this.permissions[Permission.VOC_SHOW_UNASSIGNED_COMMENTS] ? true : false;
      this.sentimentList = this.filterService.getSentimentData(includeUnassigned);
      this.sentimentListMapping = this.sentimentList.map((itm: any, index: number) => {
        if (itm.value === null) {
          return {
            label: this.translateService.instant('All Sentiment.label'),
            value: itm.value,
          };
        } else {
          return {
            label: this.GetSentimentTranslation(itm.label),
            value: itm.value,
          };
        }
      });
      // this.initLanguageChCtrl();
    }
    this.sentimentTitle =
      this.filterService.getSentiment().label !== this.translateService.instant('All Sentiment.label')
        ? this.GetSentimentTranslation(this.filterService.getSentiment().label)
        : this.translateService.instant('All Sentiment.label');
    this.surveyScore = this.filterService.getSurveyScore();
    if (this.surveyScore.Type !== '') {
      this.surveyScoreTitle = `${this.translateService.instant(this.surveyScore.Type)}: ${
        this.surveyScore.Score[0]
      } - ${this.surveyScore.Score[1]}`;
    } else {
      this.surveyScoreTitle = `Survey Score: ${this.surveyScore.Score[0]} - ${this.surveyScore.Score[1]}`;
    }

    this.subscription = this.filterService.getTab().subscribe((message) => {
      // this.resetQuestion();

      localStorage.removeItem('survey_responses');
      localStorage.removeItem('survey_question');
      localStorage.removeItem('Preserve_survey_responses');
      localStorage.removeItem('Preserve_survey_Questions');
      // localStorage.removeItem('Preserve_LanguageCode');
      this.surveyQuestionsList = [];
      this.surveyQuestionsListBak = [];
      this.surveyResponsesList = [];
      this.surveyResponsesListBak = [];
      this.surveyResponsesOptions = [];
      this.initQuestionsCtrl();
      this.initSurveyResponseCtrl();
    });
  }
  initSmVocDashboard() {
    this.initLanguageCtrl();
    this.initAspectCategoryCtrl();
  }
  initSmDashboard() {
    this.filterService.filterDisabledStatus = true;
    // To remove Program To Date
    this.dateDropDown.unshift({
      label: this.translateService.instant('TODAY'),
      key: 'R1',
      value: () => [moment().subtract(1, 'days'), moment()],
    });
    const programIndex = this.dateDropDown.findIndex((d) => d.key === 'PROGRAM TO DATE');
    this.dateDropDown.splice(programIndex, 1);

    // Source type filter does not show for SM Competitors report.
    if (!this.isSMCompetitorReport) {
      this.initSourceTypeCtrl();
    }

    if (this.showSMFilters === true) {
      this.initLanguageCtrl();
      this.initAspectCategoryCtrl();
      const includeUnassigned = this.permissions[Permission.SOCIALMEDIA_SHOW_UNASSIGNED_COMMENTS] ? true : false;
      this.sentimentList = this.filterService.getSentimentData(includeUnassigned);
      this.sentimentListMapping = this.sentimentList.map((itm: any, index: number) => {
        if (itm.value === null) {
          return {
            label: this.translateService.instant('All Sentiment.label'),
            value: itm.value,
          };
        } else {
          return {
            label: this.GetSentimentTranslation(itm.label),
            value: itm.value,
          };
        }
      });
    }
  }

  GetSentimentTranslation(key: string) {
    return this.translationHelper.GetSentimentTranslation(key);
  }

  initSourceTypeCtrl(isCache: boolean = false) {
    this.sourceTypeList = this.filterService.getSourceType();
    if (!this.sourceTypeList) {
      this.sourceTypeList = this.filterService.getSmReviewTypes();
    }

    this.filterService.setSourceType(this.sourceTypeList);
  }

  mapSurveyQuestionsList() {
    this.filterService.getSurveyQuestionData().subscribe(
      (res: ISurveyQuestion[]) => {
        if (res) {
          localStorage.removeItem('survey_question');
          this.surveyQuestionTitle = 'All Comment Questions';
          res.map((val: any) => {
            val.IsChecked = true;
            val.IsSelected = false;
          });
          res.forEach((val: any) => {
            val.IsChecked = true;
            val.IsSelected = false;
            val.Title = val.Title.replace(/(<([^>]+)>)/gi, '')
              .replace('{{', '')
              .replace('}}', '');
            val.Options.forEach((opt: any) => {
              opt.IsChecked = true;
              opt.IsSelected = false;
            });
          });

          res.unshift({
            Id: 'All',
            QuestionId: '',
            Title: 'All',
            Type: 'toggle',
            Options: [],
            IsChecked: true,
            IsSelected: false,
            IsAddClass: '',
          });
          // this.surveyResponsesOptions = [];
          this.surveyQuestionsListBak = res;
          this.surveyQuestionsList = res;
          this.filterService.setSurveyQuestion(this.surveyQuestionsListBak);
        }
      },
      (error: any) => {}
    );
  }

  // resetOnTabCtrl() {

  // }

  showLanguages() {
    if (this.showLanguagesCtrl) {
      this.showLanguagesCtrl = false;
      return;
    }
    this.toggleMenu('showLanguagesCtrl');
  }

  changeLanguage(data: ILanguages) {
    this.filterService.setLanguage(data);
    this.showLanguagesCtrl = false;
    // if (data.LanguageCode === null) {
    //   data.LanguageCode = '';
    // }
    this.languageTitle = data && data.LanguageCode !== null ? `${data.Name}` : `${data.Name}`;
    // localStorage.removeItem('survey_question');
    // localStorage.removeItem('survey_responses');
    // this.initQuestionsCtrl(true);
  }

  showSentiment() {
    if (this.showSentimentCtrl) {
      this.showSentimentCtrl = false;
      return;
    }
    this.toggleMenu('showSentimentCtrl');
  }

  changeSentiment(data: any) {
    this.filterService.setSentiment(data);
    this.showSentimentCtrl = false;
    this.sentimentTitle = data.label;
  }

  showAspectCategory() {
    if (this.showAspectCategoryCtrl) {
      this.showAspectCategoryCtrl = false;
      return;
    }
    this.toggleMenu('showAspectCategoryCtrl');
  }

  aspectCategoriesDrill(response: any) {
    this.aspectCategoriesListFiltered.forEach((cat: any) => {
      cat.isSelected = false;
    });
    if (response != null && response.children != null && response.children.length > 0) {
      this.aspectCategoriesListFiltered = response.children;
      this.aspectCategoriesBreadcrum.push({
        id: response.Name,
        title: response.Label,
        translatedTitle: this.GetCategoryTranslation(response.Label),
        // level: this.aspectCategoriesBreadcrum.length
        level: response.Level,
      });
      this.filterService.setAspectCategoryBC(this.aspectCategoriesBreadcrum);
    } else {
      response.isSelected = true;
    }
    this.aspectCategorySelected.label = response.Label;
    this.aspectCategorySelected.key = response.Name;
    this.aspectCategorySelected.leaf = response.Level;
    if (this.aspectCategorySelected) {
      this.filterService.setAspectCategory(this.aspectCategorySelected);
    }
    this.aspectCategoryTitle = this.GetCategoryTranslation(this.aspectCategorySelected.label);
  }

  aspectCategoriesBreadcrumReset(breadcrum: IAspectCategoriesBreadcrum) {
    if (breadcrum == null) {
      this.aspectCategoryTitle = 'All Categories';
      this.aspectCategoriesListFiltered = this.aspectCategoriesList;
      this.aspectCategoriesBreadcrum = [];
      this.aspectCategorySelected = {};
      // this.filterService.setAspectCategory({ id: 0, name: this.aspectCategoryTitle });
      localStorage.removeItem('voc_category');
      localStorage.removeItem('voc_category_bc');
      this.aspectCategoriesRec2(this.aspectCategoriesListFiltered);
    } else {
      this.aspectCategoriesRec(breadcrum, this.aspectCategoriesList);
      if (this.aspectCategoriesBreadcrum.length && this.aspectCategoriesBreadcrum.length > 0) {
        this.aspectCategoryTitle = this.translationHelper.GetCategoryTranslation(breadcrum.title);
        this.filterService.setAspectCategory({
          label: breadcrum.title,
          key: breadcrum.id.toString(),
          leaf: breadcrum.level,
        });
        this.filterService.setAspectCategoryBC(this.aspectCategoriesBreadcrum);
      }
      this.acrecFlag = true;
    }
  }

  aspectCategoryDone() {
    if (this.aspectCategorySelected) {
      this.filterService.setAspectCategory(this.aspectCategorySelected);
    }
    // this.showAspectCategoryCtrl = false;
    this.aspectCategoryTitle = this.aspectCategorySelected.label;
  }

  initAspectCategoryCtrl() {
    this.filterService.getAspectCategoryData().subscribe(
      (res: any) => {
        if (res) {
          const categories = this.unflatten(res).map((x: any) => {
            x.isSelected = false;
            if (x.children) {
              x.children.map((y: any) => {
                y.isSelected = false;
                if (y.children) {
                  y.children.map((z: any) => {
                    z.isSelected = false;
                    return z;
                  });
                }
                return y;
              });
            }
            return x;
          });
          this.aspectCategoriesList = categories;
          this.aspectCategoriesListFiltered = this.aspectCategoriesList;
          const locCat = this.filterService.getAspectCategory();
          if (locCat) {
            this.aspectCategoryTitle = this.GetCategoryTranslation(locCat.label);

            const locCatBC = this.filterService.getAspectCategoryBC();
            if (locCatBC && locCatBC.length > 0) {
              // const bc = locCatBC.pop();
              const bc = locCatBC[locCatBC.length - 1];

              if (locCat.label !== 'All Categories') {
                this.aspectCategoriesBreadcrumReset({
                  // id: locCat.key,
                  id: bc.id,
                  title: '',
                  level: null,
                  translatedTitle: '',
                });
              }

              this.aspectCategoriesBreadcrum = locCatBC;
            }
          }
        }
      },
      (error: any) => {}
    );
  }

  initLanguageCtrl() {
    const languageCodefilter = this.filterService.getFilterPreserveLanguageCode();
    if (languageCodefilter && languageCodefilter.length > 0) {
      this.languagesList = languageCodefilter;
      this.languageTitle = this.filterService.getFilterPreserveLanguageLabel();
    } else {
      this.filterService.getLanguagesData().subscribe(
        (res: any) => {
          if (res) {
            this.languagesList = res;
            this.languagesList.map((x) => (x.checked = true));
            this.languagesList.unshift({
              Label: this.translateService.instant('All'),
              Name: 'All',
              DisplayName: null,
              Value: null,
              LanguageCode: null,
              Translate: null,
              checked: true,
            });
            this.languageTitle = 'All Languages';
          }
        },
        (error: any) => {}
      );
    }
  }

  initQuestionsCtrl() {
    this.surveyResponsesSearch = '';
    this.surveyQuestionsList = this.filterService.getSurveyQuestion();

    if (this.surveyQuestionsList && this.surveyQuestionsList.length > 0) {
      this.surveyQuestionsList = this.surveyQuestionsList;
      if (this.surveyQuestionsList[0] && this.surveyQuestionsList[0].Id.toLocaleLowerCase() !== 'all') {
        this.surveyQuestionsList.unshift({
          Id: 'All',
          QuestionId: '',
          Title: 'All',
          Type: 'toggle',
          Options: [],
          IsChecked: true,
          IsSelected: false,
          IsAddClass: '',
        });
      }
    } else {
      this.mapSurveyQuestionsList();
    }
    this.surveyQuestionsListBak = this.surveyQuestionsList;
    this.surveyQuestionLabel();
  }

  showSurveyQuestion() {
    this.surveyQuestionSearch = '';
    this.surveyQuestionsList = [];
    this.surveyQuestionsList = this.surveyQuestionsListBak.map((itm) => {
      return itm;
    });

    if (this.showSurveyQuestionCtrl) {
      this.showSurveyQuestionCtrl = false;
      return;
    }
    this.toggleMenu('showSurveyQuestionCtrl');

    this.AllSelectionToggle();
  }

  LanguageSelection(event: any, lan: any) {
    if (lan && lan.Name === 'All') {
      if (lan.checked) {
        this.languageTitle = 'All Languages';
        this.languagesList.map((x) => (x.checked = true));
      } else {
        this.languageTitle = 'All Languages';
        this.languagesList.map((x) => (x.checked = false));
      }
    }
    let totalLanguages = 0;
    let isSelectedlanguages = 0;
    if (this.languagesList) {
      totalLanguages = this.languagesList.filter((x) => x.Name !== 'All').length;
      isSelectedlanguages = this.languagesList.filter((x) => x.Name !== 'All' && x.checked === true).length;
    }

    if (totalLanguages === isSelectedlanguages) {
      this.languagesList.forEach((language) => {
        if (language.Name === 'All') {
          language.checked = true;
        }
      });
      this.languageTitle = 'All Languages';
    } else {
      this.languagesList.forEach((language) => {
        if (language.Name === 'All') {
          language.checked = false;
        }
      });
      if (isSelectedlanguages > 1) {
        this.languageTitle = 'Multiple Selected';
      } else if (isSelectedlanguages === 0) {
        this.languageTitle = 'All Languages';
      } else if (isSelectedlanguages === 1) {
        if (lan.checked) {
          this.languageTitle = lan.Name;
        } else {
          this.languageTitle = this.languagesList.find((x) => x.checked === true)
            ? this.languagesList.find((x) => x.checked === true).Name
            : '';
        }
      }
    }
    this.filterService.setFilterPreserveLanguageCode(this.languagesList);
    this.filterService.setFilterPreserveLanguageLabel(this.languageTitle);
  }

  SurveyQuestionSelection(event: any, sq: any) {
    this.surveyResponsesSearch = '';
    this.surveyResponsesOptions = [];
    if (sq.toLowerCase() === 'All'.toLowerCase()) {
      this.surveyQuestionsList.forEach(function (val: any) {
        val.IsChecked = event;
      });
    }
    this.AllSelectionToggle();
  }

  AllSelectionToggle() {
    const cnt = this.surveyQuestionsList.length;
    const surveyQuestionsListTmp = this.surveyQuestionsList.filter(function (val: any) {
      return val.Id.toLowerCase() !== 'all' && val.IsChecked === true;
    });
    const flag = surveyQuestionsListTmp.length === cnt - 1 ? true : false;
    if (this.surveyQuestionsList[0] && this.surveyQuestionsList[0].Id.toLocaleLowerCase() === 'all') {
      this.surveyQuestionsList[0].IsChecked = flag;
    }
  }

  surveyQuestionChange($event: any, sq: ISurveyQuestion) {
    sq.IsChecked = !sq.IsChecked;
    // this.surveyResponsesList = this.surveyQuestionsList.filter(function (val: any) {
    //   return val.IsChecked === true;
    // });
  }

  onSurveyQuestionSearch(event: any) {
    if (event.target.value !== null || event.target.value !== '') {
      let flag = false;
      if (this.surveyQuestionsList[0] && this.surveyQuestionsList[0].Id.toLocaleLowerCase() === 'all') {
        flag = this.surveyQuestionsList[0].IsChecked;
      }

      this.surveyQuestionsList = this.surveyQuestionsListBak.filter((val: any) => {
        return val.Id.toLowerCase().includes(event.target.value.toLowerCase());
      });

      // if (this.surveyQuestionsList[0] && this.surveyQuestionsList[0].Id.toLocaleLowerCase() !== 'all survey questions') {
      //   this.surveyQuestionsList.unshift({
      //     Id: 'All Survey Questions',
      //     QuestionId: '',
      //     Title: 'All',
      //     Type: 'toggle',
      //     Options: [],
      //     IsChecked: flag,
      //     IsSelected: false,
      //     IsAddClass:''
      //   });
      // }

      this.AllSelectionToggle();
    }
  }

  surveyQuestionDone_rc2() {
    this.surveyQuestionsList.forEach((itm: any) => {
      this.surveyQuestionsListBak.forEach((qb) => {
        if (qb.Id === itm.Id) {
          qb.IsChecked = itm.IsChecked;
        }
      });
    });

    // this.surveyResponsesList = this.surveyQuestionsListBak.filter(function (val: any) {
    //   return ((val.Id.toLowerCase() !== 'all') && (val.IsChecked === true));
    // });

    this.surveyQuestionLabel();
    // this.filterService.setSurveyResponses(this.surveyResponsesList);
    this.filterService.setSurveyQuestion(this.surveyQuestionsListBak);
    this.showSurveyQuestionCtrl = false;
  }

  surveyQuestionDone() {
    this.surveyQuestionsList.forEach((itm: any) => {
      this.surveyQuestionsListBak.forEach((qb) => {
        if (qb.Id === itm.Id) {
          qb.IsChecked = itm.IsChecked;
        }
      });
    });

    // this.surveyResponsesList = this.surveyQuestionsListBak.filter(function (val: any) {
    //   return ((val.Id.toLowerCase() !== 'all survey questions') && (val.IsChecked === true));
    // });

    this.surveyQuestionLabel();
    //  this.filterService.setSurveyResponses(this.surveyResponsesList);
    this.filterService.setSurveyQuestion(this.surveyQuestionsListBak);
    this.showSurveyQuestionCtrl = false;
  }

  surveyQuestionLabel() {
    if (this.surveyQuestionsListBak) {
      const surveyQuestionsListTmp = this.surveyQuestionsListBak.filter(function (val: any) {
        return val.Id.toLowerCase() !== 'all' && val.IsChecked === true;
      });
      const sqLenBak = this.surveyQuestionsListBak.length - 1;
      const sqLen = surveyQuestionsListTmp.length;

      if (sqLen === 0 || sqLenBak === sqLen) {
        this.surveyQuestionTitle = 'All Comment Questions';
      } else if (sqLen === 1) {
        this.surveyQuestionTitle = surveyQuestionsListTmp[0].Id;
      } else {
        this.surveyQuestionTitle = 'Multiple Selected';
      }
    }
  }

  showSurveyScore() {
    if (this.showSurveyScoreCtrl) {
      this.showSurveyScoreCtrl = false;
      return;
    }
    this.toggleMenu('showSurveyScoreCtrl');
  }

  silderBarRange(type: string, firstValue: number, secondValue: number) {
    this.surveyScore = {
      Id: '',
      Type: type,
      Score: [firstValue, secondValue],
      Types: [],
    };
    this.filterService.setSurveyScore(this.surveyScore);
    this.surveyScoreTitle = `${this.translateService.instant(this.surveyScore.Type)}: ${this.surveyScore.Score[0]} - ${
      this.surveyScore.Score[1]
    }`;
  }

  changeSurveyScore(event: any) {
    this.surveyScore.Type = 'Survey Score';
    this.filterService.setSurveyScore(this.surveyScore);
    this.surveyScoreTitle = `${this.translateService.instant('Survey Score')}: ${this.surveyScore.Score[0]} - ${
      this.surveyScore.Score[1]
    }`;
  }

  surveyScoreDone() {
    this.filterService.setSurveyScore(this.surveyScore);
    this.showSurveyScoreCtrl = false;
    this.surveyScoreTitle = `${this.surveyScore.Type}: ${this.surveyScore.Score[0]} - ${this.surveyScore.Score[1]}`;
  }

  initSurveyResponseCtrl() {
    this.surveyResponsesSearch = '';
    const filterdata = this.filterService.getFilterPreserveSurveyResponses();
    if (filterdata && filterdata.length > 0) {
      this.surveyResponsesList = filterdata;
      this.surveyResponsesTitle = this.filterService.getFilterPreserveSurveyresponseLabel();
      this.surveyResponsesOptions = this.filterService.getFilterPreserveSurveyResponsesOptions();
    } else {
      this.mapSurveyResponseList();
    }
  }

  mapSurveyResponseList() {
    // this.filterService.resetSurveyResponse();
    this.filterService.getSurveyResponseData().subscribe(
      (res: ISurveyQuestion[]) => {
        if (res) {
          localStorage.removeItem('survey_response');
          this.surveyResponsesTitle = 'All Survey Responses';
          res.map((val: any) => {
            val.IsChecked = false;
            val.IsSelected = false;
            val.IsAddClass = '';
          });
          res.forEach((val: any) => {
            val.Id.includes('$$') ? (val.Id = val.Id.replace('$$', '')) : (val.Id = val.Id);
            val.IsChecked = false;
            val.IsSelected = false;
            val.Title = val.Title.replace(/(<([^>]+)>)/gi, '')
              .replace('{{', '')
              .replace('}}', '');
            val.Options.forEach((opt: any) => {
              opt.IsChecked = false;
              opt.IsSelected = false;
            });
          });

          res.sort((a, b) => (a.Id > b.Id ? 1 : -1));

          res.unshift({
            Id: 'All',
            QuestionId: '',
            Title: 'All',
            Type: 'toggle',
            Options: [],
            IsChecked: false,
            IsSelected: false,
            IsAddClass: '',
          });

          this.surveyResponsesListBak = res;
          this.surveyResponsesList = res;
          this.surveyResponsesListSearch = res;
          this.filterService.setSurveyResponses(this.surveyResponsesListBak);
        }
      },
      (error: any) => {}
    );
  }

  showSurveyResponses() {
    this.surveyResponsesSearch = '';
    // this.surveyResponsesList = this.surveyResponsesListBak.map((itm) => {
    //   return itm;
    // });
    if (this.filterService.getFilterPreserveSurveyResponses()) {
      this.surveyResponsesList = this.filterService.getFilterPreserveSurveyResponses();
      this.surveyResponsesListBak = this.filterService.getFilterPreserveSurveyResponses();
      this.surveyResponsesOptions = this.filterService.getFilterPreserveSurveyResponsesOptions();
    }
    if (this.showSurveyResponsesCtrl) {
      this.showSurveyResponsesCtrl = false;
      return;
    }
    this.toggleMenu('showSurveyResponsesCtrl');
  }

  surveyResponsesDrill_bak(event: any, response: ISurveyQuestion) {
    this.surveyResponsesList.forEach((val: any, index: number) => {
      val.IsSelected = false;
      if (val.Id === response.Id) {
        val.IsSelected = true;
      }
    });
    if (response != null && response.Options != null) {
      this.surveyResponsesOptions = [];
      this.surveyResponsesOptions = response.Options;
    } else {
      this.surveyResponsesOptions = [];
    }

    // this.surveyResponsesSearch = '';
    // this.surveyResponsesOptions = [];
    // if (sq.toLowerCase() === 'All Survey Questions'.toLowerCase()) {
    //   this.surveyQuestionsList.forEach(function (val: any) {
    //     val.IsChecked = event;
    //   });
    // }
    // this.AllSelectionToggle();
  }

  checkAlredyExist(data: any[], Id: any) {
    const exist = data.find((x) => x.Id === Id);
    if (exist) {
      return false;
    } else {
      return true;
    }
  }
  IscheckSurveyresponseCount(datalist: any[], id: any, IsResponse: boolean = false) {
    if (id) {
      let totalCount = 0;
      let isCheckCount = 0;
      let optionCount = 0;
      if (datalist) {
        totalCount = datalist.filter((x) => x.Id !== 'All').length;
        isCheckCount = datalist.filter((x) => x.Id !== 'All' && x.IsChecked === true).length;
        if (IsResponse) {
          datalist.map((x) => {
            if (x.Id !== 'All') {
              if (x.Options) {
                x.Options.map((op: any) => {
                  if (op.IsChecked) {
                    optionCount++;
                  }
                });
              }
            }
          });
        }
      }
      return { total: totalCount, checked: isCheckCount, optionChk: optionCount };
    } else {
      return null;
    }
  }

  surveyResponsesDrill(event: any, response: ISurveyQuestion) {
    if (response.Id === 'All') {
      this.surveyResponsesList.forEach((i) => {
        if (response.IsChecked) {
          this.IsAllSelected = true;
          i.IsChecked = true;
          i.IsAddClass = '';
          if (i.Options.length > 0) {
            i.Options.map((op) => {
              op.IsChecked = true;
              op.IsSelected = true;
            });
          }
          this.surveyresponsefiled.push(i);
        } else {
          this.surveyResponsesList.map((x) => {
            x.IsChecked = false;
            x.IsSelected = false;
            if (x.Options.length > 0) {
              x.Options.map((op) => {
                op.IsChecked = false;
                op.IsSelected = false;
              });
            }
          });
          i.IsChecked = false;
          i.IsAddClass = '';
          this.surveyResponsesOptions = [];
          this.surveyresponsefiled = [];
          this.IsAllSelected = false;
        }
      });
    } else {
      if (response.IsChecked) {
        this.surveyResponsesList.forEach((res) => {
          if (response.IsChecked) {
            if (res.Id === response.Id) {
              if (res.Options) {
                res.Options.forEach((op) => {
                  op.IsChecked = true;
                  op.IsSelected = true;
                });
              }
            }
          }
        });
      } else {
        this.surveyResponsesList.forEach((res) => {
          if (res.Id === response.Id) {
            if (res.Options) {
              res.Options.forEach((op) => {
                op.IsChecked = false;
                op.IsSelected = false;
              });
            }
          }
        });
      }
      if (response && response.Options) {
        this.surveyResponsesOptions = [];
        this.surveyResponsesOptions = response.Options;
      }
      this.surveyResponsesList.map((res) => {
        const responseCount = this.IscheckSurveyresponseCount(this.surveyResponsesList, response.Id);
        if (responseCount) {
          if (responseCount.total === responseCount.checked) {
            res.IsChecked = true;
            res.IsAddClass = '';
          } else if (responseCount.checked === 0) {
            if (res.Id === 'All') {
              res.IsChecked = false;
              res.IsSelected = false;
            }
            res.IsSelected = false;
          } else if (responseCount.checked >= 1 && responseCount.total !== responseCount.checked) {
            // if (res.Id === 'All') {
            //   res.IsChecked = true;
            //   res.IsAddClass = 'secondary-checkbox';
            // }
            if (res.IsChecked && res.Id !== 'All') {
              res.IsAddClass = 'secondary-checkbox';
              const resOption = this.IscheckSurveyresponseCount(res.Options, response.Id);
              if (resOption.total === resOption.checked) {
                res.IsAddClass = '';
              } else if (resOption.checked >= 1 && resOption.checked !== resOption.total) {
                res.IsAddClass = 'secondary-checkbox';
              }
            }
          }
        }
        const responseOption = this.IscheckSurveyresponseCount(this.surveyResponsesOptions, response.Id);
        if (responseOption) {
          if (responseOption.total === responseOption.checked) {
            if (res.Id === response.Id) {
              if (res.IsChecked) {
                res.IsChecked = true;
                res.IsAddClass = '';
              }
            }
          } else {
            if (res.Id === response.Id) {
              if (res.IsChecked) {
                res.IsChecked = true;
                res.IsAddClass = 'secondary-checkbox';
              }
            }
          }
        }
      });
    }
  }

  surveyResponsesOptSelected(opt: any) {
    this.surveyResponsesOptions.map((op) => {
      if (op.Id === opt.Id) {
        if (opt.IsChecked) {
          op.IsChecked = true;
          op.IsSelected = true;
        } else {
          op.IsChecked = false;
          op.IsSelected = false;
        }
      }
    });

    this.surveyResponsesList.map((res) => {
      const responseOption = this.IscheckSurveyresponseCount(this.surveyResponsesOptions, opt.Id);
      if (responseOption) {
        if (res.Options) {
          res.Options.map((op) => {
            if (op.Id === opt.Id) {
              op.IsChecked = opt.IsChecked;
              if (responseOption.total === responseOption.checked) {
                res.IsChecked = true;
                res.IsSelected = true;
                res.IsAddClass = '';
                const responseCount = this.IscheckSurveyresponseCount(this.surveyResponsesList, opt.Id);
                if (responseCount.checked !== responseCount.total && responseCount.checked >= 1) {
                  this.surveyResponsesList.map((x) => {
                    // if (x.Id === 'All') {
                    //   x.IsChecked = true;
                    //   x.IsAddClass = 'secondary-checkbox';
                    // }
                    if (x.IsChecked && x.Id !== res.Id && x.Id !== 'All') {
                      let t = 0;
                      let ck = 0;
                      if (x.Options) {
                        x.Options.map((op) => {
                          t++;
                          if (op.IsChecked) {
                            ck++;
                          }
                        });
                        if (t === ck) {
                          x.IsAddClass = '';
                        } else if (t !== ck && ck >= 1) {
                          x.IsAddClass = 'secondary-checkbox';
                        }
                      }
                    }
                  });
                } else if (responseCount.checked === responseCount.total) {
                  this.surveyResponsesList.map((x) => {
                    if (x.Id === 'All') {
                      x.IsChecked = true;
                      x.IsAddClass = '';
                    }
                    if (x.IsChecked && x.Id !== res.Id) {
                      x.IsAddClass = '';
                    }
                  });
                }
              }
              if (responseOption.checked === 0) {
                res.IsChecked = false;
                res.IsSelected = false;
                const responseCount = this.IscheckSurveyresponseCount(this.surveyResponsesList, opt.Id);
                if (responseCount.checked === 0) {
                  this.surveyResponsesList.map((x) => {
                    if (x.Id === 'All') {
                      x.IsChecked = false;
                    }
                  });
                } else if (responseCount.checked >= 1 && responseCount.total !== responseCount.checked) {
                  this.surveyResponsesList.map((x) => {
                    // if (x.Id === 'All') {
                    //   x.IsChecked = true;
                    //   x.IsAddClass = 'secondary-checkbox';
                    // }

                    if (x.IsChecked && x.Id !== res.Id && x.Id !== 'All') {
                      let t = 0;
                      let ck = 0;
                      if (x.Options) {
                        x.Options.map((op) => {
                          t++;
                          if (op.IsChecked) {
                            ck++;
                          }
                        });
                        if (t === ck) {
                          x.IsAddClass = '';
                        } else if (t !== ck && ck >= 1) {
                          x.IsAddClass = 'secondary-checkbox';
                        }
                      }
                    }
                  });
                }
              } else if (responseOption.total !== responseOption.checked && responseOption.checked >= 1) {
                res.IsChecked = true;
                res.IsSelected = true;
                res.IsAddClass = 'secondary-checkbox';

                const responseCount = this.IscheckSurveyresponseCount(this.surveyResponsesList, opt.Id);
                if (responseCount.checked !== responseCount.total && responseCount.checked >= 1) {
                  this.surveyResponsesList.map((x) => {
                    // if (x.Id === 'All') {
                    //   x.IsChecked = true;
                    //   x.IsAddClass = 'secondary-checkbox';
                    // }
                    if (x.IsChecked && x.Id !== res.Id && x.Id !== 'All') {
                      let t = 0;
                      let ck = 0;
                      if (x.Options) {
                        x.Options.map((op) => {
                          t++;
                          if (op.IsChecked) {
                            ck++;
                          }
                        });
                        if (t === ck) {
                          x.IsAddClass = '';
                        } else if (t !== ck && ck >= 1) {
                          x.IsAddClass = 'secondary-checkbox';
                        }
                      }
                    }
                  });
                } else if (responseCount.checked === responseCount.total) {
                  this.surveyResponsesList.map((x) => {
                    if (x.Id === 'All') {
                      x.IsChecked = true;
                      x.IsAddClass = '';
                    }
                    if (x.IsChecked && x.Id !== res.Id) {
                      x.IsAddClass = '';
                    }
                  });
                }
              }
            }
          });
        }
      }
    });
  }

  setSurveyresponsetitle() {
    const responseOption = this.IscheckSurveyresponseCount(this.surveyResponsesOptions, true);
    const responseCount = this.IscheckSurveyresponseCount(this.surveyResponsesList, true, true);
    if (responseCount.checked === responseCount.total) {
      this.surveyResponsesTitle = 'All Survey Responses';
    } else if (responseCount.checked > 1) {
      this.surveyResponsesTitle = 'Multiple Selected';
    } else if (responseCount.checked === 1 && responseOption.checked === 1) {
      const responsetitile = this.surveyResponsesList.find((x) => x.Id !== 'All' && x.IsChecked === true);
      const optiontitle = this.surveyResponsesOptions.find((x) => x.Id !== 'All' && x.IsChecked === true);
      this.surveyResponsesTitle = responsetitile.Id + ' > ' + optiontitle.Title;
    } else if (responseCount.checked === 1 && responseOption.checked > 1) {
      const responsetitile = this.surveyResponsesList.find((x) => x.Id !== 'All' && x.IsChecked === true);
      this.surveyResponsesTitle = responsetitile.Id + ' > ' + this.translateService.instant('Multiple Selected');
    } else if (responseCount.checked === 1 && responseOption.optionChk > 1) {
      const responsetitile = this.surveyResponsesList.find((x) => x.Id !== 'All' && x.IsChecked === true);
      this.surveyResponsesTitle = responsetitile.Id + ' > ' + this.translateService.instant('Multiple Selected');
    } else {
      this.surveyResponsesTitle = 'All Survey Responses';
    }
  }
  onSurveyResponsesSearch(event: any) {
    if (event.target.value !== null || event.target.value !== '' || event !== '') {
      // this.surveyResponsesOptions = [];

      this.surveyResponsesList = this.surveyResponsesListBak.filter((val: any) => {
        val.IsSelected = false;
        // return (val.Id.toLowerCase().includes(event.target.value.toLowerCase()) && val.IsChecked === true && val.Id.toLowerCase() !== 'all');
        return val.Id.toLowerCase().includes(event.target.value.toLowerCase());
      });
    }
  }

  surveyResponsesDone_rc2() {
    // this.surveyQuestionDone();
    // this.surveyResponsesOptions = [];

    this.surveyResponsesList.forEach((itm: any) => {
      this.surveyResponsesListBak.forEach((qb) => {
        if (qb.Id === itm.Id) {
          qb.IsChecked = itm.IsChecked;
        }
      });
    });

    // this.surveyResponsesList = this.surveyQuestionsListBak.filter(function (val: any) {
    //   return ((val.Id.toLowerCase() !== 'all') && (val.IsChecked === true));
    // });

    this.surveyResponseLabel();
    // this.filterService.setSurveyResponses(this.surveyResponsesList);
    this.filterService.setSurveyResponses(this.surveyResponsesListBak);

    this.showSurveyResponsesCtrl = false;
  }

  surveyResponsesDone() {
    this.setSurveyresponsetitle();

    this.surveyResponsesList.forEach((itm: any) => {
      this.surveyResponsesListBak.forEach((qb) => {
        if (qb.Id === itm.Id) {
          qb.IsChecked = itm.IsChecked;
          qb.IsSelected = itm.IsSelected;
          qb.IsAddClass = itm.IsAddClass;
          qb.Options = itm.Options;
        }
      });
    });
    const responseCount = this.IscheckSurveyresponseCount(this.surveyResponsesListBak, true, true);
    if (responseCount.checked === responseCount.total) {
      this.surveyResponsesListBak.map((x) => {
        if (x.Id === 'All') {
          x.IsChecked = true;
          x.IsAddClass = '';
        }
      });
    }
    if (responseCount.checked >= 1 && responseCount.checked !== responseCount.total) {
      this.surveyResponsesListBak.map((x) => {
        // if (x.Id === 'All') {
        //   x.IsChecked = true;
        //   x.IsAddClass = 'secondary-checkbox';
        // }
      });
    }

    if (this.surveyResponsesSearch !== '') {
      const responseOption = this.IscheckSurveyresponseCount(this.surveyResponsesOptions, true);
      const responseCount = this.IscheckSurveyresponseCount(this.surveyResponsesListBak, true, true);
      if (responseCount.checked === responseCount.total) {
        this.surveyResponsesTitle = 'All Survey Responses';
      } else if (responseCount.checked > 1) {
        this.surveyResponsesTitle = 'Multiple Selected';
      } else if (responseCount.checked === 1 && responseOption.checked === 1) {
        const responsetitile = this.surveyResponsesList.find((x) => x.Id !== 'All' && x.IsChecked === true);
        const optiontitle = this.surveyResponsesOptions.find((x) => x.Id !== 'All' && x.IsChecked === true);
        this.surveyResponsesTitle = responsetitile.Id + ' > ' + optiontitle.Title;
      } else if (responseCount.checked === 1 && responseOption.checked > 1) {
        const responsetitile = this.surveyResponsesList.find((x) => x.Id !== 'All' && x.IsChecked === true);
        this.surveyResponsesTitle = responsetitile.Id + ' > ' + this.translateService.instant('Multiple Selected');
      } else if (responseCount.checked === 1 && responseOption.optionChk > 1) {
        const responsetitile = this.surveyResponsesList.find((x) => x.Id !== 'All' && x.IsChecked === true);
        this.surveyResponsesTitle = responsetitile.Id + ' > ' + this.translateService.instant('Multiple Selected');
      } else {
        this.surveyResponsesTitle = 'All Survey Responses';
      }
      this.surveyResponsesOptions = [];
    }
    this.filterService.setFilterPreserveSurveyResponses(this.surveyResponsesListBak);
    this.filterService.setFilterPreserveSurveyResponsesOptions(this.surveyResponsesOptions);
    this.filterService.setFilterPreserveSurveyresponseLabel(this.surveyResponsesTitle);
    this.filterService.setsureyresponcefilter(this.surveyresponsefiled);
    this.surveyResponsesListSearch = this.surveyResponsesList;
    //    }
    // this.surveyQuestionDone();
    // this.surveyResponsesOptions = [];
    this.showSurveyResponsesCtrl = false;
  }

  surveyResponseLabel() {
    if (this.surveyResponsesListBak) {
      const surveyResponsesListTmp = this.surveyResponsesListBak.filter(function (val: any) {
        return val.Id.toLowerCase() !== 'all' && val.IsChecked === true;
      });
      const srLenBak = this.surveyResponsesListBak.length - 1;
      const srLen = surveyResponsesListTmp.length;

      if (srLen === 0 || srLenBak === srLen) {
        // this.surveyResponsesTitle = 'All Survey Responses';
      } else if (srLen === 1) {
        // this.surveyResponsesTitle = surveyResponsesListTmp[0].Id;
      } else {
        // this.surveyResponsesTitle = 'Multiple Selected';
      }
    }
  }

  public closeall() {
    this.OnFilter.emit('');
    this.showHierarchy = false;
    this.showMenu = false;
    this.showDateRange = false;
    this.filterService.filterDisabledStatus = false;
    if (this.showVocFilters) {
      this.filterService.filterDisabledStatus = true;
      this.showSurveyScoreCtrl = false;
      this.showAspectCategoryCtrl = false;
    }
    if (this.showDateRange) {
      this.datePicker.showDateRange_({});
    }
  }

  unflatten(arr: any) {
    let tree: any = [];
    arr.forEach((element: any) => {
      switch (element.Level) {
        case 2: {
          tree = element.Data[0].Nodes;
          tree.forEach((ele: any) => {
            ele.Level = element.Level;
          });
          break;
        }
        case 3: {
          element.Data.forEach((l2: any) => {
            for (let i = 0; i < tree.length; i++) {
              if (tree) {
                if (tree[i].Name === l2.Parent) {
                  tree[i]['children'] = l2.Nodes;
                  tree[i]['children'].forEach((ele: any) => {
                    ele.Level = element.Level;
                  });
                }
              }
            }
          });
          break;
        }
        case 4: {
          element.Data.forEach((l3: any) => {
            if (tree) {
              for (let i = 0; i < tree.length; i++) {
                if (tree[i].children) {
                  for (let j = 0; j < tree[i].children.length; j++) {
                    if (tree[i].children[j].Name === l3.Parent) {
                      tree[i].children[j]['children'] = l3.Nodes;
                      tree[i].children[j]['children'].forEach((ele: any) => {
                        ele.Level = element.Level;
                      });
                    }
                  }
                }
              }
            }
          });
          break;
        }
      }
    });
    return tree;
  }

  aspectCategoriesRec(bc: any, aspectCategoriesLst: any): any {
    aspectCategoriesLst.forEach((val: any, index: number, arr: any) => {
      if (this.acrecFlag) {
        if (val.Name === bc.id) {
          this.acrecFlag = false;
          if (val.children && val.children.length > 0) {
            this.aspectCategoriesListFiltered = val.children;
            this.aspectCategorySelected = val;
            let ind = this.aspectCategoriesBreadcrum.indexOf(bc);
            ++ind;
            this.aspectCategoriesBreadcrum.splice(ind, this.aspectCategoriesBreadcrum.length - ind);
            return val.children;
          }
        }
        if (val.children && val.children.length > 0) {
          this.aspectCategoriesRec(bc, val.children);
        }
      }
    });
  }

  aspectCategoriesRec2(aspectCategoriesLst: TreeNode[]): any {
    aspectCategoriesLst.forEach((val: any) => {
      val.isSelected = false;
      if (val.children && val.children.length > 0) {
        this.aspectCategoriesRec2(val.children);
      }
    });
  }
  removeHtmlTags(value: string) {
    return value.replace(/<\/?[^>]+(>|$)/g, '');
  }

  private getAndSetupData(reset: boolean = true) {
    this.filterService.getAllOrgs().subscribe((res: any) => {
      this.filterService.orgList = res;
      this.setUpDate(reset, res);
    });
  }

  private setUpDate(reset: boolean, res: any) {
    if (reset) {
      this.items = res;
      this.active = this.filterService.inMemoryActiveHierarchy || [];
      if (this.active.length > this.orgMaxLevel) {
        const nActive: any = [];
        this.active.forEach((act: any, index: number) => {
          if (act.id <= this.orgMaxLevel) {
            if (act.active.level === undefined || act.active.level <= this.orgMaxLevel) {
              nActive.push(act);
            }
          }
        });
        this.active = nActive;
        this.filterService.inMemoryActiveHierarchy = this.active;
      }
      this.logActive = res;
      if (this.active && this.active.length > 0) {
        this.buildBreadCrumb(this.active);
      } else {
        this.active = [];
        this.active.push({ id: 0, active: this.items[0], items: this.items });
        this.buildBreadCrumb(this.active);
        if (this.currentLevel && this.currentParent) {
          this.setData();
        }
      }
      this.items2 = JSON.parse(localStorage.getItem('hierarchy'));
      if (!this.items2) {
        this.items2 = [
          {
            label: this.active[0].active.label,
          },
        ];
      }
    } else {
      this.logActive = res;
      this.items = res;
      this.setData();
    }
  }

  // scope end
}
