<div class="modal-form">
  <div class="row"><h3>Bug Report</h3></div>
  <form name="bugReportForm" novalidate>
    <div class="row">
      <div class="col-left"><label>Type</label></div>
      <div class="col-right">
        <!-- <mat-select
          data-live-search="true"
          title="Select"
          selectpicker
          toggle-dropdown
          name="Type"
          [(ngModel)]="bugreport.Type"
          data-width="100%"
        >
          <mat-option *ngFor="let col of options.types" [value]="col">
            {{ col }}
          </mat-option>
        </mat-select> -->
      </div>
    </div>
    <div class="row">
      <div class="col-left"><label>Priority</label></div>
      <div class="col-right">
        <!-- <mat-select
          data-live-search="true"
          title="Select"
          selectpicker
          toggle-dropdown
          name="Priority"
          [(ngModel)]="bugreport.Priority"
          data-width="100%"
        >
          <mat-option *ngFor="let col of options.priority" [value]="col">
            {{ col }}
          </mat-option>
        </mat-select> -->
      </div>
    </div>
    <div class="row">
      <div class="col-left"><label>Subject</label></div>
      <div class="col-right">
        <input
          #subject="ngModel"
          [ngClass]="{
            error: subject.invalid && (subject.dirty || subject.touched)
          }"
          type="text"
          name="subject"
          required
          [(ngModel)]="bugreport.Subject"
          pInputText
        />
        <div class="info error" *ngIf="(subject.dirty || subject.touched) && subject.errors?.required">
          <p>Subject is required.</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-left"><label>Feedback</label></div>
      <div class="col-right">
        <textarea
          #feedback="ngModel"
          [ngClass]="{
            error: feedback.invalid && (feedback.dirty || feedback.touched)
          }"
          [(ngModel)]="bugreport.Feedback"
          name="feedback"
          required
        ></textarea>
        <div class="info error" *ngIf="(feedback.dirty || feedback.touched) && feedback.errors?.required">
          <p>Feedback is required.</p>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- <div class="modal__footer btn--action">
  <a class="btn btn--secondary" (click)="close()">Cancel</a>
  <a class="btn">Save</a>
  <a class="btn">Save</a>
</div> -->
