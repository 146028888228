import { Component, OnInit, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Subscription, interval, Observable, fromEvent } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import HighCharts from '@clearviewshared/highchart/highchart';
import { WidgetService } from '../widget.service';
import { CacheService } from '@app/shared/services/cache.service';
import { ITotalAlerts, AlertStatus, INpsScore } from '../widget-interface';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { drawPieChartDot, generateNpsScoreV1, generateNpsScoreV2 } from '@clearviewshared/highchart/helperfunctions';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import { debounceTime } from 'rxjs/operators';
import WidgetBase from '../widegt.base';
import { TranslationHelperService } from '@app/shared/services/translationHelper.service';
import { I18nService } from '@app/core';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { ContactStatisticsService } from '../../reporting/contact-statistics/contact-statistics.service';
import { environment } from '@env/environment';

@Component({
  selector: 'nps-score-widget',
  templateUrl: './nps-score.html',
  styleUrls: ['./nps-score.component.scss'],
})
export class NPSScoreComponent extends WidgetBase implements OnInit, OnDestroy {
  routerPrefix = '';
  _HighCharts = HighCharts;
  @Input() styleClass = '';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  @ViewChild('containerNpsScore', { read: ElementRef }) containerNpsScore: ElementRef;
  canRender = false;
  noResult = false;
  data: INpsScore[];
  dataModel: INpsScore[] = [];
  chartOptions: any = {};
  NpsScoreValue = 0;
  chart: any;
  name: string;
  colors = ['green', 'yellow', 'red', 'blue', 'orange', 'red', 'red'];
  secondsCounter = interval(120000);
  secondsCounterSub: Subscription;
  api: Subscription;
  private subscriptions: Subscription[] = [];
  reportingRoutePrefix: any = environment.reportingRoutePrefix;

  constructor(
    private router: Router,
    public widgetService: WidgetService,
    public translateService: TranslateService,
    private cacheService: CacheService,
    public gbfService: GlobalFilterService,
    public toastService: ToastService,
    private appConfig: AppConfigurationService,
    private translationHelper: TranslationHelperService,
    public i18nService: I18nService
  ) {
    super(gbfService, translateService);
    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }
      this.secondsCounter = interval(widgetThresholdVal);
    }
    fromEvent(window, 'resize')
      .pipe(debounceTime(1500))
      .subscribe((event) => {
        this.initChart();
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadWidget();
    this.subscriptions.push(
      this.cacheService.for(this.widgetService.npsSocreUrl).subscribe((res: any) => {
        if (res) {
          this.data = res.NpsScoreList ? res.NpsScoreList : [];
          this.NpsScoreValue = res.NpsScoreValue ? res.NpsScoreValue : 0;
          this.initChart();
          this.canRender = true;
        }
      })
    );
    if (this.from !== this.WIDGET_HOSTER) {
      this.subscriptions.push(
        this.widgetService.update.subscribe(() => {
          this.loadWidget();
        })
      );
      this.subscriptions.push(
        this.gbfService.gbfOnApplyFilter.subscribe(() => {
          this.loadWidget();
        })
      );
    }
  }
  routeToSurveyList(key: any) {
    let eventName = this.eventName;
    if (eventName != '') {
      setTimeout(() => {
        this.router.navigate(['/insights/reports/surveylist'], {
          queryParams: { nps: key, en: eventName },
        });
      }, 0);
    } else {
      setTimeout(() => {
        this.router.navigate(['/insights/reports/surveylist'], { queryParams: { nps: key } });
      }, 0);
    }
  }

  loadWidget() {
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    this.api = this.widgetService.getNpsScore({ apiConfig: this.apiConfig }).subscribe({
      next: (res: any) => {
        if (res) {
          this.data = res.NpsScoreList ? res.NpsScoreList : [];
          this.NpsScoreValue = res.NpsScoreValue ? res.NpsScoreValue : 0;
          this.canRender = true;
          this.widgetService.isAllLoaded = true;
          this.initChart();
        }
      },
      error: (error) => {
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
        this.data = [];
      },
      complete: () => {
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      },
    });
  }

  initChart() {
    this.chartOptions = {};
    this.dataModel = [];

    if (this.data) {
      const IsAllZero = this.data.find((x: any) => {
        return x.Count > 0;
      });

      if (!IsAllZero) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }

      let totalCout = 0;
      const charData: any[] = [];
      this.data.forEach((data: INpsScore, index: number) => {
        totalCout += data.Count;
      });

      this.data.map((itm, i) => {
        if (itm.Count !== 0) {
          itm.Percent = this.calcPrePercent(itm.Count, totalCout, 1);
        } else {
          itm.Percent = '0.0';
        }

        charData.push({
          // itm.Title
          name: this.translateService.instant(itm.Title),
          nametext: itm.Title,
          nameValue: itm.Key,
          data: itm.Count,
          y: itm.Count,
          Percent: itm.Percent,
          order: itm.Order,
          events: {
            legendItemClick: (e: any) => {
              if (e.target.visible) {
                totalCout -= e.target.data;
                npsCount = totalCout;
              } else {
                totalCout += e.target.data;
                npsCount = totalCout;
              }
              // this.chart.update({
              //   title: {
              //     text: "<span class='pie-chart'><span class='chart__title'>KPI</span> <span class='chart__count'>" +
              //     kpiPercent + '%' +
              //       "</span>  <span class='chart__label'>Alert Count</span> <span class='chart__link'>" +
              //       totalCout +
              //       "</span></span>",
              //   },
              // });
            },
          },
        });

        return itm;
      });

      const seriesData: any = [
        {
          type: 'pie',
          size: '100%',
          innerSize: '85%',
          tooltip: {
            pointFormat: '<b>{point.y}</b>',
            borderWidth: 0,
            backgroundColor: 'none',
            shadow: false,
            useHTML: true,
          },
          dataLabels: {
            enabled: false,
          },
          showInLegend: false,
          name: this.translateService.instant('Survey Count'),
          data: charData,
        },
      ];

      charData.sort((a, b) => (a.order > b.order ? 1 : -1));

      const plotOpt = {
        cursor: 'pointer',
        point: {
          events: {
            click: (e: any, b: any, c: any) => {
              setTimeout(() => {
                this.router.navigate(['/insights/reports/surveylist'], {
                  queryParams: { nps: e.point.nameValue },
                });
              }, 0);
            },
          },
        },
      };
      const kpiPercent = this.NpsScoreValue.toFixed(1);
      let npsCount: any = parseFloat(totalCout.toFixed(1));
      const link = `/portal/${this.reportingRoutePrefix}/reports/surveylist`;
      this.chartOptions = generateNpsScoreV2(
        seriesData,
        plotOpt,
        this.i18nService.transformNumber(parseFloat(kpiPercent)),
        `<a href=${link}>${npsCount}</a>`,
        this.translateService.instant('Survey Count'),
        this.i18nService
      );
      this.chart = this._HighCharts['Reflecx'].createOrUpdate(
        this.chart,
        this.containerNpsScore.nativeElement,
        this.chartOptions
      );
      // drawPieChartDot(this.chart);
    }
  }

  calcPrePercent(val: number, totalVal: number, decPlace: number) {
    let result = '0';
    const valArr = ((val / totalVal) * 100).toString().split('.');
    if (valArr.length > 1) {
      if (valArr[1].length > 0) {
        result = valArr[0].toString() + '.' + valArr[1].substring(0, decPlace);
      }
    } else {
      result = valArr[0].toString();
    }

    return result;
  }

  calcPrePer(val: number, decPlace: number) {
    let result = '0.0';
    const valArr = val.toString().split('.');
    if (valArr.length > 1) {
      if (valArr[1].length > 0) {
        result = valArr[0].toString() + '.' + valArr[1].substring(0, decPlace);
      }
    } else {
      result = valArr[0].toString();
    }

    return result;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribtion) => {
      subscribtion.unsubscribe();
    });

    if (this.secondsCounterSub) {
      this.secondsCounterSub.unsubscribe();
    }

    if (this.api) {
      this.api.unsubscribe();
    }
  }
}
