import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'keys',
  pure: false,
})
export class KeysPipe implements PipeTransform {
  transform(value: any, args: string[]): any {
    const keys: any = [];
    if (value) {
      Object.keys(value).forEach((key: any) => {
        keys.push({ key: key, value: value[key] });
      });
    }
    return keys;
  }
}
