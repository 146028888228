import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem, DynamicDialogConfig, DynamicDialogRef } from '@primeng';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'appc-report-chart',
  templateUrl: './report.chart.component.html',
  styleUrls: ['./report.chart.component.scss'],
})
export class ReportChartComponent implements OnInit {
  @ViewChild('header', { static: true }) headerRef: any;
  @ViewChild('footer', { static: true }) footerRef: any;
  @ViewChild('content', { static: true }) contentRef: any;
  optionsC: any = [];
  selectedPieValue = {};
  currentStep = 1;
  finalStep = 2;
  tabItems = [
    {
      active: true,
      key: 'PIE',
      icon: 'fal fa-chart-pie',
      html: 'Pie Chart',
      name: 'Pie Chart',
    },
    {
      active: false,
      icon: 'fal fa-chart-line',
      key: 'LINE',
      html: 'Line Column',
      name: 'Line Column',
    },
    {
      active: false,
      key: 'BAR',
      icon: 'fal fa-chart-bar',
      html: 'Bar Chart',
      name: 'Bar Chart',
    },
  ];
  activeItem = this.tabItems[0];
  columnHeaders: any;
  mColumns: any = [];
  constructor(
    public dialogConfig: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public translateService: TranslateService
  ) {
    const parsel = this.dialogConfig.data;
    this.columnHeaders = parsel.columnHeaders;
    this.mColumns = this.columnHeaders.map((column: any) => {
      return { label: column.Name, value: column.Name };
    });
    this.optionsC = [
      { label: this.translateService.instant('Line'), value: 'line' },
      { label: this.translateService.instant('Column'), value: 'Column' },
    ];
  }
  ngOnInit(): void {}
  setViewActive(item: any, index: number) {
    this.tabItems.forEach((i: any) => {
      i.active = false;
    });
    this.tabItems[index].active = true;
    this.activeItem = this.tabItems[index];
    this.currentStep = 1;
  }
  closeDialog() {
    this.ref.close();
  }
}
