import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { RestMessage } from '@app/core/types';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { FiltersService } from '@app/shared/services/filters.service';

@Injectable()
export class VocDashboardService {
  program$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  filter$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private filterService: FiltersService
  ) {}

  changeProgram(value: string) {
    // console.log('value', value);
    this.program$.next(value);
  }

  getProgramObservable() {
    return this.program$.asObservable();
  }

  changeFilter(value: any) {
    this.filter$.next(value);
  }

  getFilterObservable() {
    return this.filter$.asObservable();
  }

  getAspectCategories3() {
    const url = `/voc/categories`;
    return this.httpClient.cache().get<RestMessage>(`${url}`);
  }

  getAspectCategories(sid: any, cat: any) {
    const filters = this.filterService.getFilterObjectForVOCDashboard() as any;
    filters.SentimentType = sid;
    filters.CategoryId = undefined;
    filters.CategoryName = undefined;
    filters.CategoryLevel = undefined;
    // if (cat && cat.CategoryId && cat.CategoryName) {
    //   filters.CategoryId = cat.CategoryId;
    //   filters.CategoryName = cat.CategoryName;
    //   filters.CategoryLevel = cat.CategoryLevel;
    // } else {
    //   filters.CategoryId = undefined;
    //   filters.CategoryName = undefined;
    //   filters.CategoryLevel = undefined;
    // }
    const url = `/vocreports/treereport`;
    return this.httpClient.post<RestMessage>(`${url}`, filters);
  }

  getAspectCategoriesSocial(sid: any, cat: any) {
    //const filters = this.filterService.getFilterObjectForVOCDashboard() as any;
    const filters = this.filterService.getFilterObjectForTreeReportSM() as any;
    filters.SentimentType = sid;
    filters.CategoryId = undefined;
    filters.CategoryName = undefined;
    filters.CategoryLevel = undefined;

    // if (cat && cat.CategoryId && cat.CategoryName) {
    //   filters.CategoryId = cat.CategoryId;
    //   filters.CategoryName = cat.CategoryName;
    //   filters.CategoryLevel = cat.CategoryLevel;
    // } else {
    //   filters.CategoryId = undefined;
    //   filters.CategoryName = undefined;
    //   filters.CategoryLevel = undefined;
    // }
    const url = `/sm-reports/treereport`;
    return this.httpClient.post<RestMessage>(`${url}`, filters);
  }

  getTreeReportSentimentTab(sid: any, cat: any) {
    const filters = this.filterService.getFilterObjectForVOCDashboard() as any;
    // filters.SentimentType = sid;
    filters.CategoryId = undefined;
    filters.CategoryName = undefined;
    filters.CategoryLevel = undefined;
    // if (cat && cat.CategoryId && cat.CategoryName) {
    //   filters.CategoryId = cat.CategoryId;
    //   filters.CategoryName = cat.CategoryName;
    //   filters.CategoryLevel = cat.CategoryLevel;
    // } else {
    //   filters.CategoryId = undefined;
    //   filters.CategoryName = undefined;
    //   filters.CategoryLevel = undefined;
    // }

    const url = `/voc/treereportsentimenttab`;
    return this.httpClient.post<RestMessage>(`${url}`, filters);
  }

  getTreeReportSentimentTabSocial(sid: any, cat: any) {
    //const filters = this.filterService.getFilterObjectForVOCDashboard() as any;
    const filters = this.filterService.getFilterObjectForTreeReportSM() as any;

    if (cat && cat.CategoryId && cat.CategoryName) {
      filters.CategoryId = cat.CategoryId;
      filters.CategoryName = cat.CategoryName;
      filters.CategoryLevel = cat.CategoryLevel;
    } else {
      filters.CategoryId = undefined;
      filters.CategoryName = undefined;
      filters.CategoryLevel = undefined;
    }

    const url = `/sm-reports/treereportsentimenttab`;
    return this.httpClient.post<RestMessage>(`${url}`, filters);
  }

  getAspectCategories2(): Observable<any> {
    const url = `/voc/categories`;
    let categories: any;
    this.httpClient
      .cache()
      .get<RestMessage>(`${url}`)
      .subscribe(
        (res: any) => {
          if (res) {
            categories = this.unflatten(res).map((x: any) => {
              x.isSelected = false;
              if (x.children) {
                x.children.map((y: any) => {
                  y.isSelected = false;
                  if (y.children) {
                    y.children.map((z: any) => {
                      z.isSelected = false;
                      return z;
                    });
                  }
                  return y;
                });
              }
              return x;
            });
            return of(categories);
          }
        },
        (error: any) => {
          // categories = null;
          return of(null);
        }
      );
    return of(null);
  }

  unflatten(arr: any) {
    let tree: any = [];
    arr.forEach((element: any) => {
      switch (element.Level) {
        case 2: {
          tree = element.Data[0].Nodes;
          tree.forEach((ele: any) => {
            ele.Level = element.Level;
          });
          break;
        }
        case 3: {
          element.Data.forEach((l2: any) => {
            for (let i = 0; i < tree.length; i++) {
              if (tree[i].Label === l2.Parent) {
                tree[i]['children'] = l2.Nodes;
                tree[i]['children'].forEach((ele: any) => {
                  ele.Level = element.Level;
                });
              }
            }
          });
          break;
        }
        case 4: {
          element.Data.forEach((l3: any) => {
            for (let i = 0; i < tree.length; i++) {
              for (let j = 0; j < tree[i].children.length; j++) {
                if (tree[i].children[j].Label === l3.Parent) {
                  tree[i].children[j]['children'] = l3.Nodes;
                  tree[i].children[j]['children'].forEach((ele: any) => {
                    ele.Level = element.Level;
                  });
                }
              }
            }
          });
          break;
        }
      }
    });
    return tree;
  }
}
