import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { WidgetService } from '../widget.service';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from '@app/shared/services/cache.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import WidgetBase from '../widegt.base';
import { GlobalFilterService } from '@app/shared/module/global-filters/global-filters.service';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { Identifiers } from '@app/shared/services/app.config.type';
import { FiltersService } from '@app/shared/services/filters.service';
@Component({
  selector: 'nissan-university-certification',
  templateUrl: './nissan-university-certification.component.html',
  styleUrls: ['./nissan-university-certification.component.scss'],
})
export class NissanUniversityCertificationComponent extends WidgetBase implements OnInit, OnDestroy {
  @Input() styleClass = '';
  @Input() module = 'frft';
  @Input() layout = 'medium';
  @Input() rootClass = 'card-medium';
  @Input() clickThrough = '';
  @Input() widgetTitle = '';
  secondsCounter = interval(120000);
  @Input() allowDropDownShow = true;
  filters: any;
  kpiNames: any[] = [];
  name: string;
  showsWidgets: boolean = false;
  noResults: boolean = true;
  response: any[];
  Certification: any;
  canRender = false;
  api: Subscription;
  cols: any = [
    { field: 'EmplpoyeeName', header: 'EMPLOYEE NAME' },
    { field: 'CertificationStatus', header: 'STATUS' },
    { field: 'Tenure', header: 'TOTAL # OF MONTHS REGISTERED IN NU' },
    { field: 'TechnicianLevel', header: 'TECHNICIAN LEVEL' },

    // You can add or remove columns as needed
  ];
  public subscriptions: Subscription[] = [];

  constructor(
    public appConfig: AppConfigurationService,
    public widgetService: WidgetService,
    private cacheService: CacheService,
    public translateService: TranslateService,
    public gbfService: GlobalFilterService,
    private filterService: FiltersService
  ) {
    super(gbfService, translateService);
    const widgetsRefreshThreshold = appConfig.getByIdentifier(Identifiers.WidgetsRefreshThreshold);
    if (widgetsRefreshThreshold) {
      let widgetThresholdVal;
      if (widgetsRefreshThreshold.ClientConfig === null) {
        widgetThresholdVal = widgetsRefreshThreshold['Fields'].equal.value;
      } else {
        if (widgetsRefreshThreshold.ClientConfig) {
          widgetThresholdVal = widgetsRefreshThreshold.ClientConfig['Fields'].equal.value;
        }
      }
      this.secondsCounter = interval(widgetThresholdVal);
    }
  }

  ngOnInit() {
    this.fillKpiDropDown();
    this.filters = this.filterService.getFilterObject();
    this.loadWidget();

    this.subscriptions.push(
      this.widgetService.update.subscribe(() => {
        this.loadWidget();
      })
    );
  }

  ngOnDestroy(): void {}

  loadWidget() {
    this.loadView();
    this.widgetService.isAllLoaded = false;
    this.canRender = false;
    if (this.api) {
      this.api.unsubscribe();
    }
    const encryptedName = this.encryptValue(this.name);
    this.api = this.widgetService.getnissanUniversityCertificate(encryptedName).subscribe({
      next: (res: any) => {
        if (res == null || res != null) {
          if (res.length > 0) {
            this.response = res;
            this.noResults = false;
            this.canRender = true;
            this.Certification = res;
            this.widgetService.isAllLoaded = true;
          } else {
            this.noResults = this.showsWidgets;
            this.Certification = res;
          }
        }
      },
      complete: () => {
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      },
      error: () => {
        this.widgetService.isAllLoaded = true;
        this.canRender = true;
      },
    });
  }

  onKPIChange(event: any) {
    this.loadWidget();
  }
  fillKpiDropDown() {
    var kpiConfig = this.appConfig.getByIdentifier('nissan_university_certification.process_view');

    if (kpiConfig && kpiConfig['Fields']) {
      var obj = this.filterService.getFilterObject();
      if (obj) {
        let kpisConf: [] = kpiConfig.Fields;
        var kpiList = kpisConf?.filter((f) => f['survey_id'] == obj.SurveyVersion)[0];
        //var kpiList = kpisConf.filter((f) => f['survey_id'] == "635a4e801969812dadb191ce")[0];
        if (kpiList) {
          let list: [] = kpiList['kpis'];
          this.name = kpiList['kpis'][0];
          for (var ii of list) {
            this.kpiNames.push({ label: this.translateService.instant(ii), value: ii });
          }
        }
      }
    }
  }
  encryptValue(value: string): string {
    const key = 4302;
    let encryptedValue = '';

    for (let i = 0; i < value.length; i++) {
      if (value[i] !== '\\') {
        encryptedValue += String.fromCharCode(value.charCodeAt(i) ^ key);
      }
    }

    return encryptedValue;
  }
  loadView() {
    const mainNodeLevel = this.gbfService.authorizationData.MainNodeLevel;
    const selectedOrgLevel = this.gbfService.getFilterObject().OrgLevel;

    this.showsWidgets = selectedOrgLevel >= mainNodeLevel;
    return this.showsWidgets;
  }
}
